import { i18n } from "@toolkit/i18n";
import { MedicalFormType } from "../../schema/types";
export const medicalFormTypeOptionsMap = {
    [MedicalFormType.Administrative]: {
        key: MedicalFormType.Administrative,
        get label() {
            return i18n.t("Administrative", { ns: "domains" });
        },
        value: MedicalFormType.Administrative,
    },
    [MedicalFormType.Assessment]: {
        key: MedicalFormType.Assessment,
        get label() {
            return i18n.t("Assessment", { ns: "domains" });
        },
        value: MedicalFormType.Assessment,
    },
    [MedicalFormType.History]: {
        key: MedicalFormType.History,
        get label() {
            return i18n.t("History", { ns: "domains" });
        },
        value: MedicalFormType.History,
    },
    [MedicalFormType.Prom]: {
        key: MedicalFormType.Prom,
        get label() {
            return i18n.t("PROM", { ns: "domains" });
        },
        value: MedicalFormType.Prom,
    },
    [MedicalFormType.Prem]: {
        key: MedicalFormType.Prem,
        get label() {
            return i18n.t("PREM", { ns: "domains" });
        },
        value: MedicalFormType.Prem,
    },
    [MedicalFormType.Quizzes]: {
        key: MedicalFormType.Quizzes,
        get label() {
            return i18n.t("Quizzes", { ns: "domains" });
        },
        value: MedicalFormType.Quizzes,
    },
};
export const medicalFormTypeOptions = Object.values(medicalFormTypeOptionsMap);
