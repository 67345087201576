import { MarketplaceApprovalStatus, MarketplaceHealthPackageSortingField, MarketplaceSortDirection } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useHealthPackagesQuery } from "../gql";
import { healthPackageTitleBreadcrumb } from "../health-package-breadcrumb";
import { useHealthPackageColumns } from "./components/useHealthPackageColumns";

export const ReviewHealthPackagesList: FC = () => {
  const { t } = useTranslation("admin");
  const healthPackageColumns = useHealthPackageColumns();
  const { setBreadCrumb } = useBreadCrumbs();

  useEffect(() => {
    setBreadCrumb({
      title: healthPackageTitleBreadcrumb(t),
    });
  }, []);

  return (
    <GridProvider
      hasTableSetting
      gridName='healthPackages'
      query={useHealthPackagesQuery}
      columns={healthPackageColumns}
      filterInput={{
        approvalStatuses: [MarketplaceApprovalStatus.PendingApproval],
      }}
      hideFilterInput
      variables={{
        sortBy: {
          direction: MarketplaceSortDirection.Desc,
          field: MarketplaceHealthPackageSortingField.Created,
        },
        filter: {
          approvalStatuses: [MarketplaceApprovalStatus.PendingApproval],
        },
      }}
    >
      <PageWrapper filters={<FilterGrid />} actions={<TableSettingComponent />}>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
