import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const OptimaEditGetDocument = gql `
    query OptimaEditGet($id: ID!) {
  optimaEdit(id: $id) {
    code
    details
    message
    priority
    source
    category
    subCategory
    isActive
    actions {
      id
      action
    }
  }
}
    `;
/**
 * __useOptimaEditGetQuery__
 *
 * To run a query within a React component, call `useOptimaEditGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptimaEditGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptimaEditGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOptimaEditGetQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(OptimaEditGetDocument, options);
}
export function useOptimaEditGetLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(OptimaEditGetDocument, options);
}
export function useOptimaEditGetSuspenseQuery(baseOptions) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(OptimaEditGetDocument, options);
}
