import { gql } from '@apollo/client';
import { PageInfoAutocompleteFragmentDoc } from '../../../../gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const MarketplaceBrandsAutocompleteDocument = gql `
    query MarketplaceBrandsAutocomplete($first: Int, $last: Int, $after: String, $before: String, $filter: MarketplaceBrandFilterInput, $sortBy: MarketplaceDefaultSortingInput) {
  marketplaceBrands(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      ...PageInfoAutocomplete
    }
    edges {
      node {
        id
        name
        nameAr
      }
    }
  }
}
    ${PageInfoAutocompleteFragmentDoc}`;
/**
 * __useMarketplaceBrandsAutocompleteQuery__
 *
 * To run a query within a React component, call `useMarketplaceBrandsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceBrandsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceBrandsAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useMarketplaceBrandsAutocompleteQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(MarketplaceBrandsAutocompleteDocument, options);
}
export function useMarketplaceBrandsAutocompleteLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(MarketplaceBrandsAutocompleteDocument, options);
}
export function useMarketplaceBrandsAutocompleteSuspenseQuery(baseOptions) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(MarketplaceBrandsAutocompleteDocument, options);
}
