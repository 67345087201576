import { i18n } from "@toolkit/i18n";
import { QuestionType } from "../../schema/types";
export const questionTypeOptionsMap = {
    [QuestionType.Boolean]: {
        key: QuestionType.Boolean,
        get label() {
            return i18n.t("Yes/No", { ns: "domains" });
        },
        value: QuestionType.Boolean,
    },
    [QuestionType.MultiChoice]: {
        key: QuestionType.MultiChoice,
        get label() {
            return i18n.t("Multiple Choice", { ns: "domains" });
        },
        value: QuestionType.MultiChoice,
    },
    [QuestionType.SingleChoice]: {
        key: QuestionType.SingleChoice,
        get label() {
            return i18n.t("Single Choice", { ns: "domains" });
        },
        value: QuestionType.SingleChoice,
    },
    [QuestionType.String]: {
        key: QuestionType.String,
        get label() {
            return i18n.t("Free Text", { ns: "domains" });
        },
        value: QuestionType.String,
    },
};
export const questionTypeOptions = Object.values(questionTypeOptionsMap);
