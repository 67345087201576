import { CityOrderField } from "@/schema/types";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, useFormatDate } from "@toolkit/ui";
import { useMemo } from "react";
import { ICityListNode } from "../../types";

export const useCityListContainerColumns = (): CustomTableColumnProps<ICityListNode>[] => {
  const { t } = useTranslation("admin");
  const { formatLocalizedDate } = useFormatDate();

  return useMemo(
    () => [
      {
        key: "name",
        header: t("Name"),
        accessor: ({ name, nameAr }) => pickLocalizedValue(name, nameAr),
        isSortable: true,
        sortDirection: "DESC",
        sortColumnEnum: CityOrderField.Name,
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
      {
        key: "area",
        header: t("Area"),
        accessor: ({ area }) => area || "-",
      },
      {
        key: "createdDate",
        header: t("Created Date"),
        accessor: ({ created }) => formatLocalizedDate(created),
        isSortable: true,
        sortDirection: "DESC",
        sortColumnEnum: CityOrderField.Created,
      },
    ],
    [formatLocalizedDate, t]
  );
};
