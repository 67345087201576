import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable max-lines */
import { OrderDirection, OrderSortField, PermissionEnum } from "../../../../schema/types";
import { getBranchesAutocompleteFilter } from "@health/autocompletes";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { capitalize, hasPermission } from "@toolkit/core";
import { i18n, useTranslation } from "@toolkit/i18n";
import { MedicationsModel, OrderStatusTypography, TruncateTypography } from "@toolkit/ui";
import moment from "moment-timezone";
import "moment/locale/ar";
import { LifeCycleModel } from "../../../OrdersTracking/components/LifeCycle.components";
import { getOrderStatusColor } from "../../../OrdersTracking/components/utils";
import { useMemo } from "react";
import { OrderHandlingModal } from "../components";
export const usePrescriptionsOrdersTrackingColumns = ({ isAdmin }) => {
    const { t } = useTranslation("domains");
    return useMemo(() => {
        return [
            {
                key: "number",
                header: t("Order Number"),
                accessor: ({ number }) => number,
                isSortable: true,
                sortDirection: OrderDirection === null || OrderDirection === void 0 ? void 0 : OrderDirection.Asc,
                sortColumnEnum: OrderSortField.Number,
                filter: {
                    type: "string",
                    name: "numbers",
                },
            },
            {
                key: "Number",
                header: t("eRx Number"),
                accessor: ({ prescription }) => prescription === null || prescription === void 0 ? void 0 : prescription.referenceNumber,
                isHidden: false,
                hideFromSettings: true,
                disableToggleVisibility: true,
                filter: {
                    type: "string",
                    name: "referenceNumber",
                },
            },
            {
                key: "type",
                header: t("Order Type"),
                accessor: ({ type }) => ((type === null || type === void 0 ? void 0 : type.includes("_")) ? capitalize(type.toLowerCase().split("_")) : capitalize([String(type)])),
            },
            {
                key: "patientId",
                header: t("Customer Id"),
                accessor: ({ prescription }) => { var _a; return String((_a = prescription === null || prescription === void 0 ? void 0 : prescription.patientIdentifier) !== null && _a !== void 0 ? _a : "-"); },
                isSortable: true,
                sortDirection: OrderDirection === null || OrderDirection === void 0 ? void 0 : OrderDirection.Asc,
                sortColumnEnum: OrderSortField.Customer,
            },
            {
                key: "patient name",
                header: t("Customer Name"),
                accessor: ({ prescription }) => { var _a, _b, _c, _d; return String((_b = (_a = prescription === null || prescription === void 0 ? void 0 : prescription.patient) === null || _a === void 0 ? void 0 : _a.firstName) !== null && _b !== void 0 ? _b : "") + " " + String((_d = (_c = prescription === null || prescription === void 0 ? void 0 : prescription.patient) === null || _c === void 0 ? void 0 : _c.lastName) !== null && _d !== void 0 ? _d : ""); },
            },
            {
                key: "contactEmail",
                header: t("Customer Email"),
                accessor: ({ prescription }) => { var _a, _b; return _jsx("span", { className: 'mobile-phone-rtl-fix', children: String((_b = (_a = prescription === null || prescription === void 0 ? void 0 : prescription.patient) === null || _a === void 0 ? void 0 : _a.email) !== null && _b !== void 0 ? _b : "") }); },
            },
            {
                key: "contactNumber",
                header: t("Customer Mobile"),
                accessor: ({ prescription }) => { var _a, _b; return _jsx("span", { className: 'mobile-phone-rtl-fix', children: String((_b = (_a = prescription === null || prescription === void 0 ? void 0 : prescription.patient) === null || _a === void 0 ? void 0 : _a.contactNumber) !== null && _b !== void 0 ? _b : "") }); },
            },
            {
                header: t("Payment Price"),
                key: "totalAmount",
                accessor: ({ totalNetAmount }) => totalNetAmount,
            },
            {
                key: "Medications",
                header: t("Medications"),
                accessor: ({ prescription }) => _jsx(MedicationsModel, { medications: prescription === null || prescription === void 0 ? void 0 : prescription.medications }),
            },
            {
                key: "createdTo",
                header: t("Created Date To"),
                showOnlyForFilterField: true,
                filter: {
                    type: "date",
                    name: "created.lte",
                },
            },
            {
                key: "Order Status",
                header: t("Order Status"),
                accessor: ({ status }) => _jsx(OrderStatusTypography, { status: status, color: getOrderStatusColor(status) }),
                filter: getAutocompleteEnumFilter("OrderStatusActive", "status", { multiple: true }),
            },
            {
                key: "branch",
                header: t("Branch"),
                accessor: ({ branch }) => _jsx(TruncateTypography, { text: (branch === null || branch === void 0 ? void 0 : branch.name) || "-" }),
            },
            {
                key: "vendor",
                header: t("Vendor"),
                accessor: ({ branch }) => { var _a; return _jsx(TruncateTypography, { text: ((_a = branch === null || branch === void 0 ? void 0 : branch.vendor) === null || _a === void 0 ? void 0 : _a.name) || "-" }); },
                hideFromPreferencesAndTable: !isAdmin,
            },
            {
                key: "Created",
                header: t("Created"),
                accessor: ({ created }) => {
                    moment.localeData("ar");
                    const createdTz = moment.tz.guess();
                    moment.locale(i18n.language);
                    return moment(created).tz(createdTz).format("DD MMM YYYY hh:mm a");
                },
                isSortable: true,
                sortDirection: OrderDirection === null || OrderDirection === void 0 ? void 0 : OrderDirection.Asc,
                sortColumnEnum: OrderSortField.CreationDate,
                filter: {
                    type: "date",
                    name: "created.gte",
                },
            },
            {
                key: "auditHistory",
                header: t("Audit History"),
                accessor: ({ number, prescription }) => (_jsx(LifeCycleModel, { number: number, prescriptionID: prescription === null || prescription === void 0 ? void 0 : prescription.id, referenceNumber: prescription === null || prescription === void 0 ? void 0 : prescription.referenceNumber, dispenseStatus: prescription === null || prescription === void 0 ? void 0 : prescription.dispenseStatus })),
            },
            {
                key: "orderHandling",
                header: t("Order Handling"),
                accessor: row => _jsx(OrderHandlingModal, { order: row }),
                hideFromPreferencesAndTable: !isAdmin && !hasPermission(PermissionEnum.CustomerSupportManageOrders),
            },
            {
                key: "helpDiskTicketId_Icontains",
                header: t("Help Disk Ticket Id"),
                showOnlyForFilterField: true,
                filter: {
                    type: "string",
                    name: "helpDiskTicketId_Icontains",
                },
            },
            {
                key: "hasHelpDiskTicket",
                header: t("Has help disk ticket Id"),
                showOnlyForFilterField: true,
                filter: getAutocompleteEnumFilter("YesNo", "hasHelpDiskTicket"),
            },
            {
                key: "Branches",
                header: t("Branches"),
                showOnlyForFilterField: true,
                filter: getBranchesAutocompleteFilter({ name: "branches", multiple: true }),
            },
            {
                key: "patient-id-filter",
                header: t("Patient ID"),
                showOnlyForFilterField: true,
                filter: {
                    type: "string",
                    name: "nationalId",
                },
            },
        ];
    }, []);
};
