import { MarketplaceProductSortingField, SortDirection } from "@/schema/types";
import {
  getMarketplaceBrandsAutocompleteFilter,
  getMarketplaceProductCategoriesAutocompleteFilter,
  getVendorsAutocompleteFilter,
} from "@health/autocompletes";
import { getAutocompleteEnumFilter, marketplaceApprovalStatusOptionsMap } from "@health/enum-options";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Box, CustomDialog, CustomTableColumnProps, GridImageModal } from "@toolkit/ui";
import { useMemo } from "react";
import { ProductsQueryNode } from "../types";
import { ProductApprovalModal } from "./components/ProductApprovalModal";
import { ProductDetails } from "./components/ProductDetails";
import { ProductRejectionModal } from "./components/ProductRejectionModal";

export const useProductsListTableColumns = (): CustomTableColumnProps<ProductsQueryNode>[] => {
  const { t } = useTranslation("domains");
  return useMemo(() => {
    return [
      {
        key: "image",
        header: t("Image "),
        accessor: ({ mainImageUrl }) => <GridImageModal image={mainImageUrl!} title={t("Product Image")} altText={t("Product")} />,
      },
      {
        key: "name",
        header: t("Name"),
        accessor: pickLocalizedValue("name", "nameAr"),
        filter: {
          type: "string",
          name: "search",
        },
        isSortable: true,
        sortDirection: SortDirection.Desc,
        sortColumnEnum: pickLocalizedValue(MarketplaceProductSortingField.Name, MarketplaceProductSortingField.NameAr),
      },
      {
        key: "brand",
        header: t("Brand"),
        accessor: ({ brand }) => pickLocalizedValue(brand?.name!, brand?.nameAr!),
        filter: getMarketplaceBrandsAutocompleteFilter({ name: "brandIds", multiple: true }),
      },
      {
        key: "category",
        header: t("Category"),
        accessor: ({ category }) => pickLocalizedValue(category?.name!, category?.nameAr!),
        filter: getMarketplaceProductCategoriesAutocompleteFilter({ name: "categories", multiple: true }),
      },
      {
        key: "health-provider",
        header: t("Health Provider"),
        accessor: ({ vendor }) => pickLocalizedValue(vendor?.name, vendor?.nameAr!),
        filter: getVendorsAutocompleteFilter({ name: "vendorId" }),
      },
      {
        key: "Approval Status",
        header: t("Approval Statuses"),
        accessor: ({ approvalStatus }) => marketplaceApprovalStatusOptionsMap[approvalStatus!]?.label,
        filter: getAutocompleteEnumFilter("MarketplaceApprovalStatusNoDraft", "approvalStatuses", { multiple: true }),
      },
      {
        key: "preview",
        header: t("Preview"),
        accessor: props => (
          <CustomDialog type='info' title={t("Product Preview")}>
            <ProductDetails {...props} />
          </CustomDialog>
        ),
      },
      {
        key: "actions",
        header: t("Actions"),
        accessor: props => (
          <Box display='flex' flexDirection='row' gap={2}>
            <ProductApprovalModal {...props} />
            <ProductRejectionModal isProductUpdatable={props?.updatable} productID={props.id} approvalStatus={props?.approvalStatus!} />
          </Box>
        ),
      },
    ];
  }, []);
};
