import { CoverageByType, HealthProgramMembershipRequest, HealthProgramMembershipRequestApproveInput, RequestStatus } from "@/schema/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { useToasts } from "@toolkit/ui";
import moment from "moment";
import {
  HealthProgramMembershipRequestApproveMutation,
  HealthProgramMembershipRequestsDocument,
  useHealthProgramMembershipRequestApproveMutation,
} from "pages/InsuranceApprovals/gql";
import { useState } from "react";

export const useApproveInsuranceCardDialog = ({
  healthProgramMembershipRequest,
}: {
  healthProgramMembershipRequest: HealthProgramMembershipRequest;
}) => {
  const [coverageBy, setCoverageBy] = useState(CoverageByType.Cash);
  const [insuranceCategory, setInsuranceCategory] = useState();
  const [insuranceCategoryCode, setInsuranceCategoryCode] = useState();
  const [insuranceClass, setInsuranceClass] = useState();
  const [insurancePolicyNumber, setInsurancePolicyNumber] = useState();
  const [membershipStart, setMembershipStart] = useState(moment().add(1, "day"));
  const [membershipEnd, setMembershipEnd] = useState(moment().add(1, "M"));

  const { t } = useTranslation("admin");
  const { addToast } = useToasts();

  const { handleToggle: handleToggleDialog, open: isOpen } = useOpenState();

  const [healthProgramMembershipRequestApproveMutation, { loading: isSubmitting }] = useHealthProgramMembershipRequestApproveMutation({
    onCompleted: (data: HealthProgramMembershipRequestApproveMutation) => {
      const errors = data?.healthProgramMembershipRequestApprove?.healthProgramMembershipRequestErrors;
      if (errors?.length === 0) {
        addToast(t("Insurance Request Approved"), {
          appearance: "success",
          autoDismiss: true,
        });
        handleToggleDialog();
      } else {
        const formatted = formatMessageErrors(errors);
        addToast(formatted, {
          appearance: "error",
          autoDismiss: true,
        });
      }
    },
    onError: ({ graphQLErrors }) => {
      const formatted = formatGraphQLError(graphQLErrors);
      addToast(formatted || t("Insurance Request Approve failed"), {
        appearance: "error",
        autoDismiss: true,
      });
    },
    refetchQueries: [
      {
        query: HealthProgramMembershipRequestsDocument,
        variables: {
          first: 10,
        },
      },
    ],
  });

  const isAlreadyApproved = healthProgramMembershipRequest.status === RequestStatus.Approved;
  const isAlreadyRejected = healthProgramMembershipRequest.status === RequestStatus.Rejected;
  const isDataInvalid = membershipStart?.isAfter(membershipEnd);

  let tooltipTitle = "";
  if (isAlreadyApproved) {
    tooltipTitle = t("The request is already been approved");
  } else if (isAlreadyRejected) {
    tooltipTitle = t("The request is already been rejected");
  }

  const handleSubmit = () => {
    if (!membershipStart && !membershipEnd) {
      return;
    }
    const input: HealthProgramMembershipRequestApproveInput = {
      coverageBy,
      membershipStart: membershipStart.format("YYYY-MM-DD"),
      membershipEnd: membershipEnd.format("YYYY-MM-DD"),
      insuranceCategory: insuranceCategory,
      insuranceClass: insuranceClass,
      insuranceCategoryCode,
      insurancePolicyNumber,
    };

    healthProgramMembershipRequestApproveMutation({
      variables: {
        healthProgramMembershipRequestApproveId: healthProgramMembershipRequest.id,
        input,
      },
    });
  };

  const handleToggle = e => {
    e.stopPropagation();
    handleToggleDialog();
  };

  return {
    isOpen,
    membershipStart,
    membershipEnd,
    coverageBy,
    isDataInvalid,
    isAlreadyApproved,
    isAlreadyRejected,
    tooltipTitle,
    handleChangeMembershipStart: value => setMembershipStart(value),
    handleChangeMembershipEnd: value => setMembershipEnd(value),
    handleChangeInsurancePolicyNumber: event => setInsurancePolicyNumber(event.target.value),
    handleChangeInsuranceClass: event => setInsuranceClass(event.target.value),
    handleChangeInsuranceCategory: event => setInsuranceCategory(event.target.value),
    handleChangeInsuranceCategoryCode: event => setInsuranceCategoryCode(event.target.value),
    handleChangeCoverageBy: (_, value) => setCoverageBy(value),
    handleSubmit,
    t,
    handleToggleDialog,
    handleToggle,
    isSubmitting,
    insuranceClass,
    insuranceCategory,
    insuranceCategoryCode,
    insurancePolicyNumber,
  };
};
