import { H_OrderDirection, HealthProgramMemberSortingField, PermissionEnum } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { hasPermission } from "@toolkit/core";
import { useNavigate, useSearchParams } from "react-router-dom";
import { HealthProgramMemberListPageWrapper } from "../../components/HealthProgramMemberListPageWrapper/HealthProgramMemberListPageWrapper";
import { healthProgramMembersPaths } from "../../constants";
import { useHealthProgramMemberListQuery } from "../../gql";
import { useSetHealthProgramMembersBreadcrumbs } from "../../hooks";
import { IHealthProgramMemberNode } from "../../types";
import { useHealthProgramMemberListContainerColumns } from "./useHealthProgramMemberListContainerColumns";

export const HealthProgramMemberListContainer = () => {
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const memberListId = params?.get("memberListId");

  const hasManageHealthProgramsPermission = hasPermission(PermissionEnum.ManageHealthPrograms);

  const onEditRowClick = (item: IHealthProgramMemberNode) => {
    const editPath = healthProgramMembersPaths.update.fullPathWithParams({ memberId: item?.id });
    const editPathWithParams = memberListId ? `${editPath}?memberListId=${memberListId}` : editPath;
    navigate(editPathWithParams);
  };

  useSetHealthProgramMembersBreadcrumbs("LIST");

  return (
    <GridProvider
      gridName={"healthProgramMemberList"}
      query={useHealthProgramMemberListQuery}
      columns={useHealthProgramMemberListContainerColumns()}
      variables={{
        filter: {
          healthProgramMemberLists: memberListId ? [memberListId] : undefined,
        },
        sortBy: {
          direction: H_OrderDirection.Desc,
          field: HealthProgramMemberSortingField.Created,
        },
      }}
      tableAction={{
        isEditVisible: hasManageHealthProgramsPermission,
        onEditRow: onEditRowClick,
      }}
      hideFilterInput
    >
      <HealthProgramMemberListPageWrapper memberListId={memberListId} />
    </GridProvider>
  );
};
