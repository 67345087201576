import { OrderDirection, Plan, PlanOrderField, PlanPeriod } from "@/schema/types";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, Typography, format } from "@toolkit/ui";
import { useMemo } from "react";
import { ToggleActiveSwitch } from "./ActiveSwitch.component";

const dateFormat = "DD MMM YYYY MM:HH";
const getPlanPeriodValue = period => {
  const planPeriods = Object.keys(PlanPeriod).map(item => ({ value: PlanPeriod[item], name: item }));
  const getvalue = planPeriods.find(item => item.value === period);
  return getvalue?.name || "";
};

export const useSubscriptionsColumns = (): CustomTableColumnProps<Plan>[] => {
  const { t } = useTranslation("admin");

  return useMemo(
    () => [
      {
        key: "name",
        header: t("Name"),
        accessor: "name",
        isHidden: false,
        isSortable: true,
        sortDirection: OrderDirection?.Asc,
        hideFromSettings: true,
        disableToggleVisibility: true,
        sortColumnEnum: PlanOrderField.Name,
        filter: {
          name: "name",
          type: "string",
        },
      },
      {
        key: "fixedCost",
        header: t("Fixed Cost (SAR)"),
        accessor: row => row?.fixedCost?.amount,
        isHidden: false,
      },
      {
        key: "fixedOrderPercentage",
        header: t("Fixed Order Percentage"),
        accessor: row => {
          return <Typography>{row?.fixedOrderPercentage + "%"}</Typography>;
        },
        isHidden: false,
      },
      {
        key: "Period",
        header: t("Period"),
        accessor: row => t(getPlanPeriodValue(row?.period)),
        isHidden: false,
      },

      {
        key: "validFrom",
        header: t("Valid From"),
        accessor: row => <Typography noWrap>{format(row?.validFrom, dateFormat)}</Typography>,
        isHidden: false,
      },
      {
        key: "validTill",
        header: t("Valid Till"),
        accessor: row => <Typography noWrap>{format(row?.validTill, dateFormat) || "-"}</Typography>,
        isHidden: false,
      },
      {
        key: "created",
        header: t("Created"),
        accessor: row => <Typography noWrap>{format(row?.created, dateFormat) || "-"}</Typography>,
        isHidden: false,
      },
      {
        key: "description",
        header: t("Description"),

        accessor: row => (
          <Typography noWrap>
            {Number(row?.description?.length) > 10 ? <>{row?.description?.substr(0, 10) + "..."}</> : row?.description}
          </Typography>
        ),
        isHidden: false,
      },
      {
        key: "isActive",
        header: t("Is Active"),
        accessor: row => <ToggleActiveSwitch id={row?.id} isActive={row?.isActive} />,
        isHidden: false,
        disableToggleVisibility: false,
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
    ],
    []
  );
};
