import { AccountError, User } from "@/schema/types";
import { combineErrors, formGirdBreakPoints, formGirdSpacing, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomDatePicker, Grid, TextField } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";

export const DoctorQualificationsInformation: FC<{
  errors: AccountError[];
  defaultValues: User | undefined;
}> = ({ errors, defaultValues }) => {
  const { t } = useTranslation("admin");
  const { register, formState, control, watch, setError, clearErrors } = useFormContext();
  const combinedErrors = combineErrors(formState.errors, errors);
  const defaultQualification = defaultValues?.doctor?.qualifications?.[0];

  const fromDateFieldName = `doctorInfo.qualifications.fromDate`;
  const toDateFieldName = `doctorInfo.qualifications.toDate`;
  const fromDateValue = watch(fromDateFieldName);
  const toDateValue = watch(toDateFieldName);
  useEffect(() => {
    const validateDateRange = () => {
      if (fromDateValue && toDateValue) {
        const fromDateObj = new Date(fromDateValue);
        const toDateObj = new Date(toDateValue);
        if (fromDateObj > toDateObj) {
          setError(fromDateFieldName, {
            message: t("From Date should be less than To Date"),
          });

          setError(toDateFieldName, {
            message: t("To Date should be after than From Date"),
          });
          return false;
        }
      }
      clearErrors(fromDateFieldName);
      clearErrors(toDateFieldName);
      return true;
    };

    validateDateRange();
  }, [fromDateValue, toDateValue]);
  return (
    <Grid container spacing={formGirdSpacing}>
      <Grid item {...formGirdBreakPoints} md={3}>
        <TextField
          label={t("Code")}
          placeholder={t("Code")}
          fullWidth
          error={Boolean(combinedErrors?.doctorInfo?.qualifications?.code?.message)}
          helperText={t(combinedErrors?.doctorInfo?.qualifications?.code?.message)}
          defaultValue={defaultQualification?.code!}
          {...register("doctorInfo.qualifications.code", {
            required: getRequiredValidation(t, true),
          })}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints} md={3}>
        <TextField
          label={t("Issuer")}
          placeholder={t("Issuer")}
          fullWidth
          defaultValue={defaultQualification?.issuer!}
          error={Boolean(combinedErrors?.doctorInfo?.qualifications?.issuer?.message)}
          helperText={t(combinedErrors?.doctorInfo?.qualifications?.issuer?.message)}
          {...register("doctorInfo.qualifications.issuer", {
            required: getRequiredValidation(t, false),
          })}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints} md={3}>
        <CustomDatePicker
          control={control}
          name={fromDateFieldName}
          label={t("From Date")}
          placeholder={t("From Date")}
          defaultValue={defaultQualification?.fromDate!}
          error={Boolean(combinedErrors?.doctorInfo?.qualifications?.fromDate?.message)}
          helperText={t(combinedErrors?.doctorInfo?.qualifications?.fromDate?.message)}
          format='YYYY-MM-DD'
        />
      </Grid>
      <Grid item {...formGirdBreakPoints} md={3}>
        <CustomDatePicker
          control={control}
          name={toDateFieldName}
          label={t("To Date")}
          placeholder={t("To Date")}
          defaultValue={defaultQualification?.toDate!}
          error={Boolean(combinedErrors?.doctorInfo?.qualifications?.toDate?.message)}
          helperText={t(combinedErrors?.doctorInfo?.qualifications?.toDate?.message)}
          format='YYYY-MM-DD'
        />
      </Grid>
    </Grid>
  );
};
