import { PermissionGroupCreateInput, PermissionGroupError } from "@/schema/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import {
  permissionsManagementGroupListBreadcrumb,
  permissionsManagementNewBreadcrumb,
  permissionsManagementTitleBreadcrumb,
} from "pages/PermissionsManagement/constants/permissions-management-breadcrumbs";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CreatePermissionMutation, useCreatePermissionMutation } from "../../gql";

export const usePermissionGroupCreateHook = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { setBreadCrumb } = useBreadCrumbs();
  const [errors, setErrors] = React.useState<PermissionGroupError[]>([]);
  const { failed, succeeded } = useAddToast();
  const [createPermissionMutation, { loading }] = useCreatePermissionMutation({
    onCompleted: (createdData: CreatePermissionMutation) => {
      const mutationErrors = createdData?.permissionGroupCreate?.permissionGroupErrors;
      if (mutationErrors && mutationErrors.length > 0) {
        failed(t(formatMessageErrors(mutationErrors)));
        setErrors(mutationErrors);
      } else {
        succeeded(t("Permission group created successfully"));
        navigate(-1);
      }
    },
    onError: () => {
      failed(t("Failed to create permission group"));
    },
  });

  const handleSubmit = (inputData: PermissionGroupCreateInput) => {
    createPermissionMutation({
      variables: {
        input: inputData,
      },
    });
  };

  useEffect(() => {
    setBreadCrumb({
      title: permissionsManagementTitleBreadcrumb(t),
      values: [permissionsManagementGroupListBreadcrumb(t), permissionsManagementNewBreadcrumb(t)],
    });
  }, []);

  return {
    errors,
    handleSubmit,
    loading,
    isSubmitting: loading,
  };
};
