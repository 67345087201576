/* eslint-disable max-lines */
import { AppointmentSortingField, H_OrderDirection, HealthProgramMember } from "@/schema/types";
import { PatientContactInformation } from "@/shared/components/PatientContactInformation/PatientContactInformation";
import { PatientInsuranceInformation } from "@/shared/components/PatientInsuranceInformation/PatientInsuranceInformation";
import { getBranchesAutocompleteFilter, getDepartmentsAutocompleteFilter } from "@health/autocompletes";
import {
  appointmentStatusOptionsMap,
  appointmentTypeOptionsMap,
  getAutocompleteEnumFilter,
  paymentStatusOptionsMap,
  requestedByOptionsMap,
} from "@health/enum-options";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, TruncateTypography, format } from "@toolkit/ui";
import { useMemo } from "react";
import { AppointmentCancel } from "../../components/AppointmentCancel/AppointmentCancel";
import { IAppointmentListNode } from "../../types";

export const useAppointmentListContainerColumns = (): CustomTableColumnProps<IAppointmentListNode>[] => {
  const { t } = useTranslation("admin");

  return useMemo(
    () => [
      {
        key: "id",
        header: t("ID"),
        accessor: "id",
      },
      {
        key: "patient",
        header: t("Patient"),
        accessor: ({ consumer }) => <TruncateTypography text={`${consumer?.firstName} ${consumer?.lastName}`} />,
      },
      {
        key: "contactInfo",
        header: t("Contact Info"),
        accessor: ({ consumer }) => <PatientContactInformation contactNumber={consumer?.contactNumber!} email={consumer?.email!} />,
      },
      {
        key: "appointmentTime",
        header: t("Appointment Time"),
        accessor: ({ confirmedStartTime }) => (confirmedStartTime ? format(confirmedStartTime, "DD MMMM YYYY | HH:mm A") : "-"),
        isSortable: true,
        sortColumnEnum: AppointmentSortingField.ConfirmedStartDate,
        sortDirection: H_OrderDirection.Desc,
      },
      {
        key: "doctor",
        header: t("Doctor"),
        accessor: ({ doctor }) => <TruncateTypography text={doctor?.user?.fullName || "-"} />,
      },
      {
        key: "provider",
        header: t("Provider"),
        accessor: ({ branch }) => <TruncateTypography text={pickLocalizedValue(branch?.vendor?.name, branch?.vendor?.nameAr) || "-"} />,
      },
      {
        key: "branch",
        header: t("Branch"),
        accessor: ({ branch }) => <TruncateTypography text={pickLocalizedValue(branch?.name, branch?.nameAr) || "-"} />,
        filter: getBranchesAutocompleteFilter({ name: "branches", multiple: true }),
      },
      {
        key: "type",
        header: t("Type"),
        accessor: ({ type }) => appointmentTypeOptionsMap[type!]?.label,
        filter: getAutocompleteEnumFilter("AppointmentType", "type", { multiple: true }),
      },
      {
        key: "status",
        header: t("Status"),
        accessor: ({ status }) => <TruncateTypography text={appointmentStatusOptionsMap[status!]?.label} />,
      },
      {
        key: "payment",
        header: t("Payment"),
        accessor: ({ paymentStatus }) => paymentStatusOptionsMap[paymentStatus!]?.label || "-",
      },
      {
        key: "source",
        header: t("Source"),
        accessor: ({ requestedBy }) => requestedByOptionsMap[requestedBy!]?.label || "-",
        filter: getAutocompleteEnumFilter("RequestedBy", "requestedBy"),
      },
      {
        key: "insurance",
        header: t("Insurance"),
        accessor: ({ consumer }) => (
          <PatientInsuranceInformation healthProgramMembers={consumer?.activeHealthProgramMembers as HealthProgramMember[]} />
        ),
      },
      {
        key: "cancellationReason",
        header: t("Cancellation Reason"),
        accessor: ({ cancellationReason }) => <TruncateTypography text={cancellationReason || "-"} />,
      },
      {
        key: "cancel",
        header: t("Cancel"),
        accessor: ({ id, status }) => <AppointmentCancel id={id} status={status} />,
      },
      {
        key: "requestedStartTimeGte",
        header: t("Requested Start Time From"),
        showOnlyForFilterField: true,
        filter: {
          type: "dateTime",
          name: "requestedStartTime.gte",
          label: t("Requested Start Time From"),
        },
      },
      {
        key: "requestedStartTimeLte",
        header: t("Requested Start Time To"),
        showOnlyForFilterField: true,
        filter: {
          type: "dateTime",
          name: "requestedStartTime.lte",
          label: t("Requested Start Time To"),
        },
      },
      {
        key: "requestedEndTimeGte",
        header: t("Requested End Time From"),
        showOnlyForFilterField: true,
        filter: {
          type: "dateTime",
          name: "requestedEndTime.gte",
          label: t("Requested End Time From"),
        },
      },
      {
        key: "requestedEndTimeLte",
        header: t("Requested End Time To"),
        showOnlyForFilterField: true,
        filter: {
          type: "dateTime",
          name: "requestedEndTime.lte",
          label: t("Requested End Time To"),
        },
      },
      {
        key: "confirmedStartTimeGte",
        header: t("Confirmed Start Time From"),
        showOnlyForFilterField: true,
        filter: {
          type: "dateTime",
          name: "confirmedStartTime.gte",
          label: t("Confirmed Start Time From"),
        },
      },
      {
        key: "confirmedStartTimeLte",
        header: t("Confirmed Start Time To"),
        showOnlyForFilterField: true,
        filter: {
          type: "dateTime",
          name: "confirmedStartTime.lte",
          label: t("Confirmed Start Time To"),
        },
      },
      {
        key: "confirmedEndTimeGte",
        header: t("Confirmed End Time From"),
        showOnlyForFilterField: true,
        filter: {
          type: "dateTime",
          name: "confirmedEndTime.gte",
          label: t("Confirm End Time From"),
        },
      },
      {
        key: "confirmedEndTimeLte",
        header: t("Confirmed End Time To"),
        showOnlyForFilterField: true,
        filter: {
          type: "dateTime",
          name: "confirmedEndTime.lte",
          label: t("Confirmed End Time To"),
        },
      },
      {
        key: "departments",
        header: t("Departments"),
        showOnlyForFilterField: true,
        filter: getDepartmentsAutocompleteFilter({ name: "departments", multiple: true }),
      },
    ],
    [t]
  );
};
