import { gql } from '@apollo/client';
import { DecisionPlanFragmentDoc } from '../../fragments/__generated__/DecisionPlan';
import { ConditionFragmentDoc } from '../../fragments/__generated__/Condition';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const DecisionGetDocument = gql `
    query DecisionGet($id: ID!) {
  getDecision(id: $id) {
    id
    name
    priority
    isActive
    isScript
    drl
    actionFields {
      code
      type
      valueBoolean
      valueString
      valueNumber
      valueList
      valueDate
      extra
    }
    decisionPlan {
      ...DecisionPlan
    }
    conditionsGroups: conditions {
      ...Condition
      conditions {
        ...Condition
      }
    }
  }
}
    ${DecisionPlanFragmentDoc}
${ConditionFragmentDoc}`;
/**
 * __useDecisionGetQuery__
 *
 * To run a query within a React component, call `useDecisionGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useDecisionGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDecisionGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDecisionGetQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DecisionGetDocument, options);
}
export function useDecisionGetLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DecisionGetDocument, options);
}
export function useDecisionGetSuspenseQuery(baseOptions) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(DecisionGetDocument, options);
}
