/* eslint-disable max-lines */
/* eslint-disable max-statements */
import * as Types from "@/schema/types";
import { Survey } from "@/schema/types";
import { NetworkStatus } from "@apollo/client";
import { formatGraphQLError } from "@toolkit/apollo";
import { useAddToast } from "@toolkit/ui";
import { FC, PropsWithChildren, createContext, useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { surveysPaths } from "../constants/surveys-paths";
import { useFetchSurveyQuery } from "../gql";

export interface SurveyDetailsContextValue {
  survey?: Types.Maybe<Survey>;
  isLoading: boolean;
  questionOptionState: QuestionOptionState;
  resetQuestionOptionState: () => void;
  updateQuestionOptionState: (newState: Partial<QuestionOptionState>) => void;
  setSelectOptionForEdit: (option: Types.Maybe<string>, questionIndex: number) => void;
}

interface QuestionOptionState {
  questionIndex: number | null;
  selectedForEdit: Types.Maybe<string>;
  newOption: Types.Maybe<string>;
  isOptionDuplicated: boolean;
}

const defaultQuestionOptionState: QuestionOptionState = {
  questionIndex: null,
  selectedForEdit: null,
  newOption: null,
  isOptionDuplicated: false,
};

export const SurveyDetailsContext = createContext<SurveyDetailsContextValue>({} as SurveyDetailsContextValue);
export const useSurveyDetailsContext = () => useContext(SurveyDetailsContext);

export const SurveyDetailsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [questionOptionState, setQuestionOptionState] = useState<QuestionOptionState>(defaultQuestionOptionState);
  const navigate = useNavigate();
  const { surveyId } = useParams();
  const { failed } = useAddToast();
  const isNewSurvey = surveyId == "new";

  const resetQuestionOptionState = () => {
    setQuestionOptionState(defaultQuestionOptionState);
  };

  const updateQuestionOptionState = (newState: Partial<QuestionOptionState>) => {
    setQuestionOptionState(state => ({ ...state, ...newState }));
  };

  const setSelectOptionForEdit = (option: Types.Maybe<string>, questionIndex: number) => {
    updateQuestionOptionState({ selectedForEdit: option, newOption: option, questionIndex });
  };

  const { data, networkStatus } = useFetchSurveyQuery({
    variables: { surveyId: surveyId! },
    skip: isNewSurvey || !surveyId,
    onError: ({ graphQLErrors }) => {
      if (!isNewSurvey) {
        failed(formatGraphQLError(graphQLErrors));
        navigate(surveysPaths.listPath.fullPath);
      }
    },
  });

  return (
    <SurveyDetailsContext.Provider
      value={{
        survey: data?.survey,
        isLoading: NetworkStatus.loading === networkStatus,
        questionOptionState,
        setSelectOptionForEdit,
        resetQuestionOptionState,
        updateQuestionOptionState,
      }}
    >
      {children}
    </SurveyDetailsContext.Provider>
  );
};
