import { AppointmentType, AppRoleTypes, VendorUserTypes } from "@/schema/types";
import { TFunction } from "@toolkit/i18n";
import { removeEmptyRanges } from "@toolkit/ui";

export const appRolesAsInput = (t: TFunction) => {
  return Object?.keys(AppRoleTypes)?.map(key => ({ value: AppRoleTypes[key], label: t(key) }));
};

export const vendorUserTypesAsInput = Object?.keys(VendorUserTypes)?.map(key => ({ value: VendorUserTypes[key], label: key }));

export const hasHealthLicense = (vendorUserType: VendorUserTypes | undefined): boolean => {
  switch (vendorUserType) {
    case VendorUserTypes.Doctor:
    case VendorUserTypes.Pharmacist:
    case VendorUserTypes.Nurse:
    case VendorUserTypes.DentalHygienist:
    case VendorUserTypes.FitnessCoach:
    case VendorUserTypes.DiabetesEducator:
    case VendorUserTypes.Nutritionist:
    case VendorUserTypes.Optometrist:
    case VendorUserTypes.PodiatricMedicalAssistant:
    case VendorUserTypes.Psychologist:
    case VendorUserTypes.SocialWorker:
      return true;
    case VendorUserTypes.Manager:
    case VendorUserTypes.Receptionist:
      return false;
    default:
      return false;
  }
};

export const getDoctorInfo = inputData => {
  if (!inputData) return;
  const appointmentTypes = inputData?.appointmentTypes?.map(type => (type?.value ? type?.value : type));
  return {
    ...inputData,
    bio: inputData?.bio,
    isLanguagesPublic: inputData?.isLanguagesPublic,
    seniority: inputData?.seniority?.value,
    appointmentSlotTimePeriod: Number(inputData?.appointmentSlotTimePeriod),
    specializations: inputData?.specializations?.map(specialty => specialty?.value?.code ?? specialty),
    languages: inputData?.languages?.map(language => (language?.code ? language?.code : language)),
    appointmentTypes,
    onsiteVisitPrice: (appointmentTypes?.includes(AppointmentType.Onsite) && inputData?.onsiteVisitPrice) || null,
    onlineVisitPrice: (appointmentTypes?.includes(AppointmentType.Online) && inputData?.onlineVisitPrice) || null,
    atHomeVisitPrice: (appointmentTypes?.includes(AppointmentType.AtHome) && inputData?.atHomeVisitPrice) || null,
    yearsOfExperience: Number(inputData?.yearsOfExperience),
    isYearsOfExperiencePublic: inputData?.isYearsOfExperiencePublic,
    workingHours: removeEmptyRanges(inputData?.workingHours),
    qualifications: [
      {
        code: inputData?.qualifications?.code,
        issuer: inputData?.qualifications?.issuer,
        fromDate: inputData?.qualifications?.fromDate,
        toDate: inputData?.qualifications?.toDate,
      },
    ],
  };
};
