import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const CustomerSupportErxOrderStatusUpdateDocument = gql `
    mutation CustomerSupportErxOrderStatusUpdate($customerSupportErxOrderStatusUpdateId: ID!, $status: CustomerSupportOrderStatusEnum!) {
  customerSupportErxOrderStatusUpdate(
    orderId: $customerSupportErxOrderStatusUpdateId
    status: $status
  ) {
    id
    status
  }
}
    `;
/**
 * __useCustomerSupportErxOrderStatusUpdateMutation__
 *
 * To run a mutation, you first call `useCustomerSupportErxOrderStatusUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomerSupportErxOrderStatusUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customerSupportErxOrderStatusUpdateMutation, { data, loading, error }] = useCustomerSupportErxOrderStatusUpdateMutation({
 *   variables: {
 *      customerSupportErxOrderStatusUpdateId: // value for 'customerSupportErxOrderStatusUpdateId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useCustomerSupportErxOrderStatusUpdateMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(CustomerSupportErxOrderStatusUpdateDocument, options);
}
