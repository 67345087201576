import { HealthProgram } from "@/schema/types";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, PageWrapper } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { healthProgramsPaths } from "../../constants";
import { convertHealthProgramParametersToFormValues } from "../../others";
import { HealthProgramParametersForm } from "../HealthProgramParameters/HealthProgramParametersForm";
import {
  healthProgramParametersUpdateFormDefaultValues,
  healthProgramParametersUpdateFormSchema,
  IHealthProgramParametersUpdateFormValues,
} from "./HealthProgramParametersUpdateFormSchema";

export type IHealthProgramParametersUpdateFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IHealthProgramParametersUpdateFormValues;
  };
};

type HealthProgramParametersUpdateFormProps = {
  healthProgram?: HealthProgram;
  isLoading?: boolean;
  isSubmitting?: boolean;
  onChange: (event: IHealthProgramParametersUpdateFormEvent) => void;
};

export const HealthProgramParametersUpdateForm: FC<HealthProgramParametersUpdateFormProps> = props => {
  const { healthProgram, isLoading, isSubmitting, onChange } = props;

  const { t } = useTranslation("admin");
  const navigate = useNavigate();

  const form = useCustomForm<IHealthProgramParametersUpdateFormValues>({
    defaultValues: healthProgramParametersUpdateFormDefaultValues,
    schema: healthProgramParametersUpdateFormSchema,
  });

  const { handleSubmit, setValues } = form;

  const onSubmit = (values: IHealthProgramParametersUpdateFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  const handleCancel = () => {
    navigate(healthProgramsPaths.list.fullPath);
  };

  useEffect(() => {
    if (healthProgram) {
      const _healthProgram = convertHealthProgramParametersToFormValues(healthProgram);
      setValues(_healthProgram);
    }
  }, [healthProgram, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={t("Update")}
              newButtonDisabled={isLoading || isSubmitting}
              onNavigation={handleCancel}
            />
          }
        >
          <FormCard title={t("Program Points Factors")} loading={isLoading} doYouHaveData>
            <HealthProgramParametersForm />
          </FormCard>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
