import { MarketplaceProductSortingField, MarketplacePromotionSortingField, SortDirection } from "@/schema/types";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { DATE_FORMAT_FOR_TABLE_LONG } from "@toolkit/core";
import { i18n, pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, format, GridImageModal, TruncateTooltipTypography, Typography } from "@toolkit/ui";
import { TogglePromotionsPublishStatus } from "pages/MarketplacePromotions/components/PublishPromotions";
import { AdminPromotionsQueryNode } from "pages/MarketplacePromotions/types";
import { useMemo } from "react";

export const usePromotionsListTableColumns = (): CustomTableColumnProps<AdminPromotionsQueryNode>[] => {
  const { t } = useTranslation("admin");

  return useMemo(() => {
    return [
      {
        key: "image",
        header: t("Image"),
        accessor: ({ photo }) => <GridImageModal image={photo!} title={t("Promotion Image")} altText={t("Promotion")} />,
      },
      {
        key: "name",
        header: t("Name"),
        accessor: ({ name, nameAr }) => <TruncateTooltipTypography text={pickLocalizedValue(name, nameAr) || name || "-"} />,
        filter: {
          type: "string",
          name: "name",
        },
        isSortable: true,
        sortDirection: SortDirection.Desc,
        sortColumnEnum: MarketplaceProductSortingField.Name,
      },
      {
        key: "description",
        header: t("Description"),
        accessor: ({ description, descriptionAr }) => (
          <TruncateTooltipTypography text={pickLocalizedValue(description, descriptionAr) || description || "-"} />
        ),
      },
      {
        key: "startDate",
        header: t("Start Date"),
        accessor: ({ startDate }) => (
          <Typography width='max-content'>{format(startDate, DATE_FORMAT_FOR_TABLE_LONG, i18n.language)}</Typography>
        ),
        filter: { type: "date", name: "startDate" },
        isSortable: true,
        sortDirection: SortDirection.Desc,
        sortColumnEnum: MarketplacePromotionSortingField.StartDate,
      },
      {
        key: "endDate",
        header: t("End Date"),
        accessor: ({ endDate }) => (
          <Typography width='max-content'>{format(endDate, DATE_FORMAT_FOR_TABLE_LONG, i18n.language)}</Typography>
        ),
        filter: {
          type: "date",
          name: "endDate",
        },
        isSortable: true,
        sortDirection: SortDirection.Desc,
        sortColumnEnum: MarketplacePromotionSortingField.EndDate,
      },
      {
        key: "isPublished",
        header: t("Published"),
        accessor: ({ id, isPublished, endDate }) => <TogglePromotionsPublishStatus id={id} isPublished={isPublished} endDate={endDate} />,
        filter: getAutocompleteEnumFilter("YesNo", "isPublished"),
        isSortable: true,
        sortDirection: SortDirection.Desc,
        sortColumnEnum: MarketplacePromotionSortingField.Published,
      },
      {
        key: "isActive",
        header: t("Is Active"),
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
        showOnlyForFilterField: true,
      },
    ];
  }, []);
};
