import { PayersAutocomplete } from "@health/autocompletes";
import { coverageByTypeOptions } from "@health/enum-options";
import { useCustomFormContext } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, FormDatePickerField, FormTextField, Grid } from "@toolkit/ui";
import moment from "moment";
import { FC } from "react";
import { IHealthProgramMemberUpsertFormValues } from "../HealthProgramMemberUpsert/HealthProgramMemberUpsertFormSchema";

type HealthProgramMemberInsuranceFormProps = {
  isUpdateMode: boolean;
};

export const HealthProgramMemberInsuranceForm: FC<HealthProgramMemberInsuranceFormProps> = props => {
  const { isUpdateMode } = props;

  const { t } = useTranslation("admin");

  const form = useCustomFormContext<IHealthProgramMemberUpsertFormValues>();

  const memberShipStartDate = form.watch("memberShipStartDate");

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <FormTextField name={"network"} label={t("Network")} placeholder={t("Network")} />
      </Grid>

      <Grid item xs={4}>
        <PayersAutocomplete name={"payer"} filter={{ isActive: true }} />
      </Grid>

      <Grid item xs={4}>
        <FormAutocomplete name={"coverageByType"} label={t("Coverage By")} disabled={isUpdateMode} options={coverageByTypeOptions} />
      </Grid>

      <Grid item xs={4}>
        <FormDatePickerField name={"memberShipStartDate"} label={t("Membership Start Date")} placeholder={t("Membership Start Date")} />
      </Grid>

      <Grid item xs={4}>
        <FormDatePickerField
          name={"memberShipEndDate"}
          label={t("Membership End Date")}
          placeholder={t("Membership End Date")}
          datePickerProps={{
            disablePast: true,
            minDate: moment(memberShipStartDate).add(1, "d"),
          }}
        />
      </Grid>

      <Grid item xs={4}>
        <FormTextField name={"insuranceId"} label={t("Insurance Id")} placeholder={t("Insurance Id")} />
      </Grid>

      <Grid item xs={4}>
        <FormTextField name={"insurancePolicyNumber"} label={t("Insurance Policy Number")} placeholder={t("Insurance Policy Number")} />
      </Grid>
    </Grid>
  );
};
