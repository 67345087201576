var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React from "react";
import { useHealthProgramBenefitsGroupsAutocompleteQuery } from "./gql";
export const HealthProgramBenefitsGroupsAutocomplete = props => {
    const { label, skip } = props, rest = __rest(props, ["label", "skip"]);
    const { t } = useTranslation("domains");
    const { data, loading } = useHealthProgramBenefitsGroupsAutocompleteQuery({
        skip: props.disabled || skip,
    });
    const _data = data === null || data === void 0 ? void 0 : data.healthProgramBenefitGroups;
    const options = mapToAutocompleteOptions(_data, "id", "name");
    return (React.createElement(FormAutocomplete, Object.assign({}, rest, { loading: loading, options: options, label: label || (props.multiple ? t("Benefits Groups") : t("Benefit Group")) })));
};
