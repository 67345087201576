import { VendorType } from "@/schema/types";
import { addSpacesBetweenWords, combineErrors } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { ErrorsInputProps } from "../types";

type TypeProps = ErrorsInputProps & {
  type: string;
};

export const TypesControl: FC<TypeProps> = props => {
  const { t } = useTranslation("admin");
  const { type, errorsInput } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();
  const types = Object.keys(VendorType).map(key => ({ value: VendorType[key], name: addSpacesBetweenWords(key) }));

  const formErrors = combineErrors(errors, errorsInput);

  return (
    <AutocompleteController
      ControllerProps={{
        name: "type",
        control: control,
        defaultValue: types?.find(el => el.value === type) || null,
      }}
      TextFieldProps={{
        placeholder: t("Type"),
        error: Boolean(formErrors.type?.message),
        helperText: formErrors.type?.message,
      }}
      getOptionLabel={option => t(option?.name)}
      options={types || []}
      defaultValue={types?.find(el => el.value === type) || null}
    />
  );
};
