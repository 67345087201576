import { Survey } from "@/schema/types";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps } from "@toolkit/ui";
import { useMemo } from "react";

export const useFiltersFields = (): CustomTableColumnProps<Partial<Survey>>[] => {
  const { t } = useTranslation("admin");

  return useMemo(() => {
    return [
      {
        key: "label",
        header: t("Label"),
        showOnlyForFilter: true,
        filter: {
          type: "string",
          name: "label",
          label: t("Label"),
        },
      },
      {
        key: "status",
        header: t("Status"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("SurveyStatus", "status"),
      },
    ];
  }, []);
};
