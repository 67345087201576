import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateUserMutationVariables = Types.Exact<{
  input: Types.AdminUserCreateInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', userCreate?: { __typename?: 'UserCreate', accountErrors: Array<{ __typename?: 'AccountError', code: Types.AccountErrorCode, field?: string | null, message?: string | null }>, user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, appRole?: Types.AppRoleTypes | null, nationalId?: string | null, appType?: Types.AppTypes | null, email?: string | null, mobile?: string | null, isActive: boolean, doctor?: { __typename?: 'Doctor', specializations?: Array<{ __typename?: 'DoctorSpecialization', code: string, display?: string | null, id: string } | null> | null } | null } | null } | null };


export const CreateUserDocument = gql`
    mutation createUser($input: AdminUserCreateInput!) {
  userCreate(input: $input) {
    accountErrors {
      code
      field
      message
    }
    user {
      id
      firstName
      lastName
      appRole
      nationalId
      appType
      email
      mobile
      isActive
      doctor {
        specializations {
          code
          display
          id
        }
      }
    }
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;