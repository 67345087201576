import { i18n } from "@toolkit/i18n";
import { VisitDiagnosisType } from "../../schema/types";
export const visitDiagnosisTypeOptionsMap = {
    [VisitDiagnosisType.Principal]: {
        key: VisitDiagnosisType.Principal,
        get label() {
            return i18n.t("Principal", { ns: "domains" });
        },
        value: VisitDiagnosisType.Principal,
    },
    [VisitDiagnosisType.Secondary]: {
        key: VisitDiagnosisType.Secondary,
        get label() {
            return i18n.t("Secondary", { ns: "domains" });
        },
        value: VisitDiagnosisType.Secondary,
    },
};
export const visitDiagnosisTypeOptions = Object.values(visitDiagnosisTypeOptionsMap);
