import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const OptimaPriorRequestKpIsTotalCountsDocument = gql `
    query OptimaPriorRequestKPIsTotalCounts($transactionDateInput: TransactionDateFilterInput, $filter: TransactionPriorRequestFilterInput) {
  optimaPriorRequestsRejectedAuth(transactionDateInput: $transactionDateInput) {
    totalCount
  }
  optimaPriorRequestsWithoutClaim(transactionDateInput: $transactionDateInput) {
    totalCount
  }
  optimaPriorRequests(filter: $filter) {
    totalCount
  }
}
    `;
/**
 * __useOptimaPriorRequestKpIsTotalCountsQuery__
 *
 * To run a query within a React component, call `useOptimaPriorRequestKpIsTotalCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptimaPriorRequestKpIsTotalCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptimaPriorRequestKpIsTotalCountsQuery({
 *   variables: {
 *      transactionDateInput: // value for 'transactionDateInput'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useOptimaPriorRequestKpIsTotalCountsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(OptimaPriorRequestKpIsTotalCountsDocument, options);
}
export function useOptimaPriorRequestKpIsTotalCountsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(OptimaPriorRequestKpIsTotalCountsDocument, options);
}
export function useOptimaPriorRequestKpIsTotalCountsSuspenseQuery(baseOptions) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(OptimaPriorRequestKpIsTotalCountsDocument, options);
}
