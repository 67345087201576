var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useRelayStyleConnection } from "@toolkit/apollo";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React from "react";
import { useHealthProgramNetworksProvidersAutocompleteQuery } from "./gql";
export const HealthProgramNetworksProvidersAutocomplete = props => {
    const { label, filter, skip } = props, rest = __rest(props, ["label", "filter", "skip"]);
    const { t } = useTranslation("domains");
    const _a = useRelayStyleConnection({
        useQuery: useHealthProgramNetworksProvidersAutocompleteQuery,
        variables: { filter },
        skip: props.disabled || skip,
    }), { data } = _a, restRelayStyleConnection = __rest(_a, ["data"]);
    const options = mapToAutocompleteOptions(data, "id", item => { var _a, _b; return pickLocalizedValue((_a = item === null || item === void 0 ? void 0 : item.provider) === null || _a === void 0 ? void 0 : _a.name, (_b = item === null || item === void 0 ? void 0 : item.provider) === null || _b === void 0 ? void 0 : _b.nameAr); });
    return (React.createElement(FormAutocomplete, Object.assign({}, rest, restRelayStyleConnection, { options: options, label: label || (props.multiple ? t("Networks Providers") : t("Network Provider")) })));
};
