import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const GetSubListsDocument = gql `
    query getSubLists($first: Int, $after: String, $before: String, $last: Int, $sortBy: SubListSortingInput, $filter: SublistFilterInput) {
  subLists: getSubLists(
    first: $first
    after: $after
    before: $before
    last: $last
    sortBy: $sortBy
    filter: $filter
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    totalCount
    edges {
      node {
        display
        id
        field {
          id
          display
          code
        }
      }
    }
  }
}
    `;
/**
 * __useGetSubListsQuery__
 *
 * To run a query within a React component, call `useGetSubListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubListsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      sortBy: // value for 'sortBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetSubListsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetSubListsDocument, options);
}
export function useGetSubListsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetSubListsDocument, options);
}
export function useGetSubListsSuspenseQuery(baseOptions) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(GetSubListsDocument, options);
}
