import { User } from "@/schema/types";
import { formGirdBreakPoints, formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, FormControlLabel, Grid } from "@toolkit/ui";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

export const DoctorInformationButtonGroup: FC<{ defaultValues?: User | undefined; isEdit: boolean }> = ({ defaultValues, isEdit }) => {
  const { control } = useFormContext();
  const { t } = useTranslation("admin");
  return (
    <Grid container columnSpacing={formGirdSpacing} item>
      <Grid item {...formGirdBreakPoints}>
        <Controller
          name='doctorInfo.isLanguagesPublic'
          defaultValue={isEdit ? defaultValues?.doctor?.isLanguagesPublic : true}
          control={control}
          render={({ field: { onChange: handleC, value } }) => (
            <FormControlLabel
              control={<CustomToggleButton onChange={e => handleC(e.target.checked)} checked={value} />}
              label={t("Is Languages Public")}
            />
          )}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <Controller
          name='doctorInfo.isYearsOfExperiencePublic'
          defaultValue={isEdit ? defaultValues?.doctor?.isYearsOfExperiencePublic : true}
          control={control}
          render={({ field: { onChange: handleC, value } }) => (
            <FormControlLabel
              control={<CustomToggleButton onChange={e => handleC(e.target.checked)} checked={value} />}
              label={t("Is Years Of Experience Public")}
            />
          )}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <Controller
          name='doctorInfo.isSecondMobileNumberPublic'
          defaultValue={defaultValues?.doctor?.isSecondMobileNumberPublic ?? true}
          control={control}
          render={({ field: { onChange: handleC, value } }) => (
            <FormControlLabel
              control={<CustomToggleButton onChange={e => handleC(e.target.checked)} checked={value} />}
              label={t("Is Second Number Public")}
            />
          )}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <Controller
          name='doctorInfo.isDateOfBirthPublic'
          defaultValue={isEdit ? defaultValues?.doctor?.isDateOfBirthPublic : true}
          control={control}
          render={({ field: { onChange: handleC, value } }) => (
            <FormControlLabel
              control={<CustomToggleButton onChange={e => handleC(e.target.checked)} checked={value} />}
              label={t("Is Date Of Birth Public")}
            />
          )}
        />
      </Grid>
      <Grid item {...formGirdBreakPoints}>
        <Controller
          name='doctorInfo.isSocialLinksPublic'
          defaultValue={isEdit ? defaultValues?.doctor?.isSocialLinksPublic : true}
          control={control}
          render={({ field: { onChange: handleC, value } }) => (
            <FormControlLabel
              control={<CustomToggleButton onChange={e => handleC(e.target.checked)} checked={value} />}
              label={t("Is Links Public")}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
