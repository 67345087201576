import { i18n } from "@toolkit/i18n";
import { SurveyStatus } from "../../schema/types";
export const surveyStatusOptionsMap = {
    [SurveyStatus.Cancelled]: {
        key: SurveyStatus.Cancelled,
        get label() {
            return i18n.t("Cancelled", { ns: "domains" });
        },
        value: SurveyStatus.Cancelled,
    },
    [SurveyStatus.Draft]: {
        key: SurveyStatus.Draft,
        get label() {
            return i18n.t("Draft", { ns: "domains" });
        },
        value: SurveyStatus.Draft,
    },
    [SurveyStatus.Expired]: {
        key: SurveyStatus.Expired,
        get label() {
            return i18n.t("Expired", { ns: "domains" });
        },
        value: SurveyStatus.Expired,
    },
    [SurveyStatus.Published]: {
        key: SurveyStatus.Published,
        get label() {
            return i18n.t("Published", { ns: "domains" });
        },
        value: SurveyStatus.Published,
    },
    [SurveyStatus.Unpublished]: {
        key: SurveyStatus.Unpublished,
        get label() {
            return i18n.t("Unpublished", { ns: "domains" });
        },
        value: SurveyStatus.Unpublished,
    },
};
export const surveyStatusOptions = Object.values(surveyStatusOptionsMap);
