import { i18n } from "@toolkit/i18n";
import { UserGender } from "../../schema/types";
export const userGenderOptionsMap = {
    [UserGender.Male]: {
        key: UserGender.Male,
        get label() {
            return i18n.t("Male", { ns: "domains" });
        },
        value: UserGender.Male,
    },
    [UserGender.Female]: {
        key: UserGender.Female,
        get label() {
            return i18n.t("Female", { ns: "domains" });
        },
        value: UserGender.Female,
    },
};
export const userGenderOptions = Object.values(userGenderOptionsMap);
