import { InputMaybe, TimeRangeInput, WorkingHourInput } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { WorkingDay, getDefaultWeekWorkingDays, getWeekWorkingDays } from "@toolkit/ui";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useGetTimeZoneQuery } from "../../../pages/UserManagement/gql";
import { getWorkingHoursWithTimeZone, requestWorkingHoursWithTimeZone } from "./utils";
export const useWorkingHoursHook = ({ workingHoursDefaultValue, inputName = "", isRequired }) => {
  const [workingHours, setWorkingHours] = useState<WorkingDay[]>(getDefaultWeekWorkingDays());
  const {
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();
  const { data: setting } = useGetTimeZoneQuery();
  const timeZone = setting?.siteSettings?.timeZone! || 3;

  const onSetWorkingHours = val => {
    let isNotEmpty = false;
    const workingHoursValues = val?.map((item: WorkingHourInput) => ({
      day: item.day,
      openTimeRanges: item.openTimeRanges.map((data: InputMaybe<TimeRangeInput>) => {
        isNotEmpty = isNotEmpty || (data?.closeTime && data?.openTime);
        return {
          closeTime: data?.closeTime ? data?.closeTime : null,
          openTime: data?.openTime ? data?.openTime : null,
        };
      }),
    }));
    if (isRequired && !isNotEmpty) {
      setError(inputName, { message: t("Required"), type: "required" });
    } else if (isRequired && get(errors, inputName)) {
      clearErrors(inputName);
    }
    const requestedWorkingHours = requestWorkingHoursWithTimeZone(workingHoursValues, timeZone);
    setValue(inputName, requestedWorkingHours);
    setWorkingHours(val);
  };

  const onError = (isError: boolean | null) => {
    if (isError) {
      setError(inputName, { message: t("Please check input errors on some fields."), type: "validate" });
    } else {
      if (errors?.[inputName]?.type !== "required") clearErrors(inputName);
    }
  };

  useEffect(() => {
    const initialWorkHours = (workingHoursDefaultValue && getWeekWorkingDays(workingHoursDefaultValue)) || getDefaultWeekWorkingDays();
    const getWorkingHoursWithTimeZoneData = getWorkingHoursWithTimeZone(initialWorkHours, timeZone);
    onSetWorkingHours(getWorkingHoursWithTimeZoneData);
  }, [workingHoursDefaultValue]);

  return {
    workingHours,
    timeZone,
    onSetWorkingHours,
    onError,
  };
};
