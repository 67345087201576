import { SurveyStatus } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import {
  surveysFieldBreadcrumb,
  surveysListBreadcrumb,
  surveysNewBreadcrumb,
  surveysTitleBreadcrumb,
} from "pages/Surveys/constants/surveys-breadcrumbs";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SurveyDetailsTab } from "../components/SurveyDetailsHeader/types";
import { useSurveyDetailsContext } from "../state/context";

export const useSurveyDetailsPage = () => {
  const [selectedTab, setSelectedTab] = useState<SurveyDetailsTab>("Questions");
  const { t } = useTranslation("admin");
  const { survey } = useSurveyDetailsContext();
  const { setBreadCrumb } = useBreadCrumbs();
  const { surveyId } = useParams();
  const isNewSurvey = surveyId == "new";

  const handleChangeTab = (tab: SurveyDetailsTab) => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    setBreadCrumb({
      title: surveysTitleBreadcrumb(t),
      values: [surveysListBreadcrumb(t), isNewSurvey ? surveysNewBreadcrumb(t) : surveysFieldBreadcrumb(survey?.label!)],
    });
  }, [isNewSurvey, survey?.label]);

  return {
    answeredParticipantsCount: survey?.answeredParticipantsCount,
    showResponsesTab:
      survey?.status === SurveyStatus.Published || survey?.status === SurveyStatus.Unpublished || survey?.status === SurveyStatus.Expired,
    selectedTab,
    handleChangeTab,
  };
};
