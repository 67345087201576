import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { transactionStatusTypeOptionsMap } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { ValueWithLabel } from "@toolkit/ui";
import { getOptimaAuthorizationStatusColor } from "../../../../utils";
import { usePriorAuthorizationDrawerStyles } from "../PriorAuthorizationDrawer/PriorAuthorizationDrawerStyle";
import { toTitleCase } from "@toolkit/core";
export const PriorAuthorizationDrawerSummary = props => {
    var _a, _b;
    const { request } = props;
    const { priorAuthorization } = request;
    const { classes, theme } = usePriorAuthorizationDrawerStyles();
    const { t } = useTranslation("domains");
    return (_jsxs(_Fragment, { children: [_jsx(ValueWithLabel, { label: t("Authorization Status"), value: (priorAuthorization === null || priorAuthorization === void 0 ? void 0 : priorAuthorization.status) ? transactionStatusTypeOptionsMap[priorAuthorization === null || priorAuthorization === void 0 ? void 0 : priorAuthorization.status].label : "-", labelProps: {
                    className: classes.label,
                }, valueProps: {
                    className: classes.value,
                    color: getOptimaAuthorizationStatusColor((priorAuthorization === null || priorAuthorization === void 0 ? void 0 : priorAuthorization.status) || "", theme),
                } }), _jsx(ValueWithLabel, { labelProps: {
                    className: classes.label,
                }, valueProps: {
                    className: classes.value,
                }, label: t("Authorization ID"), value: priorAuthorization === null || priorAuthorization === void 0 ? void 0 : priorAuthorization.id }), _jsx(ValueWithLabel, { labelProps: {
                    className: classes.label,
                }, valueProps: {
                    className: classes.value,
                }, label: t("Member ID"), value: (request === null || request === void 0 ? void 0 : request.memberId) || "-" }), _jsx(ValueWithLabel, { labelProps: {
                    className: classes.label,
                }, valueProps: {
                    className: classes.value,
                }, label: t("Patient ID"), value: (request === null || request === void 0 ? void 0 : request.patientId) || "-" }), _jsx(ValueWithLabel, { labelProps: {
                    className: classes.label,
                }, valueProps: {
                    className: classes.value,
                }, label: t("Service Type"), value: ((_a = request === null || request === void 0 ? void 0 : request.encounterTypeLookup) === null || _a === void 0 ? void 0 : _a.name) || (request === null || request === void 0 ? void 0 : request.encounterType) || "-" }), _jsx(ValueWithLabel, { labelProps: {
                    className: classes.label,
                }, valueProps: {
                    className: classes.value,
                }, label: t("Payer"), value: toTitleCase(((_b = request === null || request === void 0 ? void 0 : request.payer) === null || _b === void 0 ? void 0 : _b.name) || "-") || "-" })] }));
};
