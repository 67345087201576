/* eslint-disable max-statements */
import { AdminUserCreateInput, AppRoleTypes, AppTypes, UserUpdateInput, VendorUserTypes } from "@/schema/types";
import { BACKEND_DATE_FORMAT, inputCleanUp } from "@toolkit/core";
import { camelCase, merge } from "lodash";
import moment from "moment";
import { getDoctorInfo } from "../UserManagement.constant";

export const userFormMap = input => {
  const vendorUserType = input?.vendorUserType?.value;
  const vendorUserTypeHealthLicense = camelCase(vendorUserType);
  const includeHealthLicenseInfo = ![VendorUserTypes.Receptionist, VendorUserTypes.Manager].includes(vendorUserType);
  const vendorUserTypeHealthLicenseInformation = includeHealthLicenseInfo
    ? {
        healthLicenseNumber: input?.healthLicenseNumber || null,
        healthLicenseStartDate: input?.healthLicenseStartDate ? moment(input?.healthLicenseStartDate).format(BACKEND_DATE_FORMAT) : null,
        healthLicenseEndDate: input?.healthLicenseEndDate ? moment(input?.healthLicenseEndDate).format(BACKEND_DATE_FORMAT) : null,
      }
    : null;
  delete input?.healthLicenseNumber;
  delete input?.healthLicenseStartDate;
  delete input?.healthLicenseEndDate;
  if (vendorUserType !== VendorUserTypes.Doctor) {
    delete input.doctorInfo;
  }
  if (input.appRole?.value === AppRoleTypes.Admin) {
    delete input.branches;
  }
  const requestInput = {
    [`${vendorUserTypeHealthLicense}Info`]: vendorUserTypeHealthLicenseInformation,
    doctorInfo:
      vendorUserType === VendorUserTypes.Doctor
        ? merge({}, getDoctorInfo(input?.doctorInfo), vendorUserTypeHealthLicenseInformation)
        : null,
    appRole: input?.appRole?.value,
    branches: input?.branches?.map(branch => branch?.value?.id) ?? undefined,
    defaultBranch: input?.defaultBranch?.value?.id,
  };
  if (!includeHealthLicenseInfo) {
    delete requestInput[`${vendorUserTypeHealthLicense}Info`];
  }
  inputCleanUp(requestInput);
  return requestInput;
};

export const getUserCreateInfo = (input: AdminUserCreateInput | any) => {
  const vendorUserType = input?.vendorUserType?.value;
  if (input.vendorUserType?.value !== VendorUserTypes.Doctor) {
    delete input.doctorInfo;
  }

  return {
    ...input,
    ...userFormMap(input),
    appType: AppTypes.Vendor,
    gender: input?.gender?.value,
    vendor: input?.vendor?.value?.id,
    vendorUserType: vendorUserType,
  };
};

export const getUserUpdateInfo = (input: UserUpdateInput | any) => {
  delete input?.vendor;
  delete input?.appType;

  return {
    ...input,
    ...userFormMap(input),
    gender: input?.gender?.value,
  };
};
