import { BranchInput } from "@/schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { FIELD_IS_REQUIRED_MSG } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, TextField, useAddToast } from "@toolkit/ui";
import { FC, useState } from "react";
import { useGetCodeSystemConceptsLazyQuery, useGetCodeSystemDefinitionsSummeryQuery } from "../gql";
import { convertProviderCentralListFieldIntoFormFields, getCentralListPropValue } from "./utils";

export const SearchByLicenseID: FC<{
  onLoading: (isLoading: boolean) => void;
  onFillMappedDataToForm: (mappedValues: BranchInput) => void;
}> = ({ onLoading, onFillMappedDataToForm }) => {
  const { t } = useTranslation("admin");
  const { failed } = useAddToast();
  const [error, setError] = useState("");
  const [value, setValue] = useState("");

  // To Do , see if there is  a single api that takes codeSystemCode HEALTH_PROVIDER, and searches for a value in the lates published version

  const { data: codeSystemDefinitionsSummeryData, loading } = useGetCodeSystemDefinitionsSummeryQuery({
    variables: {
      name: "HEALTH_PROVIDER",
    },
  });
  const [fetchCodeSystem, { loading: isLoading }] = useGetCodeSystemConceptsLazyQuery();

  const handleSearchClicked = () => {
    const licenseId = value;
    if (!licenseId) {
      setError(t(FIELD_IS_REQUIRED_MSG));
      return;
    }
    onLoading(true);
    fetchCodeSystem({
      variables: {
        first: 1,
        codeSystemId: codeSystemDefinitionsSummeryData?.getCodeSystemDefinitionsSummery?.[0]?.activeCodeSystem?.id || "",
        searchParameters: [
          {
            code: "code",
            valueString: licenseId,
            type: "STRING" as any,
          },
        ],
      },
    })
      .then(({ data }) => {
        const vendor = data?.getCodeSystemConcepts?.edges?.[0]?.node;
        if (!vendor) {
          failed(t("Provider With License ID {{ licenseId }} is Not Found", { licenseId }));
          return;
        }
        const foundProviderProps =
          vendor?.properties?.reduce((res, item) => {
            return {
              ...res,
              [item!.code!]: getCentralListPropValue(item!),
            };
          }, {}) ?? {};
        const mappedValues = convertProviderCentralListFieldIntoFormFields(foundProviderProps as any);
        onFillMappedDataToForm(mappedValues);
      })
      .catch(e => {
        failed(formatGraphQLError(e));
      })
      .finally(() => {
        onLoading(false);
      });
  };

  const handleChangeCode = e => {
    const v = e.target.value;
    if (/^\s*$/.test(v) && v.length) setError(t("Enter a valid value"));
    else if (error) setError("");
    setValue(v);
  };

  return (
    <>
      <TextField
        fullWidth
        type='text'
        inputProps={{
          maxLength: 20,
        }}
        value={value}
        onChange={handleChangeCode}
        size='small'
        label={t("License Id")}
        error={!!error}
        helperText={error}
      />
      <Button onClick={handleSearchClicked} sx={{ marginTop: "16px" }} disabled={loading || isLoading || !value || !!error}>
        {isLoading ? t("Loading") : t("Search")}
      </Button>
    </>
  );
};
