import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const ListFieldsAutocompleteDocument = gql `
    query ListFieldsAutocomplete {
  getAllListFields {
    id
    code
    display
  }
}
    `;
/**
 * __useListFieldsAutocompleteQuery__
 *
 * To run a query within a React component, call `useListFieldsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFieldsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFieldsAutocompleteQuery({
 *   variables: {
 *   },
 * });
 */
export function useListFieldsAutocompleteQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ListFieldsAutocompleteDocument, options);
}
export function useListFieldsAutocompleteLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ListFieldsAutocompleteDocument, options);
}
export function useListFieldsAutocompleteSuspenseQuery(baseOptions) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ListFieldsAutocompleteDocument, options);
}
