import { Group } from "@/schema/types";
import { combineErrors, formGirdBreakPoints, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormCard, Grid, TextField } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";

export const PermissionGroupInformation: FC<{ defaultValues?: Group; doYouHaveData: boolean | any; errors; isLoading }> = ({
  defaultValues,
  isLoading,
  errors,
  doYouHaveData,
}) => {
  const { t } = useTranslation("admin");
  const {
    register,
    formState: { errors: formErrors },
    setValue,
  } = useFormContext();
  const combinedErrors = combineErrors(formErrors, errors);

  useEffect(() => {
    setValue("rightChecked", []);
  }, []);
  return (
    <FormCard loading={isLoading} title={t("Permission Group Information")} doYouHaveData={doYouHaveData}>
      <Grid container spacing={2}>
        <Grid item {...formGirdBreakPoints}>
          <TextField
            label={t("Name")}
            fullWidth
            placeholder={t("Name")}
            data-testId={"permissionName"}
            defaultValue={defaultValues?.name}
            {...register("name", {
              required: getRequiredValidation(t, !defaultValues?.name),
            })}
            disabled={!!defaultValues?.name}
            helperText={t(combinedErrors?.name?.message)}
            error={Boolean(combinedErrors?.name?.message)}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};
PermissionGroupInformation.defaultProps = {
  defaultValues: undefined,
};
