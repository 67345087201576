import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { formGirdBreakPoints, formGirdSpacing, getMaxLengthValidation, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, DownloadImportActions, FormActions, FormCard, Grid, PageWrapper, TextField } from "@toolkit/ui";
import { SublistItemList } from "../../../Sublists/components";
import { FieldsAutocomplete, SublistItemCreateForm } from "../../../Sublists/forms";
import { FormProvider } from "react-hook-form";
import { useParams } from "react-router-dom";
import { UploadDialog } from "../../../../shared/components";
import { useSublistUpsertForm } from "./useSublistUpsertForm";
export const SublistUpsertForm = props => {
    var _a, _b, _c, _d, _e, _f;
    const { onEditItemRowClick } = props;
    const { t } = useTranslation("domains");
    const { id } = useParams();
    const { methods, errors, defaults, isSubmitting, isDataLoading, importData, isSubmittingList, isUploadDialogOpened, handleCancel, handleSubmit, handleOnUpload, handleOpenImport, handleDownloadClicked, handleOnCloseImportDialog, } = useSublistUpsertForm({ id: id });
    return (_jsx(PageWrapper, { actions: _jsxs(Box, { justifyContent: 'flex-end', display: 'flex', children: [_jsx(DownloadImportActions, { hasDownload: !!id, hasImport: !!id, onImportClicked: handleOpenImport, onDownloadClicked: handleDownloadClicked }), _jsx(UploadDialog, { isDialogLoading: isSubmittingList, isOpen: isUploadDialogOpened, onFileUploadedSuccessfully: handleOnUpload, handleClose: handleOnCloseImportDialog, hasErrors: Boolean((_b = (_a = importData === null || importData === void 0 ? void 0 : importData.uploadSubList) === null || _a === void 0 ? void 0 : _a.errors) === null || _b === void 0 ? void 0 : _b.length), errorMessage: (_d = (_c = importData === null || importData === void 0 ? void 0 : importData.uploadSubList) === null || _c === void 0 ? void 0 : _c.errors) === null || _d === void 0 ? void 0 : _d.map(e => `${e === null || e === void 0 ? void 0 : e.field} : ${e === null || e === void 0 ? void 0 : e.message}`).join(", "), title: t("Upload sublist"), subTitle: t("Upload subList you want to get") }), _jsx(FormActions, { hasSave: true, hasCancel: true, formButtonTitle: t("Save"), onNavigation: handleCancel, onSave: handleSubmit, loadingIndicators: { save: isSubmitting }, isSaveDisabled: isSubmitting })] }), children: _jsxs(Grid, { container: true, direction: 'column', rowSpacing: 2, children: [_jsx(Grid, { item: true, children: _jsx(FormProvider, Object.assign({}, methods, { children: _jsx(FormCard, { loading: isDataLoading, title: t("Sublist"), doYouHaveData: !id || Boolean(defaults), children: _jsx("form", { onSubmit: handleSubmit, children: _jsxs(Grid, { container: true, spacing: formGirdSpacing, children: [_jsx(Grid, Object.assign({ item: true }, formGirdBreakPoints, { children: _jsx(TextField, Object.assign({ label: t("Display name"), fullWidth: true, defaultValue: defaults === null || defaults === void 0 ? void 0 : defaults.display }, methods.register("display", {
                                                required: getRequiredValidation(t, true),
                                                maxLength: getMaxLengthValidation(t, 255),
                                                validate: value => (value === null || value === void 0 ? void 0 : value.trim().length) > 0 || t("Display name is required"),
                                            }), { error: Boolean((_e = errors === null || errors === void 0 ? void 0 : errors.display) === null || _e === void 0 ? void 0 : _e.message), helperText: (_f = errors === null || errors === void 0 ? void 0 : errors.display) === null || _f === void 0 ? void 0 : _f.message })) })), _jsx(Grid, Object.assign({ item: true }, formGirdBreakPoints, { children: _jsx(FieldsAutocomplete, { defaultValue: defaults === null || defaults === void 0 ? void 0 : defaults.field }) }))] }) }) }) })) }), Boolean(id) && (_jsxs(_Fragment, { children: [_jsx(Grid, { item: true, children: _jsx(SublistItemCreateForm, { id: id }) }), _jsx(Grid, { item: true, children: _jsx(SublistItemList, { id: id, onEditItemRowClick: onEditItemRowClick }) })] }))] }) }));
};
