import { i18n } from "@toolkit/i18n";
import { DestinationType } from "../../schema/types";
export const destinationTypeOptionsMap = {
    [DestinationType.Eclaim]: {
        key: DestinationType.Eclaim,
        get label() {
            return i18n.t("Eclaim", { ns: "domains" });
        },
        value: DestinationType.Eclaim,
    },
};
export const destinationTypeOptions = Object.values(destinationTypeOptionsMap);
