import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { useCustomerSupportErxOrderStatusUpdateMutation } from "../../../../OrdersTracking/gql/mutations/__generated__";
import { useOpenState } from "@toolkit/core";
export const useHandleOrder = (reset, id) => {
    const { t } = useTranslation("domains");
    const { succeeded, failed } = useAddToast();
    const { open: isOpen, handleOpen: handleOpenState, handleClose, handleToggle } = useOpenState();
    const [handelOrder, { loading }] = useCustomerSupportErxOrderStatusUpdateMutation({
        onCompleted: () => {
            succeeded(t("Order handled successfully"));
            handleClose();
            reset();
        },
        onError: ({ graphQLErrors }) => {
            failed(t(formatGraphQLError(graphQLErrors)));
        },
    });
    const handleOpen = e => {
        e.stopPropagation();
        handleOpenState();
    };
    const handleUpdate = inputData => {
        var _a;
        handelOrder({
            variables: {
                customerSupportErxOrderStatusUpdateId: id,
                status: (_a = inputData === null || inputData === void 0 ? void 0 : inputData.status) === null || _a === void 0 ? void 0 : _a.value,
            },
        });
    };
    return { handleUpdate, loading, handleToggleDialog: handleToggle, handleOpen, isOpen };
};
