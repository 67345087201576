import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable react/jsx-handler-names */
import { FilterGrid, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { useGetMarketplaceOrdersQuery } from "../../../OrdersTracking/gql/queries";
import { GridProvider } from "../../../../shared/components";
import { useMarketplaceOrdersTrackingColumns } from "./useMarketplaceOrdersTrackingColumns";
export const MarketplaceOrdersTrackingPage = props => {
    const { isAdmin = false } = props;
    return (_jsx(GridProvider, { gridName: "marketplaceOrdersTracking", variables: {
            isAdmin,
        }, fetchPolicy: "network-only", nextFetchPolicy: "cache-first", columns: useMarketplaceOrdersTrackingColumns({ isAdmin }), query: useGetMarketplaceOrdersQuery, hasTableSetting: true, children: _jsx(PageWrapper, { filters: _jsx(FilterGrid, {}), actions: _jsx(TableSettingComponent, {}), children: _jsx(TableGrid, {}) }) }));
};
