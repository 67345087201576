import { AccountError, AppRoleTypes } from "@/schema/types";
import { BranchesAutocomplete, VendorsAutocomplete } from "@health/autocompletes";
import { formGirdBreakPoints, formGirdSpacing, getRequiredValidation } from "@toolkit/core";
import { AutocompleteController, FormCard, Grid } from "@toolkit/ui";
import { FC } from "react";
import { Branches } from "./Branches.component";
import { useProviderInformationHook } from "./ProviderInformation.hook";

export const ProviderInformation: FC<{ errors: AccountError[]; defaultValues; doYouHaveUser; isLoading }> = ({
  errors,
  defaultValues,
  doYouHaveUser,
  isLoading,
}) => {
  const { combinedErrors, control, t, hasVendor, vendorsValue, role, formBranchesValues } = useProviderInformationHook(
    errors,
    defaultValues
  );
  return (
    <>
      <FormCard doYouHaveData={doYouHaveUser} loading={isLoading} title={t("Health provider information")}>
        <Grid container spacing={formGirdSpacing}>
          <Grid item {...formGirdBreakPoints}>
            <VendorsAutocomplete name={"vendor"} label={t("Vendor")} disabled={!!defaultValues} />
          </Grid>
          {(vendorsValue || hasVendor) && (
            <>
              {role?.value && role?.value !== AppRoleTypes.Admin ? (
                <>
                  <Grid item {...formGirdBreakPoints}>
                    <BranchesAutocomplete
                      name={"branches"}
                      multiple
                      filter={{
                        vendors: vendorsValue?.value?.id ? [vendorsValue?.value?.id] : null,
                      }}
                      skip={!vendorsValue?.value?.id}
                    />
                  </Grid>
                  <Grid item {...formGirdBreakPoints}>
                    <AutocompleteController
                      ControllerProps={{
                        name: "defaultBranch",
                        control: control,
                        rules: {
                          required: getRequiredValidation(t, true),
                        },
                      }}
                      TextFieldProps={{
                        error: Boolean(combinedErrors?.defaultBranch?.message),
                        helperText: combinedErrors?.defaultBranch?.message,
                        label: t("Default Branch"),
                      }}
                      disabled={!formBranchesValues?.length}
                      getOptionLabel={option => option?.label}
                      options={formBranchesValues || []}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item {...formGirdBreakPoints}>
                  <Branches vendorValue={vendorsValue} errors={errors} />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </FormCard>
    </>
  );
};
