import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { Button, CircularProgress, CustomDialog } from "@toolkit/ui";
import { useOpenState } from "@toolkit/core";
import { useEffect, useRef, useState } from "react";
import { OrderCancelForm } from "../../forms/OrderCancel/OrderCancelForm";
export const OrderCancelModalApi = {
    open: () => { },
    close: () => { },
};
export const OrderCancelModal = props => {
    const { isLoading, onChange } = props;
    const [data, setData] = useState();
    const { t } = useTranslation("domains");
    const { open: isOpen, handleOpen, handleClose } = useOpenState();
    const orderCancelFormRef = useRef(null);
    const onCancelClick = () => {
        var _a;
        (_a = orderCancelFormRef === null || orderCancelFormRef === void 0 ? void 0 : orderCancelFormRef.current) === null || _a === void 0 ? void 0 : _a.submit();
    };
    const open = _data => {
        handleOpen();
        setData(_data);
    };
    useEffect(() => {
        OrderCancelModalApi.open = open;
        OrderCancelModalApi.close = handleClose;
    }, []);
    return (_jsx(CustomDialog, { type: "base", open: isOpen, DialogTitleProps: {
            title: t("Order Cancel"),
            onClose: close,
        }, DialogActionsProps: {
            children: (_jsx(Button, { color: "error", disabled: isLoading, endIcon: isLoading ? _jsx(CircularProgress, { color: "inherit", size: 20 }) : null, onClick: onCancelClick, children: t("Cancel") })),
        }, children: _jsx(OrderCancelForm, { id: data === null || data === void 0 ? void 0 : data.id, ref: orderCancelFormRef, onChange: onChange }) }));
};
