import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const DecisionConditionCitiesAutocompleteDocument = gql `
    query DecisionConditionCitiesAutocomplete($first: Int, $last: Int, $after: String, $before: String, $filter: CityFilterInput) {
  cities(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        name
        nameAr
      }
    }
  }
}
    `;
/**
 * __useDecisionConditionCitiesAutocompleteQuery__
 *
 * To run a query within a React component, call `useDecisionConditionCitiesAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useDecisionConditionCitiesAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDecisionConditionCitiesAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDecisionConditionCitiesAutocompleteQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DecisionConditionCitiesAutocompleteDocument, options);
}
export function useDecisionConditionCitiesAutocompleteLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DecisionConditionCitiesAutocompleteDocument, options);
}
export function useDecisionConditionCitiesAutocompleteSuspenseQuery(baseOptions) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(DecisionConditionCitiesAutocompleteDocument, options);
}
