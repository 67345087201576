import { createOptimaActionAutocompleteOption } from "@health/autocompletes";
import { optimaPriorityOptionsMap } from "@health/enum-options";
export const convertOptimaEditToFormValues = (optimaEdit) => {
    var _a, _b;
    return {
        code: optimaEdit === null || optimaEdit === void 0 ? void 0 : optimaEdit.code,
        category: optimaEdit === null || optimaEdit === void 0 ? void 0 : optimaEdit.category,
        subCategory: optimaEdit === null || optimaEdit === void 0 ? void 0 : optimaEdit.subCategory,
        source: optimaEdit === null || optimaEdit === void 0 ? void 0 : optimaEdit.source,
        priority: optimaPriorityOptionsMap[optimaEdit === null || optimaEdit === void 0 ? void 0 : optimaEdit.priority],
        details: optimaEdit === null || optimaEdit === void 0 ? void 0 : optimaEdit.details,
        message: optimaEdit === null || optimaEdit === void 0 ? void 0 : optimaEdit.message,
        actions: ((_a = optimaEdit === null || optimaEdit === void 0 ? void 0 : optimaEdit.actions) === null || _a === void 0 ? void 0 : _a.length) ? (_b = optimaEdit === null || optimaEdit === void 0 ? void 0 : optimaEdit.actions) === null || _b === void 0 ? void 0 : _b.map(item => createOptimaActionAutocompleteOption(item)) : [],
        isActive: !!(optimaEdit === null || optimaEdit === void 0 ? void 0 : optimaEdit.isActive),
    };
};
export const convertOptimaEditFormValuesToBackEndValues = (values) => {
    var _a, _b;
    return {
        code: values === null || values === void 0 ? void 0 : values.code,
        category: values === null || values === void 0 ? void 0 : values.category,
        subCategory: values === null || values === void 0 ? void 0 : values.subCategory,
        priority: (_a = values === null || values === void 0 ? void 0 : values.priority) === null || _a === void 0 ? void 0 : _a.value,
        source: values === null || values === void 0 ? void 0 : values.source,
        details: values === null || values === void 0 ? void 0 : values.details,
        message: values === null || values === void 0 ? void 0 : values.message,
        actionIds: (_b = values === null || values === void 0 ? void 0 : values.actions) === null || _b === void 0 ? void 0 : _b.map(item => { var _a; return (_a = item === null || item === void 0 ? void 0 : item.value) === null || _a === void 0 ? void 0 : _a.id; }),
        isActive: values === null || values === void 0 ? void 0 : values.isActive,
    };
};
