import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Box, CustomIcon, IconButton, useAddToast, useTheme } from "@toolkit/ui";
import { MarketplaceOrderStatus } from "../../../../schema/types";
import { useMarketplaceOrderCancelBySupportTeamMutation } from "../../gql";
import { OrderCancelModal, OrderCancelModalApi } from "../../modals/OrderCancel/OrderCancelModal";
export const OrderCancel = props => {
    const { id, status } = props;
    const { t } = useTranslation("domains");
    const theme = useTheme();
    const { succeeded, failed } = useAddToast();
    const onIconButtonClick = () => {
        if (status === MarketplaceOrderStatus.Cancelled)
            return;
        OrderCancelModalApi.open({ id });
    };
    const [cancelMarketplaceOrder, { loading: isSubmitting }] = useMarketplaceOrderCancelBySupportTeamMutation({
        onCompleted: mutationData => {
            var _a;
            if ((_a = mutationData === null || mutationData === void 0 ? void 0 : mutationData.marketplaceOrderCancelBySupportTeam) === null || _a === void 0 ? void 0 : _a.id) {
                succeeded(t("Order canceled successfully"));
                OrderCancelModalApi.close();
            }
            else {
                failed(t("Order cancellation failed"));
            }
        },
        onError: ({ graphQLErrors }) => {
            failed(formatGraphQLError(graphQLErrors));
        },
        update: (cache, { data }) => {
            var _a;
            const normalizedId = cache.identify({ id: (_a = data === null || data === void 0 ? void 0 : data.marketplaceOrderCancelBySupportTeam) === null || _a === void 0 ? void 0 : _a.id, __typename: "MarketplaceOrder" });
            cache.evict({ id: normalizedId });
            cache.gc();
        },
    });
    const onOrderCancelModalChange = (event) => {
        var _a;
        if (event.type === "SUBMIT") {
            const { reason, otherReason } = event.payload.values;
            const reasonValue = reason === null || reason === void 0 ? void 0 : reason.value;
            cancelMarketplaceOrder({
                variables: {
                    id: event.payload.id,
                    cancellationReason: ((_a = reasonValue === null || reasonValue === void 0 ? void 0 : reasonValue.text) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === "other" ? otherReason : reasonValue === null || reasonValue === void 0 ? void 0 : reasonValue.text,
                },
            });
        }
    };
    return (_jsxs(Box, { children: [_jsx(OrderCancelModal, { isLoading: isSubmitting, onChange: onOrderCancelModalChange }), _jsx(IconButton, { disabled: status === MarketplaceOrderStatus.Cancelled, onClick: onIconButtonClick, children: _jsx(CustomIcon, { icon: "miniClose", height: 30, width: 30, viewBox: "0 5 20 10", color: theme.palette.error.main }) })] }));
};
