import { PermissionEnum } from "@/schema/types";
import RouteItem from "@/shared/components/Root/routeTypes";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { NavigateFunction } from "react-router-dom";
import { AdminOptimaActionCreateContainer, AdminOptimaActionListContainer, AdminOptimaActionUpdateContainer } from "../containers";
import { optimaActionsPaths, optimaActionsRoute } from "./OptimaActionsPaths";

export const optimaActionsRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  return {
    id: "optima-actions-routes",
    text: t("Optima Actions", { ns: "admin" }),
    route: optimaActionsRoute,
    isProhibited: !hasPermission(PermissionEnum.MangeEditsAndActions),
    subItems: [
      {
        id: "optima-action-list-route",
        route: optimaActionsPaths.list.route,
        fullPath: optimaActionsPaths.list.fullPath,
        element: <AdminOptimaActionListContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "optima-action-create-route",
        route: optimaActionsPaths.create.route,
        fullPath: optimaActionsPaths.create.fullPath,
        hidden: true,
        element: <AdminOptimaActionCreateContainer />,
        onClick: (route: string) => navigate(route),
      },
      {
        id: "optima-action-update-route",
        route: optimaActionsPaths.update.route,
        fullPath: optimaActionsPaths.update.fullPath,
        hidden: true,
        element: <AdminOptimaActionUpdateContainer />,
        onClick: (route: string) => navigate(route),
      },
    ],
  };
};
