import { AccountError, User, VendorUserTypes } from "@/schema/types";
import { BACKEND_DATE_FORMAT, combineErrors, formGirdBreakPoints, formGirdSpacing, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomDatePicker, CustomToggleButton, FormCard, FormControlLabel, Grid, TextField } from "@toolkit/ui";
import moment from "moment";
import { FC, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { hasHealthLicense } from "../../../UserManagement.constant";

export const HealthLicenseComponent: FC<{
  errors: AccountError[];
  defaultValues?: User | undefined;
  isEdit: boolean | undefined;
  isLoading: boolean;
  doYouHaveUser: boolean;
}> = ({ errors, doYouHaveUser, isLoading, defaultValues, isEdit }) => {
  const { watch, formState, register, control, setValue } = useFormContext();
  const { t } = useTranslation("admin");
  const combinedErrors = combineErrors(formState.errors, errors);
  const healthLicenseNumber = watch("healthLicenseNumber");
  const watchVendorUserType = watch("vendorUserType");
  const vendorUserType = Object?.keys(VendorUserTypes)?.map(key => ({ value: VendorUserTypes[key], name: key }));
  const vendorUserTypeDefaultValue = vendorUserType?.find(el => el?.value === defaultValues?.vendorUserType) || null;

  useEffect(() => {
    setValue("healthLicenseStartDate", defaultValues ? defaultValues?.healthLicenseStartDate : moment().format(BACKEND_DATE_FORMAT));
    setValue("healthLicenseEndDate", defaultValues ? defaultValues?.healthLicenseEndDate : moment().format(BACKEND_DATE_FORMAT));
  }, [defaultValues]);
  return (
    <>
      {hasHealthLicense(watchVendorUserType?.value || vendorUserTypeDefaultValue?.value) && (
        <Grid item xs={12}>
          <FormCard title={t("Health License")} loading={isLoading} doYouHaveData={doYouHaveUser}>
            <Grid container spacing={formGirdSpacing}>
              <Grid item {...formGirdBreakPoints}>
                <TextField
                  fullWidth
                  size='small'
                  label={t("Health License Number")}
                  error={Boolean(combinedErrors?.healthLicenseNumber?.message)}
                  helperText={t(combinedErrors?.healthLicenseNumber?.message)}
                  {...register("healthLicenseNumber")}
                  defaultValue={defaultValues?.healthLicenseNumber ?? ""}
                />
              </Grid>
              <Grid item {...formGirdBreakPoints}>
                <CustomDatePicker
                  control={control}
                  label={t("Health License Start Date")}
                  placeholder={t("Health License Start Date")}
                  {...register("healthLicenseStartDate", {
                    required: getRequiredValidation(t, Boolean(healthLicenseNumber)),
                  })}
                  error={Boolean(combinedErrors?.healthLicenseStartDate)}
                  helperText={t(combinedErrors?.healthLicenseStartDate?.type)}
                  format={BACKEND_DATE_FORMAT}
                />
              </Grid>
              <Grid item {...formGirdBreakPoints}>
                <CustomDatePicker
                  control={control}
                  {...register("healthLicenseEndDate", {
                    required: getRequiredValidation(t, Boolean(healthLicenseNumber)),
                  })}
                  label={t("Health License End Date")}
                  error={Boolean(combinedErrors?.healthLicenseEndDate)}
                  helperText={t(combinedErrors?.healthLicenseEndDate?.type)}
                  placeholder={t("Health License End Date")}
                  format={BACKEND_DATE_FORMAT}
                />
              </Grid>
              {(watchVendorUserType?.value == VendorUserTypes.Doctor || vendorUserTypeDefaultValue?.value == VendorUserTypes.Doctor) && (
                <Grid item {...formGirdBreakPoints}>
                  <Controller
                    name='doctorInfo.isLicenseNumberPublic'
                    defaultValue={isEdit ? defaultValues?.doctor?.isLicenseNumberPublic : true}
                    control={control}
                    render={({ field: { onChange: handleC, value } }) => (
                      <FormControlLabel
                        control={<CustomToggleButton onChange={e => handleC(e.target.checked)} checked={value} />}
                        label={t("Is Health License Public")}
                      />
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </FormCard>
        </Grid>
      )}
    </>
  );
};
