import { useTranslation } from "@toolkit/i18n";
import {
  CustomIcon,
  DatePicker,
  IconButton,
  InputAdornment,
  LocalizationProvider,
  MobileDatePicker,
  MomentUtils,
  TextField,
  useMediaQuery,
} from "@toolkit/ui";
import { Moment } from "moment/moment";
import React, { FC } from "react";
import { useOpenState } from "@toolkit/core";

type IMembershipDate = {
  date: Moment;
  min?: Moment;
  max?: Moment;
  label: string;
  isDisable?: boolean;
  onChangeDate: (date: Moment | null) => void;
  isRequired?: boolean;
  errorMessage?: string;
};

export const MembershipDate: FC<IMembershipDate> = ({
  label,
  date,
  min,
  max,
  onChangeDate: handleChangeDate,
  isDisable = false,
  isRequired = false,
  errorMessage,
}) => {
  const { t } = useTranslation("admin");

  const { open, handleOpen, handleClose } = useOpenState();

  const isMobileView = useMediaQuery("(max-width:1200px)");

  let helperText = "";
  if (min && date?.isBefore(min, "day")) {
    helperText = t("Please select a later date");
  } else if (max && date?.isAfter(max, "day")) {
    helperText = t("Please select an earlier  date");
  }

  return (
    <LocalizationProvider dateAdapter={MomentUtils}>
      {isMobileView ? (
        <MobileDatePicker
          label={label}
          value={date}
          open={open}
          disabled={isDisable}
          onOpen={handleOpen}
          onClose={handleClose}
          onChange={handleChangeDate}
          minDate={min}
          maxDate={max}
          renderInput={params => (
            <TextField
              fullWidth
              placeholder={t("please choose date")}
              {...params}
              helperText={helperText}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton sx={{ width: 44 }} edge='end' onClick={handleOpen}>
                      <CustomIcon icon={"date"} height={30} width={30} viewBox='-1 0 22 22' />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      ) : (
        <DatePicker
          label={label}
          value={date}
          disabled={isDisable}
          onChange={handleChangeDate}
          components={{
            OpenPickerIcon: () => <CustomIcon icon={"date"} height={30} width={30} viewBox='-2 0 22 22' />,
          }}
          renderInput={params => (
            <TextField
              fullWidth
              placeholder={t("please choose date")}
              required={isRequired}
              {...params}
              error={isRequired && !!errorMessage}
              helperText={helperText || (isRequired && errorMessage)}
            />
          )}
          minDate={min}
          maxDate={max}
        />
      )}
    </LocalizationProvider>
  );
};
