import { DecisionMakerOrderDirection, SubListSortField } from "../../../../schema/types";
import { getListFieldsAutocompleteFilter } from "@health/autocompletes";
import { useTranslation } from "@toolkit/i18n";
import { useMemo } from "react";
export const useSublistListTableColumns = () => {
    const { t } = useTranslation("domains");
    return useMemo(() => {
        return [
            {
                key: "name",
                header: t("Name"),
                accessor: ({ display }) => display,
                isHidden: false,
                isSortable: true,
                hideFromSettings: true,
                disableToggleVisibility: true,
                sortDirection: DecisionMakerOrderDirection.Asc,
                sortColumnEnum: SubListSortField.Display,
            },
            {
                key: "display",
                header: t("Display"),
                showOnlyForFilterField: true,
                filter: {
                    type: "string",
                    name: "display",
                },
            },
            {
                key: "Field",
                header: t("Field"),
                accessor: ({ field }) => field === null || field === void 0 ? void 0 : field.display,
                type: "string",
                disableToggleVisibility: true,
                isSortable: true,
                sortDirection: undefined,
                sortColumnEnum: SubListSortField.Field,
                filter: getListFieldsAutocompleteFilter({ name: "field" }),
            },
        ];
    }, []);
};
