import { H_OrderDirection, HealthProgramNetworkSortingField, PermissionEnum } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { hasPermission } from "@toolkit/core";
import { useNavigate } from "react-router-dom";
import { HealthProgramNetworkListPageWrapper } from "../../components/HealthProgramNetworkListPageWrapper/HealthProgramNetworkListPageWrapper";
import { healthProgramNetworksPaths } from "../../constants";
import { useHealthProgramNetworkListQuery } from "../../gql";
import { useSetHealthProgramNetworksBreadcrumbs } from "../../hooks";
import { IHealthProgramNetworkNode } from "../../types";
import { useHealthProgramNetworkListContainerColumns } from "./useHealthProgramNetworkListContainerColumns";

export const HealthProgramNetworkListContainer = () => {
  const navigate = useNavigate();

  const hasManageHealthProgramsPermission = hasPermission(PermissionEnum.ManageHealthPrograms);

  const onEditRowClick = (item: IHealthProgramNetworkNode) => {
    navigate(healthProgramNetworksPaths.update.fullPathWithParams({ networkId: item?.id }));
  };

  useSetHealthProgramNetworksBreadcrumbs("LIST");

  return (
    <GridProvider
      gridName={"healthProgramNetworkList"}
      query={useHealthProgramNetworkListQuery}
      columns={useHealthProgramNetworkListContainerColumns()}
      variables={{
        sortBy: {
          direction: H_OrderDirection.Desc,
          field: HealthProgramNetworkSortingField.Created,
        },
      }}
      tableAction={{
        isEditVisible: hasManageHealthProgramsPermission,
        onEditRow: onEditRowClick,
      }}
    >
      <HealthProgramNetworkListPageWrapper />
    </GridProvider>
  );
};
