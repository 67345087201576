import { getAutocompleteEnumFilter } from "@health/enum-options";
import { i18n, useTranslation } from "@toolkit/i18n";
import moment from "moment-timezone";
import "moment/locale/ar";
import { useMemo } from "react";
export const useOrdersInvoicesColumns = () => {
    const { t } = useTranslation("domains");
    return useMemo(() => {
        return [
            {
                key: "number",
                header: t("Order Number"),
                accessor: "id",
            },
            {
                key: "marketplaceOrderId",
                header: t("marketplace Order Id"),
                accessor: "marketplaceOrderId",
                filter: {
                    type: "string",
                    name: "marketplaceOrderIds",
                },
            },
            {
                key: "vat",
                header: t("Vat"),
                accessor: ({ vat, currency }) => `${vat} ${currency}`,
            },
            {
                key: "discount",
                header: t("Discount"),
                accessor: ({ discount, currency }) => `${discount} ${currency}`,
            },
            {
                key: "priceBeforeVat",
                header: t("Price Before Vat"),
                accessor: ({ priceBeforeVat, currency }) => `${priceBeforeVat} ${currency}`,
            },
            {
                key: "priceAfterVat",
                header: t("Price After Vat"),
                accessor: ({ priceAfterVat, currency }) => `${priceAfterVat} ${currency}`,
            },
            {
                key: "paymentType",
                header: t("Payment Type"),
                accessor: "paymentType",
                filter: getAutocompleteEnumFilter("PaymentType", "paymentTypes"),
            },
            {
                key: "paymentSource",
                header: t("Payment Source"),
                accessor: "paymentSource",
                filter: getAutocompleteEnumFilter("PaymentSource", "paymentSources"),
            },
            {
                key: "paymentSource",
                header: t("Payment Source"),
                accessor: "paymentSource",
            },
            {
                key: "createdBy",
                header: t("Created By"),
                accessor: "createdBy",
            },
            {
                key: "Created",
                header: t("Created"),
                accessor: ({ createdDate }) => {
                    moment.localeData("ar");
                    const createdTz = moment.tz.guess();
                    moment.locale(i18n.language);
                    return moment(createdDate).tz(createdTz).format("DD MMM YYYY hh:mm a");
                },
            },
            {
                key: "createdDateFrom",
                header: t("From Date"),
                showOnlyForFilter: true,
                filter: {
                    type: "date",
                    name: "createdDate.gte",
                },
            },
            {
                key: "createDateTo",
                header: t("To Date"),
                showOnlyForFilter: true,
                filter: {
                    type: "date",
                    name: "createdDate.lte",
                },
            },
            {
                key: "marketplaceOrderIdNotNull",
                header: t("Show marketplace orders only"),
                showOnlyForFilter: true,
                filter: getAutocompleteEnumFilter("YesNo", "marketplaceOrderIdNotNull"),
            },
        ];
    }, []);
};
