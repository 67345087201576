import { jsx as _jsx } from "react/jsx-runtime";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { DecisionUpsertForm } from "../../../Decisions/forms/DecisionUpsert/DecisionUpsertForm";
import { useDecisionCreateMutation } from "../../../Decisions/gql";
import { convertDecisionFormValuesToBackEndValues } from "../../../Decisions/others/utils";
import { useNavigate } from "react-router-dom";
export const DecisionCreateContainer = props => {
    const { category, healthParameter, healthParameterDecisionPlan } = props;
    const { t } = useTranslation("domains");
    const navigate = useNavigate();
    const { succeeded, failed } = useAddToast();
    const [fetchDecisionCreate, { loading: isSubmitting }] = useDecisionCreateMutation({
        onCompleted: (mutationData) => {
            var _a;
            const mutationErrors = (_a = mutationData === null || mutationData === void 0 ? void 0 : mutationData.addDecision) === null || _a === void 0 ? void 0 : _a.errors;
            if (mutationErrors === null || mutationErrors === void 0 ? void 0 : mutationErrors.length) {
                failed(t(formatMessageErrors(mutationErrors)));
            }
            else {
                succeeded(t("Decision created successfully"));
                navigate(-1);
            }
        },
        onError: ({ graphQLErrors }) => {
            failed(t(formatGraphQLError(graphQLErrors)));
        },
    });
    const onDecisionUpsertFormChange = (event) => {
        if (event.type === "SUBMIT" && event.payload) {
            const values = convertDecisionFormValuesToBackEndValues(event.payload.values, event.payload.decisionPlan);
            fetchDecisionCreate({
                variables: {
                    decision: values,
                },
            });
        }
    };
    return (_jsx(DecisionUpsertForm, { submitButtonLabel: t("Create"), category: category, isButtonDisabled: isSubmitting, healthParameter: healthParameter, healthParameterDecisionPlan: healthParameterDecisionPlan, onChange: onDecisionUpsertFormChange }));
};
