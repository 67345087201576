import { i18n } from "@toolkit/i18n";
import { AppointmentPaymentType } from "../../schema/types";
export const appointmentPaymentTypeOptionsMap = {
    [AppointmentPaymentType.CashOnly]: {
        key: AppointmentPaymentType.CashOnly,
        get label() {
            return i18n.t("Not Covered, out-of-pocket", { ns: "domains" });
        },
        value: AppointmentPaymentType.CashOnly,
    },
    [AppointmentPaymentType.InsuranceCoverage]: {
        key: AppointmentPaymentType.InsuranceCoverage,
        get label() {
            return i18n.t("Copay", { ns: "domains" });
        },
        value: AppointmentPaymentType.InsuranceCoverage,
    },
    [AppointmentPaymentType.PrePaid]: {
        key: AppointmentPaymentType.PrePaid,
        get label() {
            return i18n.t("Prepaid", { ns: "domains" });
        },
        value: AppointmentPaymentType.PrePaid,
    },
};
export const appointmentPaymentTypeOptions = Object.values(appointmentPaymentTypeOptionsMap);
