import { i18n } from "@toolkit/i18n";
import { PlanPeriod } from "../../schema/types";
export const planPeriodOptionsMap = {
    [PlanPeriod.Weekly]: {
        key: PlanPeriod.Weekly,
        get label() {
            return i18n.t("Weekly", { ns: "domains" });
        },
        value: PlanPeriod.Weekly,
    },
    [PlanPeriod.Monthly]: {
        key: PlanPeriod.Monthly,
        get label() {
            return i18n.t("Monthly", { ns: "domains" });
        },
        value: PlanPeriod.Monthly,
    },
    [PlanPeriod.ThreeMonths]: {
        key: PlanPeriod.ThreeMonths,
        get label() {
            return i18n.t("Three Months", { ns: "domains" });
        },
        value: PlanPeriod.ThreeMonths,
    },
    [PlanPeriod.HalfYear]: {
        key: PlanPeriod.HalfYear,
        get label() {
            return i18n.t("Half Year", { ns: "domains" });
        },
        value: PlanPeriod.HalfYear,
    },
    [PlanPeriod.Yearly]: {
        key: PlanPeriod.Yearly,
        get label() {
            return i18n.t("Annually", { ns: "domains" });
        },
        value: PlanPeriod.Yearly,
    },
};
export const planPeriodOptions = Object.values(planPeriodOptionsMap);
