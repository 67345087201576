import { VendorError } from "@/schema/types";
import { produce } from "immer";
import { get, reduce } from "lodash";
import { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

export const useGetBackendErrorsForInputsValues = (errors: VendorError[] | undefined, fields: string[]) => {
  const [backendErrorStorage, setBackendErrorsStorage] = useState(
    reduce(
      fields,
      (acc, key) => {
        acc[key] = {};
        return acc;
      },
      {}
    )
  );
  const { trigger, control } = useFormContext();
  const formFieldsValues = useWatch({
    control,
    name: fields,
  });

  useEffect(() => {
    if (!errors?.length) return;
    setBackendErrorsStorage(
      produce(backendErrorStorage, draft => {
        errors
          ?.filter(error => fields?.includes(error?.field || ""))
          ?.forEach(error => {
            const fieldName = error?.field || "";
            const fieldValue =
              get(
                formFieldsValues,
                fields?.findIndex(field => field === fieldName)
              ) || "";
            draft[fieldName][fieldValue] = error?.message;
          });
      })
    );
  }, [errors]);
  useEffect(() => {
    if (!errors?.length) return;
    trigger(fields);
  }, [backendErrorStorage]);
  return {
    backendErrorStorage,
  };
};
