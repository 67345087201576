import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from "@toolkit/i18n";
import { FormTextField, Grid } from "@toolkit/ui";
import { forwardRef, useImperativeHandle } from "react";
import { useCustomForm } from "@toolkit/core";
import { FormProvider } from "react-hook-form";
import { CancellationReasonsAutocomplete } from "@health/autocompletes";
import { orderCancelFormSchema, orderCancelFormDefaultValues } from "./OrderCancelFormSchema";
const OrderCancelFormForwardRef = (props, ref) => {
    var _a, _b, _c;
    const { id, onChange } = props;
    const { t } = useTranslation("domains");
    const form = useCustomForm({
        defaultValues: orderCancelFormDefaultValues,
        schema: orderCancelFormSchema,
    });
    const { watch, handleSubmit } = form;
    const isSelectedReasonOther = ((_c = (_b = (_a = watch("reason")) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.text) === null || _c === void 0 ? void 0 : _c.toLowerCase()) === "other";
    const onSubmit = (values) => {
        onChange({
            type: "SUBMIT",
            payload: { id, values },
        });
    };
    useImperativeHandle(ref, () => ({
        getForm: () => form,
        submit: handleSubmit(onSubmit),
    }));
    return (_jsx(FormProvider, Object.assign({}, form, { children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(CancellationReasonsAutocomplete, { name: "reason" }) }), isSelectedReasonOther && (_jsx(Grid, { item: true, xs: 12, children: _jsx(FormTextField, { name: "otherReason", label: t("Reason"), placeholder: t("Reason"), multiline: true, rows: 3 }) }))] }) })));
};
export const OrderCancelForm = forwardRef(OrderCancelFormForwardRef);
