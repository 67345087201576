import { i18n } from "@toolkit/i18n";
import { GuidedCareType } from "../../schema/types";
export const guidedCareTypeOptionsMap = {
    [GuidedCareType.Primary]: {
        key: GuidedCareType.Primary,
        get label() {
            return i18n.t("Primary", { ns: "domains" });
        },
        value: GuidedCareType.Primary,
    },
    [GuidedCareType.Chronic]: {
        key: GuidedCareType.Chronic,
        get label() {
            return i18n.t("Chronic", { ns: "domains" });
        },
        value: GuidedCareType.Chronic,
    },
    [GuidedCareType.Acute]: {
        key: GuidedCareType.Acute,
        get label() {
            return i18n.t("Acute", { ns: "domains" });
        },
        value: GuidedCareType.Acute,
    },
    [GuidedCareType.SubAcute]: {
        key: GuidedCareType.SubAcute,
        get label() {
            return i18n.t("Sub Acute", { ns: "domains" });
        },
        value: GuidedCareType.SubAcute,
    },
    [GuidedCareType.PostOperative]: {
        key: GuidedCareType.PostOperative,
        get label() {
            return i18n.t("Post Operative", { ns: "domains" });
        },
        value: GuidedCareType.PostOperative,
    },
    [GuidedCareType.PreOperative]: {
        key: GuidedCareType.PreOperative,
        get label() {
            return i18n.t("Pre Operative", { ns: "domains" });
        },
        value: GuidedCareType.PreOperative,
    },
    [GuidedCareType.Supportive]: {
        key: GuidedCareType.Supportive,
        get label() {
            return i18n.t("Supportive", { ns: "domains" });
        },
        value: GuidedCareType.Supportive,
    },
};
export const guidedCareTypeOptions = Object.values(guidedCareTypeOptionsMap);
