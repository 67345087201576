import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const GetCodeSystemConceptDocument = gql `
    query getCodeSystemConcept($id: ID!) {
  getCodeSystemConcept(id: $id) {
    id
    code
    display
    properties {
      code
      fieldType
      valueDate
      valueBoolean
      valueFloat
      valueInteger
      valueString
    }
  }
}
    `;
/**
 * __useGetCodeSystemConceptQuery__
 *
 * To run a query within a React component, call `useGetCodeSystemConceptQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCodeSystemConceptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCodeSystemConceptQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCodeSystemConceptQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetCodeSystemConceptDocument, options);
}
export function useGetCodeSystemConceptLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetCodeSystemConceptDocument, options);
}
export function useGetCodeSystemConceptSuspenseQuery(baseOptions) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(GetCodeSystemConceptDocument, options);
}
