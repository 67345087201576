import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
export type BranchFragmentFragment = { __typename?: 'Branch', id: string, isOpen?: boolean | null, isActive: boolean, name: string, description: string, nameAr?: string | null };

export const BranchFragmentFragmentDoc = gql`
    fragment BranchFragment on Branch {
  id
  isOpen
  isActive
  name
  description
  nameAr
}
    `;