import { getAllowedAppointmentStatus } from "@/pages/Appointments/others/utils";
import { AppointmentSortingField, H_OrderDirection } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { useAppointmentListQuery } from "../../gql";
import { useSetAppointmentsBreadcrumbs } from "../../hooks";
import { useAppointmentListContainerColumns } from "./useAppointmentListContainerColumns";

export const AppointmentListContainer = () => {
  useSetAppointmentsBreadcrumbs("LIST");

  return (
    <GridProvider
      gridName={"appointmentList"}
      query={useAppointmentListQuery}
      columns={useAppointmentListContainerColumns()}
      variables={{
        filter: {
          status: getAllowedAppointmentStatus(),
        },
        sortBy: {
          field: AppointmentSortingField.Created,
          direction: H_OrderDirection.Desc,
        },
      }}
      pageSize={9}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
