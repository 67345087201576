import { AccountError, AppRoleTypes, AppTypes, VendorUserTypes } from "@/schema/types";
import { IEnumOption } from "@health/enum-options";
import { combineErrors } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

export const AutocompleteAppComponent: FC<{
  formErrors?: AccountError[];
  defaultAppValue?: {
    value: AppTypes | AppRoleTypes;
    label: string;
  } | null;
  options:
    | {
        value: AppTypes | AppRoleTypes;
        label: string;
      }[]
    | IEnumOption<VendorUserTypes>[];
  name: string;
  label: string;
  isDisabled?: boolean;
  isEdit?: boolean;
}> = ({ formErrors, defaultAppValue, options, name, label, isDisabled, isEdit }) => {
  const { t } = useTranslation("admin");

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const combinedErrors = combineErrors(errors, formErrors);
  return (
    <AutocompleteController
      ControllerProps={{
        name: name,
        control: control,
        defaultValue: defaultAppValue || null,
        rules: { required: true },
      }}
      hidden={isEdit}
      TextFieldProps={{
        placeholder: t(label),
        error: Boolean(combinedErrors?.[name]?.type),
        helperText: t(combinedErrors?.[name]?.type && t("Required")),
        label: t(label),
      }}
      disabled={isDisabled}
      getOptionLabel={option => t(option?.name) || t(option?.label)}
      options={options || []}
    />
  );
};
AutocompleteAppComponent.defaultProps = {
  formErrors: undefined,
  defaultAppValue: undefined,
  isDisabled: false,
  isEdit: false,
};
