import { jsx as _jsx } from "react/jsx-runtime";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { OptimaEditUpsertForm } from "../../../OptimaEdits/forms/OptimaEditUpsert/OptimaEditUpsertForm";
import { OptimaEditListDocument, useOptimaEditGetQuery, useOptimaEditUpdateMutation } from "../../../OptimaEdits/gql";
import { convertOptimaEditFormValuesToBackEndValues } from "../../../OptimaEdits/others";
import { useParams } from "react-router-dom";
export const OptimaEditUpdateContainer = props => {
    const { onChange } = props;
    const { t } = useTranslation("domains");
    const { succeeded, failed } = useAddToast();
    const { editId } = useParams();
    const { data, loading } = useOptimaEditGetQuery({
        variables: { id: editId },
        skip: !editId,
        fetchPolicy: "no-cache",
    });
    const optimaEdit = data === null || data === void 0 ? void 0 : data.optimaEdit;
    const [updateOptimaEdit, { loading: isSubmitting }] = useOptimaEditUpdateMutation({
        onCompleted: () => {
            succeeded(t("Optima Edit updated successfully"));
            onChange({
                type: "SUBMIT_SUCCESS",
            });
        },
        onError: ({ graphQLErrors }) => {
            failed(formatGraphQLError(graphQLErrors));
        },
        refetchQueries: [OptimaEditListDocument],
    });
    const onOptimaEditUpsertFormChange = (event) => {
        if (event.type === "SUBMIT") {
            const values = convertOptimaEditFormValuesToBackEndValues(event.payload.values);
            updateOptimaEdit({
                variables: {
                    id: editId,
                    input: values,
                },
            });
        }
    };
    return (_jsx(OptimaEditUpsertForm, { buttonLabel: t("Update"), optimaEdit: optimaEdit, isLoading: loading, isButtonDisabled: isSubmitting, onChange: onOptimaEditUpsertFormChange }));
};
