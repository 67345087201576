import { i18n } from "@toolkit/i18n";
import { PaymentStatus } from "../../schema/types";
export const paymentStatusOptionsMap = {
    [PaymentStatus.FullyCovered]: {
        key: PaymentStatus.FullyCovered,
        get label() {
            return i18n.t("Fully Covered", { ns: "domains" });
        },
        value: PaymentStatus.FullyCovered,
    },
    [PaymentStatus.PaymentFailed]: {
        key: PaymentStatus.PaymentFailed,
        get label() {
            return i18n.t("Payment Failed", { ns: "domains" });
        },
        value: PaymentStatus.PaymentFailed,
    },
    [PaymentStatus.PaymentSucceeded]: {
        key: PaymentStatus.PaymentSucceeded,
        get label() {
            return i18n.t("Payment Succeeded", { ns: "domains" });
        },
        value: PaymentStatus.PaymentSucceeded,
    },
    [PaymentStatus.PaymentUnavailable]: {
        key: PaymentStatus.PaymentUnavailable,
        get label() {
            return i18n.t("Payment Unavailable", { ns: "domains" });
        },
        value: PaymentStatus.PaymentUnavailable,
    },
    [PaymentStatus.PaymentUnderProcessing]: {
        key: PaymentStatus.PaymentUnderProcessing,
        get label() {
            return i18n.t("Payment Under Processing", { ns: "domains" });
        },
        value: PaymentStatus.PaymentUnderProcessing,
    },
    [PaymentStatus.Refunded]: {
        key: PaymentStatus.Refunded,
        get label() {
            return i18n.t("Refunded", { ns: "domains" });
        },
        value: PaymentStatus.Refunded,
    },
    [PaymentStatus.RefundedFailed]: {
        key: PaymentStatus.RefundedFailed,
        get label() {
            return i18n.t("Refunded Failed", { ns: "domains" });
        },
        value: PaymentStatus.RefundedFailed,
    },
    [PaymentStatus.RefundedToWallet]: {
        key: PaymentStatus.RefundedToWallet,
        get label() {
            return i18n.t("Refunded To Wallet", { ns: "domains" });
        },
        value: PaymentStatus.RefundedToWallet,
    },
    [PaymentStatus.RefundedUnderProcessing]: {
        key: PaymentStatus.RefundedUnderProcessing,
        get label() {
            return i18n.t("Refunded Under Processing", { ns: "domains" });
        },
        value: PaymentStatus.RefundedUnderProcessing,
    },
    [PaymentStatus.UnPaid]: {
        key: PaymentStatus.UnPaid,
        get label() {
            return i18n.t("Not Paid", { ns: "domains" });
        },
        value: PaymentStatus.UnPaid,
    },
};
export const paymentStatusOptions = Object.values(paymentStatusOptionsMap);
