/* eslint-disable max-lines */
import { ActionFieldType, ConditionOperation, EntityType, Operator, } from "../../../schema/types";
import { operatorOptionsMap, priorityOptionsMap } from "@health/enum-options";
import { toSentenceCase } from "@toolkit/core";
import { createAutocompleteOption, mapStringsToAutocompleteOptions, mapToAutocompleteOptions } from "@toolkit/ui";
import { isObject, keyBy, uniqBy } from "lodash";
import { convertDecisionActionFieldsToFormValues, convertDecisionPlanActionFieldsToFormValues, } from "../../Decisions/others/decisionActionFieldsUtils";
import { convertDecisionConditionGroupsToFormValues, convertDecisionPlanConditionGroupToFormValues, } from "../../Decisions/others/decisionConditionUtils";
import { DecisionActionFieldListCode, DecisionConditionFieldCategoryCode, } from "../../Decisions/types/types";
export const isCodeInDecisionActionFieldListCode = (code) => {
    return code && Object.values(DecisionActionFieldListCode).includes(code);
};
export const hideDecisionConditionOperator = (operator) => {
    return [Operator.IsEmpty, Operator.IsNotEmpty, Operator.No, Operator.Yes].includes(operator);
};
export const getConditionCategoriesOptions = (decisionPlanFields, isHealthParameter = false) => {
    let filteredFields = decisionPlanFields;
    if (isHealthParameter) {
        filteredFields = decisionPlanFields === null || decisionPlanFields === void 0 ? void 0 : decisionPlanFields.filter(item => { var _a; return ((_a = item === null || item === void 0 ? void 0 : item.category) === null || _a === void 0 ? void 0 : _a.code) !== DecisionConditionFieldCategoryCode.HealthParameter; });
    }
    return uniqBy(mapToAutocompleteOptions((filteredFields === null || filteredFields === void 0 ? void 0 : filteredFields.map(item => {
        var _a, _b;
        return ({
            code: (_a = item === null || item === void 0 ? void 0 : item.category) === null || _a === void 0 ? void 0 : _a.code,
            display: (_b = item === null || item === void 0 ? void 0 : item.category) === null || _b === void 0 ? void 0 : _b.display,
        });
    })) || [], "code", "display"), "key");
};
export const getConditionFieldsOptions = (decisionPlanFields, categoryCode) => {
    var _a;
    return mapToAutocompleteOptions(((_a = decisionPlanFields === null || decisionPlanFields === void 0 ? void 0 : decisionPlanFields.filter(item => { var _a; return ((_a = item === null || item === void 0 ? void 0 : item.category) === null || _a === void 0 ? void 0 : _a.code) === categoryCode; })) === null || _a === void 0 ? void 0 : _a.sort((a, b) => { var _a; return (_a = ((a === null || a === void 0 ? void 0 : a.display) || "")) === null || _a === void 0 ? void 0 : _a.localeCompare((b === null || b === void 0 ? void 0 : b.display) || ""); }).map(item => {
        var _a;
        return ({
            id: item === null || item === void 0 ? void 0 : item.id,
            code: item === null || item === void 0 ? void 0 : item.code,
            display: item === null || item === void 0 ? void 0 : item.display,
            type: (_a = item === null || item === void 0 ? void 0 : item.fieldType) === null || _a === void 0 ? void 0 : _a.type,
        });
    })) || [], "code", "display");
};
export const getConditionOperatorsOptions = (decisionPlanFields, fieldCode) => {
    var _a, _b, _c;
    return (((_c = (_b = (_a = decisionPlanFields === null || decisionPlanFields === void 0 ? void 0 : decisionPlanFields.find(item => (item === null || item === void 0 ? void 0 : item.code) === fieldCode)) === null || _a === void 0 ? void 0 : _a.operators) === null || _b === void 0 ? void 0 : _b.map(operator => operatorOptionsMap[operator])) === null || _c === void 0 ? void 0 : _c.sort((a, b) => { var _a; return (_a = a === null || a === void 0 ? void 0 : a.value) === null || _a === void 0 ? void 0 : _a.localeCompare(b === null || b === void 0 ? void 0 : b.value); })) || []);
};
export const getConditionAllowedValuesOptions = (decisionPlanFields, fieldCode) => {
    var _a;
    const allowedValues = (((_a = decisionPlanFields === null || decisionPlanFields === void 0 ? void 0 : decisionPlanFields.find(item => (item === null || item === void 0 ? void 0 : item.code) === fieldCode)) === null || _a === void 0 ? void 0 : _a.allowedValues) || []);
    const allowedValuesOptions = mapStringsToAutocompleteOptions(allowedValues, toSentenceCase);
    return allowedValuesOptions === null || allowedValuesOptions === void 0 ? void 0 : allowedValuesOptions.sort((a, b) => { var _a; return (_a = a === null || a === void 0 ? void 0 : a.value) === null || _a === void 0 ? void 0 : _a.localeCompare(b === null || b === void 0 ? void 0 : b.value); });
};
export const convertDecisionPlanToFormValues = (decisionPlan) => {
    return {
        actionFields: convertDecisionPlanActionFieldsToFormValues(decisionPlan === null || decisionPlan === void 0 ? void 0 : decisionPlan.actionDefinitionFields),
        conditionsGroups: convertDecisionPlanConditionGroupToFormValues(decisionPlan === null || decisionPlan === void 0 ? void 0 : decisionPlan.fields),
    };
};
export const convertDecisionToFormValues = (decision) => {
    var _a, _b, _c, _d, _e;
    const decisionPlanActionsFields = keyBy(((_a = decision === null || decision === void 0 ? void 0 : decision.decisionPlan) === null || _a === void 0 ? void 0 : _a.actionDefinitionFields) || [], "code");
    const decisionPlanFields = keyBy(((_b = decision === null || decision === void 0 ? void 0 : decision.decisionPlan) === null || _b === void 0 ? void 0 : _b.fields) || [], "code");
    return {
        name: decision === null || decision === void 0 ? void 0 : decision.name,
        decisionPlan: createAutocompleteOption({
            id: (_c = decision === null || decision === void 0 ? void 0 : decision.decisionPlan) === null || _c === void 0 ? void 0 : _c.id,
            code: (_d = decision === null || decision === void 0 ? void 0 : decision.decisionPlan) === null || _d === void 0 ? void 0 : _d.code,
            display: (_e = decision === null || decision === void 0 ? void 0 : decision.decisionPlan) === null || _e === void 0 ? void 0 : _e.display,
        }, "code", "display"),
        priority: priorityOptionsMap[decision === null || decision === void 0 ? void 0 : decision.priority],
        isActive: decision === null || decision === void 0 ? void 0 : decision.isActive,
        isScript: decision === null || decision === void 0 ? void 0 : decision.isScript,
        script: decision === null || decision === void 0 ? void 0 : decision.drl,
        actionFields: convertDecisionActionFieldsToFormValues(decision === null || decision === void 0 ? void 0 : decision.actionFields, decisionPlanActionsFields),
        conditionsGroups: convertDecisionConditionGroupsToFormValues(decision === null || decision === void 0 ? void 0 : decision.conditionsGroups, decisionPlanFields),
    };
};
export const createHealthParameterAutocompleteObject = (healthParameter) => {
    return createAutocompleteOption({
        id: healthParameter === null || healthParameter === void 0 ? void 0 : healthParameter.id,
        code: healthParameter === null || healthParameter === void 0 ? void 0 : healthParameter.code,
        display: healthParameter === null || healthParameter === void 0 ? void 0 : healthParameter.display,
    }, "id", "display");
};
export const createHealthParameterDecisionPlanAutocompleteObject = (healthParameterDecisionPlan) => {
    return createAutocompleteOption({
        id: healthParameterDecisionPlan === null || healthParameterDecisionPlan === void 0 ? void 0 : healthParameterDecisionPlan.id,
        code: healthParameterDecisionPlan === null || healthParameterDecisionPlan === void 0 ? void 0 : healthParameterDecisionPlan.code,
        display: healthParameterDecisionPlan === null || healthParameterDecisionPlan === void 0 ? void 0 : healthParameterDecisionPlan.display,
    }, "id", "display");
};
export const convertDecisionActionFieldsFormValuesToBackEndValues = (actionFields) => {
    return actionFields === null || actionFields === void 0 ? void 0 : actionFields
    // eslint-disable-next-line max-statements
    .map(item => {
        var _a, _b, _c, _d, _e, _f, _g;
        let extra = undefined;
        const actionFieldsBackEndValues = {
            valueBoolean: undefined,
            valueNumber: undefined,
            valueString: undefined,
            valueList: undefined,
        };
        if ((item === null || item === void 0 ? void 0 : item.type) === ActionFieldType.Boolean) {
            actionFieldsBackEndValues.valueBoolean = item === null || item === void 0 ? void 0 : item.valueBoolean;
        }
        else if ((item === null || item === void 0 ? void 0 : item.type) === ActionFieldType.Number) {
            actionFieldsBackEndValues.valueNumber = item === null || item === void 0 ? void 0 : item.valueNumber;
        }
        else if ((item === null || item === void 0 ? void 0 : item.type) === ActionFieldType.String) {
            if (isCodeInDecisionActionFieldListCode(item === null || item === void 0 ? void 0 : item.code) && isObject(item === null || item === void 0 ? void 0 : item.valueString)) {
                extra = JSON.stringify((_a = item === null || item === void 0 ? void 0 : item.valueString) === null || _a === void 0 ? void 0 : _a.value);
                actionFieldsBackEndValues.valueString = (_b = item === null || item === void 0 ? void 0 : item.valueString) === null || _b === void 0 ? void 0 : _b.key;
            }
            else if (((_c = item === null || item === void 0 ? void 0 : item.allowedValues) === null || _c === void 0 ? void 0 : _c.length) && isObject(item === null || item === void 0 ? void 0 : item.valueString)) {
                actionFieldsBackEndValues.valueString = (_d = item === null || item === void 0 ? void 0 : item.valueString) === null || _d === void 0 ? void 0 : _d.value;
            }
            else {
                actionFieldsBackEndValues.valueString = item === null || item === void 0 ? void 0 : item.valueString;
            }
        }
        else if ((item === null || item === void 0 ? void 0 : item.type) === ActionFieldType.List) {
            extra = JSON.stringify((_e = item === null || item === void 0 ? void 0 : item.valueList) === null || _e === void 0 ? void 0 : _e.value);
            actionFieldsBackEndValues.valueList = [(_g = (_f = item === null || item === void 0 ? void 0 : item.valueList) === null || _f === void 0 ? void 0 : _f.value) === null || _g === void 0 ? void 0 : _g.id];
        }
        const nonNullableValues = Object.entries(actionFieldsBackEndValues)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            .filter(([key, value]) => value !== undefined)
            .reduce((acc, [key, value]) => (Object.assign(Object.assign({}, acc), { [key]: value })), {});
        if (Object.keys(nonNullableValues).length > 0) {
            return Object.assign(Object.assign({}, nonNullableValues), { code: item === null || item === void 0 ? void 0 : item.code, type: item === null || item === void 0 ? void 0 : item.type, extra });
        }
        return undefined;
    }).filter(Boolean);
};
export const convertDecisionConditionsFormValuesToBackEndValues = (conditionsGroups) => {
    // eslint-disable-next-line sonarjs/cognitive-complexity
    return conditionsGroups === null || conditionsGroups === void 0 ? void 0 : conditionsGroups.map(conditionGroup => {
        var _a, _b;
        return {
            isGroup: true,
            conditionOperation: (_a = conditionGroup === null || conditionGroup === void 0 ? void 0 : conditionGroup.outerConditionOperation) === null || _a === void 0 ? void 0 : _a.value,
            conditions: (_b = conditionGroup === null || conditionGroup === void 0 ? void 0 : conditionGroup.conditions) === null || _b === void 0 ? void 0 : _b.map((condition, index) => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
                const type = (_b = (_a = condition === null || condition === void 0 ? void 0 : condition.field) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.type;
                const actionFieldsBackEndValues = {
                    valueNumber: undefined,
                    valueString: undefined,
                    valueList: undefined,
                    valueDate: undefined,
                    valueID: undefined,
                    extra: undefined,
                };
                if (type === EntityType.Number) {
                    actionFieldsBackEndValues.valueNumber = condition === null || condition === void 0 ? void 0 : condition.valueNumber;
                }
                else if (type === EntityType.String) {
                    if (isObject(condition === null || condition === void 0 ? void 0 : condition.valueString)) {
                        actionFieldsBackEndValues.valueString = (_c = condition === null || condition === void 0 ? void 0 : condition.valueString) === null || _c === void 0 ? void 0 : _c.value;
                    }
                    else {
                        actionFieldsBackEndValues.valueString = condition === null || condition === void 0 ? void 0 : condition.valueString;
                    }
                }
                else if (type === EntityType.List) {
                    actionFieldsBackEndValues.valueList = (_e = (_d = condition === null || condition === void 0 ? void 0 : condition.valueList) === null || _d === void 0 ? void 0 : _d.value) === null || _e === void 0 ? void 0 : _e.id;
                    actionFieldsBackEndValues.extra = JSON.stringify((_f = condition === null || condition === void 0 ? void 0 : condition.valueList) === null || _f === void 0 ? void 0 : _f.value);
                }
                else if (type === EntityType.Id) {
                    actionFieldsBackEndValues.valueID = (_h = (_g = condition === null || condition === void 0 ? void 0 : condition.valueID) === null || _g === void 0 ? void 0 : _g.value) === null || _h === void 0 ? void 0 : _h.id;
                    actionFieldsBackEndValues.extra = JSON.stringify((_j = condition === null || condition === void 0 ? void 0 : condition.valueID) === null || _j === void 0 ? void 0 : _j.value);
                }
                else if (type === EntityType.Date) {
                    actionFieldsBackEndValues.valueDate = (condition === null || condition === void 0 ? void 0 : condition.valueDate) || undefined;
                }
                return {
                    isGroup: false,
                    conditionOperation: index === 0 ? null : (_k = conditionGroup === null || conditionGroup === void 0 ? void 0 : conditionGroup.innerConditionOperation) === null || _k === void 0 ? void 0 : _k.value,
                    entityType: type,
                    field: { id: (_m = (_l = condition === null || condition === void 0 ? void 0 : condition.field) === null || _l === void 0 ? void 0 : _l.value) === null || _m === void 0 ? void 0 : _m.id },
                    operator: (_o = condition === null || condition === void 0 ? void 0 : condition.operator) === null || _o === void 0 ? void 0 : _o.value,
                    value: hideDecisionConditionOperator((_p = condition === null || condition === void 0 ? void 0 : condition.operator) === null || _p === void 0 ? void 0 : _p.value) ? undefined : actionFieldsBackEndValues,
                };
            }),
        };
    });
};
export const createDecisionConditionForHealthParameter = (decisionPlanFields, healthParameterCode) => {
    var _a, _b, _c;
    const decisionField = decisionPlanFields === null || decisionPlanFields === void 0 ? void 0 : decisionPlanFields.find(item => { var _a; return ((_a = item === null || item === void 0 ? void 0 : item.category) === null || _a === void 0 ? void 0 : _a.code) === DecisionConditionFieldCategoryCode.HealthParameter; });
    return [
        {
            isGroup: true,
            conditionOperation: ConditionOperation.And,
            conditions: [
                {
                    isGroup: false,
                    conditionOperation: undefined,
                    entityType: (_a = decisionField === null || decisionField === void 0 ? void 0 : decisionField.fieldType) === null || _a === void 0 ? void 0 : _a.type,
                    field: { id: decisionField === null || decisionField === void 0 ? void 0 : decisionField.id },
                    operator: (_b = decisionField === null || decisionField === void 0 ? void 0 : decisionField.operators) === null || _b === void 0 ? void 0 : _b.find(item => item === Operator.Equal),
                    value: {
                        valueString: (_c = decisionField === null || decisionField === void 0 ? void 0 : decisionField.allowedValues) === null || _c === void 0 ? void 0 : _c.find(item => item === healthParameterCode),
                    },
                },
            ],
        },
    ];
};
export const convertDecisionFormValuesToBackEndValues = (values, decisionPlan) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const conditions = convertDecisionConditionsFormValuesToBackEndValues(values === null || values === void 0 ? void 0 : values.conditionsGroups);
    return {
        name: values === null || values === void 0 ? void 0 : values.name,
        decisionPlan: {
            id: (_b = (_a = values === null || values === void 0 ? void 0 : values.decisionPlan) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.id,
        },
        priority: (_c = values === null || values === void 0 ? void 0 : values.priority) === null || _c === void 0 ? void 0 : _c.value,
        isActive: values === null || values === void 0 ? void 0 : values.isActive,
        isScript: values === null || values === void 0 ? void 0 : values.isScript,
        drl: (values === null || values === void 0 ? void 0 : values.isScript) ? values === null || values === void 0 ? void 0 : values.script : undefined,
        healthParameterId: (values === null || values === void 0 ? void 0 : values.healthParameter) ? (_e = (_d = values === null || values === void 0 ? void 0 : values.healthParameter) === null || _d === void 0 ? void 0 : _d.value) === null || _e === void 0 ? void 0 : _e.id : undefined,
        actionFields: convertDecisionActionFieldsFormValuesToBackEndValues(values === null || values === void 0 ? void 0 : values.actionFields),
        conditions: (values === null || values === void 0 ? void 0 : values.healthParameter)
            ? [...conditions, ...createDecisionConditionForHealthParameter(decisionPlan === null || decisionPlan === void 0 ? void 0 : decisionPlan.fields, (_g = (_f = values === null || values === void 0 ? void 0 : values.healthParameter) === null || _f === void 0 ? void 0 : _f.value) === null || _g === void 0 ? void 0 : _g.code)]
            : conditions,
    };
};
