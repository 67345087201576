import { jsx as _jsx } from "react/jsx-runtime";
import { formatGraphQLError } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast } from "@toolkit/ui";
import { OptimaActionUpsertForm } from "../../../OptimaActions/forms/OptimaActionUpsert/OptimaActionUpsertForm";
import { OptimaActionListDocument, useOptimaActionGetQuery, useOptimaActionUpdateMutation } from "../../../OptimaActions/gql";
import { convertOptimaActionFormValuesToBackEndValues } from "../../../OptimaActions/others";
import { useParams } from "react-router-dom";
export const OptimaActionUpdateContainer = props => {
    const { onChange } = props;
    const { t } = useTranslation("domains");
    const { succeeded, failed } = useAddToast();
    const { actionId } = useParams();
    const { data, loading } = useOptimaActionGetQuery({
        variables: { id: actionId },
        skip: !actionId,
        fetchPolicy: "no-cache",
    });
    const optimaAction = data === null || data === void 0 ? void 0 : data.optimaAction;
    const [updateOptimaAction, { loading: isSubmitting }] = useOptimaActionUpdateMutation({
        onCompleted: () => {
            succeeded(t("Optima Action updated successfully"));
            onChange({
                type: "SUBMIT_SUCCESS",
            });
        },
        onError: ({ graphQLErrors }) => {
            failed(formatGraphQLError(graphQLErrors));
        },
        refetchQueries: [OptimaActionListDocument],
    });
    const onOptimaActionUpsertFormChange = (event) => {
        if (event.type === "SUBMIT") {
            const values = convertOptimaActionFormValuesToBackEndValues(event.payload.values);
            updateOptimaAction({
                variables: {
                    id: actionId,
                    input: values,
                },
            });
        }
    };
    return (_jsx(OptimaActionUpsertForm, { buttonLabel: t("Update"), optimaAction: optimaAction, isLoading: loading, isButtonDisabled: isSubmitting, onChange: onOptimaActionUpsertFormChange }));
};
