import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const GetCodeSystemDefinitionsSummeryDocument = gql `
    query getCodeSystemDefinitionsSummery($name: String) {
  getCodeSystemDefinitionsSummery(name: $name) {
    activeCodeSystem {
      id
      display
      processingStatus
      codeSystemHistory {
        id
        updateDate
        processingStatus
        user {
          firstName
          lastName
        }
      }
      isActive
      version
    }
    codeSystemDefinition {
      id
      code
      display
      editorUserId {
        firstName
        lastName
      }
      reviewerUserId {
        firstName
        lastName
      }
    }
    lastCodeSystem {
      id
      processingStatus
      codeSystemHistory {
        id
        processingStatus
        user {
          firstName
          lastName
        }
      }
    }
    iconUrl
  }
}
    `;
/**
 * __useGetCodeSystemDefinitionsSummeryQuery__
 *
 * To run a query within a React component, call `useGetCodeSystemDefinitionsSummeryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCodeSystemDefinitionsSummeryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCodeSystemDefinitionsSummeryQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetCodeSystemDefinitionsSummeryQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetCodeSystemDefinitionsSummeryDocument, options);
}
export function useGetCodeSystemDefinitionsSummeryLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetCodeSystemDefinitionsSummeryDocument, options);
}
export function useGetCodeSystemDefinitionsSummerySuspenseQuery(baseOptions) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(GetCodeSystemDefinitionsSummeryDocument, options);
}
