import { MarketplaceDefaultSortingField, MarketplaceSortDirection } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { Box, FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import ParentCategoryInfoCard from "pages/MarketplaceProductCategories/components/ParentCategoryInfoCard.component";
import { FC, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { categoriesBreadcrumb } from "../../constants/CategoriesBreadcrumbs";
import { categoriesPaths } from "../../constants/CategoriesPaths";
import { useMarketplaceProductCategoriesQuery } from "../../gql";
import { ProductCategoriesQueryNode } from "../../types";
import { useCategoriesListTableColumns } from "./useCategoriesListTableColumns";

type CategoriesListContainerProps = {
  hasParent?: boolean;
};
export const CategoriesListContainer: FC<CategoriesListContainerProps> = ({ hasParent }) => {
  const { setBreadCrumb } = useBreadCrumbs();
  const { categoryId: parentId } = useParams<{ categoryId: string }>();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const onEditRowClick = (item: ProductCategoriesQueryNode) => {
    navigate(categoriesPaths.update.fullPathWithParams({ categoryId: item?.id }));
  };
  const filter = hasParent
    ? {
        parentId: parentId,
      }
    : {
        parent: true,
      };
  const onAddNewItemClick = () => {
    hasParent
      ? navigate(categoriesPaths.newSubcategory.fullPathWithParams({ categoryId: parentId! }))
      : navigate(categoriesPaths.new.fullPath);
  };
  useEffect(() => {
    !hasParent &&
      setBreadCrumb({
        title: categoriesBreadcrumb().title,
        values: [],
      });
  }, [pathname, hasParent, setBreadCrumb]);

  return (
    <GridProvider
      gridName='marketplace-product-categories'
      query={useMarketplaceProductCategoriesQuery}
      columns={useCategoriesListTableColumns()}
      hasTableSetting
      variables={{
        sortBy: {
          field: MarketplaceDefaultSortingField.Created,
          direction: MarketplaceSortDirection.Desc,
        },
        filter: filter,
      }}
      tableAction={{
        isEditVisible: true,
        onEditRow: onEditRowClick,
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        {hasParent && <ParentCategoryInfoCard hasParent={hasParent} />}
        <Box marginTop={2}>
          <TableGrid />
        </Box>
      </PageWrapper>
    </GridProvider>
  );
};
