import { H_OrderDirection, HealthProgramMemberListSortingField, PermissionEnum } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { hasPermission } from "@toolkit/core";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { useNavigate } from "react-router-dom";
import { healthProgramMemberListsPaths } from "../../constants";
import { useHealthProgramMemberListListQuery } from "../../gql";
import { useSetHealthProgramMemberListsBreadcrumbs } from "../../hooks";
import { IHealthProgramMemberListNode } from "../../types";
import { useHealthProgramMemberListListContainerColumns } from "./useHealthProgramMemberListListContainerColumns";

export const HealthProgramMemberListListContainer = () => {
  const navigate = useNavigate();

  const hasManageHealthProgramsPermission = hasPermission(PermissionEnum.ManageHealthPrograms);

  const onAddNewItemClick = () => {
    navigate(healthProgramMemberListsPaths.create.fullPath);
  };

  const onEditRowClick = (item: IHealthProgramMemberListNode) => {
    navigate(healthProgramMemberListsPaths.update.fullPathWithParams({ memberListId: item?.id }));
  };

  useSetHealthProgramMemberListsBreadcrumbs("LIST");

  return (
    <GridProvider
      gridName={"healthProgramMemberListList"}
      query={useHealthProgramMemberListListQuery}
      columns={useHealthProgramMemberListListContainerColumns()}
      variables={{
        sortBy: {
          direction: H_OrderDirection.Desc,
          field: HealthProgramMemberListSortingField.Created,
        },
      }}
      tableAction={{
        isEditVisible: hasManageHealthProgramsPermission,
        onEditRow: onEditRowClick,
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew={hasManageHealthProgramsPermission} onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
