import { QuestionType, Survey, SurveyStatus } from "@/schema/types";
import { toPascalCase } from "@toolkit/core";
import { Palette } from "@toolkit/ui";
import moment from "moment";

export const getSurveyStatusColor = (status: Survey["status"], palette: Palette) => {
  switch (status) {
    case SurveyStatus.Cancelled:
      return palette.warning.main;
    case SurveyStatus.Draft:
      return palette.stale.main;
    case SurveyStatus.Expired:
      return palette.error.main;
    case SurveyStatus.Published:
      return palette.primary.dark;
    case SurveyStatus.Unpublished:
      return palette.primary.main;
    default:
      return palette.primary.main;
  }
};

export const getSurveyStatusText = (status: Survey["status"]) => toPascalCase(status?.toLowerCase() ?? "");

export const getLastPublishedCriteria = (publishCriteria: Survey["publishCriteria"]) => {
  let lastCriteria = publishCriteria?.[0];
  publishCriteria?.forEach(criteria => {
    if (moment(criteria?.createdDate).isAfter(lastCriteria?.createdDate)) {
      lastCriteria = criteria;
    }
  });
  return lastCriteria;
};

export const shouldQuestionHaveOptions = (questionType: QuestionType): boolean => {
  return questionType !== QuestionType.Boolean && questionType !== QuestionType.String;
};
