import { combineErrors } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Button, CheckMarkIcon, CircularProgress, PageWrapper } from "@toolkit/ui";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { SettingView } from "./components/SettingView";
import { HealthProgramSetting } from "./healthProgramSetting/HealthProgramSetting";
import { useSettingsPage } from "./SettingsPage.hook";
import { SiteSetting } from "./siteSetting/SiteSetting";
import { siteSetting } from "./utils";

export const SettingsPage: FC = () => {
  const { t } = useTranslation("admin");

  const { loading, isSubmitting, siteSettings, siteSettingView, onSubmit, handleChangeView } = useSettingsPage();

  const { ...methods } = useForm({
    mode: "onChange",
  });
  const {
    handleSubmit,
    formState: { errors },
  } = methods;

  const formErrors = combineErrors(errors, []);

  return (
    <FormProvider {...methods}>
      <PageWrapper
        start={<SettingView state={siteSettingView} onChangeView={handleChangeView} />}
        actions={
          <Button
            color='success'
            size='medium'
            type='submit'
            startIcon={isSubmitting ? <CircularProgress size={12} /> : <CheckMarkIcon color='inherit' />}
            onClick={handleSubmit(onSubmit)}
            disabled={loading || isSubmitting}
          >
            {t("Save")}
          </Button>
        }
      >
        {siteSettingView === siteSetting.siteSettings ? (
          <SiteSetting loading={loading} errors={formErrors} siteSettings={siteSettings} />
        ) : (
          <HealthProgramSetting loading={loading} errors={formErrors} siteSettings={siteSettings} />
        )}
      </PageWrapper>
    </FormProvider>
  );
};
