import { i18n } from "@toolkit/i18n";
import { BulkTemplateCode } from "../../schema/types";
export const bulkTemplateCodeOptionsMap = {
    [BulkTemplateCode.Doctor]: {
        key: BulkTemplateCode.Doctor,
        get label() {
            return i18n.t("Doctor", { ns: "domains" });
        },
        value: BulkTemplateCode.Doctor,
    },
    [BulkTemplateCode.HealthParameter]: {
        key: BulkTemplateCode.HealthParameter,
        get label() {
            return i18n.t("Health Parameter", { ns: "domains" });
        },
        value: BulkTemplateCode.HealthParameter,
    },
    [BulkTemplateCode.HealthProgramMember]: {
        key: BulkTemplateCode.HealthProgramMember,
        get label() {
            return i18n.t("Health Program Member", { ns: "domains" });
        },
        value: BulkTemplateCode.HealthProgramMember,
    },
    [BulkTemplateCode.HealthProgramNetwork]: {
        key: BulkTemplateCode.HealthProgramNetwork,
        get label() {
            return i18n.t("Health Program Network", { ns: "domains" });
        },
        value: BulkTemplateCode.HealthProgramNetwork,
    },
    [BulkTemplateCode.HealthProgramNetworkProvider]: {
        key: BulkTemplateCode.HealthProgramNetworkProvider,
        get label() {
            return i18n.t("Health Program Network Provider", { ns: "domains" });
        },
        value: BulkTemplateCode.HealthProgramNetworkProvider,
    },
    [BulkTemplateCode.MedicalForm]: {
        key: BulkTemplateCode.MedicalForm,
        get label() {
            return i18n.t("Medical Form", { ns: "domains" });
        },
        value: BulkTemplateCode.MedicalForm,
    },
};
export const bulkTemplateCodeOptions = Object.values(bulkTemplateCodeOptionsMap);
