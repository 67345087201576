import { i18n } from "@toolkit/i18n";
import { Operator } from "../../schema/types";
export const operatorOptionsMap = {
    [Operator.Contains]: {
        key: Operator.Contains,
        get label() {
            return i18n.t("Contains", { ns: "domains" });
        },
        value: Operator.Contains,
    },
    [Operator.Count]: {
        key: Operator.Count,
        get label() {
            return i18n.t("Count", { ns: "domains" });
        },
        value: Operator.Count,
    },
    [Operator.EndWith]: {
        key: Operator.EndWith,
        get label() {
            return i18n.t("End With", { ns: "domains" });
        },
        value: Operator.EndWith,
    },
    [Operator.Equal]: {
        key: Operator.Equal,
        get label() {
            return i18n.t("Equal", { ns: "domains" });
        },
        value: Operator.Equal,
    },
    [Operator.GreaterThan]: {
        key: Operator.GreaterThan,
        get label() {
            return i18n.t("Greater Than", { ns: "domains" });
        },
        value: Operator.GreaterThan,
    },
    [Operator.GreaterThanOrEqual]: {
        key: Operator.GreaterThanOrEqual,
        get label() {
            return i18n.t("Greater Than Or Equal", { ns: "domains" });
        },
        value: Operator.GreaterThanOrEqual,
    },
    [Operator.In]: {
        key: Operator.In,
        get label() {
            return i18n.t("In", { ns: "domains" });
        },
        value: Operator.In,
    },
    [Operator.IsEmpty]: {
        key: Operator.IsEmpty,
        get label() {
            return i18n.t("Is Empty", { ns: "domains" });
        },
        value: Operator.IsEmpty,
    },
    [Operator.IsNotEmpty]: {
        key: Operator.IsNotEmpty,
        get label() {
            return i18n.t("Is Not Empty", { ns: "domains" });
        },
        value: Operator.IsNotEmpty,
    },
    [Operator.LessThan]: {
        key: Operator.LessThan,
        get label() {
            return i18n.t("Less Than", { ns: "domains" });
        },
        value: Operator.LessThan,
    },
    [Operator.LessThanOrEqual]: {
        key: Operator.LessThanOrEqual,
        get label() {
            return i18n.t("Less Than Or Equal", { ns: "domains" });
        },
        value: Operator.LessThanOrEqual,
    },
    [Operator.No]: {
        key: Operator.No,
        get label() {
            return i18n.t("No", { ns: "domains" });
        },
        value: Operator.No,
    },
    [Operator.NotContains]: {
        key: Operator.NotContains,
        get label() {
            return i18n.t("Not Contains", { ns: "domains" });
        },
        value: Operator.NotContains,
    },
    [Operator.NotEqual]: {
        key: Operator.NotEqual,
        get label() {
            return i18n.t("Not Equal", { ns: "domains" });
        },
        value: Operator.NotEqual,
    },
    [Operator.NotIn]: {
        key: Operator.NotIn,
        get label() {
            return i18n.t("Not In", { ns: "domains" });
        },
        value: Operator.NotIn,
    },
    [Operator.StartWith]: {
        key: Operator.StartWith,
        get label() {
            return i18n.t("Start With", { ns: "domains" });
        },
        value: Operator.StartWith,
    },
    [Operator.Yes]: {
        key: Operator.Yes,
        get label() {
            return i18n.t("Yes", { ns: "domains" });
        },
        value: Operator.Yes,
    },
};
export const operatorOptions = Object.values(operatorOptionsMap);
