import { jsx as _jsx } from "react/jsx-runtime";
import { MarketplaceOrderSortingField, MarketplaceOrderType, OrderDirection } from "../../../../schema/types";
import { getVendorsAutocompleteFilter } from "@health/autocompletes";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { OrderStatusTypography, TruncateTypography, Typography, useFormatDate } from "@toolkit/ui";
import "moment/locale/ar";
import { getOrderStatusColor } from "../../../OrdersTracking/components/utils";
import { useMemo } from "react";
import { ProductsModal } from "../components/ProductsModal";
import { OrderCancel } from "../../components/OrderCancel/OrderCancel";
export const useMarketplaceOrdersTrackingColumns = ({ isAdmin }) => {
    const { t } = useTranslation("domains");
    const { formatLocalizedDate } = useFormatDate();
    return useMemo(() => {
        return [
            {
                key: "number",
                header: t("Order Number"),
                accessor: "id",
                filter: {
                    type: "string",
                    name: "ids",
                },
            },
            {
                key: "vendor",
                header: t("Vendor"),
                accessor: ({ vendor }) => _jsx(TruncateTypography, { width: 100, text: (vendor === null || vendor === void 0 ? void 0 : vendor.name) || "-" }),
                hideFromPreferencesAndTable: !isAdmin,
                filter: getVendorsAutocompleteFilter({ name: "vendorId" }),
            },
            {
                key: "patient-id",
                header: t("Customer ID"),
                accessor: ({ user }) => user === null || user === void 0 ? void 0 : user.nationalId,
            },
            {
                key: "customer-name",
                header: t("Customer Name"),
                accessor: ({ user }) => _jsx(TruncateTypography, { text: (user === null || user === void 0 ? void 0 : user.fullName) || "-" }),
            },
            {
                key: "contactEmail",
                header: t("Customer Email"),
                accessor: ({ user }) => { var _a; return _jsx(Typography, { width: 200, children: String((_a = user === null || user === void 0 ? void 0 : user.email) !== null && _a !== void 0 ? _a : "") }); },
            },
            {
                key: "contactNumber",
                header: t("Customer Mobile"),
                accessor: ({ user }) => { var _a; return _jsx("span", { className: 'mobile-phone-rtl-fix', children: String((_a = user === null || user === void 0 ? void 0 : user.mobile) !== null && _a !== void 0 ? _a : "") }); },
            },
            {
                header: t("Payment Price"),
                key: "totalAmount",
                accessor: "totalNetAmount",
            },
            {
                key: "Order Status",
                header: t("Order Status"),
                accessor: ({ status }) => _jsx(OrderStatusTypography, { status: status, color: getOrderStatusColor(status) }),
                filter: getAutocompleteEnumFilter("MarketplaceOrderStatus", "statuses", { multiple: true }),
            },
            {
                key: "type",
                header: t("Order Type"),
                accessor: ({ type }) => (type === MarketplaceOrderType.HealthPackage ? t("Health Package") : t("Product")),
                filter: getAutocompleteEnumFilter("MarketplaceOrderType", "type"),
            },
            {
                key: "cancellationReason",
                header: t("Cancellation Reason"),
                accessor: ({ cancellationReason }) => _jsx(TruncateTypography, { text: cancellationReason || "-" }),
                isHidden: !isAdmin,
            },
            {
                key: "orderItems",
                header: t("Order Items"),
                accessor: ({ id }) => _jsx(ProductsModal, { orderId: id, title: t("Items") }),
            },
            {
                key: "created",
                header: t("Created"),
                accessor: ({ createdDate }) => _jsx(Typography, { width: 100, children: formatLocalizedDate(createdDate) }),
                isSortable: true,
                sortDirection: OrderDirection === null || OrderDirection === void 0 ? void 0 : OrderDirection.Asc,
                sortColumnEnum: MarketplaceOrderSortingField.Created,
            },
            {
                key: "cancel",
                header: t("Cancel"),
                accessor: ({ id, status }) => _jsx(OrderCancel, { id: id, status: status }),
                isHidden: !isAdmin,
            },
            {
                key: "toDate",
                header: t("To Date"),
                accessor: "toDate",
                hideFromPreferencesAndTable: true,
                filter: {
                    type: "date",
                    name: "toDate",
                },
            },
            {
                key: "fromDate",
                header: t("From Date"),
                accessor: "fromDate",
                hideFromPreferencesAndTable: true,
                filter: {
                    type: "date",
                    name: "fromDate",
                },
            },
        ];
    }, []);
};
