import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const GetCodeSystemDocument = gql `
    query getCodeSystem($id: ID!) {
  getCodeSystem(id: $id) {
    id
    processingStatus
    version
    codeSystemHistory {
      user {
        id
        firstName
        lastName
      }
      processingStatus
    }
    codeSystemDefinition {
      id
      code
      display
      isEditable
      editorUserId {
        id
        firstName
        lastName
        __typename
      }
      reviewerUserId {
        id
        firstName
        lastName
        __typename
      }
      codeSystemConceptFields {
        code
        display
        fieldType
        isMandatory
      }
    }
  }
}
    `;
/**
 * __useGetCodeSystemQuery__
 *
 * To run a query within a React component, call `useGetCodeSystemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCodeSystemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCodeSystemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCodeSystemQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetCodeSystemDocument, options);
}
export function useGetCodeSystemLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetCodeSystemDocument, options);
}
export function useGetCodeSystemSuspenseQuery(baseOptions) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(GetCodeSystemDocument, options);
}
