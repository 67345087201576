import { Type } from "../../../schema/types";
import { FieldUIComponent } from "../types";
export const getConceptPropertyValue = (item) => {
    switch (item === null || item === void 0 ? void 0 : item.fieldType) {
        case Type.String:
            return item === null || item === void 0 ? void 0 : item.valueString;
        case Type.Boolean:
            return (item === null || item === void 0 ? void 0 : item.valueBoolean) ? "Yes" : "No";
        case Type.Float:
            return item === null || item === void 0 ? void 0 : item.valueFloat;
        case Type.Integer:
            return item === null || item === void 0 ? void 0 : item.valueInteger;
        case Type.Date:
            return item === null || item === void 0 ? void 0 : item.valueDate;
        default:
            return "-";
    }
};
export const getInputType = (item) => {
    switch (item === null || item === void 0 ? void 0 : item.fieldType) {
        case Type.String:
            return "string";
        case Type.Boolean:
            return "autocomplete";
        case Type.Float:
        case Type.Integer:
            return "number";
        case Type.Date:
            return "date";
        default:
            return "string";
    }
};
export const getFieldType = (item) => {
    switch (item === null || item === void 0 ? void 0 : item.fieldType) {
        case Type.String:
            return FieldUIComponent.input;
        case Type.Boolean:
            return FieldUIComponent.autocomplete;
        case Type.Date:
            return FieldUIComponent.date;
        default:
            return FieldUIComponent.input;
    }
};
export const getFilterValuePropertyName = (fieldType) => {
    switch (fieldType) {
        case Type.String:
            return "valueString";
        case Type.Boolean:
            return "valueBoolean";
        case Type.Float:
            return "valueFloat";
        case Type.Integer:
            return "valueInteger";
        case Type.Date:
            return "valueDate";
        default:
            return "valueString";
    }
};
