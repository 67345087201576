import { IHealthProgramMemberNode } from "@/pages/HealthProgramMembers/types";
import { H_OrderDirection, HealthProgramMemberSortingField } from "@/schema/types";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, format, Typography } from "@toolkit/ui";
import { useMemo } from "react";

export const useHealthProgramMemberListContainerColumns = (): CustomTableColumnProps<IHealthProgramMemberNode>[] => {
  const { t } = useTranslation("admin");

  return useMemo(
    () => [
      {
        key: "name",
        header: t("Name"),
        accessor: ({ firstName, lastName }) => `${firstName} ${lastName}`,
        isSortable: true,
        sortDirection: H_OrderDirection.Desc,
        sortColumnEnum: HealthProgramMemberSortingField.Name,
        filter: {
          name: "name",
          type: "string",
        },
      },
      {
        key: "nationalId",
        header: t("National Id"),
        accessor: "nationalId",
        isSortable: true,
        sortDirection: H_OrderDirection.Desc,
        sortColumnEnum: HealthProgramMemberSortingField.NationalId,
        filter: {
          name: "nationalId",
          type: "string",
        },
      },
      {
        key: "sehacityId",
        header: t("iO Health Id"),
        accessor: "sehacityId",
      },
      {
        key: "insuranceId",
        header: t("Insurance Id"),
        accessor: "insuranceId",
        isSortable: true,
        sortDirection: H_OrderDirection.Desc,
        sortColumnEnum: HealthProgramMemberSortingField.InsuranceId,
        filter: {
          type: "string",
          name: "insuranceId",
        },
      },
      {
        key: "email",
        header: t("Email"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "email",
        },
      },
      {
        key: "gender",
        header: t("Gender"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("UserGender", "gender"),
      },
      {
        key: "dateOfBirth",
        header: t("Date of Birth"),
        showOnlyForFilterField: true,
        filter: {
          type: "date",
          name: "dateOfBirth",
        },
      },
      {
        key: "phone",
        header: t("Phone Number"),
        accessor: ({ phone }) => phone || "-",
        filter: {
          name: "phone",
          type: "string",
        },
      },
      {
        key: "membership",
        header: t("Membership"),
        accessor: ({ membershipStart, membershipEnd }) => `${format(membershipStart)} - ${format(membershipEnd)}`,
      },
      {
        key: "created",
        header: t("Created"),
        isSortable: true,
        direction: H_OrderDirection.Desc,
        sortColumnEnum: HealthProgramMemberSortingField.Created,
        accessor: row => <Typography noWrap>{format(row?.created, "DD MMM YYYY MM:HH") || "-"}</Typography>,
      },
    ],
    [t]
  );
};
