import { formGirdBreakPoints, formGirdSpacing, getRequiredValidation, useCustomFormContext } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormCard, FormPhoneInput, Grid } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { CustomTextField } from "../CustomTextField.components";
import { InfoProps } from "../types";
import { financialManagerFields, managersContactInfoFields, purchasingManagerFields } from "./managersInfoUtils";

export const ManagersContactInfo: FC<InfoProps> = props => {
  const { defaultValues, isLoading, doYouHaveVendor, errorsInput } = props;

  const { t } = useTranslation("admin");
  const { register, setValue } = useCustomFormContext();

  const purchasingManagerMobileNumber = "managersContactInfo.purchasingManagerMobileNumber";
  const financialManagerMobileNumber = "managersContactInfo.financialManagerMobileNumber";
  useEffect(() => {
    setValue("contactMobileNumber", defaultValues?.contactMobileNumber ?? "");
    setValue(purchasingManagerMobileNumber, defaultValues?.managersContactInfo?.purchasingManagerMobileNumber ?? "");
    setValue(financialManagerMobileNumber, defaultValues?.managersContactInfo?.financialManagerMobileNumber ?? "");
  }, [defaultValues]);

  return (
    <Grid item xs={12}>
      <FormCard title={t("Managers Contact Information")} loading={isLoading} doYouHaveData={doYouHaveVendor}>
        <Grid container spacing={formGirdSpacing}>
          {managersContactInfoFields(t, defaultValues)?.map(item => (
            <Grid item {...formGirdBreakPoints} key={item.name}>
              <CustomTextField
                name={item.name}
                label={item.label}
                defaultValue={item.defaultValue}
                registerProps={{ ...item?.registerProps, pattern: item?.pattern }}
              />
            </Grid>
          ))}
          <Grid item {...formGirdBreakPoints}>
            <FormPhoneInput
              {...register("contactMobileNumber", {
                required: getRequiredValidation(t, true),
              })}
              label={t("Contact Mobile Number")}
              error={Boolean(errorsInput?.["contactMobileNumber"]?.message)}
              helperText={t(errorsInput?.["contactMobileNumber"]?.message)}
              fullWidth
            />
          </Grid>
          {purchasingManagerFields(t, defaultValues)?.map(item => (
            <Grid item {...formGirdBreakPoints} key={item.name}>
              <CustomTextField
                name={item.name}
                label={item.label}
                defaultValue={item.defaultValue}
                isRequired={item?.isRequired}
                registerProps={{ ...item?.registerProps, pattern: item?.pattern }}
              />
            </Grid>
          ))}

          <Grid item {...formGirdBreakPoints}>
            <FormPhoneInput
              {...register(purchasingManagerMobileNumber, {
                required: getRequiredValidation(t, false),
              })}
              label={t("Purchasing Manager Number")}
              error={Boolean(errorsInput?.[purchasingManagerMobileNumber]?.message)}
              helperText={t(errorsInput?.[purchasingManagerMobileNumber]?.message)}
              fullWidth
            />
          </Grid>
          {financialManagerFields(t, defaultValues)?.map(item => (
            <Grid item {...formGirdBreakPoints} key={item.name}>
              <CustomTextField
                name={item.name}
                isRequired={item?.isRequired}
                label={item.label}
                defaultValue={item.defaultValue}
                registerProps={{ ...item?.registerProps, pattern: item?.pattern }}
              />
            </Grid>
          ))}
          <Grid item {...formGirdBreakPoints}>
            <FormPhoneInput
              {...register(financialManagerMobileNumber, {
                required: getRequiredValidation(t, false),
              })}
              label={t("Financial Manager Number")}
              error={Boolean(errorsInput?.[financialManagerMobileNumber]?.message)}
              helperText={t(errorsInput?.[financialManagerMobileNumber]?.message)}
              fullWidth
            />
          </Grid>
        </Grid>
      </FormCard>
    </Grid>
  );
};
