import { z } from "zod";
import { zodSchema } from "@health/autocompletes";
import { i18n } from "@toolkit/i18n";
export const orderCancelFormSchema = z
    .object({
    reason: zodSchema.cancellationReason,
    otherReason: z.string().nullish(),
})
    .superRefine((data, ctx) => {
    var _a, _b, _c;
    if (((_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.reason) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.text) === null || _c === void 0 ? void 0 : _c.toLowerCase()) === "other" && !(data === null || data === void 0 ? void 0 : data.otherReason)) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18n.t("Required", { ns: "domains" }),
            path: ["otherReason"],
        });
    }
});
export const orderCancelFormDefaultValues = {
    reason: undefined,
    otherReason: undefined,
};
