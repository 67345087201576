import { PermissionEnum } from "@/schema/types";
import { hasPermission } from "@toolkit/core";
import { ClickAwayListener, CloseIcon, CompleteIcon, CopyIcon, EditIcon, Grid, IconButton } from "@toolkit/ui";
import { useSurveyDetailsContext } from "pages/Surveys/state/context";
import { useFormContext } from "react-hook-form";
import OptionItem from "./OptionItem";
import { useQuestionCardStyles } from "./QuestionCard.styles";
import { QuestionOptionProps } from "./types";

const QuestionOption: React.FC<QuestionOptionProps> = ({
  questionIndex,
  allOptions,
  option,
  isHovered,
  type,
  onCopyOption,
  onRemoveOption,
}: QuestionOptionProps) => {
  const { classes, theme } = useQuestionCardStyles();
  const { questionOptionState, setSelectOptionForEdit, resetQuestionOptionState, updateQuestionOptionState } = useSurveyDetailsContext();
  const isEditing = questionOptionState.questionIndex === questionIndex && questionOptionState.selectedForEdit === option;
  const canManageSurvey = hasPermission(PermissionEnum.ManageSurveys);
  const { setValue } = useFormContext();

  const handleSelectOptionForEdit = () => {
    setSelectOptionForEdit(option, questionIndex);
  };

  const handleCloseEditing = () => {
    resetQuestionOptionState();
  };

  const handleClickAway = () => {
    if (isEditing) {
      handleCloseEditing();
    }
  };

  const handleSaveOptionChanges = () => {
    const newOptionIndex = allOptions.indexOf(questionOptionState.newOption!);
    const oldOptionIndex = allOptions.indexOf(questionOptionState.selectedForEdit!);
    const isOptionDuplicated = newOptionIndex > -1 && oldOptionIndex > -1 && newOptionIndex != oldOptionIndex;
    if (isOptionDuplicated) {
      updateQuestionOptionState({ isOptionDuplicated });
    } else {
      const newOptions = allOptions.slice();
      newOptions[oldOptionIndex] = questionOptionState.newOption!;
      setValue(`questions.${questionIndex}.options`, newOptions);
      resetQuestionOptionState();
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Grid
        item
        xs={12}
        container
        alignItems='center'
        justifyContent='space-between'
        sx={{ background: isHovered ? theme.palette.gray.light : undefined }}
      >
        <Grid item xs container alignItems='center'>
          <OptionItem item={option} type={type} isEditing={isEditing && canManageSurvey} onSubmit={handleSaveOptionChanges} />
        </Grid>
        <Grid item sx={{ visibility: (isHovered || isEditing) && canManageSurvey ? "visible" : "hidden" }}>
          {isEditing ? (
            <>
              <IconButton disabled={questionOptionState.isOptionDuplicated} size='small' color='primary' onClick={handleSaveOptionChanges}>
                <CompleteIcon
                  fontSize='small'
                  className={classes.icon}
                  fill={theme.palette.primary.main}
                  sx={{
                    fontSize: theme.mixins.fonts.fontSize.xs,
                  }}
                />
              </IconButton>
              <IconButton size='small' color='primary' onClick={handleCloseEditing}>
                <CloseIcon
                  fontSize='small'
                  className={classes.icon}
                  fill={theme.palette.primary.main}
                  sx={{
                    fontSize: theme.mixins.fonts.fontSize.xs,
                  }}
                />
              </IconButton>
            </>
          ) : (
            <>
              <IconButton size='small' color='primary' onClick={handleSelectOptionForEdit}>
                <EditIcon
                  fontSize='small'
                  className={classes.icon}
                  fill={theme.palette.primary.main}
                  sx={{
                    fontSize: theme.mixins.fonts.fontSize.xs,
                  }}
                />
              </IconButton>
              <IconButton size='small' color='primary' onClick={onCopyOption}>
                <CopyIcon fontSize='small' className={classes.icon} />
              </IconButton>
              <IconButton size='small' color='primary' onClick={onRemoveOption}>
                <CloseIcon
                  fontSize='small'
                  className={classes.icon}
                  fill={theme.palette.primary.main}
                  sx={{
                    fontSize: theme.mixins.fonts.fontSize.xs,
                  }}
                />
              </IconButton>
            </>
          )}
        </Grid>
      </Grid>
    </ClickAwayListener>
  );
};

QuestionOption.defaultProps = {
  option: null,
};

export default QuestionOption;
