import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BranchUsersQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type BranchUsersQuery = { __typename?: 'Query', branch?: { __typename?: 'Branch', id: string, name: string, users?: Array<{ __typename?: 'User', id: string, email?: string | null, firstName?: string | null, lastName?: string | null, mobile?: string | null, isStaff?: boolean | null, isSuperuser?: boolean | null, isVendorAdmin?: boolean | null, note?: string | null, healthLicenseNumber?: string | null, healthLicenseStartDate?: any | null, healthLicenseEndDate?: any | null, appType?: Types.AppTypes | null, appRole?: Types.AppRoleTypes | null, isActive: boolean } | null> | null } | null };


export const BranchUsersDocument = gql`
    query branchUsers($id: ID!) {
  branch(id: $id) {
    id
    name
    users {
      id
      email
      firstName
      lastName
      mobile
      isStaff
      isSuperuser
      isVendorAdmin
      note
      healthLicenseNumber
      healthLicenseStartDate
      healthLicenseEndDate
      appType
      appRole
      isActive
    }
  }
}
    `;

/**
 * __useBranchUsersQuery__
 *
 * To run a query within a React component, call `useBranchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBranchUsersQuery(baseOptions: Apollo.QueryHookOptions<BranchUsersQuery, BranchUsersQueryVariables> & ({ variables: BranchUsersQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BranchUsersQuery, BranchUsersQueryVariables>(BranchUsersDocument, options);
      }
export function useBranchUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BranchUsersQuery, BranchUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BranchUsersQuery, BranchUsersQueryVariables>(BranchUsersDocument, options);
        }
export function useBranchUsersSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BranchUsersQuery, BranchUsersQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BranchUsersQuery, BranchUsersQueryVariables>(BranchUsersDocument, options);
        }
export type BranchUsersQueryHookResult = ReturnType<typeof useBranchUsersQuery>;
export type BranchUsersLazyQueryHookResult = ReturnType<typeof useBranchUsersLazyQuery>;
export type BranchUsersSuspenseQueryHookResult = ReturnType<typeof useBranchUsersSuspenseQuery>;
export type BranchUsersQueryResult = Apollo.QueryResult<BranchUsersQuery, BranchUsersQueryVariables>;