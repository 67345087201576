import { MarketplaceBrand } from "@/schema/types";
import { formGirdSpacing, useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, ImageUploadController, PageWrapper } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useOidcUserProfile } from "shared/hooks/useOidcUserProfile";
import { brandsPaths } from "../../constants";
import { convertBrandToFormValues } from "../../utils";
import { BrandInformationForm } from "../BrandFromInformation";
import { BrandUpsertFormSchema, brandUpsertFormSchemaDefaultValues, IBrandUpsertFormValues } from "./BrandUpsertFormSchema";

type BrandUpsertFormProps = {
  brand?: MarketplaceBrand;
  isLoading?: boolean;
  isSubmitting?: boolean;
  submitButtonLabel: string;
  onChange: (values: IBrandUpsertFormValues) => void;
};

export const BrandUpsertForm: FC<BrandUpsertFormProps> = props => {
  const { brand, isLoading, isSubmitting, submitButtonLabel, onChange } = props;
  const navigate = useNavigate();
  const form = useCustomForm<IBrandUpsertFormValues>({
    schema: BrandUpsertFormSchema,
    defaultValues: brandUpsertFormSchemaDefaultValues,
    mode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors },
    setValues,
  } = form;
  const { t } = useTranslation("domains");
  const isButtonDisabled = isLoading || isSubmitting;
  const { accessToken } = useOidcUserProfile();

  const onSubmit = (values: IBrandUpsertFormValues) => {
    onChange(values);
  };
  const handleCancel = () => {
    navigate(brandsPaths.main.fullPath);
  };
  useEffect(() => {
    if (brand) {
      const _brand = convertBrandToFormValues(brand);

      setValues(_brand);
    }
  }, [brand, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={submitButtonLabel}
              onNavigation={handleCancel}
              newButtonDisabled={isButtonDisabled}
            />
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <FormCard title={t("Brand Information")} loading={isLoading} doYouHaveData>
                <BrandInformationForm />
              </FormCard>
            </Grid>
            <Grid item xs={12}>
              <FormCard title={t("Image")} loading={isLoading} doYouHaveData>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ImageUploadController
                      control={form.control}
                      name={"image"}
                      validationRation
                      error={!!errors?.image}
                      helperText={errors?.image?.message}
                      token={accessToken!}
                      defaultValue={brand?.image}
                    />
                  </Grid>
                </Grid>
              </FormCard>
            </Grid>
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
