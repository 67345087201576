import { i18n } from "@toolkit/i18n";
import { ProviderGuidedCareTeamStatus } from "../../schema/types";
export const providerGuidedCareTeamStatusOptionsMap = {
    [ProviderGuidedCareTeamStatus.Approved]: {
        key: ProviderGuidedCareTeamStatus.Approved,
        get label() {
            return i18n.t("Approved", { ns: "domains" });
        },
        value: ProviderGuidedCareTeamStatus.Approved,
    },
    [ProviderGuidedCareTeamStatus.Pending]: {
        key: ProviderGuidedCareTeamStatus.Pending,
        get label() {
            return i18n.t("Pending", { ns: "domains" });
        },
        value: ProviderGuidedCareTeamStatus.Pending,
    },
    [ProviderGuidedCareTeamStatus.Rejected]: {
        key: ProviderGuidedCareTeamStatus.Rejected,
        get label() {
            return i18n.t("Rejected", { ns: "domains" });
        },
        value: ProviderGuidedCareTeamStatus.Rejected,
    },
};
export const providerGuidedCareTeamStatusOptions = Object.values(providerGuidedCareTeamStatusOptionsMap);
