import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const OptimaActionGetDocument = gql `
    query OptimaActionGet($id: ID!) {
  optimaAction(id: $id) {
    id
    link
    action
    summary
    isActive
  }
}
    `;
/**
 * __useOptimaActionGetQuery__
 *
 * To run a query within a React component, call `useOptimaActionGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptimaActionGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptimaActionGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOptimaActionGetQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(OptimaActionGetDocument, options);
}
export function useOptimaActionGetLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(OptimaActionGetDocument, options);
}
export function useOptimaActionGetSuspenseQuery(baseOptions) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(OptimaActionGetDocument, options);
}
