import { i18n } from "@toolkit/i18n";
import { PayerType } from "../../schema/types";
export const payerTypeOptionsMap = {
    [PayerType.Insurance]: {
        key: PayerType.Insurance,
        get label() {
            return i18n.t("Insurance", { ns: "domains" });
        },
        value: PayerType.Insurance,
    },
    [PayerType.Tpa]: {
        key: PayerType.Tpa,
        get label() {
            return i18n.t("Tpa", { ns: "domains" });
        },
        value: PayerType.Tpa,
    },
};
export const payerTypeOptions = Object.values(payerTypeOptionsMap);
