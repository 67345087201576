var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useGetEventList } from "@sada/react/queries";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, mapToAutocompleteOptions } from "@toolkit/ui";
import React from "react";
export const SadaVideosAutocomplete = props => {
    const { label } = props, rest = __rest(props, ["label"]);
    const { t } = useTranslation("domains");
    const { events, loading, hasMore, fetchMore } = useGetEventList({ type: "vod" });
    const _data = events === null || events === void 0 ? void 0 : events.map(event => ({
        id: event === null || event === void 0 ? void 0 : event.slug,
        title: event === null || event === void 0 ? void 0 : event.title,
    }));
    const options = mapToAutocompleteOptions(_data, "id", "title");
    return (React.createElement(FormAutocomplete, Object.assign({}, rest, { label: label || (props.multiple ? t("Videos") : t("Video")), options: options, loading: loading, hasMore: hasMore, handleFetchMore: fetchMore })));
};
