import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const MarketplaceOrderCancelBySupportTeamDocument = gql `
    mutation MarketplaceOrderCancelBySupportTeam($id: ID!, $cancellationReason: String!) {
  marketplaceOrderCancelBySupportTeam(
    cancellationReason: $cancellationReason
    id: $id
  ) {
    id
  }
}
    `;
/**
 * __useMarketplaceOrderCancelBySupportTeamMutation__
 *
 * To run a mutation, you first call `useMarketplaceOrderCancelBySupportTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceOrderCancelBySupportTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketplaceOrderCancelBySupportTeamMutation, { data, loading, error }] = useMarketplaceOrderCancelBySupportTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *      cancellationReason: // value for 'cancellationReason'
 *   },
 * });
 */
export function useMarketplaceOrderCancelBySupportTeamMutation(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useMutation(MarketplaceOrderCancelBySupportTeamDocument, options);
}
