import { HealthProgramNetworkProvider } from "@/schema/types";
import { VendorsAutocomplete } from "@health/autocompletes";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, FormChipsInput, FormSwitch, Grid, PageWrapper } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { healthProgramNetworksPaths } from "../../constants";
import { convertHealthProgramNetworkProviderToFormValues } from "../../others";
import {
  healthProgramNetworkProviderUpsertFormDefaultValues,
  healthProgramNetworkProviderUpsertFormSchema,
  IHealthProgramNetworkProviderUpsertFormValues,
} from "./HealthProgramNetworkProviderUpsertFormSchema";

export type IHealthProgramNetworkProviderUpsertFormEvent = {
  type: "SUBMIT";
  payload: {
    values: IHealthProgramNetworkProviderUpsertFormValues;
  };
};

type HealthProgramNetworkProviderUpsertFormProps = {
  networkId: string;
  healthProgramNetworkProvider?: HealthProgramNetworkProvider;
  buttonLabel: string;
  isLoading?: boolean;
  isSubmitting?: boolean;
  onChange: (event: IHealthProgramNetworkProviderUpsertFormEvent) => void;
};

export const HealthProgramNetworkProviderUpsertForm: FC<HealthProgramNetworkProviderUpsertFormProps> = props => {
  const { networkId, healthProgramNetworkProvider, isLoading, isSubmitting, buttonLabel, onChange } = props;

  const { t } = useTranslation("admin");
  const navigate = useNavigate();

  const form = useCustomForm<IHealthProgramNetworkProviderUpsertFormValues>({
    defaultValues: healthProgramNetworkProviderUpsertFormDefaultValues,
    schema: healthProgramNetworkProviderUpsertFormSchema,
  });

  const { handleSubmit, setValues } = form;

  const onSubmit = (values: IHealthProgramNetworkProviderUpsertFormValues) => {
    onChange({
      type: "SUBMIT",
      payload: { values },
    });
  };

  const handleCancel = () => {
    navigate(healthProgramNetworksPaths.providerList.fullPathWithParams({ networkId }));
  };

  useEffect(() => {
    if (healthProgramNetworkProvider) {
      const _healthProgramNetworkProvider = convertHealthProgramNetworkProviderToFormValues(healthProgramNetworkProvider);
      setValues(_healthProgramNetworkProvider);
    }
  }, [healthProgramNetworkProvider, setValues]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <FormActions
              hasCancel
              hasFormButton
              isLoading={isLoading}
              formButtonTitle={buttonLabel}
              newButtonDisabled={isLoading || isSubmitting}
              onNavigation={handleCancel}
            />
          }
        >
          <FormCard title={t("Network Provider Information")} loading={isLoading} doYouHaveData>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <VendorsAutocomplete name={"vendor"} filter={{ isActive: true }} />
              </Grid>

              <Grid item xs={3}>
                <FormChipsInput name={"services"} placeholder={t("Services")} />
              </Grid>

              <Grid item xs={12}>
                <FormSwitch name={"isVirtualCallEnabled"} label={t("Is Virtual Call Enabled")} />
              </Grid>

              <Grid item xs={12}>
                <FormSwitch name={"isActive"} label={t("Is Active")} />
              </Grid>
            </Grid>
          </FormCard>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
