import {
  BACKEND_DATE_FORMAT,
  combineErrors,
  formGirdBreakPoints,
  getNumericValidation,
  getRequiredValidation,
  NumericPattern,
} from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomDatePicker, FormCard, Grid } from "@toolkit/ui";
import moment from "moment";
import { FC, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { CustomTextField } from "../CustomTextField.components";
import { FormDataProps } from "../types";

export const HealthLicense: FC<FormDataProps> = ({ isLoading, doYouHaveVendor, errorsInput }) => {
  const { t } = useTranslation("admin");
  const {
    control,
    formState: { errors },
    register,
    setValue,
  } = useFormContext();
  const formErrors = combineErrors(errors, errorsInput);
  const [startValue, endValue] = useWatch({ control, name: ["healthLicenseStartDate", "healthLicenseEndDate"] });
  useEffect(() => {
    setValue("healthLicenseStartDate", null);
    setValue("healthLicenseEndDate", null);
  }, []);
  const todayDate = moment().startOf("day");
  return (
    <FormCard title={t("Health License")} loading={isLoading} doYouHaveData={doYouHaveVendor}>
      <Grid container spacing={2}>
        <Grid item {...formGirdBreakPoints}>
          <CustomTextField
            name='healthLicenseNumber'
            placeholder={t("Health License Number")}
            label={t("Health License Number")}
            isRequired
            registerProps={{ pattern: getNumericValidation(t, NumericPattern) }}
          />
        </Grid>
        <Grid item {...formGirdBreakPoints}>
          <CustomDatePicker
            control={control}
            label={t("Health License Start Date")}
            placeholder={t("Health License Start Date")}
            error={Boolean(formErrors?.healthLicenseStartDate?.message)}
            helperText={t(formErrors?.healthLicenseStartDate?.message)}
            {...register("healthLicenseStartDate", {
              validate: value => {
                const start = moment(value, BACKEND_DATE_FORMAT);
                const end = moment(endValue, BACKEND_DATE_FORMAT);
                if (start.isValid() && end.isValid() && start.isSameOrAfter(end)) {
                  return t("health-license-start-date", { end: end.format(BACKEND_DATE_FORMAT) });
                }
                if (start.isSameOrAfter(todayDate)) return t("health-license-start-date", { end: todayDate.format(BACKEND_DATE_FORMAT) });
              },
              required: getRequiredValidation(t, !!doYouHaveVendor),
            })}
          />
        </Grid>
        <Grid item {...formGirdBreakPoints}>
          <CustomDatePicker
            control={control}
            label={t("Health License End Date")}
            placeholder={t("Health License End Date")}
            error={Boolean(formErrors?.healthLicenseEndDate?.message)}
            helperText={t(formErrors?.healthLicenseEndDate?.message)}
            {...register("healthLicenseEndDate", {
              validate: value => {
                const start = moment(startValue, BACKEND_DATE_FORMAT);
                const end = moment(value, BACKEND_DATE_FORMAT);
                if (start.isValid() && end.isValid() && end.isSameOrBefore(start))
                  return t("health-license-end-date", { start: start.format(BACKEND_DATE_FORMAT) });
                if (end.isSameOrBefore(todayDate)) t("health-license-end-date", { start: todayDate.format(BACKEND_DATE_FORMAT) });
              },
              required: getRequiredValidation(t, !!doYouHaveVendor),
            })}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};
