import { H_Gender, H_IdTypes, HealthProgramMember, HealthProgramMemberInput } from "@/schema/types";
import {
  createHealthProgramMemberListAutocompleteOption,
  createPayerAutocompleteOption,
  createSystemCodeAutocompleteOption,
} from "@health/autocompletes";
import { coverageByTypeOptionsMap, patientIdTypeOptionsMap, userGenderOptionsMap } from "@health/enum-options";
import { IHealthProgramMemberUpsertFormValues } from "../forms/HealthProgramMemberUpsert/HealthProgramMemberUpsertFormSchema";

export const convertHealthProgramMemberToFormValues = (healthProgramMember: HealthProgramMember): IHealthProgramMemberUpsertFormValues => {
  return {
    firstName: healthProgramMember?.firstName!,
    lastName: healthProgramMember?.lastName!,
    gender: userGenderOptionsMap[healthProgramMember?.gender!],
    dateOfBirth: healthProgramMember?.dateOfBirth,
    nationalId: healthProgramMember?.nationalId!,
    iOHealthId: healthProgramMember?.sehacityId!,
    patientIdType: patientIdTypeOptionsMap[healthProgramMember?.idType!],
    nationality: createSystemCodeAutocompleteOption({
      code: healthProgramMember?.nationality,
      display: healthProgramMember?.nationalityDisplay,
    }),
    documentExpiryDate: healthProgramMember?.documentExpiryDate,
    phoneNumber: healthProgramMember?.phone!,
    email: healthProgramMember?.email!,
    memberLists: healthProgramMember?.healthProgramMemberLists?.edges?.length
      ? healthProgramMember?.healthProgramMemberLists?.edges?.map(item => createHealthProgramMemberListAutocompleteOption(item?.node!))
      : [],
    network: healthProgramMember?.network!,
    payer: createPayerAutocompleteOption(healthProgramMember?.payer!),
    coverageByType: coverageByTypeOptionsMap[healthProgramMember?.coverageBy!],
    memberShipStartDate: healthProgramMember?.membershipStart,
    memberShipEndDate: healthProgramMember?.membershipEnd,
    insuranceId: healthProgramMember?.insuranceId!,
    insurancePolicyNumber: healthProgramMember?.insurancePolicyNumber!,
  };
};

export const convertHealthProgramMemberFormValuesToBackEndValues = (
  values: IHealthProgramMemberUpsertFormValues
): HealthProgramMemberInput => {
  return {
    firstName: values?.firstName,
    lastName: values?.lastName,
    gender: values?.gender?.value as unknown as H_Gender,
    dateOfBirth: values?.dateOfBirth,
    nationalId: values?.nationalId,
    sehacityId: values?.iOHealthId,
    idType: values?.patientIdType?.value as unknown as H_IdTypes,
    nationality: values?.nationality?.value?.code!,
    documentExpiryDate: values?.documentExpiryDate,
    phone: values?.phoneNumber,
    email: values?.email,
    healthProgramMemberLists: values?.memberLists?.map(item => item?.value?.id),
    network: values?.network,
    insuranceCompanyId: values?.payer?.value?.id,
    coverageBy: values?.coverageByType?.value,
    membershipStart: values?.memberShipStartDate,
    membershipEnd: values?.memberShipEndDate,
    insuranceId: values?.insuranceId,
    insurancePolicyNumber: values?.insurancePolicyNumber,
  };
};
