import { i18n } from "@toolkit/i18n";
import { MedicalMessageType } from "../../schema/types";
export const medicalMessageTypeOptionsMap = {
    [MedicalMessageType.Article]: {
        key: MedicalMessageType.Article,
        get label() {
            return i18n.t("Article", { ns: "domains" });
        },
        value: MedicalMessageType.Article,
    },
    [MedicalMessageType.Text]: {
        key: MedicalMessageType.Text,
        get label() {
            return i18n.t("Text", { ns: "domains" });
        },
        value: MedicalMessageType.Text,
    },
    [MedicalMessageType.Video]: {
        key: MedicalMessageType.Video,
        get label() {
            return i18n.t("Video", { ns: "domains" });
        },
        value: MedicalMessageType.Video,
    },
};
export const medicalMessageTypeOptions = Object.values(medicalMessageTypeOptionsMap);
