import { HealthProgramMember, VisitSortField } from "@/schema/types";
import { PatientContactInformation } from "@/shared/components/PatientContactInformation/PatientContactInformation";
import { PatientInsuranceInformation } from "@/shared/components/PatientInsuranceInformation/PatientInsuranceInformation";
import { getBranchesAutocompleteFilter, getVendorDepartmentsAutocompleteFilter } from "@health/autocompletes";
import { getAutocompleteEnumFilter, paymentStatusOptionsMap, visitStatusOptionsMap, visitTypeOptionsMap } from "@health/enum-options";
import { BACKEND_DATE_TIME_FORMAT } from "@toolkit/core";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, TruncateTypography, format } from "@toolkit/ui";
import { produce } from "immer";
import moment from "moment";
import { useMemo } from "react";
import { VisitCancel } from "../../components/VisitCancel/VisitCancel";
import { IVisitListNode } from "../../types";

export type VisitsColumnsTypes = CustomTableColumnProps<IVisitListNode>[];

export const useVisitListContainerColumns = (): VisitsColumnsTypes => {
  const { t } = useTranslation("admin");

  return useMemo(() => {
    return [
      {
        key: "id",
        header: t("ID"),
        accessor: "id",
        filter: {
          name: "id",
          type: "string",
        },
      },
      {
        key: "patient",
        header: t("Patient"),
        accessor: ({ patient }) => <TruncateTypography text={`${patient?.firstName} ${patient?.lastName}`} />,
        filter: {
          type: "string",
          name: "patientName",
        },
      },
      {
        key: "contactInfo",
        header: t("Contact Info"),
        accessor: ({ patient }) => <PatientContactInformation contactNumber={patient?.contactNumber!} email={patient?.email!} />,
      },
      {
        key: "visitTime",
        header: t("Visit Time"),
        accessor: ({ created }) => (created ? format(created, "DD MMMM YYYY | HH:mm A") : undefined),
        isSortable: true,
        sortColumnEnum: VisitSortField.Date,
      },
      {
        key: "doctor",
        header: t("Doctor"),
        accessor: ({ doctor }) => <TruncateTypography text={doctor?.user?.fullName || "-"} />,
      },
      {
        key: "provider",
        header: t("Provider"),
        accessor: ({ branch }) => <TruncateTypography text={pickLocalizedValue(branch?.vendor?.name, branch?.vendor?.nameAr) || "-"} />,
      },
      {
        key: "branch",
        header: t("Branch"),
        accessor: ({ branch }) => <TruncateTypography text={pickLocalizedValue(branch?.name, branch?.nameAr) || "-"} />,
        filter: getBranchesAutocompleteFilter({ name: "branches", multiple: true }),
      },
      {
        key: "type",
        header: t("Type"),
        accessor: ({ type }) => visitTypeOptionsMap[type!]?.label,
      },
      {
        key: "status",
        header: t("Status"),
        accessor: ({ status }) => visitStatusOptionsMap[status!]?.label,
        filter: getAutocompleteEnumFilter("VisitStatus", "status"),
      },
      {
        key: "payment",
        header: t("Payment"),
        accessor: ({ paymentStatus }) => paymentStatusOptionsMap[paymentStatus!]?.label || "-",
      },
      {
        key: "insurance",
        header: t("Insurance"),
        accessor: ({ patient }) => (
          <PatientInsuranceInformation healthProgramMembers={patient?.activeHealthProgramMembers as HealthProgramMember[]} />
        ),
      },
      {
        key: "cancellationReason",
        header: t("Cancellation Reason"),
        accessor: ({ cancellationReason }) => <TruncateTypography text={cancellationReason || "-"} />,
      },
      {
        key: "cancel",
        header: t("Cancel"),
        accessor: ({ id, status }) => <VisitCancel id={id} status={status} />,
      },
      {
        key: "startDate",
        header: t("Visit Date From"),
        showOnlyForFilterField: true,
        filter: {
          type: "dateTime",
          name: "date.gte",
          label: t("Visit Date From"),
        },
      },
      {
        key: "endDate",
        header: t("Visit Date To"),
        showOnlyForFilterField: true,
        filter: {
          type: "dateTime",
          name: "date.lte",
          getValueForBackend: value =>
            produce(value, draft => {
              draft["gte"] = draft?.gte ? moment(draft?.gte).format(BACKEND_DATE_TIME_FORMAT) : null;
              draft["lte"] = draft?.lte ? moment(draft?.lte).format(BACKEND_DATE_TIME_FORMAT) : null;
            }),
          label: t("Visit Date To"),
        },
      },
      {
        key: "careType",
        header: t("Care Type"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("VisitCareType", "careType"),
      },
      {
        key: "careInitialType",
        header: t("Care Initial Type"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("CareType", "careInitialType"),
      },
      {
        key: "visitTypeFilter",
        header: t("Visit Type"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("VisitType", "types"),
      },
      {
        key: "departments",
        header: t("Departments"),
        showOnlyForFilterField: true,
        filter: getVendorDepartmentsAutocompleteFilter({ name: "departments", multiple: true }),
      },
    ];
  }, [t]);
};
