import { AccountError, AdminUserCreateInput, AppRoleTypes, AppTypes } from "@/schema/types";
import { formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import {
  adminUserManagementListBreadcrumb,
  adminUserManagementNewBreadcrumb,
  adminUserManagementTitleBreadcrumb,
} from "pages/UserManagement/constants/admin-user-management-breadcrumbs";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CreateUserMutation, useCreateUserMutation } from "../../gql";

export const useAdminUserManagementAddHook = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const { succeeded, failed } = useAddToast();
  const [errors, setErrors] = React.useState<AccountError[]>([]);
  const [createUserMutation, { loading: isCreateLoading }] = useCreateUserMutation({
    onCompleted: (mutationData: CreateUserMutation) => {
      const error = mutationData?.userCreate?.accountErrors as AccountError[];
      if (error && error?.length > 0) {
        failed(t(formatMessageErrors(error)));
        setErrors(error);
      } else {
        succeeded(t("User created successfully"));
        navigate(-1);
      }
    },
    onError: () => {
      failed(t("Failed in creating user"));
    },
  });

  const handleCreate = (inputData: AdminUserCreateInput | any) => {
    const request: AdminUserCreateInput = {
      ...inputData,
      addGroups: inputData?.addGroups?.map(group => group?.id),
      appType: AppTypes.Admin,
      appRole: inputData?.appRole?.value,
    };
    if (request?.appRole == AppRoleTypes.Admin) {
      delete request?.addGroups;
    }
    createUserMutation({
      variables: {
        input: request,
      },
    });
  };
  const { setBreadCrumb } = useBreadCrumbs();

  useEffect(() => {
    setBreadCrumb({
      title: adminUserManagementTitleBreadcrumb(t),
      values: [adminUserManagementListBreadcrumb(t), adminUserManagementNewBreadcrumb(t)],
    });
  }, []);

  return {
    errors,
    handleCreate,
    isCreateLoading,
  };
};
