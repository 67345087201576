import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { PermissionGroupFragmentFragmentDoc, PermissionGroupErrorFragmentFragmentDoc } from '../../fragments/__generated__/permissionFragment';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePermissionMutationVariables = Types.Exact<{
  input: Types.PermissionGroupCreateInput;
}>;


export type CreatePermissionMutation = { __typename?: 'Mutation', permissionGroupCreate?: { __typename?: 'PermissionGroupCreate', group?: { __typename?: 'Group', id: string, name: string, permissions?: Array<{ __typename?: 'Permission', code?: Types.PermissionEnum | null, name: string } | null> | null } | null, permissionGroupErrors: Array<{ __typename?: 'PermissionGroupError', code: Types.PermissionGroupErrorCode, field?: string | null, message?: string | null }> } | null };


export const CreatePermissionDocument = gql`
    mutation createPermission($input: PermissionGroupCreateInput!) {
  permissionGroupCreate(input: $input) {
    group {
      ...PermissionGroupFragment
    }
    permissionGroupErrors {
      ...PermissionGroupErrorFragment
    }
  }
}
    ${PermissionGroupFragmentFragmentDoc}
${PermissionGroupErrorFragmentFragmentDoc}`;
export type CreatePermissionMutationFn = Apollo.MutationFunction<CreatePermissionMutation, CreatePermissionMutationVariables>;

/**
 * __useCreatePermissionMutation__
 *
 * To run a mutation, you first call `useCreatePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPermissionMutation, { data, loading, error }] = useCreatePermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePermissionMutation(baseOptions?: Apollo.MutationHookOptions<CreatePermissionMutation, CreatePermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePermissionMutation, CreatePermissionMutationVariables>(CreatePermissionDocument, options);
      }
export type CreatePermissionMutationHookResult = ReturnType<typeof useCreatePermissionMutation>;
export type CreatePermissionMutationResult = Apollo.MutationResult<CreatePermissionMutation>;
export type CreatePermissionMutationOptions = Apollo.BaseMutationOptions<CreatePermissionMutation, CreatePermissionMutationVariables>;