import { H_OrderDirection, HealthProgramMembershipRequestSortingField } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { insuranceApprovalsTitleBreadcrumb } from "pages/InsuranceApprovals/constants/insurance-approvals-breadcrumbs";
import { useEffect } from "react";
import { useHealthProgramMembershipRequestsQuery } from "../gql";
import { useInsuranceCardsListColumns } from "./useInsuranceCardsListColumns";

export const InsuranceCardsList = () => {
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();

  useEffect(() => {
    setBreadCrumb({
      title: insuranceApprovalsTitleBreadcrumb(t),
    });
  }, []);

  return (
    <GridProvider
      gridName='insuranceCardsList'
      dataAccessor='healthProgramMembershipRequests'
      columns={useInsuranceCardsListColumns()}
      query={useHealthProgramMembershipRequestsQuery}
      hasTableSetting
      variables={{
        sortBy: {
          direction: H_OrderDirection.Desc,
          field: HealthProgramMembershipRequestSortingField.Created,
        },
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
