import React, { FC } from "react";
import { Dialog, DialogContent, IconButton } from "../../base/mui";
import { MuiCloseIcon } from "../../base/mui-icons";
import { styles } from "./styles";
import { ZoomImage } from "./types";

const ZoomUploadedImage: FC<ZoomImage> = ({ open, onClose: handleClose, uploadedImage }) => {
  const { classes } = styles();

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <DialogContent className={classes.content}>
        <img src={uploadedImage} alt='ZoomUploadedImage' />
        <IconButton size='small' color='default' onClick={handleClose} className={classes.zoomIcon}>
          <MuiCloseIcon />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

export default ZoomUploadedImage;
