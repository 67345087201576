import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CityGetQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type CityGetQuery = { __typename?: 'Query', city?: { __typename?: 'City', id: string, name: string, nameAr?: string | null, area?: string | null } | null };


export const CityGetDocument = gql`
    query CityGet($id: ID!) {
  city(id: $id) {
    id
    name
    nameAr
    area
  }
}
    `;

/**
 * __useCityGetQuery__
 *
 * To run a query within a React component, call `useCityGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCityGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCityGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCityGetQuery(baseOptions: Apollo.QueryHookOptions<CityGetQuery, CityGetQueryVariables> & ({ variables: CityGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CityGetQuery, CityGetQueryVariables>(CityGetDocument, options);
      }
export function useCityGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CityGetQuery, CityGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CityGetQuery, CityGetQueryVariables>(CityGetDocument, options);
        }
export function useCityGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CityGetQuery, CityGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CityGetQuery, CityGetQueryVariables>(CityGetDocument, options);
        }
export type CityGetQueryHookResult = ReturnType<typeof useCityGetQuery>;
export type CityGetLazyQueryHookResult = ReturnType<typeof useCityGetLazyQuery>;
export type CityGetSuspenseQueryHookResult = ReturnType<typeof useCityGetSuspenseQuery>;
export type CityGetQueryResult = Apollo.QueryResult<CityGetQuery, CityGetQueryVariables>;