import { PermissionEnum } from "@/schema/types";
import { hasPermission } from "@toolkit/core";
import { TFunction } from "@toolkit/i18n";
import { CardsIcon } from "@toolkit/ui";
import { insuranceApprovalsPaths, insuranceApprovalsRoute } from "pages/InsuranceApprovals/constants/insurance-approvals-paths";
import { NavigateFunction } from "react-router-dom";
import RouteItem from "shared/components/Root/routeTypes";
import { InsuranceCardsList } from "./list";

export const insuranceApprovalsRoutes: (props: { navigate: NavigateFunction; t: TFunction }) => RouteItem = ({ navigate, t }) => {
  {
    return {
      id: "insurance-approvals-main-route",
      text: t("Insurance Approvals", { ns: "admin" }),
      icon: <CardsIcon />,
      route: insuranceApprovalsRoute,
      fullPath: insuranceApprovalsPaths.mainPath.fullPath,
      isProhibited: !hasPermission(PermissionEnum.VerifyMemberIds),
      element: <InsuranceCardsList />,
      onClick: (route: string) => navigate(route),
    };
  }
};
