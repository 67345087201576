import { HealthProgramMember } from "@/schema/types";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Box, CustomDialog, ShowButton, Typography } from "@toolkit/ui";
import moment from "moment";
import { FC } from "react";
import { usePatientInsuranceInformationStyle } from "./usePatientInsuranceInformationStyle";

type PatientInsuranceInformationProps = {
  healthProgramMembers?: HealthProgramMember[];
};

export const PatientInsuranceInformation: FC<PatientInsuranceInformationProps> = props => {
  const { healthProgramMembers } = props;

  const { t } = useTranslation("admin");

  const { classes } = usePatientInsuranceInformationStyle();

  const patientActiveHealthProgram = healthProgramMembers?.[0];

  return (
    <CustomDialog type={"info"} title={t("Insurance Information")} button={<ShowButton disabled={!patientActiveHealthProgram} />}>
      <Box className={classes.wrapper}>
        <Box className={classes.innerWrapper}>
          <Typography className={classes.label}> {t("Company Name")}: </Typography>
          <Typography> {pickLocalizedValue(patientActiveHealthProgram?.payer?.name, patientActiveHealthProgram?.payer?.nameAr)}</Typography>
        </Box>

        <Box className={classes.innerWrapper}>
          <Typography className={classes.label}> {t("Card Id")}: </Typography>
          <Typography> {patientActiveHealthProgram?.insuranceId} </Typography>
        </Box>

        {patientActiveHealthProgram?.membershipStart && patientActiveHealthProgram?.membershipEnd && (
          <Box className={classes.innerWrapper}>
            <Typography className={classes.label}> {t("Date")}: </Typography>

            <Typography>
              {`${moment(patientActiveHealthProgram?.membershipStart).format("D MMM YYYY")} - ${moment(
                patientActiveHealthProgram?.membershipEnd
              ).format("D MMM YYYY")}`}
            </Typography>
          </Box>
        )}
      </Box>
    </CustomDialog>
  );
};
