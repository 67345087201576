import { AppTypes, OrderDirection, PermissionEnum, User, UserSortField } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { hasPermission } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { adminUserManagementTitleBreadcrumb } from "pages/UserManagement/constants/admin-user-management-breadcrumbs";
import { adminUserManagementPaths } from "pages/UserManagement/constants/admin-user-management-paths";
import { FC, useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";
import { useDeleteUserMutation, useGetAdminUsersQuery } from "../../gql";
import { useUserManagementColumns } from "./Columns/useUserManagementColumns";

const AdminUsersManagementList: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("admin");
  const { setBreadCrumb } = useBreadCrumbs();
  const { user } = useAuth();
  const currentUserId = btoa("User:" + user?.profile?.["user_id"]);
  const canManageUser = hasPermission(PermissionEnum.ManageUsers);
  const UsersColumns = useUserManagementColumns(currentUserId, canManageUser);

  const isCurrentUserRow = user => {
    return currentUserId === user?.id;
  };

  const handleAddNewUser = () => {
    navigate(adminUserManagementPaths.newPath.fullPath);
  };

  const handleEditUser = (user: User) => {
    navigate(adminUserManagementPaths.editPath.fullPathWithParams(user?.id));
  };

  useEffect(() => {
    setBreadCrumb({
      title: adminUserManagementTitleBreadcrumb(t),
    });
  }, []);

  return (
    <GridProvider
      filterInput={{
        appType: AppTypes.Admin,
      }}
      hideFilterInput
      hasTableSetting
      gridName='users'
      columns={UsersColumns}
      query={useGetAdminUsersQuery}
      variables={{
        sortBy: {
          direction: OrderDirection.Desc,
          field: UserSortField.DateJoined,
        },
        filter: {
          appType: [AppTypes.Admin],
        },
      }}
      tableAction={{
        isEditVisible: true,
        onEditRow: handleEditUser,
        isDeleteVisible: true,
        isRowDeletable: user => canManageUser && !isCurrentUserRow(user),
        isRowEditable: user => canManageUser && !isCurrentUserRow(user),
      }}
      deleteItemProps={{
        entityTypeName: "User",
        name: "Admin user",
        useDeleteMutation: useDeleteUserMutation,
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            {canManageUser ? <MainListActions hasAddNew onAddNewItem={handleAddNewUser} /> : ""}
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
export default AdminUsersManagementList;
