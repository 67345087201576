import { i18n } from "@toolkit/i18n";
import { VendorTypeEnum } from "../../schema/types";
export const vendorTypeOptionsMap = {
    [VendorTypeEnum.Clinic]: {
        key: VendorTypeEnum.Clinic,
        get label() {
            return i18n.t("Clinic", { ns: "domains" });
        },
        value: VendorTypeEnum.Clinic,
    },
    [VendorTypeEnum.DiagnosticCenter]: {
        key: VendorTypeEnum.DiagnosticCenter,
        get label() {
            return i18n.t("Diagnostic Center", { ns: "domains" });
        },
        value: VendorTypeEnum.DiagnosticCenter,
    },
    [VendorTypeEnum.Hospital]: {
        key: VendorTypeEnum.Hospital,
        get label() {
            return i18n.t("Hospital", { ns: "domains" });
        },
        value: VendorTypeEnum.Hospital,
    },
    [VendorTypeEnum.Pharmacy]: {
        key: VendorTypeEnum.Pharmacy,
        get label() {
            return i18n.t("Pharmacy", { ns: "domains" });
        },
        value: VendorTypeEnum.Pharmacy,
    },
};
export const vendorTypeOptions = Object.values(vendorTypeOptionsMap);
