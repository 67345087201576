import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { ActionFieldType } from "../../../../schema/types";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, FormNumberField, FormSwitch, FormTextField, Grid } from "@toolkit/ui";
import { camelCase, startCase } from "lodash";
import { DecisionActionAutocompletesForm } from "../../../Decisions/forms/DecisionActionAutocompletes/DecisionActionAutocompletesForm";
import { isCodeInDecisionActionFieldListCode } from "../../../Decisions/others/utils";
export const DecisionActionFieldsForm = props => {
    const { actionFields } = props;
    const { t } = useTranslation("domains");
    const renderActionField = (item, index) => {
        var _a;
        const display = startCase(camelCase((item === null || item === void 0 ? void 0 : item.display) || (item === null || item === void 0 ? void 0 : item.code)));
        const type = item === null || item === void 0 ? void 0 : item.type;
        if (type === ActionFieldType.Boolean) {
            return _jsx(FormSwitch, { name: `actionFields.${index}.valueBoolean`, label: display });
        }
        else if (type === ActionFieldType.Number) {
            return _jsx(FormNumberField, { name: `actionFields.${index}.valueNumber`, label: display, isFloatAllowed: true });
        }
        else if (type === ActionFieldType.String) {
            if (isCodeInDecisionActionFieldListCode(item === null || item === void 0 ? void 0 : item.code)) {
                return (_jsx(DecisionActionAutocompletesForm, { name: `actionFields.${index}.valueString`, display: display, code: item === null || item === void 0 ? void 0 : item.code }));
            }
            else if ((_a = item === null || item === void 0 ? void 0 : item.allowedValues) === null || _a === void 0 ? void 0 : _a.length) {
                return _jsx(FormAutocomplete, { name: `actionFields.${index}.valueString`, label: t(display), options: item.allowedValues });
            }
            else {
                return _jsx(FormTextField, { name: `actionFields.${index}.valueString`, label: t(display) });
            }
        }
        else if (type === ActionFieldType.List) {
            return (_jsx(DecisionActionAutocompletesForm, { name: `actionFields.${index}.valueList`, display: display, code: item === null || item === void 0 ? void 0 : item.code }));
        }
        else {
            return _jsx(_Fragment, { children: t("Not Supported Field") });
        }
    };
    return (_jsx(Grid, { container: true, spacing: 2, children: _jsx(Grid, { container: true, item: true, spacing: 2, children: actionFields === null || actionFields === void 0 ? void 0 : actionFields.map((item, index) => (_jsx(Grid, { item: true, xs: 12, md: 4, children: renderActionField(item, index) }, item === null || item === void 0 ? void 0 : item.code))) }) }));
};
