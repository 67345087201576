import { UserSortField } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { useTranslation } from "@toolkit/i18n";
import { Box, MainListActions, OrderDirection, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { staffUsersColumns } from "pages/PermissionsManagement/Users/list/usersColumns";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { ProviderCard } from "shared/components";
import { BranchCard } from "shared/components/BranchCard";
import { useBranchUsersQuery } from "./gql";
import { useBranchUsersHook } from "./uesBranchUsersHook";

const BranchUsersManagement: FC = () => {
  const { t } = useTranslation("admin");
  const params = useParams<{ idBranch?: string }>();
  const { loadingBranchUsers, branch, vendor } = useBranchUsersHook();
  return (
    <GridProvider
      hasTableSetting
      gridName='adminBranchUsers'
      columns={staffUsersColumns(t)}
      query={useBranchUsersQuery}
      variables={{
        id: params.idBranch!,
        sortBy: {
          direction: OrderDirection.Desc,
          field: UserSortField.DateJoined,
        },
      }}
      dataAccessor='users'
    >
      <PageWrapper
        actions={
          <>
            <TableSettingComponent />
            <MainListActions />
          </>
        }
      >
        <Box sx={{ marginBottom: 2 }}>
          <ProviderCard vendor={vendor} loading={loadingBranchUsers} />
        </Box>
        <Box sx={{ marginBottom: 2 }}>
          <BranchCard branch={branch} loading={loadingBranchUsers} />
        </Box>
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
export default BranchUsersManagement;
