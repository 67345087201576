import { UserStatus } from "../../../../schema/types";
import { formatGraphQLError } from "@toolkit/apollo";
import { FIELD_IS_REQUIRED_MSG, useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useAutocompleteCustomHook } from "@toolkit/ui";
import { useEffect, useState } from "react";
import { useAssignCodeSystemEditorAndReviewerMutation, useMedlistUsersQuery } from "../../gql";
export const useAMLAssignReviewersHook = (props) => {
    var _a;
    const { succeeded, failed } = useAddToast();
    const { open: isOpen, handleOpen, handleClose } = useOpenState();
    const { t } = useTranslation("domains");
    const [reviewer, setReviewer] = useState(() => props.reviewerUser);
    const [editor, setEditor] = useState(() => props.editorUser);
    const [errors, setErrors] = useState({
        editorId: undefined,
        reviewerId: undefined,
    });
    useEffect(() => {
        setEditor(props.editorUser);
    }, [props.editorUser]);
    useEffect(() => {
        setReviewer(props.reviewerUser);
    }, [props.reviewerUser]);
    const { data: _users, isLoading: isLoading, onFetchMoreData: onFetchMoreUsers, } = useAutocompleteCustomHook({
        useAutocompleteControllerQuery: useMedlistUsersQuery,
        input: {
            first: 20,
            filter: {
                status: UserStatus.Active,
            },
        },
        dataAccessor: "medlistUsers",
    });
    const users = (_a = _users === null || _users === void 0 ? void 0 : _users.medlistUsers) === null || _a === void 0 ? void 0 : _a.edges.map(item => item.node);
    const [assignCodeSystemEditorAndReviewer, { loading: isSubmitting }] = useAssignCodeSystemEditorAndReviewerMutation({
        onCompleted: data => {
            var _a;
            if ((_a = data === null || data === void 0 ? void 0 : data.assignCodeSystemEditorAndReviewer) === null || _a === void 0 ? void 0 : _a.reviewerUserId) {
                succeeded(t("list assigned successfully"));
                handleClose();
            }
        },
        onError: ({ graphQLErrors }) => {
            failed(formatGraphQLError(graphQLErrors));
        },
    });
    const handleReviewerChange = (_event, value) => {
        setErrors(s => (Object.assign(Object.assign({}, s), { reviewerId: undefined })));
        setReviewer(value);
    };
    const handleEditorChange = (_event, value) => {
        setErrors(s => (Object.assign(Object.assign({}, s), { editorId: undefined })));
        setEditor(value);
    };
    const handleAssignClick = () => {
        if (!(reviewer === null || reviewer === void 0 ? void 0 : reviewer.id)) {
            setErrors(s => (Object.assign(Object.assign({}, s), { reviewerId: t(FIELD_IS_REQUIRED_MSG) })));
        }
        if (!(editor === null || editor === void 0 ? void 0 : editor.id)) {
            setErrors(s => (Object.assign(Object.assign({}, s), { editorId: t(FIELD_IS_REQUIRED_MSG) })));
        }
        if (!(reviewer === null || reviewer === void 0 ? void 0 : reviewer.id) || !(editor === null || editor === void 0 ? void 0 : editor.id))
            return;
        assignCodeSystemEditorAndReviewer({
            variables: {
                code: props.code,
                editorUser: editor.id,
                reviewerUser: reviewer.id,
            },
        });
    };
    return {
        t,
        isOpen,
        errors,
        users,
        editor,
        reviewer,
        isLoading,
        isSubmitting,
        onFetchMoreUsers,
        handleOpen,
        handleClose,
        handleReviewerChange,
        handleEditorChange,
        handleAssignClick,
    };
};
