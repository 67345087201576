import { QuestionType } from "@/schema/types";
import {
  AlignLeftIcon,
  CheckboxIcon,
  MuiCheckBoxOutlineBlankRoundedIcon,
  MuiExpandCircleDownOutlinedIcon,
  MuiExpandCircleDownRoundedIcon,
  MuiThumbsUpDownIcon,
} from "@toolkit/ui";
import { FC } from "react";

export const OptionItemIcon: FC<{ type: QuestionType; isChecked: boolean; color?: string }> = ({ type, isChecked, color }) => {
  switch (type) {
    case QuestionType.Boolean:
      return <MuiThumbsUpDownIcon sx={{ color: color }} />;
    case QuestionType.MultiChoice:
      return isChecked ? <CheckboxIcon fill={color} /> : <MuiCheckBoxOutlineBlankRoundedIcon sx={{ color: color }} />;
    case QuestionType.SingleChoice:
      return isChecked ? (
        <MuiExpandCircleDownRoundedIcon sx={{ color: color }} />
      ) : (
        <MuiExpandCircleDownOutlinedIcon sx={{ color: color }} />
      );
    case QuestionType.String:
      return <AlignLeftIcon fill={color} />;
    default:
      return null;
  }
};
