import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramMembershipRequestApproveMutationVariables = Types.Exact<{
  healthProgramMembershipRequestApproveId: Types.Scalars['ID']['input'];
  input: Types.HealthProgramMembershipRequestApproveInput;
}>;


export type HealthProgramMembershipRequestApproveMutation = { __typename?: 'Mutation', healthProgramMembershipRequestApprove?: { __typename?: 'HealthProgramMembershipRequestCRUD', healthProgramMembershipRequest?: { __typename?: 'HealthProgramMembershipRequest', id: string } | null, healthProgramMembershipRequestErrors?: Array<{ __typename?: 'H_EntityError', message?: string | null, field?: string | null, code: Types.H_EntityErrorCode }> | null } | null };


export const HealthProgramMembershipRequestApproveDocument = gql`
    mutation HealthProgramMembershipRequestApprove($healthProgramMembershipRequestApproveId: ID!, $input: HealthProgramMembershipRequestApproveInput!) {
  healthProgramMembershipRequestApprove(
    id: $healthProgramMembershipRequestApproveId
    input: $input
  ) {
    healthProgramMembershipRequest {
      id
    }
    healthProgramMembershipRequestErrors {
      message
      field
      code
    }
  }
}
    `;
export type HealthProgramMembershipRequestApproveMutationFn = Apollo.MutationFunction<HealthProgramMembershipRequestApproveMutation, HealthProgramMembershipRequestApproveMutationVariables>;

/**
 * __useHealthProgramMembershipRequestApproveMutation__
 *
 * To run a mutation, you first call `useHealthProgramMembershipRequestApproveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramMembershipRequestApproveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [healthProgramMembershipRequestApproveMutation, { data, loading, error }] = useHealthProgramMembershipRequestApproveMutation({
 *   variables: {
 *      healthProgramMembershipRequestApproveId: // value for 'healthProgramMembershipRequestApproveId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useHealthProgramMembershipRequestApproveMutation(baseOptions?: Apollo.MutationHookOptions<HealthProgramMembershipRequestApproveMutation, HealthProgramMembershipRequestApproveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HealthProgramMembershipRequestApproveMutation, HealthProgramMembershipRequestApproveMutationVariables>(HealthProgramMembershipRequestApproveDocument, options);
      }
export type HealthProgramMembershipRequestApproveMutationHookResult = ReturnType<typeof useHealthProgramMembershipRequestApproveMutation>;
export type HealthProgramMembershipRequestApproveMutationResult = Apollo.MutationResult<HealthProgramMembershipRequestApproveMutation>;
export type HealthProgramMembershipRequestApproveMutationOptions = Apollo.BaseMutationOptions<HealthProgramMembershipRequestApproveMutation, HealthProgramMembershipRequestApproveMutationVariables>;