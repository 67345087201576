/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */
import { careForTypeOptionsMap, CopayType, copayTypeOptionsMap } from "@/pages/HealthPrograms/enum-options";
import { IHealthProgramParametersUpdateFormValues } from "@/pages/HealthPrograms/forms/HealthProgramParametersUpdate/HealthProgramParametersUpdateFormSchema";
import {
  BenefitGroup,
  BenefitGroupComplex,
  BenefitOption,
  HealthProgram,
  HealthProgramInput,
  HealthProgramUpdateInput,
  Maybe,
} from "@/schema/types";
import {
  createHealthProgramMemberListAutocompleteOption,
  createHealthProgramNetworkAutocompleteOption,
  createPayerAutocompleteOption,
  createSystemCodeAutocompleteOption,
} from "@health/autocompletes";
import { healthProgramSubscriptionPeriodOptionsMap, healthProgramTypeOptionsMap } from "@health/enum-options";
import { isNil } from "lodash";
import { IHealthProgramUpsertFormValues } from "../forms/HealthProgramUpsert/HealthProgramUpsertFormSchema";
import {
  IHealthProgramBenefitsFormValues,
  IHealthProgramCallQualityRatingFactors,
  IHealthProgramSpecializationsRateFactors,
} from "../types";

export const convertHealthProgramToFormValues = (healthProgram: HealthProgram): IHealthProgramUpsertFormValues => {
  return {
    name: healthProgram?.name!,
    code: healthProgram?.code!,
    targetGroup: healthProgram?.targetGroup!,
    price: healthProgram?.priceAmount!,
    subscriptionPeriod: healthProgramSubscriptionPeriodOptionsMap[healthProgram?.subscriptionPeriod!],
    platformProfitShare: healthProgram?.platformProfitSharePercentage!,
    vendorProfitShare: healthProgram?.vendorProfitSharePercentage!,
    operatorProfitShare: healthProgram?.operatorProfitSharePercentage!,
    copayType: !isNil(healthProgram?.copayAmount) ? copayTypeOptionsMap[CopayType.Fixed] : copayTypeOptionsMap[CopayType.Percentage],
    copayValue: healthProgram?.copayAmount! || healthProgram?.copayPercentage!,
    isPrescriptionEnabled: !!healthProgram?.prescriptionEnabled,
    isReferralsToSpecialistsEnabled: !!healthProgram?.referralsToSpecialistsEnabled,
    payPerCall: !!healthProgram?.payPerCallOnly,
    isActive: !!healthProgram?.isActive,
    description: healthProgram?.description!,
    type: healthProgramTypeOptionsMap[healthProgram?.type!],
    benefits: [],
    payer: createPayerAutocompleteOption(healthProgram?.payer!),
    networks: healthProgram?.healthProgramNetworks?.edges?.length
      ? healthProgram?.healthProgramNetworks?.edges?.map(item => createHealthProgramNetworkAutocompleteOption(item?.node!))
      : [],
    specializations: healthProgram?.specializations?.length
      ? healthProgram?.specializations?.map(item => createSystemCodeAutocompleteOption(item?.specialization!))
      : [],
    membersLists: healthProgram?.healthProgramMemberLists?.edges?.length
      ? healthProgram?.healthProgramMemberLists?.edges?.map(item => createHealthProgramMemberListAutocompleteOption(item?.node!))
      : [],
    careForTypes: healthProgram?.careFor?.length ? healthProgram?.careFor?.map(item => careForTypeOptionsMap[item!]) : [],
    images: healthProgram?.images?.length ? (healthProgram?.images as string[]) : [],
    ...convertHealthProgramParametersToFormValues(healthProgram),
  };
};

export const convertHealthProgramFormValuesToBackEndValues = (values: IHealthProgramUpsertFormValues) => {
  return {
    name: values?.name,
    code: values?.code,
    targetGroup: values?.targetGroup,
    priceAmount: values?.price,
    subscriptionPeriod: values?.payPerCall ? undefined : values?.subscriptionPeriod?.value,
    platformProfitSharePercentage: values?.platformProfitShare,
    vendorProfitSharePercentage: values?.vendorProfitShare,
    operatorProfitSharePercentage: values?.operatorProfitShare,
    copay: !!values?.copayType?.value,
    copayAmount: values?.copayType?.value === CopayType.Fixed ? values?.copayValue : null,
    copayPercentage: values?.copayType?.value === CopayType.Percentage ? values?.copayValue : null,
    prescriptionEnabled: values?.isPrescriptionEnabled,
    referralsToSpecialistsEnabled: values?.isReferralsToSpecialistsEnabled,
    isActive: values?.isActive,
    description: values?.description,
    payerId: values?.payer?.value?.id,
    specializations: values?.specializations?.map(item => item?.value?.code!),
    careFor: values?.careForTypes?.map(item => item?.value),
    healthProgramNetworks: values?.networks?.map(item => item?.value?.id),
    healthProgramMemberLists: values?.membersLists?.map(item => item?.value?.id),
    benefitGroups: values?.payPerCall ? [] : convertHealthProgramBenefitsFormValuesToBackEndValues(values?.benefits),
    images: values?.images,
    ...convertHealthProgramParametersFormValuesToBackEndValues(values),
  };
};

export const convertHealthProgramCreateFormValuesToBackEndValues = (values: IHealthProgramUpsertFormValues): HealthProgramInput => {
  return {
    ...convertHealthProgramFormValuesToBackEndValues(values),
    payPerCallOnly: values?.payPerCall,
    type: values?.type?.value,
  };
};

export const convertHealthProgramUpdateFormValuesToBackEndValues = (values: IHealthProgramUpsertFormValues): HealthProgramUpdateInput => {
  return convertHealthProgramFormValuesToBackEndValues(values);
};

export const convertHealthProgramBenefitsFormValuesToBackEndValues = (
  benefits: IHealthProgramBenefitsFormValues
): HealthProgramInput["benefitGroups"] => {
  return (
    benefits
      ?.filter(item => item?.options?.some(option => option?.isAdded))
      .map(item => {
        return {
          benefitGroup: item?.group?.id,
          optionsValues: item?.options
            ?.filter(option => option?.isAdded)
            ?.map(option => ({
              option: option?.option?.id,
              name: option?.name!,
              value: option?.value!,
            })),
        };
      }) || []
  );
};

export const convertHealthProgramParametersToFormValues = (healthProgram: HealthProgram): IHealthProgramParametersUpdateFormValues => {
  const callQualityRatings: IHealthProgramCallQualityRatingFactors = JSON.parse(healthProgram?.callQualityRatingFactors || "");
  const specializationsRates: IHealthProgramSpecializationsRateFactors = JSON.parse(healthProgram?.mainSpecializationsRateFactors || "");

  return {
    normalDemand: healthProgram?.pointsDuringNormalDemand!,
    urgentDemand: healthProgram?.pointsDuringUrgentDemand!,
    callRating1: callQualityRatings?.["1"]!,
    callRating2: callQualityRatings?.["2"]!,
    callRating3: callQualityRatings?.["3"]!,
    callRating4: callQualityRatings?.["4"]!,
    callRating5: callQualityRatings?.["5"]!,
    availabilityHours: healthProgram?.baselineAvailabilityHours!,
    unattendedCallPenalty: healthProgram?.unattendedCallPenaltyFactor!,
    generalPractitioners: specializationsRates?.GP!,
    specialityPractitioners: specializationsRates?.SP!,
    consultantPractitioners: specializationsRates?.CP!,
  };
};

export const convertHealthProgramParametersFormValuesToBackEndValues = (values: IHealthProgramParametersUpdateFormValues) => {
  return {
    pointsDuringNormalDemand: values?.normalDemand,
    pointsDuringUrgentDemand: values?.urgentDemand,
    baselineAvailabilityHours: values?.availabilityHours,
    unattendedCallPenaltyFactor: values?.unattendedCallPenalty,
    callQualityRatingFactors: JSON.stringify({
      "1": values?.callRating1,
      "2": values?.callRating2,
      "3": values?.callRating3,
      "4": values?.callRating4,
      "5": values?.callRating5,
    }),
    mainSpecializationsRateFactors: JSON.stringify({
      GP: values?.generalPractitioners,
      SP: values?.specialityPractitioners,
      CP: values?.consultantPractitioners,
    }),
  };
};

export const convertHealthProgramBenefitsToFormValues = (
  healthProgramBenefits: Maybe<BenefitGroupComplex>[],
  benefitsGroups: BenefitGroup[],
  benefitsOptions: BenefitOption[]
): IHealthProgramBenefitsFormValues => {
  return benefitsGroups?.map(group => {
    const modifiedGroup = healthProgramBenefits?.find(item => item?.benefitGroup?.id === group?.id);

    return {
      group,
      options: benefitsOptions?.map(option => {
        const modifiedOption = modifiedGroup?.optionsValues?.find(item => item?.option?.id === option.id);

        return {
          option,
          name: modifiedOption?.name || undefined,
          value: modifiedOption?.value || undefined,
          isAdded: !!modifiedOption,
        };
      }),
    };
  });
};

export const convertHealthProgramBenefitsInitialDataToFormValues = (
  benefitsGroups: BenefitGroup[],
  benefitsOptions: BenefitOption[]
): IHealthProgramBenefitsFormValues => {
  return benefitsGroups.map(group => ({
    group,
    options: benefitsOptions.map(option => ({
      option,
      name: undefined,
      value: undefined,
      isAdded: false,
    })),
  }));
};
