import { gql } from '@apollo/client';
import { HPageInfoAutocompleteFragmentDoc } from '../../../../gql/fragments/__generated__/HPageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const ArticlesAutocompleteDocument = gql `
    query ArticlesAutocomplete($after: String, $before: String, $last: Int, $filter: ArticleFilterInput, $first: Int) {
  articles(
    after: $after
    before: $before
    last: $last
    filter: $filter
    first: $first
  ) {
    pageInfo {
      ...HPageInfoAutocomplete
    }
    edges {
      node {
        id
        title
        titleAr
      }
    }
  }
}
    ${HPageInfoAutocompleteFragmentDoc}`;
/**
 * __useArticlesAutocompleteQuery__
 *
 * To run a query within a React component, call `useArticlesAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticlesAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticlesAutocompleteQuery({
 *   variables: {
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useArticlesAutocompleteQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(ArticlesAutocompleteDocument, options);
}
export function useArticlesAutocompleteLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(ArticlesAutocompleteDocument, options);
}
export function useArticlesAutocompleteSuspenseQuery(baseOptions) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(ArticlesAutocompleteDocument, options);
}
