import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormCard, Grid, PageWrapper, Typography } from "@toolkit/ui";
import { DecisionActionFieldsForm } from "../../../Decisions/forms/DecisionActionFields/DecisionActionFieldsForm";
import { DecisionConditionsGroupsForm } from "../../../Decisions/forms/DecisionConditionsGroups/DecisionConditionsGroupsForm";
import { DecisionInformationForm } from "../../../Decisions/forms/DecisionInformation/DecisionInformationForm";
import { useDecisionPlanGetQuery } from "../../../Decisions/gql";
import { convertDecisionPlanToFormValues, convertDecisionToFormValues, createHealthParameterAutocompleteObject, createHealthParameterDecisionPlanAutocompleteObject, } from "../../../Decisions/others/utils";
import { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { DecisionUpsertFormSchema, decisionUpsertFormSchemaDefaultValues } from "./DecisionUpsertFormSchema";
export const DecisionUpsertForm = props => {
    var _a, _b;
    const { submitButtonLabel, decision, category, isLoading, isButtonDisabled, healthParameter, healthParameterDecisionPlan, onChange } = props;
    const { t } = useTranslation("domains");
    const navigate = useNavigate();
    const form = useCustomForm({
        defaultValues: decisionUpsertFormSchemaDefaultValues,
        schema: DecisionUpsertFormSchema,
    });
    const { watch, setValue, setValues, clearErrors, handleSubmit } = form;
    const selectedDecisionPlanId = (_b = (_a = watch("decisionPlan")) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.id;
    const actionFields = watch("actionFields");
    const isScript = watch("isScript");
    const { data, loading: isDecisionPlanLoading } = useDecisionPlanGetQuery({
        variables: {
            getPlanId: selectedDecisionPlanId,
        },
        skip: !selectedDecisionPlanId,
    });
    const selectedDecisionPlan = data === null || data === void 0 ? void 0 : data.getPlan;
    const onSubmit = (values) => {
        onChange({
            type: "SUBMIT",
            payload: {
                values,
                decisionPlan: selectedDecisionPlan,
            },
        });
    };
    const handleNavigation = () => {
        navigate(-1);
    };
    useEffect(() => {
        if (decision) {
            const _decision = convertDecisionToFormValues(decision);
            setValues(_decision);
        }
        else if (selectedDecisionPlan) {
            clearErrors();
            const _decisionPlan = convertDecisionPlanToFormValues(selectedDecisionPlan);
            setValues(_decisionPlan);
        }
    }, [decision, selectedDecisionPlan, setValues, clearErrors]);
    useEffect(() => {
        if (healthParameter) {
            const _healthParameter = createHealthParameterAutocompleteObject(healthParameter);
            setValue("healthParameter", _healthParameter);
        }
    }, [healthParameter, healthParameterDecisionPlan, setValue]);
    useEffect(() => {
        if (healthParameterDecisionPlan) {
            const _healthParameterDecisionPlan = createHealthParameterDecisionPlanAutocompleteObject(healthParameterDecisionPlan);
            setValue("decisionPlan", _healthParameterDecisionPlan);
        }
    }, [healthParameter, healthParameterDecisionPlan, setValue]);
    return (_jsx(FormProvider, Object.assign({}, form, { children: _jsx("form", { onSubmit: handleSubmit(onSubmit), children: _jsx(PageWrapper, { actions: _jsx(FormActions, { hasCancel: true, hasFormButton: true, isLoading: isLoading, formButtonTitle: submitButtonLabel, onNavigation: handleNavigation, newButtonDisabled: isButtonDisabled }), children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(FormCard, { title: t("Decision Information"), loading: isLoading, doYouHaveData: true, children: _jsx(DecisionInformationForm, { category: category, isScript: isScript, healthParameter: healthParameter, isUpdateMode: !!decision }) }) }), !selectedDecisionPlan || isDecisionPlanLoading ? (_jsx(Grid, { item: true, xs: 12, children: _jsx(FormCard, { title: t("Decision Fields"), loading: isDecisionPlanLoading, doYouHaveData: true, children: _jsxs(Typography, { children: [" ", t("Select a plan to create a decision"), " "] }) }) })) : (_jsxs(_Fragment, { children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(FormCard, { title: t("Decision Fields"), loading: isLoading || isDecisionPlanLoading, doYouHaveData: true, children: _jsx(DecisionActionFieldsForm, { actionFields: actionFields }) }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(DecisionConditionsGroupsForm, { decisionPlanFields: selectedDecisionPlan === null || selectedDecisionPlan === void 0 ? void 0 : selectedDecisionPlan.fields }) })] }))] }) }) }) })));
};
