import { H_OrderDirection, HealthProgramSortingField, PermissionEnum } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { hasPermission } from "@toolkit/core";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { useNavigate } from "react-router-dom";
import { healthProgramsPaths } from "../../constants";
import { useHealthProgramListQuery } from "../../gql";
import { useSetHealthProgramsBreadcrumbs } from "../../hooks";
import { IHealthProgramListNode } from "../../types";
import { useHealthProgramListContainerColumns } from "./useHealthProgramListContainerColumns";

export const HealthProgramListContainer = () => {
  const navigate = useNavigate();
  const hasManageHealthProgramsPermission = hasPermission(PermissionEnum.ManageHealthPrograms);

  const onAddNewItemClick = () => {
    navigate(healthProgramsPaths.create.fullPath);
  };

  const onEditRowClick = (item: IHealthProgramListNode) => {
    navigate(healthProgramsPaths.update.fullPathWithParams({ healthProgramId: item?.id }));
  };

  useSetHealthProgramsBreadcrumbs("LIST");

  return (
    <GridProvider
      gridName={"healthProgramList"}
      query={useHealthProgramListQuery}
      columns={useHealthProgramListContainerColumns()}
      variables={{
        sortBy: {
          direction: H_OrderDirection.Desc,
          field: HealthProgramSortingField.Created,
        },
      }}
      tableAction={{
        isEditVisible: hasManageHealthProgramsPermission,
        onEditRow: onEditRowClick,
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            {hasManageHealthProgramsPermission && <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />}
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
