import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const GetOrderLinesDocument = gql `
    query getOrderLines($orderId: ID!) {
  marketplaceOrder(id: $orderId) {
    id
    orderLines {
      quantity
      unitPriceNetAmount
      product {
        id
        name
        nameAr
        mainImageUrl
        shortDescription
        shortDescriptionAr
      }
      healthPackage {
        id
        name
        nameAr
        mainImageUrl
        shortDescription
        shortDescriptionAr
      }
    }
  }
}
    `;
/**
 * __useGetOrderLinesQuery__
 *
 * To run a query within a React component, call `useGetOrderLinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderLinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderLinesQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *   },
 * });
 */
export function useGetOrderLinesQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetOrderLinesDocument, options);
}
export function useGetOrderLinesLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetOrderLinesDocument, options);
}
export function useGetOrderLinesSuspenseQuery(baseOptions) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(GetOrderLinesDocument, options);
}
