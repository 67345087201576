import { i18n } from "@toolkit/i18n";
import { AppointmentType } from "../../schema/types";
export const appointmentTypeOptionsMap = {
    [AppointmentType.AtHome]: {
        key: AppointmentType.AtHome,
        get label() {
            return i18n.t("In Home Visit", { ns: "domains" });
        },
        value: AppointmentType.AtHome,
    },
    [AppointmentType.Diagnostic]: {
        key: AppointmentType.Diagnostic,
        get label() {
            return i18n.t("Diagnostic", { ns: "domains" });
        },
        value: AppointmentType.Diagnostic,
    },
    [AppointmentType.Online]: {
        key: AppointmentType.Online,
        get label() {
            return i18n.t("Online", { ns: "domains" });
        },
        value: AppointmentType.Online,
    },
    [AppointmentType.Onsite]: {
        key: AppointmentType.Onsite,
        get label() {
            return i18n.t("In Person", { ns: "domains" });
        },
        value: AppointmentType.Onsite,
    },
};
export const appointmentTypeOptions = Object.values(appointmentTypeOptionsMap);
