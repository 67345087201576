import { AccountError, AppointmentTypeEnum, User } from "@/schema/types";
import { combineErrors, formGirdBreakPoints, getMinValueValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, Grid, TextField } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

const getShowPriceField = function (watchAppointmentTypes, defaultAppointmentTypes, field) {
  const isVisitPriceExsistOnForm = watchAppointmentTypes && watchAppointmentTypes.map(e => e.value).includes(field);
  const isVisitPriceExsistOnDefault = defaultAppointmentTypes && defaultAppointmentTypes.includes(field);
  return isVisitPriceExsistOnForm ?? isVisitPriceExsistOnDefault;
};

export const AppointmentInformation: FC<{ defaultValues: User | undefined; errors?: AccountError[] }> = ({ defaultValues, errors }) => {
  const { t } = useTranslation("admin");
  const { control, register, formState } = useFormContext();
  const { watch } = useFormContext();
  const appointmentTypes = [
    { name: t("Online"), value: AppointmentTypeEnum.Online },
    { name: t("Onsite"), value: AppointmentTypeEnum.Onsite },
    { name: t("At-Home"), value: AppointmentTypeEnum.AtHome },
  ];
  const watchAppointmentTypes = watch("doctorInfo.appointmentTypes");
  const isOnlineVisitPriceSet = getShowPriceField(
    watchAppointmentTypes,
    defaultValues?.doctor?.appointmentTypes,
    AppointmentTypeEnum.Online
  );
  const isOnSiteVisitPriceSet = getShowPriceField(
    watchAppointmentTypes,
    defaultValues?.doctor?.appointmentTypes,
    AppointmentTypeEnum.Onsite
  );
  const isAtHomeVisitPriceSet = getShowPriceField(
    watchAppointmentTypes,
    defaultValues?.doctor?.appointmentTypes,
    AppointmentTypeEnum.AtHome
  );
  const combinedErrors = combineErrors(formState.errors, errors);
  return (
    <>
      <Grid item {...formGirdBreakPoints}>
        <AutocompleteController
          ControllerProps={{
            name: "doctorInfo.appointmentTypes",
            control: control,
            rules: { required: true },
            defaultValue: appointmentTypes.filter(type => defaultValues?.doctor?.appointmentTypes?.includes(type.value)),
          }}
          multiple
          TextFieldProps={{
            error: Boolean(combinedErrors?.doctorInfo?.appointmentTypes),
            helperText: combinedErrors?.doctorInfo?.appointmentTypes && t("Required"),
            label: t("Appointment Types"),
          }}
          getOptionLabel={o => o?.name || o}
          options={appointmentTypes || []}
          isOptionEqualToValue={(option, value) => option?.value === value.value}
        />
      </Grid>
      {isOnlineVisitPriceSet && (
        <Grid item {...formGirdBreakPoints}>
          <TextField
            fullWidth
            type='number'
            label={t("Online Visit Price")}
            error={Boolean(combinedErrors?.doctorInfo?.onlineVisitPrice?.message)}
            helperText={t(combinedErrors?.doctorInfo?.onlineVisitPrice?.message)}
            defaultValue={defaultValues?.doctor?.onlineVisitPrice ?? null}
            {...register(`doctorInfo.onlineVisitPrice`, {
              valueAsNumber: true,
              min: getMinValueValidation(t, 0),
            })}
          />
        </Grid>
      )}
      {isOnSiteVisitPriceSet && (
        <Grid item {...formGirdBreakPoints}>
          <TextField
            fullWidth
            type='number'
            label={t("Onsite Visit Price")}
            error={Boolean(combinedErrors?.doctorInfo?.onsiteVisitPrice?.message)}
            helperText={t(combinedErrors?.doctorInfo?.onsiteVisitPrice?.message)}
            defaultValue={defaultValues?.doctor?.onsiteVisitPrice ?? null}
            {...register(`doctorInfo.onsiteVisitPrice`, {
              valueAsNumber: true,
              min: getMinValueValidation(t, 0),
            })}
          />
        </Grid>
      )}
      {isAtHomeVisitPriceSet && (
        <Grid item {...formGirdBreakPoints}>
          <TextField
            fullWidth
            type='number'
            label={t("At Home Visit Price")}
            error={Boolean(combinedErrors?.doctorInfo?.atHomeVisitPrice?.message)}
            helperText={t(combinedErrors?.doctorInfo?.atHomeVisitPrice?.message)}
            defaultValue={defaultValues?.doctor?.atHomeVisitPrice ?? null}
            {...register(`doctorInfo.atHomeVisitPrice`, {
              valueAsNumber: true,
              min: getMinValueValidation(t, 0),
            })}
          />
        </Grid>
      )}
    </>
  );
};
