/* eslint-disable sonarjs/cognitive-complexity */
import { ProcessingStatus, Type } from "../../../schema/types";
import { formatUserNameFull, toSentenceCase } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { useBreadCrumbs } from "@toolkit/ui";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { adminManageListBreadcrumb, adminManageListFieldBreadcrumb, adminManageListsTitleBreadcrumb, adminManagedListsPaths, } from "../constants";
import { useGetCodeSystemQuery } from "../gql";
import { useMapConceptDefinitionsToDataGridColumns } from "./useMapConceptDefinitionsToDataGridColumns";
import { getFilterValuePropertyName } from "./utils";
export const useAdminManagedListDetailsPage = () => {
    const navigate = useNavigate();
    const { code, id: _id } = useParams();
    const { t } = useTranslation("domains");
    const id = _id == "0" ? null : _id;
    const { setBreadCrumb } = useBreadCrumbs();
    const { data: getCodeSystemResponse, refetch: refetchCodeSystem } = useGetCodeSystemQuery({
        variables: {
            id: id,
        },
        skip: !id || id === "new",
    });
    const { processingStatus, codeSystemDefinition, codeSystemHistory, version: versionNumber } = (getCodeSystemResponse === null || getCodeSystemResponse === void 0 ? void 0 : getCodeSystemResponse.getCodeSystem) || {};
    const assignedReviewerData = codeSystemHistory === null || codeSystemHistory === void 0 ? void 0 : codeSystemHistory.find(item => (item === null || item === void 0 ? void 0 : item.processingStatus) === ProcessingStatus.Approved);
    const reviewerUserName = formatUserNameFull(codeSystemDefinition === null || codeSystemDefinition === void 0 ? void 0 : codeSystemDefinition.reviewerUserId) || formatUserNameFull(assignedReviewerData === null || assignedReviewerData === void 0 ? void 0 : assignedReviewerData.user);
    const { codeSystemConceptFields, isEditable, editorUserId: editorUser, reviewerUserId: reviewerUser } = codeSystemDefinition || {};
    const columnsDefinition = useMapConceptDefinitionsToDataGridColumns(codeSystemConceptFields);
    const handleEditRow = (rowData) => {
        navigate(adminManagedListsPaths.detailsEditPath.fullPathWithParams(code, id, rowData === null || rowData === void 0 ? void 0 : rowData.id));
    };
    function getSearchParameters(data) {
        if (!data)
            return;
        const filterData = {};
        Object.keys(data).forEach(item => {
            if (data[item]) {
                filterData[item] = data[item];
            }
        });
        const getFilterPropertyType = (item) => {
            var _a;
            return (_a = codeSystemConceptFields === null || codeSystemConceptFields === void 0 ? void 0 : codeSystemConceptFields.find(column => (column === null || column === void 0 ? void 0 : column.code) == item)) === null || _a === void 0 ? void 0 : _a.fieldType;
        };
        return Object.keys(filterData).map(item => {
            var _a;
            return ({
                code: item,
                type: getFilterPropertyType(item),
                [getFilterValuePropertyName(getFilterPropertyType(item))]: getFilterPropertyType(item) == Type.Boolean ? ((_a = filterData[item]) === null || _a === void 0 ? void 0 : _a.toLowerCase()) == "yes" : filterData[item],
            });
        });
    }
    useEffect(() => {
        if (code) {
            setBreadCrumb({
                title: adminManageListsTitleBreadcrumb(t),
                values: [
                    adminManageListBreadcrumb(t),
                    adminManageListFieldBreadcrumb(versionNumber ? `${toSentenceCase(code)} (v${versionNumber})` : toSentenceCase(code)),
                ],
            });
        }
    }, [code, versionNumber]);
    return {
        id,
        code: code,
        isNew: id === "new",
        isEditable: isEditable,
        versionNumber,
        versionParamId: id,
        columnsDefinition: columnsDefinition || [],
        editorUser,
        reviewerUser,
        processingStatus,
        reviewerUserName,
        t,
        getSearchParameters,
        refetchCodeSystem,
        handleEditRow,
    };
};
