import { i18n } from "@toolkit/i18n";
import { TransactionTransactionStatus } from "../../schema/types";
export const transactionStatusTypeOptionsMap = {
    [TransactionTransactionStatus.PartiallyApproved]: {
        key: TransactionTransactionStatus.PartiallyApproved,
        get label() {
            return i18n.t("Partially Approved", { ns: "domains" });
        },
        value: TransactionTransactionStatus.PartiallyApproved,
    },
    [TransactionTransactionStatus.FullyApproved]: {
        key: TransactionTransactionStatus.FullyApproved,
        get label() {
            return i18n.t("Fully Approved", { ns: "domains" });
        },
        value: TransactionTransactionStatus.FullyApproved,
    },
    [TransactionTransactionStatus.Rejected]: {
        key: TransactionTransactionStatus.Rejected,
        get label() {
            return i18n.t("Rejected", { ns: "domains" });
        },
        value: TransactionTransactionStatus.Rejected,
    },
};
export const transactionStatusTypeOptions = Object.values(transactionStatusTypeOptionsMap);
