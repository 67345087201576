import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthProgramMemberGetQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type HealthProgramMemberGetQuery = { __typename?: 'Query', healthProgramMember?: { __typename?: 'HealthProgramMember', id: string, firstName?: string | null, lastName?: string | null, gender?: Types.H_Gender | null, dateOfBirth?: any | null, nationalId?: string | null, sehacityId?: string | null, idType?: Types.H_IdTypes | null, nationality?: string | null, nationalityDisplay?: string | null, documentExpiryDate?: any | null, phone?: string | null, email?: string | null, network?: string | null, coverageBy?: Types.CoverageByType | null, membershipStart?: any | null, membershipEnd?: any | null, insuranceId?: string | null, insurancePolicyNumber?: string | null, payer?: { __typename?: 'Payer', id: string, name: string, nameAr?: string | null } | null } | null, healthProgramMemberLists?: { __typename?: 'HealthProgramMemberListCountableConnection', edges: Array<{ __typename?: 'HealthProgramMemberListCountableEdge', node: { __typename?: 'HealthProgramMemberList', id: string, name?: string | null } }> } | null };


export const HealthProgramMemberGetDocument = gql`
    query HealthProgramMemberGet($id: ID!) {
  healthProgramMember(id: $id) {
    id
    firstName
    lastName
    gender
    dateOfBirth
    nationalId
    sehacityId
    idType
    nationality
    nationalityDisplay
    documentExpiryDate
    phone
    email
    network
    coverageBy
    membershipStart
    membershipEnd
    insuranceId
    insurancePolicyNumber
    payer {
      id
      name
      nameAr
    }
  }
  healthProgramMemberLists(first: 100) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useHealthProgramMemberGetQuery__
 *
 * To run a query within a React component, call `useHealthProgramMemberGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthProgramMemberGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthProgramMemberGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHealthProgramMemberGetQuery(baseOptions: Apollo.QueryHookOptions<HealthProgramMemberGetQuery, HealthProgramMemberGetQueryVariables> & ({ variables: HealthProgramMemberGetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthProgramMemberGetQuery, HealthProgramMemberGetQueryVariables>(HealthProgramMemberGetDocument, options);
      }
export function useHealthProgramMemberGetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthProgramMemberGetQuery, HealthProgramMemberGetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthProgramMemberGetQuery, HealthProgramMemberGetQueryVariables>(HealthProgramMemberGetDocument, options);
        }
export function useHealthProgramMemberGetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HealthProgramMemberGetQuery, HealthProgramMemberGetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HealthProgramMemberGetQuery, HealthProgramMemberGetQueryVariables>(HealthProgramMemberGetDocument, options);
        }
export type HealthProgramMemberGetQueryHookResult = ReturnType<typeof useHealthProgramMemberGetQuery>;
export type HealthProgramMemberGetLazyQueryHookResult = ReturnType<typeof useHealthProgramMemberGetLazyQuery>;
export type HealthProgramMemberGetSuspenseQueryHookResult = ReturnType<typeof useHealthProgramMemberGetSuspenseQuery>;
export type HealthProgramMemberGetQueryResult = Apollo.QueryResult<HealthProgramMemberGetQuery, HealthProgramMemberGetQueryVariables>;