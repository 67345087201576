import { BranchError, BranchInput } from "@/schema/types";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { useAddToast, useBreadCrumbs } from "@toolkit/ui";
import { branchFieldBreadcrumb, branchListBreadcrumb, branchesTitleBreadcrumb } from "pages/Branches/constants/branches-breadcrumbs";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useBranchQuery, useBranchUpdateMutation } from "../../gql";

export const useBranchEditPageHook = () => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const params = useParams();
  const { setBreadCrumb } = useBreadCrumbs();

  const [errors, setError] = useState<BranchError[]>();

  const { succeeded, failed } = useAddToast();
  const translateBranch = t("Branch Update Successfully");

  const [branchUpdate, { loading: isSubmitting }] = useBranchUpdateMutation({
    onCompleted: data => {
      const errors = data?.branchUpdate?.branchErrors;
      if (errors?.length! === 0) {
        succeeded(translateBranch);
        navigate(-1);
      } else {
        const error = t(formatMessageErrors(errors));
        failed(error);
        setError(errors as BranchError[]);
      }
    },
    onError: ({ graphQLErrors }) => {
      failed(formatGraphQLError(graphQLErrors));
    },
  });

  const { data, loading } = useBranchQuery({
    variables: {
      id: String(params?.idBranch),
    },
  });

  const handleUpdateBranch = (data: BranchInput) => {
    branchUpdate({
      variables: {
        id: params.idBranch as string,
        branch: data,
      },
    });
  };

  const branch = data?.branch;

  useEffect(() => {
    if (branch?.name) {
      setBreadCrumb({
        title: branchesTitleBreadcrumb(),
        values: [branchListBreadcrumb(String(params.id)), branchFieldBreadcrumb(branch.name)],
      });
    }
  }, [branch?.name]);

  return {
    branch,
    errors,
    loading,
    handleUpdateBranch,
    isSubmitting,
  };
};
