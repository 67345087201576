import { i18n } from "@toolkit/i18n";
import { VendorUserTypes } from "../../schema/types";
export const vendorUserTypeOptionsMap = {
    [VendorUserTypes.DentalHygienist]: {
        key: VendorUserTypes.DentalHygienist,
        get label() {
            return i18n.t("Dental Hygienist", { ns: "domains" });
        },
        value: VendorUserTypes.DentalHygienist,
    },
    [VendorUserTypes.DiabetesEducator]: {
        key: VendorUserTypes.DiabetesEducator,
        get label() {
            return i18n.t("Diabetes Educator", { ns: "domains" });
        },
        value: VendorUserTypes.DiabetesEducator,
    },
    [VendorUserTypes.FitnessCoach]: {
        key: VendorUserTypes.FitnessCoach,
        get label() {
            return i18n.t("Fitness Coach", { ns: "domains" });
        },
        value: VendorUserTypes.FitnessCoach,
    },
    [VendorUserTypes.Nurse]: {
        key: VendorUserTypes.Nurse,
        get label() {
            return i18n.t("Nurse", { ns: "domains" });
        },
        value: VendorUserTypes.Nurse,
    },
    [VendorUserTypes.Nutritionist]: {
        key: VendorUserTypes.Nutritionist,
        get label() {
            return i18n.t("Nutritionist", { ns: "domains" });
        },
        value: VendorUserTypes.Nutritionist,
    },
    [VendorUserTypes.Optometrist]: {
        key: VendorUserTypes.Optometrist,
        get label() {
            return i18n.t("Optometrist", { ns: "domains" });
        },
        value: VendorUserTypes.Optometrist,
    },
    [VendorUserTypes.Pharmacist]: {
        key: VendorUserTypes.Pharmacist,
        get label() {
            return i18n.t("Pharmacist", { ns: "domains" });
        },
        value: VendorUserTypes.Pharmacist,
    },
    [VendorUserTypes.PodiatricMedicalAssistant]: {
        key: VendorUserTypes.PodiatricMedicalAssistant,
        get label() {
            return i18n.t("Podiatric Medical Assistant", { ns: "domains" });
        },
        value: VendorUserTypes.PodiatricMedicalAssistant,
    },
    [VendorUserTypes.Psychologist]: {
        key: VendorUserTypes.Psychologist,
        get label() {
            return i18n.t("Psychologist", { ns: "domains" });
        },
        value: VendorUserTypes.Psychologist,
    },
    [VendorUserTypes.SocialWorker]: {
        key: VendorUserTypes.SocialWorker,
        get label() {
            return i18n.t("Social Worker", { ns: "domains" });
        },
        value: VendorUserTypes.SocialWorker,
    },
    [VendorUserTypes.Doctor]: {
        key: VendorUserTypes.Doctor,
        get label() {
            return i18n.t("Doctor", { ns: "domains" });
        },
        value: VendorUserTypes.Doctor,
    },
    [VendorUserTypes.Receptionist]: {
        key: VendorUserTypes.Receptionist,
        get label() {
            return i18n.t("Receptionist", { ns: "domains" });
        },
        value: VendorUserTypes.Receptionist,
    },
    [VendorUserTypes.Manager]: {
        key: VendorUserTypes.Manager,
        get label() {
            return i18n.t("Manager", { ns: "domains" });
        },
        value: VendorUserTypes.Manager,
    },
};
export const vendorUserTypeOptions = Object.values(vendorUserTypeOptionsMap);
