import { healthProgramMembersPaths } from "@/pages/HealthProgramMembers/constants";
import { HealthProgramMemberListSortingField } from "@/schema/types";
import {
  getHealthProgramMembersAutocompleteFilter,
  getHealthProgramsAutocompleteFilter,
  getPayersAutocompleteFilter,
} from "@health/autocompletes";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, ShowButton } from "@toolkit/ui";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { HealthProgramMemberListActivation } from "../../components/HealthProgramMemberListActivation/HealthProgramMemberListActivation";
import { IHealthProgramMemberListNode } from "../../types";

export const useHealthProgramMemberListListContainerColumns = (): CustomTableColumnProps<IHealthProgramMemberListNode>[] => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();

  const onShowMembersClick = useCallback(
    (id: string) => {
      navigate(`${healthProgramMembersPaths.list.fullPath}?memberListId=${id}`);
    },
    [navigate]
  );

  return useMemo(
    () => [
      {
        key: "name",
        header: t("Name"),
        accessor: "name",
        isSortable: true,
        sortDirection: "DESC",
        sortColumnEnum: HealthProgramMemberListSortingField.Name,
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
      {
        key: "payer",
        header: t("Payer"),
        accessor: ({ payer }) => pickLocalizedValue(payer?.name, payer?.nameAr),
        filter: getPayersAutocompleteFilter({ name: "payers", multiple: true }),
      },
      {
        key: "members",
        header: t("Members"),
        accessor: ({ id }) => <ShowButton onClick={() => onShowMembersClick(id)} />,
        filter: getHealthProgramMembersAutocompleteFilter({ name: "members", multiple: true }),
      },
      {
        key: "healthPrograms",
        header: t("Health Programs"),
        showOnlyForFilterField: true,
        filter: getHealthProgramsAutocompleteFilter({ name: "healthPrograms", multiple: true }),
      },
      {
        key: "active",
        header: t("Active"),
        accessor: ({ id, isActive }) => <HealthProgramMemberListActivation id={id} isActive={!!isActive} />,
        isSortable: true,
        sortDirection: "DESC",
        sortColumnEnum: HealthProgramMemberListSortingField.IsActive,
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
    ],
    [onShowMembersClick, t]
  );
};
