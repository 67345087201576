import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const DecisionActionRecommendationsAutocompleteDocument = gql `
    query DecisionActionRecommendationsAutocomplete($first: Int, $last: Int, $after: String, $before: String, $filter: TemplateRecommendationFilterInput) {
  templateRecommendations(
    first: $first
    last: $last
    after: $after
    before: $before
    filter: $filter
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id: code
        name
        nameAr
      }
    }
  }
}
    `;
/**
 * __useDecisionActionRecommendationsAutocompleteQuery__
 *
 * To run a query within a React component, call `useDecisionActionRecommendationsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useDecisionActionRecommendationsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDecisionActionRecommendationsAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useDecisionActionRecommendationsAutocompleteQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DecisionActionRecommendationsAutocompleteDocument, options);
}
export function useDecisionActionRecommendationsAutocompleteLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DecisionActionRecommendationsAutocompleteDocument, options);
}
export function useDecisionActionRecommendationsAutocompleteSuspenseQuery(baseOptions) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(DecisionActionRecommendationsAutocompleteDocument, options);
}
