import { Address, Maybe } from "@/schema/types";
import { getMaxLengthValidation, validateValueIsNotEmpty } from "@toolkit/core";

export const locationFields = (t, address: Maybe<Address> | undefined, backendErrorStorage) => [
  {
    name: "addressInput.area",
    label: t("Area"),
    defaultValue: address?.area,
    registerProps: {
      maxLength: getMaxLengthValidation(t, 128),
      validate: value => backendErrorStorage["addressInput.area"][value] || validateValueIsNotEmpty(value, t),
    },
  },
  {
    name: "addressInput.streetAddress1",
    label: t("English Street Address 1"),
    defaultValue: address?.streetAddress1,
    registerProps: {
      maxLength: getMaxLengthValidation(t, 256),
      validate: value => backendErrorStorage["addressInput.streetAddress1"][value] || validateValueIsNotEmpty(value, t),
    },
  },
  {
    name: "arabicStreetAddress",
    label: t("Arabic Street Address 1"),
    defaultValue: address?.streetAddress1Ar,
    registerProps: {
      maxLength: getMaxLengthValidation(t, 255),
      validate: value => backendErrorStorage["arabicStreetAddress"][value] || validateValueIsNotEmpty(value, t),
    },
  },
  {
    name: "addressInput.streetAddress2",
    label: t("English Street Address 2"),
    defaultValue: address?.streetAddress2,
    registerProps: {
      maxLength: getMaxLengthValidation(t, 256),
      validate: value => backendErrorStorage["addressInput.streetAddress2"][value] || validateValueIsNotEmpty(value, t),
    },
  },
  {
    name: "arabicStreetAddress2",
    label: t("Arabic Street Address 2"),
    defaultValue: address?.streetAddress2Ar,
    registerProps: {
      maxLength: getMaxLengthValidation(t, 256),
      validate: value => backendErrorStorage["arabicStreetAddress2"][value] || validateValueIsNotEmpty(value, t),
    },
  },
];
