import { OrderStatus, OrderStatusEnum, PrescriptionDispenseStatus } from "../../../schema/types";
import { capitalize } from "@toolkit/core";
export const statuses = Object.values(OrderStatusEnum)
    .map(value => ({
    value: value,
    name: capitalize(value.toLowerCase().split("_")),
}))
    .filter(item => !(item.value === OrderStatusEnum.Draft ||
    item.value === OrderStatusEnum.Unfulfilled ||
    item.value === OrderStatusEnum.InProgress ||
    item.value === OrderStatusEnum.Fulfilled ||
    item.value === OrderStatusEnum.PartiallyFulfilled));
export const getStatusColor = (status, palette) => {
    switch (status) {
        case PrescriptionDispenseStatus.OutForDelivery:
            return palette.primary.main;
        case PrescriptionDispenseStatus.ErxHubRejected:
            return palette.secondary.main;
        case PrescriptionDispenseStatus.ErxHubPartiallyApproved:
            return palette.success.main;
        case PrescriptionDispenseStatus.Pending:
        case PrescriptionDispenseStatus.WaitingErxHubApproval:
        case PrescriptionDispenseStatus.WaitingProviderSelection:
            return palette.warning.main;
        default:
            return palette.primary.main;
    }
};
export const getOrderStatusColor = statusData => {
    switch (statusData) {
        case OrderStatus.NewRequest:
        case OrderStatus.WaitingProviderSelection:
            return "preparing";
        case OrderStatus.Delivered:
        case OrderStatus.ErxHubClaimApproved:
        case OrderStatus.ErxHubFullyApproved:
        case OrderStatus.WaitingErxHubApproval:
        case OrderStatus.ReadyForCustomerPickup:
        case OrderStatus.ErxHubPartiallyApproved:
        case OrderStatus.WaitingErxHubClaimApproval:
            return "success";
        case OrderStatus.Failed:
        case OrderStatus.ErxHubRejected:
        case OrderStatus.ErxHubClaimRejected:
        case OrderStatus.ErxHubClaimFailed:
        case OrderStatus.ErxHubTimedOut:
        case OrderStatus.ErxHubAuthRequestFailed:
        case OrderStatus.ErxHubClaimSubmitTimedOut:
            return "error";
        case OrderStatus.Allocated:
            return "pending";
        case OrderStatus.Dispensed:
            return "blue";
        case OrderStatus.OutForDelivery:
            return "warning";
        default:
            return "pending";
    }
};
