import { AppTypes, Group, PermissionEnum, PermissionGroupSortField } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { hasPermission } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FilterGrid, MainListActions, OrderDirection, PageWrapper, TableGrid, TableSettingComponent, useBreadCrumbs } from "@toolkit/ui";
import { permissionsManagementTitleBreadcrumb } from "pages/PermissionsManagement/constants/permissions-management-breadcrumbs";
import { permissionsManagementPaths } from "pages/PermissionsManagement/constants/permissions-management-paths";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDeletePermissionGroupMutation, useGetAdminPermissionsQuery } from "../../gql";
import { usePermissionsManagementColumns } from "./components/PermissionsColumns";

const PermissionsList: FC<{ appType: AppTypes }> = ({ appType }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("admin");
  const canManagePermissionGroup = hasPermission(PermissionEnum.ManagePermissionGroup);
  const PermissionsGroupColumns = usePermissionsManagementColumns();
  const { setBreadCrumb } = useBreadCrumbs();

  const handleEdit = ({ id }: Group) => {
    navigate(permissionsManagementPaths.editPath.fullPathWithParams(id));
  };

  const handleAddNew = () => {
    navigate(permissionsManagementPaths.newPath.fullPath);
  };

  useEffect(() => {
    setBreadCrumb({
      title: permissionsManagementTitleBreadcrumb(t),
    });
  }, []);

  return (
    <GridProvider
      hasTableSetting
      gridName='permissionGroups'
      columns={PermissionsGroupColumns}
      query={useGetAdminPermissionsQuery}
      tableAction={{
        isEditVisible: true,
        onEditRow: handleEdit,
        isDeleteVisible: true,
        isRowDeletable: canManagePermissionGroup,
        isRowEditable: canManagePermissionGroup,
      }}
      variables={{
        appType: appType,
        sortBy: {
          direction: OrderDirection.Desc,
          field: PermissionGroupSortField.CreatedDate,
        },
      }}
      dataAccessor='permissionGroups'
      deleteItemProps={{
        name: "Permission group",
        entityTypeName: "Group",
        useDeleteMutation: useDeletePermissionGroupMutation,
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            {canManagePermissionGroup ? <MainListActions hasAddNew onAddNewItem={handleAddNew} /> : ""}
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
export default PermissionsList;
