import { gql } from '@apollo/client';
import { PageInfoAutocompleteFragmentDoc } from '../../../../gql/fragments/__generated__/PageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const DocumentStoresAutocompleteDocument = gql `
    query DocumentStoresAutocomplete($first: Int, $last: Int, $before: String, $after: String) {
  documentStores(first: $first, last: $last, before: $before, after: $after) {
    pageInfo {
      ...PageInfoAutocomplete
    }
    edges {
      node {
        id
        name
      }
    }
  }
}
    ${PageInfoAutocompleteFragmentDoc}`;
/**
 * __useDocumentStoresAutocompleteQuery__
 *
 * To run a query within a React component, call `useDocumentStoresAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentStoresAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentStoresAutocompleteQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useDocumentStoresAutocompleteQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(DocumentStoresAutocompleteDocument, options);
}
export function useDocumentStoresAutocompleteLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(DocumentStoresAutocompleteDocument, options);
}
export function useDocumentStoresAutocompleteSuspenseQuery(baseOptions) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(DocumentStoresAutocompleteDocument, options);
}
