import { H_OrderDirection, HealthProgramNetworkSortingField } from "@/schema/types";
import { getHealthProgramsAutocompleteFilter, getVendorsAutocompleteFilter } from "@health/autocompletes";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, ShowButton } from "@toolkit/ui";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { HealthProgramNetworkActivation } from "../../components/HealthProgramNetworkActivation/HealthProgramNetworkActivation";
import { healthProgramNetworksPaths } from "../../constants";
import { IHealthProgramNetworkNode } from "../../types";

export const useHealthProgramNetworkListContainerColumns = (): CustomTableColumnProps<IHealthProgramNetworkNode>[] => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();

  const onShowProvidersClick = useCallback(
    (networkId: string) => {
      navigate(healthProgramNetworksPaths.providerList.fullPathWithParams({ networkId }));
    },
    [navigate]
  );

  return useMemo(
    () => [
      {
        key: "name",
        header: t("Name"),
        accessor: ({ name }) => name || "-",
        isSortable: true,
        sortDirection: H_OrderDirection.Desc,
        sortColumnEnum: HealthProgramNetworkSortingField.Name,
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
      {
        key: "categoryCode",
        header: t("Category Code"),
        accessor: ({ categoryCode }) => categoryCode || "-",
      },
      {
        key: "policyNumber",
        header: t("Policy Number"),
        accessor: ({ policyNumber }) => policyNumber || "-",
      },
      {
        key: "payer",
        header: t("Payer"),
        accessor: ({ payer }) => payer?.name || "-",
      },
      {
        key: "providers",
        header: t("Providers"),
        accessor: ({ id }) => <ShowButton onClick={() => onShowProvidersClick(id)} />,
        filter: getVendorsAutocompleteFilter({ name: "providers", multiple: true }),
      },
      {
        key: "healthPrograms",
        header: t("Health Programs"),
        showOnlyForFilterField: true,
        filter: getHealthProgramsAutocompleteFilter({ name: "healthPrograms", multiple: true }),
      },
      {
        key: "createdDate",
        header: t("Created Date"),
        accessor: ({ created }) => (created ? moment(created).format("DD MMM YYYY") : "-"),
      },
      {
        key: "isActive",
        header: t("Active"),
        accessor: ({ id, isActive }) => <HealthProgramNetworkActivation id={id} isActive={!!isActive} />,
        isSortable: true,
        sortDirection: H_OrderDirection.Desc,
        sortColumnEnum: HealthProgramNetworkSortingField.IsActive,
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
    ],
    [onShowProvidersClick, t]
  );
};
