import { MarketplaceProductSortingField, SortDirection } from "@/schema/types";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, GridImageModal } from "@toolkit/ui";
import { useMemo } from "react";
import { BrandsQueryNode } from "../../types";

export const useBrandsListTableColumns = (): CustomTableColumnProps<BrandsQueryNode>[] => {
  const { t } = useTranslation("admin");

  return useMemo(() => {
    return [
      {
        key: "image",
        header: t("Image"),
        accessor: ({ image }) => <GridImageModal image={image!} title={t("Brand Image")} altText={t("Brand")} />,
      },
      {
        key: "name",
        header: t("Name"),
        accessor: pickLocalizedValue("name", "nameAr"),
        filter: {
          type: "string",
          name: "search",
        },
        isSortable: true,
        sortDirection: SortDirection.Desc,
        sortColumnEnum: pickLocalizedValue(MarketplaceProductSortingField.Name, MarketplaceProductSortingField.NameAr),
      },
    ];
  }, []);
};
