import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { DecisionMakerOrderDirection, SubListSortField } from "../../../../schema/types";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { useSublistDeleteMutation, useSublistListQuery } from "../../../Sublists/gql";
import { GridProvider } from "../../../../shared/components";
import { useSublistListTableColumns } from "./useSublistListTableColumns";
export const SublistListContainer = props => {
    const { onAddNewClick, onEditRowClick } = props;
    return (_jsx(GridProvider, { hasTableSetting: true, gridName: 'sublistList', columns: useSublistListTableColumns(), query: useSublistListQuery, variables: {
            sortBy: {
                field: SubListSortField.Display,
                direction: DecisionMakerOrderDirection.Asc,
            },
        }, tableAction: {
            isEditVisible: true,
            onEditRow: onEditRowClick,
            isDeleteVisible: true,
        }, deleteItemProps: {
            useDeleteMutation: useSublistDeleteMutation,
            entityTypeName: "SubList",
            name: "SubList",
        }, children: _jsx(PageWrapper, { filters: _jsx(FilterGrid, {}), actions: _jsxs(_Fragment, { children: [_jsx(TableSettingComponent, {}), _jsx(MainListActions, { hasAddNew: true, onAddNewItem: onAddNewClick })] }), children: _jsx(TableGrid, {}) }) }));
};
