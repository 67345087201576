import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { BranchFragmentFragmentDoc } from '../../../../../gql/fragments/__generated__/Branch';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BranchUpdateMutationVariables = Types.Exact<{
  branch: Types.BranchInput;
  id: Types.Scalars['ID']['input'];
}>;


export type BranchUpdateMutation = { __typename?: 'Mutation', branchUpdate?: { __typename?: 'BranchUpdate', branchErrors: Array<{ __typename?: 'BranchError', field?: string | null, message?: string | null, code: Types.VendorErrorCode }>, branch?: { __typename?: 'Branch', id: string, isOpen?: boolean | null, isActive: boolean, name: string, description: string, nameAr?: string | null } | null } | null };


export const BranchUpdateDocument = gql`
    mutation branchUpdate($branch: BranchInput!, $id: ID!) {
  branchUpdate(input: $branch, id: $id) {
    branchErrors {
      field
      message
      code
    }
    branch {
      ...BranchFragment
    }
  }
}
    ${BranchFragmentFragmentDoc}`;
export type BranchUpdateMutationFn = Apollo.MutationFunction<BranchUpdateMutation, BranchUpdateMutationVariables>;

/**
 * __useBranchUpdateMutation__
 *
 * To run a mutation, you first call `useBranchUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBranchUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [branchUpdateMutation, { data, loading, error }] = useBranchUpdateMutation({
 *   variables: {
 *      branch: // value for 'branch'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBranchUpdateMutation(baseOptions?: Apollo.MutationHookOptions<BranchUpdateMutation, BranchUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BranchUpdateMutation, BranchUpdateMutationVariables>(BranchUpdateDocument, options);
      }
export type BranchUpdateMutationHookResult = ReturnType<typeof useBranchUpdateMutation>;
export type BranchUpdateMutationResult = Apollo.MutationResult<BranchUpdateMutation>;
export type BranchUpdateMutationOptions = Apollo.BaseMutationOptions<BranchUpdateMutation, BranchUpdateMutationVariables>;