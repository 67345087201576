import { CallbackRequest } from "@/schema/types";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { CustomTableColumnProps, TruncateTypography, Typography } from "@toolkit/ui";
import { startCase } from "lodash";
import moment from "moment/moment";
import { useMemo } from "react";
import { PatientNotesModal } from "../components/PatientNotesModal";
import { RequestHandlingModal } from "../components/RequestHandling/RequestHandlingModal";
export type CallbackRequestListColumnsTypes = CustomTableColumnProps<CallbackRequest>[];

export const useCallbackRequestListColumns = (): CallbackRequestListColumnsTypes => {
  const { t } = useTranslation("admin");
  return useMemo(() => {
    return [
      {
        key: "id",
        header: t("Request ID"),
        accessor: row => <Typography sx={{ maxWidth: "100px", width: "100px" }}>{row.id}</Typography>,
      },
      {
        key: "fullName",
        header: t("Patient Name"),
        accessor: row => <TruncateTypography text={row?.user?.fullName!} />,
      },
      {
        key: "contactNumber",
        header: t("Contact Number"),
        accessor: row => <TruncateTypography text={row?.patient?.contactNumber!} />,
      },
      {
        key: "serviceName",
        header: t("Service Name"),
        accessor: row => <TruncateTypography text={row?.serviceName!} />,
      },
      {
        key: "status",
        header: t("Request Status"),
        accessor: row => t(startCase(row?.status!.toLowerCase())),
        filter: getAutocompleteEnumFilter("CallbackRequestStatus", "status"),
      },
      {
        key: "patientNotes",
        header: t("Patient Notes"),
        accessor: row => <PatientNotesModal patientNotes={row.patientNotes} />,
      },
      {
        key: "requestDate",
        header: t("Request Date"),
        accessor: ({ created }) => (created ? moment(created).format("DD MMM YYYY") : "-"),
      },
      {
        key: "requestHandling",
        header: t("Request Handling"),
        accessor: row => <RequestHandlingModal callBackRequest={row} />,
      },
    ];
  }, [t]);
};
