import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { OptimaActionsAutocomplete } from "@health/autocompletes";
import { optimaPriorityOptions } from "@health/enum-options";
import { useCustomForm } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { FormActions, FormAutocomplete, FormCard, FormSwitch, FormTextField, Grid, PageWrapper } from "@toolkit/ui";
import { convertOptimaEditToFormValues } from "../../../OptimaEdits/others";
import { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { optimaEditUpsertFormDefaultValues, optimaEditUpsertFormSchema } from "./OptimaEditUpsertFormSchema";
export const OptimaEditUpsertForm = props => {
    const { optimaEdit, buttonLabel, isLoading, isButtonDisabled, onChange } = props;
    const { t } = useTranslation("domains");
    const navigate = useNavigate();
    const form = useCustomForm({
        defaultValues: optimaEditUpsertFormDefaultValues,
        schema: optimaEditUpsertFormSchema,
    });
    const { setValues, handleSubmit } = form;
    const onSubmit = (values) => {
        onChange({
            type: "SUBMIT",
            payload: { values },
        });
    };
    const handleCancel = () => {
        navigate(-1);
    };
    useEffect(() => {
        if (optimaEdit) {
            const _optimaEdit = convertOptimaEditToFormValues(optimaEdit);
            setValues(_optimaEdit);
        }
    }, [optimaEdit]);
    return (_jsx(FormProvider, Object.assign({}, form, { children: _jsx("form", { onSubmit: handleSubmit(onSubmit), children: _jsx(PageWrapper, { actions: _jsx(FormActions, { hasCancel: true, hasFormButton: true, isLoading: isLoading, formButtonTitle: buttonLabel, newButtonDisabled: isButtonDisabled, onNavigation: handleCancel }), children: _jsx(FormCard, { title: t("Optima Edit Information"), loading: isLoading, doYouHaveData: true, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, sm: 6, md: 4, children: _jsx(FormTextField, { name: "code", label: t("Code"), placeholder: t("Code") }) }), _jsx(Grid, { item: true, xs: 12, sm: 6, md: 4, children: _jsx(FormTextField, { name: "category", label: t("Category"), placeholder: t("Category") }) }), _jsx(Grid, { item: true, xs: 12, sm: 6, md: 4, children: _jsx(FormTextField, { name: "subCategory", label: t("Sub Category"), placeholder: t("Sub Category") }) }), _jsx(Grid, { item: true, xs: 12, sm: 6, md: 4, children: _jsx(FormAutocomplete, { name: "priority", label: t("Priority"), options: optimaPriorityOptions }) }), _jsx(Grid, { item: true, xs: 12, sm: 6, md: 4, children: _jsx(OptimaActionsAutocomplete, { name: "actions", multiple: true, filter: { isActive: true } }) }), _jsx(Grid, { item: true, xs: 12, sm: 6, md: 4, children: _jsx(FormTextField, { name: "source", label: t("Source"), placeholder: t("Source") }) }), _jsx(Grid, { item: true, xs: 12, sm: 6, children: _jsx(FormTextField, { name: "details", label: t("Details"), placeholder: t("Details"), multiline: true, rows: 4 }) }), _jsx(Grid, { item: true, xs: 12, sm: 6, children: _jsx(FormTextField, { name: "message", label: t("Message"), placeholder: t("Message"), multiline: true, rows: 4 }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(FormSwitch, { name: "isActive", label: t("Active") }) })] }) }) }) }) })));
};
