import { QuestionType } from "@/schema/types";
import { useTranslation } from "@toolkit/i18n";
import { z } from "zod";

export const useSurveyValidationHook = () => {
  const { t } = useTranslation("admin");
  const questionInputSchema = z
    .object({
      id: z.string().nullish(),
      options: z.array(z.string()),
      question: z.string().min(1),
      questionType: z.string().min(1),
      required: z.boolean(),
    })
    .refine(
      data => {
        // If questionType is Boolean or Free Text, then options must be empty
        if (data.questionType === QuestionType.Boolean || data.questionType === QuestionType.String) {
          return data.options.length === 0;
        } else {
          // For all other questionTypes, options must not be empty
          return data.options.length > 0;
        }
      },
      {
        message: t("Options must be filled"),
        path: ["options"],
      }
    );

  const surveyValidationSchema = z.object({
    description: z.string().min(1),
    label: z.string().min(1),
    notification: z.string().min(1),
    questions: z.array(questionInputSchema),
  });

  return { surveyValidationSchema };
};
