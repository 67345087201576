import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const HealthParameterUnitsAutocompleteDocument = gql `
    query HealthParameterUnitsAutocomplete {
  getHealthParameterUnits {
    id
    code
    display
    arabicDisplay
  }
}
    `;
/**
 * __useHealthParameterUnitsAutocompleteQuery__
 *
 * To run a query within a React component, call `useHealthParameterUnitsAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthParameterUnitsAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthParameterUnitsAutocompleteQuery({
 *   variables: {
 *   },
 * });
 */
export function useHealthParameterUnitsAutocompleteQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(HealthParameterUnitsAutocompleteDocument, options);
}
export function useHealthParameterUnitsAutocompleteLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(HealthParameterUnitsAutocompleteDocument, options);
}
export function useHealthParameterUnitsAutocompleteSuspenseQuery(baseOptions) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(HealthParameterUnitsAutocompleteDocument, options);
}
