import { careForTypeOptions } from "@/pages/HealthPrograms/enum-options";
import { CodeSystemCode } from "@/schema/types";
import {
  HealthProgramMemberListsAutocomplete,
  HealthProgramNetworksAutocomplete,
  PayersAutocomplete,
  SystemCodeAutocomplete,
} from "@health/autocompletes";
import { healthProgramTypeOptions } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { FormAutocomplete, Grid } from "@toolkit/ui";
import { FC } from "react";

type HealthProgramDetailsFormProps = {
  isUpdateMode: boolean;
};

export const HealthProgramDetailsForm: FC<HealthProgramDetailsFormProps> = props => {
  const { isUpdateMode } = props;

  const { t } = useTranslation("admin");

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4}>
        <FormAutocomplete name={"type"} label={t("Type")} options={healthProgramTypeOptions} disabled={isUpdateMode} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <FormAutocomplete name={"careForTypes"} label={t("Care For Types")} multiple options={careForTypeOptions} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <PayersAutocomplete name={"payer"} filter={{ isActive: true }} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <HealthProgramNetworksAutocomplete name={"networks"} multiple filter={{ isActive: true }} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <SystemCodeAutocomplete name={"specializations"} isCodeHidden multiple filter={{ codeSystemCode: CodeSystemCode.Speciality }} />
      </Grid>

      <Grid item xs={12} sm={6} md={4}>
        <HealthProgramMemberListsAutocomplete name={"membersLists"} multiple filter={{ isActive: true }} />
      </Grid>
    </Grid>
  );
};
