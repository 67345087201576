import { UserUpdateInput } from "@/schema/types";
import { formGirdSpacing } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Box, FormActions, Grid, PageWrapper } from "@toolkit/ui";
import { providerUserManagementPaths } from "pages/UserManagement/constants/provider-user-management-paths";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { DoctorDetailsComponent } from "./components/DoctorDetails.component";
import { HealthLicenseComponent } from "./components/HealthLicense.component";
import { ProviderInformation } from "./components/ProviderInformation.components";
import { UserFormProps } from "./components/UserForm.types";
import { UserInformation } from "./components/UserInformation.components";

export const HealthProviderUserManagementForm: FC<UserFormProps> = ({
  errors,
  onDone,
  isEdit,
  isLoading,
  defaultValues,
  isFormLoading,
}) => {
  const { t } = useTranslation("admin");
  const navigate = useNavigate();
  const methods = useForm<UserUpdateInput>({
    mode: "onChange",
  });
  const { handleSubmit } = methods;
  const onSubmit = (data: UserUpdateInput) => {
    if (onDone) {
      onDone(data);
    }
  };

  const handleCancel = () => {
    navigate(providerUserManagementPaths.listPath.fullPath);
  };

  const doYouHaveUser = isEdit ? defaultValues != undefined : true;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PageWrapper
          actions={
            <Box display='inline-block'>
              <FormActions
                isLoading={isLoading}
                hasCancel
                hasFormButton
                formButtonTitle={isEdit ? t("Update") : t("Create")}
                onNavigation={handleCancel}
                newButtonDisabled={isFormLoading}
              />
            </Box>
          }
        >
          <Grid container spacing={formGirdSpacing}>
            <Grid item xs={12}>
              <UserInformation
                errors={errors}
                defaultValues={defaultValues}
                isEdit={isEdit}
                doYouHaveUser={doYouHaveUser}
                isLoading={isLoading}
              />
            </Grid>

            <Grid item xs={12}>
              <ProviderInformation errors={errors} defaultValues={defaultValues} doYouHaveUser={doYouHaveUser} isLoading={isLoading} />
            </Grid>
            <DoctorDetailsComponent
              errors={errors}
              doYouHaveUser={doYouHaveUser}
              isLoading={isLoading as boolean}
              defaultValues={defaultValues}
              isEdit={isEdit as boolean}
            />
            <HealthLicenseComponent
              errors={errors}
              defaultValues={defaultValues}
              doYouHaveUser={doYouHaveUser}
              isLoading={isLoading as boolean}
              isEdit={isEdit as boolean}
            />
          </Grid>
        </PageWrapper>
      </form>
    </FormProvider>
  );
};
