import { OrderDirection, PayerOrderField } from "@/schema/types";
import { getAutocompleteEnumFilter, payerTpoOptionsMap, payerTypeOptionsMap } from "@health/enum-options";
import { pickLocalizedValue, useTranslation } from "@toolkit/i18n";
import { Avatar, CustomTableColumnProps } from "@toolkit/ui";
import moment from "moment";
import { useMemo } from "react";
import { IPayerListNode } from "../../types";

export const usePayerListContainerColumns = (): CustomTableColumnProps<IPayerListNode>[] => {
  const { t } = useTranslation("admin");

  return useMemo(
    () => [
      {
        key: "logo",
        header: t("Logo"),
        accessor: ({ logo }) => <Avatar src={logo!} />,
      },
      {
        key: "name",
        header: t("Name"),
        accessor: ({ name, nameAr }) => pickLocalizedValue(name, nameAr),
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: PayerOrderField.Name,
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
      {
        key: "contactName",
        header: t("Contact Name"),
        accessor: "contactName",
      },
      {
        key: "contactEmail",
        header: t("Contact Email"),
        accessor: "contactEmail",
      },
      {
        key: "contactNumber",
        header: t("Contact Number"),
        accessor: ({ contactPhoneNumber, contactMobileNumber }) => contactPhoneNumber || contactMobileNumber || "-",
      },
      {
        key: "licenseNumber",
        header: t("License Number"),
        accessor: "licenseNumber",
        filter: {
          type: "string",
          name: "licenseNumber",
        },
      },
      {
        key: "type",
        header: t("Type"),
        accessor: ({ type }) => payerTypeOptionsMap[type!]?.label,
      },
      {
        key: "tpo",
        header: t("TPO"),
        accessor: ({ tpo }) => payerTpoOptionsMap[tpo!]?.label,
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: PayerOrderField.Tpo,
      },
      {
        key: "created",
        header: t("Created Date"),
        accessor: ({ created }) => (created ? moment(created).format("DD/MM/YYYY") : "-"),
        isSortable: true,
        sortDirection: OrderDirection.Desc,
        sortColumnEnum: PayerOrderField.Created,
      },
      {
        key: "active",
        header: t("Active"),
        // todo: create a component for activation
        accessor: ({ isActive }) => (isActive ? t("Yes") : t("No")),
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
    ],
    [t]
  );
};
