/* eslint-disable max-lines */
import { PermissionEnum, QuestionType, SurveyStatus } from "@/schema/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { hasPermission, useOpenState } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { surveysPaths } from "pages/Surveys/constants/surveys-paths";
import { useSurveyDetailsContext } from "pages/Surveys/state/context";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { uuidv4 } from "utils";
import { z } from "zod";
import { useSurveyValidationHook } from "./surveyValidationHook";
import { useMutations } from "./useMutations";
import { useSurveyQuestionsStyle } from "./useSurveyQuestionsStyle";

export const useSurveyQuestionsTab = () => {
  const { survey, isLoading: isLoadingSurvey } = useSurveyDetailsContext();
  const { surveyValidationSchema } = useSurveyValidationHook();
  type SurveyInputSchema = z.infer<typeof surveyValidationSchema>;
  const methods = useForm<SurveyInputSchema>({
    resolver: zodResolver(surveyValidationSchema),
    mode: "onChange",
  });

  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const { open: isUnPublishDialogOpen, handleToggle: handleToggleUnPublishModalOpen } = useOpenState();
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const { open: isCloneModalOpen, handleToggle: handleToggleCloneModalOpen } = useOpenState();
  const { append, remove, update } = useFieldArray({
    control: methods.control,
    name: "questions",
  });

  const questions = methods.watch("questions") ?? [];

  const navigate = useNavigate();

  const { t } = useTranslation("admin");

  const { classes } = useSurveyQuestionsStyle();

  const canManageSurvey = hasPermission(PermissionEnum.ManageSurveys);

  const isSaveButtonVisible = (!survey?.status || survey?.status === SurveyStatus.Draft) && canManageSurvey;
  const isEditedForm = methods.formState.isDirty;
  const isEditedValid = methods.formState.isValid;

  const handleAddQuestion = () => {
    append({ id: uuidv4(), options: [], question: "", questionType: QuestionType.MultiChoice, required: true });
  };

  const handleRemoveQuestion = (index: number) => {
    remove(index);
  };

  const handleResetFields = () => {
    methods?.reset?.({
      description: survey?.description || "",
      label: survey?.label || "",
      notification: survey?.notification || "",
      questions:
        survey?.questions?.map(q => ({
          id: q.id,
          options: (q.options as string[]) || [],
          question: q.question || "",
          questionType: q.questionType || QuestionType.SingleChoice,
          required: q.required ?? true,
        })) ?? [],
    });
  };

  const handleCloseCancelModal = () => {
    navigate(surveysPaths.listPath.fullPath);
  };

  const handleCancel = () => {
    navigate(surveysPaths.listPath.fullPath);
  };

  const handleCancelSurvey = () => {
    cancelSurveyMutation({
      variables: {
        cancelSurveyId: survey?.id as string,
      },
    });
    navigate(surveysPaths.listPath.fullPath);
  };

  const handlePublishModalOpen = () => {
    setIsPublishModalOpen(true);
  };

  const handleToggleCancelModalOpen = () => {
    setIsCancelModalOpen(prevState => !prevState);
  };

  const handleClosePublishModal = () => {
    setIsPublishModalOpen(false);
  };

  const handleAddOption = (qIndex: number) => (option: string) => {
    const question = questions[qIndex];
    update(qIndex, { ...question, options: [...question.options, option] });
  };

  const handleRemoveOption = (qIndex: number, optionIndex: number) => {
    const question = questions[qIndex];
    const newOptions = question.options.slice();
    newOptions.splice(optionIndex, 1);
    update(qIndex, { ...question, options: newOptions });
  };

  useEffect(() => {
    handleResetFields();
  }, [survey]);

  const {
    isUpdateLoading,
    isCreateLoading,
    isPublishLoading,
    isCancelLoading,
    isUnPublishLoading,
    isCloneLoading,
    handlePublish,
    handleUnPublish,
    handleCreateSurvey,
    handleSaveSurvey,
    publishSurveyMutation,
    updateSurveyMutation,
    createSurveyMutation,
    cancelSurveyMutation,
    cloneSurveyMutation,
  } = useMutations({ survey, methods, onCloneCompleted: handleToggleCloneModalOpen });

  const handleCloneSurvey = (clonedSurveyLabel: string) => {
    cloneSurveyMutation({
      variables: {
        cloneSurveyId: survey?.id!,
        label: clonedSurveyLabel,
      },
    });
  };

  return {
    survey,
    methods,
    isLoadingSurvey,
    classes,
    isSaveButtonVisible,
    questions,
    canUnPublishSurvey: survey?.status === SurveyStatus.Published,
    isSurveyCancelable: survey?.status !== SurveyStatus.Cancelled,
    isPublishModalOpen,
    isUnPublishDialogOpen,
    isUpdateLoading,
    isCreateLoading,
    isPublishLoading,
    isUnPublishLoading,
    isPublishDisabled: isEditedForm || (survey?.status !== SurveyStatus.Draft && survey?.status !== SurveyStatus.Expired),
    isSaveDisabled: !isEditedValid || !isEditedForm || isCreateLoading || isUpdateLoading || isPublishLoading || isCloneLoading,
    isCloneModalOpen,
    isCancelModalOpen,
    isCancelLoading,
    isCloneLoading,
    isEditedForm,
    canManageSurvey,
    isDisabled: survey?.status === SurveyStatus.Published,
    t,
    publishSurveyMutation,
    updateSurveyMutation,
    createSurveyMutation,
    handlePublish,
    handleUnPublish,
    handlePublishModalOpen,
    handleSaveSurvey,
    handleCreateSurvey,
    handleClosePublishModal,
    handleRemoveQuestion,
    handleRemoveOption,
    handleAddQuestion,
    handleAddOption,
    handleResetFields,
    handleCancelSurvey,
    handleCancel,
    handleCloseCancelModal,
    handleToggleCancelModalOpen,
    handleToggleUnPublishModalOpen,
    handleCloneSurvey,
    handleToggleCloneModalOpen,
  };
};
