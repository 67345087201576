import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { useOptimaEditListQuery } from "../../../OptimaEdits/gql";
import { GridProvider } from "../../../../shared/components";
import { useOptimaEditListContainerColumns } from "./useOptimaEditListContainerColumns";
export const OptimaEditListContainer = props => {
    const { onChange } = props;
    const onAddNewItemClick = () => {
        onChange({
            type: "ADD_CLICK",
        });
    };
    const onEditRowClick = (item) => {
        onChange({
            type: "UPDATE_CLICK",
            payload: { item },
        });
    };
    return (_jsx(GridProvider, { gridName: "optimaEditList", query: useOptimaEditListQuery, columns: useOptimaEditListContainerColumns(), hasTableSetting: true, tableAction: {
            isEditVisible: true,
            isDeleteVisible: false,
            onEditRow: onEditRowClick,
        }, children: _jsx(PageWrapper, { filters: _jsx(FilterGrid, {}), actions: _jsxs(_Fragment, { children: [_jsx(TableSettingComponent, {}), _jsx(MainListActions, { hasAddNew: true, onAddNewItem: onAddNewItemClick })] }), children: _jsx(TableGrid, {}) }) }));
};
