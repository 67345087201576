import * as Types from '@/schema/types';

import { gql } from '@apollo/client';
import { AddressFragmentFragmentDoc } from '../../../../../gql/fragments/__generated__/Address';
import { BranchFragmentFragmentDoc } from '../../../../../gql/fragments/__generated__/Branch';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BranchQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type BranchQuery = { __typename?: 'Query', branch?: { __typename?: 'Branch', type?: Types.BranchType | null, contactNumber?: string | null, contactEmail?: string | null, healthLicense?: string | null, healthLicenseStartDate?: any | null, healthLicenseEndDate?: any | null, id: string, isOpen?: boolean | null, isActive: boolean, name: string, description: string, nameAr?: string | null, address?: { __typename: 'Address', id: string, area: string, areaAr: string, streetAddress1: string, streetAddress1Ar: string, streetAddress2: string, streetAddress2Ar: string, buildingName?: string | null, buildingNumber?: string | null, phone?: string | null, isDefaultShippingAddress?: boolean | null, region: string, city: { __typename?: 'City', id: string, name: string, nameAr?: string | null }, coordinates?: { __typename?: 'LocationType', lng?: number | null, lat?: number | null } | null } | null, workingHours?: Array<{ __typename?: 'WorkingHour', day?: Types.DayOfWeekEnum | null, openTimeRanges?: Array<{ __typename?: 'TimeRange', openTime?: any | null, closeTime?: any | null } | null> | null } | null> | null } | null };


export const BranchDocument = gql`
    query branch($id: ID!) {
  branch(id: $id) {
    address {
      ...AddressFragment
    }
    ...BranchFragment
    type
    contactNumber
    contactEmail
    healthLicense
    healthLicenseStartDate
    healthLicenseEndDate
    workingHours {
      day
      openTimeRanges {
        openTime
        closeTime
      }
    }
  }
}
    ${AddressFragmentFragmentDoc}
${BranchFragmentFragmentDoc}`;

/**
 * __useBranchQuery__
 *
 * To run a query within a React component, call `useBranchQuery` and pass it any options that fit your needs.
 * When your component renders, `useBranchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBranchQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBranchQuery(baseOptions: Apollo.QueryHookOptions<BranchQuery, BranchQueryVariables> & ({ variables: BranchQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BranchQuery, BranchQueryVariables>(BranchDocument, options);
      }
export function useBranchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BranchQuery, BranchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BranchQuery, BranchQueryVariables>(BranchDocument, options);
        }
export function useBranchSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BranchQuery, BranchQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BranchQuery, BranchQueryVariables>(BranchDocument, options);
        }
export type BranchQueryHookResult = ReturnType<typeof useBranchQuery>;
export type BranchLazyQueryHookResult = ReturnType<typeof useBranchLazyQuery>;
export type BranchSuspenseQueryHookResult = ReturnType<typeof useBranchSuspenseQuery>;
export type BranchQueryResult = Apollo.QueryResult<BranchQuery, BranchQueryVariables>;