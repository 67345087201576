import { AppTypes, Group } from "@/schema/types";
import { combineErrors } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { useAutocompleteCustomHook } from "@toolkit/ui";
import { GetAllPermissionGroupsQueryVariables, useGetAllPermissionGroupsQuery } from "pages/UserManagement/gql";
import { useFormContext } from "react-hook-form";

export const usePermissionsGroupHook = (errors, defaultValues) => {
  const { t } = useTranslation("admin");
  const {
    control,
    formState: { errors: formErrors },
  } = useFormContext();
  const selectedAppType = { value: AppTypes.Admin, name: "Admin" };
  const combinedErrors = combineErrors(formErrors, errors);
  const inputs: GetAllPermissionGroupsQueryVariables = {
    first: 10,
    after: null,
    before: null,
    last: null,
    appType: AppTypes.Admin,
  };
  const dataAccessor = "permissionGroups";
  const {
    data: permissionGroupsData,
    isLoading: loadingPermission,
    onFetchMoreData: onFetchMorePermissions,
    refetch,
  } = useAutocompleteCustomHook({
    useAutocompleteControllerQuery: useGetAllPermissionGroupsQuery,
    input: inputs,
    dataAccessor: dataAccessor,
    skip: !selectedAppType,
  });
  const handleSearch = (filter: string) => {
    refetch({
      filter: {
        search: filter,
      },
    });
  };
  const permissionPageInfo = permissionGroupsData?.[dataAccessor]?.pageInfo;
  const hasMorePermission = permissionPageInfo?.hasNextPage;
  const permissions = permissionGroupsData?.[dataAccessor]?.edges?.map(item => item?.node)! as Group[];
  const permissionDefaultValue = defaultValues?.permissionGroups;
  return {
    loadingPermission,
    selectedAppType,
    t,
    control,
    combinedErrors,
    onFetchMorePermissions,
    hasMorePermission,
    permissionDefaultValue,
    permissions,
    handleSearch,
  };
};
