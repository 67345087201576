import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const GetPharmacyCredentialsDocument = gql `
    query GetPharmacyCredentials($branch: ID!, $destinationType: DestinationType!) {
  getPharmacyCredentials(branch: $branch, destinationType: $destinationType) {
    userName
    license
  }
}
    `;
/**
 * __useGetPharmacyCredentialsQuery__
 *
 * To run a query within a React component, call `useGetPharmacyCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPharmacyCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPharmacyCredentialsQuery({
 *   variables: {
 *      branch: // value for 'branch'
 *      destinationType: // value for 'destinationType'
 *   },
 * });
 */
export function useGetPharmacyCredentialsQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(GetPharmacyCredentialsDocument, options);
}
export function useGetPharmacyCredentialsLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(GetPharmacyCredentialsDocument, options);
}
export function useGetPharmacyCredentialsSuspenseQuery(baseOptions) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(GetPharmacyCredentialsDocument, options);
}
