import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ProcessingStatus } from "../../../../schema/types";
import { useTranslation } from "@toolkit/i18n";
import { CustomAlert } from "@toolkit/ui";
const StatusAlertsPanel = ({ processingStatus, versionNumber, userName }) => {
    const { t } = useTranslation("domains");
    return (_jsxs(_Fragment, { children: [processingStatus === ProcessingStatus.Approved && (_jsx(CustomAlert, { level: "module", appearance: 'success', title: t("Ready To Publish"), description: t("The data reviewed successfully & get approval by") + " " + `${userName}`, disableClose: true })), processingStatus === ProcessingStatus.UnderReview && (_jsx(CustomAlert, { level: "module", appearance: 'warning', title: t("Waiting for your review."), description: t("Your action is required to review and approve/reject."), disableClose: true })), processingStatus === ProcessingStatus.Draft && (_jsx(CustomAlert, { level: "module", appearance: 'error', title: t("Be Aware! "), description: t(`You are working and viewing a draft version`), disableClose: true })), processingStatus === ProcessingStatus.Expired && (_jsx(CustomAlert, { level: "module", appearance: 'error', title: t("Be Aware! "), description: t("You are working and viewing an expired version number") + ": " + `${versionNumber}`, disableClose: true }))] }));
};
export default StatusAlertsPanel;
StatusAlertsPanel.defaultProps = {
    versionNumber: 0,
    userName: "",
};
