import { AccountError, AppRoleTypes, AppTypes, VendorUserTypes } from "@/schema/types";
import { combineErrors, formGirdBreakPoints, formGirdSpacing, getRequiredValidation } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { CustomToggleButton, FormCard, FormControlLabel, FormPhoneInput, Grid, TextField } from "@toolkit/ui";
import { FC, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { AutocompleteAppComponent } from "./CustomAppComponent";
import { userFields } from "./UserInformation.utils";

export const UserInformation: FC<{ errors: AccountError[]; defaultValues; isEdit; isLoading; doYouHaveUser }> = ({
  errors,
  defaultValues,
  isEdit,
  isLoading,
  doYouHaveUser,
}) => {
  const { t } = useTranslation("admin");
  const {
    register,
    formState: { errors: formErrors },
    control,
    watch,
    setValue,
  } = useFormContext();
  const combinedErrors = combineErrors(formErrors, errors);
  const appRole = Object?.keys(AppRoleTypes)?.map(key => ({ value: AppRoleTypes[key], name: key }));
  const vendorUserType = Object?.keys(VendorUserTypes)?.map(key => ({ value: VendorUserTypes[key], name: key }));
  const appType = [
    { value: AppTypes.Admin, name: "Admin" },
    { value: AppTypes.Vendor, name: "Vendor" },
  ];
  const appRoleDefaultValue = appRole?.find(el => el?.value === defaultValues?.appRole) || null;
  const appTypeDefaultValue = appType?.find(el => el?.value === defaultValues?.appType) || null;
  const vendorUserTypeDefaultValue = vendorUserType?.find(el => el?.value === defaultValues?.vendorUserType) || null;
  const watchAppType = watch("appType");
  useEffect(() => {
    setValue("mobile", defaultValues?.mobile ?? "");
  }, [setValue, defaultValues?.mobile]);
  return (
    <FormCard doYouHaveData={doYouHaveUser} loading={isLoading} title={t("User Information")}>
      <Grid container spacing={formGirdSpacing}>
        {userFields(t, defaultValues, isEdit)?.map(item => (
          <Grid item {...formGirdBreakPoints} key={item?.name}>
            <TextField
              inputProps={{
                autoComplete: item.autoComplete ?? "off",
              }}
              fullWidth
              error={Boolean(combinedErrors?.[item?.name]?.message)}
              helperText={t(combinedErrors?.[item?.name]?.message)}
              defaultValue={item?.defaultValue}
              label={item?.label}
              placeholder={item?.label}
              {...register(item?.name, {
                required: getRequiredValidation(t, item?.isRequired),
                minLength: item?.minLength,
                maxLength: item?.maxLength,
                pattern: item?.pattern,
              })}
              type={item?.type}
              disabled={item?.disabled}
            />
          </Grid>
        ))}
        <Grid item {...formGirdBreakPoints}>
          <FormPhoneInput
            {...register("mobile", {
              required: getRequiredValidation(t, false),
            })}
            label={t("Mobile Number")}
            error={Boolean(combinedErrors?.["mobile"]?.message)}
            helperText={t(combinedErrors?.["mobile"]?.message)}
            fullWidth
          />
        </Grid>
        <Grid item {...formGirdBreakPoints}>
          <AutocompleteAppComponent
            formErrors={errors}
            name={"appRole"}
            label={t("App Role")}
            options={appRole}
            defaultAppValue={appRoleDefaultValue}
          />
        </Grid>
        {(watchAppType?.value == AppTypes.Vendor || appTypeDefaultValue?.value == AppTypes.Vendor) && (
          <Grid item {...formGirdBreakPoints}>
            <AutocompleteAppComponent
              formErrors={errors}
              name={"vendorUserType"}
              label={t("Vendor User Type")}
              options={vendorUserType}
              defaultAppValue={vendorUserTypeDefaultValue}
              isDisabled={isEdit}
            />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2} item alignItems='center' sx={{ padding: "8px" }}>
        <Grid item>
          <Controller
            name='isActive'
            defaultValue={defaultValues?.isActive ?? true}
            control={control}
            render={({ field: { onChange: handleC, value } }) => (
              <FormControlLabel
                control={<CustomToggleButton onChange={e => handleC(e.target.checked)} checked={value} />}
                label={t("Is Active")}
              />
            )}
          />
        </Grid>
      </Grid>
    </FormCard>
  );
};
