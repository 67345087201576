/* eslint-disable max-lines */
import { Vendor, VendorOrderField } from "@/schema/types";
import { getAutocompleteEnumFilter } from "@health/enum-options";
import { getMediaLink } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { Avatar, Box, CustomTableColumnProps, format, Tooltip, TruncateTypography, Typography } from "@toolkit/ui";
import { useMemo } from "react";
import { AddressInformation } from "shared/components/AddressModel/AddressInformation.component";
import { IsActiveToggleButton } from "./ActiveToggle.component";
import { BankingInfo } from "./BankingInfo.list";
import BranchesInfo from "./BranchesInfo.list";
import { OwnerInfo } from "./OwnerInfo.list";
import { SupportOutpatientJourney } from "./SupportOutpatientJourney.component";
import TerminateVendor from "./TerminateVendor.list";
import { UsersList } from "./UsersList";
import { getVendorType } from "./VendorList.types";
import { WorkingHours } from "./WorkingHours.list";

export const useVendorsColumns = (): CustomTableColumnProps<Vendor>[] => {
  const { t } = useTranslation("admin");

  return useMemo(() => {
    return [
      {
        key: "avatar",
        header: t("Logo"),
        accessor: ({ logo }) => <Avatar src={getMediaLink(logo!)} />,
        isHidden: false,
        hideFromSettings: true,
        disableToggleVisibility: true,
      },
      {
        key: "name",
        header: t("Name"),
        accessor: ({ name }) => <TruncateTypography text={name} />,
        isHidden: false,
        isSortable: true,
        sortDirection: undefined,
        hideFromSettings: true,
        disableToggleVisibility: true,
        sortColumnEnum: VendorOrderField.Name,
        filter: {
          type: "string",
          name: "name_Icontains",
        },
      },
      {
        key: "description",
        header: t("Description"),
        accessor: row => {
          const des = row?.description?.split("\\n") || [];

          return (
            <Box sx={{ width: "250px" }}>
              <Tooltip title={row?.description ?? ""}>
                <span>
                  {des?.map(item => (
                    <Typography noWrap key={item} component={"div"}>
                      {item}
                    </Typography>
                  ))}
                </span>
              </Tooltip>
            </Box>
          );
        },
        isHidden: false,
      },
      {
        key: "nationalId_Icontains",
        header: t("National Id"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "nationalId_Icontains",
        },
      },
      {
        key: "ownerName_Icontains",
        header: t("Owner Name"),
        showOnlyForFilterField: true,
        filter: {
          type: "string",
          name: "ownerName_Icontains",
        },
      },
      {
        key: "deliveryPrice",
        header: t("Delivery Price"),
        accessor: "deliveryPrice",
      },
      {
        key: "created",
        header: t("Created"),
        accessor: row => <TruncateTypography text={format(row?.created, "DD MMM YYYY, h:mm A")} />,
        isHidden: false,
        isSortable: true,
        sortDirection: "DESC",
        sortColumnEnum: VendorOrderField.Created,
      },
      {
        key: "address",
        header: t("Address"),
        accessor: row => {
          return <AddressInformation address={row?.address} />;
        },
        isHidden: false,
      },
      {
        key: "branches",
        header: t("Branches"),
        accessor: row => {
          return <BranchesInfo id={row?.id} />;
        },
        isHidden: false,
      },
      {
        key: "users",
        header: t("Users"),
        accessor: row => {
          return <UsersList id={row?.id} provider={row} />;
        },
        isHidden: false,
      },
      {
        key: "bankingInfo",
        header: t("Banking Info"),
        accessor: row => {
          return (
            <BankingInfo
              iban={row?.bankInfo?.iban || ""}
              accountName={row?.bankInfo?.accountName || ""}
              accountNumber={row?.bankInfo?.accountNumber || ""}
              bankName={row?.bankInfo?.bankName || ""}
            />
          );
        },
        isHidden: false,
      },
      {
        key: "workingHours",
        header: t("Working Hours"),
        accessor: row => {
          return <WorkingHours id={row.id} />;
        },
        isHidden: false,
      },
      {
        key: "ownerInfo",
        header: t("Owner Info"),
        accessor: row => {
          return <OwnerInfo ownerName={row?.ownerName} nationalId={row?.nationalId} />;
        },
        isHidden: false,
      },
      {
        key: "type",
        header: t("Type"),
        accessor: ({ type }) => t(getVendorType(type)),
      },
      {
        key: "isActive",
        header: t("Is Active"),
        accessor: row => <IsActiveToggleButton id={row?.id} isActive={row?.isActive} />,
        filter: getAutocompleteEnumFilter("YesNo", "isActive"),
      },
      {
        key: "terminate",
        header: t("Terminate"),
        accessor: row => row.approved && <TerminateVendor id={row?.id} isActive={row?.isActive} />,
        isHidden: false,
        disableToggleVisibility: false,
        hideFromPreferencesAndTable: true,
      },
      {
        key: "supportOutpatientJourney",
        header: t("Supports Out Patient Journey"),
        accessor: ({ supportOutpatientJourney, id }) => (
          <SupportOutpatientJourney id={id} supportOutpatientJourney={supportOutpatientJourney} />
        ),
      },
      {
        key: "vendorTypeFilter",
        header: t("Vendor Type"),
        showOnlyForFilterField: true,
        filter: getAutocompleteEnumFilter("VendorType", "type"),
      },
    ];
  }, []);
};
