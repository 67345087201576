import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const OptimaRemittanceGetDocument = gql `
    query OptimaRemittanceGet($optimaRemittanceId: ID!) {
  optimaRemittance(id: $optimaRemittanceId) {
    id
    dateSettlement
    status
    source
    paymentReference
  }
}
    `;
/**
 * __useOptimaRemittanceGetQuery__
 *
 * To run a query within a React component, call `useOptimaRemittanceGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useOptimaRemittanceGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOptimaRemittanceGetQuery({
 *   variables: {
 *      optimaRemittanceId: // value for 'optimaRemittanceId'
 *   },
 * });
 */
export function useOptimaRemittanceGetQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useQuery(OptimaRemittanceGetDocument, options);
}
export function useOptimaRemittanceGetLazyQuery(baseOptions) {
    const options = Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useLazyQuery(OptimaRemittanceGetDocument, options);
}
export function useOptimaRemittanceGetSuspenseQuery(baseOptions) {
    const options = baseOptions === Apollo.skipToken ? baseOptions : Object.assign(Object.assign({}, defaultOptions), baseOptions);
    return Apollo.useSuspenseQuery(OptimaRemittanceGetDocument, options);
}
