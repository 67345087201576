import { OrderDirection, PayerOrderField } from "@/schema/types";
import { GridProvider } from "@health/domains";
import { FilterGrid, MainListActions, PageWrapper, TableGrid, TableSettingComponent } from "@toolkit/ui";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { payersPaths } from "../../constants";
import { usePayerListQuery } from "../../gql";
import { useSetPayersBreadcrumbs } from "../../hooks";
import { IPayerListNode } from "../../types";
import { usePayerListContainerColumns } from "./usePayerListContainerColumns";

export const PayerListContainer: FC = () => {
  const navigate = useNavigate();

  const onAddNewItemClick = () => {
    navigate(payersPaths.create.fullPath);
  };

  const onEditRowClick = (item: IPayerListNode) => {
    navigate(payersPaths.update.fullPathWithParams({ payerId: item?.id }));
  };

  useSetPayersBreadcrumbs("LIST");

  return (
    <GridProvider
      gridName={"payerList"}
      query={usePayerListQuery}
      columns={usePayerListContainerColumns()}
      hasTableSetting
      tableAction={{
        isEditVisible: true,
        onEditRow: onEditRowClick,
      }}
      variables={{
        sortBy: {
          field: PayerOrderField.Created,
          direction: OrderDirection.Desc,
        },
      }}
    >
      <PageWrapper
        filters={<FilterGrid />}
        actions={
          <>
            <TableSettingComponent />
            <MainListActions hasAddNew onAddNewItem={onAddNewItemClick} />
          </>
        }
      >
        <TableGrid />
      </PageWrapper>
    </GridProvider>
  );
};
