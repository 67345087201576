import { i18n } from "@toolkit/i18n";
import { VisitCareType } from "../../schema/types";
export const visitCareTypeOptionsMap = {
    [VisitCareType.HomeVisit]: {
        key: VisitCareType.HomeVisit,
        get label() {
            return i18n.t("Home Visit", { ns: "domains" });
        },
        value: VisitCareType.HomeVisit,
    },
    [VisitCareType.InPerson]: {
        key: VisitCareType.InPerson,
        get label() {
            return i18n.t("In Person", { ns: "domains" });
        },
        value: VisitCareType.InPerson,
    },
    [VisitCareType.Virtual]: {
        key: VisitCareType.Virtual,
        get label() {
            return i18n.t("Virtual", { ns: "domains" });
        },
        value: VisitCareType.Virtual,
    },
};
export const visitCareTypeOptions = Object.values(visitCareTypeOptionsMap);
