import { jsx as _jsx } from "react/jsx-runtime";
import { formatGraphQLError, formatMessageErrors } from "@toolkit/apollo";
import { useTranslation } from "@toolkit/i18n";
import { Button, useAddToast } from "@toolkit/ui";
import { useState } from "react";
import { useAuth } from "react-oidc-context";
import { useParams, useSearchParams } from "react-router-dom";
import { domainsConfig } from "../../domainsConfig";
import { useUploadBulkFileMutation } from "./gql";
export const FileUpload = props => {
    const { file, templateCode, onToggle, onSuccessUpload, onResetUploadFile } = props;
    const [isSending, setIsSending] = useState(false);
    const { t } = useTranslation("domains");
    const { user } = useAuth();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const memberListId = searchParams.get("memberListId");
    const { succeeded, failed } = useAddToast();
    console.log({ memberListId });
    const [uploadBulkFile] = useUploadBulkFileMutation({
        onCompleted: data => {
            var _a, _b, _c;
            if ((_b = (_a = data === null || data === void 0 ? void 0 : data.uploadBulkFile) === null || _a === void 0 ? void 0 : _a.errors) === null || _b === void 0 ? void 0 : _b.length) {
                failed(formatMessageErrors((_c = data === null || data === void 0 ? void 0 : data.uploadBulkFile) === null || _c === void 0 ? void 0 : _c.errors));
            }
            else {
                succeeded(t("Upload File Successfully"));
                onToggle();
                onResetUploadFile();
                setTimeout(() => {
                    onSuccessUpload();
                }, 10000);
            }
        },
        onError: ({ graphQLErrors }) => {
            failed(formatGraphQLError(graphQLErrors));
        },
    });
    const handleUploadBulkFile = (fileName) => {
        uploadBulkFile({
            variables: {
                code: templateCode,
                fileName,
                entityId: (params === null || params === void 0 ? void 0 : params.id) || memberListId || "",
            },
        });
    };
    const handleSendFile = () => {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${user === null || user === void 0 ? void 0 : user.access_token}`);
        const formData = new FormData();
        if (file) {
            formData.append("file", file);
            setIsSending(true);
            const fileServiceApiUrl = domainsConfig.get("fileServiceApiUrl");
            const baseURL = `${fileServiceApiUrl}/bulk/upload/${templateCode}`;
            fetch(baseURL, {
                method: "POST",
                headers: myHeaders,
                body: formData,
                redirect: "follow",
            })
                .then(result => result.json())
                .then(fileName => {
                if (fileName === null || fileName === void 0 ? void 0 : fileName.error) {
                    failed(fileName === null || fileName === void 0 ? void 0 : fileName.error);
                    onResetUploadFile();
                    onToggle();
                }
                else {
                    handleUploadBulkFile(fileName === null || fileName === void 0 ? void 0 : fileName.fileName);
                }
            })
                .catch(error => {
                failed(error);
                console.error({ error });
            })
                .finally(() => {
                setIsSending(false);
            });
        }
    };
    return (_jsx(Button, { sx: { margin: "10px" }, onClick: handleSendFile, disabled: isSending, children: t("Submit") }));
};
