export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** An RFC-3339 compliant Full Date Scalar */
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Decimal: { input: any; output: any; }
  DictType: { input: any; output: any; }
  Double: { input: any; output: any; }
  H_Long: { input: any; output: any; }
  Instant: { input: any; output: any; }
  InstantNoTime: { input: any; output: any; }
  InstantNotFuture: { input: any; output: any; }
  JSONString: { input: any; output: any; }
  LocalDateTime: { input: any; output: any; }
  /** 24-hour clock time value string in the format `hh:mm:ss` or `hh:mm:ss.sss`. */
  LocalTime: { input: any; output: any; }
  P_Date: { input: any; output: any; }
  Time: { input: any; output: any; }
  /** A custom scalar that represents files */
  Upload: { input: any; output: any; }
};

export type AccountAddressCreate = {
  __typename?: 'AccountAddressCreate';
  accountErrors: Array<AccountError>;
  address?: Maybe<Address>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type AccountAddressDelete = {
  __typename?: 'AccountAddressDelete';
  accountErrors: Array<AccountError>;
  address?: Maybe<Address>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type AccountAddressUpdate = {
  __typename?: 'AccountAddressUpdate';
  accountErrors: Array<AccountError>;
  address?: Maybe<Address>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type AccountAvatarDelete = {
  __typename?: 'AccountAvatarDelete';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type AccountAvatarUpdate = {
  __typename?: 'AccountAvatarUpdate';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type AccountError = {
  __typename?: 'AccountError';
  code: AccountErrorCode;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum AccountErrorCode {
  ActivateOwnAccount = 'ACTIVATE_OWN_ACCOUNT',
  ActivateSuperuserAccount = 'ACTIVATE_SUPERUSER_ACCOUNT',
  DeactivateOwnAccount = 'DEACTIVATE_OWN_ACCOUNT',
  DeactivateSuperuserAccount = 'DEACTIVATE_SUPERUSER_ACCOUNT',
  DeleteConsumerAccount = 'DELETE_CONSUMER_ACCOUNT',
  DeleteNonStaffUser = 'DELETE_NON_STAFF_USER',
  DeleteOwnAccount = 'DELETE_OWN_ACCOUNT',
  DeleteStaffAccount = 'DELETE_STAFF_ACCOUNT',
  DeleteSuperuserAccount = 'DELETE_SUPERUSER_ACCOUNT',
  DeleteSupervendorAccount = 'DELETE_SUPERVENDOR_ACCOUNT',
  DuplicatedInputItem = 'DUPLICATED_INPUT_ITEM',
  GraphqlError = 'GRAPHQL_ERROR',
  IntegrationTokenError = 'INTEGRATION_TOKEN_ERROR',
  Invalid = 'INVALID',
  InvalidCredentials = 'INVALID_CREDENTIALS',
  InvalidPassword = 'INVALID_PASSWORD',
  LeftNotManageablePermission = 'LEFT_NOT_MANAGEABLE_PERMISSION',
  NotFound = 'NOT_FOUND',
  OutOfScopeGroup = 'OUT_OF_SCOPE_GROUP',
  OutOfScopePermission = 'OUT_OF_SCOPE_PERMISSION',
  OutOfScopeServiceAccount = 'OUT_OF_SCOPE_SERVICE_ACCOUNT',
  OutOfScopeUser = 'OUT_OF_SCOPE_USER',
  PasswordEntirelyNumeric = 'PASSWORD_ENTIRELY_NUMERIC',
  PasswordTooCommon = 'PASSWORD_TOO_COMMON',
  PasswordTooShort = 'PASSWORD_TOO_SHORT',
  PasswordTooSimilar = 'PASSWORD_TOO_SIMILAR',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type AccountSetDefaultAddress = {
  __typename?: 'AccountSetDefaultAddress';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type Action = {
  __typename?: 'Action';
  actionFields?: Maybe<Array<Maybe<ActionField>>>;
  errors?: Maybe<Array<Maybe<DecisionMakerGraphqlError>>>;
};

export type ActionDefinitionField = {
  __typename?: 'ActionDefinitionField';
  allowedValues?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  code?: Maybe<Scalars['String']['output']>;
  display?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<ActionFieldType>;
};

export type ActionField = {
  __typename?: 'ActionField';
  code?: Maybe<Scalars['String']['output']>;
  extra?: Maybe<Scalars['String']['output']>;
  type: ActionFieldType;
  valueBoolean?: Maybe<Scalars['Boolean']['output']>;
  valueDate?: Maybe<Scalars['Date']['output']>;
  valueList?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  valueNumber?: Maybe<Scalars['Float']['output']>;
  valueString?: Maybe<Scalars['String']['output']>;
};

export type ActionFieldInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  extra?: InputMaybe<Scalars['String']['input']>;
  type: ActionFieldType;
  valueBoolean?: InputMaybe<Scalars['Boolean']['input']>;
  valueDate?: InputMaybe<Scalars['Date']['input']>;
  valueList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  valueNumber?: InputMaybe<Scalars['Float']['input']>;
  valueString?: InputMaybe<Scalars['String']['input']>;
};

export enum ActionFieldType {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  List = 'LIST',
  Number = 'NUMBER',
  String = 'STRING'
}

export type ActivateInterventionInput = {
  guidedCareJourneyId: Scalars['ID']['input'];
  interventionActivationType: InterventionActivationType;
  startDate?: InputMaybe<Scalars['Instant']['input']>;
  templateInterventionActivityItemsIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  templateInterventionId: Scalars['ID']['input'];
};

export type ActivityGuidedCareProgramTeamMemberInput = {
  uniqueId?: InputMaybe<Scalars['String']['input']>;
};

export enum ActivityLevel {
  HeavyActive = 'HEAVY_ACTIVE',
  LightlyActive = 'LIGHTLY_ACTIVE',
  ModeratelyActive = 'MODERATELY_ACTIVE',
  Sedentary = 'SEDENTARY',
  VeryHeavyActive = 'VERY_HEAVY_ACTIVE'
}

export type ActivityTemplateTeamMemberInput = {
  uniqueId: Scalars['String']['input'];
};

export enum ActivityType {
  GenericDrug = 'GENERIC_DRUG',
  TradeDrug = 'TRADE_DRUG'
}

export enum Actor {
  Doctor = 'DOCTOR',
  Patient = 'PATIENT'
}

export type AddDiagnosisToVisitInput = {
  code: Scalars['String']['input'];
  visitDiagnosisType: VisitDiagnosisType;
  visitId: Scalars['ID']['input'];
};

export type AddMemberToProgramInput = {
  guidedCareProgramId: Scalars['ID']['input'];
  memberId?: InputMaybe<Scalars['ID']['input']>;
  visitId?: InputMaybe<Scalars['ID']['input']>;
};

export type AddSnapshotToMeetingInput = {
  meetingId: Scalars['ID']['input'];
  snapshot: Scalars['String']['input'];
};

export type AddVendorToFavorite = {
  __typename?: 'AddVendorToFavorite';
  customerFavoriteVendor?: Maybe<CustomerFavoriteVendor>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendorErrors: Array<VendorError>;
};

export type Address = Node & {
  __typename?: 'Address';
  area: Scalars['String']['output'];
  areaAr: Scalars['String']['output'];
  buildingName?: Maybe<Scalars['String']['output']>;
  buildingNameAr?: Maybe<Scalars['String']['output']>;
  buildingNumber?: Maybe<Scalars['String']['output']>;
  city: City;
  coordinates?: Maybe<LocationType>;
  district: Scalars['String']['output'];
  districtAr: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isDefaultBillingAddress?: Maybe<Scalars['Boolean']['output']>;
  isDefaultShippingAddress?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameAr?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postalCode: Scalars['String']['output'];
  region: Scalars['String']['output'];
  regionAr: Scalars['String']['output'];
  streetAddress1: Scalars['String']['output'];
  streetAddress1Ar: Scalars['String']['output'];
  streetAddress2: Scalars['String']['output'];
  streetAddress2Ar: Scalars['String']['output'];
  unitNumber?: Maybe<Scalars['String']['output']>;
};

export type AddressCountableConnection = {
  __typename?: 'AddressCountableConnection';
  edges: Array<AddressCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type AddressCountableEdge = {
  __typename?: 'AddressCountableEdge';
  cursor: Scalars['String']['output'];
  node: Address;
};

export type AddressInput = {
  area?: InputMaybe<Scalars['String']['input']>;
  areaAr?: InputMaybe<Scalars['String']['input']>;
  buildingName?: InputMaybe<Scalars['String']['input']>;
  buildingNameAr?: InputMaybe<Scalars['String']['input']>;
  buildingNumber?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['ID']['input']>;
  coordinates: LocationInput;
  district?: InputMaybe<Scalars['String']['input']>;
  districtAr?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameAr?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  regionAr?: InputMaybe<Scalars['String']['input']>;
  streetAddress1?: InputMaybe<Scalars['String']['input']>;
  streetAddress1Ar?: InputMaybe<Scalars['String']['input']>;
  streetAddress2?: InputMaybe<Scalars['String']['input']>;
  streetAddress2Ar?: InputMaybe<Scalars['String']['input']>;
  translations?: InputMaybe<Array<InputMaybe<AddressTranslationInput>>>;
  unitNumber?: InputMaybe<Scalars['String']['input']>;
};

export type AddressTranslationInput = {
  area?: InputMaybe<Scalars['String']['input']>;
  areaAr?: InputMaybe<Scalars['String']['input']>;
  buildingName?: InputMaybe<Scalars['String']['input']>;
  buildingNameAr?: InputMaybe<Scalars['String']['input']>;
  district?: InputMaybe<Scalars['String']['input']>;
  districtAr?: InputMaybe<Scalars['String']['input']>;
  languageCode: LanguageCodeEnum;
  name?: InputMaybe<Scalars['String']['input']>;
  nameAr?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<Scalars['String']['input']>;
  regionAr?: InputMaybe<Scalars['String']['input']>;
  streetAddress1?: InputMaybe<Scalars['String']['input']>;
  streetAddress1Ar?: InputMaybe<Scalars['String']['input']>;
  streetAddress2?: InputMaybe<Scalars['String']['input']>;
  streetAddress2Ar?: InputMaybe<Scalars['String']['input']>;
};

export enum AddressTypeEnum {
  Billing = 'BILLING',
  Shipping = 'SHIPPING'
}

export type AdminMedicalFormInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  category: MedicalFormCategory;
  code: Scalars['String']['input'];
  departmentId?: InputMaybe<Scalars['ID']['input']>;
  description: Scalars['String']['input'];
  medicalFormClassifications?: InputMaybe<Array<MedicalFormClassificationInput>>;
  name: Scalars['String']['input'];
  nameAr: Scalars['String']['input'];
  questions?: InputMaybe<Array<InputMaybe<QuestionInput>>>;
  type: MedicalFormType;
  welcomeCard?: InputMaybe<Scalars['String']['input']>;
  welcomeCardAr?: InputMaybe<Scalars['String']['input']>;
};

export type AdminPromotion = {
  __typename?: 'AdminPromotion';
  content?: Maybe<Scalars['String']['output']>;
  contentAr?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionAr?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['Instant']['output']>;
  id: Scalars['ID']['output'];
  isPublished: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  nameAr?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['Instant']['output']>;
};

export type AdminPromotionConnection = {
  __typename?: 'AdminPromotionConnection';
  edges: Array<AdminPromotionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type AdminPromotionEdge = {
  __typename?: 'AdminPromotionEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: AdminPromotion;
};

export type AdminPromotionFilterInput = {
  endDate?: InputMaybe<Scalars['Instant']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameAr?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Instant']['input']>;
};

export type AdminPromotionInput = {
  content: Scalars['String']['input'];
  contentAr: Scalars['String']['input'];
  description: Scalars['String']['input'];
  descriptionAr: Scalars['String']['input'];
  endDate: Scalars['Instant']['input'];
  name: Scalars['String']['input'];
  nameAr: Scalars['String']['input'];
  photo: Scalars['String']['input'];
  startDate: Scalars['Instant']['input'];
};

export enum AdminPromotionSortingField {
  Created = 'CREATED',
  EndDate = 'END_DATE',
  Name = 'NAME',
  NameAr = 'NAME_AR',
  Published = 'PUBLISHED',
  StartDate = 'START_DATE'
}

export type AdminPromotionSortingInput = {
  direction?: InputMaybe<MarketplaceSortDirection>;
  field?: InputMaybe<AdminPromotionSortingField>;
};

export type AdminUserCreateInput = {
  addGroups?: InputMaybe<Array<Scalars['ID']['input']>>;
  address?: InputMaybe<AddressInput>;
  appRole: AppRoleTypes;
  appType: AppTypes;
  branches?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  defaultBranch?: InputMaybe<Scalars['ID']['input']>;
  dentalHygienistInfo?: InputMaybe<DentalHygienistInput>;
  departments?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  diabetesEducatorInfo?: InputMaybe<DiabetesEducatorInput>;
  doctorInfo?: InputMaybe<DoctorInput>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  fitnessCoachInfo?: InputMaybe<FitnessCoachInput>;
  gender?: InputMaybe<PersonGenderEnum>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  nationalId?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  nurseInfo?: InputMaybe<NurseInput>;
  nutritionistInfo?: InputMaybe<NutritionistInput>;
  optometristInfo?: InputMaybe<OptometristInput>;
  parentUser?: InputMaybe<Scalars['ID']['input']>;
  password: Scalars['String']['input'];
  payer?: InputMaybe<Scalars['ID']['input']>;
  pharmacistInfo?: InputMaybe<PharmacistInput>;
  photo?: InputMaybe<Scalars['String']['input']>;
  podiatricMedicalAssistantInfo?: InputMaybe<PodiatricMedicalAssistantInput>;
  preferredLanguage?: InputMaybe<Scalars['String']['input']>;
  psychologistInfo?: InputMaybe<PsychologistInput>;
  relationType?: InputMaybe<RelationTypes>;
  secondName?: InputMaybe<Scalars['String']['input']>;
  socialWorkerInfo?: InputMaybe<SocialWorkerInput>;
  thirdName?: InputMaybe<Scalars['String']['input']>;
  vendor?: InputMaybe<Scalars['ID']['input']>;
  vendorUserType?: InputMaybe<VendorUserTypes>;
};

export enum AffectedType {
  Appointment = 'APPOINTMENT',
  Visit = 'VISIT',
  VisitCall = 'VISIT_CALL'
}

export type Aggregation = {
  __typename?: 'Aggregation';
  key: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export enum AmountSourceType {
  Appointment = 'APPOINTMENT',
  Doctor = 'DOCTOR',
  HealthProgram = 'HEALTH_PROGRAM',
  MarketplaceOrder = 'MARKETPLACE_ORDER',
  Order = 'ORDER',
  OrderCheckoutToken = 'ORDER_CHECKOUT_TOKEN',
  OutPatientJourney = 'OUT_PATIENT_JOURNEY',
  PrescriptionOrder = 'PRESCRIPTION_ORDER',
  Visit = 'VISIT'
}

export type AnonymousChatGroup = H_Node & {
  __typename?: 'AnonymousChatGroup';
  anonymousChatLink?: Maybe<Scalars['String']['output']>;
  anonymousChatLinkForChatView?: Maybe<Scalars['String']['output']>;
  anonymousChatLinkForJoinCall?: Maybe<Scalars['String']['output']>;
  chatId?: Maybe<Scalars['H_Long']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  failedToCreate?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['Instant']['output']>;
  participants?: Maybe<Array<Maybe<AnonymousChatGroupParticipant>>>;
  suspended?: Maybe<Scalars['Boolean']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  visit?: Maybe<Visit>;
};

export type AnonymousChatGroupCountableConnection = {
  __typename?: 'AnonymousChatGroupCountableConnection';
  edges: Array<AnonymousChatGroupCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type AnonymousChatGroupCountableEdge = {
  __typename?: 'AnonymousChatGroupCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: AnonymousChatGroup;
};

export type AnonymousChatGroupFilterInput = {
  fetchBinaryChatGroups?: InputMaybe<Scalars['Boolean']['input']>;
};

export type AnonymousChatGroupParticipant = H_Node & {
  __typename?: 'AnonymousChatGroupParticipant';
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  id: Scalars['ID']['output'];
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['Instant']['output']>;
  meetingPlatformId?: Maybe<Scalars['H_Long']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  selectedAsOwnerUser?: Maybe<Scalars['Boolean']['output']>;
  user?: Maybe<User>;
};

export enum AnxietyFrequency {
  MoreThanHalfTheDays = 'MORE_THAN_HALF_THE_DAYS',
  NearlyEveryDay = 'NEARLY_EVERY_DAY',
  NotAtAll = 'NOT_AT_ALL',
  SeveralDays = 'SEVERAL_DAYS'
}

export type AnxietyScreeningInput = {
  feelingNervous: AnxietyFrequency;
  notBeingAbleToStopOrControlWorrying: AnxietyFrequency;
};

export type AnxietyScreeningInputLog = {
  __typename?: 'AnxietyScreeningInputLog';
  feelingNervous: AnxietyFrequency;
  notBeingAbleToStopOrControlWorrying: AnxietyFrequency;
};

export type AnxietyScreeningResponse = {
  __typename?: 'AnxietyScreeningResponse';
  riskLevel?: Maybe<RiskLevel>;
  score?: Maybe<Scalars['Int']['output']>;
};

export type AnxietyScreeningResult = {
  __typename?: 'AnxietyScreeningResult';
  createdDate?: Maybe<Scalars['Instant']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  queryInput?: Maybe<AnxietyScreeningInputLog>;
  riskLevel?: Maybe<RiskLevel>;
  score?: Maybe<Scalars['Int']['output']>;
};

export type AnxietyScreeningResultConnection = {
  __typename?: 'AnxietyScreeningResultConnection';
  edges: Array<AnxietyScreeningResultEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type AnxietyScreeningResultEdge = {
  __typename?: 'AnxietyScreeningResultEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: AnxietyScreeningResult;
};

export enum AppRoleTypes {
  Admin = 'ADMIN',
  User = 'USER'
}

export enum AppTypes {
  Admin = 'ADMIN',
  Aggregator = 'AGGREGATOR',
  Customer = 'CUSTOMER',
  Payer = 'PAYER',
  Vendor = 'VENDOR'
}

export type AppendTranscriptInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  done?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  meetingId: Scalars['ID']['input'];
  msgId?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  timestamp?: InputMaybe<Scalars['Instant']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type Appointment = H_Node & {
  __typename?: 'Appointment';
  appointmentCategory?: Maybe<AppointmentCategory>;
  appointmentCreationSource?: Maybe<AppointmentCreationSource>;
  appointmentServiceDetails?: Maybe<Array<Maybe<ServiceDetails>>>;
  branch?: Maybe<Branch>;
  callId?: Maybe<Scalars['H_Long']['output']>;
  cancellationReason?: Maybe<Scalars['String']['output']>;
  careInitialType?: Maybe<CareType>;
  chatId?: Maybe<Scalars['H_Long']['output']>;
  confirmedEndTime?: Maybe<Scalars['Instant']['output']>;
  confirmedStartTime?: Maybe<Scalars['Instant']['output']>;
  consumer?: Maybe<Patient>;
  consumerUser?: Maybe<User>;
  created?: Maybe<Scalars['Instant']['output']>;
  createdByUser?: Maybe<User>;
  department?: Maybe<Department>;
  doctor?: Maybe<Doctor>;
  doctorNote?: Maybe<Scalars['String']['output']>;
  doctorUser?: Maybe<User>;
  healthProgram?: Maybe<HealthProgram>;
  healthProgramMember?: Maybe<HealthProgramMember>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  justification?: Maybe<Scalars['String']['output']>;
  lastModifiedByUser?: Maybe<User>;
  messageToPatient?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['Instant']['output']>;
  paymentInfo?: Maybe<AppointmentPaymentRequest>;
  paymentOperationLogs: PaymentOperationLogCountableConnection;
  paymentReceipts: PaymentReceiptCountableConnection;
  paymentStatus?: Maybe<PaymentStatus>;
  paymentStatusFromPaymentLogs?: Maybe<PaymentStatus>;
  providerGuidedCareHealthProgramTeam?: Maybe<ProviderGuidedCareHealthProgramTeam>;
  rejectionReason?: Maybe<Scalars['String']['output']>;
  requestedBy?: Maybe<RequestedBy>;
  requestedTimeSlots?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  specialty?: Maybe<T_Specialization>;
  status?: Maybe<AppointmentStatus>;
  subscription?: Maybe<HealthProgramUserSubscription>;
  suggestedTimeSlots?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  type?: Maybe<AppointmentType>;
  vendor?: Maybe<Vendor>;
  visit?: Maybe<Visit>;
};


export type AppointmentPaymentOperationLogsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PaymentOperationLogFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type AppointmentPaymentReceiptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PaymentReceiptFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** Appointment Approval Action */
export type AppointmentApprovalAction = {
  __typename?: 'AppointmentApprovalAction';
  appointment?: Maybe<Appointment>;
  appointmentErrors?: Maybe<Array<H_EntityError>>;
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
};

export enum AppointmentCategory {
  Consultation = 'CONSULTATION',
  Lab = 'LAB',
  Rad = 'RAD'
}

export type AppointmentChatLinkInfo = {
  __typename?: 'AppointmentChatLinkInfo';
  appointmentId: Scalars['ID']['output'];
  chatLinkForChatView?: Maybe<Scalars['String']['output']>;
  chatLinkForJoinCall?: Maybe<Scalars['String']['output']>;
};

export type AppointmentCountableConnection = {
  __typename?: 'AppointmentCountableConnection';
  edges: Array<AppointmentCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type AppointmentCountableEdge = {
  __typename?: 'AppointmentCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Appointment;
};

export enum AppointmentCreationSource {
  Automatic = 'AUTOMATIC',
  Other = 'OTHER',
  Personalized = 'PERSONALIZED'
}

export type AppointmentFilterInput = {
  branches?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  confirmedEndTime?: InputMaybe<H_DateTimeRangeInput>;
  confirmedStartTime?: InputMaybe<H_DateTimeRangeInput>;
  consumerUserId?: InputMaybe<Scalars['Int']['input']>;
  departments?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  doctors?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  hasOutPatientJourney?: InputMaybe<Scalars['Boolean']['input']>;
  patients?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  providerGuidedCareHealthProgramTeams?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  requestedBy?: InputMaybe<RequestedBy>;
  requestedEndTime?: InputMaybe<H_DateTimeRangeInput>;
  requestedStartTime?: InputMaybe<H_DateTimeRangeInput>;
  selfOnly?: InputMaybe<Scalars['Boolean']['input']>;
  selfProviderGuidedCareHealthProgramTeamsOnly?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<InputMaybe<AppointmentStatus>>>;
  type?: InputMaybe<Array<InputMaybe<AppointmentType>>>;
  upcoming?: InputMaybe<Scalars['Boolean']['input']>;
  vendors?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export enum AppointmentFrequencyType {
  Day = 'Day',
  Month = 'Month',
  Week = 'Week',
  Year = 'Year'
}

export type AppointmentNotificationSetting = H_Node & {
  __typename?: 'AppointmentNotificationSetting';
  arabicText?: Maybe<Scalars['String']['output']>;
  channels?: Maybe<Array<Maybe<NotificationChannel>>>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  englishText?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['Instant']['output']>;
  sendTimeInMinutes?: Maybe<Scalars['Int']['output']>;
  types?: Maybe<Array<Maybe<AppointmentType>>>;
  vendor?: Maybe<Vendor>;
};

export type AppointmentNotificationSettingCountableConnection = {
  __typename?: 'AppointmentNotificationSettingCountableConnection';
  edges: Array<AppointmentNotificationSettingCountableEdge>;
  pageInfo: H_PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type AppointmentNotificationSettingCountableEdge = {
  __typename?: 'AppointmentNotificationSettingCountableEdge';
  cursor: Scalars['String']['output'];
  node: AppointmentNotificationSetting;
};

export type AppointmentNotificationSettingFilterInput = {
  channels?: InputMaybe<Array<InputMaybe<NotificationChannel>>>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  sendTimeInMinutes?: InputMaybe<H_IntRangeInput>;
  types?: InputMaybe<Array<InputMaybe<AppointmentType>>>;
  vendors?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type AppointmentNotificationSettingInput = {
  arabicText: Scalars['String']['input'];
  channels: Array<NotificationChannel>;
  englishText: Scalars['String']['input'];
  sendTimeInMinutes: Scalars['Int']['input'];
  types: Array<AppointmentType>;
};

export enum AppointmentNotificationType {
  Confirmation = 'CONFIRMATION',
  Reminder = 'REMINDER'
}

export type AppointmentNotifications = {
  __typename?: 'AppointmentNotifications';
  appNotification?: Maybe<SubscriptionEntityChangedForAppointmentNotification>;
  emailNotifications?: Maybe<Array<Maybe<InternalEmailNotificationConverted>>>;
  smsNotifications?: Maybe<Array<Maybe<InternalSmsNotificationConverted>>>;
};

export type AppointmentPaymentRequest = H_Node & {
  __typename?: 'AppointmentPaymentRequest';
  appointment?: Maybe<Appointment>;
  currency?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  priceAmountAfterVat?: Maybe<Scalars['Float']['output']>;
  totalAmount?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<AppointmentPaymentType>;
  vatPercentage?: Maybe<Scalars['Float']['output']>;
};

export type AppointmentPaymentRequestForAcceptInput = {
  integrationPatientShare?: InputMaybe<Scalars['Float']['input']>;
};

export type AppointmentPaymentRequestForRescheduleInput = {
  totalAmount?: InputMaybe<Scalars['Float']['input']>;
};

export enum AppointmentPaymentType {
  CashOnly = 'CASH_ONLY',
  InsuranceCoverage = 'INSURANCE_COVERAGE',
  PrePaid = 'PRE_PAID'
}

export enum AppointmentSortingField {
  /** Sort appointment by confirmed start time. */
  ConfirmedStartDate = 'CONFIRMED_START_DATE',
  /** Sort appointments by creation date. */
  Created = 'CREATED',
  /** Sort appointments by last modification date. */
  LastModifiedDate = 'LAST_MODIFIED_DATE'
}

export type AppointmentSortingInput = {
  /** Specifies the direction in which to sort products. */
  direction: H_OrderDirection;
  /** Sort appointments by the selected field. */
  field?: InputMaybe<AppointmentSortingField>;
};

export enum AppointmentStatus {
  Approved = 'APPROVED',
  CallInProgress = 'CALL_IN_PROGRESS',
  CanceledByConsumer = 'CANCELED_BY_CONSUMER',
  CanceledByDoctor = 'CANCELED_BY_DOCTOR',
  Cancelled = 'CANCELLED',
  ChatInProgress = 'CHAT_IN_PROGRESS',
  ComingSoon = 'COMING_SOON',
  Confirmed = 'CONFIRMED',
  FinishedSuccessfully = 'FINISHED_SUCCESSFULLY',
  OutPatientJourneyAssessmentDone = 'OUT_PATIENT_JOURNEY_ASSESSMENT_DONE',
  OutPatientJourneyAssessmentPending = 'OUT_PATIENT_JOURNEY_ASSESSMENT_PENDING',
  OutPatientJourneyCheckedIn = 'OUT_PATIENT_JOURNEY_CHECKED_IN',
  OutPatientJourneyConsultationDone = 'OUT_PATIENT_JOURNEY_CONSULTATION_DONE',
  OutPatientJourneyConsultationPending = 'OUT_PATIENT_JOURNEY_CONSULTATION_PENDING',
  OutPatientJourneyPending = 'OUT_PATIENT_JOURNEY_PENDING',
  OutPatientJourneyTreatmentDone = 'OUT_PATIENT_JOURNEY_TREATMENT_DONE',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentPending = 'PAYMENT_PENDING',
  PaymentSucceeded = 'PAYMENT_SUCCEEDED',
  PendingApproval = 'PENDING_APPROVAL',
  Rejected = 'REJECTED',
  Request = 'REQUEST',
  RescheduledByConsumer = 'RESCHEDULED_BY_CONSUMER',
  RescheduledByDoctor = 'RESCHEDULED_BY_DOCTOR',
  Tentative = 'TENTATIVE'
}

export type AppointmentStatusHistory = H_Node & {
  __typename?: 'AppointmentStatusHistory';
  callId?: Maybe<Scalars['H_Long']['output']>;
  cancellationReason?: Maybe<Scalars['String']['output']>;
  chatId?: Maybe<Scalars['H_Long']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  doctorId?: Maybe<Scalars['Int']['output']>;
  doctorUserId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['Instant']['output']>;
  meetingPlatformLink?: Maybe<Scalars['String']['output']>;
  meetingPlatformLinkForChatView?: Maybe<Scalars['String']['output']>;
  meetingPlatformLinkForJoinCall?: Maybe<Scalars['String']['output']>;
  patientId?: Maybe<Scalars['Int']['output']>;
  patientUserId?: Maybe<Scalars['Int']['output']>;
  refId?: Maybe<Scalars['H_Long']['output']>;
  status?: Maybe<AppointmentStatus>;
  vendorId?: Maybe<Scalars['Int']['output']>;
};

export type AppointmentStatusHistoryCountableConnection = {
  __typename?: 'AppointmentStatusHistoryCountableConnection';
  edges: Array<AppointmentStatusHistoryCountableEdge>;
  pageInfo: H_PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type AppointmentStatusHistoryCountableEdge = {
  __typename?: 'AppointmentStatusHistoryCountableEdge';
  cursor: Scalars['String']['output'];
  node: AppointmentStatusHistory;
};

export type AppointmentStatusHistoryFilterInput = {
  doctors?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  patients?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  refIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  statuses?: InputMaybe<Array<InputMaybe<AppointmentStatus>>>;
  vendors?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/** Appointment status update */
export type AppointmentStatusUpdate = {
  __typename?: 'AppointmentStatusUpdate';
  appointment?: Maybe<Appointment>;
  appointmentErrors?: Maybe<Array<H_EntityError>>;
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
};

export type AppointmentStatusUpdateInput = {
  callId?: InputMaybe<Scalars['H_Long']['input']>;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  chatId?: InputMaybe<Scalars['H_Long']['input']>;
  meetingPlatformLink?: InputMaybe<Scalars['String']['input']>;
  status: AppointmentStatus;
};

export type AppointmentTimeSlotInput = {
  endTime: Scalars['Instant']['input'];
  startTime: Scalars['Instant']['input'];
};

export enum AppointmentType {
  AtHome = 'AT_HOME',
  Diagnostic = 'DIAGNOSTIC',
  Online = 'ONLINE',
  Onsite = 'ONSITE'
}

export enum AppointmentTypeEnum {
  AtHome = 'AT_HOME',
  Diagnostic = 'DIAGNOSTIC',
  Online = 'ONLINE',
  Onsite = 'ONSITE'
}

export type ApproveTermsAndConditions = {
  __typename?: 'ApproveTermsAndConditions';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type ApproveVendor = {
  __typename?: 'ApproveVendor';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type Article = {
  __typename?: 'Article';
  active?: Maybe<Scalars['Boolean']['output']>;
  category?: Maybe<ArticleCategory>;
  content?: Maybe<Scalars['String']['output']>;
  contentAr?: Maybe<Scalars['String']['output']>;
  contentImages?: Maybe<Array<Maybe<ArticleContentImage>>>;
  createdDate: Scalars['Instant']['output'];
  description?: Maybe<Scalars['String']['output']>;
  descriptionAr?: Maybe<Scalars['String']['output']>;
  headerImage?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isRead?: Maybe<Scalars['Boolean']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  readCounter?: Maybe<Scalars['Int']['output']>;
  readTimeMinutes?: Maybe<Scalars['Int']['output']>;
  readers?: Maybe<Array<Maybe<User>>>;
  references?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  relatedArticles?: Maybe<Array<Maybe<Article>>>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  title?: Maybe<Scalars['String']['output']>;
  titleAr?: Maybe<Scalars['String']['output']>;
};

export type ArticleCategory = {
  __typename?: 'ArticleCategory';
  articles?: Maybe<ArticleCategoryCountableConnection>;
  children?: Maybe<Array<Maybe<ArticleCategory>>>;
  code?: Maybe<Scalars['String']['output']>;
  display?: Maybe<Scalars['String']['output']>;
  displayAr?: Maybe<Scalars['String']['output']>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  parent?: Maybe<ArticleCategory>;
};


export type ArticleCategoryArticlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ArticleFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<ArticleSortingInput>;
};

export type ArticleCategoryCountableConnection = {
  __typename?: 'ArticleCategoryCountableConnection';
  edges: Array<ArticleCategoryCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
};

export type ArticleCategoryCountableEdge = {
  __typename?: 'ArticleCategoryCountableEdge';
  node: ArticleCategory;
};

export type ArticleCategoryFilterInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  display?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  type?: InputMaybe<ArticleCategoryType>;
};

export type ArticleCategoryInput = {
  code: Scalars['String']['input'];
  display: Scalars['String']['input'];
  displayAr: Scalars['String']['input'];
  icon: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

export enum ArticleCategorySortingField {
  Code = 'CODE',
  Created = 'CREATED',
  Id = 'ID',
  Modified = 'MODIFIED'
}

export type ArticleCategorySortingInput = {
  direction: OrderDirection;
  field?: InputMaybe<ArticleCategorySortingField>;
};

export enum ArticleCategoryType {
  Child = 'CHILD',
  Parent = 'PARENT'
}

export type ArticleContentImage = {
  __typename?: 'ArticleContentImage';
  alias: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type ArticleContentImageInput = {
  alias: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type ArticleCountableConnection = {
  __typename?: 'ArticleCountableConnection';
  edges: Array<ArticleCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type ArticleCountableEdge = {
  __typename?: 'ArticleCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  node: Article;
};

export type ArticleFilterInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type ArticleInput = {
  active: Scalars['Boolean']['input'];
  categoryId: Scalars['ID']['input'];
  content: Scalars['String']['input'];
  contentAr: Scalars['String']['input'];
  contentImageInputs?: InputMaybe<Array<InputMaybe<ArticleContentImageInput>>>;
  description: Scalars['String']['input'];
  descriptionAr: Scalars['String']['input'];
  headerImage?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  readTimeMinutes?: InputMaybe<Scalars['Int']['input']>;
  references?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  relatedArticles?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title: Scalars['String']['input'];
  titleAr: Scalars['String']['input'];
};

export enum ArticleSortingField {
  Created = 'CREATED',
  Id = 'ID',
  Modified = 'MODIFIED',
  Priority = 'PRIORITY',
  Title = 'TITLE'
}

export type ArticleSortingInput = {
  direction: OrderDirection;
  field?: InputMaybe<ArticleSortingField>;
};

export type AssignDefaultBranch = {
  __typename?: 'AssignDefaultBranch';
  branch?: Maybe<Branch>;
  branchErrors: Array<BranchError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type AssignUserToBranch = {
  __typename?: 'AssignUserToBranch';
  branchErrors: Array<BranchError>;
  branchUser?: Maybe<BranchUser>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type AssigneeTeamMemberInput = {
  id: Scalars['ID']['input'];
};

export enum AssigneeType {
  Patient = 'PATIENT',
  TeamMember = 'TEAM_MEMBER'
}

export type Attachment = Node & {
  __typename?: 'Attachment';
  alt: Scalars['String']['output'];
  contentType: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  file?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  messages: MessageCountableConnection;
  modified: Scalars['DateTime']['output'];
  sortOrder?: Maybe<Scalars['Int']['output']>;
};


export type AttachmentMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type AttachmentInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  contentType: Scalars['String']['input'];
  file: Scalars['String']['input'];
};

export type AuditLog = H_Node & {
  __typename?: 'AuditLog';
  auditLogDirtyFields?: Maybe<Array<Maybe<AuditLogDirtyField>>>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  entityClassSimpleName?: Maybe<Scalars['String']['output']>;
  entityId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['Instant']['output']>;
  operationType?: Maybe<AuditLogOperationType>;
};

export type AuditLogCountableConnection = {
  __typename?: 'AuditLogCountableConnection';
  edges: Array<AuditLogCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type AuditLogCountableEdge = {
  __typename?: 'AuditLogCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: AuditLog;
};

export type AuditLogDirtyField = H_Node & {
  __typename?: 'AuditLogDirtyField';
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  currentValue?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['Instant']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  previousValue?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type AuditLogFilterInput = {
  entityIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  entityNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  operationTypes?: InputMaybe<Array<InputMaybe<AuditLogOperationType>>>;
};

export enum AuditLogOperationType {
  Create = 'CREATE',
  Delete = 'DELETE',
  Update = 'UPDATE'
}

export type AuthorizationInfoInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  summary?: InputMaybe<Scalars['String']['input']>;
};

export enum AuthorizationStatus {
  Approved = 'APPROVED',
  New = 'NEW',
  PartialApproved = 'PARTIAL_APPROVED',
  Rejected = 'REJECTED'
}

export type AvailabilitiesAverage = {
  __typename?: 'AvailabilitiesAverage';
  average?: Maybe<Scalars['Float']['output']>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendorErrors: Array<VendorError>;
};

export type BeeAndTdeeInput = {
  activityLevel: ActivityLevel;
  age: Scalars['Int']['input'];
  gender: Gender;
  height: Scalars['Float']['input'];
  weight: Scalars['Float']['input'];
};

export type BeeAndTdeeInputLog = {
  __typename?: 'BEEAndTDEEInputLog';
  activityLevel: ActivityLevel;
  age: Scalars['Int']['output'];
  gender: Gender;
  height: Scalars['Float']['output'];
  weight: Scalars['Float']['output'];
};

export type BeeAndTdeeResponse = {
  __typename?: 'BEEAndTDEEResponse';
  bee?: Maybe<Scalars['Float']['output']>;
  tdee?: Maybe<Scalars['Float']['output']>;
};

export type BeeAndTdeeResult = {
  __typename?: 'BEEAndTDEEResult';
  bee?: Maybe<Scalars['Double']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  queryInput?: Maybe<BeeAndTdeeInputLog>;
  tdee?: Maybe<Scalars['Double']['output']>;
};

export type BeeAndTdeeResultConnection = {
  __typename?: 'BEEAndTDEEResultConnection';
  edges: Array<BeeAndTdeeResultEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type BeeAndTdeeResultEdge = {
  __typename?: 'BEEAndTDEEResultEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: BeeAndTdeeResult;
};

export enum Benefit {
  Dental = 'DENTAL',
  Diagnostic = 'DIAGNOSTIC',
  Emergency = 'EMERGENCY',
  Inpatient = 'INPATIENT',
  Laboratory = 'LABORATORY',
  Maternity = 'MATERNITY',
  Optical = 'OPTICAL',
  Outpatient = 'OUTPATIENT',
  Pharmacy = 'PHARMACY',
  Radiology = 'RADIOLOGY'
}

export type BenefitEligibility = {
  __typename?: 'BenefitEligibility';
  aiJustification?: Maybe<Scalars['String']['output']>;
  benefit?: Maybe<Benefit>;
  id: Scalars['ID']['output'];
  isEligible?: Maybe<Scalars['Boolean']['output']>;
};

export type BenefitFilterInput = {
  healthPrograms?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type BenefitGroup = H_Node & {
  __typename?: 'BenefitGroup';
  created?: Maybe<Scalars['Instant']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  healthPrograms?: Maybe<HealthProgramCountableConnection>;
  id: Scalars['ID']['output'];
  modified?: Maybe<Scalars['Instant']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};


export type BenefitGroupHealthProgramsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** CRUD a new Benefit Group */
export type BenefitGroupCrud = {
  __typename?: 'BenefitGroupCRUD';
  benefitGroup?: Maybe<BenefitGroup>;
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  healthProgramErrors?: Maybe<Array<H_EntityError>>;
};

export type BenefitGroupComplex = H_Node & {
  __typename?: 'BenefitGroupComplex';
  benefitGroup?: Maybe<BenefitGroup>;
  created?: Maybe<Scalars['Instant']['output']>;
  extraBenefits?: Maybe<Array<Maybe<ExtraBenefitComplex>>>;
  id: Scalars['ID']['output'];
  modified?: Maybe<Scalars['Instant']['output']>;
  optionsValues?: Maybe<Array<Maybe<BenefitOptionComplex>>>;
};

export type BenefitGroupInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type BenefitInput = {
  authorizationInfo?: InputMaybe<Array<InputMaybe<AuthorizationInfoInput>>>;
  authorizationRequired?: InputMaybe<Scalars['Boolean']['input']>;
  benefitType?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  coverage?: InputMaybe<Array<InputMaybe<CoverageInput>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  network?: InputMaybe<Scalars['String']['input']>;
  productOrService?: InputMaybe<ProductOrServiceInput>;
  term?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
  waitingPeriod?: InputMaybe<Scalars['Int']['input']>;
};

export type BenefitOption = H_Node & {
  __typename?: 'BenefitOption';
  careType?: Maybe<CareType>;
  code?: Maybe<HealthProgramBenefitOptionCode>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<HealthProgramOptionType>;
};

export type BenefitOptionComplex = H_Node & {
  __typename?: 'BenefitOptionComplex';
  created?: Maybe<Scalars['Instant']['output']>;
  healthPrograms?: Maybe<HealthProgramCountableConnection>;
  id: Scalars['ID']['output'];
  modified?: Maybe<Scalars['Instant']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  option?: Maybe<BenefitOption>;
  value?: Maybe<Scalars['Float']['output']>;
};


export type BenefitOptionComplexHealthProgramsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type Block = Node & {
  __typename?: 'Block';
  city: City;
  coordinates?: Maybe<Array<Maybe<LocationType>>>;
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  nameAr?: Maybe<Scalars['String']['output']>;
};

export type BlockCountableConnection = {
  __typename?: 'BlockCountableConnection';
  edges: Array<BlockCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type BlockCountableEdge = {
  __typename?: 'BlockCountableEdge';
  cursor: Scalars['String']['output'];
  node: Block;
};

export type BlockCreate = {
  __typename?: 'BlockCreate';
  block?: Maybe<Block>;
  blockErrors: Array<BlockError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type BlockDelete = {
  __typename?: 'BlockDelete';
  block?: Maybe<Block>;
  blockErrors: Array<BlockError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type BlockError = {
  __typename?: 'BlockError';
  code: BlockErrorCode;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum BlockErrorCode {
  AlreadyExists = 'ALREADY_EXISTS',
  DuplicatedInputItem = 'DUPLICATED_INPUT_ITEM',
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type BlockFilterInput = {
  location?: InputMaybe<LocationInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
};

export type BlockInput = {
  city?: InputMaybe<Scalars['ID']['input']>;
  coordinates?: InputMaybe<Array<LocationInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameAr?: InputMaybe<Scalars['String']['input']>;
};

export type BlockOrder = {
  direction: OrderDirection;
  field?: InputMaybe<BlockOrderField>;
};

export enum BlockOrderField {
  Created = 'CREATED',
  Name = 'NAME'
}

export type BlockUpdate = {
  __typename?: 'BlockUpdate';
  block?: Maybe<Block>;
  blockErrors: Array<BlockError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type BodyComposition = {
  __typename?: 'BodyComposition';
  alcohol?: Maybe<Scalars['Boolean']['output']>;
  alcoholCupsPerDay?: Maybe<Scalars['Int']['output']>;
  dietType?: Maybe<DietType>;
  exercise?: Maybe<Scalars['Boolean']['output']>;
  exercises?: Maybe<Array<Maybe<Exercise>>>;
  heightInCM?: Maybe<Scalars['Float']['output']>;
  patientId?: Maybe<Scalars['H_Long']['output']>;
  sleep?: Maybe<Scalars['Int']['output']>;
  smoking?: Maybe<Scalars['Int']['output']>;
  weightInKG?: Maybe<Scalars['Float']['output']>;
};

export type BodyFatPercentageInput = {
  age: Scalars['Int']['input'];
  gender: Gender;
  height: Scalars['Float']['input'];
  weight: Scalars['Float']['input'];
};

export type BodyFatPercentageInputLog = {
  __typename?: 'BodyFatPercentageInputLog';
  age: Scalars['Int']['output'];
  gender: Gender;
  height: Scalars['Float']['output'];
  weight: Scalars['Float']['output'];
};

export type BodyFatPercentageResponse = {
  __typename?: 'BodyFatPercentageResponse';
  bodyFatPercentage?: Maybe<Scalars['Float']['output']>;
};

export type BodyFatPercentageResult = {
  __typename?: 'BodyFatPercentageResult';
  bodyFatPercentage?: Maybe<Scalars['Double']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  queryInput?: Maybe<BodyFatPercentageInputLog>;
};

export type BodyFatPercentageResultConnection = {
  __typename?: 'BodyFatPercentageResultConnection';
  edges: Array<BodyFatPercentageResultEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type BodyFatPercentageResultEdge = {
  __typename?: 'BodyFatPercentageResultEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: BodyFatPercentageResult;
};

export enum BookingTypeEnum {
  Appointment = 'APPOINTMENT',
  WalkIn = 'WALK_IN'
}

export type Branch = Node & {
  __typename?: 'Branch';
  acceptsDelivery: Scalars['Boolean']['output'];
  acceptsPickup: Scalars['Boolean']['output'];
  address?: Maybe<Address>;
  branchuser: BranchUserCountableConnection;
  consumers: UserCountableConnection;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactNumber?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  departments: DepartmentConnection;
  description: Scalars['String']['output'];
  healthLicense?: Maybe<Scalars['String']['output']>;
  healthLicenseEndDate?: Maybe<Scalars['Date']['output']>;
  healthLicenseStartDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isOpen?: Maybe<Scalars['Boolean']['output']>;
  isTrained: Scalars['Boolean']['output'];
  messages?: Maybe<MessageCountableConnection>;
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  nameAr?: Maybe<Scalars['String']['output']>;
  preferredPharmacies?: Maybe<Array<Maybe<Branch>>>;
  seoDescription?: Maybe<Scalars['String']['output']>;
  seoTitle?: Maybe<Scalars['String']['output']>;
  type?: Maybe<BranchType>;
  users?: Maybe<Array<Maybe<User>>>;
  vendor: Vendor;
  workingHours?: Maybe<Array<Maybe<WorkingHour>>>;
  workingHoursOverride?: Maybe<Array<Maybe<WorkingHourOverride>>>;
};


export type BranchBranchuserArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BranchConsumersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BranchDepartmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type BranchMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  customerId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type BranchCountableConnection = {
  __typename?: 'BranchCountableConnection';
  edges: Array<BranchCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type BranchCountableEdge = {
  __typename?: 'BranchCountableEdge';
  cursor: Scalars['String']['output'];
  node: Branch;
};

export type BranchCreate = {
  __typename?: 'BranchCreate';
  branch?: Maybe<Branch>;
  branchErrors: Array<BranchError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type BranchDelete = {
  __typename?: 'BranchDelete';
  branch?: Maybe<Branch>;
  branchErrors: Array<BranchError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type BranchError = {
  __typename?: 'BranchError';
  code: VendorErrorCode;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type BranchFilterInput = {
  acceptsDelivery?: InputMaybe<Scalars['Boolean']['input']>;
  acceptsPickup?: InputMaybe<Scalars['Boolean']['input']>;
  branchGlobalSearch?: InputMaybe<Scalars['String']['input']>;
  contactNumber?: InputMaybe<Scalars['String']['input']>;
  contactNumber_Icontains?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  enableVisitDetails?: InputMaybe<Scalars['Boolean']['input']>;
  hasChatWith?: InputMaybe<Scalars['Boolean']['input']>;
  healthLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isActiveBranch?: InputMaybe<Scalars['Boolean']['input']>;
  isIntegrated?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<DistanceFilterInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Array<InputMaybe<VendorBranchTypeEnum>>>;
  vendors?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type BranchInput = {
  acceptsDelivery?: InputMaybe<Scalars['Boolean']['input']>;
  acceptsPickup?: InputMaybe<Scalars['Boolean']['input']>;
  addressInput?: InputMaybe<AddressInput>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactNumber?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  healthLicense?: InputMaybe<Scalars['String']['input']>;
  healthLicenseEndDate?: InputMaybe<Scalars['Date']['input']>;
  healthLicenseStartDate?: InputMaybe<Scalars['Date']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isTrained?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameAr?: InputMaybe<Scalars['String']['input']>;
  preferredPharmacies?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  type?: InputMaybe<VendorBranchTypeEnum>;
  workingHours?: InputMaybe<Array<InputMaybe<WorkingHourInput>>>;
  workingHoursOverride?: InputMaybe<Array<InputMaybe<WorkingHourOverrideInput>>>;
};

export type BranchOrder = {
  direction: OrderDirection;
  field?: InputMaybe<BranchOrderField>;
};

export enum BranchOrderField {
  Created = 'CREATED',
  LastMessageSent = 'LAST_MESSAGE_SENT',
  Name = 'NAME',
  Nearest = 'NEAREST'
}

export enum BranchType {
  Clinic = 'CLINIC',
  DiagnosticCenter = 'DIAGNOSTIC_CENTER',
  Hospital = 'HOSPITAL',
  Pharmacy = 'PHARMACY'
}

export type BranchUpdate = {
  __typename?: 'BranchUpdate';
  branch?: Maybe<Branch>;
  branchErrors: Array<BranchError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type BranchUser = {
  __typename?: 'BranchUser';
  branch: Branch;
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  user: User;
};

export type BranchUserCountableConnection = {
  __typename?: 'BranchUserCountableConnection';
  edges: Array<BranchUserCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type BranchUserCountableEdge = {
  __typename?: 'BranchUserCountableEdge';
  cursor: Scalars['String']['output'];
  node: BranchUser;
};

export enum BulkErrorCodes {
  BulkCancelBulkFile = 'BULK_CANCEL_BULK_FILE',
  BulkDuplicateRowData = 'BULK_DUPLICATE_ROW_DATA',
  BulkFieldRequired = 'BULK_FIELD_REQUIRED',
  BulkFileNotFound = 'BULK_FILE_NOT_FOUND',
  BulkFileServiceNotReachable = 'BULK_FILE_SERVICE_NOT_REACHABLE',
  BulkInvalidDateFormat = 'BULK_INVALID_DATE_FORMAT',
  BulkInvalidFileExtension = 'BULK_INVALID_FILE_EXTENSION',
  BulkNoneNegativePageSize = 'BULK_NONE_NEGATIVE_PAGE_SIZE',
  BulkPermissionDenied = 'BULK_PERMISSION_DENIED',
  BulkTemplateNotEnabled = 'BULK_TEMPLATE_NOT_ENABLED',
  BulkUnknown = 'BULK_UNKNOWN',
  BulkUnknownType = 'BULK_UNKNOWN_TYPE',
  FileAlreadyExist = 'FILE_ALREADY_EXIST'
}

export type BulkFile = {
  __typename?: 'BulkFile';
  code?: Maybe<BulkTemplateCode>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']['output']>;
  errors?: Maybe<Array<Maybe<BulkGraphqlError>>>;
  failedRecordCount?: Maybe<Scalars['Int']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  processedRecordCount?: Maybe<Scalars['Int']['output']>;
  processingStatus?: Maybe<BulkProcessingStatus>;
  recordsCount?: Maybe<Scalars['Int']['output']>;
  updatedDate?: Maybe<Scalars['Date']['output']>;
};

export type BulkFileConnection = {
  __typename?: 'BulkFileConnection';
  edges?: Maybe<Array<Maybe<BulkFileEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type BulkFileEdge = {
  __typename?: 'BulkFileEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<BulkFile>;
};

export enum BulkFileSortField {
  Code = 'CODE',
  CreatedDate = 'CREATED_DATE',
  FailedRecordCount = 'FAILED_RECORD_COUNT',
  FileName = 'FILE_NAME',
  ProcessedRecordCount = 'PROCESSED_RECORD_COUNT',
  RecordsCount = 'RECORDS_COUNT',
  UpdatedDate = 'UPDATED_DATE'
}

export type BulkFilesFilterInput = {
  code?: InputMaybe<BulkTemplateCode>;
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  createdDate?: InputMaybe<Scalars['Date']['input']>;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  processingStatus?: InputMaybe<BulkProcessingStatus>;
  updateDate?: InputMaybe<Scalars['Date']['input']>;
};

export enum BulkFilesOrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type BulkFilesSortingInput = {
  direction: BulkFilesOrderDirection;
  field: BulkFileSortField;
};

export type BulkGraphqlError = {
  __typename?: 'BulkGraphqlError';
  code?: Maybe<BulkErrorCodes>;
  errorType?: Maybe<ErrorType>;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum BulkProcessingStatus {
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Processed = 'PROCESSED',
  UnderProcess = 'UNDER_PROCESS'
}

export type BulkTemplate = {
  __typename?: 'BulkTemplate';
  code?: Maybe<BulkTemplateCode>;
  description?: Maybe<Scalars['String']['output']>;
};

export enum BulkTemplateCode {
  Doctor = 'DOCTOR',
  HealthParameter = 'HEALTH_PARAMETER',
  HealthProgramMember = 'HEALTH_PROGRAM_MEMBER',
  HealthProgramNetwork = 'HEALTH_PROGRAM_NETWORK',
  HealthProgramNetworkProvider = 'HEALTH_PROGRAM_NETWORK_PROVIDER',
  MedicalForm = 'MEDICAL_FORM'
}

export enum CalculatedPaymentStatusForSubscription {
  PaymentChargedBack = 'PAYMENT_CHARGED_BACK',
  PaymentPending = 'PAYMENT_PENDING',
  PaymentRejected = 'PAYMENT_REJECTED',
  PaymentSucceeded = 'PAYMENT_SUCCEEDED',
  PaymentUnderProcessing = 'PAYMENT_UNDER_PROCESSING',
  RefundedToWallet = 'REFUNDED_TO_WALLET',
  RefundChargedBack = 'REFUND_CHARGED_BACK',
  RefundPending = 'REFUND_PENDING',
  RefundRejected = 'REFUND_REJECTED',
  RefundSucceeded = 'REFUND_SUCCEEDED'
}

export type CallAuditRecord = H_Node & {
  __typename?: 'CallAuditRecord';
  accessHash?: Maybe<Scalars['String']['output']>;
  anonChatId?: Maybe<Scalars['String']['output']>;
  callAuditRecordCreatedAt?: Maybe<Scalars['Instant']['output']>;
  callAuditRecordCreatedBy?: Maybe<Scalars['String']['output']>;
  callAuditRecordId?: Maybe<Scalars['String']['output']>;
  callId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  fileId?: Maybe<Scalars['String']['output']>;
  fileUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  ofUser?: Maybe<Scalars['String']['output']>;
  organizationId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<CallSummaryAuditRecordType>;
};

export type CallDoctorNowInput = {
  branchId?: InputMaybe<Scalars['ID']['input']>;
  departmentId?: InputMaybe<Scalars['ID']['input']>;
  doctorId: Scalars['ID']['input'];
  patientNote?: InputMaybe<Scalars['String']['input']>;
};

export enum CallRecordStatus {
  Available = 'AVAILABLE',
  Ready = 'READY',
  TranscriptedFailed = 'TRANSCRIPTED_FAILED',
  TranscriptedSucceeded = 'TRANSCRIPTED_SUCCEEDED',
  Unavailable = 'UNAVAILABLE'
}

export enum CallSummaryAuditRecordType {
  Snapshot = 'SNAPSHOT'
}

export type CallbackRequest = H_Node & {
  __typename?: 'CallbackRequest';
  comments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  contactNo?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['Instant']['output']>;
  id: Scalars['ID']['output'];
  modified?: Maybe<Scalars['Instant']['output']>;
  patient?: Maybe<Patient>;
  patientNotes?: Maybe<Scalars['String']['output']>;
  serviceName?: Maybe<Scalars['String']['output']>;
  status?: Maybe<CallbackRequestStatus>;
  user?: Maybe<User>;
};

export type CallbackRequestAdminInput = {
  comments?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<CallbackRequestStatus>;
};

export type CallbackRequestCrud = {
  __typename?: 'CallbackRequestCRUD';
  callbackRequest?: Maybe<CallbackRequest>;
  entityErrors?: Maybe<Array<H_EntityError>>;
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
};

export type CallbackRequestCountableConnection = {
  __typename?: 'CallbackRequestCountableConnection';
  edges: Array<CallbackRequestCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CallbackRequestCountableEdge = {
  __typename?: 'CallbackRequestCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  node: CallbackRequest;
};

export type CallbackRequestFilterInput = {
  contactNo?: InputMaybe<Scalars['String']['input']>;
  direction?: InputMaybe<H_OrderDirection>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  patientName?: InputMaybe<Scalars['String']['input']>;
  requestDateFrom?: InputMaybe<Scalars['Instant']['input']>;
  requestDateTo?: InputMaybe<Scalars['Instant']['input']>;
  serviceName?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<CallbackRequestStatus>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type CallbackRequestInput = {
  contactNo?: InputMaybe<Scalars['String']['input']>;
  patientNotes?: InputMaybe<Scalars['String']['input']>;
  serviceName: Scalars['String']['input'];
};

export enum CallbackRequestStatus {
  InProgress = 'IN_PROGRESS',
  New = 'NEW',
  Resolved = 'RESOLVED'
}

export type CallbackRequestUpdateHistory = H_Node & {
  __typename?: 'CallbackRequestUpdateHistory';
  callbackRequestId?: Maybe<Scalars['ID']['output']>;
  comments?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['Instant']['output']>;
  id: Scalars['ID']['output'];
  status?: Maybe<CallbackRequestStatus>;
  user?: Maybe<User>;
};

export type CallbackRequestUpdateHistoryCountableConnection = {
  __typename?: 'CallbackRequestUpdateHistoryCountableConnection';
  edges: Array<CallbackRequestUpdateHistoryCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CallbackRequestUpdateHistoryCountableEdge = {
  __typename?: 'CallbackRequestUpdateHistoryCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  node: CallbackRequestUpdateHistory;
};

export type CallsForVendorFilter = {
  fromDate: Scalars['String']['input'];
  healthProgramId: Scalars['Int']['input'];
  toDate: Scalars['String']['input'];
  vendorId: Scalars['String']['input'];
};

export type CallsPerVendorFilter = {
  fromDate: Scalars['String']['input'];
  healthProgramId: Scalars['Int']['input'];
  isUrgentDemandCall: Scalars['Boolean']['input'];
  toDate: Scalars['String']['input'];
};

export type CancelInput = {
  cancellationReason: Scalars['String']['input'];
};

/** CRUD a new cancel reason. */
export type CancelReasonCrud = {
  __typename?: 'CancelReasonCRUD';
  cancelReason?: Maybe<H_CancelReason>;
  entityErrors?: Maybe<Array<H_EntityError>>;
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
};

export type CancelReasonInput = {
  actor: Actor;
  affectedType: AffectedType;
  /** Cancel Reason */
  text: Scalars['String']['input'];
  text_ar: Scalars['String']['input'];
};

export type CardInput = {
  cardCVV: Scalars['String']['input'];
  cardExpiryMonth: Scalars['Int']['input'];
  cardExpiryYear: Scalars['Int']['input'];
  cardHolder: Scalars['String']['input'];
  cardNumber: Scalars['String']['input'];
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  paymentBrand: PaymentBrand;
};

export type CardIssuer = {
  __typename?: 'CardIssuer';
  bank?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type CareJourneyStatistics = {
  __typename?: 'CareJourneyStatistics';
  adherence?: Maybe<Scalars['Float']['output']>;
  allActivities?: Maybe<Scalars['Int']['output']>;
  allCompletedActivities?: Maybe<Scalars['Int']['output']>;
  gaps?: Maybe<Scalars['Int']['output']>;
};

export type CareJourneyStatisticsFilterInput = {
  guidedCareHealthProgramId?: InputMaybe<Scalars['ID']['input']>;
  guidedCareJourneyId?: InputMaybe<Scalars['ID']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
};

export enum CareType {
  ErxCoverage = 'ERX_COVERAGE',
  ImmediateCare = 'IMMEDIATE_CARE',
  Proactive = 'PROACTIVE',
  Reactive = 'REACTIVE'
}

export enum ChartType {
  Bar = 'BAR',
  HorizontalBar = 'HORIZONTAL_BAR',
  Line = 'LINE',
  Table = 'TABLE'
}

export type Chat = {
  __typename?: 'Chat';
  attachments?: Maybe<Array<ChatAttachment>>;
  createdAt: Scalars['Instant']['output'];
  id: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  senderUser: User;
};

export type ChatAttachment = {
  __typename?: 'ChatAttachment';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type ChatAttachmentInput = {
  name: Scalars['String']['input'];
  type: Scalars['String']['input'];
  url: Scalars['String']['input'];
};

export type ChatConnection = {
  __typename?: 'ChatConnection';
  edges: Array<ChatEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ChatEdge = {
  __typename?: 'ChatEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: Chat;
};

export type ChatError = {
  __typename?: 'ChatError';
  code: ChatErrorCode;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum ChatErrorCode {
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED'
}

export type ChatFilterInput = {
  chatGroupId: Scalars['ID']['input'];
};

export type ChatFlow = {
  __typename?: 'ChatFlow';
  code: Scalars['String']['output'];
  conditionsAndRules?: Maybe<Scalars['String']['output']>;
  contributors?: Maybe<Array<User>>;
  createdAt: Scalars['Instant']['output'];
  description?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<ChatFlowDocument>>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Instant']['output'];
};

export type ChatFlowConnection = {
  __typename?: 'ChatFlowConnection';
  edges: Array<ChatFlowEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ChatFlowCreateInput = {
  conditionsAndRules?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type ChatFlowDocument = {
  __typename?: 'ChatFlowDocument';
  createdAt: Scalars['Instant']['output'];
  fileName: Scalars['String']['output'];
  fileSize: Scalars['Int']['output'];
  fileType: FileType;
  id: Scalars['ID']['output'];
};

export type ChatFlowDocumentInput = {
  convertToText?: InputMaybe<Scalars['Boolean']['input']>;
  fileName: Scalars['String']['input'];
  fileType: FileType;
};

export type ChatFlowEdge = {
  __typename?: 'ChatFlowEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: ChatFlow;
};

export type ChatFlowFilterInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type ChatFlowSortingInput = {
  direction?: InputMaybe<FlowrseIntegrationSortDirection>;
  field?: InputMaybe<FlowiseIntegrationChatSortingField>;
};

export type ChatFlowUpdateInput = {
  conditionsAndRules: Scalars['String']['input'];
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type ChatGroup = {
  __typename?: 'ChatGroup';
  chat?: Maybe<ChatConnection>;
  consumerName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Instant']['output'];
  id: Scalars['ID']['output'];
  locked?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  participants: Array<User>;
  providerGuidedCareHealthProgramTeamLeaderName?: Maybe<Scalars['String']['output']>;
  providerGuidedCareHealthProgramTeamMemberName?: Maybe<Scalars['String']['output']>;
  providerGuidedCareHealthProgramTeamName?: Maybe<Scalars['String']['output']>;
  visitId?: Maybe<Scalars['ID']['output']>;
};


export type ChatGroupChatArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<ChatSortingInput>;
};

export type ChatGroupConnection = {
  __typename?: 'ChatGroupConnection';
  edges: Array<ChatGroupEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type ChatGroupEdge = {
  __typename?: 'ChatGroupEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: ChatGroup;
};

export enum ChatGroupSortingField {
  Created = 'CREATED'
}

export type ChatGroupSortingInput = {
  direction?: InputMaybe<CommunicationSortDirection>;
  field?: InputMaybe<ChatGroupSortingField>;
};

export type ChatMessageInput = {
  attachments?: InputMaybe<Array<ChatAttachmentInput>>;
  chatGroupId: Scalars['ID']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
};

export enum ChatSortingField {
  Created = 'CREATED'
}

export type ChatSortingInput = {
  direction?: InputMaybe<CommunicationSortDirection>;
  field?: InputMaybe<ChatSortingField>;
};

export type CheckCustomerAccountExist = {
  __typename?: 'CheckCustomerAccountExist';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  isContactNumberExists?: Maybe<Scalars['Boolean']['output']>;
  isEmailExists?: Maybe<Scalars['Boolean']['output']>;
  patient?: Maybe<Patient>;
};

export type City = Node & {
  __typename?: 'City';
  addresses: AddressCountableConnection;
  area?: Maybe<Scalars['String']['output']>;
  blocks: BlockCountableConnection;
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  maxNumberOfRounds?: Maybe<Scalars['Int']['output']>;
  maxNumberOfTries?: Maybe<Scalars['Int']['output']>;
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  nameAr?: Maybe<Scalars['String']['output']>;
  rounds?: Maybe<Array<Maybe<CityRound>>>;
  timeOutPeriod?: Maybe<Scalars['Int']['output']>;
};


export type CityAddressesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CityBlocksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CityCountableConnection = {
  __typename?: 'CityCountableConnection';
  edges: Array<CityCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CityCountableEdge = {
  __typename?: 'CityCountableEdge';
  cursor: Scalars['String']['output'];
  node: City;
};

export type CityCreate = {
  __typename?: 'CityCreate';
  city?: Maybe<City>;
  cityErrors: Array<BlockError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type CityDelete = {
  __typename?: 'CityDelete';
  city?: Maybe<City>;
  cityErrors: Array<BlockError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type CityFilterInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
};

export type CityInput = {
  area?: InputMaybe<Scalars['String']['input']>;
  maxNumberOfRounds?: InputMaybe<Scalars['Int']['input']>;
  maxNumberOfTries?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameAr?: InputMaybe<Scalars['String']['input']>;
  rounds?: InputMaybe<Array<InputMaybe<RoundInput>>>;
  timeOutPeriod?: InputMaybe<Scalars['Int']['input']>;
};

export type CityOrder = {
  direction: OrderDirection;
  field?: InputMaybe<CityOrderField>;
};

export enum CityOrderField {
  Created = 'CREATED',
  Name = 'NAME'
}

export type CityRound = Node & {
  __typename?: 'CityRound';
  city: City;
  id: Scalars['ID']['output'];
  maxNumberOfPharmacies: Scalars['Int']['output'];
  pharmaciesTypes?: Maybe<Array<Maybe<VendorBranchTypeEnum>>>;
  radius: Scalars['Float']['output'];
};

export type CityUpdate = {
  __typename?: 'CityUpdate';
  city?: Maybe<City>;
  cityErrors: Array<BlockError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type Clinician = {
  __typename?: 'Clinician';
  email?: Maybe<Scalars['String']['output']>;
  englishName?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  license?: Maybe<Scalars['String']['output']>;
  licenseEnd?: Maybe<Scalars['Date']['output']>;
  licenseStart?: Maybe<Scalars['Date']['output']>;
  nationalId?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  tempPassword?: Maybe<Scalars['String']['output']>;
};

export type ClinicianCredentials = {
  __typename?: 'ClinicianCredentials';
  errors?: Maybe<Array<Maybe<IntegrationGraphqlError>>>;
  license?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  userName?: Maybe<Scalars['String']['output']>;
};

export type ClinicianCredentialsInput = {
  license: Scalars['String']['input'];
  password: Scalars['String']['input'];
  user: Scalars['ID']['input'];
  userName: Scalars['String']['input'];
};

export type CodeSystem = {
  __typename?: 'CodeSystem';
  codeSystemConcepts?: Maybe<Array<Maybe<CodeSystemConcept>>>;
  codeSystemDefinition?: Maybe<CodeSystemDefinition>;
  codeSystemHistory?: Maybe<Array<Maybe<CodeSystemHistory>>>;
  display?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<TerminologyGraphqlError>>>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  processingStatus?: Maybe<ProcessingStatus>;
  version?: Maybe<Scalars['Int']['output']>;
};

export enum CodeSystemCode {
  Allergy = 'ALLERGY',
  CancellationReason = 'CANCELLATION_REASON',
  ChronicDisease = 'CHRONIC_DISEASE',
  ChronicMedication = 'CHRONIC_MEDICATION',
  City = 'CITY',
  Clinician = 'CLINICIAN',
  Cpt = 'CPT',
  DenialCode = 'DENIAL_CODE',
  Diagnosis = 'DIAGNOSIS',
  DiagnosisRelatedGroup = 'DIAGNOSIS_RELATED_GROUP',
  Disability = 'DISABILITY',
  Division = 'DIVISION',
  Family = 'FAMILY',
  GenericDrug = 'GENERIC_DRUG',
  GenericDrugExtraData = 'GENERIC_DRUG_EXTRA_DATA',
  HealthPackageCategory = 'HEALTH_PACKAGE_CATEGORY',
  HealthProfessionalSpeciality = 'HEALTH_PROFESSIONAL_SPECIALITY',
  HealthProvider = 'HEALTH_PROVIDER',
  LabTest = 'LAB_TEST',
  Loinc = 'LOINC',
  MedicalService = 'MEDICAL_SERVICE',
  MobileTranslation = 'MOBILE_TRANSLATION',
  Nationality = 'NATIONALITY',
  NonChronicDisease = 'NON_CHRONIC_DISEASE',
  RejectionReason = 'REJECTION_REASON',
  RouteOfAdmin = 'ROUTE_OF_ADMIN',
  Service = 'SERVICE',
  Speciality = 'SPECIALITY',
  Supplement = 'SUPPLEMENT',
  Surgery = 'SURGERY',
  Temperature = 'TEMPERATURE',
  TradeDrug = 'TRADE_DRUG',
  TradeDrugExtraData = 'TRADE_DRUG_EXTRA_DATA',
  UnitId = 'UNIT_ID'
}

export type CodeSystemConcept = {
  __typename?: 'CodeSystemConcept';
  code?: Maybe<Scalars['String']['output']>;
  display?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<TerminologyGraphqlError>>>;
  id?: Maybe<Scalars['ID']['output']>;
  properties?: Maybe<Array<Maybe<CodeSystemConceptProperty>>>;
};

export type CodeSystemConceptChange = {
  __typename?: 'CodeSystemConceptChange';
  newCodeSystemConcept?: Maybe<CodeSystemConcept>;
  oldCodeSystemConcept?: Maybe<CodeSystemConcept>;
};

export type CodeSystemConceptConnection = {
  __typename?: 'CodeSystemConceptConnection';
  edges?: Maybe<Array<Maybe<CodeSystemConceptEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CodeSystemConceptEdge = {
  __typename?: 'CodeSystemConceptEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<CodeSystemConcept>;
};

export type CodeSystemConceptFieldDefinition = {
  __typename?: 'CodeSystemConceptFieldDefinition';
  code?: Maybe<Scalars['String']['output']>;
  display?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<TerminologyGraphqlError>>>;
  fieldType?: Maybe<Type>;
  id?: Maybe<Scalars['ID']['output']>;
  isEditable: Scalars['Boolean']['output'];
  isMandatory?: Maybe<Scalars['Boolean']['output']>;
  isVisible?: Maybe<Scalars['Boolean']['output']>;
  toolTip?: Maybe<Scalars['String']['output']>;
};

export type CodeSystemConceptFieldDefinitionInput = {
  code: Scalars['String']['input'];
  codeSystemDefinition: CodeSystemDefinitionInput;
  display: Scalars['String']['input'];
  fieldType: Type;
  id?: InputMaybe<Scalars['ID']['input']>;
  isMandatory: Scalars['Boolean']['input'];
  isVisible: Scalars['Boolean']['input'];
  toolTip?: InputMaybe<Scalars['String']['input']>;
};

export type CodeSystemConceptInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  properties?: InputMaybe<Array<InputMaybe<CodeSystemConceptPropertyInput>>>;
};

export type CodeSystemConceptProperty = {
  __typename?: 'CodeSystemConceptProperty';
  code?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<TerminologyGraphqlError>>>;
  fieldType?: Maybe<Type>;
  id?: Maybe<Scalars['ID']['output']>;
  valueBoolean?: Maybe<Scalars['Boolean']['output']>;
  valueDate?: Maybe<Scalars['Date']['output']>;
  valueFloat?: Maybe<Scalars['Float']['output']>;
  valueInteger?: Maybe<Scalars['Int']['output']>;
  valueString?: Maybe<Scalars['String']['output']>;
};

export type CodeSystemConceptPropertyInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  fieldType?: InputMaybe<Type>;
  id?: InputMaybe<Scalars['ID']['input']>;
  valueBoolean?: InputMaybe<Scalars['Boolean']['input']>;
  valueDate?: InputMaybe<Scalars['Date']['input']>;
  valueFloat?: InputMaybe<Scalars['Float']['input']>;
  valueInteger?: InputMaybe<Scalars['Int']['input']>;
  valueString?: InputMaybe<Scalars['String']['input']>;
};

export type CodeSystemConnection = {
  __typename?: 'CodeSystemConnection';
  edges?: Maybe<Array<Maybe<CodeSystemEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CodeSystemDefinition = {
  __typename?: 'CodeSystemDefinition';
  code?: Maybe<CodeSystemCode>;
  codeSystemConceptFields?: Maybe<Array<Maybe<CodeSystemConceptFieldDefinition>>>;
  display?: Maybe<Scalars['String']['output']>;
  editorUserId?: Maybe<User>;
  errors?: Maybe<Array<Maybe<TerminologyGraphqlError>>>;
  id?: Maybe<Scalars['ID']['output']>;
  isEditable?: Maybe<Scalars['Boolean']['output']>;
  reviewerUserId?: Maybe<User>;
};

export type CodeSystemDefinitionInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type CodeSystemEdge = {
  __typename?: 'CodeSystemEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<CodeSystem>;
};

export type CodeSystemEvent = {
  __typename?: 'CodeSystemEvent';
  codeSystemCode?: Maybe<CodeSystemCode>;
  id?: Maybe<Scalars['ID']['output']>;
  processingStatus?: Maybe<ProcessingStatus>;
};

export type CodeSystemHistory = {
  __typename?: 'CodeSystemHistory';
  errors?: Maybe<Array<Maybe<TerminologyGraphqlError>>>;
  id?: Maybe<Scalars['ID']['output']>;
  processingStatus?: Maybe<ProcessingStatus>;
  updateDate?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type CodeSystemSummery = {
  __typename?: 'CodeSystemSummery';
  activeCodeSystem?: Maybe<CodeSystem>;
  codeSystemDefinition?: Maybe<CodeSystemDefinition>;
  iconUrl?: Maybe<Scalars['String']['output']>;
  lastCodeSystem?: Maybe<CodeSystem>;
};

export type Coding = {
  __typename?: 'Coding';
  code?: Maybe<Scalars['String']['output']>;
  display?: Maybe<Scalars['String']['output']>;
};

export enum CommunicationSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum Community {
  Rural = 'RURAL',
  Urban = 'URBAN'
}

export type Condition = {
  __typename?: 'Condition';
  conditionOperation?: Maybe<ConditionOperation>;
  conditions?: Maybe<Array<Maybe<Condition>>>;
  entityType?: Maybe<EntityType>;
  field?: Maybe<Field>;
  id?: Maybe<Scalars['ID']['output']>;
  isGroup?: Maybe<Scalars['Boolean']['output']>;
  operator?: Maybe<Operator>;
  value?: Maybe<ConditionValue>;
};

export type ConditionInput = {
  conditionOperation?: InputMaybe<ConditionOperation>;
  conditions?: InputMaybe<Array<InputMaybe<ConditionInput>>>;
  entityType?: InputMaybe<EntityType>;
  field?: InputMaybe<FieldInput>;
  isGroup: Scalars['Boolean']['input'];
  operator?: InputMaybe<Operator>;
  value?: InputMaybe<ConditionValueInput>;
};

export enum ConditionOperation {
  And = 'AND',
  Or = 'OR'
}

export type ConditionValue = {
  __typename?: 'ConditionValue';
  extra?: Maybe<Scalars['String']['output']>;
  valueBoolean?: Maybe<Scalars['Boolean']['output']>;
  valueDate?: Maybe<Scalars['Date']['output']>;
  valueID?: Maybe<Scalars['String']['output']>;
  valueList?: Maybe<Scalars['ID']['output']>;
  valueNumber?: Maybe<Scalars['Float']['output']>;
  valueString?: Maybe<Scalars['String']['output']>;
};

export type ConditionValueInput = {
  extra?: InputMaybe<Scalars['String']['input']>;
  valueBoolean?: InputMaybe<Scalars['Boolean']['input']>;
  valueDate?: InputMaybe<Scalars['Date']['input']>;
  valueID?: InputMaybe<Scalars['String']['input']>;
  valueList?: InputMaybe<Scalars['ID']['input']>;
  valueNumber?: InputMaybe<Scalars['Float']['input']>;
  valueString?: InputMaybe<Scalars['String']['input']>;
};

export type ConfirmAccountEmail = {
  __typename?: 'ConfirmAccountEmail';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ConfirmAccountMobile = {
  __typename?: 'ConfirmAccountMobile';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ConfirmPasswordRestOtp = {
  __typename?: 'ConfirmPasswordRestOTP';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ConfirmVerification = {
  __typename?: 'ConfirmVerification';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum ConsentLevel {
  All = 'ALL',
  Basic = 'BASIC',
  Medical = 'MEDICAL'
}

export enum ConsentStatus {
  Approved = 'APPROVED',
  Closed = 'CLOSED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export enum ConsentType {
  All = 'ALL',
  View = 'VIEW'
}

export type ConsumerAppointmentConfirmInput = {
  confirmedEndTime: Scalars['Instant']['input'];
  confirmedStartTime: Scalars['Instant']['input'];
  doctor?: InputMaybe<Scalars['ID']['input']>;
};

/** Creates an Appointment. */
export type ConsumerAppointmentCreate = {
  __typename?: 'ConsumerAppointmentCreate';
  appointment?: Maybe<Appointment>;
  appointmentErrors?: Maybe<Array<H_EntityError>>;
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
};

export type ConsumerAppointmentInput = {
  branchId?: InputMaybe<Scalars['ID']['input']>;
  departmentId?: InputMaybe<Scalars['ID']['input']>;
  doctor: Scalars['ID']['input'];
  healthProgram?: InputMaybe<Scalars['ID']['input']>;
  requestedTimeSlots: Array<AppointmentTimeSlotInput>;
  type?: InputMaybe<AppointmentType>;
};

export type ConsumerAppointmentInputForAnonymous = {
  branchId?: InputMaybe<Scalars['ID']['input']>;
  departmentId?: InputMaybe<Scalars['ID']['input']>;
  doctor: Scalars['ID']['input'];
  insuranceCategoryCode?: InputMaybe<Scalars['String']['input']>;
  insuranceCompanyName?: InputMaybe<Scalars['String']['input']>;
  insuranceId?: InputMaybe<Scalars['String']['input']>;
  insurancePolicyNumber?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  nationalId?: InputMaybe<Scalars['String']['input']>;
  nationality: Scalars['String']['input'];
  phone: Scalars['String']['input'];
  requestedTimeSlots: Array<AppointmentTimeSlotInput>;
  type?: InputMaybe<AppointmentType>;
  verificationCode?: InputMaybe<Scalars['Int']['input']>;
};

export type ConsumerAppointmentRescheduleInput = {
  requestedTimeSlots: Array<AppointmentTimeSlotInput>;
};

/** follow health channel. */
export type ConsumerHealthChannelFollow = {
  __typename?: 'ConsumerHealthChannelFollow';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  healthChannelErrors?: Maybe<Array<H_EntityError>>;
  healthChannelFollower?: Maybe<HealthChannelFollower>;
};

export type ConsumerHealthChannelFollowInput = {
  channel: Scalars['ID']['input'];
};

/** UnFollow health channel. */
export type ConsumerHealthChannelUnFollow = {
  __typename?: 'ConsumerHealthChannelUnFollow';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  healthChannelErrors?: Maybe<Array<H_EntityError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

/** Rate a Health Channel */
export type ConsumerRateHealthChannel = {
  __typename?: 'ConsumerRateHealthChannel';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  healthChannelErrors?: Maybe<Array<H_EntityError>>;
  healthChannelRating?: Maybe<HealthChannelRating>;
};

export type ConsumerViewPreference = Node & {
  __typename?: 'ConsumerViewPreference';
  data?: Maybe<Scalars['JSONString']['output']>;
  id: Scalars['ID']['output'];
  user: User;
  view: Scalars['String']['output'];
};

export type ConsumerViewPreferenceError = {
  __typename?: 'ConsumerViewPreferenceError';
  code: ConsumerViewPreferenceErrorCode;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum ConsumerViewPreferenceErrorCode {
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type ConsumerViewPreferenceInput = {
  data?: InputMaybe<Scalars['JSONString']['input']>;
  view?: InputMaybe<Scalars['String']['input']>;
};

export type ConsumerViewPreferenceSave = {
  __typename?: 'ConsumerViewPreferenceSave';
  consumerViewPreference?: Maybe<ConsumerViewPreference>;
  consumerViewPreferenceErrors: Array<ConsumerViewPreferenceError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type ConvertVirtualOrderToRealOneResult = {
  __typename?: 'ConvertVirtualOrderToRealOneResult';
  orderErrors?: Maybe<Array<H_EntityError>>;
  orders?: Maybe<Array<Maybe<Order>>>;
  success: Scalars['Boolean']['output'];
};

export type CountryDisplay = {
  __typename?: 'CountryDisplay';
  code: Scalars['String']['output'];
  country: Scalars['String']['output'];
};

export enum Coverage {
  FullyCovered = 'FULLY_COVERED',
  NotCovered = 'NOT_COVERED',
  PartiallyCovered = 'PARTIALLY_COVERED'
}

export enum CoverageByType {
  Cash = 'CASH',
  Insurance = 'INSURANCE',
  Provider = 'PROVIDER',
  Tpa = 'TPA'
}

export type CoverageInput = {
  allowedMoney?: InputMaybe<Scalars['Float']['input']>;
  allowedUnits?: InputMaybe<Scalars['Int']['input']>;
  copayAmount?: InputMaybe<Scalars['Float']['input']>;
  copayMax?: InputMaybe<Scalars['Float']['input']>;
  copayPercent?: InputMaybe<Scalars['Float']['input']>;
  deductibleAmount?: InputMaybe<Scalars['Float']['input']>;
  deductibleMax?: InputMaybe<Scalars['Float']['input']>;
  deductiblePercent?: InputMaybe<Scalars['Float']['input']>;
  roomType?: InputMaybe<Scalars['String']['input']>;
  usedMoney?: InputMaybe<Scalars['Float']['input']>;
  usedUnits?: InputMaybe<Scalars['Int']['input']>;
};

export type CptHcpcPrimaryProcedureValidationInput = {
  itemCode: Scalars['String']['input'];
  sequence: Scalars['String']['input'];
};

export type CreateMeetingInput = {
  chatGroupId: Scalars['ID']['input'];
};

export type CreatePersonalizedAppointmentInput = {
  appointmentServiceDetails?: InputMaybe<Array<InputMaybe<ServiceDetailsInput>>>;
  doctorNote?: InputMaybe<Scalars['String']['input']>;
  justification?: InputMaybe<Scalars['String']['input']>;
  medicalFormId?: InputMaybe<Scalars['ID']['input']>;
  medicalMessageId?: InputMaybe<Scalars['ID']['input']>;
  memberId: Scalars['String']['input'];
  messageToPatient?: InputMaybe<Scalars['String']['input']>;
  serviceDescription?: InputMaybe<ServiceDescription>;
  startTime: Scalars['Instant']['input'];
  type: GuidedCareActivityType;
};

export type CreatePersonalizedAppointmentsInput = {
  DurationBetweenAppointments: Scalars['Int']['input'];
  appointmentServiceDetails?: InputMaybe<Array<InputMaybe<ServiceDetailsInput>>>;
  doctorNote?: InputMaybe<Scalars['String']['input']>;
  frequencyType: AppointmentFrequencyType;
  justification?: InputMaybe<Scalars['String']['input']>;
  medicalFormId?: InputMaybe<Scalars['ID']['input']>;
  medicalMessageId?: InputMaybe<Scalars['ID']['input']>;
  memberId: Scalars['ID']['input'];
  messageToPatient?: InputMaybe<Scalars['String']['input']>;
  numberOfAppointments: Scalars['Int']['input'];
  serviceDescription?: InputMaybe<ServiceDescription>;
  startTime: Scalars['Instant']['input'];
  type: GuidedCareActivityType;
};

export type CreateVisitPrescriptionActivityInput = {
  code: Scalars['String']['input'];
  duration: Scalars['Int']['input'];
  frequencyType: VisitPrescriptionActivityFrequencyType;
  frequencyUnitType: Scalars['String']['input'];
  frequencyValue: Scalars['Int']['input'];
  instructions: Scalars['String']['input'];
  observation?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Float']['input'];
  refills: Scalars['Int']['input'];
  type: VisitPrescriptionActivityType;
};

export type CreateVisitPrescriptionDiagnosisInput = {
  code: Scalars['String']['input'];
  type: VisitDiagnosisType;
};

export type CreateVisitPrescriptionInput = {
  activities: Array<InputMaybe<CreateVisitPrescriptionActivityInput>>;
  diagnoses: Array<InputMaybe<CreateVisitPrescriptionDiagnosisInput>>;
  memberWeight?: InputMaybe<Scalars['Int']['input']>;
  /**  this is only used by vendor integration when notifying creating a prescription */
  reference?: InputMaybe<Scalars['String']['input']>;
  visitId: Scalars['ID']['input'];
};

export enum Currency {
  Aed = 'AED',
  Eur = 'EUR',
  Sar = 'SAR',
  Usd = 'USD'
}

export type CustomerCreate = {
  __typename?: 'CustomerCreate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  patient?: Maybe<Patient>;
  patientErrors: Array<PatientError>;
};

export type CustomerCreateInput = {
  contactNumber?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth: Scalars['Date']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  emailVerificationToken?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender: PersonGenderEnum;
  isDependent?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  mobileVerificationToken?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  photo?: InputMaybe<Scalars['String']['input']>;
  preferredCommunicationLanguage?: InputMaybe<Scalars['String']['input']>;
  preferredCommunicationMethod?: InputMaybe<PreferredCommunicationMethodsEnum>;
  relationType?: InputMaybe<RelationTypes>;
};

export type CustomerDashboardFilterInput = {
  dateJoined?: InputMaybe<DateRangeInput>;
  joinedPeriod?: InputMaybe<ReportingPeriod>;
};

export type CustomerDelete = {
  __typename?: 'CustomerDelete';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type CustomerDeleteInput = {
  deleteReason: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  emailVerificationToken?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  mobileVerificationToken?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerEvent = Node & {
  __typename?: 'CustomerEvent';
  count?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  type?: Maybe<CustomerEventsEnum>;
  user?: Maybe<User>;
};

export enum CustomerEventsEnum {
  AccountCreated = 'ACCOUNT_CREATED',
  AccountCreatedFromSso = 'ACCOUNT_CREATED_FROM_SSO',
  CustomerDeleted = 'CUSTOMER_DELETED',
  DigitalLinkDownloaded = 'DIGITAL_LINK_DOWNLOADED',
  EmailAssigned = 'EMAIL_ASSIGNED',
  EmailChanged = 'EMAIL_CHANGED',
  EmailChangedRequest = 'EMAIL_CHANGED_REQUEST',
  NameAssigned = 'NAME_ASSIGNED',
  NoteAdded = 'NOTE_ADDED',
  NoteAddedToOrder = 'NOTE_ADDED_TO_ORDER',
  PasswordChanged = 'PASSWORD_CHANGED',
  PasswordReset = 'PASSWORD_RESET',
  PasswordResetLinkSent = 'PASSWORD_RESET_LINK_SENT',
  PlacedOrder = 'PLACED_ORDER'
}

export type CustomerFavoriteVendor = Node & {
  __typename?: 'CustomerFavoriteVendor';
  customer: User;
  id: Scalars['ID']['output'];
  vendor: Vendor;
};

export type CustomerFavoriteVendorCountableConnection = {
  __typename?: 'CustomerFavoriteVendorCountableConnection';
  edges: Array<CustomerFavoriteVendorCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type CustomerFavoriteVendorCountableEdge = {
  __typename?: 'CustomerFavoriteVendorCountableEdge';
  cursor: Scalars['String']['output'];
  node: CustomerFavoriteVendor;
};

export type CustomerFilterInput = {
  dateJoined?: InputMaybe<DateRangeInput>;
  joinedPeriod?: InputMaybe<ReportingPeriod>;
  nationalId?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type CustomerMarkMessagesAsSeen = {
  __typename?: 'CustomerMarkMessagesAsSeen';
  chatErrors: Array<ChatError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  updatedCount?: Maybe<Scalars['Int']['output']>;
};

export type CustomerPrescriptionDispenseInput = {
  addressId: Scalars['ID']['input'];
  isDeliveryRequested: Scalars['Boolean']['input'];
  prescriptionId: Scalars['ID']['input'];
};

export type CustomerProfileUpdate = {
  __typename?: 'CustomerProfileUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  patient?: Maybe<Patient>;
  patientErrors: Array<PatientError>;
};

export type CustomerProfileUpdateInput = {
  bloodGroup?: InputMaybe<Scalars['String']['input']>;
  contactNumber?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<PersonGenderEnum>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  maritalStatus?: InputMaybe<MaritalStatusesEnum>;
  photo?: InputMaybe<Scalars['String']['input']>;
  preferredCommunicationLanguage?: InputMaybe<Scalars['String']['input']>;
  preferredCommunicationMethod?: InputMaybe<PreferredCommunicationMethodsEnum>;
  preferredLanguage?: InputMaybe<Scalars['String']['input']>;
  relationType?: InputMaybe<RelationTypes>;
  residencyType?: InputMaybe<ResidencyTypesEnum>;
};

export type CustomerSendMessage = {
  __typename?: 'CustomerSendMessage';
  chatErrors: Array<ChatError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  message?: Maybe<Message>;
};

export enum CustomerSupportOrderStatusEnum {
  Delivered = 'DELIVERED',
  Failed = 'FAILED',
  Returned = 'RETURNED'
}

export type DateRangeInput = {
  gte?: InputMaybe<Scalars['Date']['input']>;
  lte?: InputMaybe<Scalars['Date']['input']>;
};

export type DateTimeFilterInput = {
  date: Scalars['Date']['input'];
  time: TimeRangeFilterInput;
};

export enum DayOfWeekEnum {
  Fri = 'FRI',
  Mon = 'MON',
  Sat = 'SAT',
  Sun = 'SUN',
  Thu = 'THU',
  Tue = 'TUE',
  Wed = 'WED'
}

export type DecimalRangeInput = {
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
};

export type Decision = {
  __typename?: 'Decision';
  actionFields?: Maybe<Array<Maybe<ActionField>>>;
  conditions?: Maybe<Array<Maybe<Condition>>>;
  decisionPlan?: Maybe<DecisionPlan>;
  drl?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<DecisionMakerGraphqlError>>>;
  healthProgramTemplate?: Maybe<HealthProgramTemplate>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isScript?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Priority>;
};

export type DecisionConnection = {
  __typename?: 'DecisionConnection';
  edges?: Maybe<Array<Maybe<DecisionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type DecisionEdge = {
  __typename?: 'DecisionEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<Decision>;
};

export type DecisionFilterInput = {
  categories?: InputMaybe<Array<InputMaybe<DecisionPlanCategory>>>;
  decisionName?: InputMaybe<Scalars['String']['input']>;
  guidedCareTemplateCode?: InputMaybe<Scalars['String']['input']>;
  healthParameterId?: InputMaybe<Scalars['ID']['input']>;
  healthProgramTemplateId?: InputMaybe<Scalars['ID']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isScript?: InputMaybe<Scalars['Boolean']['input']>;
  plan?: InputMaybe<Scalars['ID']['input']>;
};

export type DecisionInput = {
  actionFields?: InputMaybe<Array<InputMaybe<ActionFieldInput>>>;
  conditions: Array<InputMaybe<ConditionInput>>;
  decisionPlan: DecisionPlanInput;
  drl?: InputMaybe<Scalars['String']['input']>;
  guidedCareTemplateCode?: InputMaybe<Scalars['String']['input']>;
  healthParameterId?: InputMaybe<Scalars['ID']['input']>;
  healthProgramTemplateId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isActive: Scalars['Boolean']['input'];
  isScript: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
  priority: Priority;
};

export type DecisionInputData = {
  code: Scalars['String']['input'];
  type: EntityType;
  valueBoolean?: InputMaybe<Scalars['Boolean']['input']>;
  valueDate?: InputMaybe<Scalars['Date']['input']>;
  valueID?: InputMaybe<Scalars['String']['input']>;
  valueList?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  valueNumber?: InputMaybe<Scalars['Float']['input']>;
  valueString?: InputMaybe<Scalars['String']['input']>;
};

export enum DecisionMakerErrorCodes {
  DecisionFileNotFound = 'DECISION_FILE_NOT_FOUND',
  DecisionFileServiceNotReachable = 'DECISION_FILE_SERVICE_NOT_REACHABLE',
  DecisionInvalidFileExtension = 'DECISION_INVALID_FILE_EXTENSION',
  DecisionInvalidId = 'DECISION_INVALID_ID',
  DecisionMakerDuplicateListItem = 'DECISION_MAKER_DUPLICATE_LIST_ITEM',
  DecisionMakerDuplicateListName = 'DECISION_MAKER_DUPLICATE_LIST_NAME',
  DecisionMakerDuplicateRuleName = 'DECISION_MAKER_DUPLICATE_RULE_NAME',
  DecisionMakerEntityNotFound = 'DECISION_MAKER_ENTITY_NOT_FOUND',
  DecisionMakerFieldMandatory = 'DECISION_MAKER_FIELD_MANDATORY',
  DecisionMakerFieldSize = 'DECISION_MAKER_FIELD_SIZE',
  DecisionMakerFieldSizeException = 'DECISION_MAKER_FIELD_SIZE_EXCEPTION',
  DecisionMakerInvalidAction = 'DECISION_MAKER_INVALID_ACTION',
  DecisionMakerInvalidOperator = 'DECISION_MAKER_INVALID_OPERATOR',
  DecisionMakerInvalidRule = 'DECISION_MAKER_INVALID_RULE',
  DecisionMakerInvalidValue = 'DECISION_MAKER_INVALID_VALUE',
  DecisionMakerNoneNegativePageSize = 'DECISION_MAKER_NONE_NEGATIVE_PAGE_SIZE',
  DecisionMakerSubListNotFound = 'DECISION_MAKER_SUB_LIST_NOT_FOUND',
  DecisionMakerUnknown = 'DECISION_MAKER_UNKNOWN',
  DecisionMakerUnknownType = 'DECISION_MAKER_UNKNOWN_TYPE',
  DecisionMakerValidationError = 'DECISION_MAKER_VALIDATION_ERROR',
  DecisionPermissionDenied = 'DECISION_PERMISSION_DENIED',
  InvalidOrExpiredToken = 'INVALID_OR_EXPIRED_TOKEN'
}

export type DecisionMakerGraphqlError = {
  __typename?: 'DecisionMakerGraphqlError';
  code?: Maybe<DecisionMakerErrorCodes>;
  errorType?: Maybe<ErrorType>;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum DecisionMakerOrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type DecisionPlan = {
  __typename?: 'DecisionPlan';
  actionDefinitionFields?: Maybe<Array<Maybe<ActionDefinitionField>>>;
  category?: Maybe<DecisionPlanCategory>;
  code?: Maybe<Scalars['String']['output']>;
  display?: Maybe<Scalars['String']['output']>;
  field?: Maybe<Array<Maybe<Field>>>;
  id?: Maybe<Scalars['ID']['output']>;
};

export enum DecisionPlanCategory {
  HealthCondition = 'HEALTH_CONDITION',
  Intervention = 'INTERVENTION',
  Optima = 'OPTIMA',
  PatientEligibility = 'PATIENT_ELIGIBILITY',
  RiskStratification = 'RISK_STRATIFICATION',
  TaskFlow = 'TASK_FLOW',
  UserRequiredAction = 'USER_REQUIRED_ACTION'
}

export type DecisionPlanConnection = {
  __typename?: 'DecisionPlanConnection';
  edges?: Maybe<Array<Maybe<DecisionPlanEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type DecisionPlanEdge = {
  __typename?: 'DecisionPlanEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<DecisionPlan>;
};

export type DecisionPlanFilterInput = {
  categories?: InputMaybe<Array<InputMaybe<DecisionPlanCategory>>>;
  planCode?: InputMaybe<Scalars['String']['input']>;
};

export type DecisionPlanInput = {
  id: Scalars['ID']['input'];
};

export enum DecisionSortField {
  DecisionPlan = 'DECISION_PLAN',
  IsActive = 'IS_ACTIVE',
  Name = 'NAME',
  Type = 'TYPE'
}

export type DecisionSortingInput = {
  direction: DecisionMakerOrderDirection;
  field: DecisionSortField;
};

export enum DefaultSortingField {
  Created = 'CREATED'
}

export type DefaultSortingInput = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<DefaultSortingField>;
};

export type DeleteCurrentCustomerUser = {
  __typename?: 'DeleteCurrentCustomerUser';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export enum DeliveryType {
  Delivery = 'DELIVERY',
  Pickup = 'PICKUP'
}

export type DentalHygienist = Node & {
  __typename?: 'DentalHygienist';
  created: Scalars['DateTime']['output'];
  healthLicenseEndDate?: Maybe<Scalars['Date']['output']>;
  healthLicenseNumber?: Maybe<Scalars['String']['output']>;
  healthLicenseStartDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type DentalHygienistInput = {
  healthLicenseEndDate?: InputMaybe<Scalars['Date']['input']>;
  healthLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  healthLicenseStartDate?: InputMaybe<Scalars['Date']['input']>;
};

export type Department = Node & {
  __typename?: 'Department';
  branch: Branch;
  code: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  directions?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  medicalServices?: Maybe<DepartmentMedicalServiceCountableConnection>;
  name: Scalars['String']['output'];
  users?: Maybe<UserCountableConnection>;
};


export type DepartmentMedicalServicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type DepartmentUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DepartmentConnection = {
  __typename?: 'DepartmentConnection';
  edges: Array<Maybe<DepartmentEdge>>;
  pageInfo: PageInfo;
};

export type DepartmentEdge = {
  __typename?: 'DepartmentEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Department>;
};

export type DepartmentMedicalService = Node & {
  __typename?: 'DepartmentMedicalService';
  code: Scalars['String']['output'];
  department: Department;
  id: Scalars['ID']['output'];
};

export type DepartmentMedicalServiceCountableConnection = {
  __typename?: 'DepartmentMedicalServiceCountableConnection';
  edges: Array<DepartmentMedicalServiceCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type DepartmentMedicalServiceCountableEdge = {
  __typename?: 'DepartmentMedicalServiceCountableEdge';
  cursor: Scalars['String']['output'];
  node: DepartmentMedicalService;
};

export enum DepressionFrequency {
  MoreThanHalfTheDays = 'MORE_THAN_HALF_THE_DAYS',
  NearlyEveryDay = 'NEARLY_EVERY_DAY',
  NotAtAll = 'NOT_AT_ALL',
  SeveralDays = 'SEVERAL_DAYS'
}

export type DepressionScreeningInput = {
  feelingDown: DepressionFrequency;
  littleInterest: DepressionFrequency;
};

export type DepressionScreeningInputLog = {
  __typename?: 'DepressionScreeningInputLog';
  feelingDown: DepressionFrequency;
  littleInterest: DepressionFrequency;
};

export type DepressionScreeningResponse = {
  __typename?: 'DepressionScreeningResponse';
  riskLevel?: Maybe<RiskLevel>;
  score?: Maybe<Scalars['Int']['output']>;
};

export type DepressionScreeningResult = {
  __typename?: 'DepressionScreeningResult';
  createdDate?: Maybe<Scalars['Instant']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  queryInput?: Maybe<DepressionScreeningInputLog>;
  riskLevel?: Maybe<RiskLevel>;
  score?: Maybe<Scalars['Int']['output']>;
};

export type DepressionScreeningResultConnection = {
  __typename?: 'DepressionScreeningResultConnection';
  edges: Array<DepressionScreeningResultEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type DepressionScreeningResultEdge = {
  __typename?: 'DepressionScreeningResultEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: DepressionScreeningResult;
};

export enum DestinationType {
  Eclaim = 'ECLAIM'
}

export type DeviceInformation = {
  __typename?: 'DeviceInformation';
  id: Scalars['ID']['output'];
};

export type DeviceInformationInput = {
  AppVersion?: InputMaybe<Scalars['String']['input']>;
  OsVersion?: InputMaybe<Scalars['String']['input']>;
  buildNumber?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  fireBaseToken?: InputMaybe<Scalars['String']['input']>;
  heightPixels?: InputMaybe<Scalars['Int']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  localDisplay?: InputMaybe<Scalars['String']['input']>;
  manufacturer?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  uniqueId: Scalars['String']['input'];
  widthPixels?: InputMaybe<Scalars['Int']['input']>;
};

export type DiabetesEducator = Node & {
  __typename?: 'DiabetesEducator';
  created: Scalars['DateTime']['output'];
  healthLicenseEndDate?: Maybe<Scalars['Date']['output']>;
  healthLicenseNumber?: Maybe<Scalars['String']['output']>;
  healthLicenseStartDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type DiabetesEducatorInput = {
  healthLicenseEndDate?: InputMaybe<Scalars['Date']['input']>;
  healthLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  healthLicenseStartDate?: InputMaybe<Scalars['Date']['input']>;
};

export enum DiabetesRisk {
  High = 'HIGH',
  Low = 'LOW'
}

export type DiabetesRiskInput = {
  age: Scalars['Int']['input'];
  gender: Gender;
  hasBloodPressure: Scalars['Boolean']['input'];
  hasFamilyHistory: Scalars['Boolean']['input'];
  hasGestationalDiabetes: Scalars['Boolean']['input'];
  height: Scalars['Float']['input'];
  physicalActive: Scalars['Boolean']['input'];
  weight: Scalars['Float']['input'];
};

export type DiabetesRiskInputLog = {
  __typename?: 'DiabetesRiskInputLog';
  age: Scalars['Int']['output'];
  gender: Gender;
  hasBloodPressure: Scalars['Boolean']['output'];
  hasFamilyHistory: Scalars['Boolean']['output'];
  hasGestationalDiabetes: Scalars['Boolean']['output'];
  height: Scalars['Float']['output'];
  physicalActive: Scalars['Boolean']['output'];
  weight: Scalars['Float']['output'];
};

export type DiabetesRiskResponse = {
  __typename?: 'DiabetesRiskResponse';
  risk?: Maybe<RiskLevel>;
  score?: Maybe<Scalars['Int']['output']>;
};

export type DiabetesRiskResult = {
  __typename?: 'DiabetesRiskResult';
  createdDate?: Maybe<Scalars['Instant']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  queryInput?: Maybe<DiabetesRiskInputLog>;
  risk?: Maybe<RiskLevel>;
  score?: Maybe<Scalars['Int']['output']>;
};

export type DiabetesRiskResultConnection = {
  __typename?: 'DiabetesRiskResultConnection';
  edges: Array<DiabetesRiskResultEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type DiabetesRiskResultEdge = {
  __typename?: 'DiabetesRiskResultEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: DiabetesRiskResult;
};

export type Diagnose = {
  __typename?: 'Diagnose';
  code: Scalars['String']['output'];
  display?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  type: DiagnoseType;
};

export enum DiagnoseType {
  Principal = 'PRINCIPAL',
  Secondary = 'SECONDARY'
}

export type DiagnosisInput = {
  code: Scalars['String']['input'];
  type: DiagnosisType;
};

export type DiagnosisPredictionError = {
  __typename?: 'DiagnosisPredictionError';
  code: DiagnosisPrescriptionErrorCode;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type DiagnosisPredictionInput = {
  doctorNote?: InputMaybe<Scalars['String']['input']>;
};

export type DiagnosisPredictionMutation = {
  __typename?: 'DiagnosisPredictionMutation';
  diagnosis?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  diagnosisPredictionsErrors: Array<DiagnosisPredictionError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export enum DiagnosisPrescriptionErrorCode {
  ControlledDrug = 'CONTROLLED_DRUG',
  GraphqlError = 'GRAPHQL_ERROR',
  Ingested = 'INGESTED',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export enum DiagnosisType {
  Principal = 'Principal',
  Secondary = 'Secondary'
}

export enum DietQuality {
  Adequate = 'ADEQUATE',
  Fair = 'FAIR',
  Poor = 'POOR',
  VeryPoor = 'VERY_POOR'
}

export enum DietType {
  Balanced = 'BALANCED',
  HighProtein = 'HIGH_PROTEIN',
  Keto = 'KETO',
  LowCarb = 'LOW_CARB',
  LowFat = 'LOW_FAT',
  MealReplacement = 'MEAL_REPLACEMENT',
  VeryLowCalorie = 'VERY_LOW_CALORIE'
}

export type DistanceFilterInput = {
  coordinates: LocationInput;
  distance?: InputMaybe<Scalars['Decimal']['input']>;
};

export type Division = Node & {
  __typename?: 'Division';
  created: Scalars['DateTime']['output'];
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  modifiedBy?: Maybe<User>;
  name: Scalars['String']['output'];
  vendorSet: VendorCountableConnection;
};


export type DivisionVendorSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DivisionConnection = {
  __typename?: 'DivisionConnection';
  edges: Array<Maybe<DivisionEdge>>;
  pageInfo: PageInfo;
};

export type DivisionEdge = {
  __typename?: 'DivisionEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<Division>;
};

export type Doctor = Node & {
  __typename?: 'Doctor';
  appointmentSlotTimePeriod: Scalars['Int']['output'];
  appointmentTypes?: Maybe<Array<Maybe<AppointmentTypeEnum>>>;
  appointments?: Maybe<AppointmentCountableConnection>;
  /**
   * returns the minimal info of accepted but not confirmed appointments,
   * check the property suggestedTimeSlotsOverlapped for the value of the booked timeslots
   */
  appointmentsAcceptedOnlyBooked?: Maybe<OverlappedAppointmentCountableConnection>;
  /**
   * returns the minimal info of confirmed appointments,
   * check the properties (confirmedStartTime, confirmedEndTime) for the value of the booked timeslots
   */
  appointmentsConfirmedBooked?: Maybe<OverlappedAppointmentCountableConnection>;
  atHomeVisitPrice?: Maybe<Scalars['Float']['output']>;
  availabilities: DoctorAvailabilityCountableConnection;
  availabilityStatus: DoctorAvailabilityStatusEnum;
  /** needed to send either the values of appointmentId or the values each of (start, end) */
  availableTimeSlots?: Maybe<Array<Maybe<TimeSlot>>>;
  averageRating: Scalars['Float']['output'];
  bio?: Maybe<Scalars['String']['output']>;
  canAcceptCall: Scalars['Boolean']['output'];
  created: Scalars['DateTime']['output'];
  dashboard?: Maybe<DoctorDashboard>;
  experiences?: Maybe<Array<Maybe<DoctorExperience>>>;
  healthLicenseEndDate?: Maybe<Scalars['Date']['output']>;
  healthLicenseNumber?: Maybe<Scalars['String']['output']>;
  healthLicenseStartDate?: Maybe<Scalars['Date']['output']>;
  healthProgramNetworkProviderProfessionals?: Maybe<HealthProgramNetworkProviderProfessionalCountableConnection>;
  id: Scalars['ID']['output'];
  isAddressPublic: Scalars['Boolean']['output'];
  isDateOfBirthPublic: Scalars['Boolean']['output'];
  isLanguagesPublic: Scalars['Boolean']['output'];
  isLicenseNumberPublic: Scalars['Boolean']['output'];
  isMobileNumberPublic: Scalars['Boolean']['output'];
  isNationalIdPublic: Scalars['Boolean']['output'];
  isSecondMobileNumberPublic: Scalars['Boolean']['output'];
  isSocialLinksPublic: Scalars['Boolean']['output'];
  isYearsOfExperiencePublic: Scalars['Boolean']['output'];
  languages?: Maybe<Array<Maybe<Language>>>;
  lastAvailability?: Maybe<DoctorAvailability>;
  modified: Scalars['DateTime']['output'];
  notificationEmail?: Maybe<Scalars['String']['output']>;
  onlineVisitPrice?: Maybe<Scalars['Float']['output']>;
  onsiteVisitPrice?: Maybe<Scalars['Float']['output']>;
  qualifications?: Maybe<Array<Maybe<Qualification>>>;
  ratingsSum: Scalars['Float']['output'];
  secondMobileNumber?: Maybe<Scalars['String']['output']>;
  seniority?: Maybe<DoctorSeniorityEnum>;
  socialLinks?: Maybe<Scalars['JSONString']['output']>;
  specializations?: Maybe<Array<Maybe<DoctorSpecialization>>>;
  totalRatings: Scalars['Int']['output'];
  user?: Maybe<User>;
  vendor: Vendor;
  workingHours?: Maybe<Array<Maybe<WorkingHour>>>;
  yearsOfExperience?: Maybe<Scalars['Int']['output']>;
};


export type DoctorAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AppointmentFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<AppointmentSortingInput>;
};


export type DoctorAppointmentsAcceptedOnlyBookedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['Instant']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Instant']['input']>;
};


export type DoctorAppointmentsConfirmedBookedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['Instant']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Instant']['input']>;
};


export type DoctorAvailabilitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type DoctorAvailableTimeSlotsArgs = {
  end?: InputMaybe<Scalars['Instant']['input']>;
  start?: InputMaybe<Scalars['Instant']['input']>;
};


export type DoctorHealthProgramNetworkProviderProfessionalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<HealthProgramNetworkProviderProfessionalFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type DoctorAcceptAppointmentInput = {
  branchId?: InputMaybe<Scalars['ID']['input']>;
  departmentId?: InputMaybe<Scalars['ID']['input']>;
  paymentInfo?: InputMaybe<AppointmentPaymentRequestForAcceptInput>;
  suggestedTimeSlots: Array<InputMaybe<AppointmentTimeSlotInput>>;
};

export type DoctorAppointment = H_Node & {
  __typename?: 'DoctorAppointment';
  appointmentCategory?: Maybe<AppointmentCategory>;
  branch?: Maybe<Branch>;
  cancellationReason?: Maybe<Scalars['String']['output']>;
  confirmedEndTime?: Maybe<Scalars['Instant']['output']>;
  confirmedStartTime?: Maybe<Scalars['Instant']['output']>;
  consumer?: Maybe<Patient>;
  created?: Maybe<Scalars['Instant']['output']>;
  doctor?: Maybe<Doctor>;
  doctorNote?: Maybe<Scalars['String']['output']>;
  /** The ID of the object. */
  id: Scalars['ID']['output'];
  justification?: Maybe<Scalars['String']['output']>;
  messageToPatient?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['Instant']['output']>;
  rejectionReason?: Maybe<Scalars['String']['output']>;
  requestedTimeSlots?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  status?: Maybe<AppointmentStatus>;
  suggestedTimeSlots?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  type?: Maybe<AppointmentType>;
  vendor?: Maybe<Vendor>;
  visitId?: Maybe<Scalars['ID']['output']>;
};

export type DoctorAppointmentRejectInput = {
  rejectionReason: Scalars['String']['input'];
};

export type DoctorAppointmentRescheduleInput = {
  branchId?: InputMaybe<Scalars['ID']['input']>;
  departmentId?: InputMaybe<Scalars['ID']['input']>;
  paymentInfo?: InputMaybe<AppointmentPaymentRequestForRescheduleInput>;
  suggestedTimeSlots: Array<InputMaybe<AppointmentTimeSlotInput>>;
};

export type DoctorAvailabilitiesSum = {
  __typename?: 'DoctorAvailabilitiesSum';
  doctor?: Maybe<Scalars['ID']['output']>;
  sumOfAvailabilities?: Maybe<Scalars['Float']['output']>;
};

export type DoctorAvailability = Node & {
  __typename?: 'DoctorAvailability';
  doctor: Doctor;
  endTime?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  period?: Maybe<Scalars['Float']['output']>;
  startTime: Scalars['DateTime']['output'];
  status: DoctorAvailabilityStatus;
  vendor: Vendor;
};

export type DoctorAvailabilityCountableConnection = {
  __typename?: 'DoctorAvailabilityCountableConnection';
  edges: Array<DoctorAvailabilityCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type DoctorAvailabilityCountableEdge = {
  __typename?: 'DoctorAvailabilityCountableEdge';
  cursor: Scalars['String']['output'];
  node: DoctorAvailability;
};

export enum DoctorAvailabilityStatus {
  AvailableLevel_1 = 'AVAILABLE_LEVEL_1',
  AvailableLevel_2 = 'AVAILABLE_LEVEL_2',
  Unavailable = 'UNAVAILABLE'
}

export type DoctorAvailabilityStatusChange = {
  __typename?: 'DoctorAvailabilityStatusChange';
  doctor?: Maybe<Doctor>;
};

export enum DoctorAvailabilityStatusEnum {
  AvailableLevel_1 = 'AVAILABLE_LEVEL_1',
  AvailableLevel_2 = 'AVAILABLE_LEVEL_2',
  Unavailable = 'UNAVAILABLE'
}

export type DoctorAvailabilityStatusUpdate = {
  __typename?: 'DoctorAvailabilityStatusUpdate';
  doctorErrors: Array<DoctorError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  result?: Maybe<Scalars['String']['output']>;
};

export type DoctorCallsRatingsConnection = {
  __typename?: 'DoctorCallsRatingsConnection';
  edges: Array<DoctorCallsRatingsEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type DoctorCallsRatingsEdge = {
  __typename?: 'DoctorCallsRatingsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  node: DoctorCallsRatingsResponse;
};

export type DoctorCallsRatingsFilter = {
  department?: InputMaybe<Scalars['String']['input']>;
  doctorName?: InputMaybe<Scalars['String']['input']>;
  minRatingAverage?: InputMaybe<Scalars['Int']['input']>;
  specialization?: InputMaybe<Scalars['String']['input']>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
};

export type DoctorCallsRatingsResponse = {
  __typename?: 'DoctorCallsRatingsResponse';
  doctor?: Maybe<Doctor>;
  ratings?: Maybe<Array<Maybe<RatingAndNumberOfCallsPair>>>;
};

export type DoctorCanAcceptCallUpdate = {
  __typename?: 'DoctorCanAcceptCallUpdate';
  doctorErrors: Array<DoctorError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  result?: Maybe<Scalars['String']['output']>;
};

export type DoctorCountableConnection = {
  __typename?: 'DoctorCountableConnection';
  edges: Array<DoctorCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type DoctorCountableEdge = {
  __typename?: 'DoctorCountableEdge';
  cursor: Scalars['String']['output'];
  node: Doctor;
};

export type DoctorDashboard = {
  __typename?: 'DoctorDashboard';
  numberOfNewRequests?: Maybe<Scalars['Int']['output']>;
  numberOfScheduledCalls?: Maybe<Scalars['Int']['output']>;
  numberOfVisitsPerStatus?: Maybe<Scalars['DictType']['output']>;
  totalEVisits?: Maybe<Scalars['Int']['output']>;
};


export type DoctorDashboardNumberOfVisitsPerStatusArgs = {
  date?: InputMaybe<H_ReportingPeriod>;
};

export type DoctorError = {
  __typename?: 'DoctorError';
  code: DoctorErrorCode;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum DoctorErrorCode {
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type DoctorExperience = Node & {
  __typename?: 'DoctorExperience';
  description?: Maybe<Scalars['String']['output']>;
  doctor: Doctor;
  endDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  jobTitle: Scalars['String']['output'];
  provider: Scalars['String']['output'];
  startDate: Scalars['Date']['output'];
};

export type DoctorExperienceCreate = {
  __typename?: 'DoctorExperienceCreate';
  doctorErrors: Array<DoctorError>;
  doctorExperience?: Maybe<DoctorExperience>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type DoctorExperienceCreateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  jobTitle: Scalars['String']['input'];
  provider: Scalars['String']['input'];
  startDate: Scalars['Date']['input'];
};

export type DoctorExperienceDelete = {
  __typename?: 'DoctorExperienceDelete';
  doctorErrors: Array<DoctorError>;
  doctorExperience?: Maybe<DoctorExperience>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type DoctorExperienceUpdate = {
  __typename?: 'DoctorExperienceUpdate';
  doctorErrors: Array<DoctorError>;
  doctorExperience?: Maybe<DoctorExperience>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type DoctorExperienceUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Date']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Date']['input']>;
};

export type DoctorFilterInput = {
  appointmentAvailableTime?: InputMaybe<DateTimeFilterInput>;
  appointmentTypes?: InputMaybe<Array<InputMaybe<AppointmentTypeEnum>>>;
  availabilityStatus?: InputMaybe<Array<InputMaybe<DoctorAvailabilityStatusEnum>>>;
  branch?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  canAcceptCall?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['ID']['input']>;
  departmentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  experience?: InputMaybe<Scalars['String']['input']>;
  fromAtHomeVisitPrice?: InputMaybe<Scalars['Float']['input']>;
  fromOnlineVisitPrice?: InputMaybe<Scalars['Float']['input']>;
  fromOnsiteVisitPrice?: InputMaybe<Scalars['Float']['input']>;
  gender?: InputMaybe<PersonGenderEnum>;
  healthConditions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  licenseNumber?: InputMaybe<Scalars['String']['input']>;
  licenseNumber_Icontains?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<DistanceFilterInput>;
  meetingPlatformId?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  networkId?: InputMaybe<Scalars['Float']['input']>;
  networkName?: InputMaybe<Scalars['String']['input']>;
  programId?: InputMaybe<Scalars['Float']['input']>;
  programName?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<DecimalRangeInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  specializations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  toAtHomeVisitPrice?: InputMaybe<Scalars['Float']['input']>;
  toOnlineVisitPrice?: InputMaybe<Scalars['Float']['input']>;
  toOnsiteVisitPrice?: InputMaybe<Scalars['Float']['input']>;
  vendor?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  yearsOfExperience?: InputMaybe<IntRangeInput>;
};

export type DoctorHealthProgramPointsFilterInput = {
  doctorId: Scalars['String']['input'];
  fromDate: Scalars['String']['input'];
  healthProgramId: Scalars['Int']['input'];
  toDate: Scalars['String']['input'];
};

export type DoctorHealthProgramTotalPointsTrackingConnection = {
  __typename?: 'DoctorHealthProgramTotalPointsTrackingConnection';
  edges: Array<DoctorHealthProgramTotalPointsTrackingCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type DoctorHealthProgramTotalPointsTrackingCountableEdge = {
  __typename?: 'DoctorHealthProgramTotalPointsTrackingCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  node: DoctorHealthProgramTotalPointsTrackingResponse;
};

export type DoctorHealthProgramTotalPointsTrackingResponse = {
  __typename?: 'DoctorHealthProgramTotalPointsTrackingResponse';
  availabilityRatingFactor?: Maybe<Scalars['Float']['output']>;
  callQualityRatingFactor?: Maybe<Scalars['Float']['output']>;
  doctor?: Maybe<Doctor>;
  mainSpecializationsRateFactor?: Maybe<Scalars['Float']['output']>;
  pointsDuringNormalDemand?: Maybe<Scalars['Float']['output']>;
  pointsDuringUrgentDemand?: Maybe<Scalars['Float']['output']>;
  totalPoints?: Maybe<Scalars['Int']['output']>;
};

export type DoctorInput = {
  appointmentSlotTimePeriod?: InputMaybe<Scalars['Int']['input']>;
  appointmentTypes?: InputMaybe<Array<InputMaybe<AppointmentTypeEnum>>>;
  atHomeVisitPrice?: InputMaybe<Scalars['Float']['input']>;
  bio?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  healthLicenseEndDate?: InputMaybe<Scalars['Date']['input']>;
  healthLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  healthLicenseStartDate?: InputMaybe<Scalars['Date']['input']>;
  isAddressPublic?: InputMaybe<Scalars['Boolean']['input']>;
  isDateOfBirthPublic?: InputMaybe<Scalars['Boolean']['input']>;
  isLanguagesPublic?: InputMaybe<Scalars['Boolean']['input']>;
  isLicenseNumberPublic?: InputMaybe<Scalars['Boolean']['input']>;
  isMobileNumberPublic?: InputMaybe<Scalars['Boolean']['input']>;
  isNationalIdPublic?: InputMaybe<Scalars['Boolean']['input']>;
  isSecondMobileNumberPublic?: InputMaybe<Scalars['Boolean']['input']>;
  isSocialLinksPublic?: InputMaybe<Scalars['Boolean']['input']>;
  isYearsOfExperiencePublic?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notificationEmail?: InputMaybe<Scalars['String']['input']>;
  onlineVisitPrice?: InputMaybe<Scalars['Float']['input']>;
  onsiteVisitPrice?: InputMaybe<Scalars['Float']['input']>;
  photo?: InputMaybe<Scalars['String']['input']>;
  qualifications?: InputMaybe<Array<InputMaybe<QualificationInput>>>;
  secondMobileNumber?: InputMaybe<Scalars['String']['input']>;
  seniority?: InputMaybe<DoctorSeniorityEnum>;
  socialLinks?: InputMaybe<Array<Scalars['String']['input']>>;
  specializations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  workingHours?: InputMaybe<Array<InputMaybe<WorkingHourInput>>>;
  yearsOfExperience?: InputMaybe<Scalars['Int']['input']>;
};

export type DoctorNurseAssignment = H_Node & {
  __typename?: 'DoctorNurseAssignment';
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  department?: Maybe<Department>;
  doctor?: Maybe<Doctor>;
  id: Scalars['ID']['output'];
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['Instant']['output']>;
  nurseUser?: Maybe<User>;
  vendor?: Maybe<Vendor>;
};

export type DoctorNurseAssignmentCountableConnection = {
  __typename?: 'DoctorNurseAssignmentCountableConnection';
  edges: Array<DoctorNurseAssignmentCountableEdge>;
  pageInfo: H_PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type DoctorNurseAssignmentCountableEdge = {
  __typename?: 'DoctorNurseAssignmentCountableEdge';
  cursor: Scalars['String']['output'];
  node: DoctorNurseAssignment;
};

export type DoctorNurseAssignmentFilterInput = {
  departments?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  doctors?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  nurseUsers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  vendors?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type DoctorNurseAssignmentInput = {
  departmentId: Scalars['ID']['input'];
  doctorId: Scalars['ID']['input'];
};

export type DoctorOrder = {
  direction: OrderDirection;
  field?: InputMaybe<DoctorOrderField>;
};

export enum DoctorOrderField {
  Created = 'CREATED',
  Distance = 'DISTANCE',
  Rating = 'RATING'
}

export type DoctorProfileInput = {
  appointmentSlotTimePeriod?: InputMaybe<Scalars['Int']['input']>;
  appointmentTypes?: InputMaybe<Array<InputMaybe<AppointmentTypeEnum>>>;
  bio?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  isAddressPublic?: InputMaybe<Scalars['Boolean']['input']>;
  isDateOfBirthPublic?: InputMaybe<Scalars['Boolean']['input']>;
  isLanguagesPublic?: InputMaybe<Scalars['Boolean']['input']>;
  isMobileNumberPublic?: InputMaybe<Scalars['Boolean']['input']>;
  isSocialLinksPublic?: InputMaybe<Scalars['Boolean']['input']>;
  isYearsOfExperiencePublic?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notificationEmail?: InputMaybe<Scalars['String']['input']>;
  photo?: InputMaybe<Scalars['String']['input']>;
  qualifications?: InputMaybe<Array<InputMaybe<QualificationInput>>>;
  seniority?: InputMaybe<DoctorSeniorityEnum>;
  socialLinks?: InputMaybe<Array<Scalars['String']['input']>>;
  specializations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  workingHours?: InputMaybe<Array<InputMaybe<WorkingHourInput>>>;
  yearsOfExperience?: InputMaybe<Scalars['Int']['input']>;
};

export type DoctorProfileUpdate = {
  __typename?: 'DoctorProfileUpdate';
  doctor?: Maybe<Doctor>;
  doctorErrors: Array<DoctorError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type DoctorRating = {
  __typename?: 'DoctorRating';
  doctor?: Maybe<Doctor>;
  doctorErrors: Array<DoctorError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type DoctorRatingInput = {
  action: RatingActionEnum;
  doctor: Scalars['ID']['input'];
  oldRating?: InputMaybe<Scalars['Decimal']['input']>;
  rating: Scalars['Decimal']['input'];
};

export enum DoctorSeniorityEnum {
  Cp = 'CP',
  Gp = 'GP',
  Sp = 'SP'
}

export type DoctorSpecialization = Node & {
  __typename?: 'DoctorSpecialization';
  arabicDisplay?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  display?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

/** doctor reject assigned call */
export type DoctorVisitReject = {
  __typename?: 'DoctorVisitReject';
  doctorVisitRejectionAudit?: Maybe<DoctorVisitRejectionAudit>;
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  visitErrors?: Maybe<Array<H_EntityError>>;
};

export type DoctorVisitRejectInput = {
  reason: Scalars['String']['input'];
  visit: Scalars['ID']['input'];
};

export type DoctorVisitRejectionAudit = H_Node & {
  __typename?: 'DoctorVisitRejectionAudit';
  created?: Maybe<Scalars['Instant']['output']>;
  doctorId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  modified?: Maybe<Scalars['Instant']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  visit?: Maybe<Visit>;
};

export type DoctorVisitRejectionAuditCountableConnection = {
  __typename?: 'DoctorVisitRejectionAuditCountableConnection';
  edges: Array<DoctorVisitRejectionAuditCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type DoctorVisitRejectionAuditCountableEdge = {
  __typename?: 'DoctorVisitRejectionAuditCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: DoctorVisitRejectionAudit;
};

export type DoctorVisitRejectionAuditFilterInput = {
  visits?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type DocumentStore = {
  __typename?: 'DocumentStore';
  code: Scalars['String']['output'];
  createdAt: Scalars['Instant']['output'];
  description?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['Instant']['output'];
};

export type DocumentStoreConnection = {
  __typename?: 'DocumentStoreConnection';
  edges: Array<DocumentStoreEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type DocumentStoreEdge = {
  __typename?: 'DocumentStoreEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: DocumentStore;
};

/** Create a new EarliestVisit */
export type EarliestVisitCreate = {
  __typename?: 'EarliestVisitCreate';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  visit?: Maybe<Visit>;
  visitErrors?: Maybe<Array<H_EntityError>>;
};

export type EarliestVisitCreateInput = {
  healthProgram: Scalars['ID']['input'];
  patientNote?: InputMaybe<Scalars['String']['input']>;
  paymentReceiptIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export enum EducationLevel {
  HighSchoolGraduate = 'HIGH_SCHOOL_GRADUATE',
  LessThanHighSchool = 'LESS_THAN_HIGH_SCHOOL',
  UniversityGraduate = 'UNIVERSITY_GRADUATE'
}

export enum EncounterType {
  AmbulanceAirOrWater = 'AMBULANCE_AIR_OR_WATER',
  AmbulanceLand = 'AMBULANCE_LAND',
  AssistedLivingFacility = 'ASSISTED_LIVING_FACILITY',
  DaycaseBedEr = 'DAYCASE_BED_ER',
  DaycaseBedNoEr = 'DAYCASE_BED_NO_ER',
  Home = 'HOME',
  InpatientBedEr = 'INPATIENT_BED_ER',
  InpatientBedNoEr = 'INPATIENT_BED_NO_ER',
  MobileUnit = 'MOBILE_UNIT',
  NationalsScreening = 'NATIONALS_SCREENING',
  NewVisaScreening = 'NEW_VISA_SCREENING',
  NoBedEr = 'NO_BED_ER',
  NoBedNoEr = 'NO_BED_NO_ER',
  RenewalVisaScreening = 'RENEWAL_VISA_SCREENING',
  Telehealth = 'TELEHEALTH'
}

export type EntityInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export enum EntityType {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Id = 'ID',
  List = 'LIST',
  Number = 'NUMBER',
  String = 'STRING'
}

export type Error = {
  __typename?: 'Error';
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum ErrorDetail {
  /**
   * The deadline expired before the operation could complete.
   *
   * For operations that change the state of the system, this error
   * may be returned even if the operation has completed successfully.
   * For example, a successful response from a server could have been
   * delayed long enough for the deadline to expire.
   *
   * HTTP Mapping: 504 Gateway Timeout
   * Error Type: UNAVAILABLE
   */
  DeadlineExceeded = 'DEADLINE_EXCEEDED',
  /**
   * The server detected that the client is exhibiting a behavior that
   * might be generating excessive load.
   *
   * HTTP Mapping: 429 Too Many Requests or 420 Enhance Your Calm
   * Error Type: UNAVAILABLE
   */
  EnhanceYourCalm = 'ENHANCE_YOUR_CALM',
  /**
   * The requested field is not found in the schema.
   *
   * This differs from `NOT_FOUND` in that `NOT_FOUND` should be used when a
   * query is valid, but is unable to return a result (if, for example, a
   * specific video id doesn't exist). `FIELD_NOT_FOUND` is intended to be
   * returned by the server to signify that the requested field is not known to exist.
   * This may be returned in lieu of failing the entire query.
   * See also `PERMISSION_DENIED` for cases where the
   * requested field is invalid only for the given user or class of users.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: BAD_REQUEST
   */
  FieldNotFound = 'FIELD_NOT_FOUND',
  /**
   * The client specified an invalid argument.
   *
   * Note that this differs from `FAILED_PRECONDITION`.
   * `INVALID_ARGUMENT` indicates arguments that are problematic
   * regardless of the state of the system (e.g., a malformed file name).
   *
   * HTTP Mapping: 400 Bad Request
   * Error Type: BAD_REQUEST
   */
  InvalidArgument = 'INVALID_ARGUMENT',
  /**
   * The provided cursor is not valid.
   *
   * The most common usage for this error is when a client is paginating
   * through a list that uses stateful cursors. In that case, the provided
   * cursor may be expired.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: NOT_FOUND
   */
  InvalidCursor = 'INVALID_CURSOR',
  /**
   * Unable to perform operation because a required resource is missing.
   *
   * Example: Client is attempting to refresh a list, but the specified
   * list is expired. This requires an action by the client to get a new list.
   *
   * If the user is simply trying GET a resource that is not found,
   * use the NOT_FOUND error type. FAILED_PRECONDITION.MISSING_RESOURCE
   * is to be used particularly when the user is performing an operation
   * that requires a particular resource to exist.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   * Error Type: FAILED_PRECONDITION
   */
  MissingResource = 'MISSING_RESOURCE',
  /**
   * Service Error.
   *
   * There is a problem with an upstream service.
   *
   * This may be returned if a gateway receives an unknown error from a service
   * or if a service is unreachable.
   * If a request times out which waiting on a response from a service,
   * `DEADLINE_EXCEEDED` may be returned instead.
   * If a service returns a more specific error Type, the specific error Type may
   * be returned instead.
   *
   * HTTP Mapping: 502 Bad Gateway
   * Error Type: UNAVAILABLE
   */
  ServiceError = 'SERVICE_ERROR',
  /**
   * Request failed due to network errors.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  TcpFailure = 'TCP_FAILURE',
  /**
   * Request throttled based on server concurrency limits.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  ThrottledConcurrency = 'THROTTLED_CONCURRENCY',
  /**
   * Request throttled based on server CPU limits
   *
   * HTTP Mapping: 503 Unavailable.
   * Error Type: UNAVAILABLE
   */
  ThrottledCpu = 'THROTTLED_CPU',
  /**
   * The operation is not implemented or is not currently supported/enabled.
   *
   * HTTP Mapping: 501 Not Implemented
   * Error Type: BAD_REQUEST
   */
  Unimplemented = 'UNIMPLEMENTED',
  /**
   * Unknown error.
   *
   * This error should only be returned when no other error detail applies.
   * If a client sees an unknown errorDetail, it will be interpreted as UNKNOWN.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Unknown = 'UNKNOWN'
}

export enum ErrorType {
  /**
   * Bad Request.
   *
   * There is a problem with the request.
   * Retrying the same request is not likely to succeed.
   * An example would be a query or argument that cannot be deserialized.
   *
   * HTTP Mapping: 400 Bad Request
   */
  BadRequest = 'BAD_REQUEST',
  /**
   * The operation was rejected because the system is not in a state
   * required for the operation's execution.  For example, the directory
   * to be deleted is non-empty, an rmdir operation is applied to
   * a non-directory, etc.
   *
   * Service implementers can use the following guidelines to decide
   * between `FAILED_PRECONDITION` and `UNAVAILABLE`:
   *
   * - Use `UNAVAILABLE` if the client can retry just the failing call.
   * - Use `FAILED_PRECONDITION` if the client should not retry until
   * the system state has been explicitly fixed.  E.g., if an "rmdir"
   *      fails because the directory is non-empty, `FAILED_PRECONDITION`
   * should be returned since the client should not retry unless
   * the files are deleted from the directory.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   */
  FailedPrecondition = 'FAILED_PRECONDITION',
  /**
   * Internal error.
   *
   * An unexpected internal error was encountered. This means that some
   * invariants expected by the underlying system have been broken.
   * This error code is reserved for serious errors.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  Internal = 'INTERNAL',
  /**
   * The requested entity was not found.
   *
   * This could apply to a resource that has never existed (e.g. bad resource id),
   * or a resource that no longer exists (e.g. cache expired.)
   *
   * Note to server developers: if a request is denied for an entire class
   * of users, such as gradual feature rollout or undocumented allowlist,
   * `NOT_FOUND` may be used. If a request is denied for some users within
   * a class of users, such as user-based access control, `PERMISSION_DENIED`
   * must be used.
   *
   * HTTP Mapping: 404 Not Found
   */
  NotFound = 'NOT_FOUND',
  /**
   * The caller does not have permission to execute the specified
   * operation.
   *
   * `PERMISSION_DENIED` must not be used for rejections
   * caused by exhausting some resource or quota.
   * `PERMISSION_DENIED` must not be used if the caller
   * cannot be identified (use `UNAUTHENTICATED`
   * instead for those errors).
   *
   * This error Type does not imply the
   * request is valid or the requested entity exists or satisfies
   * other pre-conditions.
   *
   * HTTP Mapping: 403 Forbidden
   */
  PermissionDenied = 'PERMISSION_DENIED',
  /**
   * The request does not have valid authentication credentials.
   *
   * This is intended to be returned only for routes that require
   * authentication.
   *
   * HTTP Mapping: 401 Unauthorized
   */
  Unauthenticated = 'UNAUTHENTICATED',
  /**
   * Currently Unavailable.
   *
   * The service is currently unavailable.  This is most likely a
   * transient condition, which can be corrected by retrying with
   * a backoff.
   *
   * HTTP Mapping: 503 Unavailable
   */
  Unavailable = 'UNAVAILABLE',
  /**
   * Unknown error.
   *
   * For example, this error may be returned when
   * an error code received from another address space belongs to
   * an error space that is not known in this address space.  Also
   * errors raised by APIs that do not return enough error information
   * may be converted to this error.
   *
   * If a client sees an unknown errorType, it will be interpreted as UNKNOWN.
   * Unknown errors MUST NOT trigger any special behavior. These MAY be treated
   * by an implementation as being equivalent to INTERNAL.
   *
   * When possible, a more specific error should be provided.
   *
   * HTTP Mapping: 520 Unknown Error
   */
  Unknown = 'UNKNOWN'
}

export type EvaluateProgramRiskFactorTemplateInput = {
  memberId?: InputMaybe<Scalars['ID']['input']>;
  programId: Scalars['ID']['input'];
  result: Scalars['String']['input'];
  visitId?: InputMaybe<Scalars['ID']['input']>;
};

export type EvaluatedLabTemplate = {
  __typename?: 'EvaluatedLabTemplate';
  arabicDisplay?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['String']['output']>;
  display?: Maybe<Scalars['String']['output']>;
  fields?: Maybe<Array<Maybe<EvaluatedTemplateLabField>>>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
};

export type EvaluatedTemplateLabField = {
  __typename?: 'EvaluatedTemplateLabField';
  allowedValues?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  arabicDisplay?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['String']['output']>;
  defaultValue?: Maybe<Scalars['String']['output']>;
  display?: Maybe<Scalars['String']['output']>;
  maxNormalRangeValue?: Maybe<Scalars['Float']['output']>;
  minNormalRangeValue?: Maybe<Scalars['Float']['output']>;
  referenceRange?: Maybe<Scalars['String']['output']>;
  standard?: Maybe<FieldStandard>;
  type?: Maybe<TemplateFieldType>;
  unit?: Maybe<FieldUnit>;
};

export type ExclusionOption = H_Node & {
  __typename?: 'ExclusionOption';
  created?: Maybe<Scalars['Instant']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  healthPrograms?: Maybe<HealthProgramCountableConnection>;
  id: Scalars['ID']['output'];
  modified?: Maybe<Scalars['Instant']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};


export type ExclusionOptionHealthProgramsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** CRUD a new Exclusion Option */
export type ExclusionOptionCrud = {
  __typename?: 'ExclusionOptionCRUD';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  exclusionOption?: Maybe<ExclusionOption>;
  healthProgramErrors?: Maybe<Array<H_EntityError>>;
};

export type ExclusionOptionInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Exercise = {
  __typename?: 'Exercise';
  exerciseDuration: Scalars['Int']['output'];
  exerciseDurationUnit: ExerciseDurationUnit;
  exerciseFrequencyDayWeek: Scalars['Int']['output'];
  exerciseType: ExerciseType;
  id: Scalars['ID']['output'];
};

export enum ExerciseDurationUnit {
  Hours = 'HOURS',
  Minutes = 'MINUTES'
}

export enum ExerciseType {
  BodyweightExercises = 'BODYWEIGHT_EXERCISES',
  Cycling = 'CYCLING',
  Dancing = 'DANCING',
  Pilates = 'PILATES',
  ResistanceBandExercises = 'RESISTANCE_BAND_EXERCISES',
  StretchingRoutines = 'STRETCHING_ROUTINES',
  Swimming = 'SWIMMING',
  Walking = 'WALKING',
  WaterAerobics = 'WATER_AEROBICS',
  Weightlifting = 'WEIGHTLIFTING',
  Yoga = 'YOGA'
}

export type ExtraBenefit = H_Node & {
  __typename?: 'ExtraBenefit';
  created?: Maybe<Scalars['Instant']['output']>;
  id: Scalars['ID']['output'];
  modified?: Maybe<Scalars['Instant']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** CRUD a new Extra Benefit */
export type ExtraBenefitCrud = {
  __typename?: 'ExtraBenefitCRUD';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  extraBenefit?: Maybe<ExtraBenefit>;
  healthProgramErrors?: Maybe<Array<H_EntityError>>;
};

export type ExtraBenefitComplex = H_Node & {
  __typename?: 'ExtraBenefitComplex';
  created?: Maybe<Scalars['Instant']['output']>;
  extraBenefit?: Maybe<ExtraBenefit>;
  healthChannels?: Maybe<Array<Maybe<HealthChannel>>>;
  id: Scalars['ID']['output'];
  modified?: Maybe<Scalars['Instant']['output']>;
};

export type ExtraBenefitInput = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  activityTrackerFeatureEnabled: Scalars['Boolean']['output'];
  addMemberCardFeatureEnabled: Scalars['Boolean']['output'];
  addNationalIdFeatureEnabled: Scalars['Boolean']['output'];
  communityFeatureEnabled: Scalars['Boolean']['output'];
  customerRegistrationFeatureEnabled: Scalars['Boolean']['output'];
  deleteCustomerAccountFeatureEnabled: Scalars['Boolean']['output'];
  dependentCreationFeatureEnabled: Scalars['Boolean']['output'];
  enayaIntegrationFeatureEnabled: Scalars['Boolean']['output'];
  familySyncFeatureEnabled: Scalars['Boolean']['output'];
  healthPackageFeatureEnabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  inPersonCheckinFeatureEnabled: Scalars['Boolean']['output'];
  interventionFeatureEnabled: Scalars['Boolean']['output'];
  iotAddDeviceFeatureEnabled: Scalars['Boolean']['output'];
  knowledgeHubFeatureEnabled: Scalars['Boolean']['output'];
  marketplaceFeatureEnabled: Scalars['Boolean']['output'];
  ssoFeatureEnabled: Scalars['Boolean']['output'];
  transcriptionFeatureEnabled: Scalars['Boolean']['output'];
  uaePassFeatureEnabled: Scalars['Boolean']['output'];
};

export type Field = {
  __typename?: 'Field';
  allowedValues?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  category?: Maybe<FieldCategory>;
  code?: Maybe<Scalars['String']['output']>;
  display?: Maybe<Scalars['String']['output']>;
  fieldType?: Maybe<FieldType>;
  id?: Maybe<Scalars['ID']['output']>;
  operators?: Maybe<Array<Maybe<Operator>>>;
  standard?: Maybe<FieldStandard>;
  unit?: Maybe<FieldUnit>;
};

export type FieldCategory = {
  __typename?: 'FieldCategory';
  arabicDisplay?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  display?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type FieldCategoryConnection = {
  __typename?: 'FieldCategoryConnection';
  edges?: Maybe<Array<Maybe<FieldCategoryEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type FieldCategoryEdge = {
  __typename?: 'FieldCategoryEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<FieldCategory>;
};

export type FieldCategoryInput = {
  id: Scalars['ID']['input'];
};

export type FieldInput = {
  id: Scalars['ID']['input'];
};

export enum FieldStandard {
  Cpt = 'CPT',
  Loinc = 'LOINC'
}

export type FieldType = {
  __typename?: 'FieldType';
  id?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<EntityType>;
};

export type FieldTypeInput = {
  type: EntityType;
};

export type FieldUnit = {
  __typename?: 'FieldUnit';
  arabicDisplay?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  display?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type FieldUnitInput = {
  id: Scalars['ID']['input'];
};

export enum FileType {
  Csv = 'CSV',
  Pdf = 'PDF',
  Txt = 'TXT'
}

export type FitnessCoach = Node & {
  __typename?: 'FitnessCoach';
  created: Scalars['DateTime']['output'];
  healthLicenseEndDate?: Maybe<Scalars['Date']['output']>;
  healthLicenseNumber?: Maybe<Scalars['String']['output']>;
  healthLicenseStartDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type FitnessCoachInput = {
  healthLicenseEndDate?: InputMaybe<Scalars['Date']['input']>;
  healthLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  healthLicenseStartDate?: InputMaybe<Scalars['Date']['input']>;
};

export enum FlowiseIntegrationChatSortingField {
  Code = 'CODE',
  Created = 'CREATED',
  Description = 'DESCRIPTION',
  Name = 'NAME',
  Updated = 'UPDATED'
}

export enum FlowrseIntegrationSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type FraminghamRiskInput = {
  age: Scalars['Int']['input'];
  gender: Gender;
  hdlCholesterol: Scalars['Float']['input'];
  smoker: Scalars['Boolean']['input'];
  systolicBp: Scalars['Float']['input'];
  totalCholesterol: Scalars['Float']['input'];
  treatedForBp: Scalars['Int']['input'];
};

export type FraminghamRiskInputLog = {
  __typename?: 'FraminghamRiskInputLog';
  age: Scalars['Int']['output'];
  gender: Gender;
  hdlCholesterol: Scalars['Float']['output'];
  smoker: Scalars['Boolean']['output'];
  systolicBp: Scalars['Float']['output'];
  totalCholesterol: Scalars['Float']['output'];
  treatedForBp: Scalars['Int']['output'];
};

export type FraminghamRiskResponse = {
  __typename?: 'FraminghamRiskResponse';
  riskPercentage?: Maybe<Scalars['Float']['output']>;
};

export type FraminghamRiskResult = {
  __typename?: 'FraminghamRiskResult';
  createdDate?: Maybe<Scalars['Instant']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  queryInput?: Maybe<FraminghamRiskInputLog>;
  riskPercentage?: Maybe<Scalars['Double']['output']>;
};

export type FraminghamRiskResultConnection = {
  __typename?: 'FraminghamRiskResultConnection';
  edges: Array<FraminghamRiskResultEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type FraminghamRiskResultEdge = {
  __typename?: 'FraminghamRiskResultEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: FraminghamRiskResult;
};

export type FromGlobalId = {
  __typename?: 'FromGlobalId';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  pk?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type GeneralResponse = {
  __typename?: 'GeneralResponse';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type GenericDrug = {
  __typename?: 'GenericDrug';
  code: Scalars['String']['output'];
  display: Scalars['String']['output'];
  routeOfAdmin?: Maybe<Scalars['String']['output']>;
};

export type GenericDrugDeliveryEligibility = {
  __typename?: 'GenericDrugDeliveryEligibility';
  deliveryTypes?: Maybe<Array<Maybe<DeliveryType>>>;
  genericDrug?: Maybe<Scalars['String']['output']>;
};

export type GenericDrugProperties = {
  __typename?: 'GenericDrugProperties';
  isHazardous?: Maybe<Scalars['Boolean']['output']>;
  isHighAlert?: Maybe<Scalars['Boolean']['output']>;
  isLASA?: Maybe<Scalars['Boolean']['output']>;
};

export type GoalWeightInput = {
  bee: Scalars['Float']['input'];
  days: Scalars['Int']['input'];
  goalWeight: Scalars['Float']['input'];
  newActivityLevel: ActivityLevel;
  weight: Scalars['Float']['input'];
};

export type GoalWeightInputLog = {
  __typename?: 'GoalWeightInputLog';
  bee: Scalars['Float']['output'];
  days: Scalars['Int']['output'];
  goalWeight: Scalars['Float']['output'];
  newActivityLevel: ActivityLevel;
  weight: Scalars['Float']['output'];
};

export type GoalWeightResponse = {
  __typename?: 'GoalWeightResponse';
  caloriesPerDayToAdjust?: Maybe<Scalars['Float']['output']>;
  isWeightLoss?: Maybe<Scalars['Boolean']['output']>;
  newCalorieIntake?: Maybe<Scalars['Float']['output']>;
  newTDEE?: Maybe<Scalars['Float']['output']>;
};

export type GoalWeightResult = {
  __typename?: 'GoalWeightResult';
  caloriesPerDayToAdjust?: Maybe<Scalars['Double']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isWeightLoss?: Maybe<Scalars['Boolean']['output']>;
  newCalorieIntake?: Maybe<Scalars['Double']['output']>;
  newTDEE?: Maybe<Scalars['Double']['output']>;
  queryInput?: Maybe<GoalWeightInputLog>;
};

export type GoalWeightResultConnection = {
  __typename?: 'GoalWeightResultConnection';
  edges: Array<GoalWeightResultEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type GoalWeightResultEdge = {
  __typename?: 'GoalWeightResultEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: GoalWeightResult;
};

export type Group = Node & {
  __typename?: 'Group';
  groupConfiguration?: Maybe<GroupConfiguration>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  permissions?: Maybe<Array<Maybe<Permission>>>;
  userCanManage: Scalars['Boolean']['output'];
  users?: Maybe<Array<Maybe<User>>>;
};

export type GroupConfiguration = {
  __typename?: 'GroupConfiguration';
  created?: Maybe<Scalars['DateTime']['output']>;
  groupType?: Maybe<AppTypes>;
  isEditable?: Maybe<Scalars['Boolean']['output']>;
  isGlobal?: Maybe<Scalars['Boolean']['output']>;
  keycloakGroupId?: Maybe<Scalars['String']['output']>;
  vendor?: Maybe<Vendor>;
};

export type GroupConfigurationInput = {
  groupType: AppTypes;
  isEditable?: InputMaybe<Scalars['Boolean']['input']>;
  isGlobal?: InputMaybe<Scalars['Boolean']['input']>;
  vendor?: InputMaybe<Scalars['ID']['input']>;
};

export type GroupConfigurationUpdateInput = {
  isEditable?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GroupCountableConnection = {
  __typename?: 'GroupCountableConnection';
  edges: Array<GroupCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type GroupCountableEdge = {
  __typename?: 'GroupCountableEdge';
  cursor: Scalars['String']['output'];
  node: Group;
};

export enum GuidedCareActivityType {
  FacilityVisit = 'FACILITY_VISIT',
  HomeVisit = 'HOME_VISIT',
  Lab = 'LAB',
  MedicalForm = 'MEDICAL_FORM',
  MedicalMessageGeneral = 'MEDICAL_MESSAGE_GENERAL',
  MedicalMessageInstruction = 'MEDICAL_MESSAGE_INSTRUCTION',
  MedicalMessageMedical = 'MEDICAL_MESSAGE_MEDICAL',
  MedicalMessageMonitoring = 'MEDICAL_MESSAGE_MONITORING',
  MedicalMessageReminder = 'MEDICAL_MESSAGE_REMINDER',
  OnlineVisit = 'ONLINE_VISIT',
  Rad = 'RAD'
}

export enum GuidedCareCareJourneyItemStatus {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Missed = 'MISSED',
  OnHold = 'ON_HOLD',
  Pending = 'PENDING',
  Received = 'RECEIVED',
  Rejected = 'REJECTED',
  Rescheduled = 'RESCHEDULED'
}

export type GuidedCareHealthProgram = H_Node & {
  __typename?: 'GuidedCareHealthProgram';
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  guidedCareHealthProgramMemberLists?: Maybe<Array<Maybe<HealthProgramMemberList>>>;
  guidedCareHealthProgramTemplate?: Maybe<HealthProgramTemplate>;
  guidedCareType?: Maybe<GuidedCareType>;
  id: Scalars['ID']['output'];
  insuranceNetworks?: Maybe<Array<Maybe<HealthProgramNetwork>>>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isLatestVersion?: Maybe<Scalars['Boolean']['output']>;
  lastModifiedDate?: Maybe<Scalars['Instant']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  payer?: Maybe<Payer>;
  price?: Maybe<Scalars['Int']['output']>;
  programActivities?: Maybe<Array<Maybe<GuidedCareHealthProgramActivity>>>;
  teamMembers?: Maybe<Array<Maybe<GuidedCareProgramTeamMember>>>;
};

export type GuidedCareHealthProgramActivity = {
  __typename?: 'GuidedCareHealthProgramActivity';
  afterCallMessage?: Maybe<MedicalMessage>;
  afterCallMessageOffsetDays?: Maybe<Scalars['Int']['output']>;
  beforeCallMessage?: Maybe<MedicalMessage>;
  beforeCallMessageOffsetDays?: Maybe<Scalars['Int']['output']>;
  frequencyType: AppointmentFrequencyType;
  guidedCareProgramActivityItems?: Maybe<Array<Maybe<GuidedCareProgramActivityItem>>>;
  guidedCareProgramTeamMember?: Maybe<GuidedCareProgramTeamMember>;
  id?: Maybe<Scalars['ID']['output']>;
  numberOfOccurrences: Scalars['Int']['output'];
  serviceOfGuidedCareHealthProgramDetails?: Maybe<Array<Maybe<ServiceDetails>>>;
  templateActivity?: Maybe<TemplateActivity>;
  templateActivityType?: Maybe<GuidedCareActivityType>;
};

export type GuidedCareHealthProgramActivityCrud = {
  __typename?: 'GuidedCareHealthProgramActivityCRUD';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  guidedCareHealthProgramActivity?: Maybe<GuidedCareHealthProgramActivity>;
  guidedCareHealthProgramActivityErrors?: Maybe<Array<H_EntityError>>;
};

export type GuidedCareHealthProgramActivityInput = {
  afterCallMessageId?: InputMaybe<Scalars['ID']['input']>;
  afterCallMessageOffsetDays?: InputMaybe<Scalars['Int']['input']>;
  beforeCallMessageId?: InputMaybe<Scalars['ID']['input']>;
  beforeCallMessageOffsetDays?: InputMaybe<Scalars['Int']['input']>;
  frequencyType: AppointmentFrequencyType;
  guidedCareProgramTeamMember?: InputMaybe<ActivityGuidedCareProgramTeamMemberInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isModified?: InputMaybe<Scalars['Boolean']['input']>;
  numberOfOccurrences: Scalars['Int']['input'];
  serviceOfGuidedCareHealthProgramDetails?: InputMaybe<Array<InputMaybe<ServiceDetailsInput>>>;
  templateActivity?: InputMaybe<TemplateActivityInput>;
  templateActivityType?: InputMaybe<GuidedCareActivityType>;
};

export type GuidedCareHealthProgramActivityItemInput = {
  afterCallMessageId?: InputMaybe<Scalars['ID']['input']>;
  afterCallMessageOffsetDays?: InputMaybe<Scalars['Int']['input']>;
  beforeCallMessageId?: InputMaybe<Scalars['ID']['input']>;
  beforeCallMessageOffsetDays?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  offsetInDays?: InputMaybe<Scalars['Int']['input']>;
  timeOfDay?: InputMaybe<TimeOfDay>;
};

export type GuidedCareHealthProgramCrud = {
  __typename?: 'GuidedCareHealthProgramCRUD';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  guidedCareHealthProgram?: Maybe<GuidedCareHealthProgram>;
  guidedCareHealthProgramErrors?: Maybe<Array<H_EntityError>>;
};

export type GuidedCareHealthProgramCountableConnection = {
  __typename?: 'GuidedCareHealthProgramCountableConnection';
  edges: Array<GuidedCareHealthProgramCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type GuidedCareHealthProgramCountableEdge = {
  __typename?: 'GuidedCareHealthProgramCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: GuidedCareHealthProgram;
};

export type GuidedCareHealthProgramFilter = {
  careTeams?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  guidedCareType?: InputMaybe<GuidedCareType>;
  healthConditionId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  insuranceNetworks?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  memberLists?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  payerId?: InputMaybe<Scalars['ID']['input']>;
  price?: InputMaybe<H_PriceRangeInput>;
  providerId?: InputMaybe<Scalars['ID']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
  templateName?: InputMaybe<Scalars['String']['input']>;
};

export type GuidedCareHealthProgramInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  guidedCareHealthProgramMemberLists?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  guidedCareType?: InputMaybe<GuidedCareType>;
  insuranceNetworks?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name: Scalars['String']['input'];
  payerId?: InputMaybe<Scalars['ID']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  programActivities?: InputMaybe<Array<InputMaybe<GuidedCareHealthProgramActivityInput>>>;
  teamMembers?: InputMaybe<Array<InputMaybe<GuidedCareProgramTeamMemberInput>>>;
  templateName: Scalars['String']['input'];
};

export type GuidedCareHealthProgramRiskFactorTemplateEvaluation = {
  __typename?: 'GuidedCareHealthProgramRiskFactorTemplateEvaluation';
  enrolledGuidedPrograms?: Maybe<Array<Maybe<GuidedCareHealthProgram>>>;
  level?: Maybe<Scalars['String']['output']>;
  notification?: Maybe<Scalars['String']['output']>;
  suggestedGuidedPrograms?: Maybe<Array<Maybe<GuidedCareHealthProgram>>>;
  visitId?: Maybe<Scalars['ID']['output']>;
};

export enum GuidedCareHealthProgramSortingField {
  Created = 'CREATED',
  Id = 'ID',
  IsActive = 'IS_ACTIVE',
  Modified = 'MODIFIED',
  Price = 'PRICE',
  ProgramName = 'PROGRAM_NAME',
  TemplateName = 'TEMPLATE_NAME'
}

export type GuidedCareHealthProgramSortingInput = {
  /** Specifies the direction in which to sort Guided Care Journeys. */
  direction: H_OrderDirection;
  /** Sort journeys by the selected field. */
  field: GuidedCareHealthProgramSortingField;
};

export type GuidedCareJourney = H_Node & {
  __typename?: 'GuidedCareJourney';
  activeInterventions?: Maybe<Array<Maybe<GuidedCareJourneyIntervention>>>;
  activities?: Maybe<Scalars['String']['output']>;
  adherence?: Maybe<Scalars['Float']['output']>;
  allActivitiesCount?: Maybe<Scalars['Int']['output']>;
  allActivitiesCountBeforeCurrentDate?: Maybe<Scalars['Int']['output']>;
  completedActivitiesCount?: Maybe<Scalars['Int']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByUserId?: Maybe<Scalars['ID']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  disenrollmentDate?: Maybe<Scalars['Instant']['output']>;
  eligibleInterventions?: Maybe<Array<Maybe<GuidedCareJourneyIntervention>>>;
  endDate?: Maybe<Scalars['Instant']['output']>;
  gaps?: Maybe<Scalars['Int']['output']>;
  guidedCareHealthProgram?: Maybe<GuidedCareHealthProgram>;
  guidedCareJourneyItems?: Maybe<Array<Maybe<GuidedCareJourneyItem>>>;
  id: Scalars['ID']['output'];
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['Instant']['output']>;
  patientMedicalProfile?: Maybe<PatientMedicalProfile>;
  providerGuidedCareHealthProgramTeam?: Maybe<ProviderGuidedCareHealthProgramTeam>;
  startDate?: Maybe<Scalars['Instant']['output']>;
  status?: Maybe<GuidedCareJourneyStatus>;
  statusReason?: Maybe<Scalars['String']['output']>;
};

export type GuidedCareJourneyCountableConnection = {
  __typename?: 'GuidedCareJourneyCountableConnection';
  edges: Array<GuidedCareJourneyCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type GuidedCareJourneyCountableEdge = {
  __typename?: 'GuidedCareJourneyCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: GuidedCareJourney;
};

export type GuidedCareJourneyFilterInput = {
  healthProgramId?: InputMaybe<Scalars['ID']['input']>;
  ioHealthId?: InputMaybe<Scalars['String']['input']>;
  patientName?: InputMaybe<Scalars['String']['input']>;
  patientsIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  statuses?: InputMaybe<Array<InputMaybe<GuidedCareJourneyStatus>>>;
  teamLeaderName?: InputMaybe<Scalars['String']['input']>;
};

export type GuidedCareJourneyIntervention = {
  __typename?: 'GuidedCareJourneyIntervention';
  healthParameter?: Maybe<HealthParameter>;
  templateIntervention?: Maybe<TemplateIntervention>;
};

export type GuidedCareJourneyItem = H_Node & {
  __typename?: 'GuidedCareJourneyItem';
  appointment?: Maybe<Appointment>;
  classification?: Maybe<JourneyItemClassification>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByUserId?: Maybe<Scalars['ID']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  delete?: Maybe<Scalars['Boolean']['output']>;
  dueDate?: Maybe<Scalars['Instant']['output']>;
  executionEnd?: Maybe<Scalars['Instant']['output']>;
  executionStart?: Maybe<Scalars['Instant']['output']>;
  guidedCareJourney?: Maybe<GuidedCareJourney>;
  guidedCareJourneyItemServiceDetails?: Maybe<Array<Maybe<ServiceDetails>>>;
  guidedCareJourneyItemSource?: Maybe<GuidedCareJourneyItemSource>;
  guidedCareJourneyItemStatus?: Maybe<GuidedCareJourneyItemStatus>;
  guidedCareJourneyItemType?: Maybe<GuidedCareActivityType>;
  id: Scalars['ID']['output'];
  isDisenrolled?: Maybe<Scalars['Boolean']['output']>;
  isVisibleInCareJourneyTimeLine?: Maybe<Scalars['Boolean']['output']>;
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['Instant']['output']>;
  medicalForm?: Maybe<MedicalForm>;
  medicalFormAnswers?: Maybe<MedicalFormAnswerConnection>;
  medicalMessage?: Maybe<MedicalMessage>;
  note?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<GuidedCareJourneyItemPriority>;
  questionAnswers?: Maybe<QuestionAnswerConnection>;
  statusReason?: Maybe<Scalars['String']['output']>;
  teamMember?: Maybe<ProviderGuidedCareProgramTeamMember>;
  templateIntervention?: Maybe<TemplateIntervention>;
  timeOfDay?: Maybe<TimeOfDay>;
};


export type GuidedCareJourneyItemMedicalFormAnswersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MedicalFormAnswerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type GuidedCareJourneyItemQuestionAnswersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<QuestionAnswerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type GuidedCareJourneyItemCrud = {
  __typename?: 'GuidedCareJourneyItemCRUD';
  guidedCareJourneyItem?: Maybe<GuidedCareJourneyItem>;
  guidedCareJourneyItemErrors?: Maybe<Array<H_EntityError>>;
};

export type GuidedCareJourneyItemCountableConnection = {
  __typename?: 'GuidedCareJourneyItemCountableConnection';
  edges: Array<GuidedCareJourneyItemCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type GuidedCareJourneyItemCountableEdge = {
  __typename?: 'GuidedCareJourneyItemCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: GuidedCareJourneyItem;
};

export type GuidedCareJourneyItemFilter = {
  doctorId?: InputMaybe<Scalars['ID']['input']>;
  doctorUserId?: InputMaybe<Scalars['ID']['input']>;
  dueDate?: InputMaybe<H_DateTimeRangeInput>;
  guidedCareHealthProgramId?: InputMaybe<Scalars['ID']['input']>;
  guidedCareJourneyId?: InputMaybe<Scalars['ID']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  source?: InputMaybe<GuidedCareJourneyItemSource>;
  statuses?: InputMaybe<Array<InputMaybe<GuidedCareJourneyItemStatus>>>;
  teamMemberPosition?: InputMaybe<TeamMemberPosition>;
  types?: InputMaybe<Array<InputMaybe<GuidedCareActivityType>>>;
};

export type GuidedCareJourneyItemInput = {
  guidedCareJourneyItemStatus?: InputMaybe<GuidedCareCareJourneyItemStatus>;
  note?: InputMaybe<Scalars['String']['input']>;
  statusReason?: InputMaybe<Scalars['String']['input']>;
};

export enum GuidedCareJourneyItemPriority {
  /** The request should be actioned as soon as possible - higher priority than urgent */
  Asap = 'ASAP',
  /** The request has normal priority */
  Routine = 'ROUTINE',
  /** The request should be actioned immediately - highest possible priority. E.g. an emergency */
  Stat = 'STAT',
  /** The request should be actioned promptly - higher priority than routine */
  Urgent = 'URGENT'
}

export enum GuidedCareJourneyItemSource {
  Personalized = 'PERSONALIZED',
  Program = 'PROGRAM'
}

export enum GuidedCareJourneyItemStatus {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Missed = 'MISSED',
  OnHold = 'ON_HOLD',
  Pending = 'PENDING',
  Received = 'RECEIVED',
  Rejected = 'REJECTED',
  Rescheduled = 'RESCHEDULED'
}

export enum GuidedCareJourneySortField {
  /** Sort by adherence. */
  Adherence = 'ADHERENCE',
  /** Sort by creation date. */
  CreationDate = 'CREATION_DATE',
  /** Sort by gaps. */
  Gaps = 'GAPS'
}

export type GuidedCareJourneySortingInput = {
  /** Specifies the direction in which to sort Medical Messages. */
  direction: H_OrderDirection;
  /** Sort medical messages by the selected field. */
  field: GuidedCareJourneySortField;
};

export enum GuidedCareJourneyStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS'
}

export type GuidedCarePatientTeamEnrollmentInput = {
  patientId?: InputMaybe<Scalars['ID']['input']>;
  patientUserId?: InputMaybe<Scalars['ID']['input']>;
  teamId: Scalars['ID']['input'];
};

export type GuidedCarePatientTeamSwitchInput = {
  newTeamId: Scalars['ID']['input'];
  oldTeamId: Scalars['ID']['input'];
};

export type GuidedCareProgramActivityItem = H_Node & {
  __typename?: 'GuidedCareProgramActivityItem';
  afterCallMessage?: Maybe<MedicalMessage>;
  afterCallMessageOffsetDays?: Maybe<Scalars['Int']['output']>;
  beforeCallMessage?: Maybe<MedicalMessage>;
  beforeCallMessageOffsetDays?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  offsetInDays?: Maybe<Scalars['Int']['output']>;
  timeOfDay?: Maybe<TimeOfDay>;
};

export type GuidedCareProgramTeamMember = H_Node & {
  __typename?: 'GuidedCareProgramTeamMember';
  id: Scalars['ID']['output'];
  isBackupPersonMandatory?: Maybe<Scalars['Boolean']['output']>;
  isKeyTeamMember?: Maybe<Scalars['Boolean']['output']>;
  isLicencedHealthProfessional?: Maybe<Scalars['Boolean']['output']>;
  position?: Maybe<TeamMemberPosition>;
  specialization?: Maybe<Scalars['String']['output']>;
  specializationArabicDisplay?: Maybe<Scalars['String']['output']>;
  specializationDisplay?: Maybe<Scalars['String']['output']>;
  templateTeamMember?: Maybe<TemplateTeamMember>;
};

export type GuidedCareProgramTeamMemberIdInput = {
  id: Scalars['ID']['input'];
};

export type GuidedCareProgramTeamMemberInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  isBackupPersonMandatory: Scalars['Boolean']['input'];
  isKeyTeamMember: Scalars['Boolean']['input'];
  isLicencedHealthProfessional: Scalars['Boolean']['input'];
  position: TeamMemberPosition;
  specialization?: InputMaybe<Scalars['String']['input']>;
  templateTeamMember?: InputMaybe<TemplateTeamMemberIdInput>;
  uniqueId?: InputMaybe<Scalars['String']['input']>;
};

export type GuidedCareTask = H_Node & {
  __typename?: 'GuidedCareTask';
  assignee?: Maybe<Array<Maybe<GuidedCareTaskAssignee>>>;
  description?: Maybe<Scalars['String']['output']>;
  displayAr?: Maybe<Scalars['String']['output']>;
  displayEn?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['Instant']['output']>;
  executionStartDate?: Maybe<Scalars['Instant']['output']>;
  guidedCareJourneyItemId?: Maybe<Scalars['ID']['output']>;
  guidedCareProgramName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  nextReminderDate?: Maybe<Scalars['Instant']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  notificationMessageAr?: Maybe<Scalars['String']['output']>;
  notificationMessageEn?: Maybe<Scalars['String']['output']>;
  notificationTitleAr?: Maybe<Scalars['String']['output']>;
  notificationTitleEn?: Maybe<Scalars['String']['output']>;
  patient?: Maybe<Patient>;
  previousTask?: Maybe<GuidedCareTask>;
  status?: Maybe<TaskDefinitionStatus>;
  statusReason?: Maybe<Scalars['String']['output']>;
  taskActionEntityId?: Maybe<Scalars['ID']['output']>;
  taskDefinition?: Maybe<TaskDefinition>;
  teamId?: Maybe<Scalars['ID']['output']>;
};

export type GuidedCareTaskAssignee = {
  __typename?: 'GuidedCareTaskAssignee';
  assigneeType?: Maybe<AssigneeType>;
  assigneeUserId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  teamMember?: Maybe<ProviderGuidedCareProgramTeamMember>;
};

export type GuidedCareTaskAssigneeInput = {
  assigneeType: AssigneeType;
  assigneeUserId: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  teamMember?: InputMaybe<AssigneeTeamMemberInput>;
};

/** CRUD a new Guided Care Task */
export type GuidedCareTaskCrud = {
  __typename?: 'GuidedCareTaskCRUD';
  entityErrors?: Maybe<Array<H_EntityError>>;
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  guidedCareTask?: Maybe<GuidedCareTask>;
};

export type GuidedCareTaskCountableConnection = {
  __typename?: 'GuidedCareTaskCountableConnection';
  edges: Array<GuidedCareTaskCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type GuidedCareTaskCountableEdge = {
  __typename?: 'GuidedCareTaskCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  node: GuidedCareTask;
};

export type GuidedCareTaskFilterInput = {
  assigneeUserIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  dueDate?: InputMaybe<H_DateTimeRangeInput>;
  guidedCareHealthProgramId?: InputMaybe<Scalars['ID']['input']>;
  nextReminderDate?: InputMaybe<H_DateTimeRangeInput>;
  patientUserId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  statuses?: InputMaybe<Array<InputMaybe<TaskDefinitionStatus>>>;
  teamMemberUserId?: InputMaybe<Scalars['ID']['input']>;
};

export type GuidedCareTaskInnerInput = {
  id: Scalars['ID']['input'];
};

export type GuidedCareTaskInput = {
  description: Scalars['String']['input'];
  displayAr: Scalars['String']['input'];
  displayEn: Scalars['String']['input'];
  dueDate: Scalars['Instant']['input'];
  nextReminderDate: Scalars['Instant']['input'];
  notes: Scalars['String']['input'];
  notificationMessageAr: Scalars['String']['input'];
  notificationMessageEn: Scalars['String']['input'];
  notificationTitleAr: Scalars['String']['input'];
  notificationTitleEn: Scalars['String']['input'];
  previousTask?: InputMaybe<GuidedCareTaskInnerInput>;
  status: TaskDefinitionStatus;
  statusReason: Scalars['String']['input'];
  taskDefinition: TaskDefinitionInnerInput;
};

export enum GuidedCareTaskSortingField {
  DueDate = 'DUE_DATE',
  NextReminderDate = 'NEXT_REMINDER_DATE'
}

export type GuidedCareTaskSortingInput = {
  direction: H_OrderDirection;
  field?: InputMaybe<GuidedCareTaskSortingField>;
};

export type GuidedCareTaskUpdateInput = {
  assignee: Array<InputMaybe<GuidedCareTaskAssigneeInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayAr?: InputMaybe<Scalars['String']['input']>;
  displayEn?: InputMaybe<Scalars['String']['input']>;
  dueDate?: InputMaybe<Scalars['Instant']['input']>;
  nextReminderDate?: InputMaybe<Scalars['Instant']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  notificationMessageAr?: InputMaybe<Scalars['String']['input']>;
  notificationMessageEn?: InputMaybe<Scalars['String']['input']>;
  notificationTitleAr?: InputMaybe<Scalars['String']['input']>;
  notificationTitleEn?: InputMaybe<Scalars['String']['input']>;
  previousTask?: InputMaybe<GuidedCareTaskInnerInput>;
  status?: InputMaybe<TaskDefinitionStatus>;
  statusReason?: InputMaybe<Scalars['String']['input']>;
  taskDefinition?: InputMaybe<TaskDefinitionInnerInput>;
};

export enum GuidedCareType {
  Acute = 'ACUTE',
  Chronic = 'CHRONIC',
  PostOperative = 'POST_OPERATIVE',
  PreOperative = 'PRE_OPERATIVE',
  Primary = 'PRIMARY',
  SubAcute = 'SUB_ACUTE',
  Supportive = 'SUPPORTIVE'
}

export type HisAppointmentFilterInput = {
  branchId?: InputMaybe<Scalars['String']['input']>;
  departmentId?: InputMaybe<Scalars['String']['input']>;
  doctorId?: InputMaybe<Scalars['String']['input']>;
  fromDate: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  size: Scalars['Int']['input'];
  toDate: Scalars['String']['input'];
  vendorId?: InputMaybe<Scalars['String']['input']>;
};

export type H_CancelReason = H_Node & {
  __typename?: 'H_CancelReason';
  actor?: Maybe<Actor>;
  affectedType?: Maybe<AffectedType>;
  created?: Maybe<Scalars['Instant']['output']>;
  id: Scalars['ID']['output'];
  modified?: Maybe<Scalars['Instant']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  text_ar?: Maybe<Scalars['String']['output']>;
};

export type H_DateTimeRangeInput = {
  /** Start date. */
  gte?: InputMaybe<Scalars['Instant']['input']>;
  /** End date. */
  lte?: InputMaybe<Scalars['Instant']['input']>;
};

export enum H_DayOfWeek {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday'
}

export type H_EntityError = {
  __typename?: 'H_EntityError';
  /** The error code. */
  code: H_EntityErrorCode;
  /** Name of a field that caused the error. A value of `null` indicates that the error isn't associated with a particular field. */
  field?: Maybe<Scalars['String']['output']>;
  /** The error message. */
  message?: Maybe<Scalars['String']['output']>;
};

export enum H_EntityErrorCode {
  AlreadyExists = 'ALREADY_EXISTS',
  AppointmentOverlap = 'APPOINTMENT_OVERLAP',
  DuplicatedInputItem = 'DUPLICATED_INPUT_ITEM',
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  Mismatch = 'MISMATCH',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

/** Represents an error in the input of a mutation. */
export type H_Error = {
  __typename?: 'H_Error';
  /** Name of a field that caused the error. A value of `null` indicates that the error isn't associated with a particular field. */
  field?: Maybe<Scalars['String']['output']>;
  /** The error message. */
  message?: Maybe<Scalars['String']['output']>;
};

export enum H_Gender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export enum H_IdTypes {
  NationalCard = 'NATIONAL_CARD',
  Passport = 'PASSPORT',
  ResidentCard = 'RESIDENT_CARD'
}

export type H_IntRangeInput = {
  gte?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
};

export type H_LocationInput = {
  /** Latitude Value. */
  lat: Scalars['Float']['input'];
  /** Longitude Value . */
  lng: Scalars['Float']['input'];
};

export type H_LocationType = {
  __typename?: 'H_LocationType';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** Represents amount of money in specific currency. */
export type H_Money = {
  __typename?: 'H_Money';
  /** Amount of money. */
  amount: Scalars['Float']['output'];
  /** Currency code. */
  currency: Scalars['String']['output'];
};

/** An object with an ID */
export type H_Node = {
  /** The ID of the object. */
  id: Scalars['ID']['output'];
};

export enum H_OrderDirection {
  /** Specifies an ascending sort order. */
  Asc = 'ASC',
  /** Specifies a descending sort order. */
  Desc = 'DESC'
}

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type H_PageInfo = {
  __typename?: 'H_PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type H_PriceRangeInput = {
  /** Price greater than or equal to. */
  gte?: InputMaybe<Scalars['Float']['input']>;
  /** Price less than or equal to. */
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type H_RejectionReason = H_Node & {
  __typename?: 'H_RejectionReason';
  actor?: Maybe<Actor>;
  affectedType?: Maybe<AffectedType>;
  created?: Maybe<Scalars['Instant']['output']>;
  id: Scalars['ID']['output'];
  modified?: Maybe<Scalars['Instant']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  text_ar?: Maybe<Scalars['String']['output']>;
};

export enum H_RelationTypes {
  Aunt = 'AUNT',
  Brother = 'BROTHER',
  Cousin = 'COUSIN',
  Daughter = 'DAUGHTER',
  Father = 'FATHER',
  Friend = 'FRIEND',
  Granddaughter = 'GRANDDAUGHTER',
  Grandfather = 'GRANDFATHER',
  Grandmother = 'GRANDMOTHER',
  Grandson = 'GRANDSON',
  Husband = 'HUSBAND',
  Mother = 'MOTHER',
  Nephew = 'NEPHEW',
  Niece = 'NIECE',
  Other = 'OTHER',
  Sister = 'SISTER',
  Son = 'SON',
  Uncle = 'UNCLE',
  Wife = 'WIFE'
}

export enum H_ReportingPeriod {
  Last_7Days = 'LAST_7_DAYS',
  ThisMonth = 'THIS_MONTH',
  Today = 'TODAY'
}

export type H_SiteSettingsInput = {
  /**
   * allowed time in seconds to make a new call after the essential latest call was made
   * , essential call means the first call that was requested by patient and perhaps the call was timedout/rejoined/cancelled
   * and then the final status of it can be
   * (INCOMPLETED, COMPLETED, CANCELED, CANCELED_BY_CONSUMER, ENDED_BY_ONE_OF_THE_PARTICIPANTS)
   */
  allowedTimeInSecondsToMakeNewCall?: InputMaybe<Scalars['Int']['input']>;
  appointmentReminderTimeSeconds?: InputMaybe<Scalars['Int']['input']>;
  callJoinTimeoutSeconds?: InputMaybe<Scalars['Int']['input']>;
  callRejoinTimeoutInSeconds?: InputMaybe<Scalars['Int']['input']>;
  doctorVisitAcceptanceTimeoutSeconds?: InputMaybe<Scalars['Int']['input']>;
  guidedCareAppointmentBookingFirstReminderSeconds?: InputMaybe<Scalars['Int']['input']>;
  guidedCareAppointmentBookingReminderCareTeamAdminMessage?: InputMaybe<Scalars['String']['input']>;
  guidedCareAppointmentBookingReminderCareTeamAdminMessageAr?: InputMaybe<Scalars['String']['input']>;
  guidedCareAppointmentBookingReminderDoctorMessage?: InputMaybe<Scalars['String']['input']>;
  guidedCareAppointmentBookingReminderDoctorMessageAr?: InputMaybe<Scalars['String']['input']>;
  guidedCareAppointmentBookingReminderPatientMessage?: InputMaybe<Scalars['String']['input']>;
  guidedCareAppointmentBookingReminderPatientMessageAr?: InputMaybe<Scalars['String']['input']>;
  guidedCareAppointmentBookingSecondReminderSeconds?: InputMaybe<Scalars['Int']['input']>;
  guidedCareAppointmentBookingThirdReminderSeconds?: InputMaybe<Scalars['Int']['input']>;
  healthProgramVisitWaitTimeBeforeCallSeconds?: InputMaybe<Scalars['Int']['input']>;
  messengerCallMinimumDurationSeconds?: InputMaybe<Scalars['Int']['input']>;
  paymentDeadlineInSeconds?: InputMaybe<Scalars['Int']['input']>;
  paymentFirstReminderInSeconds?: InputMaybe<Scalars['Int']['input']>;
  paymentSecondReminderInSeconds?: InputMaybe<Scalars['Int']['input']>;
  runningLateTimeoutMinutes?: InputMaybe<Scalars['Int']['input']>;
  vatPercentageForMedicalServices?: InputMaybe<Scalars['Float']['input']>;
};

export type HandleOrderTimeoutResult = {
  __typename?: 'HandleOrderTimeoutResult';
  orderErrors?: Maybe<Array<H_EntityError>>;
  success: Scalars['Boolean']['output'];
};

export type HealthChannel = H_Node & {
  __typename?: 'HealthChannel';
  averageRating?: Maybe<Scalars['Float']['output']>;
  category?: Maybe<HealthChannelCategory>;
  coverImage?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['Instant']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  followers?: Maybe<HealthChannelFollowerCountableConnection>;
  followersCount?: Maybe<Scalars['Int']['output']>;
  healthChannelFollowers?: Maybe<Array<Maybe<HealthChannelFollower>>>;
  healthChannelRatings?: Maybe<Array<Maybe<HealthChannelRating>>>;
  id: Scalars['ID']['output'];
  isFollowing?: Maybe<Scalars['Boolean']['output']>;
  labels?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  modified?: Maybe<Scalars['Instant']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  published?: Maybe<Scalars['Boolean']['output']>;
  ratingsSum?: Maybe<Scalars['Float']['output']>;
  subscriptionType?: Maybe<HealthChannelSubscriptionType>;
  totalRatings?: Maybe<Scalars['Int']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};


export type HealthChannelFollowersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** CRUD a new health channel. */
export type HealthChannelCrud = {
  __typename?: 'HealthChannelCRUD';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  healthChannel?: Maybe<HealthChannel>;
  healthChannelErrors?: Maybe<Array<H_EntityError>>;
};

export type HealthChannelCategory = H_Node & {
  __typename?: 'HealthChannelCategory';
  created?: Maybe<Scalars['Instant']['output']>;
  healthChannels?: Maybe<HealthChannelCountableConnection>;
  id: Scalars['ID']['output'];
  modified?: Maybe<Scalars['Instant']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};


export type HealthChannelCategoryHealthChannelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** CRUD a new health channel category. */
export type HealthChannelCategoryCrud = {
  __typename?: 'HealthChannelCategoryCRUD';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  healthChannelCategory?: Maybe<HealthChannelCategory>;
  healthChannelErrors?: Maybe<Array<H_EntityError>>;
};

export type HealthChannelCategoryCountableConnection = {
  __typename?: 'HealthChannelCategoryCountableConnection';
  edges: Array<HealthChannelCategoryCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type HealthChannelCategoryCountableEdge = {
  __typename?: 'HealthChannelCategoryCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: HealthChannelCategory;
};

export type HealthChannelCategoryInput = {
  /** Health Channel Category Name */
  name: Scalars['String']['input'];
};

export type HealthChannelCountableConnection = {
  __typename?: 'HealthChannelCountableConnection';
  edges: Array<HealthChannelCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type HealthChannelCountableEdge = {
  __typename?: 'HealthChannelCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: HealthChannel;
};

export type HealthChannelFilterInput = {
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  consumers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  subscriptionType?: InputMaybe<Array<InputMaybe<HealthChannelSubscriptionType>>>;
};

export type HealthChannelFollower = H_Node & {
  __typename?: 'HealthChannelFollower';
  channel?: Maybe<HealthChannel>;
  consumerId?: Maybe<Scalars['Int']['output']>;
  created?: Maybe<Scalars['Instant']['output']>;
  id: Scalars['ID']['output'];
  modified?: Maybe<Scalars['Instant']['output']>;
};

export type HealthChannelFollowerCountableConnection = {
  __typename?: 'HealthChannelFollowerCountableConnection';
  edges: Array<HealthChannelFollowerCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type HealthChannelFollowerCountableEdge = {
  __typename?: 'HealthChannelFollowerCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: HealthChannelFollower;
};

export type HealthChannelFollowerFilterInput = {
  channels?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type HealthChannelInput = {
  category?: InputMaybe<Scalars['ID']['input']>;
  coverImage?: InputMaybe<Scalars['Upload']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  labels?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  published?: InputMaybe<Scalars['Boolean']['input']>;
  subscriptionType?: InputMaybe<HealthChannelSubscriptionType>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type HealthChannelRating = H_Node & {
  __typename?: 'HealthChannelRating';
  comment?: Maybe<Scalars['String']['output']>;
  consumerId?: Maybe<Scalars['Int']['output']>;
  healthChannel?: Maybe<HealthChannel>;
  id: Scalars['ID']['output'];
  rating?: Maybe<Scalars['Float']['output']>;
};

export type HealthChannelRatingInput = {
  /** ID of Health Channel to be rated */
  channel: Scalars['ID']['input'];
  /** Feedback on the user's rating for the health channel */
  comment?: InputMaybe<Scalars['String']['input']>;
  /** The rating provided by the user (from 1 to 5) */
  rating: Scalars['Float']['input'];
};

export enum HealthChannelSubscriptionType {
  Free = 'FREE',
  Paid = 'PAID'
}

export type HealthCondition = {
  __typename?: 'HealthCondition';
  arabicDisplay?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['String']['output']>;
  disease?: Maybe<Scalars['String']['output']>;
  display?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<DecisionMakerGraphqlError>>>;
  fields?: Maybe<Array<Maybe<HealthParameter>>>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<HealthConditionType>;
  umlsCode?: Maybe<Scalars['String']['output']>;
};

export type HealthConditionDiagnosesFilterInput = {
  diagnosisCode?: InputMaybe<Scalars['String']['input']>;
  healthConditionId?: InputMaybe<Scalars['ID']['input']>;
};

export type HealthConditionDiagnosesSortingInput = {
  direction: DecisionMakerOrderDirection;
  field: HealthConditionDiagnosisSortingField;
};

export type HealthConditionDiagnosis = {
  __typename?: 'HealthConditionDiagnosis';
  diagnosisCode?: Maybe<Scalars['String']['output']>;
  diagnosisDisplay?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<DecisionMakerGraphqlError>>>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type HealthConditionDiagnosisConnection = {
  __typename?: 'HealthConditionDiagnosisConnection';
  edges?: Maybe<Array<Maybe<HealthConditionDiagnosisEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type HealthConditionDiagnosisEdge = {
  __typename?: 'HealthConditionDiagnosisEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<HealthConditionDiagnosis>;
};

export enum HealthConditionDiagnosisSortingField {
  Code = 'CODE',
  CreatedDate = 'CREATED_DATE'
}

export type HealthConditionEdge = {
  __typename?: 'HealthConditionEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<HealthCondition>;
};

export type HealthConditionFilterInput = {
  arabicDisplay?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  diagnosisCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  disease?: InputMaybe<Scalars['String']['input']>;
  display?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<HealthConditionType>;
};

export type HealthConditionInput = {
  arabicDisplay: Scalars['String']['input'];
  code: Scalars['String']['input'];
  disease: Scalars['String']['input'];
  display: Scalars['String']['input'];
  isActive: Scalars['Boolean']['input'];
  type: HealthConditionType;
  umlsCode: Scalars['String']['input'];
};

export enum HealthConditionSortingField {
  ArabicDisplay = 'ARABIC_DISPLAY',
  Code = 'CODE',
  CreatedDate = 'CREATED_DATE',
  Disease = 'DISEASE',
  Display = 'DISPLAY',
  IsActive = 'IS_ACTIVE',
  Type = 'TYPE'
}

export type HealthConditionSortingInput = {
  direction: DecisionMakerOrderDirection;
  field: HealthConditionSortingField;
};

export enum HealthConditionType {
  Acute = 'ACUTE',
  Chronic = 'CHRONIC',
  SubAcute = 'SUB_ACUTE'
}

export type HealthConditionUpdateInput = {
  arabicDisplay: Scalars['String']['input'];
  disease: Scalars['String']['input'];
  display: Scalars['String']['input'];
  isActive: Scalars['Boolean']['input'];
  type: HealthConditionType;
  umlsCode: Scalars['String']['input'];
};

export type HealthConditioneConnection = {
  __typename?: 'HealthConditioneConnection';
  edges?: Maybe<Array<Maybe<HealthConditionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export enum HealthDocumentType {
  Laboratory = 'LABORATORY',
  Medication = 'MEDICATION',
  Prescription = 'PRESCRIPTION',
  Radiology = 'RADIOLOGY',
  Visit = 'VISIT'
}

export type HealthHistory = {
  __typename?: 'HealthHistory';
  code?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['Instant']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  type?: Maybe<HealthParameterType>;
  valueBoolean?: Maybe<Scalars['Boolean']['output']>;
  valueNumber?: Maybe<Scalars['Double']['output']>;
  valueNumericList?: Maybe<Array<Maybe<Scalars['Double']['output']>>>;
  valueString?: Maybe<Scalars['String']['output']>;
};

export enum HealthHistoryType {
  Allergy = 'ALLERGY',
  Diagnose = 'DIAGNOSE',
  Family = 'FAMILY',
  Medical = 'MEDICAL',
  Surgery = 'SURGERY'
}

export type HealthParameter = {
  __typename?: 'HealthParameter';
  allowManualEditForPatient?: Maybe<Scalars['Boolean']['output']>;
  allowedValues?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  arabicDisplay?: Maybe<Scalars['String']['output']>;
  category?: Maybe<FieldCategory>;
  chartType?: Maybe<ChartType>;
  code?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['String']['output']>;
  defaultValue?: Maybe<Scalars['String']['output']>;
  display?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<DecisionMakerGraphqlError>>>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isMandatory?: Maybe<Scalars['Boolean']['output']>;
  maxNormalRangeValue?: Maybe<Scalars['Float']['output']>;
  minNormalRangeValue?: Maybe<Scalars['Float']['output']>;
  referenceRange?: Maybe<Scalars['String']['output']>;
  standard?: Maybe<FieldStandard>;
  standardCode?: Maybe<Scalars['String']['output']>;
  standardCodeDisplay?: Maybe<Scalars['String']['output']>;
  type?: Maybe<TemplateFieldType>;
  unit?: Maybe<FieldUnit>;
  visibleForPatientOverview?: Maybe<Scalars['Boolean']['output']>;
};

export type HealthParameterCategoryFilterInput = {
  arabicDisplay?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  display?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
};

export type HealthParameterConnection = {
  __typename?: 'HealthParameterConnection';
  edges?: Maybe<Array<Maybe<HealthParameterEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type HealthParameterEdge = {
  __typename?: 'HealthParameterEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<HealthParameter>;
};

export type HealthParameterFilterInput = {
  allowManualEditForPatient?: InputMaybe<Scalars['Boolean']['input']>;
  arabicDisplay?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<Scalars['ID']['input']>;
  categoryCode?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  display?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  standardCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type HealthParameterInput = {
  allowManualEditForPatient?: Scalars['Boolean']['input'];
  allowedValues?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  arabicDisplay: Scalars['String']['input'];
  category: FieldCategoryInput;
  chartType: ChartType;
  code: Scalars['String']['input'];
  defaultValue?: InputMaybe<Scalars['String']['input']>;
  display: Scalars['String']['input'];
  isActive?: Scalars['Boolean']['input'];
  isMandatory: Scalars['Boolean']['input'];
  maxNormalRangeValue?: InputMaybe<Scalars['Float']['input']>;
  minNormalRangeValue?: InputMaybe<Scalars['Float']['input']>;
  referenceRange?: InputMaybe<Scalars['String']['input']>;
  standard: FieldStandard;
  standardCode: Scalars['String']['input'];
  type: TemplateFieldType;
  unit?: InputMaybe<FieldUnitInput>;
  visibleForPatientOverview: Scalars['Boolean']['input'];
};

export enum HealthParameterSortingField {
  ArabicDisplay = 'ARABIC_DISPLAY',
  Code = 'CODE',
  CreatedDate = 'CREATED_DATE',
  Display = 'DISPLAY',
  StandardCode = 'STANDARD_CODE'
}

export type HealthParameterSortingInput = {
  direction: DecisionMakerOrderDirection;
  field: HealthParameterSortingField;
};

export enum HealthParameterType {
  Vaccination = 'VACCINATION'
}

export type HealthParameterUpdateInput = {
  allowManualEditForPatient?: Scalars['Boolean']['input'];
  arabicDisplay: Scalars['String']['input'];
  category: FieldCategoryInput;
  chartType: ChartType;
  defaultValue?: InputMaybe<Scalars['String']['input']>;
  display: Scalars['String']['input'];
  isActive?: Scalars['Boolean']['input'];
  isMandatory: Scalars['Boolean']['input'];
  maxNormalRangeValue?: InputMaybe<Scalars['Float']['input']>;
  minNormalRangeValue?: InputMaybe<Scalars['Float']['input']>;
  referenceRange?: InputMaybe<Scalars['String']['input']>;
  standard: FieldStandard;
  standardCode: Scalars['String']['input'];
  unit?: InputMaybe<FieldUnitInput>;
  visibleForPatientOverview: Scalars['Boolean']['input'];
};

export type HealthProgram = H_Node & {
  __typename?: 'HealthProgram';
  backGroundImage?: Maybe<Scalars['String']['output']>;
  baselineAvailabilityHours?: Maybe<Scalars['Int']['output']>;
  benefitGroups?: Maybe<Array<Maybe<BenefitGroupComplex>>>;
  callQualityRatingFactors?: Maybe<Scalars['String']['output']>;
  careFor?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  code?: Maybe<Scalars['String']['output']>;
  copay?: Maybe<Scalars['Boolean']['output']>;
  copayAmount?: Maybe<Scalars['Float']['output']>;
  copayPercentage?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Instant']['output']>;
  createdByUser?: Maybe<User>;
  currency?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  exclusionOptions?: Maybe<Array<Maybe<ExclusionOption>>>;
  healthProgramMemberLists?: Maybe<HealthProgramMemberListCountableConnection>;
  healthProgramNetworks?: Maybe<HealthProgramNetworkCountableConnection>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  insuranceNetworks?: Maybe<InsuranceNetworkCountableConnection>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isSubscribed?: Maybe<Scalars['Boolean']['output']>;
  mainSpecializationsRateFactors?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['Instant']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  operatorProfitSharePercentage?: Maybe<Scalars['Float']['output']>;
  payPerCallOnly?: Maybe<Scalars['Boolean']['output']>;
  payer?: Maybe<Payer>;
  paymentReceipts: PaymentReceiptCountableConnection;
  platformProfitSharePercentage?: Maybe<Scalars['Float']['output']>;
  pointsDuringNormalDemand?: Maybe<Scalars['Float']['output']>;
  pointsDuringUrgentDemand?: Maybe<Scalars['Float']['output']>;
  prescriptionEnabled?: Maybe<Scalars['Boolean']['output']>;
  price?: Maybe<H_Money>;
  priceAmount?: Maybe<Scalars['Float']['output']>;
  referralsToSpecialistsEnabled?: Maybe<Scalars['Boolean']['output']>;
  requiresIdValidation?: Maybe<Scalars['Boolean']['output']>;
  service?: Maybe<Scalars['String']['output']>;
  specializations?: Maybe<Array<Maybe<HealthProgramSpecialization>>>;
  subscriptionPeriod?: Maybe<HealthProgramSubscriptionPeriod>;
  subscriptions?: Maybe<HealthProgramUserSubscriptionCountableConnection>;
  targetGroup?: Maybe<Scalars['String']['output']>;
  totalMembers?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<HealthProgramType>;
  unattendedCallPenaltyFactor?: Maybe<Scalars['Float']['output']>;
  vendorProfitSharePercentage?: Maybe<Scalars['Float']['output']>;
};


export type HealthProgramHealthProgramMemberListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type HealthProgramHealthProgramNetworksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type HealthProgramInsuranceNetworksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type HealthProgramPaymentReceiptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PaymentReceiptFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type HealthProgramSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type HealthProgramBenefitGroupExtraBenefitInput = {
  extraBenefit: Scalars['ID']['input'];
  healthChannels: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type HealthProgramBenefitGroupInput = {
  benefitGroup: Scalars['ID']['input'];
  extraBenefits?: InputMaybe<Array<InputMaybe<HealthProgramBenefitGroupExtraBenefitInput>>>;
  optionsValues: Array<InputMaybe<HealthProgramBenefitGroupOptionValueInput>>;
};

export type HealthProgramBenefitGroupOptionValueInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  option: Scalars['ID']['input'];
  value: Scalars['Float']['input'];
};

/** CRUD a new Health Program Benefit Option */
export type HealthProgramBenefitOptionCrud = {
  __typename?: 'HealthProgramBenefitOptionCRUD';
  benefitOption?: Maybe<BenefitOption>;
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  healthProgramErrors?: Maybe<Array<H_EntityError>>;
};

export enum HealthProgramBenefitOptionCode {
  MaxOfFreeAudioVideoCalls = 'MAX_OF_FREE_AUDIO_VIDEO_CALLS',
  NumberOfProactiveAudioVideoCalls = 'NUMBER_OF_PROACTIVE_AUDIO_VIDEO_CALLS',
  NumberOfReactiveAudioVideoCalls = 'NUMBER_OF_REACTIVE_AUDIO_VIDEO_CALLS',
  PatientSharePercentage = 'PATIENT_SHARE_PERCENTAGE',
  ProactiveAtHomePlannedHealthServices = 'PROACTIVE_AT_HOME_PLANNED_HEALTH_SERVICES',
  ProactiveInPersonPlannedHealthServices = 'PROACTIVE_IN_PERSON_PLANNED_HEALTH_SERVICES',
  ReactiveAtHomeHealthServices = 'REACTIVE_AT_HOME_HEALTH_SERVICES',
  ReactiveInPersonHealthServices = 'REACTIVE_IN_PERSON_HEALTH_SERVICES'
}

export type HealthProgramBenefitOptionInput = {
  careType?: InputMaybe<CareType>;
  code?: InputMaybe<HealthProgramBenefitOptionCode>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<HealthProgramOptionType>;
};

/** CRUD a new Health Program */
export type HealthProgramCrud = {
  __typename?: 'HealthProgramCRUD';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  healthProgram?: Maybe<HealthProgram>;
  healthProgramErrors?: Maybe<Array<H_EntityError>>;
};

export type HealthProgramCallsForVendorResponse = {
  __typename?: 'HealthProgramCallsForVendorResponse';
  isUrgentDemandCall?: Maybe<Scalars['Boolean']['output']>;
  numberOfCalls?: Maybe<Scalars['Int']['output']>;
};

export type HealthProgramCallsPerVendorResponse = {
  __typename?: 'HealthProgramCallsPerVendorResponse';
  numberOfCalls?: Maybe<Scalars['Int']['output']>;
  vendor?: Maybe<Vendor>;
};

export type HealthProgramCallsPerVendorResponseConnection = {
  __typename?: 'HealthProgramCallsPerVendorResponseConnection';
  edges: Array<HealthProgramCallsPerVendorResponseCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type HealthProgramCallsPerVendorResponseCountableEdge = {
  __typename?: 'HealthProgramCallsPerVendorResponseCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  node: HealthProgramCallsPerVendorResponse;
};

export type HealthProgramCareForFields = H_Node & {
  __typename?: 'HealthProgramCareForFields';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type HealthProgramCountableConnection = {
  __typename?: 'HealthProgramCountableConnection';
  edges: Array<HealthProgramCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type HealthProgramCountableEdge = {
  __typename?: 'HealthProgramCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: HealthProgram;
};

export type HealthProgramExclusionOptionInput = {
  exclusionOption: Scalars['ID']['input'];
};

export type HealthProgramFilterInput = {
  benefitGroups?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  benefitOptions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  exclusionOptions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  healthProgramMemberLists?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  healthProgramNetworks?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  insuranceNetworks?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isSubscribed?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  payPerCallOnly?: InputMaybe<Scalars['Boolean']['input']>;
  prescriptionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  price?: InputMaybe<H_PriceRangeInput>;
  specializations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  targetGroup?: InputMaybe<Scalars['String']['input']>;
  targetGroup_Icontains?: InputMaybe<Scalars['String']['input']>;
  types?: InputMaybe<Array<InputMaybe<HealthProgramType>>>;
};

export type HealthProgramInput = {
  /** health program back ground image */
  backGroundImage?: InputMaybe<Scalars['Upload']['input']>;
  baselineAvailabilityHours?: InputMaybe<Scalars['Int']['input']>;
  benefitGroups?: InputMaybe<Array<InputMaybe<HealthProgramBenefitGroupInput>>>;
  callQualityRatingFactors?: InputMaybe<Scalars['String']['input']>;
  careFor?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  code: Scalars['String']['input'];
  copay?: InputMaybe<Scalars['Boolean']['input']>;
  copayAmount?: InputMaybe<Scalars['Float']['input']>;
  copayPercentage?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  exclusionOptions?: InputMaybe<Array<InputMaybe<HealthProgramExclusionOptionInput>>>;
  healthProgramMemberLists?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  healthProgramNetworks?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** health program icon */
  icon?: InputMaybe<Scalars['Upload']['input']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  insuranceNetworks?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  mainSpecializationsRateFactors?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  operatorProfitSharePercentage?: InputMaybe<Scalars['Float']['input']>;
  payPerCallOnly?: InputMaybe<Scalars['Boolean']['input']>;
  payerId?: InputMaybe<Scalars['ID']['input']>;
  platformProfitSharePercentage?: InputMaybe<Scalars['Float']['input']>;
  pointsDuringNormalDemand?: InputMaybe<Scalars['Float']['input']>;
  pointsDuringUrgentDemand?: InputMaybe<Scalars['Float']['input']>;
  prescriptionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  priceAmount?: InputMaybe<Scalars['Float']['input']>;
  referralsToSpecialistsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  requiresIdValidation?: InputMaybe<Scalars['Boolean']['input']>;
  service?: InputMaybe<Scalars['String']['input']>;
  specializations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subscriptionPeriod?: InputMaybe<HealthProgramSubscriptionPeriod>;
  targetGroup?: InputMaybe<Scalars['String']['input']>;
  type: HealthProgramType;
  unattendedCallPenaltyFactor?: InputMaybe<Scalars['Float']['input']>;
  vendorProfitSharePercentage?: InputMaybe<Scalars['Float']['input']>;
};

export type HealthProgramMember = H_Node & {
  __typename?: 'HealthProgramMember';
  active?: Maybe<Scalars['Boolean']['output']>;
  age?: Maybe<Scalars['Int']['output']>;
  backInsuranceCardPhoto?: Maybe<Scalars['String']['output']>;
  categoryCode?: Maybe<Scalars['String']['output']>;
  chronicDiseases?: Maybe<Array<Scalars['String']['output']>>;
  company?: Maybe<Scalars['String']['output']>;
  coverageBy?: Maybe<CoverageByType>;
  created?: Maybe<Scalars['Instant']['output']>;
  createdByUser?: Maybe<User>;
  dateOfBirth?: Maybe<Scalars['InstantNoTime']['output']>;
  disabilities?: Maybe<Array<Scalars['String']['output']>>;
  documentExpiryDate?: Maybe<Scalars['Instant']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  employeeId?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  frontInsuranceCardPhoto?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<H_Gender>;
  healthProgramMemberLists?: Maybe<HealthProgramMemberListCountableConnection>;
  healthProgramsByActiveMembers?: Maybe<Array<Maybe<HealthProgram>>>;
  id: Scalars['ID']['output'];
  idType?: Maybe<H_IdTypes>;
  insuranceCategory?: Maybe<Scalars['String']['output']>;
  insuranceClass?: Maybe<Scalars['String']['output']>;
  insuranceCompanyId?: Maybe<Scalars['String']['output']>;
  insuranceCompanyLicenseNumber?: Maybe<Scalars['String']['output']>;
  insuranceId?: Maybe<Scalars['String']['output']>;
  insurancePolicyNumber?: Maybe<Scalars['String']['output']>;
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  memberType?: Maybe<Scalars['String']['output']>;
  membershipEnd?: Maybe<Scalars['Instant']['output']>;
  membershipStart?: Maybe<Scalars['Instant']['output']>;
  modified?: Maybe<Scalars['Instant']['output']>;
  nationalId?: Maybe<Scalars['String']['output']>;
  nationality?: Maybe<Scalars['String']['output']>;
  nationality3166Code?: Maybe<Scalars['String']['output']>;
  nationalityArabicDisplay?: Maybe<Scalars['String']['output']>;
  nationalityDisplay?: Maybe<Scalars['String']['output']>;
  network?: Maybe<Scalars['String']['output']>;
  parentNationalId?: Maybe<Scalars['String']['output']>;
  patient?: Maybe<Patient>;
  payer?: Maybe<Payer>;
  phone?: Maybe<Scalars['String']['output']>;
  relationType?: Maybe<H_RelationTypes>;
  remarks?: Maybe<Scalars['String']['output']>;
  sehacityId?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};


export type HealthProgramMemberHealthProgramMemberListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type HealthProgramMemberHealthProgramsByActiveMembersArgs = {
  filter?: InputMaybe<HealthProgramFilterInput>;
  sortBy?: InputMaybe<HealthProgramSortingInput>;
};

export type HealthProgramMemberBodyComposition = H_Node & {
  __typename?: 'HealthProgramMemberBodyComposition';
  alcohol?: Maybe<Scalars['Boolean']['output']>;
  alcoholCupsPerDay?: Maybe<Scalars['Int']['output']>;
  bmi?: Maybe<Scalars['Float']['output']>;
  created?: Maybe<Scalars['Instant']['output']>;
  dietType?: Maybe<DietType>;
  exercise?: Maybe<Scalars['Boolean']['output']>;
  exercises?: Maybe<Array<Maybe<Exercise>>>;
  heightInCM?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  member?: Maybe<HealthProgramMember>;
  modified?: Maybe<Scalars['Instant']['output']>;
  patient?: Maybe<Patient>;
  sleep?: Maybe<Scalars['Int']['output']>;
  smoking?: Maybe<Scalars['Int']['output']>;
  user?: Maybe<User>;
  weightInKG?: Maybe<Scalars['Float']['output']>;
};

export type HealthProgramMemberBodyCompositionBasic = {
  __typename?: 'HealthProgramMemberBodyCompositionBasic';
  alcohol?: Maybe<Scalars['Boolean']['output']>;
  alcoholCupsPerDay?: Maybe<Scalars['Int']['output']>;
  bmi?: Maybe<Scalars['Float']['output']>;
  dietType?: Maybe<DietType>;
  exercise?: Maybe<Scalars['Boolean']['output']>;
  exercises?: Maybe<Array<Maybe<Exercise>>>;
  heightInCM?: Maybe<Scalars['Float']['output']>;
  sleep?: Maybe<Scalars['Int']['output']>;
  smoking?: Maybe<Scalars['Int']['output']>;
  weightInKG?: Maybe<Scalars['Float']['output']>;
};

export type HealthProgramMemberBodyCompositionCrud = {
  __typename?: 'HealthProgramMemberBodyCompositionCRUD';
  entity?: Maybe<HealthProgramMemberBodyComposition>;
  entityErrors?: Maybe<Array<H_EntityError>>;
  errors?: Maybe<Array<H_Error>>;
};

export type HealthProgramMemberBodyCompositionCountableConnection = {
  __typename?: 'HealthProgramMemberBodyCompositionCountableConnection';
  edges: Array<HealthProgramMemberBodyCompositionCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type HealthProgramMemberBodyCompositionCountableEdge = {
  __typename?: 'HealthProgramMemberBodyCompositionCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: HealthProgramMemberBodyComposition;
};

export type HealthProgramMemberBodyCompositionExerciseInput = {
  exerciseDuration: Scalars['Int']['input'];
  exerciseDurationUnit: ExerciseDurationUnit;
  exerciseFrequencyDayWeek: Scalars['Int']['input'];
  exerciseType: ExerciseType;
};

export type HealthProgramMemberBodyCompositionFilterInput = {
  healthProgramMembers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  patients?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type HealthProgramMemberBodyCompositionInput = {
  alcohol?: InputMaybe<Scalars['Boolean']['input']>;
  alcoholCupsPerDay?: InputMaybe<Scalars['Int']['input']>;
  dietType?: InputMaybe<DietType>;
  exercise?: InputMaybe<Scalars['Boolean']['input']>;
  exercises?: InputMaybe<Array<InputMaybe<HealthProgramMemberBodyCompositionExerciseInput>>>;
  heightInCM?: InputMaybe<Scalars['Float']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  sleep?: InputMaybe<Scalars['Int']['input']>;
  smoking?: InputMaybe<Scalars['Int']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  weightInKG?: InputMaybe<Scalars['Float']['input']>;
};

export type HealthProgramMemberCrud = {
  __typename?: 'HealthProgramMemberCRUD';
  details?: Maybe<Scalars['String']['output']>;
  entity?: Maybe<HealthProgramMember>;
  entityErrors?: Maybe<Array<H_EntityError>>;
  errors?: Maybe<Array<H_Error>>;
};

export type HealthProgramMemberCountableConnection = {
  __typename?: 'HealthProgramMemberCountableConnection';
  edges: Array<HealthProgramMemberCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type HealthProgramMemberCountableEdge = {
  __typename?: 'HealthProgramMemberCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: HealthProgramMember;
};

export type HealthProgramMemberFilterInput = {
  chronicDiseases?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  coverageBys?: InputMaybe<Array<InputMaybe<CoverageByType>>>;
  dateOfBirth?: InputMaybe<Scalars['InstantNotFuture']['input']>;
  disabilities?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  email?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<H_Gender>;
  healthProgramMemberLists?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  insuranceCompanyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  insuranceCompanyLicenseNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  insuranceId?: InputMaybe<Scalars['String']['input']>;
  insurancePolicyNumber?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  membershipEnd?: InputMaybe<H_DateTimeRangeInput>;
  membershipStart?: InputMaybe<H_DateTimeRangeInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  nationalId?: InputMaybe<Scalars['String']['input']>;
  patients?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  phone?: InputMaybe<Scalars['String']['input']>;
  relationTypes?: InputMaybe<Array<InputMaybe<H_RelationTypes>>>;
  teamId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type HealthProgramMemberInput = {
  backInsuranceCardPhoto?: InputMaybe<Scalars['String']['input']>;
  categoryCode?: InputMaybe<Scalars['String']['input']>;
  chronicDiseases?: InputMaybe<Array<Scalars['String']['input']>>;
  company?: InputMaybe<Scalars['String']['input']>;
  coverageBy: CoverageByType;
  dateOfBirth?: InputMaybe<Scalars['InstantNotFuture']['input']>;
  disabilities?: InputMaybe<Array<Scalars['String']['input']>>;
  documentExpiryDate: Scalars['Instant']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  frontInsuranceCardPhoto?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<H_Gender>;
  healthProgramMemberLists?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  idType: H_IdTypes;
  insuranceCategory?: InputMaybe<Scalars['String']['input']>;
  insuranceClass?: InputMaybe<Scalars['String']['input']>;
  insuranceCompanyId?: InputMaybe<Scalars['ID']['input']>;
  insuranceCompanyLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  insuranceId: Scalars['String']['input'];
  insurancePolicyNumber?: InputMaybe<Scalars['String']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  lastName: Scalars['String']['input'];
  memberType?: InputMaybe<Scalars['String']['input']>;
  membershipEnd: Scalars['Instant']['input'];
  membershipStart: Scalars['Instant']['input'];
  nationalId: Scalars['String']['input'];
  nationality: Scalars['String']['input'];
  network?: InputMaybe<Scalars['String']['input']>;
  parentNationalId?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  relationType?: InputMaybe<H_RelationTypes>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  sehacityId?: InputMaybe<Scalars['String']['input']>;
};

export type HealthProgramMemberList = H_Node & {
  __typename?: 'HealthProgramMemberList';
  created?: Maybe<Scalars['Instant']['output']>;
  createdByUser?: Maybe<User>;
  description?: Maybe<Scalars['String']['output']>;
  healthProgramMembers?: Maybe<HealthProgramMemberCountableConnection>;
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['Instant']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  payer?: Maybe<Payer>;
};


export type HealthProgramMemberListHealthProgramMembersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type HealthProgramMemberListCrud = {
  __typename?: 'HealthProgramMemberListCRUD';
  entity?: Maybe<HealthProgramMemberList>;
  entityErrors?: Maybe<Array<H_EntityError>>;
  errors?: Maybe<Array<H_Error>>;
};

export type HealthProgramMemberListCountableConnection = {
  __typename?: 'HealthProgramMemberListCountableConnection';
  edges: Array<HealthProgramMemberListCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type HealthProgramMemberListCountableEdge = {
  __typename?: 'HealthProgramMemberListCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: HealthProgramMemberList;
};

export type HealthProgramMemberListFilterInput = {
  healthPrograms?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  members?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  payers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type HealthProgramMemberListInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  payerId?: InputMaybe<Scalars['ID']['input']>;
};

export enum HealthProgramMemberListSortingField {
  Created = 'CREATED',
  IsActive = 'IS_ACTIVE',
  Name = 'NAME',
  Updated = 'UPDATED'
}

export type HealthProgramMemberListSortingInput = {
  direction: H_OrderDirection;
  field?: InputMaybe<HealthProgramMemberListSortingField>;
};

export type HealthProgramMemberListUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  payerId?: InputMaybe<Scalars['ID']['input']>;
};

export enum HealthProgramMemberSortingField {
  Created = 'CREATED',
  InsuranceId = 'INSURANCE_ID',
  Name = 'NAME',
  NationalId = 'NATIONAL_ID',
  Updated = 'UPDATED'
}

export type HealthProgramMemberSortingInput = {
  direction: H_OrderDirection;
  field?: InputMaybe<HealthProgramMemberSortingField>;
};

export type HealthProgramMembershipRequest = H_Node & {
  __typename?: 'HealthProgramMembershipRequest';
  backInsuranceCardPhoto?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['Instant']['output']>;
  createdByUser?: Maybe<User>;
  dateOfBirth?: Maybe<Scalars['InstantNoTime']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  frontInsuranceCardPhoto?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<H_Gender>;
  healthProgramMember?: Maybe<HealthProgramMember>;
  id: Scalars['ID']['output'];
  insuranceCategory?: Maybe<Scalars['String']['output']>;
  insuranceClass?: Maybe<Scalars['String']['output']>;
  insuranceCompanyId?: Maybe<Scalars['H_Long']['output']>;
  insuranceCompanyLicenseNumber?: Maybe<Scalars['String']['output']>;
  insuranceId?: Maybe<Scalars['String']['output']>;
  insurancePolicyNumber?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  modified?: Maybe<Scalars['Instant']['output']>;
  nationalId?: Maybe<Scalars['String']['output']>;
  patient?: Maybe<Patient>;
  payer?: Maybe<Payer>;
  phone?: Maybe<Scalars['String']['output']>;
  rejectionReason?: Maybe<Scalars['String']['output']>;
  status?: Maybe<RequestStatus>;
  user?: Maybe<User>;
};

export type HealthProgramMembershipRequestApproveInput = {
  coverageBy: CoverageByType;
  insuranceCategory?: InputMaybe<Scalars['String']['input']>;
  insuranceCategoryCode?: InputMaybe<Scalars['String']['input']>;
  insuranceClass?: InputMaybe<Scalars['String']['input']>;
  insurancePolicyNumber?: InputMaybe<Scalars['String']['input']>;
  membershipEnd: Scalars['Instant']['input'];
  membershipStart: Scalars['Instant']['input'];
  sehacityId?: InputMaybe<Scalars['String']['input']>;
};

export type HealthProgramMembershipRequestCrud = {
  __typename?: 'HealthProgramMembershipRequestCRUD';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  healthProgramMembershipRequest?: Maybe<HealthProgramMembershipRequest>;
  healthProgramMembershipRequestErrors?: Maybe<Array<H_EntityError>>;
};

export type HealthProgramMembershipRequestConnection = {
  __typename?: 'HealthProgramMembershipRequestConnection';
  edges: Array<HealthProgramMembershipRequestCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type HealthProgramMembershipRequestCountableEdge = {
  __typename?: 'HealthProgramMembershipRequestCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  node: HealthProgramMembershipRequest;
};

export type HealthProgramMembershipRequestFilterInput = {
  coverageBys?: InputMaybe<Array<InputMaybe<CoverageByType>>>;
  insuranceCompanyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  insuranceCompanyLicenseNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  insuranceId?: InputMaybe<Scalars['String']['input']>;
  membershipEnd?: InputMaybe<H_DateTimeRangeInput>;
  membershipStart?: InputMaybe<H_DateTimeRangeInput>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<RequestStatus>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type HealthProgramMembershipRequestInput = {
  backInsuranceCardPhoto?: InputMaybe<Scalars['String']['input']>;
  frontInsuranceCardPhoto: Scalars['String']['input'];
  insuranceCompanyId: Scalars['ID']['input'];
  insuranceId: Scalars['String']['input'];
};

export enum HealthProgramMembershipRequestSortingField {
  Created = 'CREATED',
  InsuranceId = 'INSURANCE_ID',
  Name = 'NAME',
  NationalId = 'NATIONAL_ID',
  Updated = 'UPDATED'
}

export type HealthProgramMembershipRequestSortingInput = {
  direction: H_OrderDirection;
  field?: InputMaybe<HealthProgramMembershipRequestSortingField>;
};

export type HealthProgramNetwork = H_Node & {
  __typename?: 'HealthProgramNetwork';
  categoryCode?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['Instant']['output']>;
  createdByUser?: Maybe<User>;
  healthProgramNetworkProviders?: Maybe<HealthProgramNetworkProviderCountableConnection>;
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['Instant']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  payer?: Maybe<Payer>;
  policyNumber?: Maybe<Scalars['String']['output']>;
  providerCount?: Maybe<Scalars['Int']['output']>;
};


export type HealthProgramNetworkHealthProgramNetworkProvidersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type HealthProgramNetworkCrud = {
  __typename?: 'HealthProgramNetworkCRUD';
  entity?: Maybe<HealthProgramNetwork>;
  entityErrors?: Maybe<Array<H_EntityError>>;
  errors?: Maybe<Array<H_Error>>;
};

export type HealthProgramNetworkCountableConnection = {
  __typename?: 'HealthProgramNetworkCountableConnection';
  edges: Array<HealthProgramNetworkCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type HealthProgramNetworkCountableEdge = {
  __typename?: 'HealthProgramNetworkCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: HealthProgramNetwork;
};

export type HealthProgramNetworkFilterInput = {
  healthPrograms?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  payers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  providers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type HealthProgramNetworkInput = {
  categoryCode?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  payerId?: InputMaybe<Scalars['ID']['input']>;
  policyNumber?: InputMaybe<Scalars['String']['input']>;
};

export type HealthProgramNetworkProvider = H_Node & {
  __typename?: 'HealthProgramNetworkProvider';
  created?: Maybe<Scalars['Instant']['output']>;
  createdByUser?: Maybe<User>;
  healthProgramNetwork?: Maybe<HealthProgramNetwork>;
  healthProgramNetworkProviderProfessionals?: Maybe<HealthProgramNetworkProviderProfessionalCountableConnection>;
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['Instant']['output']>;
  provider?: Maybe<Vendor>;
  services?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  virtualCallEnabled?: Maybe<Scalars['Boolean']['output']>;
};


export type HealthProgramNetworkProviderHealthProgramNetworkProviderProfessionalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<HealthProgramNetworkProviderProfessionalFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type HealthProgramNetworkProviderCrud = {
  __typename?: 'HealthProgramNetworkProviderCRUD';
  entity?: Maybe<HealthProgramNetworkProvider>;
  entityErrors?: Maybe<Array<H_EntityError>>;
  errors?: Maybe<Array<H_Error>>;
};

export type HealthProgramNetworkProviderCountableConnection = {
  __typename?: 'HealthProgramNetworkProviderCountableConnection';
  edges: Array<HealthProgramNetworkProviderCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type HealthProgramNetworkProviderCountableEdge = {
  __typename?: 'HealthProgramNetworkProviderCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: HealthProgramNetworkProvider;
};

export type HealthProgramNetworkProviderFilterInput = {
  healthProgramNetworks?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  professionals?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  providers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  services?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  virtualCallEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HealthProgramNetworkProviderInput = {
  healthProgramNetwork?: InputMaybe<Scalars['ID']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  providerId?: InputMaybe<Scalars['ID']['input']>;
  services?: InputMaybe<Array<Scalars['String']['input']>>;
  virtualCallEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type HealthProgramNetworkProviderProfessional = H_Node & {
  __typename?: 'HealthProgramNetworkProviderProfessional';
  created?: Maybe<Scalars['Instant']['output']>;
  createdByUser?: Maybe<User>;
  healthProgramNetworkProvider?: Maybe<HealthProgramNetworkProvider>;
  id: Scalars['ID']['output'];
  modified?: Maybe<Scalars['Instant']['output']>;
  overridingServices?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  overridingVirtualCallEnabled?: Maybe<Scalars['Boolean']['output']>;
  professional?: Maybe<Doctor>;
};

export type HealthProgramNetworkProviderProfessionalCrud = {
  __typename?: 'HealthProgramNetworkProviderProfessionalCRUD';
  entity?: Maybe<HealthProgramNetworkProviderProfessional>;
  entityErrors?: Maybe<Array<H_EntityError>>;
  errors?: Maybe<Array<H_Error>>;
};

export type HealthProgramNetworkProviderProfessionalCountableConnection = {
  __typename?: 'HealthProgramNetworkProviderProfessionalCountableConnection';
  edges: Array<HealthProgramNetworkProviderProfessionalCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type HealthProgramNetworkProviderProfessionalCountableEdge = {
  __typename?: 'HealthProgramNetworkProviderProfessionalCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: HealthProgramNetworkProviderProfessional;
};

export type HealthProgramNetworkProviderProfessionalFilterInput = {
  healthProgramNetworkProviders?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  healthProgramNetworks?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  overridingServices?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  overridingVirtualCallEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  professionals?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  providers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type HealthProgramNetworkProviderProfessionalInput = {
  healthProgramNetworkProvider?: InputMaybe<Scalars['ID']['input']>;
  overridingServices?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  overridingVirtualCallEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  professionalId?: InputMaybe<Scalars['ID']['input']>;
};

export enum HealthProgramNetworkSortingField {
  Code = 'CODE',
  Created = 'CREATED',
  IsActive = 'IS_ACTIVE',
  Name = 'NAME',
  Updated = 'UPDATED'
}

export type HealthProgramNetworkSortingInput = {
  direction: H_OrderDirection;
  field?: InputMaybe<HealthProgramNetworkSortingField>;
};

export type HealthProgramNetworkUpdateInput = {
  categoryCode?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  payerId?: InputMaybe<Scalars['ID']['input']>;
  policyNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum HealthProgramOptionType {
  Include = 'INCLUDE',
  Limitation = 'LIMITATION'
}

export type HealthProgramPointsFilterInput = {
  doctorId?: InputMaybe<Scalars['String']['input']>;
  healthProgramId?: InputMaybe<Scalars['Int']['input']>;
  month: Scalars['Int']['input'];
  vendorId?: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
};

export enum HealthProgramSortingField {
  Code = 'CODE',
  Created = 'CREATED',
  IsActive = 'IS_ACTIVE',
  Name = 'NAME',
  Type = 'TYPE',
  Updated = 'UPDATED'
}

export type HealthProgramSortingInput = {
  direction: H_OrderDirection;
  field?: InputMaybe<HealthProgramSortingField>;
};

export type HealthProgramSpecialization = H_Node & {
  __typename?: 'HealthProgramSpecialization';
  created?: Maybe<Scalars['Instant']['output']>;
  id: Scalars['ID']['output'];
  modified?: Maybe<Scalars['Instant']['output']>;
  program?: Maybe<HealthProgram>;
  specialization?: Maybe<T_Specialization>;
};

/** Subscribe user to Health Program */
export type HealthProgramSubscribe = {
  __typename?: 'HealthProgramSubscribe';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  healthProgramErrors?: Maybe<Array<H_EntityError>>;
  healthProgramUserSubscription?: Maybe<HealthProgramUserSubscription>;
};

export type HealthProgramSubscribeInput = {
  insuranceMemberId?: InputMaybe<Scalars['String']['input']>;
  period: HealthProgramSubscriptionPeriod;
  /** program id to subscribe to */
  program?: InputMaybe<Scalars['ID']['input']>;
};

export enum HealthProgramSubscriptionPeriod {
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY'
}

export type HealthProgramTemplate = H_Node & {
  __typename?: 'HealthProgramTemplate';
  active?: Maybe<Scalars['Boolean']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  guidedCareType?: Maybe<GuidedCareType>;
  healthCondition?: Maybe<HealthCondition>;
  id: Scalars['ID']['output'];
  isDraft?: Maybe<Scalars['Boolean']['output']>;
  isLatestVersion?: Maybe<Scalars['Boolean']['output']>;
  isRenewable?: Maybe<Scalars['Boolean']['output']>;
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  lastModifiedDate?: Maybe<Scalars['Instant']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  riskFactorTemplate?: Maybe<RiskFactorTemplate>;
  standardCode?: Maybe<Scalars['String']['output']>;
  standardName?: Maybe<Scalars['String']['output']>;
  standardURL?: Maybe<Scalars['String']['output']>;
  templateActivities?: Maybe<Array<Maybe<TemplateActivity>>>;
  templateDuration?: Maybe<Scalars['Int']['output']>;
  templateGoals?: Maybe<Array<Maybe<TemplateGoal>>>;
  templateInterventions?: Maybe<Array<Maybe<TemplateIntervention>>>;
  templateTeamMembers?: Maybe<Array<Maybe<TemplateTeamMember>>>;
  version?: Maybe<Scalars['Int']['output']>;
};

/** CRUD a new Health Program Template */
export type HealthProgramTemplateCrud = {
  __typename?: 'HealthProgramTemplateCRUD';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  healthProgramTemplate?: Maybe<HealthProgramTemplate>;
  healthProgramTemplateErrors?: Maybe<Array<H_EntityError>>;
};

export type HealthProgramTemplateCountableConnection = {
  __typename?: 'HealthProgramTemplateCountableConnection';
  edges: Array<HealthProgramTemplateCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type HealthProgramTemplateCountableEdge = {
  __typename?: 'HealthProgramTemplateCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: HealthProgramTemplate;
};

export type HealthProgramTemplateFilterInput = {
  createdBy?: InputMaybe<Scalars['String']['input']>;
  creationDate?: InputMaybe<H_DateTimeRangeInput>;
  guidedCareType?: InputMaybe<GuidedCareType>;
  healthConditionId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isDraft?: InputMaybe<Scalars['Boolean']['input']>;
  isVerified?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
};

export type HealthProgramTemplateInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  guidedCareType?: InputMaybe<GuidedCareType>;
  healthConditionId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isRenewable?: InputMaybe<Scalars['Boolean']['input']>;
  isVerified?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  riskFactorTemplateId?: InputMaybe<Scalars['ID']['input']>;
  standardCode?: InputMaybe<Scalars['String']['input']>;
  standardName?: InputMaybe<Scalars['String']['input']>;
  standardURL?: InputMaybe<Scalars['String']['input']>;
  templateActivities?: InputMaybe<Array<InputMaybe<TemplateActivityInput>>>;
  templateDuration: Scalars['Int']['input'];
  templateGoals?: InputMaybe<Array<InputMaybe<TemplateGoalInput>>>;
  templateInterventions?: InputMaybe<Array<InputMaybe<TemplateInterventionInput>>>;
  templateTeamMembers?: InputMaybe<Array<InputMaybe<TemplateTeamMemberInput>>>;
  version?: InputMaybe<Scalars['Int']['input']>;
};

export type HealthProgramTemplateInterventionCountableConnection = {
  __typename?: 'HealthProgramTemplateInterventionCountableConnection';
  edges: Array<HealthProgramTemplateInterventionCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type HealthProgramTemplateInterventionCountableEdge = {
  __typename?: 'HealthProgramTemplateInterventionCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: TemplateIntervention;
};

export type HealthProgramTemplateInterventionFilterInput = {
  excludedIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  goalId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameAr?: InputMaybe<Scalars['String']['input']>;
  recommendationId?: InputMaybe<Scalars['ID']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
};

export enum HealthProgramTemplateSortingField {
  Created = 'CREATED',
  GuidedCareType = 'GUIDED_CARE_TYPE',
  Id = 'ID',
  IsDraft = 'IS_DRAFT',
  Modified = 'MODIFIED',
  Name = 'NAME'
}

export type HealthProgramTemplateSortingInput = {
  direction: H_OrderDirection;
  field?: InputMaybe<HealthProgramTemplateSortingField>;
};

export type HealthProgramTotalPointsTrackingConnection = {
  __typename?: 'HealthProgramTotalPointsTrackingConnection';
  doctorId?: Maybe<Scalars['Int']['output']>;
  edges: Array<HealthProgramTotalPointsTrackingCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
  totalPoints?: Maybe<Scalars['Int']['output']>;
};

export type HealthProgramTotalPointsTrackingCountableEdge = {
  __typename?: 'HealthProgramTotalPointsTrackingCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  node: HealthProgramTotalPointsTrackingResponse;
};

export type HealthProgramTotalPointsTrackingResponse = {
  __typename?: 'HealthProgramTotalPointsTrackingResponse';
  doctor?: Maybe<Doctor>;
  healthProgramId?: Maybe<Scalars['String']['output']>;
  healthProgramName?: Maybe<Scalars['String']['output']>;
  percentage?: Maybe<Scalars['Int']['output']>;
  totalPoints?: Maybe<Scalars['Int']['output']>;
  vendor?: Maybe<Vendor>;
};

export enum HealthProgramType {
  ImmediateCare = 'IMMEDIATE_CARE',
  ScheduledCare = 'SCHEDULED_CARE'
}

export type HealthProgramUnSubscribe = {
  __typename?: 'HealthProgramUnSubscribe';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  healthProgramErrors?: Maybe<Array<H_EntityError>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type HealthProgramUpdateInput = {
  /** health program back ground image */
  backGroundImage?: InputMaybe<Scalars['Upload']['input']>;
  baselineAvailabilityHours?: InputMaybe<Scalars['Int']['input']>;
  benefitGroups?: InputMaybe<Array<InputMaybe<HealthProgramBenefitGroupInput>>>;
  callQualityRatingFactors?: InputMaybe<Scalars['String']['input']>;
  careFor?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  code: Scalars['String']['input'];
  copay?: InputMaybe<Scalars['Boolean']['input']>;
  copayAmount?: InputMaybe<Scalars['Float']['input']>;
  copayPercentage?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  exclusionOptions?: InputMaybe<Array<InputMaybe<HealthProgramExclusionOptionInput>>>;
  healthProgramMemberLists?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  healthProgramNetworks?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** health program icon */
  icon?: InputMaybe<Scalars['Upload']['input']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  insuranceNetworks?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  mainSpecializationsRateFactors?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  operatorProfitSharePercentage?: InputMaybe<Scalars['Float']['input']>;
  payerId?: InputMaybe<Scalars['ID']['input']>;
  platformProfitSharePercentage?: InputMaybe<Scalars['Float']['input']>;
  pointsDuringNormalDemand?: InputMaybe<Scalars['Float']['input']>;
  pointsDuringUrgentDemand?: InputMaybe<Scalars['Float']['input']>;
  prescriptionEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  priceAmount?: InputMaybe<Scalars['Float']['input']>;
  referralsToSpecialistsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  requiresIdValidation?: InputMaybe<Scalars['Boolean']['input']>;
  service?: InputMaybe<Scalars['String']['input']>;
  specializations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subscriptionPeriod?: InputMaybe<HealthProgramSubscriptionPeriod>;
  targetGroup?: InputMaybe<Scalars['String']['input']>;
  unattendedCallPenaltyFactor?: InputMaybe<Scalars['Float']['input']>;
  vendorProfitSharePercentage?: InputMaybe<Scalars['Float']['input']>;
};

export type HealthProgramUserSubscription = H_Node & {
  __typename?: 'HealthProgramUserSubscription';
  consumerId?: Maybe<Scalars['Int']['output']>;
  created?: Maybe<Scalars['Instant']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  insuranceMemberId?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['Instant']['output']>;
  period?: Maybe<Scalars['Int']['output']>;
  program?: Maybe<HealthProgram>;
  total?: Maybe<H_Money>;
  totalAmount?: Maybe<Scalars['Float']['output']>;
  user?: Maybe<User>;
  validTill?: Maybe<Scalars['Instant']['output']>;
  visits?: Maybe<VisitCountableConnection>;
};


export type HealthProgramUserSubscriptionVisitsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type HealthProgramUserSubscriptionCountableConnection = {
  __typename?: 'HealthProgramUserSubscriptionCountableConnection';
  edges: Array<HealthProgramUserSubscriptionCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type HealthProgramUserSubscriptionCountableEdge = {
  __typename?: 'HealthProgramUserSubscriptionCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: HealthProgramUserSubscription;
};

export type HealthProgramUserSubscriptionFilterInput = {
  consumers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  healthProgram?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type HealthProgramVendorPointsFilterInput = {
  healthProgramId?: InputMaybe<Scalars['Int']['input']>;
  month: Scalars['Int']['input'];
  vendorId?: InputMaybe<Scalars['String']['input']>;
  year: Scalars['Int']['input'];
};

export type HealthProgramVendorTotalPointsTrackingConnection = {
  __typename?: 'HealthProgramVendorTotalPointsTrackingConnection';
  edges: Array<HealthProgramVendorTotalPointsTrackingCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type HealthProgramVendorTotalPointsTrackingCountableEdge = {
  __typename?: 'HealthProgramVendorTotalPointsTrackingCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  node: HealthProgramVendorTotalPointsTrackingResponse;
};

export type HealthProgramVendorTotalPointsTrackingResponse = {
  __typename?: 'HealthProgramVendorTotalPointsTrackingResponse';
  healthProgramId?: Maybe<Scalars['String']['output']>;
  healthProgramName?: Maybe<Scalars['String']['output']>;
  percentage?: Maybe<Scalars['Int']['output']>;
  profitShare?: Maybe<Scalars['Float']['output']>;
  totalPoints?: Maybe<Scalars['Int']['output']>;
  vendor?: Maybe<Vendor>;
};

export type HealthSymptom = Node & {
  __typename?: 'HealthSymptom';
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  specializations?: Maybe<Array<Maybe<HealthSymptomSpecialization>>>;
};

export type HealthSymptomCountableConnection = {
  __typename?: 'HealthSymptomCountableConnection';
  edges: Array<HealthSymptomCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type HealthSymptomCountableEdge = {
  __typename?: 'HealthSymptomCountableEdge';
  cursor: Scalars['String']['output'];
  node: HealthSymptom;
};

export type HealthSymptomCreate = {
  __typename?: 'HealthSymptomCreate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  healthSymptom?: Maybe<HealthSymptom>;
  healthSymptomErrors: Array<HealthSymptomError>;
};

export type HealthSymptomDelete = {
  __typename?: 'HealthSymptomDelete';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  healthSymptom?: Maybe<HealthSymptom>;
  healthSymptomErrors: Array<HealthSymptomError>;
};

export type HealthSymptomError = {
  __typename?: 'HealthSymptomError';
  code: HealthSymptomErrorCode;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum HealthSymptomErrorCode {
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type HealthSymptomFilterInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  specializations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type HealthSymptomInput = {
  name: Scalars['String']['input'];
  specializations?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type HealthSymptomOrder = {
  direction: OrderDirection;
  field?: InputMaybe<HealthSymptomOrderField>;
};

export enum HealthSymptomOrderField {
  Created = 'CREATED',
  Name = 'NAME'
}

export type HealthSymptomSpecialization = Node & {
  __typename?: 'HealthSymptomSpecialization';
  arabicDisplay?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  display?: Maybe<Scalars['String']['output']>;
  healthSymptom?: Maybe<HealthSymptom>;
  id: Scalars['ID']['output'];
};

export type HealthSymptomSpecializationDelete = {
  __typename?: 'HealthSymptomSpecializationDelete';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  healthSymptomSpecialization?: Maybe<HealthSymptomSpecialization>;
  specializationErrors: Array<SpecializationError>;
};

export type HealthSymptomSpecializationsCreate = {
  __typename?: 'HealthSymptomSpecializationsCreate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  healthSymptom?: Maybe<HealthSymptom>;
  specializationErrors: Array<SpecializationError>;
};

export type HealthSymptomUpdate = {
  __typename?: 'HealthSymptomUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  healthSymptom?: Maybe<HealthSymptom>;
  healthSymptomErrors: Array<HealthSymptomError>;
};

export type IdealBodyWeightInput = {
  gender: Gender;
  height: Scalars['Float']['input'];
};

export type IdealBodyWeightInputLog = {
  __typename?: 'IdealBodyWeightInputLog';
  gender: Gender;
  height: Scalars['Float']['output'];
};

export type IdealBodyWeightResponse = {
  __typename?: 'IdealBodyWeightResponse';
  idealBodyWeight?: Maybe<Scalars['Float']['output']>;
};

export type IdealBodyWeightResult = {
  __typename?: 'IdealBodyWeightResult';
  createdDate?: Maybe<Scalars['Instant']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  idealBodyWeight?: Maybe<Scalars['Double']['output']>;
  queryInput?: Maybe<IdealBodyWeightInputLog>;
};

export type IdealBodyWeightResultConnection = {
  __typename?: 'IdealBodyWeightResultConnection';
  edges: Array<IdealBodyWeightResultEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type IdealBodyWeightResultEdge = {
  __typename?: 'IdealBodyWeightResultEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: IdealBodyWeightResult;
};

export type InsuranceNetwork = H_Node & {
  __typename?: 'InsuranceNetwork';
  created?: Maybe<Scalars['Instant']['output']>;
  facilities?: Maybe<InsuranceNetworkFacilityCountableConnection>;
  healthPrograms?: Maybe<HealthProgramCountableConnection>;
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['Instant']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  payer?: Maybe<Payer>;
};


export type InsuranceNetworkFacilitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type InsuranceNetworkHealthProgramsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

/** CRUD a new InsuranceNetwork */
export type InsuranceNetworkCrud = {
  __typename?: 'InsuranceNetworkCRUD';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  insuranceNetwork?: Maybe<InsuranceNetwork>;
  insuranceNetworkErrors?: Maybe<Array<H_EntityError>>;
};

export type InsuranceNetworkCountableConnection = {
  __typename?: 'InsuranceNetworkCountableConnection';
  edges: Array<InsuranceNetworkCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type InsuranceNetworkCountableEdge = {
  __typename?: 'InsuranceNetworkCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: InsuranceNetwork;
};

export type InsuranceNetworkFacility = H_Node & {
  __typename?: 'InsuranceNetworkFacility';
  created?: Maybe<Scalars['Instant']['output']>;
  facility?: Maybe<Vendor>;
  facilityId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  insuranceNetwork?: Maybe<InsuranceNetwork>;
  modified?: Maybe<Scalars['Instant']['output']>;
};

export type InsuranceNetworkFacilityCountableConnection = {
  __typename?: 'InsuranceNetworkFacilityCountableConnection';
  edges: Array<InsuranceNetworkFacilityCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type InsuranceNetworkFacilityCountableEdge = {
  __typename?: 'InsuranceNetworkFacilityCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: InsuranceNetworkFacility;
};

export type InsuranceNetworkFacilityFilterInput = {
  facilities?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  insuranceNetworks?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  insurances?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type InsuranceNetworkFilterInput = {
  facilities?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  healthPrograms?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  payer?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type InsuranceNetworkInput = {
  facilities?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** is InsuranceNetwork Active */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** InsuranceNetwork Name */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Payer ID. */
  payerId?: InputMaybe<Scalars['ID']['input']>;
};

export enum InsuranceSortField {
  /** Sort by creation date. */
  CreationDate = 'CREATION_DATE',
  /** Sort by name. */
  Name = 'NAME'
}

export type InsuranceSortingInput = {
  /** Specifies the direction in which to sort products. */
  direction: H_OrderDirection;
  /** Sort insurance by the selected field. */
  field: InsuranceSortField;
};

export type IntRangeInput = {
  gte?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
};

export enum IntegrationErrorCodes {
  IntegrationActivityCodeNotFound = 'INTEGRATION_ACTIVITY_CODE_NOT_FOUND',
  IntegrationClinicianCredentialsNotFound = 'INTEGRATION_CLINICIAN_CREDENTIALS_NOT_FOUND',
  IntegrationDateOfBirthNotDefined = 'INTEGRATION_DATE_OF_BIRTH_NOT_DEFINED',
  IntegrationDuplicateBranchCredentials = 'INTEGRATION_DUPLICATE_BRANCH_CREDENTIALS',
  IntegrationErxValidation = 'INTEGRATION_ERX_VALIDATION',
  IntegrationInvalidOrExpiredToken = 'INTEGRATION_INVALID_OR_EXPIRED_TOKEN',
  IntegrationMemberNotActive = 'INTEGRATION_MEMBER_NOT_ACTIVE',
  IntegrationNoneNegativePageSize = 'INTEGRATION_NONE_NEGATIVE_PAGE_SIZE',
  IntegrationNullPointer = 'INTEGRATION_NULL_POINTER',
  IntegrationOperationNotAllowed = 'INTEGRATION_OPERATION_NOT_ALLOWED',
  IntegrationPatientNotFound = 'INTEGRATION_PATIENT_NOT_FOUND',
  IntegrationPayerLicenseNotDefined = 'INTEGRATION_PAYER_LICENSE_NOT_DEFINED',
  IntegrationPermissionDenied = 'INTEGRATION_PERMISSION_DENIED',
  IntegrationUnknown = 'INTEGRATION_UNKNOWN',
  IntegrationUnknownType = 'INTEGRATION_UNKNOWN_TYPE',
  IntegrationValidation = 'INTEGRATION_VALIDATION',
  InvalidClinicianCredentials = 'INVALID_CLINICIAN_CREDENTIALS'
}

export type IntegrationGraphqlError = {
  __typename?: 'IntegrationGraphqlError';
  code?: Maybe<IntegrationErrorCodes>;
  errorType?: Maybe<ErrorType>;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type InternalEmailNotificationBody = {
  __typename?: 'InternalEmailNotificationBody';
  body?: Maybe<Scalars['String']['output']>;
  recipientEmail?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type InternalEmailNotificationConverted = {
  __typename?: 'InternalEmailNotificationConverted';
  notification?: Maybe<InternalEmailNotificationBody>;
  recipientUserId?: Maybe<Scalars['ID']['output']>;
};

export type InternalSmsNotificationBody = {
  __typename?: 'InternalSMSNotificationBody';
  body?: Maybe<Scalars['String']['output']>;
  recipientMobileNumber?: Maybe<Scalars['String']['output']>;
};

export type InternalSmsNotificationConverted = {
  __typename?: 'InternalSMSNotificationConverted';
  notification?: Maybe<InternalSmsNotificationBody>;
  recipientUserId?: Maybe<Scalars['ID']['output']>;
};

export enum InterventionActivationType {
  Merge = 'MERGE',
  Replace = 'REPLACE'
}

export enum ItemType {
  Consumable = 'CONSUMABLE',
  Dental = 'DENTAL',
  Drg = 'DRG',
  GenericDrug = 'GENERIC_DRUG',
  Procedure = 'PROCEDURE',
  Service = 'SERVICE',
  TradeDrug = 'TRADE_DRUG'
}

export enum JourneyItemClassification {
  Essential = 'ESSENTIAL',
  Supportive = 'SUPPORTIVE'
}

export type KeyCloakPermission = Node & {
  __typename?: 'KeyCloakPermission';
  id: Scalars['ID']['output'];
  isAggregator: Scalars['Boolean']['output'];
  isClient?: Maybe<Scalars['Boolean']['output']>;
  isStaff?: Maybe<Scalars['Boolean']['output']>;
  isVendor?: Maybe<Scalars['Boolean']['output']>;
  keycloakRoleId?: Maybe<Scalars['String']['output']>;
};

export type KeycloakPermissionConfigurationInput = {
  isAggregator?: InputMaybe<Scalars['Boolean']['input']>;
  isClient?: InputMaybe<Scalars['Boolean']['input']>;
  isStaff?: InputMaybe<Scalars['Boolean']['input']>;
  isVendor?: InputMaybe<Scalars['Boolean']['input']>;
  keycloakRoleId?: InputMaybe<Scalars['String']['input']>;
};

export type KeycloakPermissionConfigurationUpdate = {
  __typename?: 'KeycloakPermissionConfigurationUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  keyCloakPermission?: Maybe<KeyCloakPermission>;
  permissionGroupErrors: Array<PermissionGroupError>;
};

export enum KeywordSearch {
  All = 'ALL',
  Code = 'CODE',
  Display = 'DISPLAY'
}

export type Lab = H_Node & {
  __typename?: 'Lab';
  code?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['Instant']['output']>;
  display?: Maybe<Scalars['String']['output']>;
  evaluatedTemplate?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  modified?: Maybe<Scalars['Instant']['output']>;
  result?: Maybe<Scalars['String']['output']>;
  resultReceived?: Maybe<Scalars['Boolean']['output']>;
  visit?: Maybe<Visit>;
};

export type LabCrud = {
  __typename?: 'LabCRUD';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  evaluatedLabTemplate?: Maybe<EvaluatedLabTemplate>;
  lab?: Maybe<Lab>;
  labErrors?: Maybe<Array<H_EntityError>>;
};

export type LabInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  visitId: Scalars['ID']['input'];
};

export type LabResultInput = {
  code: Scalars['String']['input'];
  result: Scalars['String']['input'];
  visitId: Scalars['ID']['input'];
};

export type LabTemplate = {
  __typename?: 'LabTemplate';
  arabicDisplay?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['String']['output']>;
  display?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<DecisionMakerGraphqlError>>>;
  fields?: Maybe<Array<Maybe<HealthParameter>>>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  labCodeDisplay?: Maybe<Scalars['String']['output']>;
};

export type LabTemplateConnection = {
  __typename?: 'LabTemplateConnection';
  edges?: Maybe<Array<Maybe<LabTemplateEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type LabTemplateEdge = {
  __typename?: 'LabTemplateEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<LabTemplate>;
};

export type LabTemplateFilterInput = {
  arabicDisplay?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  display?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LabTemplateInput = {
  arabicDisplay: Scalars['String']['input'];
  code: Scalars['String']['input'];
  display: Scalars['String']['input'];
  isActive: Scalars['Boolean']['input'];
};

export enum LabTemplateSortingField {
  ArabicDisplay = 'ARABIC_DISPLAY',
  Code = 'CODE',
  CreatedDate = 'CREATED_DATE',
  Display = 'DISPLAY',
  IsActive = 'IS_ACTIVE'
}

export type LabTemplateSortingInput = {
  direction: DecisionMakerOrderDirection;
  field: LabTemplateSortingField;
};

export type LabTemplateUpdateInput = {
  arabicDisplay: Scalars['String']['input'];
  display: Scalars['String']['input'];
  isActive: Scalars['Boolean']['input'];
};

export type Language = Node & {
  __typename?: 'Language';
  code: Scalars['String']['output'];
  display: Scalars['String']['output'];
  displayAr?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export enum LanguageCodeEnum {
  Ar = 'AR',
  Az = 'AZ',
  Bg = 'BG',
  Bn = 'BN',
  Ca = 'CA',
  Cs = 'CS',
  Da = 'DA',
  De = 'DE',
  El = 'EL',
  En = 'EN',
  Es = 'ES',
  EsCo = 'ES_CO',
  Et = 'ET',
  Fa = 'FA',
  Fi = 'FI',
  Fr = 'FR',
  Hi = 'HI',
  Hu = 'HU',
  Hy = 'HY',
  Id = 'ID',
  Is = 'IS',
  It = 'IT',
  Ja = 'JA',
  Ko = 'KO',
  Lt = 'LT',
  Mn = 'MN',
  Nb = 'NB',
  Nl = 'NL',
  Pl = 'PL',
  Pt = 'PT',
  PtBr = 'PT_BR',
  Ro = 'RO',
  Ru = 'RU',
  Sk = 'SK',
  Sl = 'SL',
  Sq = 'SQ',
  Sr = 'SR',
  Sv = 'SV',
  Sw = 'SW',
  Ta = 'TA',
  Th = 'TH',
  Tr = 'TR',
  Uk = 'UK',
  Vi = 'VI',
  ZhHans = 'ZH_HANS',
  ZhHant = 'ZH_HANT'
}

export type LanguageCountableConnection = {
  __typename?: 'LanguageCountableConnection';
  edges: Array<LanguageCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type LanguageCountableEdge = {
  __typename?: 'LanguageCountableEdge';
  cursor: Scalars['String']['output'];
  node: Language;
};

export type LanguageCreate = {
  __typename?: 'LanguageCreate';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  language?: Maybe<Language>;
};

export type LanguageCreateInput = {
  code: Scalars['String']['input'];
  display: Scalars['String']['input'];
  displayAr?: InputMaybe<Scalars['String']['input']>;
};

export type LanguageFilterInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  display?: InputMaybe<Scalars['String']['input']>;
};

export type LanguageUpdate = {
  __typename?: 'LanguageUpdate';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  language?: Maybe<Language>;
};

export type LanguageUpdateInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  display?: InputMaybe<Scalars['String']['input']>;
  displayAr?: InputMaybe<Scalars['String']['input']>;
};

export type LifeExpectancyInput = {
  age: Scalars['Int']['input'];
  dietQuality?: InputMaybe<DietQuality>;
  educationLevel?: InputMaybe<EducationLevel>;
  gender: Gender;
  physicalActivity?: InputMaybe<ActivityLevel>;
  smokingHabit?: InputMaybe<SmokingHabit>;
  stressLevel?: InputMaybe<RiskLevel>;
};

export type LifeExpectancyInputLog = {
  __typename?: 'LifeExpectancyInputLog';
  age: Scalars['Int']['output'];
  dietQuality?: Maybe<DietQuality>;
  educationLevel?: Maybe<EducationLevel>;
  gender: Gender;
  physicalActivity?: Maybe<ActivityLevel>;
  smokingHabit?: Maybe<SmokingHabit>;
  stressLevel?: Maybe<RiskLevel>;
};

export type LifeExpectancyResponse = {
  __typename?: 'LifeExpectancyResponse';
  averageAge?: Maybe<Scalars['Float']['output']>;
  lifeExpectancy?: Maybe<Scalars['Float']['output']>;
};

export type LifeExpectancyResult = {
  __typename?: 'LifeExpectancyResult';
  averageAge?: Maybe<Scalars['Int']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  lifeExpectancy?: Maybe<Scalars['Double']['output']>;
  queryInput?: Maybe<LifeExpectancyInputLog>;
};

export type LifeExpectancyResultConnection = {
  __typename?: 'LifeExpectancyResultConnection';
  edges: Array<LifeExpectancyResultEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type LifeExpectancyResultEdge = {
  __typename?: 'LifeExpectancyResultEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: LifeExpectancyResult;
};

export enum LikeDislike {
  Dislike = 'DISLIKE',
  Like = 'LIKE'
}

export type LocationInput = {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
};

export type LocationType = {
  __typename?: 'LocationType';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

export type LockChatInput = {
  chatGroupId: Scalars['ID']['input'];
  locked: Scalars['Boolean']['input'];
};

export type Manager = Node & {
  __typename?: 'Manager';
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export enum MaritalStatusesEnum {
  Divorced = 'DIVORCED',
  Married = 'MARRIED',
  Single = 'SINGLE',
  Widowed = 'WIDOWED'
}

export type MarkAllNotificationsAsSeenResponse = {
  __typename?: 'MarkAllNotificationsAsSeenResponse';
  errors?: Maybe<Array<Maybe<SubscriptionGraphqlError>>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type MarketplaceAddress = {
  __typename?: 'MarketplaceAddress';
  area?: Maybe<Scalars['String']['output']>;
  areaAr?: Maybe<Scalars['String']['output']>;
  buildingName?: Maybe<Scalars['String']['output']>;
  buildingNameAr?: Maybe<Scalars['String']['output']>;
  buildingNumber?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  cityAr?: Maybe<Scalars['String']['output']>;
  coordinates?: Maybe<Scalars['String']['output']>;
  district?: Maybe<Scalars['String']['output']>;
  districtAr?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  nameAr?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  regionAr?: Maybe<Scalars['String']['output']>;
  streetAddress1?: Maybe<Scalars['String']['output']>;
  streetAddress1Ar?: Maybe<Scalars['String']['output']>;
  streetAddress2?: Maybe<Scalars['String']['output']>;
  streetAddress2Ar?: Maybe<Scalars['String']['output']>;
  unitNumber?: Maybe<Scalars['String']['output']>;
};

export enum MarketplaceApprovalStatus {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  PendingApproval = 'PENDING_APPROVAL',
  Rejected = 'REJECTED'
}

export type MarketplaceBrand = {
  __typename?: 'MarketplaceBrand';
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameAr?: Maybe<Scalars['String']['output']>;
};

export type MarketplaceBrandConnection = {
  __typename?: 'MarketplaceBrandConnection';
  edges: Array<MarketplaceBrandEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type MarketplaceBrandEdge = {
  __typename?: 'MarketplaceBrandEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: MarketplaceBrand;
};

export type MarketplaceBrandFilterInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameAr?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
};

export type MarketplaceBrandInput = {
  image: Scalars['String']['input'];
  name: Scalars['String']['input'];
  nameAr: Scalars['String']['input'];
};

export type MarketplaceCancellationReasonFilterInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type MarketplaceCart = {
  __typename?: 'MarketplaceCart';
  id: Scalars['ID']['output'];
  items: Array<MarketplaceCartItem>;
  paymentOperationLogs: PaymentOperationLogCountableConnection;
  paymentReceipts: PaymentReceiptCountableConnection;
  paymentStatus?: Maybe<PaymentStatus>;
  paymentStatusFromPaymentLogs?: Maybe<PaymentStatus>;
  totalDeliveryPrice: Scalars['Double']['output'];
  totalDiscount: Scalars['Double']['output'];
  totalPrice: Scalars['Double']['output'];
  totalPriceWithVAT: Scalars['Double']['output'];
  totalVat: Scalars['Double']['output'];
  user: User;
};


export type MarketplaceCartPaymentOperationLogsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PaymentOperationLogFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type MarketplaceCartPaymentReceiptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PaymentReceiptFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type MarketplaceCartInput = {
  healthPackageId?: InputMaybe<Scalars['ID']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
  quantity: Scalars['Int']['input'];
};

export type MarketplaceCartItem = {
  __typename?: 'MarketplaceCartItem';
  healthPackage?: Maybe<MarketplaceHealthPackage>;
  id: Scalars['ID']['output'];
  product?: Maybe<MarketplaceProduct>;
  quantity: Scalars['Int']['output'];
};

export type MarketplaceCategoryFilterInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameAr?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Scalars['Boolean']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type MarketplaceCategoryInput = {
  description: Scalars['String']['input'];
  descriptionAr: Scalars['String']['input'];
  image: Scalars['String']['input'];
  name: Scalars['String']['input'];
  nameAr: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['ID']['input']>;
};

export type MarketplaceCheckoutCompleteInput = {
  addressId?: InputMaybe<Scalars['ID']['input']>;
  cartId: Scalars['ID']['input'];
  paymentMethod: MarketplacePaymentMethod;
};

export enum MarketplaceContentType {
  Html = 'HTML',
  Md = 'MD',
  Text = 'TEXT'
}

export enum MarketplaceDefaultSortingField {
  Created = 'CREATED',
  Name = 'NAME',
  NameAr = 'NAME_AR'
}

export type MarketplaceDefaultSortingInput = {
  direction?: InputMaybe<MarketplaceSortDirection>;
  field?: InputMaybe<MarketplaceDefaultSortingField>;
};

export type MarketplaceDiscount = {
  __typename?: 'MarketplaceDiscount';
  amount?: Maybe<Scalars['Double']['output']>;
  endDate: Scalars['Instant']['output'];
  healthPackages?: Maybe<Array<Maybe<MarketplaceHealthPackage>>>;
  id: Scalars['ID']['output'];
  isPublished: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  percentage?: Maybe<Scalars['Int']['output']>;
  products?: Maybe<Array<Maybe<MarketplaceProduct>>>;
  startDate: Scalars['Instant']['output'];
  type: MarketplaceDiscountType;
  vendor: Vendor;
};

export type MarketplaceDiscountConnection = {
  __typename?: 'MarketplaceDiscountConnection';
  edges: Array<MarketplaceDiscountEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type MarketplaceDiscountCreateInput = {
  amount?: InputMaybe<Scalars['Double']['input']>;
  endDate: Scalars['Instant']['input'];
  healthPackageIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  percentage?: InputMaybe<Scalars['Int']['input']>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  startDate: Scalars['Instant']['input'];
  type: MarketplaceDiscountType;
};

export type MarketplaceDiscountEdge = {
  __typename?: 'MarketplaceDiscountEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: MarketplaceDiscount;
};

export type MarketplaceDiscountFilterInput = {
  endDate?: InputMaybe<Scalars['Instant']['input']>;
  healthPackageId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  productId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  startDate?: InputMaybe<Scalars['Instant']['input']>;
};

export enum MarketplaceDiscountSortingField {
  Amount = 'AMOUNT',
  Created = 'CREATED',
  EndDate = 'END_DATE',
  Name = 'NAME',
  Percentage = 'PERCENTAGE',
  Published = 'PUBLISHED',
  StartDate = 'START_DATE',
  Type = 'TYPE'
}

export type MarketplaceDiscountSortingInput = {
  direction?: InputMaybe<MarketplaceSortDirection>;
  field?: InputMaybe<MarketplaceDiscountSortingField>;
};

export enum MarketplaceDiscountType {
  Fixed = 'FIXED',
  Percentage = 'PERCENTAGE'
}

export type MarketplaceDiscountUpdateInput = {
  amount?: InputMaybe<Scalars['Double']['input']>;
  endDate?: InputMaybe<Scalars['Instant']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  percentage?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['Instant']['input']>;
  type?: InputMaybe<MarketplaceDiscountType>;
};

export enum MarketplaceGender {
  All = 'ALL',
  Female = 'FEMALE',
  Male = 'MALE'
}

export type MarketplaceHealthPackage = {
  __typename?: 'MarketplaceHealthPackage';
  activeDiscount?: Maybe<MarketplaceDiscount>;
  approvalStatus?: Maybe<MarketplaceApprovalStatus>;
  category?: Maybe<MarketplaceHealthPackageCategory>;
  contentType?: Maybe<MarketplaceContentType>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionAr?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPublished: Scalars['Boolean']['output'];
  isReviewable?: Maybe<Scalars['Boolean']['output']>;
  mainImageUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameAr?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Double']['output']>;
  priceWithVAT?: Maybe<Scalars['Double']['output']>;
  priceWithVatAfterDiscount?: Maybe<Scalars['Double']['output']>;
  rejectionReason?: Maybe<Scalars['String']['output']>;
  reviewSummary?: Maybe<MarketplaceReviewSummary>;
  reviews?: Maybe<MarketplaceReviewConnection>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  shortDescriptionAr?: Maybe<Scalars['String']['output']>;
  targetFromAge?: Maybe<Scalars['Int']['output']>;
  targetGender?: Maybe<MarketplaceGender>;
  targetToAge?: Maybe<Scalars['Int']['output']>;
  updatable?: Maybe<Scalars['Boolean']['output']>;
  vendor: Vendor;
};


export type MarketplaceHealthPackageReviewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type MarketplaceHealthPackageCategory = {
  __typename?: 'MarketplaceHealthPackageCategory';
  description?: Maybe<Scalars['String']['output']>;
  descriptionAr?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameAr?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<MarketplaceHealthPackageCategory>;
};

export type MarketplaceHealthPackageCategoryConnection = {
  __typename?: 'MarketplaceHealthPackageCategoryConnection';
  edges: Array<MarketplaceHealthPackageCategoryEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type MarketplaceHealthPackageCategoryEdge = {
  __typename?: 'MarketplaceHealthPackageCategoryEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: MarketplaceHealthPackageCategory;
};

export type MarketplaceHealthPackageConnection = {
  __typename?: 'MarketplaceHealthPackageConnection';
  edges: Array<MarketplaceHealthPackageEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type MarketplaceHealthPackageEdge = {
  __typename?: 'MarketplaceHealthPackageEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: MarketplaceHealthPackage;
};

export type MarketplaceHealthPackageFilterInput = {
  approvalStatuses?: InputMaybe<Array<InputMaybe<MarketplaceApprovalStatus>>>;
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameAr?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  priceRange?: InputMaybe<MarketplacePriceRangeInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  targetAge?: InputMaybe<Scalars['Int']['input']>;
  targetGender?: InputMaybe<MarketplaceGender>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
};

export type MarketplaceHealthPackageInput = {
  categoryId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  descriptionAr: Scalars['String']['input'];
  mainImageUrl: Scalars['String']['input'];
  name: Scalars['String']['input'];
  nameAr: Scalars['String']['input'];
  price: Scalars['Double']['input'];
  shortDescription: Scalars['String']['input'];
  shortDescriptionAr: Scalars['String']['input'];
  targetFromAge: Scalars['Int']['input'];
  targetGender?: InputMaybe<MarketplaceGender>;
  targetToAge: Scalars['Int']['input'];
};

export enum MarketplaceHealthPackageSortingField {
  Created = 'CREATED',
  Name = 'NAME',
  NameAr = 'NAME_AR',
  Price = 'PRICE'
}

export type MarketplaceHealthPackageSortingInput = {
  direction?: InputMaybe<MarketplaceSortDirection>;
  field?: InputMaybe<MarketplaceHealthPackageSortingField>;
};

export type MarketplaceOrder = {
  __typename?: 'MarketplaceOrder';
  address?: Maybe<MarketplaceAddress>;
  cancellationReason?: Maybe<Scalars['String']['output']>;
  cartTokenId: Scalars['String']['output'];
  createdDate: Scalars['Instant']['output'];
  id: Scalars['ID']['output'];
  orderEvents: Array<MarketplaceOrderEvent>;
  orderLines: Array<MarketplaceOrderLine>;
  paymentMethod: MarketplacePaymentMethod;
  paymentOperationLogs: PaymentOperationLogCountableConnection;
  paymentReceipts: PaymentReceiptCountableConnection;
  paymentStatus?: Maybe<PaymentStatus>;
  paymentStatusFromPaymentLogs?: Maybe<PaymentStatus>;
  rejectionReason?: Maybe<Scalars['String']['output']>;
  status: MarketplaceOrderStatus;
  totalDeliveryPrice: Scalars['Double']['output'];
  totalDiscount?: Maybe<Scalars['Double']['output']>;
  totalNetAmount: Scalars['Double']['output'];
  totalNetAmountWithVAT: Scalars['Double']['output'];
  type: MarketplaceOrderType;
  user: User;
  vendor: Vendor;
};


export type MarketplaceOrderPaymentOperationLogsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PaymentOperationLogFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type MarketplaceOrderPaymentReceiptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PaymentReceiptFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type MarketplaceOrderConnection = {
  __typename?: 'MarketplaceOrderConnection';
  edges: Array<MarketplaceOrderEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type MarketplaceOrderEdge = {
  __typename?: 'MarketplaceOrderEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: MarketplaceOrder;
};

export type MarketplaceOrderEvent = {
  __typename?: 'MarketplaceOrderEvent';
  date: Scalars['Instant']['output'];
  id: Scalars['ID']['output'];
  status: MarketplaceOrderStatus;
  user: User;
};

export type MarketplaceOrderFilterInput = {
  cartTokenId?: InputMaybe<Scalars['ID']['input']>;
  fromDate?: InputMaybe<Scalars['Instant']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  statuses?: InputMaybe<Array<InputMaybe<MarketplaceOrderStatus>>>;
  toDate?: InputMaybe<Scalars['Instant']['input']>;
  type?: InputMaybe<MarketplaceOrderType>;
  userId?: InputMaybe<Scalars['ID']['input']>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
};

export type MarketplaceOrderLine = {
  __typename?: 'MarketplaceOrderLine';
  healthPackage?: Maybe<MarketplaceHealthPackage>;
  id: Scalars['ID']['output'];
  product?: Maybe<MarketplaceProduct>;
  quantity: Scalars['Int']['output'];
  unitPriceDiscount?: Maybe<Scalars['Double']['output']>;
  unitPriceNetAmount: Scalars['Double']['output'];
  unitPriceWithVAT: Scalars['Double']['output'];
};

export enum MarketplaceOrderSortingField {
  Created = 'CREATED',
  PaymentMethod = 'PAYMENT_METHOD',
  Status = 'STATUS',
  Type = 'TYPE'
}

export type MarketplaceOrderSortingInput = {
  direction?: InputMaybe<MarketplaceSortDirection>;
  field?: InputMaybe<MarketplaceOrderSortingField>;
};

export enum MarketplaceOrderStatus {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Consumed = 'CONSUMED',
  Delivered = 'DELIVERED',
  New = 'NEW',
  OutForDelivery = 'OUT_FOR_DELIVERY',
  Rejected = 'REJECTED'
}

export enum MarketplaceOrderType {
  HealthPackage = 'HEALTH_PACKAGE',
  Product = 'PRODUCT'
}

export enum MarketplacePaymentMethod {
  CreditWhenArrive = 'CREDIT_WHEN_ARRIVE',
  PayNow = 'PAY_NOW'
}

export type MarketplacePriceRangeInput = {
  max?: InputMaybe<Scalars['Double']['input']>;
  min?: InputMaybe<Scalars['Double']['input']>;
};

export type MarketplaceProduct = {
  __typename?: 'MarketplaceProduct';
  activeDiscount?: Maybe<MarketplaceDiscount>;
  approvalStatus?: Maybe<MarketplaceApprovalStatus>;
  brand?: Maybe<MarketplaceBrand>;
  category?: Maybe<MarketplaceProductCategory>;
  contentType?: Maybe<MarketplaceContentType>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionAr?: Maybe<Scalars['String']['output']>;
  howToUse?: Maybe<Scalars['String']['output']>;
  howToUseAr?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPublished: Scalars['Boolean']['output'];
  isReviewable?: Maybe<Scalars['Boolean']['output']>;
  mainImageUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameAr?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Double']['output']>;
  priceWithVAT?: Maybe<Scalars['Double']['output']>;
  priceWithVatAfterDiscount?: Maybe<Scalars['Double']['output']>;
  rejectionReason?: Maybe<Scalars['String']['output']>;
  relatedProducts?: Maybe<MarketplaceProductConnection>;
  returnPolicy?: Maybe<Scalars['String']['output']>;
  returnPolicyAr?: Maybe<Scalars['String']['output']>;
  returnPolicyDays?: Maybe<Scalars['Int']['output']>;
  returnable?: Maybe<Scalars['Boolean']['output']>;
  reviewSummary?: Maybe<MarketplaceReviewSummary>;
  reviews?: Maybe<MarketplaceReviewConnection>;
  shortDescription?: Maybe<Scalars['String']['output']>;
  shortDescriptionAr?: Maybe<Scalars['String']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  stockQuantity: Scalars['Int']['output'];
  subImages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  updatable?: Maybe<Scalars['Boolean']['output']>;
  vendor: Vendor;
};


export type MarketplaceProductRelatedProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type MarketplaceProductReviewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type MarketplaceProductCategory = {
  __typename?: 'MarketplaceProductCategory';
  description?: Maybe<Scalars['String']['output']>;
  descriptionAr?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameAr?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<MarketplaceProductCategory>;
};

export type MarketplaceProductCategoryConnection = {
  __typename?: 'MarketplaceProductCategoryConnection';
  edges: Array<MarketplaceProductCategoryEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type MarketplaceProductCategoryEdge = {
  __typename?: 'MarketplaceProductCategoryEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: MarketplaceProductCategory;
};

export type MarketplaceProductConnection = {
  __typename?: 'MarketplaceProductConnection';
  edges: Array<MarketplaceProductEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type MarketplaceProductEdge = {
  __typename?: 'MarketplaceProductEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: MarketplaceProduct;
};

export type MarketplaceProductFilterInput = {
  approvalStatuses?: InputMaybe<Array<InputMaybe<MarketplaceApprovalStatus>>>;
  brandIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameAr?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  priceRange?: InputMaybe<MarketplacePriceRangeInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  /**  showOutOfStock is true by default, set it to false to hide out of stock products  */
  showOutOfStock?: InputMaybe<Scalars['Boolean']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
};

export type MarketplaceProductInput = {
  brandId: Scalars['ID']['input'];
  categoryId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  descriptionAr: Scalars['String']['input'];
  howToUse?: InputMaybe<Scalars['String']['input']>;
  howToUseAr?: InputMaybe<Scalars['String']['input']>;
  mainImageUrl: Scalars['String']['input'];
  name: Scalars['String']['input'];
  nameAr: Scalars['String']['input'];
  price: Scalars['Double']['input'];
  returnPolicy?: InputMaybe<Scalars['String']['input']>;
  returnPolicyAr?: InputMaybe<Scalars['String']['input']>;
  returnPolicyDays?: InputMaybe<Scalars['Int']['input']>;
  returnable: Scalars['Boolean']['input'];
  shortDescription: Scalars['String']['input'];
  shortDescriptionAr: Scalars['String']['input'];
  sku: Scalars['String']['input'];
  stockQuantity: Scalars['Int']['input'];
  subImages?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum MarketplaceProductSortingField {
  Created = 'CREATED',
  Name = 'NAME',
  NameAr = 'NAME_AR',
  Price = 'PRICE',
  Published = 'PUBLISHED'
}

export type MarketplaceProductSortingInput = {
  direction?: InputMaybe<MarketplaceSortDirection>;
  field?: InputMaybe<MarketplaceProductSortingField>;
};

export type MarketplacePromotion = {
  __typename?: 'MarketplacePromotion';
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['Instant']['output']>;
  healthPackageCategories?: Maybe<Array<Maybe<MarketplaceHealthPackageCategory>>>;
  healthPackages?: Maybe<Array<Maybe<MarketplaceHealthPackage>>>;
  id: Scalars['ID']['output'];
  isPublished: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  productCategories?: Maybe<Array<Maybe<MarketplaceProductCategory>>>;
  products?: Maybe<Array<Maybe<MarketplaceProduct>>>;
  startDate?: Maybe<Scalars['Instant']['output']>;
  vendor: Vendor;
};

export type MarketplacePromotionConnection = {
  __typename?: 'MarketplacePromotionConnection';
  edges: Array<MarketplacePromotionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type MarketplacePromotionEdge = {
  __typename?: 'MarketplacePromotionEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: MarketplacePromotion;
};

export type MarketplacePromotionFilterInput = {
  endDate?: InputMaybe<Scalars['Instant']['input']>;
  healthPackageCategoryIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  healthPackageIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameAr?: InputMaybe<Scalars['String']['input']>;
  productCategoryIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  startDate?: InputMaybe<Scalars['Instant']['input']>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
};

export type MarketplacePromotionInput = {
  description: Scalars['String']['input'];
  endDate: Scalars['Instant']['input'];
  healthPackageCategoryIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  healthPackageIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name: Scalars['String']['input'];
  photo: Scalars['String']['input'];
  productCategoryIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  productIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  startDate: Scalars['Instant']['input'];
};

export enum MarketplacePromotionSortingField {
  Created = 'CREATED',
  EndDate = 'END_DATE',
  Name = 'NAME',
  Published = 'PUBLISHED',
  StartDate = 'START_DATE'
}

export type MarketplacePromotionSortingInput = {
  direction?: InputMaybe<MarketplaceSortDirection>;
  field?: InputMaybe<MarketplacePromotionSortingField>;
};

export type MarketplaceRatingCount = {
  __typename?: 'MarketplaceRatingCount';
  count: Scalars['Int']['output'];
  rating: Scalars['Int']['output'];
};

export type MarketplaceRejectionReasonFilterInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type MarketplaceReview = {
  __typename?: 'MarketplaceReview';
  comment?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  rating: Scalars['Int']['output'];
  user: User;
};

export type MarketplaceReviewConnection = {
  __typename?: 'MarketplaceReviewConnection';
  edges: Array<MarketplaceReviewEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type MarketplaceReviewEdge = {
  __typename?: 'MarketplaceReviewEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: MarketplaceReview;
};

export type MarketplaceReviewFilterInput = {
  hasComment?: InputMaybe<Scalars['Boolean']['input']>;
  healthPackageId?: InputMaybe<Scalars['ID']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type MarketplaceReviewInput = {
  healthPackageId?: InputMaybe<Scalars['ID']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
  rating: Scalars['Int']['input'];
  review?: InputMaybe<Scalars['String']['input']>;
};

export type MarketplaceReviewRatingCount = {
  __typename?: 'MarketplaceReviewRatingCount';
  count: Scalars['Int']['output'];
  rating: Scalars['Int']['output'];
};

export type MarketplaceReviewSummary = {
  __typename?: 'MarketplaceReviewSummary';
  avgRating: Scalars['Double']['output'];
  ratingCounts: Array<MarketplaceReviewRatingCount>;
  total: Scalars['Int']['output'];
};

export type MarketplaceReviewsAvg = {
  __typename?: 'MarketplaceReviewsAvg';
  avgRating: Scalars['Double']['output'];
  reviewRatingCountDTOS: Array<MarketplaceRatingCount>;
};

export enum MarketplaceSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type MarketplaceWishlist = {
  __typename?: 'MarketplaceWishlist';
  id: Scalars['ID']['output'];
  items: Array<MarketplaceWishlistItems>;
  user: User;
};

export type MarketplaceWishlistInput = {
  healthPackageId?: InputMaybe<Scalars['ID']['input']>;
  productId?: InputMaybe<Scalars['ID']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
};

export type MarketplaceWishlistItems = {
  __typename?: 'MarketplaceWishlistItems';
  healthPackage?: Maybe<MarketplaceHealthPackage>;
  id: Scalars['ID']['output'];
  product?: Maybe<MarketplaceProduct>;
  quantity: Scalars['Int']['output'];
};

export type MedicalAction = {
  __typename?: 'MedicalAction';
  action?: Maybe<Scalars['String']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  summary?: Maybe<Scalars['String']['output']>;
};

export type MedicalEdit = {
  __typename?: 'MedicalEdit';
  editCode?: Maybe<Scalars['String']['output']>;
  editMessage?: Maybe<Scalars['String']['output']>;
  editPriority?: Maybe<Scalars['String']['output']>;
  editSource?: Maybe<Scalars['String']['output']>;
};

export type MedicalForm = Node & {
  __typename?: 'MedicalForm';
  MedicalFormAnswers?: Maybe<Array<Maybe<MedicalFormAnswer>>>;
  active?: Maybe<Scalars['Boolean']['output']>;
  category?: Maybe<MedicalFormCategory>;
  code?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']['output']>;
  department?: Maybe<Department>;
  description?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<MedicalFormGraphqlError>>>;
  id: Scalars['ID']['output'];
  medicalFormClassifications?: Maybe<Array<Maybe<MedicalFormClassification>>>;
  name?: Maybe<Scalars['String']['output']>;
  nameAr?: Maybe<Scalars['String']['output']>;
  questions?: Maybe<Array<Question>>;
  type?: Maybe<MedicalFormType>;
  updateDate?: Maybe<Scalars['Date']['output']>;
  vendor?: Maybe<Vendor>;
  welcomeCard?: Maybe<Scalars['String']['output']>;
  welcomeCardAr?: Maybe<Scalars['String']['output']>;
};

export type MedicalFormAnswer = Node & {
  __typename?: 'MedicalFormAnswer';
  classificationName?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  medicalForm?: Maybe<MedicalForm>;
  medicalFormClassification?: Maybe<MedicalFormClassification>;
  patient?: Maybe<Patient>;
  questionAnswers?: Maybe<Array<Maybe<QuestionAnswerExtended>>>;
  score?: Maybe<Scalars['Int']['output']>;
};

export type MedicalFormAnswerConnection = {
  __typename?: 'MedicalFormAnswerConnection';
  edges?: Maybe<Array<Maybe<MedicalFormAnswerEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type MedicalFormAnswerEdge = {
  __typename?: 'MedicalFormAnswerEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<MedicalFormAnswer>;
};

export type MedicalFormAnswerFilter = {
  guidedCareHealthPrograms?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  guidedCareJourneyItems?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  medicalForms?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  patients?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export enum MedicalFormCategory {
  Condition = 'CONDITION',
  Disease = 'DISEASE',
  General = 'GENERAL',
  Procedure = 'PROCEDURE'
}

export type MedicalFormClassification = Node & {
  __typename?: 'MedicalFormClassification';
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  rangeFrom?: Maybe<Scalars['Int']['output']>;
  rangeTo?: Maybe<Scalars['Int']['output']>;
};

export type MedicalFormClassificationInput = {
  name: Scalars['String']['input'];
  rangeFrom: Scalars['Int']['input'];
  rangeTo: Scalars['Int']['input'];
};

export type MedicalFormClassificationToInterventionLink = H_Node & {
  __typename?: 'MedicalFormClassificationToInterventionLink';
  classification?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  intervention?: Maybe<TemplateIntervention>;
};

export type MedicalFormClassificationToInterventionLinkList = {
  classification: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  intervention?: InputMaybe<TemplateInterventionInputLight>;
};

export type MedicalFormConnection = {
  __typename?: 'MedicalFormConnection';
  edges?: Maybe<Array<Maybe<MedicalFormEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type MedicalFormEdge = {
  __typename?: 'MedicalFormEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<MedicalForm>;
};

export type MedicalFormFilter = {
  category?: InputMaybe<MedicalFormCategory>;
  codes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  departments?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isAdminForm?: InputMaybe<Scalars['Boolean']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  names?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  namesAr?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  patients?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  questions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<MedicalFormType>;
};

export type MedicalFormGraphqlError = {
  __typename?: 'MedicalFormGraphqlError';
  errorType?: Maybe<ErrorType>;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type MedicalFormInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  category: MedicalFormCategory;
  code: Scalars['String']['input'];
  departmentId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  medicalFormClassifications?: InputMaybe<Array<MedicalFormClassificationInput>>;
  name: Scalars['String']['input'];
  nameAr: Scalars['String']['input'];
  questions?: InputMaybe<Array<InputMaybe<QuestionInput>>>;
  type: MedicalFormType;
  welcomeCard?: InputMaybe<Scalars['String']['input']>;
  welcomeCardAr?: InputMaybe<Scalars['String']['input']>;
};

export enum MedicalFormSortingField {
  Active = 'ACTIVE',
  Category = 'CATEGORY',
  CreatedDate = 'CREATED_DATE',
  Name = 'NAME',
  NameAr = 'NAME_AR',
  Type = 'TYPE',
  UpdatedDate = 'UPDATED_DATE'
}

export type MedicalFormSortingInput = {
  direction: SortDirection;
  field: MedicalFormSortingField;
};

export enum MedicalFormType {
  Administrative = 'ADMINISTRATIVE',
  Assessment = 'ASSESSMENT',
  History = 'HISTORY',
  Prem = 'PREM',
  Prom = 'PROM',
  Quizzes = 'QUIZZES'
}

export type MedicalMessage = H_Node & {
  __typename?: 'MedicalMessage';
  code?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  headerImage?: Maybe<Scalars['String']['output']>;
  healthCondition?: Maybe<HealthCondition>;
  id: Scalars['ID']['output'];
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['Instant']['output']>;
  medicalMessageCategory?: Maybe<MedicalMessageCategory>;
  medicalMessageType?: Maybe<MedicalMessageType>;
  messages?: Maybe<Array<Maybe<MedicalMessageVariant>>>;
  name?: Maybe<Scalars['String']['output']>;
  readCounter?: Maybe<Scalars['Int']['output']>;
  sentCounter?: Maybe<Scalars['Int']['output']>;
};

/** CRUD a new Medical message. */
export type MedicalMessageCrud = {
  __typename?: 'MedicalMessageCRUD';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  medicalMessage?: Maybe<MedicalMessage>;
  medicalMessageErrors?: Maybe<Array<H_EntityError>>;
};

export enum MedicalMessageCategory {
  General = 'GENERAL',
  Instruction = 'INSTRUCTION',
  Medical = 'MEDICAL',
  Monitoring = 'MONITORING',
  Reminder = 'REMINDER'
}

export type MedicalMessageCountableConnection = {
  __typename?: 'MedicalMessageCountableConnection';
  edges: Array<MedicalMessageCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type MedicalMessageCountableEdge = {
  __typename?: 'MedicalMessageCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: MedicalMessage;
};

export type MedicalMessageFilterInput = {
  category?: InputMaybe<MedicalMessageCategory>;
  code?: InputMaybe<Scalars['String']['input']>;
  healthConditionId?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameContains?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<MedicalMessageType>;
};

export type MedicalMessageInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  headerImage?: InputMaybe<Scalars['String']['input']>;
  healthConditionId?: InputMaybe<Scalars['ID']['input']>;
  medicalMessageCategory?: InputMaybe<MedicalMessageCategory>;
  medicalMessageType?: InputMaybe<MedicalMessageType>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export enum MedicalMessageSortField {
  /** Sort by creation date. */
  CreatedDate = 'CREATED_DATE',
  /** Sort by update date. */
  LastModifiedDate = 'LAST_MODIFIED_DATE',
  /** Sort by name. */
  Name = 'NAME'
}

export type MedicalMessageSortingInput = {
  /** Specifies the direction in which to sort Medical Messages. */
  direction: H_OrderDirection;
  /** Sort medical messages by the selected field. */
  field: MedicalMessageSortField;
};

export enum MedicalMessageType {
  Article = 'ARTICLE',
  Text = 'TEXT',
  Video = 'VIDEO'
}

export type MedicalMessageVariant = H_Node & {
  __typename?: 'MedicalMessageVariant';
  article?: Maybe<Article>;
  bodyArabic?: Maybe<Scalars['String']['output']>;
  bodyEnglish?: Maybe<Scalars['String']['output']>;
  buttonTextArabic?: Maybe<Scalars['String']['output']>;
  buttonTextEnglish?: Maybe<Scalars['String']['output']>;
  contentURLArabic?: Maybe<Scalars['String']['output']>;
  contentURLEnglish?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  extraData?: Maybe<Scalars['String']['output']>;
  headerImage?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['Instant']['output']>;
  presentedTitleArabic?: Maybe<Scalars['String']['output']>;
  presentedTitleEnglish?: Maybe<Scalars['String']['output']>;
  readCounter?: Maybe<Scalars['Int']['output']>;
  sentCounter?: Maybe<Scalars['Int']['output']>;
};

/** CRUD a new Medical message variant. */
export type MedicalMessageVariantCrud = {
  __typename?: 'MedicalMessageVariantCRUD';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  medicalMessageVariant?: Maybe<MedicalMessageVariant>;
  medicalMessageVariantErrors?: Maybe<Array<H_EntityError>>;
};

export type MedicalMessageVariantCountableConnection = {
  __typename?: 'MedicalMessageVariantCountableConnection';
  edges: Array<MedicalMessageVariantCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type MedicalMessageVariantCountableEdge = {
  __typename?: 'MedicalMessageVariantCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: MedicalMessageVariant;
};

export type MedicalMessageVariantFilterInput = {
  buttonText?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  medicalMessageId?: InputMaybe<Scalars['ID']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleContains?: InputMaybe<Scalars['String']['input']>;
};

export type MedicalMessageVariantInput = {
  articleId?: InputMaybe<Scalars['ID']['input']>;
  bodyArabic?: InputMaybe<Scalars['String']['input']>;
  bodyEnglish?: InputMaybe<Scalars['String']['input']>;
  buttonTextArabic?: InputMaybe<Scalars['String']['input']>;
  buttonTextEnglish?: InputMaybe<Scalars['String']['input']>;
  contentURLArabic?: InputMaybe<Scalars['String']['input']>;
  contentURLEnglish?: InputMaybe<Scalars['String']['input']>;
  extraData?: InputMaybe<Scalars['String']['input']>;
  headerImage?: InputMaybe<Scalars['String']['input']>;
  /** ID of Medical Message to add variant to */
  medicalMessageID?: InputMaybe<Scalars['H_Long']['input']>;
  presentedTitleArabic?: InputMaybe<Scalars['String']['input']>;
  /** Body of Variant */
  presentedTitleEnglish?: InputMaybe<Scalars['String']['input']>;
};

export enum MedicalMessageVariantSortField {
  /** Sort by creation date. */
  CreatedDate = 'CREATED_DATE',
  Id = 'ID',
  IsActive = 'IS_ACTIVE',
  /** Sort by update date. */
  LastModifiedDate = 'LAST_MODIFIED_DATE'
}

export type MedicalMessageVariantSortingInput = {
  /** Specifies the direction in which to sort Guided Care Journeys. */
  direction: H_OrderDirection;
  /** Sort journeys by the selected field. */
  field: MedicalMessageVariantSortField;
};

export type Medication = {
  __typename?: 'Medication';
  arabicInstructions?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  dispenseCount?: Maybe<Scalars['Int']['output']>;
  display?: Maybe<Scalars['String']['output']>;
  downloadAction?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  genericDrugProperties?: Maybe<GenericDrugProperties>;
  id: Scalars['Int']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  lastDispenseDate?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  refills?: Maybe<Scalars['Int']['output']>;
  roaArName?: Maybe<Scalars['String']['output']>;
  roaEnName?: Maybe<Scalars['String']['output']>;
  routOfAdmin?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  tradeDrugs?: Maybe<Array<Maybe<TradeDrug>>>;
  type?: Maybe<ActivityType>;
  unitId?: Maybe<Scalars['Int']['output']>;
};

export type MedicationAuthorizationStatusInput = {
  grossAmount?: InputMaybe<Scalars['Double']['input']>;
  netAmount?: InputMaybe<Scalars['Double']['input']>;
  rejectionReason?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AuthorizationStatus>;
  tradeCode?: InputMaybe<Scalars['String']['input']>;
};

export type MedicationOrderLineInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  tradeCode?: InputMaybe<Scalars['String']['input']>;
};

export type MedicationTradeDrugs = {
  __typename?: 'MedicationTradeDrugs';
  code: Scalars['String']['output'];
  tradeDrugs?: Maybe<Array<Maybe<TradeDrug>>>;
};

export type Meeting = {
  __typename?: 'Meeting';
  chatGroup?: Maybe<ChatGroup>;
  chatGroupId?: Maybe<Scalars['ID']['output']>;
  createdAt: Scalars['Instant']['output'];
  id: Scalars['ID']['output'];
  jwtToken?: Maybe<Scalars['String']['output']>;
  participants: Array<User>;
  snapshots?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  topic: Scalars['String']['output'];
};

export type MeetingEvent = {
  __typename?: 'MeetingEvent';
  createdAt: Scalars['Instant']['output'];
  eventType?: Maybe<MeetingEventType>;
  id: Scalars['ID']['output'];
};

export enum MeetingEventType {
  MeetingEnded = 'MEETING_ENDED',
  MeetingParticipantJoined = 'MEETING_PARTICIPANT_JOINED',
  MeetingParticipantLeft = 'MEETING_PARTICIPANT_LEFT',
  MeetingRecordingCompleted = 'MEETING_RECORDING_COMPLETED',
  MeetingStarted = 'MEETING_STARTED',
  MeetingTranscriptCompleted = 'MEETING_TRANSCRIPT_COMPLETED'
}

export type MeetingParticipantJwt = {
  __typename?: 'MeetingParticipantJWT';
  id: Scalars['ID']['output'];
  jwt?: Maybe<Scalars['String']['output']>;
  meetingRate?: Maybe<Scalars['Int']['output']>;
  participantUserId?: Maybe<Scalars['ID']['output']>;
};

export type MeetingPlatformIdUpdate = {
  __typename?: 'MeetingPlatformIdUpdate';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  result?: Maybe<Scalars['String']['output']>;
};

export type MeetoraAnonymousChatInput = {
  anonymousChatLink?: InputMaybe<Scalars['String']['input']>;
  chatId?: InputMaybe<Scalars['H_Long']['input']>;
  error?: InputMaybe<Scalars['String']['input']>;
  exception?: InputMaybe<Scalars['String']['input']>;
  exceptionMessage?: InputMaybe<Scalars['String']['input']>;
  suspended?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  visitId?: InputMaybe<Scalars['String']['input']>;
};

export type Message = Node & {
  __typename?: 'Message';
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  branch: Branch;
  content?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  order?: Maybe<Order>;
  orderId?: Maybe<Scalars['Int']['output']>;
  recipient?: Maybe<User>;
  seenDate?: Maybe<Scalars['DateTime']['output']>;
  sender: User;
};

export type MessageAttachmentInput = {
  attachment: Scalars['ID']['input'];
  sortOrder: Scalars['Int']['input'];
};

export type MessageCountableConnection = {
  __typename?: 'MessageCountableConnection';
  edges: Array<MessageCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type MessageCountableEdge = {
  __typename?: 'MessageCountableEdge';
  cursor: Scalars['String']['output'];
  node: Message;
};

export type MessageInput = {
  attachments?: InputMaybe<Array<InputMaybe<MessageAttachmentInput>>>;
  branch?: InputMaybe<Scalars['ID']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
};

export type MessageSent = {
  __typename?: 'MessageSent';
  message?: Maybe<Message>;
};

export type Money = {
  __typename?: 'Money';
  amount: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  PatientHealthDocumentCreate?: Maybe<PatientHealthDocument>;
  PatientHealthDocumentDelete?: Maybe<PatientHealthDocument>;
  ProviderGuidedCareHealthProgramTeamPayerResponse?: Maybe<ProviderGuidedCareHealthProgramTeamCrud>;
  accelerateJourney?: Maybe<GuidedCareJourney>;
  accountAddressCreate?: Maybe<AccountAddressCreate>;
  accountAddressDelete?: Maybe<AccountAddressDelete>;
  accountAddressUpdate?: Maybe<AccountAddressUpdate>;
  accountSetDefaultAddress?: Maybe<AccountSetDefaultAddress>;
  activateMedicalForm?: Maybe<MedicalForm>;
  addCard?: Maybe<UserCard>;
  addDecision?: Maybe<Decision>;
  addDiagnosisToHealthCondition?: Maybe<Array<Maybe<HealthConditionDiagnosis>>>;
  addDiagnosisToHealthConditionByDisease?: Maybe<HealthCondition>;
  addDiagnosisToVisit?: Maybe<VisitDiagnosisCrud>;
  addFieldToHealthCondition?: Maybe<HealthCondition>;
  addFieldToLabTemplate?: Maybe<LabTemplate>;
  addFieldToRiskFactorTemplate?: Maybe<RiskFactorTemplate>;
  addHealthCondition?: Maybe<HealthCondition>;
  addHealthParameter?: Maybe<HealthParameter>;
  addLabTemplate?: Maybe<LabTemplate>;
  addMemberToChronicGuidedCareProgram?: Maybe<VisitDiagnosisRiskTemplateCrud>;
  addMemberToProgram?: Maybe<GuidedCareHealthProgram>;
  addPatientChronicDisease?: Maybe<PatientMedicalProfile>;
  addPatientChronicMedication?: Maybe<PatientMedicalProfile>;
  addPatientDisability?: Maybe<PatientMedicalProfile>;
  addPatientNonChronicDisease?: Maybe<PatientMedicalProfile>;
  addPersonalizedPatientAppointment?: Maybe<MutationPersonalizedConsumerAppointmentCreate>;
  addPersonalizedPatientAppointments?: Maybe<MutationPersonalizedConsumerAppointmentCreate>;
  addRiskFactorTemplate?: Maybe<RiskFactorTemplate>;
  addServiceToAppointment?: Maybe<ServiceDetails>;
  addSnapshotToMeeting?: Maybe<Scalars['Boolean']['output']>;
  addSubList?: Maybe<SubList>;
  addSubListItem?: Maybe<SubListItem>;
  addVendorToFavorite?: Maybe<AddVendorToFavorite>;
  adminCreateMedicalForm?: Maybe<MedicalForm>;
  adminPromotionCreate?: Maybe<AdminPromotion>;
  adminPromotionPublish?: Maybe<AdminPromotion>;
  adminPromotionUnpublish?: Maybe<AdminPromotion>;
  adminPromotionUpdate?: Maybe<AdminPromotion>;
  adminUpdateMedicalForm?: Maybe<MedicalForm>;
  appendTranscriptToMeeting?: Maybe<Scalars['Boolean']['output']>;
  appointmentAssignBranch?: Maybe<AppointmentApprovalAction>;
  appointmentAssignDepartment?: Maybe<AppointmentApprovalAction>;
  /** Appointment Cancel By Support Team */
  appointmentCancelBySupportTeam?: Maybe<AppointmentApprovalAction>;
  /** Appointment status update */
  appointmentStatusUpdate?: Maybe<AppointmentStatusUpdate>;
  approveTermsAndConditions?: Maybe<ApproveTermsAndConditions>;
  approveVendor?: Maybe<ApproveVendor>;
  articleAddContentImages?: Maybe<Article>;
  articleCategoryCreate?: Maybe<ArticleCategory>;
  articleCategoryUpdate?: Maybe<ArticleCategory>;
  articleCreate?: Maybe<Article>;
  articleRemoveContentImages?: Maybe<Article>;
  articleUpdate?: Maybe<Article>;
  assignCodeSystemEditorAndReviewer?: Maybe<CodeSystemDefinition>;
  assignDefaultBranch?: Maybe<AssignDefaultBranch>;
  assignUserToBranch?: Maybe<AssignUserToBranch>;
  availabilitiesAverage?: Maybe<AvailabilitiesAverage>;
  /** create a new Benefit Group */
  benefitGroupCreate?: Maybe<BenefitGroupCrud>;
  /** delete a Benefit Group */
  benefitGroupDelete?: Maybe<BenefitGroupCrud>;
  /** update Benefit Group */
  benefitGroupUpdate?: Maybe<BenefitGroupCrud>;
  blockCreate?: Maybe<BlockCreate>;
  blockDelete?: Maybe<BlockDelete>;
  blockUpdate?: Maybe<BlockUpdate>;
  branchCreate?: Maybe<BranchCreate>;
  branchDelete?: Maybe<BranchDelete>;
  branchUpdate?: Maybe<BranchUpdate>;
  callDoctorNowCreate?: Maybe<VisitAction>;
  callbackRequestCreate?: Maybe<CallbackRequestCrud>;
  callbackRequestUpdate?: Maybe<CallbackRequestCrud>;
  cancelBulkFile?: Maybe<BulkFile>;
  cancelSurvey?: Maybe<Survey>;
  /** Create a new cancel reason. */
  cancellationReasonCreate?: Maybe<CancelReasonCrud>;
  /** Delete an existing cancel reason. */
  cancellationReasonDelete?: Maybe<CancelReasonCrud>;
  /** Update an existing cancel reason. */
  cancellationReasonUpdate?: Maybe<CancelReasonCrud>;
  cardUpdateIsDefault?: Maybe<UserCardCrud>;
  changeGuidedCareHealthProgramActiveStatus?: Maybe<GuidedCareHealthProgramCrud>;
  changeHealthProgramTemplateActiveStatus?: Maybe<HealthProgramTemplateCrud>;
  changeProviderGuidedCareHealthProgramTeamActiveStatus?: Maybe<ProviderGuidedCareHealthProgramTeamCrud>;
  chatFlowAddContributors?: Maybe<ChatFlow>;
  chatFlowDelete?: Maybe<ChatFlow>;
  chatFlowDocumentCreate?: Maybe<ChatFlowDocument>;
  chatFlowDocumentDelete?: Maybe<ChatFlowDocument>;
  chatFlowRemoveContributors?: Maybe<ChatFlow>;
  chatFlowUpdate?: Maybe<ChatFlow>;
  checkCustomerAccountExist?: Maybe<CheckCustomerAccountExist>;
  chooseOrderPaymentMethod?: Maybe<Order>;
  cityCreate?: Maybe<CityCreate>;
  cityDelete?: Maybe<CityDelete>;
  cityUpdate?: Maybe<CityUpdate>;
  cloneCodeSystem?: Maybe<CodeSystem>;
  cloneSurvey?: Maybe<Survey>;
  confirmAccountEmail?: Maybe<ConfirmAccountEmail>;
  confirmAccountMobile?: Maybe<ConfirmAccountMobile>;
  confirmPasswordRestOtp?: Maybe<ConfirmPasswordRestOtp>;
  confirmVerification?: Maybe<ConfirmVerification>;
  /** Test create an Auto Appointment. */
  consumerAppointmentAutoCreate?: Maybe<ConsumerAppointmentCreate>;
  /** Doctor Accept Appointment Cancel */
  consumerAppointmentCancel?: Maybe<AppointmentApprovalAction>;
  /** Consumer Confirm Appointment */
  consumerAppointmentConfirm?: Maybe<AppointmentApprovalAction>;
  /** Creates an Appointment. */
  consumerAppointmentCreate?: Maybe<ConsumerAppointmentCreate>;
  /** Creates an Appointment For Anonymous. */
  consumerAppointmentCreateForAnonymous?: Maybe<ConsumerAppointmentCreate>;
  consumerAppointmentReschedule?: Maybe<AppointmentApprovalAction>;
  /** follow health channel. */
  consumerHealthChannelFollow?: Maybe<ConsumerHealthChannelFollow>;
  /** UnFollow health channel. */
  consumerHealthChannelUnFollow?: Maybe<ConsumerHealthChannelUnFollow>;
  /** Rate a Health Channel */
  consumerRateHealthChannel?: Maybe<ConsumerRateHealthChannel>;
  consumerViewPreferenceSave?: Maybe<ConsumerViewPreferenceSave>;
  convertVirtualOrderToRealOne?: Maybe<ConvertVirtualOrderToRealOneResult>;
  createConceptDefinition?: Maybe<CodeSystemConceptFieldDefinition>;
  createDummyVirtualVisit?: Maybe<Visit>;
  createEligibleFullMember?: Maybe<HealthProgramMemberCrud>;
  createMedicalForm?: Maybe<MedicalForm>;
  createMeeting?: Maybe<Meeting>;
  createQuestion?: Maybe<Question>;
  createSurvey?: Maybe<Survey>;
  createVisitPrescription?: Maybe<VisitPrescriptionTransaction>;
  createVisitPrescriptionsWithoutTPO?: Maybe<Array<Maybe<VisitPrescriptionTransaction>>>;
  customerCreate?: Maybe<CustomerCreate>;
  customerDelete?: Maybe<CustomerDelete>;
  customerMarkMessagesAsSeen?: Maybe<CustomerMarkMessagesAsSeen>;
  customerPrescriptionDispense?: Maybe<Order>;
  customerProfileUpdate?: Maybe<CustomerProfileUpdate>;
  customerSendMessage?: Maybe<CustomerSendMessage>;
  customerSupportErxOrderStatusUpdate?: Maybe<Order>;
  deactivateMedicalForm?: Maybe<MedicalForm>;
  deleteAppointmentNotificationSetting?: Maybe<Scalars['Boolean']['output']>;
  deleteAppointmentServiceDetails?: Maybe<ServiceDetails>;
  deleteCard?: Maybe<UserCardCrud>;
  deleteCodeSystemConcept?: Maybe<CodeSystemConcept>;
  deleteConceptDefinition?: Maybe<CodeSystemConceptFieldDefinition>;
  deleteCurrentCustomerUser?: Maybe<DeleteCurrentCustomerUser>;
  deleteDecision?: Maybe<Decision>;
  /** must be called by vendor nurse user */
  deleteDoctorNurseAssignment?: Maybe<Scalars['Boolean']['output']>;
  deleteFieldFromHealthCondition?: Maybe<HealthCondition>;
  deleteFieldFromLabTemplate?: Maybe<LabTemplate>;
  deleteFieldFromRiskFactorTemplate?: Maybe<RiskFactorTemplate>;
  deleteHealthConditionDiagnosis?: Maybe<HealthConditionDiagnosis>;
  deletePatientChronicDisease?: Maybe<PatientMedicalProfile>;
  deletePatientChronicMedication?: Maybe<PatientMedicalProfile>;
  deletePatientDisability?: Maybe<PatientMedicalProfile>;
  deletePatientNonChronicDisease?: Maybe<PatientMedicalProfile>;
  deleteProviderSettings?: Maybe<Scalars['Boolean']['output']>;
  deleteQuestion?: Maybe<Question>;
  deleteSubList?: Maybe<SubList>;
  deleteSubListItem?: Maybe<SubListItem>;
  deleteVisitDiagnosis?: Maybe<VisitDiagnosisCrud>;
  diagnosisPrediction?: Maybe<DiagnosisPredictionMutation>;
  disEnrollPatientToTeam?: Maybe<ProviderGuidedCareHealthProgramTeamCrud>;
  /** Doctor Accept Appointment */
  doctorAppointmentAccept?: Maybe<AppointmentApprovalAction>;
  /** Doctor Accept Appointment Cancel */
  doctorAppointmentCancel?: Maybe<AppointmentApprovalAction>;
  /** Doctor Accept Appointment Reject */
  doctorAppointmentReject?: Maybe<AppointmentApprovalAction>;
  doctorAppointmentReschedule?: Maybe<AppointmentApprovalAction>;
  doctorAvailabilityStatusUpdate?: Maybe<DoctorAvailabilityStatusUpdate>;
  doctorCanAcceptCallUpdate?: Maybe<DoctorCanAcceptCallUpdate>;
  doctorExperienceCreate?: Maybe<DoctorExperienceCreate>;
  doctorExperienceDelete?: Maybe<DoctorExperienceDelete>;
  doctorExperienceUpdate?: Maybe<DoctorExperienceUpdate>;
  doctorProfileUpdate?: Maybe<DoctorProfileUpdate>;
  doctorRating?: Maybe<DoctorRating>;
  /** doctor accepts assigned call */
  doctorVisitAccept?: Maybe<VisitApprovalAction>;
  doctorVisitComplete?: Maybe<VisitApprovalAction>;
  /** doctor reject assigned call */
  doctorVisitReject?: Maybe<DoctorVisitReject>;
  duplicateHealthCondition?: Maybe<HealthCondition>;
  duplicateLabTemplate?: Maybe<LabTemplate>;
  duplicateRiskFactorTemplate?: Maybe<RiskFactorTemplate>;
  /** Create a new EarliestVisit */
  earliestVisitCreate?: Maybe<EarliestVisitCreate>;
  editChatGroupMetaData?: Maybe<ChatGroup>;
  enrollPatientToTeam?: Maybe<PatientEnrollmentRequest>;
  evaluateGuidedCareProgramRiskFactorTemplate?: Maybe<GuidedCareHealthProgramRiskFactorTemplateEvaluation>;
  evaluateLabTemplate?: Maybe<LabTemplate>;
  evaluateOptimaRequest?: Maybe<Array<Maybe<OptimaActivityEvaluation>>>;
  evaluatePatientHealthConditions?: Maybe<Array<Maybe<PatientEvaluatedHealthCondition>>>;
  evaluateUserRequiredAction?: Maybe<Array<Maybe<UserAction>>>;
  /** create a new Exclusion Option */
  exclusionOptionCreate?: Maybe<ExclusionOptionCrud>;
  /** delete a Exclusion Option */
  exclusionOptionDelete?: Maybe<ExclusionOptionCrud>;
  /** update Exclusion Option */
  exclusionOptionUpdate?: Maybe<ExclusionOptionCrud>;
  /**
   * can be called by vendor user only,
   * filter.createdDate can't cover more than 3 months,
   * can't return more than 100K records in the file so the filter.createdDate should be adjusted according to that,
   * returns an absolute file url to download,
   *
   * CSV file header column
   * Invoice Id,Marketplace Order Id,Payment Source,Currency,Discount,Price Before VAT,VAT,Price After VAT,Consumer Id,Consumer User Id,Creation Date
   */
  exportPaymentInvoices?: Maybe<Scalars['String']['output']>;
  /** create a new Extra Benefit */
  extraBenefitCreate?: Maybe<ExtraBenefitCrud>;
  /** delete a Extra Benefit */
  extraBenefitDelete?: Maybe<ExtraBenefitCrud>;
  /** update Extra Benefit */
  extraBenefitUpdate?: Maybe<ExtraBenefitCrud>;
  fetchTerminologyListData?: Maybe<SubList>;
  firePlanDecisions?: Maybe<Action>;
  firebaseLogout?: Maybe<DeviceInformation>;
  fromGlobalId?: Maybe<FromGlobalId>;
  generatePatientProfileConsentCode?: Maybe<PatientProfileConsentCode>;
  generatePatientReferral?: Maybe<PatientReferral>;
  generatePatientReferralByClinician?: Maybe<PatientReferral>;
  getComplexPDFTextFile?: Maybe<Scalars['String']['output']>;
  guidedCareHealthProgramCreate?: Maybe<GuidedCareHealthProgramCrud>;
  guidedCareHealthProgramUpdate?: Maybe<GuidedCareHealthProgramCrud>;
  guidedCareJourneyInterventionActivate?: Maybe<GuidedCareJourney>;
  guidedCareJourneyInterventionDeactivate?: Maybe<GuidedCareJourney>;
  guidedCareJourneyItemUpdate?: Maybe<GuidedCareJourneyItemCrud>;
  guidedCareJourneyItemUpdateStatus?: Maybe<GuidedCareJourneyItem>;
  guidedCareTaskCreate?: Maybe<GuidedCareTaskCrud>;
  /** delete an Existing Guided Care Task */
  guidedCareTaskDelete?: Maybe<GuidedCareTaskCrud>;
  /** Update an Existing Guided Care Task */
  guidedCareTaskUpdate?: Maybe<GuidedCareTaskCrud>;
  handleCheckoutPaymentRedirectionByPaymentCheckoutId?: Maybe<PaymentResponse>;
  handleHyperPayWebhookNotification?: Maybe<PaymentResponse>;
  handleOrderVendorAcceptTimeout?: Maybe<HandleOrderTimeoutResult>;
  handlePaymentRedirectionByPaymentId?: Maybe<PaymentResponse>;
  /** Create a new health channel category. */
  healthChannelCategoryCreate?: Maybe<HealthChannelCategoryCrud>;
  /** Delete an existing health channel category. */
  healthChannelCategoryDelete?: Maybe<HealthChannelCategoryCrud>;
  /** Update an existing health channel category. */
  healthChannelCategoryUpdate?: Maybe<HealthChannelCategoryCrud>;
  /** Create a new health channel. */
  healthChannelCreate?: Maybe<HealthChannelCrud>;
  /** Delete an existing health channel. */
  healthChannelDelete?: Maybe<HealthChannelCrud>;
  /** Update an existing health channel. */
  healthChannelUpdate?: Maybe<HealthChannelCrud>;
  /** Create a new Health Program Benefit Option */
  healthProgramBenefitOptionCreate?: Maybe<HealthProgramBenefitOptionCrud>;
  /** delete a Health Program Benefit Option */
  healthProgramBenefitOptionDelete?: Maybe<HealthProgramBenefitOptionCrud>;
  /** update Health Program Benefit Option */
  healthProgramBenefitOptionUpdate?: Maybe<HealthProgramBenefitOptionCrud>;
  /** Create a new Health Program */
  healthProgramCreate?: Maybe<HealthProgramCrud>;
  healthProgramMemberBodyCompositionUpdate?: Maybe<HealthProgramMemberBodyCompositionCrud>;
  /**
   * """delete an Existing Health Program"""
   * healthProgramDelete(
   *     """ID of a health program to delete."""
   *     id: ID!
   * ): HealthProgramCRUD
   */
  healthProgramMemberCreate?: Maybe<HealthProgramMemberCrud>;
  /**
   * healthProgramMemberDelete(
   *     id: ID!
   * ): HealthProgramMemberCRUD
   */
  healthProgramMemberListCreate?: Maybe<HealthProgramMemberListCrud>;
  healthProgramMemberListUpdate?: Maybe<HealthProgramMemberListCrud>;
  healthProgramMemberListUpdateActiveStatus?: Maybe<HealthProgramMemberListCrud>;
  healthProgramMemberUpdate?: Maybe<HealthProgramMemberCrud>;
  healthProgramMemberUpdateIsDefault?: Maybe<HealthProgramMemberCrud>;
  healthProgramMembershipRequestApprove?: Maybe<HealthProgramMembershipRequestCrud>;
  healthProgramMembershipRequestCreate?: Maybe<HealthProgramMembershipRequestCrud>;
  healthProgramMembershipRequestReject?: Maybe<HealthProgramMembershipRequestCrud>;
  /**
   * healthProgramMemberListDelete(
   *     id: ID!
   * ): HealthProgramMemberListCRUD
   */
  healthProgramNetworkCreate?: Maybe<HealthProgramNetworkCrud>;
  /**
   * healthProgramNetworkDelete(
   *     id: ID!
   * ): HealthProgramNetworkCRUD
   */
  healthProgramNetworkProviderCreate?: Maybe<HealthProgramNetworkProviderCrud>;
  /**
   * healthProgramNetworkProviderDelete(
   *     id: ID!
   * ): HealthProgramNetworkProviderCRUD
   */
  healthProgramNetworkProviderProfessionalCreate?: Maybe<HealthProgramNetworkProviderProfessionalCrud>;
  healthProgramNetworkProviderProfessionalUpdate?: Maybe<HealthProgramNetworkProviderProfessionalCrud>;
  healthProgramNetworkProviderProfessionalUpdateOverridingVirtualCallEnabled?: Maybe<HealthProgramNetworkProviderProfessionalCrud>;
  healthProgramNetworkProviderUpdate?: Maybe<HealthProgramNetworkProviderCrud>;
  healthProgramNetworkProviderUpdateActiveStatus?: Maybe<HealthProgramNetworkProviderCrud>;
  healthProgramNetworkProviderUpdateVirtualCallEnabled?: Maybe<HealthProgramNetworkProviderCrud>;
  healthProgramNetworkUpdate?: Maybe<HealthProgramNetworkCrud>;
  healthProgramNetworkUpdateActiveStatus?: Maybe<HealthProgramNetworkCrud>;
  /** Subscribe user to Health Program */
  healthProgramSubscribe?: Maybe<HealthProgramSubscribe>;
  healthProgramTemplateCreate?: Maybe<HealthProgramTemplateCrud>;
  healthProgramTemplateUpdate?: Maybe<HealthProgramTemplateCrud>;
  healthProgramUnSubscribe?: Maybe<HealthProgramUnSubscribe>;
  /** Update an Existing Health Program */
  healthProgramUpdate?: Maybe<HealthProgramCrud>;
  healthProgramUpdateActiveStatus?: Maybe<HealthProgramCrud>;
  healthProgramsSiteSettingsUpdate?: Maybe<SiteSettings>;
  healthSymptomCreate?: Maybe<HealthSymptomCreate>;
  healthSymptomDelete?: Maybe<HealthSymptomDelete>;
  healthSymptomSpecializationDelete?: Maybe<HealthSymptomSpecializationDelete>;
  healthSymptomSpecializationsCreate?: Maybe<HealthSymptomSpecializationsCreate>;
  healthSymptomUpdate?: Maybe<HealthSymptomUpdate>;
  insertDeviceInformation?: Maybe<DeviceInformation>;
  insertPatientHealthParameters?: Maybe<Array<Maybe<PatientHealthParameterCrud>>>;
  insertUnattendedCall?: Maybe<TypeMutationInsertUnattendedCall>;
  /** Create a new InsuranceNetwork */
  insuranceNetworkCreate?: Maybe<InsuranceNetworkCrud>;
  /** deletes an existing InsuranceNetwork */
  insuranceNetworkDelete?: Maybe<InsuranceNetworkCrud>;
  /** Update an existing InsuranceNetwork */
  insuranceNetworkUpdate?: Maybe<InsuranceNetworkCrud>;
  invalidateAnonymousChatGroup?: Maybe<AnonymousChatGroup>;
  journeySwitchTeam?: Maybe<ProviderGuidedCareHealthProgramTeamCrud>;
  keycloakPermissionConfigurationUpdate?: Maybe<KeycloakPermissionConfigurationUpdate>;
  labRequestCreate?: Maybe<LabCrud>;
  labRequestDelete?: Maybe<LabCrud>;
  languageCreate?: Maybe<LanguageCreate>;
  languageUpdate?: Maybe<LanguageUpdate>;
  likeDislikeItemEdit?: Maybe<OptimaItemEdit>;
  lockChatGroup?: Maybe<Scalars['Boolean']['output']>;
  makeInterventionEligible?: Maybe<GuidedCareJourney>;
  markAllNotificationsAsSeen?: Maybe<MarkAllNotificationsAsSeenResponse>;
  markNotificationAsSeen?: Maybe<UserNotification>;
  marketplaceBrandCreate?: Maybe<MarketplaceBrand>;
  marketplaceBrandUpdate?: Maybe<MarketplaceBrand>;
  marketplaceCartAddItem?: Maybe<MarketplaceCart>;
  marketplaceCartClear?: Maybe<MarketplaceCart>;
  marketplaceCartRemoveItems?: Maybe<MarketplaceCart>;
  marketplaceCartUpdateItemQuantity?: Maybe<MarketplaceCart>;
  marketplaceDiscountCreate?: Maybe<MarketplaceDiscount>;
  marketplaceDiscountPublish?: Maybe<MarketplaceDiscount>;
  marketplaceDiscountUnpublish?: Maybe<MarketplaceDiscount>;
  marketplaceDiscountUpdate?: Maybe<MarketplaceDiscount>;
  marketplaceHealthPackageApprove?: Maybe<MarketplaceHealthPackage>;
  marketplaceHealthPackageCategoryCreate?: Maybe<MarketplaceHealthPackageCategory>;
  marketplaceHealthPackageCategoryUpdate?: Maybe<MarketplaceHealthPackageCategory>;
  marketplaceHealthPackageClone?: Maybe<MarketplaceHealthPackage>;
  marketplaceHealthPackageCreate?: Maybe<MarketplaceHealthPackage>;
  marketplaceHealthPackagePublish?: Maybe<MarketplaceHealthPackage>;
  marketplaceHealthPackageReject?: Maybe<MarketplaceHealthPackage>;
  marketplaceHealthPackageSubmitForApproval?: Maybe<MarketplaceHealthPackage>;
  marketplaceHealthPackageUnpublish?: Maybe<MarketplaceHealthPackage>;
  marketplaceHealthPackageUpdate?: Maybe<MarketplaceHealthPackage>;
  marketplaceOrderAccept?: Maybe<MarketplaceOrder>;
  marketplaceOrderCancel?: Maybe<MarketplaceOrder>;
  /** MarketplaceOrder Cancel By Support Team */
  marketplaceOrderCancelBySupportTeam?: Maybe<MarketplaceOrder>;
  marketplaceOrderCheckoutComplete?: Maybe<Array<Maybe<MarketplaceOrder>>>;
  marketplaceOrderConsumedUpdate?: Maybe<MarketplaceOrder>;
  marketplaceOrderDeliveredUpdate?: Maybe<MarketplaceOrder>;
  marketplaceOrderOutForDeliveryUpdate?: Maybe<MarketplaceOrder>;
  marketplaceOrderReject?: Maybe<MarketplaceOrder>;
  marketplaceOrderStatusUpdate?: Maybe<MarketplaceOrder>;
  marketplaceProductApprove?: Maybe<MarketplaceProduct>;
  marketplaceProductCategoryCreate?: Maybe<MarketplaceProductCategory>;
  marketplaceProductCategoryUpdate?: Maybe<MarketplaceProductCategory>;
  marketplaceProductClone?: Maybe<MarketplaceProduct>;
  marketplaceProductCreate?: Maybe<MarketplaceProduct>;
  marketplaceProductPublish?: Maybe<MarketplaceProduct>;
  marketplaceProductReject?: Maybe<MarketplaceProduct>;
  marketplaceProductSubmitForApproval?: Maybe<MarketplaceProduct>;
  marketplaceProductUnpublish?: Maybe<MarketplaceProduct>;
  marketplaceProductUpdate?: Maybe<MarketplaceProduct>;
  marketplaceProductUpdateStockQuantity?: Maybe<MarketplaceProduct>;
  marketplacePromotionCreate?: Maybe<MarketplacePromotion>;
  marketplacePromotionPublish?: Maybe<MarketplacePromotion>;
  marketplacePromotionUnpublish?: Maybe<MarketplacePromotion>;
  marketplacePromotionUpdate?: Maybe<MarketplacePromotion>;
  marketplaceReviewSubmit?: Maybe<MarketplaceReview>;
  marketplaceSyncVATPercentage?: Maybe<Scalars['Double']['output']>;
  marketplaceWishlistAddItem?: Maybe<MarketplaceWishlist>;
  marketplaceWishlistClear?: Maybe<MarketplaceWishlist>;
  marketplaceWishlistRemoveItems?: Maybe<MarketplaceWishlist>;
  marketplaceWishlistUpdateItemQuantity?: Maybe<MarketplaceWishlist>;
  /** Create a new Medical Message */
  medicalMessageCreate?: Maybe<MedicalMessageCrud>;
  /** Update an Existing Medical Message */
  medicalMessageUpdate?: Maybe<MedicalMessageCrud>;
  /** Create a new Medical Message Variant */
  medicalMessageVariantCreate?: Maybe<MedicalMessageVariantCrud>;
  /** Update an Existing Medical Message Variant */
  medicalMessageVariantUpdate?: Maybe<MedicalMessageVariantCrud>;
  meetingPlatformUpdate?: Maybe<MeetingPlatformIdUpdate>;
  myReviewForVendor?: Maybe<MyReviewForVendor>;
  offlineAppointmentComplete?: Maybe<AppointmentStatusUpdate>;
  optimaActionCreate?: Maybe<OptimaAction>;
  optimaActionUpdate?: Maybe<OptimaAction>;
  optimaEditCreate?: Maybe<OptimaEdit>;
  optimaEditUpdate?: Maybe<OptimaEdit>;
  orderCancel?: Maybe<Order>;
  orderGetErxHubApproval?: Maybe<Order>;
  orderStatusUpdate?: Maybe<OrderStatusUpdateResult>;
  orderVendorFinishProcessing?: Maybe<Order>;
  otpjAssessmentCreate?: Maybe<OtpjAssessment>;
  otpjCheckInCreate?: Maybe<OtpjCheckIn>;
  otpjConsultationCreate?: Maybe<OtpjConsultation>;
  otpjExtPaymentCreate?: Maybe<OtpjExtPayment>;
  outPatientJourneySetPriceAmount?: Maybe<OutPatientJourney>;
  outPatientJourneyStatusUpdate?: Maybe<OutPatientJourney>;
  passwordChange?: Maybe<PasswordChange>;
  patientCreate?: Maybe<PatientCreate>;
  patientEligibilityUpdate?: Maybe<OptimaPatientEligibility>;
  patientHealthHistoryCreate?: Maybe<PatientMedicalProfileCrud>;
  patientHealthHistoryDelete?: Maybe<PatientMedicalProfileCrud>;
  patientMedicalProfileMembershipAssign?: Maybe<PatientMedicalProfileCrud>;
  patientProfileConsentFormApprove?: Maybe<PatientProfileConsentFormCrud>;
  patientProfileConsentFormClose?: Maybe<PatientProfileConsentFormCrud>;
  patientProfileConsentFormCreate?: Maybe<PatientProfileConsentFormCrud>;
  patientProfileConsentFormReject?: Maybe<PatientProfileConsentFormCrud>;
  patientSendOrderOtp?: Maybe<PatientSendOrderOtp>;
  patientUpdate?: Maybe<PatientUpdate>;
  /** Upload an attachment to visit */
  patientUploadVisitAttachment?: Maybe<PatientUploadVisitAttachment>;
  /** Patient Visit Cancel */
  patientVisitCancel?: Maybe<VisitApprovalAction>;
  payerAddUpdateMemberBankDetails?: Maybe<GeneralResponse>;
  /**  Payer Mutations  */
  payerClaimSubmission?: Maybe<GeneralResponse>;
  payerCreate?: Maybe<PayerCreate>;
  payerUpdate?: Maybe<PayerUpdate>;
  permissionGroupCreate?: Maybe<PermissionGroupCreate>;
  permissionGroupDelete?: Maybe<PermissionGroupDelete>;
  permissionGroupUpdate?: Maybe<PermissionGroupUpdate>;
  planCreate?: Maybe<PlanCreate>;
  planUpdate?: Maybe<PlanUpdate>;
  prescriptionOrderAssignDefaultBranch?: Maybe<Order>;
  prescriptionOrderAssignVendors?: Maybe<PrescriptionOrderAssignVendorsResult>;
  processAppointmentNotificationSetting?: Maybe<AppointmentNotifications>;
  processPaymentCheckout?: Maybe<PaymentCheckoutResponse>;
  processPaymentClearance?: Maybe<Array<Maybe<PaymentReceipt>>>;
  processPaymentUsingWallet?: Maybe<Wallet>;
  processPaymentUsingWalletForTest?: Maybe<Wallet>;
  providerGuidedCareHealthProgramTeamCreate?: Maybe<ProviderGuidedCareHealthProgramTeamCrud>;
  providerGuidedCareHealthProgramTeamUpdate?: Maybe<ProviderGuidedCareHealthProgramTeamCrud>;
  publishCodeSystem?: Maybe<CodeSystem>;
  publishDraftHealthProgramTemplate?: Maybe<HealthProgramTemplateCrud>;
  publishSurvey?: Maybe<Survey>;
  radRequestCreate?: Maybe<Rad>;
  refundPaymentFromWallet?: Maybe<PaymentResponse>;
  rejectVendor?: Maybe<RejectVendor>;
  /** Create a new rejection reason. */
  rejectionReasonCreate?: Maybe<RejectionReasonCrud>;
  /** Delete an existing rejection reason. */
  rejectionReasonDelete?: Maybe<RejectionReasonCrud>;
  /** Update an existing rejection reason. */
  rejectionReasonUpdate?: Maybe<RejectionReasonCrud>;
  removeVendorFromFavorite?: Maybe<RemoveVendorFromFavorite>;
  requestOtpForDeleteAccount?: Maybe<RequestOtpForDeleteAccount>;
  requestPasswordReset?: Maybe<RequestPasswordReset>;
  resubmitOrderGetErxHubApproval?: Maybe<Order>;
  reviewCodeSystem?: Maybe<CodeSystem>;
  reviewVendor?: Maybe<ReviewVendor>;
  /** Review an Visit */
  reviewVisit?: Maybe<ReviewVisit>;
  saveAppointmentNotificationSetting?: Maybe<Array<Maybe<AppointmentNotificationSetting>>>;
  /** must be called by vendor nurse user, remove all existing ones for nurse and save the provided */
  saveDoctorNurseAssignment?: Maybe<Array<Maybe<DoctorNurseAssignment>>>;
  saveOrFetchExistedAnonymousChatGroup?: Maybe<AnonymousChatGroup>;
  saveProviderSettings?: Maybe<ProviderSettings>;
  scheduleFixedDelayTask?: Maybe<Scalars['Boolean']['output']>;
  sendChatMessage?: Maybe<Chat>;
  sendCodeSystemToReview?: Maybe<CodeSystem>;
  sendGuidedCareAppointmentReminderToTopic?: Maybe<Scalars['Boolean']['output']>;
  sendMedicalFormRequestToTopic?: Maybe<Scalars['Boolean']['output']>;
  sendMedicalMessageRequestToTopic?: Maybe<Scalars['Boolean']['output']>;
  sendNotificationForGuidedCareTask?: Maybe<Scalars['Boolean']['output']>;
  /** send OTP Message to the provided input details for the purpose of Appointment For Anonymous */
  sendOtpMessageForAnonymousAppointment?: Maybe<Scalars['Boolean']['output']>;
  /** Creates a new visit message. */
  sendVisitMessage?: Maybe<SendVisitMessage>;
  setAppointmentServiceDetails?: Maybe<Array<Maybe<ServiceDetails>>>;
  setClinicianCredentials?: Maybe<ClinicianCredentials>;
  setLabResult?: Maybe<LabCrud>;
  setOrderToDelivered?: Maybe<Order>;
  setOrderToOutForDelivery?: Maybe<Order>;
  setPassword?: Maybe<SetPassword>;
  setPharmacyCredentials?: Maybe<PharmacyCredentials>;
  setlRiskTemplateResult?: Maybe<VisitDiagnosisRiskTemplateCrud>;
  siteSettingsUpdate: SiteSettings;
  submitQuestionAnswersForCareJourneyMedicalForm?: Maybe<MedicalFormAnswer>;
  submitQuestionAnswersForCareJourneyMedicalFormAdmin?: Maybe<MedicalFormAnswer>;
  submitQuestionAnswersForMedicalForm?: Maybe<Array<Maybe<QuestionAnswerExtended>>>;
  submitSurveyAnswers?: Maybe<SurveyUserAnswer>;
  syncChatFlow?: Maybe<Scalars['Boolean']['output']>;
  syncFamilyMembers?: Maybe<HealthProgramMember>;
  syncLFProducts?: Maybe<Scalars['String']['output']>;
  taskDefinitionCreate?: Maybe<TaskDefinitionCrud>;
  /** delete an Existing Task Definition */
  taskDefinitionDelete?: Maybe<TaskDefinitionCrud>;
  /** Update an Existing Task Definition */
  taskDefinitionUpdate?: Maybe<TaskDefinitionCrud>;
  /** Nurse Confirm Appointment */
  tentativeAppointmentConfirm?: Maybe<AppointmentApprovalAction>;
  terminateVendorSubscription?: Maybe<TerminateVendorSubscription>;
  textSummary?: Maybe<TextSummaryMutation>;
  /** time out doctor reject assigned call */
  timeoutDoctorVisitReject?: Maybe<DoctorVisitReject>;
  toGlobalId?: Maybe<ToGlobalId>;
  trackerReminderReschedule?: Maybe<Array<Maybe<TrackerReminder>>>;
  trackerReminderSettingsCancel?: Maybe<TrackerReminderSettings>;
  trackerReminderSettingsCreate?: Maybe<TrackerReminderSettings>;
  trackerReminderSettingsUpdate?: Maybe<TrackerReminderSettings>;
  trackerReminderSnooze?: Maybe<Array<Maybe<TrackerReminder>>>;
  trackerReminderStatusUpdate?: Maybe<Array<Maybe<TrackerReminder>>>;
  trackerSupplementTypeCreate?: Maybe<TrackerSupplementType>;
  trackerSupplementTypeUpdate?: Maybe<TrackerSupplementType>;
  trackerTradeDrugTypeCreate?: Maybe<TrackerTradeDrugType>;
  trackerTradeDrugTypeUpdate?: Maybe<TrackerTradeDrugType>;
  unAssignUserFromBranch?: Maybe<UnAssignUserFromBranch>;
  unPublishSurvey?: Maybe<Survey>;
  unbindDependent?: Maybe<UnbindDependent>;
  unbindParent?: Maybe<UnbindParent>;
  updateAppointmentNotificationSetting?: Maybe<AppointmentNotificationSetting>;
  updateArticleActiveStatus?: Maybe<Article>;
  updateCodeSystemConcept?: Maybe<CodeSystemConcept>;
  updateConceptDefinition?: Maybe<CodeSystemConceptFieldDefinition>;
  updateDecision?: Maybe<Decision>;
  updateDecisionStatus?: Maybe<Decision>;
  updateGuidedCareHealthProgramActivityItems?: Maybe<GuidedCareHealthProgramActivityCrud>;
  updateGuidedCareTaskToDone?: Maybe<GuidedCareTaskCrud>;
  updateHealthCondition?: Maybe<HealthCondition>;
  updateHealthConditionActiveStatus?: Maybe<HealthCondition>;
  updateHealthParameter?: Maybe<HealthParameter>;
  updateHealthParameterActiveStatus?: Maybe<HealthParameter>;
  updateLabTemplate?: Maybe<LabTemplate>;
  updateLabTemplateActiveStatus?: Maybe<LabTemplate>;
  updateMedicalForm?: Maybe<MedicalForm>;
  /** Update an Existing Medical Message */
  updateMedicalMessagePublishStatus?: Maybe<MedicalMessageCrud>;
  updateMedicalMessageVariantActiveStatus?: Maybe<MedicalMessageVariantCrud>;
  updateMeetingRate?: Maybe<MeetingParticipantJwt>;
  updateMeetingZoomSessionStatusWithEnd?: Maybe<Scalars['Boolean']['output']>;
  updateMissedAppointmentJourneyItems?: Maybe<Array<Maybe<GuidedCareJourneyItem>>>;
  updateOptimaActionActiveStatus?: Maybe<OptimaAction>;
  updateOptimaEditActiveStatus?: Maybe<OptimaEdit>;
  updatePatientJourneysMembership?: Maybe<HealthProgramMember>;
  updatePayment?: Maybe<PaymentResponse>;
  updateQuestion?: Maybe<Question>;
  updateRiskFactorTemplate?: Maybe<RiskFactorTemplate>;
  updateRiskFactorTemplateActiveStatus?: Maybe<RiskFactorTemplate>;
  updateSubList?: Maybe<SubList>;
  updateSubListItem?: Maybe<SubListItem>;
  updateSurvey?: Maybe<Survey>;
  updateTemplateActivityItem?: Maybe<TemplateActivityCrud>;
  updateTemplateActivityItems?: Maybe<TemplateActivityCrud>;
  updateTemplateInterventionActivityItems?: Maybe<TemplateInterventionActivityCrud>;
  updateUserEmailMobile?: Maybe<UpdateUserEmailMobile>;
  updateVisitPrescriptionAuthStatus?: Maybe<VisitPrescriptionTransaction>;
  uploadAttachment?: Maybe<UploadAttachment>;
  uploadBulkFile?: Maybe<BulkFile>;
  uploadCodeSystemFile?: Maybe<CodeSystem>;
  uploadNationalCard?: Maybe<UploadNationalCard>;
  uploadSubList?: Maybe<SubList>;
  userAvatarDelete?: Maybe<AccountAvatarDelete>;
  userAvatarUpdate?: Maybe<AccountAvatarUpdate>;
  userCreate?: Maybe<UserCreate>;
  userDelete?: Maybe<UserDelete>;
  userManagementSiteSettingsUpdate?: Maybe<UserManagementSiteSettingsUpdate>;
  userProfileUpdate?: Maybe<UserProfileUpdate>;
  userUpdate?: Maybe<UserUpdate>;
  validateCptHcpcAgainstPrimaryProcedure?: Maybe<Array<Maybe<ValidateProcedureResponse>>>;
  validateInsuranceBenefitApprovalLimit?: Maybe<Array<Maybe<ValidateInsuranceBenefitApprovalLimitResponse>>>;
  validateItemMaximumQuantityAllowed?: Maybe<Array<Maybe<ValidateItemMaximumQuantityAllowedResponse>>>;
  validatePrescription?: Maybe<ValidatePrescription>;
  validateProcedureAgainstAge?: Maybe<Array<Maybe<ValidateProcedureResponse>>>;
  validateProcedures?: Maybe<Array<Maybe<ValidateProcedureResponse>>>;
  vendorCreate?: Maybe<VendorCreate>;
  vendorDelete?: Maybe<VendorDelete>;
  vendorDepartmentCreate?: Maybe<VendorDepartmentCreate>;
  vendorDepartmentDelete?: Maybe<VendorDepartmentDelete>;
  vendorDepartmentUpdate?: Maybe<VendorDepartmentUpdate>;
  vendorImageCreate?: Maybe<VendorImageCreate>;
  vendorImageDelete?: Maybe<VendorImageDelete>;
  vendorImageReorder?: Maybe<VendorImageReorder>;
  vendorImageUpdate?: Maybe<VendorImageUpdate>;
  vendorMarkMessagesAsSeen?: Maybe<VendorMarkMessagesAsSeen>;
  vendorOrderAccept?: Maybe<Order>;
  vendorOrderCancel?: Maybe<Order>;
  vendorOrderDeliveryStatusUpdate?: Maybe<Order>;
  vendorOrderReject?: Maybe<Order>;
  vendorRegister?: Maybe<VendorRegister>;
  vendorReviewDelete?: Maybe<VendorReviewDelete>;
  vendorReviewPublish?: Maybe<VendorReviewPublish>;
  vendorSendMessage?: Maybe<VendorSendMessage>;
  vendorUpdate?: Maybe<VendorUpdate>;
  vendorUpdateEnableVisitDetails?: Maybe<VendorUpdateEnableVisitDetails>;
  vendorUpdateIsIntegrated?: Maybe<VendorUpdateIsIntegrated>;
  vendorUserCreate?: Maybe<VendorUserCreate>;
  vendorUserDelete?: Maybe<VendorUserDelete>;
  vendorUserUpdate?: Maybe<VendorUserUpdate>;
  verifyCredentials?: Maybe<VerifyCredentials>;
  verifyNationalId?: Maybe<VerifyNationalId>;
  visitAssignBranch?: Maybe<VisitAction>;
  /** Update Visit Assistant Agent Status. */
  visitAssistantAgentStatusUpdate?: Maybe<VisitAssistantAgentStatusUpdate>;
  /** end visit call. */
  visitCallEnd?: Maybe<VisitCallEnd>;
  /** make new visit active call if no active call exists. */
  visitCallRejoin?: Maybe<VisitAction>;
  /** Visit Cancel By Support Team */
  visitCancelBySupportTeam?: Maybe<VisitApprovalAction>;
  /** creates a new Visit Complain */
  visitComplainCreate?: Maybe<VisitComplainCreate>;
  /** assign call request to doctor */
  visitDoctorAssign?: Maybe<VisitDoctorAssign>;
  visitDoctorNoteUpdate?: Maybe<VisitAction>;
  visitEvaluationRateSet?: Maybe<VisitAction>;
  /**
   * make new visit call if no active call exists. and if it still allowed
   * to make a new call after the essential latest call was made,
   * essential call means the first call that was requested by patient
   * and perhaps the call was timedout/rejoined/cancelled
   * and then the final status of it can be
   * (INCOMPLETED, COMPLETED, CANCELED, CANCELED_BY_CONSUMER, ENDED_BY_ONE_OF_THE_PARTICIPANTS)
   */
  visitNewCallStart?: Maybe<VisitAction>;
  visitNotToPatientUpdate?: Maybe<VisitAction>;
  /** Visit status update */
  visitStatusUpdate?: Maybe<VisitStatusUpdate>;
  /** Creates a new Visit Summary. */
  visitSummaryCreate?: Maybe<VisitSummaryCrud>;
  /** deletes an existing visit summary. */
  visitSummaryDelete?: Maybe<VisitSummaryCrud>;
  /** Update an existing lab */
  visitSummaryUpdate?: Maybe<VisitSummaryCrud>;
  visitTranscription?: Maybe<VisitTranscriptionMutation>;
};


export type MutationPatientHealthDocumentCreateArgs = {
  input: PatientHealthDocumentInput;
};


export type MutationPatientHealthDocumentDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationProviderGuidedCareHealthProgramTeamPayerResponseArgs = {
  input: ProviderGuidedCareHealthProgramTeamPayerResponseInput;
};


export type MutationAccelerateJourneyArgs = {
  completionPercentage: Scalars['Int']['input'];
  guidedCareJourneyId: Scalars['ID']['input'];
  numberOfDays: Scalars['ID']['input'];
};


export type MutationAccountAddressCreateArgs = {
  input: AddressInput;
  type?: InputMaybe<AddressTypeEnum>;
};


export type MutationAccountAddressDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationAccountAddressUpdateArgs = {
  id: Scalars['ID']['input'];
  input: AddressInput;
};


export type MutationAccountSetDefaultAddressArgs = {
  id: Scalars['ID']['input'];
  type: AddressTypeEnum;
};


export type MutationActivateMedicalFormArgs = {
  id: Scalars['ID']['input'];
};


export type MutationAddCardArgs = {
  cardInput: CardInput;
};


export type MutationAddDecisionArgs = {
  decision: DecisionInput;
};


export type MutationAddDiagnosisToHealthConditionArgs = {
  diagnosisCode?: InputMaybe<Array<Scalars['String']['input']>>;
  healthConditionId: Scalars['ID']['input'];
};


export type MutationAddDiagnosisToHealthConditionByDiseaseArgs = {
  disease: Scalars['String']['input'];
  healthConditionId: Scalars['ID']['input'];
};


export type MutationAddDiagnosisToVisitArgs = {
  input?: InputMaybe<AddDiagnosisToVisitInput>;
};


export type MutationAddFieldToHealthConditionArgs = {
  healthConditionId: Scalars['ID']['input'];
  healthParameterId: Scalars['ID']['input'];
};


export type MutationAddFieldToLabTemplateArgs = {
  healthParameterId: Scalars['ID']['input'];
  labTemplateId: Scalars['ID']['input'];
};


export type MutationAddFieldToRiskFactorTemplateArgs = {
  healthParameterId: Scalars['ID']['input'];
  riskFactorTemplateId: Scalars['ID']['input'];
};


export type MutationAddHealthConditionArgs = {
  input: HealthConditionInput;
};


export type MutationAddHealthParameterArgs = {
  input: HealthParameterInput;
};


export type MutationAddLabTemplateArgs = {
  input: LabTemplateInput;
};


export type MutationAddMemberToChronicGuidedCareProgramArgs = {
  input: VisitChronicGuidedCareMemberAddInput;
};


export type MutationAddMemberToProgramArgs = {
  input?: InputMaybe<AddMemberToProgramInput>;
};


export type MutationAddPatientChronicDiseaseArgs = {
  input: PatientChronicDiseaseInput;
};


export type MutationAddPatientChronicMedicationArgs = {
  input: PatientChronicMedicationInput;
};


export type MutationAddPatientDisabilityArgs = {
  input: PatientDisabilityInput;
};


export type MutationAddPatientNonChronicDiseaseArgs = {
  input: PatientNonChronicDiseaseInput;
};


export type MutationAddPersonalizedPatientAppointmentArgs = {
  input: CreatePersonalizedAppointmentInput;
};


export type MutationAddPersonalizedPatientAppointmentsArgs = {
  input: CreatePersonalizedAppointmentsInput;
};


export type MutationAddRiskFactorTemplateArgs = {
  input: RiskFactorTemplateInput;
};


export type MutationAddServiceToAppointmentArgs = {
  input: ServiceDetailsInput;
};


export type MutationAddSnapshotToMeetingArgs = {
  input: AddSnapshotToMeetingInput;
};


export type MutationAddSubListArgs = {
  subList?: InputMaybe<SubListInput>;
};


export type MutationAddSubListItemArgs = {
  subListId: Scalars['ID']['input'];
  subListItem: SubListItemInput;
};


export type MutationAddVendorToFavoriteArgs = {
  vendor?: Scalars['ID']['input'];
};


export type MutationAdminCreateMedicalFormArgs = {
  input: AdminMedicalFormInput;
};


export type MutationAdminPromotionCreateArgs = {
  input: AdminPromotionInput;
};


export type MutationAdminPromotionPublishArgs = {
  id: Scalars['ID']['input'];
};


export type MutationAdminPromotionUnpublishArgs = {
  id: Scalars['ID']['input'];
};


export type MutationAdminPromotionUpdateArgs = {
  id: Scalars['ID']['input'];
  input: AdminPromotionInput;
};


export type MutationAdminUpdateMedicalFormArgs = {
  id: Scalars['ID']['input'];
  input: AdminMedicalFormInput;
};


export type MutationAppendTranscriptToMeetingArgs = {
  input: AppendTranscriptInput;
};


export type MutationAppointmentAssignBranchArgs = {
  branchId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};


export type MutationAppointmentAssignDepartmentArgs = {
  departmentId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};


export type MutationAppointmentCancelBySupportTeamArgs = {
  id: Scalars['ID']['input'];
  input: CancelInput;
};


export type MutationAppointmentStatusUpdateArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  input?: InputMaybe<AppointmentStatusUpdateInput>;
};


export type MutationApproveVendorArgs = {
  id: Scalars['ID']['input'];
};


export type MutationArticleAddContentImagesArgs = {
  articleId: Scalars['ID']['input'];
  input: Array<ArticleContentImageInput>;
};


export type MutationArticleCategoryCreateArgs = {
  input: ArticleCategoryInput;
};


export type MutationArticleCategoryUpdateArgs = {
  id: Scalars['ID']['input'];
  input: ArticleCategoryInput;
};


export type MutationArticleCreateArgs = {
  input: ArticleInput;
};


export type MutationArticleRemoveContentImagesArgs = {
  articleId: Scalars['ID']['input'];
  contentImageIds: Array<Scalars['ID']['input']>;
};


export type MutationArticleUpdateArgs = {
  id: Scalars['ID']['input'];
  input: ArticleInput;
};


export type MutationAssignCodeSystemEditorAndReviewerArgs = {
  code: CodeSystemCode;
  editorUser: Scalars['ID']['input'];
  reviewerUser: Scalars['ID']['input'];
};


export type MutationAssignDefaultBranchArgs = {
  branchId: Scalars['ID']['input'];
};


export type MutationAssignUserToBranchArgs = {
  input?: InputMaybe<UserBranchInput>;
};


export type MutationAvailabilitiesAverageArgs = {
  fromDate: Scalars['Date']['input'];
  toDate: Scalars['Date']['input'];
  vendorId: Scalars['ID']['input'];
};


export type MutationBenefitGroupCreateArgs = {
  input: BenefitGroupInput;
};


export type MutationBenefitGroupDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationBenefitGroupUpdateArgs = {
  id: Scalars['ID']['input'];
  input: BenefitGroupInput;
};


export type MutationBlockCreateArgs = {
  input: BlockInput;
};


export type MutationBlockDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationBlockUpdateArgs = {
  id: Scalars['ID']['input'];
  input: BlockInput;
};


export type MutationBranchCreateArgs = {
  input: BranchInput;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationBranchDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationBranchUpdateArgs = {
  id: Scalars['ID']['input'];
  input: BranchInput;
};


export type MutationCallDoctorNowCreateArgs = {
  input: CallDoctorNowInput;
};


export type MutationCallbackRequestCreateArgs = {
  input: CallbackRequestInput;
};


export type MutationCallbackRequestUpdateArgs = {
  id: Scalars['ID']['input'];
  input: CallbackRequestAdminInput;
};


export type MutationCancelBulkFileArgs = {
  fileId: Scalars['ID']['input'];
};


export type MutationCancelSurveyArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCancellationReasonCreateArgs = {
  input: CancelReasonInput;
};


export type MutationCancellationReasonDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCancellationReasonUpdateArgs = {
  id: Scalars['ID']['input'];
  input: CancelReasonInput;
};


export type MutationCardUpdateIsDefaultArgs = {
  id: Scalars['ID']['input'];
  isDefault: Scalars['Boolean']['input'];
};


export type MutationChangeGuidedCareHealthProgramActiveStatusArgs = {
  id: Scalars['ID']['input'];
};


export type MutationChangeHealthProgramTemplateActiveStatusArgs = {
  id: Scalars['ID']['input'];
};


export type MutationChangeProviderGuidedCareHealthProgramTeamActiveStatusArgs = {
  id: Scalars['ID']['input'];
};


export type MutationChatFlowAddContributorsArgs = {
  chatFlowId: Scalars['ID']['input'];
  contributors: Array<Scalars['ID']['input']>;
};


export type MutationChatFlowDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationChatFlowDocumentCreateArgs = {
  chatFlowId: Scalars['ID']['input'];
  documentStoreId: Scalars['ID']['input'];
  input?: InputMaybe<ChatFlowDocumentInput>;
};


export type MutationChatFlowDocumentDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationChatFlowRemoveContributorsArgs = {
  chatFlowId: Scalars['ID']['input'];
  contributors: Array<Scalars['ID']['input']>;
};


export type MutationChatFlowUpdateArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<ChatFlowUpdateInput>;
};


export type MutationCheckCustomerAccountExistArgs = {
  contactNumber?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  recaptchaToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationChooseOrderPaymentMethodArgs = {
  id: Scalars['ID']['input'];
  paymentMethod: OrderPaymentMethod;
};


export type MutationCityCreateArgs = {
  input: CityInput;
};


export type MutationCityDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCityUpdateArgs = {
  id: Scalars['ID']['input'];
  input: CityInput;
};


export type MutationCloneCodeSystemArgs = {
  codeSystemCode: CodeSystemCode;
};


export type MutationCloneSurveyArgs = {
  id: Scalars['ID']['input'];
  label: Scalars['String']['input'];
};


export type MutationConfirmAccountEmailArgs = {
  sessionToken: Scalars['String']['input'];
  token: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationConfirmAccountMobileArgs = {
  mobile: Scalars['String']['input'];
  sessionToken: Scalars['String']['input'];
  token: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationConfirmPasswordRestOtpArgs = {
  appType: AppTypes;
  passwordResetMethod: PasswordResetMethodEnum;
  sessionToken: Scalars['String']['input'];
  username: Scalars['String']['input'];
  vendor?: InputMaybe<Scalars['ID']['input']>;
  verificationCode: Scalars['String']['input'];
};


export type MutationConfirmVerificationArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  sessionToken: Scalars['String']['input'];
  verificationCode: Scalars['String']['input'];
};


export type MutationConsumerAppointmentAutoCreateArgs = {
  input: ConsumerAppointmentInput;
  memberId?: InputMaybe<Scalars['ID']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  teamId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationConsumerAppointmentCancelArgs = {
  id: Scalars['ID']['input'];
  input: CancelInput;
};


export type MutationConsumerAppointmentConfirmArgs = {
  id: Scalars['ID']['input'];
  input: ConsumerAppointmentConfirmInput;
};


export type MutationConsumerAppointmentCreateArgs = {
  input: ConsumerAppointmentInput;
};


export type MutationConsumerAppointmentCreateForAnonymousArgs = {
  input: ConsumerAppointmentInputForAnonymous;
};


export type MutationConsumerAppointmentRescheduleArgs = {
  id: Scalars['ID']['input'];
  input: ConsumerAppointmentRescheduleInput;
};


export type MutationConsumerHealthChannelFollowArgs = {
  input: ConsumerHealthChannelFollowInput;
};


export type MutationConsumerHealthChannelUnFollowArgs = {
  channelId: Scalars['ID']['input'];
};


export type MutationConsumerRateHealthChannelArgs = {
  input: HealthChannelRatingInput;
};


export type MutationConsumerViewPreferenceSaveArgs = {
  input: ConsumerViewPreferenceInput;
};


export type MutationConvertVirtualOrderToRealOneArgs = {
  branches: Array<InputMaybe<Scalars['ID']['input']>>;
  order: Scalars['ID']['input'];
};


export type MutationCreateConceptDefinitionArgs = {
  codeSystemConceptFieldDefinition: CodeSystemConceptFieldDefinitionInput;
};


export type MutationCreateDummyVirtualVisitArgs = {
  doctorId: Scalars['ID']['input'];
  patientId: Scalars['ID']['input'];
};


export type MutationCreateEligibleFullMemberArgs = {
  input: HealthProgramMemberInput;
  vendorId: Scalars['ID']['input'];
};


export type MutationCreateMedicalFormArgs = {
  input: MedicalFormInput;
};


export type MutationCreateMeetingArgs = {
  input: CreateMeetingInput;
};


export type MutationCreateQuestionArgs = {
  input: QuestionInput;
  surveyId: Scalars['ID']['input'];
};


export type MutationCreateSurveyArgs = {
  input: SurveyInput;
};


export type MutationCreateVisitPrescriptionArgs = {
  createPrescriptionInput?: InputMaybe<CreateVisitPrescriptionInput>;
};


export type MutationCreateVisitPrescriptionsWithoutTpoArgs = {
  createPrescriptionInputs?: InputMaybe<Array<InputMaybe<CreateVisitPrescriptionInput>>>;
};


export type MutationCustomerCreateArgs = {
  input: CustomerCreateInput;
};


export type MutationCustomerDeleteArgs = {
  input: CustomerDeleteInput;
};


export type MutationCustomerMarkMessagesAsSeenArgs = {
  branchId: Scalars['String']['input'];
};


export type MutationCustomerPrescriptionDispenseArgs = {
  input: CustomerPrescriptionDispenseInput;
};


export type MutationCustomerProfileUpdateArgs = {
  input: CustomerProfileUpdateInput;
};


export type MutationCustomerSendMessageArgs = {
  input: MessageInput;
};


export type MutationCustomerSupportErxOrderStatusUpdateArgs = {
  orderId: Scalars['ID']['input'];
  status?: InputMaybe<CustomerSupportOrderStatusEnum>;
};


export type MutationDeactivateMedicalFormArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteAppointmentNotificationSettingArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteAppointmentServiceDetailsArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCardArgs = {
  cardId: Scalars['ID']['input'];
};


export type MutationDeleteCodeSystemConceptArgs = {
  codeSystemConceptId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDeleteConceptDefinitionArgs = {
  codeSystemConceptFieldDefinitionId: Scalars['ID']['input'];
};


export type MutationDeleteCurrentCustomerUserArgs = {
  deleteReason: Scalars['String']['input'];
};


export type MutationDeleteDecisionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteDoctorNurseAssignmentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteFieldFromHealthConditionArgs = {
  healthConditionId: Scalars['ID']['input'];
  healthParameterId: Scalars['ID']['input'];
};


export type MutationDeleteFieldFromLabTemplateArgs = {
  healthParameterId: Scalars['ID']['input'];
  labTemplateId: Scalars['ID']['input'];
};


export type MutationDeleteFieldFromRiskFactorTemplateArgs = {
  healthParameterId: Scalars['ID']['input'];
  riskFactorTemplateId: Scalars['ID']['input'];
};


export type MutationDeleteHealthConditionDiagnosisArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeletePatientChronicDiseaseArgs = {
  id: Scalars['ID']['input'];
  patientId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationDeletePatientChronicMedicationArgs = {
  id: Scalars['ID']['input'];
  patientId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationDeletePatientDisabilityArgs = {
  id: Scalars['ID']['input'];
  patientId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationDeletePatientNonChronicDiseaseArgs = {
  id: Scalars['ID']['input'];
  patientId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationDeleteProviderSettingsArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteQuestionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSubListArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteSubListItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteVisitDiagnosisArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDiagnosisPredictionArgs = {
  input: DiagnosisPredictionInput;
};


export type MutationDisEnrollPatientToTeamArgs = {
  input: GuidedCarePatientTeamEnrollmentInput;
};


export type MutationDoctorAppointmentAcceptArgs = {
  id: Scalars['ID']['input'];
  input: DoctorAcceptAppointmentInput;
};


export type MutationDoctorAppointmentCancelArgs = {
  id: Scalars['ID']['input'];
  input: CancelInput;
};


export type MutationDoctorAppointmentRejectArgs = {
  id: Scalars['ID']['input'];
  input: DoctorAppointmentRejectInput;
};


export type MutationDoctorAppointmentRescheduleArgs = {
  id: Scalars['ID']['input'];
  input: DoctorAppointmentRescheduleInput;
};


export type MutationDoctorAvailabilityStatusUpdateArgs = {
  availabilityStatus: DoctorAvailabilityStatusEnum;
  doctorId?: InputMaybe<Scalars['ID']['input']>;
  period: Scalars['Int']['input'];
};


export type MutationDoctorCanAcceptCallUpdateArgs = {
  canAcceptCall: Scalars['Boolean']['input'];
  doctorId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationDoctorExperienceCreateArgs = {
  input: DoctorExperienceCreateInput;
};


export type MutationDoctorExperienceDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDoctorExperienceUpdateArgs = {
  id: Scalars['ID']['input'];
  input: DoctorExperienceUpdateInput;
};


export type MutationDoctorProfileUpdateArgs = {
  input: DoctorProfileInput;
};


export type MutationDoctorRatingArgs = {
  input: DoctorRatingInput;
};


export type MutationDoctorVisitAcceptArgs = {
  branchId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
};


export type MutationDoctorVisitCompleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDoctorVisitRejectArgs = {
  input: DoctorVisitRejectInput;
};


export type MutationDuplicateHealthConditionArgs = {
  id: Scalars['ID']['input'];
  input: HealthConditionInput;
};


export type MutationDuplicateLabTemplateArgs = {
  id: Scalars['ID']['input'];
  input: LabTemplateInput;
};


export type MutationDuplicateRiskFactorTemplateArgs = {
  id: Scalars['ID']['input'];
  input: RiskFactorTemplateInput;
};


export type MutationEarliestVisitCreateArgs = {
  input: EarliestVisitCreateInput;
};


export type MutationEditChatGroupMetaDataArgs = {
  consumerName?: InputMaybe<Scalars['String']['input']>;
  groupName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  providerGuidedCareHealthProgramTeamLeaderName?: InputMaybe<Scalars['String']['input']>;
  providerGuidedCareHealthProgramTeamMemberName?: InputMaybe<Scalars['String']['input']>;
  providerGuidedCareHealthProgramTeamName?: InputMaybe<Scalars['String']['input']>;
  visitId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEnrollPatientToTeamArgs = {
  input: GuidedCarePatientTeamEnrollmentInput;
};


export type MutationEvaluateGuidedCareProgramRiskFactorTemplateArgs = {
  input?: InputMaybe<EvaluateProgramRiskFactorTemplateInput>;
};


export type MutationEvaluateLabTemplateArgs = {
  code: Scalars['String']['input'];
  inputs: Array<InputMaybe<DecisionInputData>>;
};


export type MutationEvaluateOptimaRequestArgs = {
  input?: InputMaybe<OptimaRequestInput>;
};


export type MutationEvaluatePatientHealthConditionsArgs = {
  patientId: Scalars['ID']['input'];
};


export type MutationExclusionOptionCreateArgs = {
  input: ExclusionOptionInput;
};


export type MutationExclusionOptionDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationExclusionOptionUpdateArgs = {
  id: Scalars['ID']['input'];
  input: ExclusionOptionInput;
};


export type MutationExportPaymentInvoicesArgs = {
  filter: PaymentInvoiceFilterInput;
};


export type MutationExtraBenefitCreateArgs = {
  input: ExtraBenefitInput;
};


export type MutationExtraBenefitDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationExtraBenefitUpdateArgs = {
  id: Scalars['ID']['input'];
  input: ExtraBenefitInput;
};


export type MutationFetchTerminologyListDataArgs = {
  codeSystemId: Scalars['ID']['input'];
  searchParameters: Array<InputMaybe<SearchParameter>>;
  subListId: Scalars['ID']['input'];
};


export type MutationFirePlanDecisionsArgs = {
  inputs: Array<InputMaybe<DecisionInputData>>;
  planCode: Scalars['String']['input'];
};


export type MutationFirebaseLogoutArgs = {
  firebaseToken: Scalars['String']['input'];
  uniqueId: Scalars['String']['input'];
};


export type MutationFromGlobalIdArgs = {
  id: Scalars['ID']['input'];
};


export type MutationGeneratePatientReferralArgs = {
  memberId: Scalars['String']['input'];
};


export type MutationGeneratePatientReferralByClinicianArgs = {
  newReferral?: InputMaybe<ReferralRequestInput>;
};


export type MutationGetComplexPdfTextFileArgs = {
  fileName: Scalars['String']['input'];
};


export type MutationGuidedCareHealthProgramCreateArgs = {
  input: GuidedCareHealthProgramInput;
};


export type MutationGuidedCareHealthProgramUpdateArgs = {
  input: GuidedCareHealthProgramInput;
};


export type MutationGuidedCareJourneyInterventionActivateArgs = {
  input: ActivateInterventionInput;
};


export type MutationGuidedCareJourneyInterventionDeactivateArgs = {
  guidedCareJourneyId: Scalars['ID']['input'];
  templateInterventionId: Scalars['ID']['input'];
};


export type MutationGuidedCareJourneyItemUpdateArgs = {
  id: Scalars['ID']['input'];
  input: GuidedCareJourneyItemInput;
};


export type MutationGuidedCareJourneyItemUpdateStatusArgs = {
  id: Scalars['ID']['input'];
  status: GuidedCareCareJourneyItemStatus;
};


export type MutationGuidedCareTaskCreateArgs = {
  input: GuidedCareTaskInput;
};


export type MutationGuidedCareTaskDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationGuidedCareTaskUpdateArgs = {
  id: Scalars['ID']['input'];
  input: GuidedCareTaskUpdateInput;
};


export type MutationHandleCheckoutPaymentRedirectionByPaymentCheckoutIdArgs = {
  paymentCheckoutId: Scalars['String']['input'];
};


export type MutationHandleHyperPayWebhookNotificationArgs = {
  body: Scalars['String']['input'];
};


export type MutationHandleOrderVendorAcceptTimeoutArgs = {
  orders: Array<Scalars['ID']['input']>;
};


export type MutationHandlePaymentRedirectionByPaymentIdArgs = {
  paymentId: Scalars['String']['input'];
};


export type MutationHealthChannelCategoryCreateArgs = {
  input: HealthChannelCategoryInput;
};


export type MutationHealthChannelCategoryDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationHealthChannelCategoryUpdateArgs = {
  id: Scalars['ID']['input'];
  input: HealthChannelCategoryInput;
};


export type MutationHealthChannelCreateArgs = {
  input: HealthChannelInput;
};


export type MutationHealthChannelDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationHealthChannelUpdateArgs = {
  id: Scalars['ID']['input'];
  input: HealthChannelInput;
};


export type MutationHealthProgramBenefitOptionCreateArgs = {
  input: HealthProgramBenefitOptionInput;
};


export type MutationHealthProgramBenefitOptionDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationHealthProgramBenefitOptionUpdateArgs = {
  id: Scalars['ID']['input'];
  input: HealthProgramBenefitOptionInput;
};


export type MutationHealthProgramCreateArgs = {
  input: HealthProgramInput;
};


export type MutationHealthProgramMemberBodyCompositionUpdateArgs = {
  input: HealthProgramMemberBodyCompositionInput;
};


export type MutationHealthProgramMemberCreateArgs = {
  input: HealthProgramMemberInput;
};


export type MutationHealthProgramMemberListCreateArgs = {
  input: HealthProgramMemberListInput;
};


export type MutationHealthProgramMemberListUpdateArgs = {
  id: Scalars['ID']['input'];
  input: HealthProgramMemberListUpdateInput;
};


export type MutationHealthProgramMemberListUpdateActiveStatusArgs = {
  id: Scalars['ID']['input'];
};


export type MutationHealthProgramMemberUpdateArgs = {
  id: Scalars['ID']['input'];
  input: HealthProgramMemberInput;
};


export type MutationHealthProgramMemberUpdateIsDefaultArgs = {
  id: Scalars['ID']['input'];
  isDefault: Scalars['Boolean']['input'];
};


export type MutationHealthProgramMembershipRequestApproveArgs = {
  id: Scalars['ID']['input'];
  input: HealthProgramMembershipRequestApproveInput;
};


export type MutationHealthProgramMembershipRequestCreateArgs = {
  input: HealthProgramMembershipRequestInput;
};


export type MutationHealthProgramMembershipRequestRejectArgs = {
  id: Scalars['ID']['input'];
  rejectionReason: Scalars['String']['input'];
};


export type MutationHealthProgramNetworkCreateArgs = {
  input: HealthProgramNetworkInput;
};


export type MutationHealthProgramNetworkProviderCreateArgs = {
  input: HealthProgramNetworkProviderInput;
};


export type MutationHealthProgramNetworkProviderProfessionalCreateArgs = {
  input: HealthProgramNetworkProviderProfessionalInput;
};


export type MutationHealthProgramNetworkProviderProfessionalUpdateArgs = {
  id: Scalars['ID']['input'];
  input: HealthProgramNetworkProviderProfessionalInput;
};


export type MutationHealthProgramNetworkProviderProfessionalUpdateOverridingVirtualCallEnabledArgs = {
  id: Scalars['ID']['input'];
};


export type MutationHealthProgramNetworkProviderUpdateArgs = {
  id: Scalars['ID']['input'];
  input: HealthProgramNetworkProviderInput;
};


export type MutationHealthProgramNetworkProviderUpdateActiveStatusArgs = {
  id: Scalars['ID']['input'];
};


export type MutationHealthProgramNetworkProviderUpdateVirtualCallEnabledArgs = {
  id: Scalars['ID']['input'];
};


export type MutationHealthProgramNetworkUpdateArgs = {
  id: Scalars['ID']['input'];
  input: HealthProgramNetworkUpdateInput;
};


export type MutationHealthProgramNetworkUpdateActiveStatusArgs = {
  id: Scalars['ID']['input'];
};


export type MutationHealthProgramSubscribeArgs = {
  input: HealthProgramSubscribeInput;
};


export type MutationHealthProgramTemplateCreateArgs = {
  input: HealthProgramTemplateInput;
};


export type MutationHealthProgramTemplateUpdateArgs = {
  input: HealthProgramTemplateInput;
};


export type MutationHealthProgramUnSubscribeArgs = {
  programId: Scalars['ID']['input'];
};


export type MutationHealthProgramUpdateArgs = {
  id: Scalars['ID']['input'];
  input: HealthProgramUpdateInput;
};


export type MutationHealthProgramUpdateActiveStatusArgs = {
  id: Scalars['ID']['input'];
};


export type MutationHealthProgramsSiteSettingsUpdateArgs = {
  input: H_SiteSettingsInput;
};


export type MutationHealthSymptomCreateArgs = {
  input: HealthSymptomInput;
};


export type MutationHealthSymptomDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationHealthSymptomSpecializationDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationHealthSymptomSpecializationsCreateArgs = {
  id: Scalars['ID']['input'];
  specializations: Array<InputMaybe<Scalars['String']['input']>>;
};


export type MutationHealthSymptomUpdateArgs = {
  id: Scalars['ID']['input'];
  input: HealthSymptomInput;
};


export type MutationInsertDeviceInformationArgs = {
  input?: InputMaybe<DeviceInformationInput>;
};


export type MutationInsertPatientHealthParametersArgs = {
  input: Array<PatientHealthParameterInput>;
  visitId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationInsertUnattendedCallArgs = {
  input?: InputMaybe<UnattendedCallInput>;
};


export type MutationInsuranceNetworkCreateArgs = {
  input: InsuranceNetworkInput;
};


export type MutationInsuranceNetworkDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationInsuranceNetworkUpdateArgs = {
  id: Scalars['ID']['input'];
  input: InsuranceNetworkInput;
};


export type MutationInvalidateAnonymousChatGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationJourneySwitchTeamArgs = {
  input: GuidedCarePatientTeamSwitchInput;
};


export type MutationKeycloakPermissionConfigurationUpdateArgs = {
  id: Scalars['ID']['input'];
  input: KeycloakPermissionConfigurationInput;
};


export type MutationLabRequestCreateArgs = {
  input: LabInput;
};


export type MutationLabRequestDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationLanguageCreateArgs = {
  input: LanguageCreateInput;
};


export type MutationLanguageUpdateArgs = {
  id: Scalars['ID']['input'];
  input: LanguageUpdateInput;
};


export type MutationLikeDislikeItemEditArgs = {
  id: Scalars['ID']['input'];
  likeDislike: LikeDislike;
};


export type MutationLockChatGroupArgs = {
  input: LockChatInput;
};


export type MutationMakeInterventionEligibleArgs = {
  guidedCareJourneyId: Scalars['ID']['input'];
  templateInterventionId: Scalars['ID']['input'];
};


export type MutationMarkNotificationAsSeenArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMarketplaceBrandCreateArgs = {
  input: MarketplaceBrandInput;
};


export type MutationMarketplaceBrandUpdateArgs = {
  id: Scalars['ID']['input'];
  input: MarketplaceBrandInput;
};


export type MutationMarketplaceCartAddItemArgs = {
  input: MarketplaceCartInput;
};


export type MutationMarketplaceCartRemoveItemsArgs = {
  cartItemIds: Array<Scalars['ID']['input']>;
};


export type MutationMarketplaceCartUpdateItemQuantityArgs = {
  cartItemId: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};


export type MutationMarketplaceDiscountCreateArgs = {
  input: MarketplaceDiscountCreateInput;
};


export type MutationMarketplaceDiscountPublishArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMarketplaceDiscountUnpublishArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMarketplaceDiscountUpdateArgs = {
  id: Scalars['ID']['input'];
  input: MarketplaceDiscountUpdateInput;
};


export type MutationMarketplaceHealthPackageApproveArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMarketplaceHealthPackageCategoryCreateArgs = {
  input: MarketplaceCategoryInput;
};


export type MutationMarketplaceHealthPackageCategoryUpdateArgs = {
  id: Scalars['ID']['input'];
  input: MarketplaceCategoryInput;
};


export type MutationMarketplaceHealthPackageCloneArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMarketplaceHealthPackageCreateArgs = {
  input: MarketplaceHealthPackageInput;
};


export type MutationMarketplaceHealthPackagePublishArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMarketplaceHealthPackageRejectArgs = {
  id: Scalars['ID']['input'];
  rejectionReason: Scalars['String']['input'];
};


export type MutationMarketplaceHealthPackageSubmitForApprovalArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMarketplaceHealthPackageUnpublishArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMarketplaceHealthPackageUpdateArgs = {
  id: Scalars['ID']['input'];
  input: MarketplaceHealthPackageInput;
};


export type MutationMarketplaceOrderAcceptArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMarketplaceOrderCancelArgs = {
  cancellationReason: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};


export type MutationMarketplaceOrderCancelBySupportTeamArgs = {
  cancellationReason: Scalars['String']['input'];
  id: Scalars['ID']['input'];
};


export type MutationMarketplaceOrderCheckoutCompleteArgs = {
  input: MarketplaceCheckoutCompleteInput;
};


export type MutationMarketplaceOrderConsumedUpdateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMarketplaceOrderDeliveredUpdateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMarketplaceOrderOutForDeliveryUpdateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMarketplaceOrderRejectArgs = {
  id: Scalars['ID']['input'];
  rejectionReason: Scalars['String']['input'];
};


export type MutationMarketplaceOrderStatusUpdateArgs = {
  id: Scalars['ID']['input'];
  status: MarketplaceOrderStatus;
};


export type MutationMarketplaceProductApproveArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMarketplaceProductCategoryCreateArgs = {
  input: MarketplaceCategoryInput;
};


export type MutationMarketplaceProductCategoryUpdateArgs = {
  id: Scalars['ID']['input'];
  input: MarketplaceCategoryInput;
};


export type MutationMarketplaceProductCloneArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMarketplaceProductCreateArgs = {
  input: MarketplaceProductInput;
};


export type MutationMarketplaceProductPublishArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMarketplaceProductRejectArgs = {
  id: Scalars['ID']['input'];
  rejectionReason: Scalars['String']['input'];
};


export type MutationMarketplaceProductSubmitForApprovalArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMarketplaceProductUnpublishArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMarketplaceProductUpdateArgs = {
  id: Scalars['ID']['input'];
  input: MarketplaceProductInput;
};


export type MutationMarketplaceProductUpdateStockQuantityArgs = {
  id: Scalars['ID']['input'];
  quantity: Scalars['Int']['input'];
};


export type MutationMarketplacePromotionCreateArgs = {
  input: MarketplacePromotionInput;
};


export type MutationMarketplacePromotionPublishArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMarketplacePromotionUnpublishArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMarketplacePromotionUpdateArgs = {
  id: Scalars['ID']['input'];
  input: MarketplacePromotionInput;
};


export type MutationMarketplaceReviewSubmitArgs = {
  input: MarketplaceReviewInput;
};


export type MutationMarketplaceWishlistAddItemArgs = {
  input: MarketplaceWishlistInput;
};


export type MutationMarketplaceWishlistRemoveItemsArgs = {
  wishlistItemIds: Array<Scalars['ID']['input']>;
};


export type MutationMarketplaceWishlistUpdateItemQuantityArgs = {
  quantity: Scalars['Int']['input'];
  wishlistItemId: Scalars['ID']['input'];
};


export type MutationMedicalMessageCreateArgs = {
  input: MedicalMessageInput;
};


export type MutationMedicalMessageUpdateArgs = {
  id: Scalars['ID']['input'];
  input: MedicalMessageInput;
};


export type MutationMedicalMessageVariantCreateArgs = {
  input: MedicalMessageVariantInput;
};


export type MutationMedicalMessageVariantUpdateArgs = {
  id: Scalars['ID']['input'];
  input: MedicalMessageVariantInput;
};


export type MutationMeetingPlatformUpdateArgs = {
  meetingPlatformId: Scalars['String']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationMyReviewForVendorArgs = {
  vendor: Scalars['ID']['input'];
};


export type MutationOfflineAppointmentCompleteArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationOptimaActionCreateArgs = {
  input: OptimaActionInput;
};


export type MutationOptimaActionUpdateArgs = {
  id: Scalars['ID']['input'];
  input: OptimaActionInput;
};


export type MutationOptimaEditCreateArgs = {
  input: OptimaEditInput;
};


export type MutationOptimaEditUpdateArgs = {
  id: Scalars['ID']['input'];
  input: OptimaEditInput;
};


export type MutationOrderCancelArgs = {
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};


export type MutationOrderGetErxHubApprovalArgs = {
  orderId: Scalars['ID']['input'];
};


export type MutationOrderStatusUpdateArgs = {
  id: Scalars['ID']['input'];
  input: OrderStatusUpdateInput;
};


export type MutationOrderVendorFinishProcessingArgs = {
  orderId: Scalars['ID']['input'];
};


export type MutationOtpjAssessmentCreateArgs = {
  input?: InputMaybe<OtpjAssessmentInput>;
};


export type MutationOtpjCheckInCreateArgs = {
  input?: InputMaybe<OtpjCheckInInput>;
};


export type MutationOtpjConsultationCreateArgs = {
  input?: InputMaybe<OtpjConsultationInput>;
};


export type MutationOtpjExtPaymentCreateArgs = {
  input?: InputMaybe<OtpjExtPaymentInput>;
};


export type MutationOutPatientJourneySetPriceAmountArgs = {
  id: Scalars['ID']['input'];
  priceAmount: Scalars['Float']['input'];
};


export type MutationOutPatientJourneyStatusUpdateArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  input?: InputMaybe<OutPatientJourneyStatusUpdateInput>;
};


export type MutationPasswordChangeArgs = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};


export type MutationPatientCreateArgs = {
  input: PatientInput;
};


export type MutationPatientEligibilityUpdateArgs = {
  id: Scalars['ID']['input'];
  input: PatientEligibilityInput;
};


export type MutationPatientHealthHistoryCreateArgs = {
  input: PatientHealthHistoryInput;
};


export type MutationPatientHealthHistoryDeleteArgs = {
  id: Scalars['ID']['input'];
  patientId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationPatientMedicalProfileMembershipAssignArgs = {
  membershipId: Scalars['Int']['input'];
};


export type MutationPatientProfileConsentFormApproveArgs = {
  id: Scalars['ID']['input'];
};


export type MutationPatientProfileConsentFormCloseArgs = {
  id: Scalars['ID']['input'];
};


export type MutationPatientProfileConsentFormCreateArgs = {
  input: PatientProfileConsentFormInput;
};


export type MutationPatientProfileConsentFormRejectArgs = {
  id: Scalars['ID']['input'];
};


export type MutationPatientSendOrderOtpArgs = {
  input: PatientSendOrderOtpInput;
};


export type MutationPatientUpdateArgs = {
  id: Scalars['ID']['input'];
  input: PatientUpdateInput;
};


export type MutationPatientUploadVisitAttachmentArgs = {
  input: VisitAttachmentUploadInput;
};


export type MutationPatientVisitCancelArgs = {
  id: Scalars['ID']['input'];
  input: CancelInput;
};


export type MutationPayerAddUpdateMemberBankDetailsArgs = {
  input: PayerAddUpdateMemberBankDetailsRequestInput;
};


export type MutationPayerClaimSubmissionArgs = {
  input: PayerClaimSubmissionRequestInput;
};


export type MutationPayerCreateArgs = {
  input: PayerInput;
};


export type MutationPayerUpdateArgs = {
  id: Scalars['ID']['input'];
  input: PayerInput;
};


export type MutationPermissionGroupCreateArgs = {
  input: PermissionGroupCreateInput;
};


export type MutationPermissionGroupDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationPermissionGroupUpdateArgs = {
  id: Scalars['ID']['input'];
  input: PermissionGroupUpdateInput;
};


export type MutationPlanCreateArgs = {
  input: PlanInput;
};


export type MutationPlanUpdateArgs = {
  id: Scalars['ID']['input'];
  input: PlanInput;
};


export type MutationPrescriptionOrderAssignDefaultBranchArgs = {
  orderId: Scalars['ID']['input'];
};


export type MutationPrescriptionOrderAssignVendorsArgs = {
  input: PrescriptionOrderAssignVendorsInput;
};


export type MutationProcessAppointmentNotificationSettingArgs = {
  appointmentId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  index: Scalars['Int']['input'];
};


export type MutationProcessPaymentCheckoutArgs = {
  paymentCheckoutInput: PaymentCheckoutInput;
};


export type MutationProcessPaymentClearanceArgs = {
  input?: InputMaybe<PaymentClearanceInput>;
};


export type MutationProcessPaymentUsingWalletArgs = {
  input: PaymentClearanceFromMultipleSourcesInput;
};


export type MutationProcessPaymentUsingWalletForTestArgs = {
  input: PaymentClearanceFromMultipleSourcesInput;
  patientId: Scalars['H_Long']['input'];
  userId: Scalars['H_Long']['input'];
};


export type MutationProviderGuidedCareHealthProgramTeamCreateArgs = {
  input: ProviderGuidedCareHealthProgramTeamInput;
};


export type MutationProviderGuidedCareHealthProgramTeamUpdateArgs = {
  input: ProviderGuidedCareHealthProgramTeamInput;
};


export type MutationPublishCodeSystemArgs = {
  codeSystemId: Scalars['ID']['input'];
};


export type MutationPublishDraftHealthProgramTemplateArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
};


export type MutationPublishSurveyArgs = {
  id: Scalars['ID']['input'];
  input: PublishCriteriaInput;
};


export type MutationRadRequestCreateArgs = {
  input: RadInput;
};


export type MutationRefundPaymentFromWalletArgs = {
  input: RefundPaymentFromWalletInput;
};


export type MutationRejectVendorArgs = {
  input: VendorRejectionReasonInput;
};


export type MutationRejectionReasonCreateArgs = {
  input: RejectionReasonInput;
};


export type MutationRejectionReasonDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRejectionReasonUpdateArgs = {
  id: Scalars['ID']['input'];
  input: RejectionReasonInput;
};


export type MutationRemoveVendorFromFavoriteArgs = {
  vendor?: Scalars['ID']['input'];
};


export type MutationRequestOtpForDeleteAccountArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  recaptchaToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRequestPasswordResetArgs = {
  appType: AppTypes;
  passwordResetMethod: PasswordResetMethodEnum;
  recaptchaToken?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
  vendor?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationResubmitOrderGetErxHubApprovalArgs = {
  input: VendorOrderAcceptInput;
  orderId: Scalars['ID']['input'];
};


export type MutationReviewCodeSystemArgs = {
  codeSystemId: Scalars['ID']['input'];
  processingStatus: ProcessingStatus;
};


export type MutationReviewVendorArgs = {
  input: VendorReviewCreateInput;
};


export type MutationReviewVisitArgs = {
  input: VisitReviewInput;
};


export type MutationSaveAppointmentNotificationSettingArgs = {
  input: Array<AppointmentNotificationSettingInput>;
};


export type MutationSaveDoctorNurseAssignmentArgs = {
  input: Array<DoctorNurseAssignmentInput>;
};


export type MutationSaveOrFetchExistedAnonymousChatGroupArgs = {
  fetchOnlyWithoutSave?: InputMaybe<Scalars['Boolean']['input']>;
  meetoraAnonymousChatInput?: InputMaybe<MeetoraAnonymousChatInput>;
  ownerUserId?: InputMaybe<Scalars['ID']['input']>;
  shouldNotCreateAnonymousChat?: InputMaybe<Scalars['Boolean']['input']>;
  userIds: Array<Scalars['ID']['input']>;
};


export type MutationSaveProviderSettingsArgs = {
  input: ProviderSettingsInput;
};


export type MutationSendChatMessageArgs = {
  input: ChatMessageInput;
};


export type MutationSendCodeSystemToReviewArgs = {
  codeSystemId: Scalars['ID']['input'];
};


export type MutationSendGuidedCareAppointmentReminderToTopicArgs = {
  appointmentId: Scalars['ID']['input'];
  reminderNumber: Scalars['Int']['input'];
};


export type MutationSendMedicalFormRequestToTopicArgs = {
  guidedCareJourneyItemId: Scalars['ID']['input'];
  medicalFormId: Scalars['ID']['input'];
  patientId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationSendMedicalMessageRequestToTopicArgs = {
  guidedCareJourneyItemId: Scalars['ID']['input'];
  medicalMessageId: Scalars['ID']['input'];
  medicalMessageType: MedicalMessageType;
  medicalMessageVariantId?: InputMaybe<Scalars['ID']['input']>;
  patientId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationSendNotificationForGuidedCareTaskArgs = {
  taskId: Scalars['ID']['input'];
};


export type MutationSendOtpMessageForAnonymousAppointmentArgs = {
  consumerMobileNumber: Scalars['String']['input'];
  consumerName: Scalars['String']['input'];
};


export type MutationSendVisitMessageArgs = {
  input: VisitMessageInput;
};


export type MutationSetAppointmentServiceDetailsArgs = {
  appointmentId: Scalars['ID']['input'];
  serviceDetailsInputs?: InputMaybe<Array<ServiceDetailsInputForAppointment>>;
};


export type MutationSetClinicianCredentialsArgs = {
  clinicianCredentials: ClinicianCredentialsInput;
  destinationType: DestinationType;
};


export type MutationSetLabResultArgs = {
  input: LabResultInput;
};


export type MutationSetOrderToDeliveredArgs = {
  orderId: Scalars['ID']['input'];
};


export type MutationSetOrderToOutForDeliveryArgs = {
  orderId: Scalars['ID']['input'];
};


export type MutationSetPasswordArgs = {
  appType: AppTypes;
  password: Scalars['String']['input'];
  passwordResetMethod: PasswordResetMethodEnum;
  token: Scalars['String']['input'];
  username: Scalars['String']['input'];
  vendor?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationSetPharmacyCredentialsArgs = {
  destinationType: DestinationType;
  pharmacyCredentials: PharmacyCredentialsInput;
};


export type MutationSetlRiskTemplateResultArgs = {
  input: RiskTemplateResultInput;
};


export type MutationSiteSettingsUpdateArgs = {
  input: SiteSettingsInput;
};


export type MutationSubmitQuestionAnswersForCareJourneyMedicalFormArgs = {
  guidedCareJourneyItemId: Scalars['ID']['input'];
  input: Array<QuestionAnswerCareJourneyExtendedInput>;
};


export type MutationSubmitQuestionAnswersForCareJourneyMedicalFormAdminArgs = {
  guidedCareJourneyItemId: Scalars['ID']['input'];
  input: Array<QuestionAnswerCareJourneyExtendedInput>;
  patientId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};


export type MutationSubmitQuestionAnswersForMedicalFormArgs = {
  input: Array<QuestionAnswerExtendedInput>;
};


export type MutationSubmitSurveyAnswersArgs = {
  input: SurveyAnswerInput;
};


export type MutationTaskDefinitionCreateArgs = {
  input: TaskDefinitionInput;
};


export type MutationTaskDefinitionDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationTaskDefinitionUpdateArgs = {
  id: Scalars['ID']['input'];
  input: TaskDefinitionUpdateInput;
};


export type MutationTentativeAppointmentConfirmArgs = {
  id: Scalars['ID']['input'];
  input: ConsumerAppointmentConfirmInput;
};


export type MutationTerminateVendorSubscriptionArgs = {
  vendor: Scalars['ID']['input'];
};


export type MutationTextSummaryArgs = {
  input: TextSummaryInput;
};


export type MutationTimeoutDoctorVisitRejectArgs = {
  input: DoctorVisitRejectInput;
};


export type MutationToGlobalIdArgs = {
  pk: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};


export type MutationTrackerReminderRescheduleArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  reminderTime: Scalars['Instant']['input'];
};


export type MutationTrackerReminderSettingsCancelArgs = {
  id: Scalars['ID']['input'];
};


export type MutationTrackerReminderSettingsCreateArgs = {
  input: TrackerReminderInput;
};


export type MutationTrackerReminderSettingsUpdateArgs = {
  id: Scalars['ID']['input'];
  input: TrackerReminderSettingsInput;
};


export type MutationTrackerReminderSnoozeArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type MutationTrackerReminderStatusUpdateArgs = {
  ids: Array<Scalars['ID']['input']>;
  status: TrackerReminderStatusUpdate;
};


export type MutationTrackerSupplementTypeCreateArgs = {
  input: TrackerSupplementTypeInput;
};


export type MutationTrackerSupplementTypeUpdateArgs = {
  id: Scalars['ID']['input'];
  input: TrackerSupplementTypeInput;
};


export type MutationTrackerTradeDrugTypeCreateArgs = {
  input: TrackerTradeDrugTypeInput;
};


export type MutationTrackerTradeDrugTypeUpdateArgs = {
  id: Scalars['ID']['input'];
  input: TrackerTradeDrugTypeInput;
};


export type MutationUnAssignUserFromBranchArgs = {
  input?: InputMaybe<UserBranchInput>;
};


export type MutationUnPublishSurveyArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUnbindDependentArgs = {
  input: UnbindDependentInput;
};


export type MutationUpdateAppointmentNotificationSettingArgs = {
  id: Scalars['ID']['input'];
  input: AppointmentNotificationSettingInput;
};


export type MutationUpdateArticleActiveStatusArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUpdateCodeSystemConceptArgs = {
  codeSystemConcept?: InputMaybe<CodeSystemConceptInput>;
};


export type MutationUpdateConceptDefinitionArgs = {
  codeSystemConceptFieldDefinition: CodeSystemConceptFieldDefinitionInput;
};


export type MutationUpdateDecisionArgs = {
  decision: DecisionInput;
};


export type MutationUpdateDecisionStatusArgs = {
  id: Scalars['ID']['input'];
  isActive: Scalars['Boolean']['input'];
};


export type MutationUpdateGuidedCareHealthProgramActivityItemsArgs = {
  activityId: Scalars['ID']['input'];
  activityItems?: InputMaybe<Array<InputMaybe<GuidedCareHealthProgramActivityItemInput>>>;
};


export type MutationUpdateGuidedCareTaskToDoneArgs = {
  actionEntityId: Scalars['ID']['input'];
  assigneeUserId: Scalars['ID']['input'];
  classificationName?: InputMaybe<Scalars['String']['input']>;
  guidedCareJourneyItemId: Scalars['ID']['input'];
  taskWorkflowType: TaskWorkflowType;
};


export type MutationUpdateHealthConditionArgs = {
  id: Scalars['ID']['input'];
  input: HealthConditionUpdateInput;
};


export type MutationUpdateHealthConditionActiveStatusArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUpdateHealthParameterArgs = {
  id: Scalars['ID']['input'];
  input?: InputMaybe<HealthParameterUpdateInput>;
};


export type MutationUpdateHealthParameterActiveStatusArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUpdateLabTemplateArgs = {
  id: Scalars['ID']['input'];
  input: LabTemplateUpdateInput;
};


export type MutationUpdateLabTemplateActiveStatusArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUpdateMedicalFormArgs = {
  id: Scalars['ID']['input'];
  input: MedicalFormInput;
};


export type MutationUpdateMedicalMessagePublishStatusArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUpdateMedicalMessageVariantActiveStatusArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUpdateMeetingRateArgs = {
  meetingId: Scalars['ID']['input'];
  meetingRate: Scalars['Int']['input'];
};


export type MutationUpdateMeetingZoomSessionStatusWithEndArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUpdateOptimaActionActiveStatusArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationUpdateOptimaEditActiveStatusArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationUpdatePatientJourneysMembershipArgs = {
  patientId: Scalars['ID']['input'];
};


export type MutationUpdatePaymentArgs = {
  paymentUpdateInput: PaymentUpdateInput;
};


export type MutationUpdateQuestionArgs = {
  id: Scalars['ID']['input'];
  input: QuestionInput;
};


export type MutationUpdateRiskFactorTemplateArgs = {
  id: Scalars['ID']['input'];
  input: RiskFactorTemplateUpdateInput;
};


export type MutationUpdateRiskFactorTemplateActiveStatusArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUpdateSubListArgs = {
  subList?: InputMaybe<SubListInput>;
};


export type MutationUpdateSubListItemArgs = {
  subListItem: SubListItemInput;
};


export type MutationUpdateSurveyArgs = {
  id: Scalars['ID']['input'];
  input: SurveyInput;
};


export type MutationUpdateTemplateActivityItemArgs = {
  activityItems?: InputMaybe<Array<InputMaybe<TemplateActivityEventItemInput>>>;
};


export type MutationUpdateTemplateActivityItemsArgs = {
  activityId: Scalars['ID']['input'];
  activityItems?: InputMaybe<Array<InputMaybe<TemplateActivityEventItemInput>>>;
};


export type MutationUpdateTemplateInterventionActivityItemsArgs = {
  activityId: Scalars['ID']['input'];
  activityItems?: InputMaybe<Array<InputMaybe<TemplateInterventionActivityEventItemInput>>>;
};


export type MutationUpdateUserEmailMobileArgs = {
  input: UpdateUserEmailMobileInput;
};


export type MutationUpdateVisitPrescriptionAuthStatusArgs = {
  input: PriorAuthorizationInput;
  prescriptionReference: Scalars['String']['input'];
};


export type MutationUploadAttachmentArgs = {
  input: AttachmentInput;
};


export type MutationUploadBulkFileArgs = {
  code: BulkTemplateCode;
  entityID?: InputMaybe<Scalars['ID']['input']>;
  fileName: Scalars['String']['input'];
};


export type MutationUploadCodeSystemFileArgs = {
  codeSystemCode: CodeSystemCode;
  fileName: Scalars['String']['input'];
};


export type MutationUploadNationalCardArgs = {
  input: UploadNationalCardInput;
};


export type MutationUploadSubListArgs = {
  fileName: Scalars['String']['input'];
  subListId: Scalars['ID']['input'];
};


export type MutationUserAvatarUpdateArgs = {
  image: Scalars['String']['input'];
};


export type MutationUserCreateArgs = {
  input: AdminUserCreateInput;
};


export type MutationUserDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUserManagementSiteSettingsUpdateArgs = {
  input: UserManagementSiteSettingsInput;
};


export type MutationUserProfileUpdateArgs = {
  input: UserProfileUpdateInput;
};


export type MutationUserUpdateArgs = {
  id: Scalars['ID']['input'];
  input: UserUpdateInput;
};


export type MutationValidateCptHcpcAgainstPrimaryProcedureArgs = {
  input: Array<CptHcpcPrimaryProcedureValidationInput>;
};


export type MutationValidateInsuranceBenefitApprovalLimitArgs = {
  input: Array<ValidateInsuranceBenefitApprovalLimitInput>;
};


export type MutationValidateItemMaximumQuantityAllowedArgs = {
  input: Array<ValidateItemMaximumQuantityAllowedInput>;
};


export type MutationValidatePrescriptionArgs = {
  input: ValidatePrescriptionInput;
};


export type MutationValidateProcedureAgainstAgeArgs = {
  input: Array<ProcedureAgeValidationInput>;
};


export type MutationValidateProceduresArgs = {
  input: Array<ValidateProceduresInput>;
};


export type MutationVendorCreateArgs = {
  input: VendorAdminInput;
};


export type MutationVendorDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationVendorDepartmentCreateArgs = {
  input: VendorDepartmentCreateInput;
};


export type MutationVendorDepartmentDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationVendorDepartmentUpdateArgs = {
  id: Scalars['ID']['input'];
  input: VendorDepartmentUpdateInput;
};


export type MutationVendorImageCreateArgs = {
  input: VendorImageCreateInput;
};


export type MutationVendorImageDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationVendorImageReorderArgs = {
  imagesIds: Array<InputMaybe<Scalars['ID']['input']>>;
  vendorId: Scalars['ID']['input'];
};


export type MutationVendorImageUpdateArgs = {
  id: Scalars['ID']['input'];
  input: VendorImageUpdateInput;
};


export type MutationVendorMarkMessagesAsSeenArgs = {
  branchId: Scalars['String']['input'];
  customerId: Scalars['String']['input'];
};


export type MutationVendorOrderAcceptArgs = {
  input: VendorOrderAcceptInput;
  orderId: Scalars['ID']['input'];
};


export type MutationVendorOrderCancelArgs = {
  cancellationReason: Scalars['String']['input'];
  orderId: Scalars['ID']['input'];
};


export type MutationVendorOrderDeliveryStatusUpdateArgs = {
  input: OrderDeliveryStatusUpdateInput;
  orderId: Scalars['ID']['input'];
};


export type MutationVendorOrderRejectArgs = {
  orderId: Scalars['ID']['input'];
  rejectionReason: Scalars['String']['input'];
};


export type MutationVendorRegisterArgs = {
  input: VendorInput;
};


export type MutationVendorReviewDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationVendorReviewPublishArgs = {
  id: Scalars['ID']['input'];
  input: VendorReviewPublishInput;
};


export type MutationVendorSendMessageArgs = {
  input: VendorMessageInput;
};


export type MutationVendorUpdateArgs = {
  id: Scalars['ID']['input'];
  input: VendorUpdateAdminInput;
};


export type MutationVendorUpdateEnableVisitDetailsArgs = {
  id: Scalars['ID']['input'];
  input: VendorUpdateEnableVisitDetailsInput;
};


export type MutationVendorUpdateIsIntegratedArgs = {
  id: Scalars['ID']['input'];
  input: VendorUpdateIsIntegratedInput;
};


export type MutationVendorUserCreateArgs = {
  input: VendorUserCreateInput;
};


export type MutationVendorUserDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationVendorUserUpdateArgs = {
  id: Scalars['ID']['input'];
  input: VendorUserUpdateInput;
};


export type MutationVerifyCredentialsArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  recaptchaToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationVerifyNationalIdArgs = {
  id: Scalars['ID']['input'];
  input: VerifyNationalIdInput;
};


export type MutationVisitAssignBranchArgs = {
  branchId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
};


export type MutationVisitAssistantAgentStatusUpdateArgs = {
  connected: Scalars['Boolean']['input'];
  id: Scalars['ID']['input'];
};


export type MutationVisitCallEndArgs = {
  visitId: Scalars['ID']['input'];
};


export type MutationVisitCallRejoinArgs = {
  visitId: Scalars['ID']['input'];
};


export type MutationVisitCancelBySupportTeamArgs = {
  id: Scalars['ID']['input'];
  input: CancelInput;
};


export type MutationVisitComplainCreateArgs = {
  input: VisitComplainCreateInput;
};


export type MutationVisitDoctorAssignArgs = {
  doctorAvailabilityStatus?: InputMaybe<Scalars['String']['input']>;
  doctorId: Scalars['ID']['input'];
  doctorUserId: Scalars['ID']['input'];
  visitId: Scalars['ID']['input'];
};


export type MutationVisitDoctorNoteUpdateArgs = {
  input: VisitDoctorNoteInput;
};


export type MutationVisitEvaluationRateSetArgs = {
  callQualityRate?: InputMaybe<Scalars['Float']['input']>;
  consultationRate?: InputMaybe<Scalars['Float']['input']>;
  id: Scalars['ID']['input'];
};


export type MutationVisitNewCallStartArgs = {
  visitId: Scalars['ID']['input'];
};


export type MutationVisitNotToPatientUpdateArgs = {
  input: VisitDoctorNoteInput;
};


export type MutationVisitStatusUpdateArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  input?: InputMaybe<VisitStatusUpdateInput>;
};


export type MutationVisitSummaryCreateArgs = {
  input: VisitSummaryInput;
};


export type MutationVisitSummaryDeleteArgs = {
  id: Scalars['ID']['input'];
};


export type MutationVisitSummaryUpdateArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  input: VisitSummaryInput;
};


export type MutationVisitTranscriptionArgs = {
  input: TranscribeInput;
};

export type MutationConsumerAppointmentCreate = {
  __typename?: 'MutationConsumerAppointmentCreate';
  appointment?: Maybe<Appointment>;
  providerGuidedCareHealthProgramTeamErrors?: Maybe<Array<H_EntityError>>;
};

export type MutationPersonalizedConsumerAppointmentCreate = {
  __typename?: 'MutationPersonalizedConsumerAppointmentCreate';
  appointmentErrors?: Maybe<Array<H_EntityError>>;
  appointments?: Maybe<Array<Maybe<Appointment>>>;
  requestStatus?: Maybe<RequestStatus>;
};

export type MyReviewForVendor = {
  __typename?: 'MyReviewForVendor';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  review?: Maybe<VendorReview>;
  vendorErrors: Array<VendorError>;
};

export type NeedActionCodeSystems = {
  __typename?: 'NeedActionCodeSystems';
  codeSystemEvents?: Maybe<Array<Maybe<CodeSystemEvent>>>;
  count?: Maybe<Scalars['Int']['output']>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object. */
  id: Scalars['ID']['output'];
};

export enum NotificationChannel {
  App = 'APP',
  Email = 'EMAIL',
  Sms = 'SMS'
}

export enum NotificationType {
  ActivityTrackerNotification = 'ACTIVITY_TRACKER_NOTIFICATION',
  BulkFile = 'BULK_FILE',
  Chat = 'CHAT',
  ChronicProgramEnrollment = 'CHRONIC_PROGRAM_ENROLLMENT',
  DoctorAvailabilityStatusChanged = 'DOCTOR_AVAILABILITY_STATUS_CHANGED',
  ErxCreate = 'ERX_CREATE',
  ErxDeliveryStatusUpdate = 'ERX_DELIVERY_STATUS_UPDATE',
  ErxStatusUpdate = 'ERX_STATUS_UPDATE',
  GuidedCareAppointmentBookingReminder = 'GUIDED_CARE_APPOINTMENT_BOOKING_REMINDER',
  GuidedCareInterventionNotification = 'GUIDED_CARE_INTERVENTION_NOTIFICATION',
  GuidedCareTaskNotification = 'GUIDED_CARE_TASK_NOTIFICATION',
  HealthParameters = 'HEALTH_PARAMETERS',
  HealthProgramAppointmentReminder = 'HEALTH_PROGRAM_APPOINTMENT_REMINDER',
  HealthProgramAppointmentStatusChanged = 'HEALTH_PROGRAM_APPOINTMENT_STATUS_CHANGED',
  HealthProgramNotification = 'HEALTH_PROGRAM_NOTIFICATION',
  HealthProgramVisitStatusChanged = 'HEALTH_PROGRAM_VISIT_STATUS_CHANGED',
  MarketplaceCheckoutCompleteFailure = 'MARKETPLACE_CHECKOUT_COMPLETE_FAILURE',
  MarketplaceOrderStatusUpdate = 'MARKETPLACE_ORDER_STATUS_UPDATE',
  MarkAllAsRead = 'MARK_ALL_AS_READ',
  MarkAsRead = 'MARK_AS_READ',
  MedicalForm = 'MEDICAL_FORM',
  MedicalMessage = 'MEDICAL_MESSAGE',
  NeedActionCodeSystem = 'NEED_ACTION_CODE_SYSTEM',
  NewNotification = 'NEW_NOTIFICATION',
  NewTransactionNotification = 'NEW_TRANSACTION_NOTIFICATION',
  OrderDeleted = 'ORDER_DELETED',
  OrderStatusUpdate = 'ORDER_STATUS_UPDATE',
  OrderTimeoutAlert = 'ORDER_TIMEOUT_ALERT',
  PatientEligibilityStatusUpdate = 'PATIENT_ELIGIBILITY_STATUS_UPDATE',
  PatientEnrollmentRequest = 'PATIENT_ENROLLMENT_REQUEST',
  PatientProfileAccessClose = 'PATIENT_PROFILE_ACCESS_CLOSE',
  PatientProfileAccessRequest = 'PATIENT_PROFILE_ACCESS_REQUEST',
  PatientProfileAccessResponse = 'PATIENT_PROFILE_ACCESS_RESPONSE',
  PaymentNotification = 'PAYMENT_NOTIFICATION',
  PingPong = 'PING_PONG',
  PrescriptionAuthStatusChanged = 'PRESCRIPTION_AUTH_STATUS_CHANGED',
  SurveyNotification = 'SURVEY_NOTIFICATION',
  ValidationRequest = 'VALIDATION_REQUEST',
  VisitVitalSigns = 'VISIT_VITAL_SIGNS'
}

export type Nurse = Node & {
  __typename?: 'Nurse';
  created: Scalars['DateTime']['output'];
  healthLicenseEndDate?: Maybe<Scalars['Date']['output']>;
  healthLicenseNumber?: Maybe<Scalars['String']['output']>;
  healthLicenseStartDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type NurseInput = {
  healthLicenseEndDate?: InputMaybe<Scalars['Date']['input']>;
  healthLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  healthLicenseStartDate?: InputMaybe<Scalars['Date']['input']>;
};

export type Nutritionist = Node & {
  __typename?: 'Nutritionist';
  created: Scalars['DateTime']['output'];
  healthLicenseEndDate?: Maybe<Scalars['Date']['output']>;
  healthLicenseNumber?: Maybe<Scalars['String']['output']>;
  healthLicenseStartDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type NutritionistInput = {
  healthLicenseEndDate?: InputMaybe<Scalars['Date']['input']>;
  healthLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  healthLicenseStartDate?: InputMaybe<Scalars['Date']['input']>;
};

export enum OperationStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Successful = 'SUCCESSFUL'
}

export enum OperationType {
  AddToWallet = 'ADD_TO_WALLET',
  DeductFromWallet = 'DEDUCT_FROM_WALLET',
  Refund = 'REFUND',
  RefundFromWallet = 'REFUND_FROM_WALLET'
}

export enum Operator {
  Contains = 'CONTAINS',
  Count = 'COUNT',
  EndWith = 'END_WITH',
  Equal = 'EQUAL',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqual = 'GREATER_THAN_OR_EQUAL',
  In = 'IN',
  IsEmpty = 'IS_EMPTY',
  IsNotEmpty = 'IS_NOT_EMPTY',
  LessThan = 'LESS_THAN',
  LessThanOrEqual = 'LESS_THAN_OR_EQUAL',
  No = 'NO',
  NotContains = 'NOT_CONTAINS',
  NotEqual = 'NOT_EQUAL',
  NotIn = 'NOT_IN',
  StartWith = 'START_WITH',
  Yes = 'YES'
}

export type OptimaAction = {
  __typename?: 'OptimaAction';
  action?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  summary?: Maybe<Scalars['String']['output']>;
};

export type OptimaActionConnection = {
  __typename?: 'OptimaActionConnection';
  edges: Array<OptimaActionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type OptimaActionEdge = {
  __typename?: 'OptimaActionEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: OptimaAction;
};

export type OptimaActionFilterInput = {
  action?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OptimaActionInput = {
  action: Scalars['String']['input'];
  isActive: Scalars['Boolean']['input'];
  link: Scalars['String']['input'];
  summary: Scalars['String']['input'];
};

export type OptimaActivityEvaluation = {
  __typename?: 'OptimaActivityEvaluation';
  itemSequence: Scalars['String']['output'];
  medicalEditCodes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type OptimaAudit = {
  __typename?: 'OptimaAudit';
  created?: Maybe<Scalars['String']['output']>;
  details?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  reference?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
};

export type OptimaAuditInput = {
  created?: InputMaybe<Scalars['Instant']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  sequence?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  userName?: InputMaybe<Scalars['String']['input']>;
};

export type OptimaCareTeam = {
  __typename?: 'OptimaCareTeam';
  id: Scalars['ID']['output'];
  practitionerId?: Maybe<Scalars['String']['output']>;
  practitionerRole?: Maybe<Scalars['String']['output']>;
  speciality?: Maybe<Scalars['String']['output']>;
};

export type OptimaCareTeamInput = {
  practitionerId?: InputMaybe<Scalars['String']['input']>;
  practitionerRole?: InputMaybe<Scalars['String']['input']>;
  sequence?: InputMaybe<Scalars['String']['input']>;
  speciality?: InputMaybe<Scalars['String']['input']>;
};

export type OptimaDiagnosis = {
  __typename?: 'OptimaDiagnosis';
  code?: Maybe<Scalars['String']['output']>;
  codeSystem?: Maybe<Scalars['String']['output']>;
  display?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  onAdmission?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type OptimaDiagnosisInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  codeSystem?: InputMaybe<Scalars['String']['input']>;
  onAdmission?: InputMaybe<Scalars['Boolean']['input']>;
  sequence?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export enum OptimaDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OptimaEdit = {
  __typename?: 'OptimaEdit';
  actions?: Maybe<Array<Maybe<OptimaAction>>>;
  category?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  details?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<OptimaPriority>;
  source?: Maybe<Scalars['String']['output']>;
  subCategory?: Maybe<Scalars['String']['output']>;
};

export type OptimaEditConnection = {
  __typename?: 'OptimaEditConnection';
  edges: Array<OptimaEditEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type OptimaEditEdge = {
  __typename?: 'OptimaEditEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: OptimaEdit;
};

export type OptimaEditFilterInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OptimaEditInput = {
  actionIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  category: Scalars['String']['input'];
  code: Scalars['String']['input'];
  details: Scalars['String']['input'];
  isActive: Scalars['Boolean']['input'];
  message: Scalars['String']['input'];
  priority: OptimaPriority;
  source: Scalars['String']['input'];
  subCategory: Scalars['String']['input'];
};

export type OptimaEncounter = {
  __typename?: 'OptimaEncounter';
  eligibilityId?: Maybe<Scalars['String']['output']>;
  end?: Maybe<Scalars['String']['output']>;
  endType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  start?: Maybe<Scalars['String']['output']>;
  startType?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  transferDestination?: Maybe<Scalars['String']['output']>;
  transferSource?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type OptimaEncounterInput = {
  eligibilityId?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['Instant']['input']>;
  endType?: InputMaybe<Scalars['String']['input']>;
  sequence?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['Instant']['input']>;
  startType?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  transferDestination?: InputMaybe<Scalars['String']['input']>;
  transferSource?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type OptimaItem = {
  __typename?: 'OptimaItem';
  authorizationId?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  codeSystem?: Maybe<Scalars['String']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  display?: Maybe<Scalars['String']['output']>;
  edits?: Maybe<Array<Maybe<OptimaItemEdit>>>;
  id: Scalars['ID']['output'];
  linkedCareTeam?: Maybe<OptimaCareTeam>;
  linkedDiagnoses?: Maybe<Array<Maybe<OptimaDiagnosis>>>;
  linkedObservations?: Maybe<Array<Maybe<OptimaObservation>>>;
  net?: Maybe<Scalars['Float']['output']>;
  patientShare?: Maybe<Scalars['Float']['output']>;
  percentageOfAcceptance?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  riskFactors?: Maybe<Array<Maybe<RiskFactor>>>;
  serviceEnd?: Maybe<Scalars['String']['output']>;
  serviceStart?: Maybe<Scalars['String']['output']>;
  tax?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  unitPrice?: Maybe<Scalars['Float']['output']>;
};

export type OptimaItemEdit = {
  __typename?: 'OptimaItemEdit';
  edit?: Maybe<OptimaEdit>;
  id: Scalars['ID']['output'];
  likeDislike?: Maybe<LikeDislike>;
};

export type OptimaItemInput = {
  authorizationId?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  codeSystem?: InputMaybe<Scalars['String']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  linkedDiagnoses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  net?: InputMaybe<Scalars['Float']['input']>;
  patientShare?: InputMaybe<Scalars['Float']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  sequence?: InputMaybe<Scalars['String']['input']>;
  serviceEnd?: InputMaybe<Scalars['Instant']['input']>;
  serviceStart?: InputMaybe<Scalars['Instant']['input']>;
  tax?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  unitPrice?: InputMaybe<Scalars['Float']['input']>;
};

export type OptimaObservation = {
  __typename?: 'OptimaObservation';
  analyticCode?: Maybe<Scalars['String']['output']>;
  analyticDescription?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  codeSystem?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  periodEnd?: Maybe<Scalars['String']['output']>;
  periodStart?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type OptimaObservationInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  codeSystem?: InputMaybe<Scalars['String']['input']>;
  periodEnd?: InputMaybe<Scalars['Instant']['input']>;
  periodStart?: InputMaybe<Scalars['Instant']['input']>;
  sequence?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type OptimaPatientEligibility = {
  __typename?: 'OptimaPatientEligibility';
  active?: Maybe<Scalars['Boolean']['output']>;
  benefitEligibility?: Maybe<Array<Maybe<BenefitEligibility>>>;
  benefits?: Maybe<Array<Maybe<PatientEligibilityBenefit>>>;
  createdDate: Scalars['Instant']['output'];
  dateOfBirth?: Maybe<Scalars['Instant']['output']>;
  effectiveFrom?: Maybe<Scalars['Instant']['output']>;
  effectiveTo?: Maybe<Scalars['Instant']['output']>;
  emiratesId: Scalars['String']['output'];
  enrollmentDate?: Maybe<Scalars['Instant']['output']>;
  excludedBenefits?: Maybe<Array<Maybe<PatientEligibilityExcludedBenefit>>>;
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  insuranceName?: Maybe<Scalars['String']['output']>;
  isLatest?: Maybe<Scalars['Boolean']['output']>;
  kind?: Maybe<Scalars['String']['output']>;
  mdSummary?: Maybe<Scalars['String']['output']>;
  memberName?: Maybe<Scalars['String']['output']>;
  memberNo?: Maybe<Scalars['String']['output']>;
  mobile?: Maybe<Scalars['String']['output']>;
  mobileCountryCode?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  patient: Patient;
  payer?: Maybe<PatientEligibilityPayer>;
  policy?: Maybe<PatientEligibilityPolicy>;
  receiver?: Maybe<PatientEligibilityReceiver>;
  snapshots?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  summary?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['Int']['output']>;
};

export enum OptimaPriority {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export type OptimaRequestInput = {
  audit?: InputMaybe<Array<InputMaybe<OptimaAuditInput>>>;
  careTeam?: InputMaybe<Array<InputMaybe<OptimaCareTeamInput>>>;
  diagnoses?: InputMaybe<Array<InputMaybe<OptimaDiagnosisInput>>>;
  encounter?: InputMaybe<Array<InputMaybe<OptimaEncounterInput>>>;
  facility?: InputMaybe<Scalars['String']['input']>;
  gross?: InputMaybe<Scalars['Float']['input']>;
  insuranceMemberId?: InputMaybe<Scalars['String']['input']>;
  insurancePayer?: InputMaybe<Scalars['String']['input']>;
  insurancePlan?: InputMaybe<Scalars['String']['input']>;
  insurancePolicy?: InputMaybe<Scalars['String']['input']>;
  insurancePolicyLocation?: InputMaybe<Scalars['String']['input']>;
  insuranceReceiver?: InputMaybe<Scalars['String']['input']>;
  items?: InputMaybe<Array<InputMaybe<OptimaItemInput>>>;
  net?: InputMaybe<Scalars['Float']['input']>;
  observations?: InputMaybe<Array<InputMaybe<OptimaObservationInput>>>;
  patientDoB?: InputMaybe<Scalars['Date']['input']>;
  patientGender?: InputMaybe<Scalars['String']['input']>;
  patientHeight?: InputMaybe<Scalars['Float']['input']>;
  patientId?: InputMaybe<Scalars['String']['input']>;
  patientShare?: InputMaybe<Scalars['Float']['input']>;
  patientWeight?: InputMaybe<Scalars['Float']['input']>;
  payeeType?: InputMaybe<Scalars['String']['input']>;
  prescriptionId?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['String']['input']>;
  providerId?: InputMaybe<Scalars['String']['input']>;
  referral?: InputMaybe<Scalars['String']['input']>;
  requestId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  subType?: InputMaybe<Scalars['String']['input']>;
  tax?: InputMaybe<Scalars['Float']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  use?: InputMaybe<Scalars['String']['input']>;
};

export enum OptimaUse {
  Claim = 'CLAIM',
  Preauthorization = 'PREAUTHORIZATION',
  Predetermination = 'PREDETERMINATION'
}

export type OptimaValidationRequest = {
  __typename?: 'OptimaValidationRequest';
  audit?: Maybe<Array<Maybe<OptimaAudit>>>;
  careTeam?: Maybe<Array<Maybe<OptimaCareTeam>>>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  diagnoses?: Maybe<Array<Maybe<OptimaDiagnosis>>>;
  edits?: Maybe<Array<Maybe<OptimaEdit>>>;
  encounter?: Maybe<Array<Maybe<OptimaEncounter>>>;
  facility?: Maybe<Scalars['String']['output']>;
  gross?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  insuranceMemberId?: Maybe<Scalars['String']['output']>;
  insurancePayer?: Maybe<Scalars['String']['output']>;
  insurancePlan?: Maybe<Scalars['String']['output']>;
  insurancePolicy?: Maybe<Scalars['String']['output']>;
  insurancePolicyLocation?: Maybe<Scalars['String']['output']>;
  insuranceReceiver?: Maybe<Scalars['String']['output']>;
  items?: Maybe<Array<Maybe<OptimaItem>>>;
  net?: Maybe<Scalars['Float']['output']>;
  observations?: Maybe<Array<Maybe<OptimaObservation>>>;
  patientDoB?: Maybe<Scalars['String']['output']>;
  patientGender?: Maybe<Gender>;
  patientHeight?: Maybe<Scalars['Float']['output']>;
  patientId?: Maybe<Scalars['String']['output']>;
  patientName?: Maybe<Scalars['String']['output']>;
  patientShare?: Maybe<Scalars['Float']['output']>;
  patientWeight?: Maybe<Scalars['Float']['output']>;
  payeeType?: Maybe<Scalars['String']['output']>;
  prescriptionId?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<OptimaPriority>;
  providerId?: Maybe<Scalars['String']['output']>;
  referral?: Maybe<Scalars['String']['output']>;
  request?: Maybe<Scalars['String']['output']>;
  requestId?: Maybe<Scalars['String']['output']>;
  response?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subType?: Maybe<Scalars['String']['output']>;
  tax?: Maybe<Scalars['Float']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  use?: Maybe<OptimaUse>;
  visitId?: Maybe<Scalars['String']['output']>;
  visitNotes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  visitReasons?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type OptimaValidationRequestFilterInput = {
  fromDate?: InputMaybe<Scalars['Instant']['input']>;
  hasDislikedEdits?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  patientId?: InputMaybe<Scalars['String']['input']>;
  patientName?: InputMaybe<Scalars['String']['input']>;
  practitionerId?: InputMaybe<Scalars['String']['input']>;
  requestId?: InputMaybe<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['Instant']['input']>;
  visitId?: InputMaybe<Scalars['String']['input']>;
};

export type OptimaValidationRequestsConnection = {
  __typename?: 'OptimaValidationRequestsConnection';
  edges: Array<OptimaValidationRequestsEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type OptimaValidationRequestsEdge = {
  __typename?: 'OptimaValidationRequestsEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: OptimaValidationRequest;
};

export enum OptimaValidationRequestsSortingField {
  Created = 'CREATED',
  PaymentMethod = 'PAYMENT_METHOD',
  Status = 'STATUS',
  Type = 'TYPE'
}

export type OptimaValidationRequestsSortingInput = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<OptimaValidationRequestsSortingField>;
};

export type Optometrist = Node & {
  __typename?: 'Optometrist';
  created: Scalars['DateTime']['output'];
  healthLicenseEndDate?: Maybe<Scalars['Date']['output']>;
  healthLicenseNumber?: Maybe<Scalars['String']['output']>;
  healthLicenseStartDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type OptometristInput = {
  healthLicenseEndDate?: InputMaybe<Scalars['Date']['input']>;
  healthLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  healthLicenseStartDate?: InputMaybe<Scalars['Date']['input']>;
};

export type Order = {
  __typename?: 'Order';
  address: PrescriptionAddress;
  branch?: Maybe<Branch>;
  cancellationReason?: Maybe<Scalars['String']['output']>;
  children?: Maybe<Array<Maybe<Order>>>;
  created: Scalars['Instant']['output'];
  deliveryDate?: Maybe<Scalars['Instant']['output']>;
  distanceGroup?: Maybe<Scalars['String']['output']>;
  failReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isDeliveryRequested: Scalars['Boolean']['output'];
  isFreeDelivery?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['Instant']['output']>;
  number: Scalars['ID']['output'];
  orderEvents?: Maybe<Array<Maybe<OrderEvent>>>;
  orderLines: Array<OrderLine>;
  parent?: Maybe<Order>;
  paymentMethod?: Maybe<OrderPaymentMethod>;
  paymentOperationLogs: PaymentOperationLogCountableConnection;
  paymentReceipts: PaymentReceiptCountableConnection;
  pickUpOtp?: Maybe<Scalars['String']['output']>;
  prescription: Prescription;
  rejectionReason?: Maybe<Scalars['String']['output']>;
  status: OrderStatus;
  totalNetAmount?: Maybe<Scalars['Float']['output']>;
  totalOrderDistance?: Maybe<Scalars['Float']['output']>;
  totalPatientShareAmount?: Maybe<Scalars['Float']['output']>;
  type: OrderType;
  user: User;
  vendor?: Maybe<Vendor>;
};


export type OrderPaymentOperationLogsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PaymentOperationLogFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OrderPaymentReceiptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PaymentReceiptFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type OrderCountableConnection = {
  __typename?: 'OrderCountableConnection';
  edges: Array<OrderCountableEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type OrderCountableEdge = {
  __typename?: 'OrderCountableEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: Order;
};

export enum OrderDeliveryStatus {
  CarrierAllocated = 'CARRIER_ALLOCATED',
  DeliveryTimedOut = 'DELIVERY_TIMED_OUT',
  DriverAcceptedOrder = 'DRIVER_ACCEPTED_ORDER',
  DriverIsAboutToArrive = 'DRIVER_IS_ABOUT_TO_ARRIVE',
  DriverPickedUpOrder = 'DRIVER_PICKED_UP_ORDER',
  Failed = 'FAILED',
  OrderIsCancelled = 'ORDER_IS_CANCELLED',
  OrderIsDelivered = 'ORDER_IS_DELIVERED',
  OrderIsResumed = 'ORDER_IS_RESUMED',
  OrderIsSuspended = 'ORDER_IS_SUSPENDED',
  Pending = 'PENDING',
  PickedByConsumer = 'PICKED_BY_CONSUMER',
  Returned = 'RETURNED'
}

export type OrderDeliveryStatusUpdateInput = {
  carrierName?: InputMaybe<Scalars['String']['input']>;
  customOrderRejectionReason?: InputMaybe<Scalars['String']['input']>;
  deliveryPrice?: InputMaybe<Scalars['Float']['input']>;
  deliveryStatus: OrderDeliveryStatus;
  distanceGroup?: InputMaybe<Scalars['String']['input']>;
  pickupOTP?: InputMaybe<Scalars['String']['input']>;
  rejectionReasonId?: InputMaybe<Scalars['ID']['input']>;
  totalOrderDistance?: InputMaybe<Scalars['Float']['input']>;
};

export enum OrderDirection {
  /** Specifies an ascending sort order. */
  Asc = 'ASC',
  /** Specifies a descending sort order. */
  Desc = 'DESC'
}

export type OrderEvent = {
  __typename?: 'OrderEvent';
  date: Scalars['Instant']['output'];
  id: Scalars['ID']['output'];
  parameters?: Maybe<Scalars['String']['output']>;
  status: OrderStatus;
  type?: Maybe<OrderEventType>;
  user: User;
};

export enum OrderEventType {
  Allocated = 'ALLOCATED',
  Canceled = 'CANCELED',
  CardPaid = 'CARD_PAID',
  CarrierAllocated = 'CARRIER_ALLOCATED',
  Consumed = 'CONSUMED',
  ConsumerCanceled = 'CONSUMER_CANCELED',
  ConsumerPicked = 'CONSUMER_PICKED',
  Created = 'CREATED',
  Delivered = 'DELIVERED',
  DeliveredTimedOut = 'DELIVERED_TIMED_OUT',
  Dispensed = 'DISPENSED',
  DraftAddedProducts = 'DRAFT_ADDED_PRODUCTS',
  DraftCreated = 'DRAFT_CREATED',
  DraftCustomerAccepted = 'DRAFT_CUSTOMER_ACCEPTED',
  DraftRemovedProducts = 'DRAFT_REMOVED_PRODUCTS',
  DriverAcceptedOrder = 'DRIVER_ACCEPTED_ORDER',
  DriverDelivered = 'DRIVER_DELIVERED',
  DriverIsAboutToArrive = 'DRIVER_IS_ABOUT_TO_ARRIVE',
  DriverPicked = 'DRIVER_PICKED',
  DriverReturned = 'DRIVER_RETURNED',
  EmailSent = 'EMAIL_SENT',
  ErxHubAuthRequestFailed = 'ERX_HUB_AUTH_REQUEST_FAILED',
  ErxHubAuthSubmitTimedOut = 'ERX_HUB_AUTH_SUBMIT_TIMED_OUT',
  ErxHubClaimApproved = 'ERX_HUB_CLAIM_APPROVED',
  ErxHubClaimFailed = 'ERX_HUB_CLAIM_FAILED',
  ErxHubClaimRejected = 'ERX_HUB_CLAIM_REJECTED',
  ErxHubClaimSubmitTimedOut = 'ERX_HUB_CLAIM_SUBMIT_TIMED_OUT',
  ErxHubFullyApproved = 'ERX_HUB_FULLY_APPROVED',
  ErxHubPartiallyApproved = 'ERX_HUB_PARTIALLY_APPROVED',
  ErxHubRejected = 'ERX_HUB_REJECTED',
  ErxHubTimedOut = 'ERX_HUB_TIMED_OUT',
  Failed = 'FAILED',
  FulfillmentCanceled = 'FULFILLMENT_CANCELED',
  FulfillmentFulfilledItems = 'FULFILLMENT_FULFILLED_ITEMS',
  FulfillmentRestockedItems = 'FULFILLMENT_RESTOCKED_ITEMS',
  NoteAdded = 'NOTE_ADDED',
  OrderDeliveryFailed = 'ORDER_DELIVERY_FAILED',
  OrderDeliveryIsCancelled = 'ORDER_DELIVERY_IS_CANCELLED',
  OrderDeliveryIsResumed = 'ORDER_DELIVERY_IS_RESUMED',
  OrderDeliveryIsSuspended = 'ORDER_DELIVERY_IS_SUSPENDED',
  OrderDeliveryTimedOut = 'ORDER_DELIVERY_TIMED_OUT',
  OrderFullyPaid = 'ORDER_FULLY_PAID',
  OrderMarkedAsPaid = 'ORDER_MARKED_AS_PAID',
  Other = 'OTHER',
  OutForDelivery = 'OUT_FOR_DELIVERY',
  OutForDeliveryTimedOut = 'OUT_FOR_DELIVERY_TIMED_OUT',
  OversoldItems = 'OVERSOLD_ITEMS',
  PaymentCaptured = 'PAYMENT_CAPTURED',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentOnArrive = 'PAYMENT_ON_ARRIVE',
  PaymentPending = 'PAYMENT_PENDING',
  PaymentRefunded = 'PAYMENT_REFUNDED',
  PaymentSucceeded = 'PAYMENT_SUCCEEDED',
  PaymentTimedOut = 'PAYMENT_TIMED_OUT',
  PaymentVoided = 'PAYMENT_VOIDED',
  PendingDelivery = 'PENDING_DELIVERY',
  PickedByConsumer = 'PICKED_BY_CONSUMER',
  Placed = 'PLACED',
  PlacedFromDraft = 'PLACED_FROM_DRAFT',
  PrescriptionOrderCreated = 'PRESCRIPTION_ORDER_CREATED',
  PrescriptionOrderVendorAssigned = 'PRESCRIPTION_ORDER_VENDOR_ASSIGNED',
  PrescriptionRefillOrderCreated = 'PRESCRIPTION_REFILL_ORDER_CREATED',
  ReadyForCustomerPickup = 'READY_FOR_CUSTOMER_PICKUP',
  Returned = 'RETURNED',
  ReturnedTimedOut = 'RETURNED_TIMED_OUT',
  ReturnInProgress = 'RETURN_IN_PROGRESS',
  TrackingUpdated = 'TRACKING_UPDATED',
  Updated = 'UPDATED',
  UpdatedAddress = 'UPDATED_ADDRESS',
  VendorAccepted = 'VENDOR_ACCEPTED',
  VendorCanceled = 'VENDOR_CANCELED',
  VendorDelivered = 'VENDOR_DELIVERED',
  VendorFinishedProcessing = 'VENDOR_FINISHED_PROCESSING',
  VendorRejected = 'VENDOR_REJECTED',
  VendorResponseTimedOut = 'VENDOR_RESPONSE_TIMED_OUT',
  VendorReturned = 'VENDOR_RETURNED',
  WaitingErxHubAuthApproval = 'WAITING_ERX_HUB_AUTH_APPROVAL',
  WaitingErxHubClaimApproval = 'WAITING_ERX_HUB_CLAIM_APPROVAL'
}

export type OrderFilterInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  branches?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  childrenAndFailedOrders?: InputMaybe<Scalars['Boolean']['input']>;
  clinician?: InputMaybe<Scalars['String']['input']>;
  created?: InputMaybe<DateRangeInput>;
  isDelivery?: InputMaybe<Scalars['Boolean']['input']>;
  modified?: InputMaybe<DateRangeInput>;
  numbers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  orderGlobalSearch?: InputMaybe<Scalars['String']['input']>;
  parentOrders?: InputMaybe<Scalars['Boolean']['input']>;
  prescription?: InputMaybe<Scalars['ID']['input']>;
  price?: InputMaybe<IntRangeInput>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<OrderStatusEnum>>>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type OrderLine = {
  __typename?: 'OrderLine';
  authStatus?: Maybe<AuthorizationStatus>;
  id: Scalars['ID']['output'];
  medication?: Maybe<Medication>;
  netAmount?: Maybe<Scalars['Float']['output']>;
  patientShareAmount?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  rejectionReason?: Maybe<Scalars['String']['output']>;
  tradeCode?: Maybe<Scalars['String']['output']>;
  tradeDrug?: Maybe<TradeDrug>;
  type?: Maybe<ActivityType>;
  variantCode?: Maybe<Scalars['String']['output']>;
  variantName?: Maybe<Scalars['String']['output']>;
};

export enum OrderPaymentMethod {
  CreditWhenArrive = 'CREDIT_WHEN_ARRIVE',
  PayNow = 'PAY_NOW'
}

export enum OrderSortField {
  CreationDate = 'CREATION_DATE',
  Customer = 'CUSTOMER',
  FulfillmentStatus = 'FULFILLMENT_STATUS',
  ModifiedDate = 'MODIFIED_DATE',
  Number = 'NUMBER',
  StatusAndCreated = 'STATUS_AND_CREATED',
  Total = 'TOTAL'
}

export type OrderSortingInput = {
  direction: OrderDirection;
  field: OrderSortField;
};

export enum OrderSourcesEnum {
  Chat = 'CHAT',
  Checkout = 'CHECKOUT',
  PrescriptionDispense = 'PRESCRIPTION_DISPENSE',
  PrescriptionRefill = 'PRESCRIPTION_REFILL'
}

/**
 * enum OrderStatus{
 *     NEW,
 *     DELIVERY_ORDER_CREATED,
 *     PENDING,
 *     PICKED_BY_CONSUMER,
 *     RETURNED,
 *     CARRIER_ALLOCATED,
 *     DRIVER_ACCEPTED_ORDER,
 *     DRIVER_PICKED_UP_ORDER,
 *     DRIVER_IS_ABOUT_TO_ARRIVE,
 *     ORDER_IS_DELIVERED,
 *     ORDER_IS_CANCELLED,
 *     ORDER_IS_SUSPENDED,
 *     ORDER_IS_RESUMED,
 *     DELIVERY_TIMED_OUT,
 *     PATIENT_CONFIRMED,
 *     FAILED
 * }
 */
export enum OrderStatus {
  AcceptedByProvider = 'ACCEPTED_BY_PROVIDER',
  Allocated = 'ALLOCATED',
  CanceledByConsumer = 'CANCELED_BY_CONSUMER',
  CanceledByProvider = 'CANCELED_BY_PROVIDER',
  Delivered = 'DELIVERED',
  Dispensed = 'DISPENSED',
  ErxHubAuthRequestFailed = 'ERX_HUB_AUTH_REQUEST_FAILED',
  ErxHubAuthSubmitTimedOut = 'ERX_HUB_AUTH_SUBMIT_TIMED_OUT',
  ErxHubClaimApproved = 'ERX_HUB_CLAIM_APPROVED',
  ErxHubClaimFailed = 'ERX_HUB_CLAIM_FAILED',
  ErxHubClaimRejected = 'ERX_HUB_CLAIM_REJECTED',
  ErxHubClaimSubmitTimedOut = 'ERX_HUB_CLAIM_SUBMIT_TIMED_OUT',
  ErxHubFullyApproved = 'ERX_HUB_FULLY_APPROVED',
  ErxHubPartiallyApproved = 'ERX_HUB_PARTIALLY_APPROVED',
  ErxHubRejected = 'ERX_HUB_REJECTED',
  ErxHubTimedOut = 'ERX_HUB_TIMED_OUT',
  Failed = 'FAILED',
  NewRequest = 'NEW_REQUEST',
  OutForDelivery = 'OUT_FOR_DELIVERY',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentOnArrive = 'PAYMENT_ON_ARRIVE',
  PaymentPending = 'PAYMENT_PENDING',
  PaymentSucceeded = 'PAYMENT_SUCCEEDED',
  PaymentTimedOut = 'PAYMENT_TIMED_OUT',
  ReadyForCustomerPickup = 'READY_FOR_CUSTOMER_PICKUP',
  RejectedByProvider = 'REJECTED_BY_PROVIDER',
  Returned = 'RETURNED',
  WaitingErxHubApproval = 'WAITING_ERX_HUB_APPROVAL',
  WaitingErxHubClaimApproval = 'WAITING_ERX_HUB_CLAIM_APPROVAL',
  WaitingProviderSelection = 'WAITING_PROVIDER_SELECTION'
}

export enum OrderStatusEnum {
  AcceptedByProvider = 'ACCEPTED_BY_PROVIDER',
  Allocated = 'ALLOCATED',
  CanceledByConsumer = 'CANCELED_BY_CONSUMER',
  CanceledByProvider = 'CANCELED_BY_PROVIDER',
  Consumed = 'CONSUMED',
  ConsumedTimedOut = 'CONSUMED_TIMED_OUT',
  Delivered = 'DELIVERED',
  DeliveredTimedOut = 'DELIVERED_TIMED_OUT',
  Dispensed = 'DISPENSED',
  Draft = 'DRAFT',
  ErxHubAuthRequestFailed = 'ERX_HUB_AUTH_REQUEST_FAILED',
  ErxHubAuthSubmitTimedOut = 'ERX_HUB_AUTH_SUBMIT_TIMED_OUT',
  ErxHubClaimApproved = 'ERX_HUB_CLAIM_APPROVED',
  ErxHubClaimFailed = 'ERX_HUB_CLAIM_FAILED',
  ErxHubClaimRejected = 'ERX_HUB_CLAIM_REJECTED',
  ErxHubClaimSubmitTimedOut = 'ERX_HUB_CLAIM_SUBMIT_TIMED_OUT',
  ErxHubFullyApproved = 'ERX_HUB_FULLY_APPROVED',
  ErxHubPartiallyApproved = 'ERX_HUB_PARTIALLY_APPROVED',
  ErxHubRejected = 'ERX_HUB_REJECTED',
  ErxHubTimedOut = 'ERX_HUB_TIMED_OUT',
  Failed = 'FAILED',
  Fulfilled = 'FULFILLED',
  InProgress = 'IN_PROGRESS',
  NewRequest = 'NEW_REQUEST',
  OutForDelivery = 'OUT_FOR_DELIVERY',
  OutForDeliveryTimedOut = 'OUT_FOR_DELIVERY_TIMED_OUT',
  PartiallyFulfilled = 'PARTIALLY_FULFILLED',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentOnArrive = 'PAYMENT_ON_ARRIVE',
  PaymentPending = 'PAYMENT_PENDING',
  PaymentSucceeded = 'PAYMENT_SUCCEEDED',
  PaymentTimedOut = 'PAYMENT_TIMED_OUT',
  ReadyForCustomerPickup = 'READY_FOR_CUSTOMER_PICKUP',
  RejectedByProvider = 'REJECTED_BY_PROVIDER',
  Returned = 'RETURNED',
  ReturnedTimedOut = 'RETURNED_TIMED_OUT',
  ReturnInProgress = 'RETURN_IN_PROGRESS',
  Unfulfilled = 'UNFULFILLED',
  VendorResponseTimedOut = 'VENDOR_RESPONSE_TIMED_OUT',
  WaitingErxHubApproval = 'WAITING_ERX_HUB_APPROVAL',
  WaitingErxHubClaimApproval = 'WAITING_ERX_HUB_CLAIM_APPROVAL',
  WaitingProviderSelection = 'WAITING_PROVIDER_SELECTION'
}

export type OrderStatusUpdateInput = {
  failReason?: InputMaybe<Scalars['String']['input']>;
  grossAmount?: InputMaybe<Scalars['Double']['input']>;
  medicationsStatuses?: InputMaybe<Array<InputMaybe<MedicationAuthorizationStatusInput>>>;
  netAmount?: InputMaybe<Scalars['Double']['input']>;
  status?: InputMaybe<OrderStatus>;
};

export type OrderStatusUpdateResult = {
  __typename?: 'OrderStatusUpdateResult';
  order?: Maybe<Order>;
  orderErrors?: Maybe<Array<H_EntityError>>;
};

export enum OrderType {
  Delivery = 'DELIVERY',
  Pickup = 'PICKUP'
}

export enum OrderTypesEnum {
  HomeDelivery = 'HOME_DELIVERY',
  PickupFromPharmacy = 'PICKUP_FROM_PHARMACY'
}

export type OtpjAssessment = H_Node & {
  __typename?: 'OtpjAssessment';
  branch?: Maybe<Branch>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  department?: Maybe<Department>;
  details?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['Instant']['output']>;
  patient?: Maybe<Patient>;
  skipped?: Maybe<Scalars['Boolean']['output']>;
  vendor?: Maybe<Vendor>;
};

export type OtpjAssessmentInput = {
  appointmentId?: InputMaybe<Scalars['ID']['input']>;
  departmentId: Scalars['ID']['input'];
  details?: InputMaybe<Scalars['String']['input']>;
  outPatientJourneyId?: InputMaybe<Scalars['ID']['input']>;
  patientId: Scalars['ID']['input'];
  skipped?: InputMaybe<Scalars['Boolean']['input']>;
  visitId?: InputMaybe<Scalars['ID']['input']>;
};

export type OtpjCheckIn = H_Node & {
  __typename?: 'OtpjCheckIn';
  branch?: Maybe<Branch>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  department?: Maybe<Department>;
  details?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['Instant']['output']>;
  patient?: Maybe<Patient>;
  patientCopayment?: Maybe<Scalars['String']['output']>;
  patientInsurance?: Maybe<Scalars['String']['output']>;
  vendor?: Maybe<Vendor>;
};

export type OtpjCheckInInput = {
  appointmentId?: InputMaybe<Scalars['ID']['input']>;
  departmentId: Scalars['ID']['input'];
  details?: InputMaybe<Scalars['String']['input']>;
  outPatientJourneyId?: InputMaybe<Scalars['ID']['input']>;
  patientCopayment?: InputMaybe<Scalars['String']['input']>;
  patientInsurance?: InputMaybe<Scalars['String']['input']>;
  visitId?: InputMaybe<Scalars['ID']['input']>;
};

export type OtpjConsultation = H_Node & {
  __typename?: 'OtpjConsultation';
  branch?: Maybe<Branch>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  department?: Maybe<Department>;
  details?: Maybe<Scalars['String']['output']>;
  doctor?: Maybe<Doctor>;
  id: Scalars['ID']['output'];
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['Instant']['output']>;
  patient?: Maybe<Patient>;
  patientCopayment?: Maybe<Scalars['String']['output']>;
  patientInsurance?: Maybe<Scalars['String']['output']>;
  vendor?: Maybe<Vendor>;
  vendorUser?: Maybe<User>;
};

export type OtpjConsultationInput = {
  appointmentId?: InputMaybe<Scalars['ID']['input']>;
  departmentId: Scalars['ID']['input'];
  details?: InputMaybe<Scalars['String']['input']>;
  doctorId?: InputMaybe<Scalars['ID']['input']>;
  outPatientJourneyId?: InputMaybe<Scalars['ID']['input']>;
  patientId: Scalars['ID']['input'];
  visitId?: InputMaybe<Scalars['ID']['input']>;
};

export type OtpjExtPayment = H_Node & {
  __typename?: 'OtpjExtPayment';
  branch?: Maybe<Branch>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  department?: Maybe<Department>;
  details?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  invoiceReferenceNumber?: Maybe<Scalars['String']['output']>;
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['Instant']['output']>;
  patient?: Maybe<Patient>;
  vendor?: Maybe<Vendor>;
  vendorUser?: Maybe<User>;
};

export type OtpjExtPaymentInput = {
  appointmentId?: InputMaybe<Scalars['ID']['input']>;
  departmentId: Scalars['ID']['input'];
  details?: InputMaybe<Scalars['String']['input']>;
  invoiceReferenceNumber?: InputMaybe<Scalars['String']['input']>;
  outPatientJourneyId?: InputMaybe<Scalars['ID']['input']>;
  patientId: Scalars['ID']['input'];
  visitId?: InputMaybe<Scalars['ID']['input']>;
};

export type OutPatientJourney = H_Node & {
  __typename?: 'OutPatientJourney';
  branch?: Maybe<Branch>;
  cancellationReason?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  department?: Maybe<Department>;
  id: Scalars['ID']['output'];
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['Instant']['output']>;
  otpjAssessment?: Maybe<OtpjAssessment>;
  otpjCheckIn?: Maybe<OtpjCheckIn>;
  otpjConsultation?: Maybe<OtpjConsultation>;
  otpjExtPayment?: Maybe<OtpjExtPayment>;
  paymentOperationLogs: PaymentOperationLogCountableConnection;
  paymentReceipts: PaymentReceiptCountableConnection;
  priceAmount?: Maybe<Scalars['Float']['output']>;
  priceAmountAfterVat?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<OutPatientJourneyStatus>;
  vatPercentage?: Maybe<Scalars['Float']['output']>;
  vendor?: Maybe<Vendor>;
  visit?: Maybe<Visit>;
};


export type OutPatientJourneyPaymentOperationLogsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PaymentOperationLogFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type OutPatientJourneyPaymentReceiptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PaymentReceiptFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type OutPatientJourneyCountableConnection = {
  __typename?: 'OutPatientJourneyCountableConnection';
  edges: Array<OutPatientJourneyCountableEdge>;
  pageInfo: H_PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type OutPatientJourneyCountableEdge = {
  __typename?: 'OutPatientJourneyCountableEdge';
  cursor: Scalars['String']['output'];
  node: OutPatientJourney;
};

export enum OutPatientJourneyStatus {
  AssessmentDone = 'ASSESSMENT_DONE',
  AssessmentPending = 'ASSESSMENT_PENDING',
  Canceled = 'CANCELED',
  CanceledByConsumer = 'CANCELED_BY_CONSUMER',
  CheckedIn = 'CHECKED_IN',
  Completed = 'COMPLETED',
  ConsultationDone = 'CONSULTATION_DONE',
  ConsultationPending = 'CONSULTATION_PENDING',
  Incompleted = 'INCOMPLETED',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentPending = 'PAYMENT_PENDING',
  PaymentSucceeded = 'PAYMENT_SUCCEEDED',
  Pending = 'PENDING',
  TreatmentDone = 'TREATMENT_DONE'
}

export type OutPatientJourneyStatusHistory = H_Node & {
  __typename?: 'OutPatientJourneyStatusHistory';
  branchId?: Maybe<Scalars['ID']['output']>;
  cancellationReason?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  departmentId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['Instant']['output']>;
  refId?: Maybe<Scalars['ID']['output']>;
  status?: Maybe<OutPatientJourneyStatus>;
  vendorId?: Maybe<Scalars['ID']['output']>;
};

export type OutPatientJourneyStatusHistoryCountableConnection = {
  __typename?: 'OutPatientJourneyStatusHistoryCountableConnection';
  edges: Array<OutPatientJourneyStatusHistoryCountableEdge>;
  pageInfo: H_PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type OutPatientJourneyStatusHistoryCountableEdge = {
  __typename?: 'OutPatientJourneyStatusHistoryCountableEdge';
  cursor: Scalars['String']['output'];
  node: OutPatientJourneyStatusHistory;
};

export type OutPatientJourneyStatusHistoryFilterInput = {
  branches?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  departments?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  refIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  selfOnly?: InputMaybe<Scalars['Boolean']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<OutPatientJourneyStatus>>>;
  vendors?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type OutPatientJourneyStatusUpdateInput = {
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  status: OutPatientJourneyStatus;
};

export type OverlappedAppointment = H_Node & {
  __typename?: 'OverlappedAppointment';
  careInitialType?: Maybe<CareType>;
  confirmedEndTime?: Maybe<Scalars['Instant']['output']>;
  confirmedStartTime?: Maybe<Scalars['Instant']['output']>;
  id: Scalars['ID']['output'];
  requestedBy?: Maybe<RequestedBy>;
  status?: Maybe<AppointmentStatus>;
  suggestedTimeSlotsOverlapped?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  type?: Maybe<AppointmentType>;
};

export type OverlappedAppointmentCountableConnection = {
  __typename?: 'OverlappedAppointmentCountableConnection';
  edges: Array<OverlappedAppointmentCountableEdge>;
  pageInfo: H_PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type OverlappedAppointmentCountableEdge = {
  __typename?: 'OverlappedAppointmentCountableEdge';
  cursor: Scalars['String']['output'];
  node: OverlappedAppointment;
};

export type P_SubscriptionEntityChanged = H_Node & {
  __typename?: 'P_SubscriptionEntityChanged';
  changes?: Maybe<Array<Maybe<P_SubscriptionEntityFieldChanges>>>;
  creationDate?: Maybe<Scalars['Instant']['output']>;
  graphqlType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  users?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

export type P_SubscriptionEntityFieldChanges = {
  __typename?: 'P_SubscriptionEntityFieldChanges';
  field?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type P_SubscriptionKafkaTopic = {
  __typename?: 'P_SubscriptionKafkaTopic';
  graphqlType?: Maybe<Scalars['String']['output']>;
  topic?: Maybe<Scalars['String']['output']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PasswordChange = {
  __typename?: 'PasswordChange';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export enum PasswordResetMethodEnum {
  Email = 'EMAIL',
  Mobile = 'MOBILE'
}

export type Patient = Node & {
  __typename?: 'Patient';
  activeAppointmentsInPerson?: Maybe<Array<Maybe<Appointment>>>;
  activeHealthProgramMembers?: Maybe<Array<Maybe<HealthProgramMember>>>;
  activeVisitsInPerson?: Maybe<Array<Maybe<Visit>>>;
  age?: Maybe<Scalars['Int']['output']>;
  anxietyScreeningResults?: Maybe<AnxietyScreeningResultConnection>;
  /** needed to send the values of (doctorId, start, end) or the value of appointmentId */
  availableTimeSlots?: Maybe<Array<Maybe<TimeSlot>>>;
  beeAndTdeeResults?: Maybe<BeeAndTdeeResultConnection>;
  bloodGroup?: Maybe<Scalars['String']['output']>;
  bodyComposition?: Maybe<HealthProgramMemberBodyCompositionBasic>;
  bodyCompositions?: Maybe<HealthProgramMemberBodyCompositionCountableConnection>;
  bodyFatPercentageResults?: Maybe<BodyFatPercentageResultConnection>;
  chronicDiseases?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  contactNumber?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  depressionScreeningResults?: Maybe<DepressionScreeningResultConnection>;
  diabetesRiskResults?: Maybe<DiabetesRiskResultConnection>;
  disabilities?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  documentExpiryDate?: Maybe<Scalars['Date']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  framinghamRiskResults?: Maybe<FraminghamRiskResultConnection>;
  gender?: Maybe<Scalars['String']['output']>;
  goalWeightResults?: Maybe<GoalWeightResultConnection>;
  guidedCareTeamChatGroups?: Maybe<Array<AnonymousChatGroup>>;
  healthProgramMembershipRequests: HealthProgramMembershipRequestConnection;
  healthProgramsByActiveMembers?: Maybe<Array<Maybe<HealthProgram>>>;
  healthProgramsByActiveUserSubscriptions?: Maybe<Array<Maybe<HealthProgram>>>;
  healthProgramsOfPayPerCallOnly?: Maybe<HealthProgramCountableConnection>;
  id: Scalars['ID']['output'];
  idType?: Maybe<PatientIdType>;
  idealBodyWeightResults?: Maybe<IdealBodyWeightResultConnection>;
  inActiveHealthProgramMembers?: Maybe<HealthProgramMemberCountableConnection>;
  lastName?: Maybe<Scalars['String']['output']>;
  lifeExpectancyResults?: Maybe<LifeExpectancyResultConnection>;
  maritalStatus?: Maybe<PatientMaritalStatus>;
  medicalFormAnswers?: Maybe<MedicalFormAnswerConnection>;
  modified: Scalars['DateTime']['output'];
  nationalIdNumber?: Maybe<Scalars['String']['output']>;
  nationality?: Maybe<Scalars['String']['output']>;
  nationality3166Code?: Maybe<Scalars['String']['output']>;
  nationalityArabicDisplay?: Maybe<Scalars['String']['output']>;
  nationalityDisplay?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  patientLabs?: Maybe<PatientLabsCountableConnection>;
  paymentOperationLogs: PaymentOperationLogCountableConnection;
  paymentReceipts: PaymentReceiptCountableConnection;
  preferredCommunicationLanguage?: Maybe<Language>;
  preferredCommunicationMethod?: Maybe<PatientPreferredCommunicationMethod>;
  providerGuidedCareHealthProgramTeamChatGroups?: Maybe<AnonymousChatGroupCountableConnection>;
  questionAnswers?: Maybe<QuestionAnswerConnection>;
  secondName?: Maybe<Scalars['String']['output']>;
  sleepResults?: Maybe<SleepResultConnection>;
  thirdName?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  validationSkipped: Scalars['Boolean']['output'];
  wallet?: Maybe<Wallet>;
};


export type PatientActiveAppointmentsInPersonArgs = {
  departmentId?: InputMaybe<Scalars['ID']['input']>;
  outPatientJourneySupportedForProvidedDepartmentId?: InputMaybe<Scalars['Boolean']['input']>;
};


export type PatientActiveVisitsInPersonArgs = {
  departmentId?: InputMaybe<Scalars['ID']['input']>;
};


export type PatientAnxietyScreeningResultsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<DefaultSortingInput>;
};


export type PatientAvailableTimeSlotsArgs = {
  doctorId?: InputMaybe<Scalars['ID']['input']>;
  end?: InputMaybe<Scalars['Instant']['input']>;
  start?: InputMaybe<Scalars['Instant']['input']>;
};


export type PatientBeeAndTdeeResultsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<DefaultSortingInput>;
};


export type PatientBodyCompositionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<HealthProgramMemberBodyCompositionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientBodyFatPercentageResultsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<DefaultSortingInput>;
};


export type PatientDepressionScreeningResultsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<DefaultSortingInput>;
};


export type PatientDiabetesRiskResultsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<DefaultSortingInput>;
};


export type PatientFraminghamRiskResultsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<DefaultSortingInput>;
};


export type PatientGoalWeightResultsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<DefaultSortingInput>;
};


export type PatientGuidedCareTeamChatGroupsArgs = {
  filter?: InputMaybe<AnonymousChatGroupFilterInput>;
};


export type PatientHealthProgramMembershipRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<HealthProgramMembershipRequestFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<HealthProgramMembershipRequestSortingInput>;
};


export type PatientHealthProgramsByActiveMembersArgs = {
  filter?: InputMaybe<HealthProgramFilterInput>;
  sortBy?: InputMaybe<HealthProgramSortingInput>;
};


export type PatientHealthProgramsByActiveUserSubscriptionsArgs = {
  filter?: InputMaybe<HealthProgramFilterInput>;
  sortBy?: InputMaybe<HealthProgramSortingInput>;
};


export type PatientHealthProgramsOfPayPerCallOnlyArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<HealthProgramFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<HealthProgramSortingInput>;
};


export type PatientIdealBodyWeightResultsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<DefaultSortingInput>;
};


export type PatientInActiveHealthProgramMembersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientLifeExpectancyResultsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<DefaultSortingInput>;
};


export type PatientMedicalFormAnswersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MedicalFormAnswerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientPatientLabsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientPaymentOperationLogsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PaymentOperationLogFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PaymentOperationLogSortingInput>;
};


export type PatientPaymentReceiptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PaymentReceiptFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientProviderGuidedCareHealthProgramTeamChatGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  chatGroupFilter?: InputMaybe<AnonymousChatGroupFilterInput>;
  filter?: InputMaybe<ProviderGuidedCareHealthProgramTeamFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientQuestionAnswersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<QuestionAnswerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PatientSleepResultsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<DefaultSortingInput>;
};

export type PatientChronicDisease = H_Node & {
  __typename?: 'PatientChronicDisease';
  code: Scalars['String']['output'];
  createdDate?: Maybe<Scalars['Instant']['output']>;
  display?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type PatientChronicDiseaseInput = {
  code: Scalars['String']['input'];
  patientId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type PatientChronicMedication = H_Node & {
  __typename?: 'PatientChronicMedication';
  code: Scalars['String']['output'];
  createdDate?: Maybe<Scalars['Instant']['output']>;
  display?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type PatientChronicMedicationInput = {
  code: Scalars['String']['input'];
  patientId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type PatientCountableConnection = {
  __typename?: 'PatientCountableConnection';
  edges: Array<PatientCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type PatientCountableEdge = {
  __typename?: 'PatientCountableEdge';
  cursor: Scalars['String']['output'];
  node: Patient;
};

export type PatientCreate = {
  __typename?: 'PatientCreate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  patient?: Maybe<Patient>;
  patientErrors: Array<PatientError>;
};

export type PatientCurrentMedicalStatus = H_Node & {
  __typename?: 'PatientCurrentMedicalStatus';
  id: Scalars['ID']['output'];
  patientChronicDiseases?: Maybe<Array<Maybe<PatientChronicDisease>>>;
  patientChronicMedications?: Maybe<Array<Maybe<PatientChronicMedication>>>;
  patientDisabilities?: Maybe<Array<Maybe<PatientDisability>>>;
  patientNonChronicDiseases?: Maybe<Array<Maybe<PatientNonChronicDisease>>>;
};

export type PatientDisability = H_Node & {
  __typename?: 'PatientDisability';
  code: Scalars['String']['output'];
  createdDate?: Maybe<Scalars['Instant']['output']>;
  display?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type PatientDisabilityInput = {
  code: Scalars['String']['input'];
  patientId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type PatientEligibilityBenefit = {
  __typename?: 'PatientEligibilityBenefit';
  authorizationInfo?: Maybe<Array<Maybe<TypePatientEligibilityAuthorizationInfo>>>;
  authorizationRequired?: Maybe<Scalars['Boolean']['output']>;
  benefitType?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  coverage?: Maybe<Array<Maybe<PatientEligibilityCoverage>>>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  network?: Maybe<Scalars['String']['output']>;
  productOrService?: Maybe<PatientEligibilityProductOrService>;
  term?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  waitingPeriod?: Maybe<Scalars['Int']['output']>;
};

export type PatientEligibilityCoverage = {
  __typename?: 'PatientEligibilityCoverage';
  allowedMoney?: Maybe<Scalars['Float']['output']>;
  allowedUnits?: Maybe<Scalars['Int']['output']>;
  copayAmount?: Maybe<Scalars['Float']['output']>;
  copayMax?: Maybe<Scalars['Float']['output']>;
  copayPercent?: Maybe<Scalars['Float']['output']>;
  deductibleAmount?: Maybe<Scalars['Float']['output']>;
  deductibleMax?: Maybe<Scalars['Float']['output']>;
  deductiblePercent?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  roomType?: Maybe<Scalars['String']['output']>;
  usedMoney?: Maybe<Scalars['Float']['output']>;
  usedUnits?: Maybe<Scalars['Int']['output']>;
};

export type PatientEligibilityExcludedBenefit = {
  __typename?: 'PatientEligibilityExcludedBenefit';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type PatientEligibilityInput = {
  active: Scalars['Boolean']['input'];
  benefits?: InputMaybe<Array<InputMaybe<BenefitInput>>>;
  dateOfBirth?: InputMaybe<Scalars['Instant']['input']>;
  effectiveFrom?: InputMaybe<Scalars['Instant']['input']>;
  effectiveTo?: InputMaybe<Scalars['Instant']['input']>;
  emiratesId: Scalars['String']['input'];
  enrollmentDate?: InputMaybe<Scalars['Instant']['input']>;
  excludedBenefits?: InputMaybe<Array<InputMaybe<EntityInput>>>;
  gender?: InputMaybe<Scalars['String']['input']>;
  insuranceName: Scalars['String']['input'];
  kind?: InputMaybe<Scalars['String']['input']>;
  memberName?: InputMaybe<Scalars['String']['input']>;
  memberNo?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  mobileCountryCode?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  payer?: InputMaybe<EntityInput>;
  plan?: InputMaybe<EntityInput>;
  policyCode?: InputMaybe<Scalars['String']['input']>;
  receiver?: InputMaybe<EntityInput>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type PatientEligibilityPayer = {
  __typename?: 'PatientEligibilityPayer';
  code?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type PatientEligibilityPolicy = {
  __typename?: 'PatientEligibilityPolicy';
  code?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  insurance?: Maybe<Scalars['String']['output']>;
};

export type PatientEligibilityProductOrService = {
  __typename?: 'PatientEligibilityProductOrService';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type PatientEligibilityReceiver = {
  __typename?: 'PatientEligibilityReceiver';
  code?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type PatientEligibilityRequestHistory = {
  __typename?: 'PatientEligibilityRequestHistory';
  createdDate?: Maybe<Scalars['Instant']['output']>;
  eligiblePayerRequestHistory?: Maybe<PayerEligibilityRequestHistory>;
  id: Scalars['ID']['output'];
  isCompleted: Scalars['Boolean']['output'];
  isFounded: Scalars['Boolean']['output'];
  isRegistered?: Maybe<Scalars['Boolean']['output']>;
  patientEligibility: OptimaPatientEligibility;
  payerEligibilityRequestHistories?: Maybe<Array<Maybe<PayerEligibilityRequestHistory>>>;
  requestedByUserId: Scalars['Int']['output'];
  requestedByVendorId: Scalars['Int']['output'];
  type?: Maybe<BookingTypeEnum>;
  updatedDate?: Maybe<Scalars['Instant']['output']>;
};

export type PatientEligibilityRequestHistoryCountableConnection = {
  __typename?: 'PatientEligibilityRequestHistoryCountableConnection';
  edges: Array<PatientEligibilityRequestHistoryCountableEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type PatientEligibilityRequestHistoryCountableEdge = {
  __typename?: 'PatientEligibilityRequestHistoryCountableEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: PatientEligibilityRequestHistory;
};

export type PatientEligibilityRequestHistoryFilterInput = {
  fromDate?: InputMaybe<Scalars['Instant']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  insuranceName?: InputMaybe<Scalars['String']['input']>;
  isCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  isFounded?: InputMaybe<Scalars['Boolean']['input']>;
  patientEligibilityId?: InputMaybe<Scalars['ID']['input']>;
  patientIdentifier?: InputMaybe<Scalars['String']['input']>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['Instant']['input']>;
};

export type PatientEligibilityRequestHistorySortingInput = {
  direction: OptimaDirection;
  field: PatientEligibilityRequestHistorySortingInputSortField;
};

export enum PatientEligibilityRequestHistorySortingInputSortField {
  CreatedAt = 'CREATED_AT',
  UpdatedAt = 'UPDATED_AT'
}

export type PatientEligibleTeamFilterInput = {
  doctorIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  guidedCareHealthProgramId?: InputMaybe<Scalars['ID']['input']>;
  guidedCareType?: InputMaybe<GuidedCareType>;
  healthProgramMembers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isPatientEnrolled?: InputMaybe<Scalars['Boolean']['input']>;
  isProgramLatestVersion?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  payerId?: InputMaybe<Scalars['ID']['input']>;
  providerId?: InputMaybe<Scalars['ID']['input']>;
};

export type PatientEnrollmentRequest = H_Node & {
  __typename?: 'PatientEnrollmentRequest';
  error?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  patient?: Maybe<Patient>;
  providerGuidedCareHealthProgramTeamErrors?: Maybe<Array<H_EntityError>>;
  status?: Maybe<PatientEnrollmentRequestStatus>;
  team?: Maybe<ProviderGuidedCareHealthProgramTeam>;
  user?: Maybe<User>;
};

export type PatientEnrollmentRequestCountableConnection = {
  __typename?: 'PatientEnrollmentRequestCountableConnection';
  edges: Array<PatientEnrollmentRequestCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type PatientEnrollmentRequestCountableEdge = {
  __typename?: 'PatientEnrollmentRequestCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: PatientEnrollmentRequest;
};

export type PatientEnrollmentRequestFilterInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<PatientEnrollmentRequestStatus>;
  teamId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export enum PatientEnrollmentRequestStatus {
  Failed = 'FAILED',
  FinishedSuccessfully = 'FINISHED_SUCCESSFULLY',
  InProgress = 'IN_PROGRESS'
}

export type PatientError = {
  __typename?: 'PatientError';
  code: PatientErrorCode;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum PatientErrorCode {
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type PatientEvaluatedHealthCondition = {
  __typename?: 'PatientEvaluatedHealthCondition';
  chronicDiseaseCode?: Maybe<Scalars['String']['output']>;
  evaluatedHealthCondition?: Maybe<HealthCondition>;
};

export type PatientFilterInput = {
  bloodGroup?: InputMaybe<Scalars['String']['input']>;
  bloodGroup_Icontains?: InputMaybe<Scalars['String']['input']>;
  contactNumber?: InputMaybe<Scalars['String']['input']>;
  contactNumber_Icontains?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_Icontains?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  firstName_Icontains?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<PersonGenderEnum>;
  id?: InputMaybe<Scalars['ID']['input']>;
  idType?: InputMaybe<Array<InputMaybe<PatientIdTypeEnum>>>;
  id_Icontains?: InputMaybe<Scalars['ID']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastName_Icontains?: InputMaybe<Scalars['String']['input']>;
  maritalStatus?: InputMaybe<Array<InputMaybe<MaritalStatusesEnum>>>;
  nationalIdNumber?: InputMaybe<Scalars['String']['input']>;
  nationalIdNumber_Icontains?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  nationality_Icontains?: InputMaybe<Scalars['String']['input']>;
  preferredLanguage?: InputMaybe<Scalars['ID']['input']>;
  residencyType?: InputMaybe<Array<InputMaybe<ResidencyTypesEnum>>>;
  secondName?: InputMaybe<Scalars['String']['input']>;
  secondName_Icontains?: InputMaybe<Scalars['String']['input']>;
  thirdName?: InputMaybe<Scalars['String']['input']>;
  thirdName_Icontains?: InputMaybe<Scalars['String']['input']>;
};

export type PatientHpVitalSignsChangePercentage = {
  __typename?: 'PatientHPVitalSignsChangePercentage';
  changePercentage?: Maybe<Scalars['Double']['output']>;
  hpCode?: Maybe<Scalars['String']['output']>;
  hpId?: Maybe<Scalars['ID']['output']>;
};

export type PatientHealthDocument = {
  __typename?: 'PatientHealthDocument';
  description?: Maybe<Scalars['String']['output']>;
  fileLink: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  resultDate: Scalars['Instant']['output'];
  type: HealthDocumentType;
};

export type PatientHealthDocumentCountableConnection = {
  __typename?: 'PatientHealthDocumentCountableConnection';
  edges: Array<PatientHealthDocumentCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type PatientHealthDocumentCountableEdge = {
  __typename?: 'PatientHealthDocumentCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  node: PatientHealthDocument;
};

export type PatientHealthDocumentInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  fileLink: Scalars['String']['input'];
  name: Scalars['String']['input'];
  resultDate: Scalars['Instant']['input'];
  type: HealthDocumentType;
};

export type PatientHealthHistory = H_Node & {
  __typename?: 'PatientHealthHistory';
  affectedDate?: Maybe<Scalars['Instant']['output']>;
  code: Scalars['String']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByUserType?: Maybe<ProfileType>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  display?: Maybe<Scalars['String']['output']>;
  healthHistoryType: HealthHistoryType;
  id: Scalars['ID']['output'];
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['Instant']['output']>;
  visitDiagnosis?: Maybe<VisitDiagnosis>;
};

export type PatientHealthHistoryCrud = {
  __typename?: 'PatientHealthHistoryCRUD';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  patientHealthHistory?: Maybe<PatientHealthHistory>;
  patientHealthHistoryErrors?: Maybe<Array<H_EntityError>>;
};

export type PatientHealthHistoryConnection = {
  __typename?: 'PatientHealthHistoryConnection';
  edges: Array<PatientHealthHistoryCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type PatientHealthHistoryCountableEdge = {
  __typename?: 'PatientHealthHistoryCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  node: PatientHealthHistory;
};

export type PatientHealthHistoryInput = {
  affectedDate: Scalars['Instant']['input'];
  code: Scalars['String']['input'];
  display: Scalars['String']['input'];
  healthHistoryType: HealthHistoryType;
  patientId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export type PatientHealthParameter = {
  __typename?: 'PatientHealthParameter';
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  deviceName?: Maybe<Scalars['String']['output']>;
  deviceSerial?: Maybe<Scalars['String']['output']>;
  healthParameter?: Maybe<HealthParameter>;
  hpCode?: Maybe<Scalars['String']['output']>;
  hpId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  isLab?: Maybe<Scalars['Boolean']['output']>;
  isRiskStratification?: Maybe<Scalars['Boolean']['output']>;
  isVitalSigns?: Maybe<Scalars['Boolean']['output']>;
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['Instant']['output']>;
  patientId?: Maybe<Scalars['ID']['output']>;
  practitionerUserId?: Maybe<Scalars['ID']['output']>;
  source?: Maybe<Source>;
  valueAudioFile?: Maybe<Scalars['String']['output']>;
  valueBoolean?: Maybe<Scalars['Boolean']['output']>;
  valueDate?: Maybe<Scalars['Instant']['output']>;
  valueNumber?: Maybe<Scalars['Double']['output']>;
  valueNumericList?: Maybe<Array<Maybe<Scalars['Double']['output']>>>;
  valueString?: Maybe<Scalars['String']['output']>;
  valueVideoFile?: Maybe<Scalars['String']['output']>;
};

/** CRUD a new Patient Health Parameter. */
export type PatientHealthParameterCrud = {
  __typename?: 'PatientHealthParameterCRUD';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  patientHealthParameter?: Maybe<PatientHealthParameter>;
  patientHealthParameterErrors?: Maybe<Array<H_EntityError>>;
};

export type PatientHealthParameterCountableConnection = {
  __typename?: 'PatientHealthParameterCountableConnection';
  edges: Array<PatientHealthParameterCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type PatientHealthParameterCountableEdge = {
  __typename?: 'PatientHealthParameterCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  node: PatientHealthParameter;
};

export type PatientHealthParameterFilterInput = {
  codes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isVitalSigns?: InputMaybe<Scalars['Boolean']['input']>;
  patientId?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  requestedDateRange?: InputMaybe<H_DateTimeRangeInput>;
  visitId?: InputMaybe<Scalars['ID']['input']>;
};

export type PatientHealthParameterInput = {
  deviceName?: InputMaybe<Scalars['String']['input']>;
  deviceSerial?: InputMaybe<Scalars['String']['input']>;
  hpCode: Scalars['String']['input'];
  valueAudioFile?: InputMaybe<Scalars['String']['input']>;
  valueBoolean?: InputMaybe<Scalars['Boolean']['input']>;
  valueNumber?: InputMaybe<Scalars['Double']['input']>;
  valueNumericList?: InputMaybe<Array<InputMaybe<Scalars['Double']['input']>>>;
  valueString?: InputMaybe<Scalars['String']['input']>;
  valueVideoFile?: InputMaybe<Scalars['String']['input']>;
};

export enum PatientHealthParameterSortField {
  /** Sort by health parameter code. */
  Code = 'CODE',
  /** Sort by creation date. */
  CreatedDate = 'CREATED_DATE',
  /** Sort by source. */
  Source = 'SOURCE'
}

export type PatientHealthParameterSortingInput = {
  /** Specifies the direction in which to sort Guided Care Journeys. */
  direction: H_OrderDirection;
  /** Sort journeys by the selected field. */
  field: PatientHealthParameterSortField;
};

export enum PatientIdType {
  NationalCard = 'NATIONAL_CARD',
  Passport = 'PASSPORT',
  ResidentCard = 'RESIDENT_CARD'
}

export enum PatientIdTypeEnum {
  NationalCard = 'NATIONAL_CARD',
  Passport = 'PASSPORT',
  ResidentCard = 'RESIDENT_CARD'
}

export enum PatientIdentifierType {
  MemberId = 'MEMBER_ID',
  NationalId = 'NATIONAL_ID'
}

export type PatientInput = {
  bloodGroup?: InputMaybe<Scalars['String']['input']>;
  contactNumber?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  documentExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<PersonGenderEnum>;
  generateMeetingPlatformId?: InputMaybe<Scalars['Boolean']['input']>;
  idType?: InputMaybe<PatientIdTypeEnum>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  maritalStatus?: InputMaybe<MaritalStatusesEnum>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  nationalIdNumber?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  parentNationalId?: InputMaybe<Scalars['String']['input']>;
  preferredCommunicationLanguage?: InputMaybe<Scalars['String']['input']>;
  preferredCommunicationMethod?: InputMaybe<PreferredCommunicationMethodsEnum>;
  preferredLanguage?: InputMaybe<Scalars['String']['input']>;
  relationType?: InputMaybe<RelationTypes>;
  secondName?: InputMaybe<Scalars['String']['input']>;
  sendWelcomeMessage?: InputMaybe<Scalars['Boolean']['input']>;
  skipValidation?: InputMaybe<Scalars['Boolean']['input']>;
  thirdName?: InputMaybe<Scalars['String']['input']>;
};

export type PatientLabsCountableConnection = {
  __typename?: 'PatientLabsCountableConnection';
  edges: Array<PatientLabsCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type PatientLabsCountableEdge = {
  __typename?: 'PatientLabsCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Lab;
};

export enum PatientMaritalStatus {
  Divorced = 'DIVORCED',
  Married = 'MARRIED',
  Single = 'SINGLE',
  Widowed = 'WIDOWED'
}

export type PatientMedicalProfile = H_Node & {
  __typename?: 'PatientMedicalProfile';
  documentSecretKey?: Maybe<Scalars['String']['output']>;
  healthDocuments?: Maybe<Array<Maybe<PatientHealthDocument>>>;
  healthProgramMember?: Maybe<HealthProgramMember>;
  id: Scalars['ID']['output'];
  patient?: Maybe<Patient>;
  patientCurrentMedicalStatus?: Maybe<PatientCurrentMedicalStatus>;
  patientHealthHistory?: Maybe<Array<Maybe<PatientHealthHistory>>>;
};

export type PatientMedicalProfileCrud = {
  __typename?: 'PatientMedicalProfileCRUD';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  patientMedicalProfile?: Maybe<PatientMedicalProfile>;
  patientMedicalProfileErrors?: Maybe<Array<H_EntityError>>;
};

export type PatientMinimal = H_Node & {
  __typename?: 'PatientMinimal';
  id: Scalars['ID']['output'];
  patientUserId?: Maybe<Scalars['ID']['output']>;
};

export type PatientMinimalCountableConnection = {
  __typename?: 'PatientMinimalCountableConnection';
  edges: Array<PatientMinimalCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type PatientMinimalCountableEdge = {
  __typename?: 'PatientMinimalCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: PatientMinimal;
};

export type PatientNonChronicDisease = H_Node & {
  __typename?: 'PatientNonChronicDisease';
  code: Scalars['String']['output'];
  createdDate?: Maybe<Scalars['Instant']['output']>;
  display?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
};

export type PatientNonChronicDiseaseInput = {
  code: Scalars['String']['input'];
  patientId: Scalars['ID']['input'];
  userId: Scalars['ID']['input'];
};

export enum PatientPreferredCommunicationMethod {
  Email = 'EMAIL',
  Phone = 'PHONE',
  Sms = 'SMS'
}

export type PatientProfileConsentCode = {
  __typename?: 'PatientProfileConsentCode';
  code?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  id: Scalars['ID']['output'];
  patientId?: Maybe<Scalars['ID']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
  validUntil?: Maybe<Scalars['Instant']['output']>;
};

export type PatientProfileConsentForm = H_Node & {
  __typename?: 'PatientProfileConsentForm';
  consentCode?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  id: Scalars['ID']['output'];
  lastModifiedDate?: Maybe<Scalars['Instant']['output']>;
  level?: Maybe<ConsentLevel>;
  parentUser?: Maybe<User>;
  patient?: Maybe<Patient>;
  patientUser?: Maybe<User>;
  requesterUser?: Maybe<User>;
  status?: Maybe<ConsentStatus>;
  type?: Maybe<ConsentType>;
};

export type PatientProfileConsentFormCrud = {
  __typename?: 'PatientProfileConsentFormCRUD';
  entityErrors?: Maybe<Array<H_EntityError>>;
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  patientProfileConsentForm?: Maybe<PatientProfileConsentForm>;
};

export type PatientProfileConsentFormConnection = {
  __typename?: 'PatientProfileConsentFormConnection';
  edges: Array<PatientProfileConsentFormCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type PatientProfileConsentFormCountableEdge = {
  __typename?: 'PatientProfileConsentFormCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  node: PatientProfileConsentForm;
};

export type PatientProfileConsentFormInput = {
  consentCode?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
};

export type PatientReferral = {
  __typename?: 'PatientReferral';
  code?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['Date']['output']>;
  errors?: Maybe<Array<Maybe<ReferralGraphqlError>>>;
  startDate?: Maybe<Scalars['Date']['output']>;
};

export type PatientReferralConnection = {
  __typename?: 'PatientReferralConnection';
  edges?: Maybe<Array<Maybe<ReferralEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type PatientSendOrderOtp = {
  __typename?: 'PatientSendOrderOTP';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  ok?: Maybe<Scalars['Boolean']['output']>;
  patientErrors: Array<PatientError>;
};

export type PatientSendOrderOtpInput = {
  branchId: Scalars['ID']['input'];
  orderId: Scalars['ID']['input'];
  orderOtp: Scalars['String']['input'];
  patientId: Scalars['ID']['input'];
  url: Scalars['String']['input'];
};

export type PatientShare = {
  __typename?: 'PatientShare';
  coverage?: Maybe<Coverage>;
  patientShare?: Maybe<Scalars['Float']['output']>;
};

export type PatientUpdate = {
  __typename?: 'PatientUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  patient?: Maybe<Patient>;
  patientErrors: Array<PatientError>;
};

export type PatientUpdateInput = {
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  documentExpiryDate?: InputMaybe<Scalars['Date']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<PersonGenderEnum>;
  idType?: InputMaybe<PatientIdTypeEnum>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  nationalId?: InputMaybe<Scalars['String']['input']>;
  nationality?: InputMaybe<Scalars['String']['input']>;
  preferredCommunicationLanguage?: InputMaybe<Scalars['String']['input']>;
  preferredCommunicationMethod?: InputMaybe<PreferredCommunicationMethodsEnum>;
  secondName?: InputMaybe<Scalars['String']['input']>;
  thirdName?: InputMaybe<Scalars['String']['input']>;
};

/** Upload an attachment to visit */
export type PatientUploadVisitAttachment = {
  __typename?: 'PatientUploadVisitAttachment';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  visitAttachment?: Maybe<VisitAttachment>;
  visitErrors?: Maybe<Array<H_EntityError>>;
};

export type Payer = Node & {
  __typename?: 'Payer';
  backgroundImage?: Maybe<Scalars['String']['output']>;
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactMobileNumber?: Maybe<Scalars['String']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  contactPhoneNumber?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  facilities?: Maybe<InsuranceNetworkFacilityCountableConnection>;
  id: Scalars['ID']['output'];
  insuranceCardLogo?: Maybe<Scalars['String']['output']>;
  isActive: Scalars['Boolean']['output'];
  licenseNumber: Scalars['String']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  nameAr?: Maybe<Scalars['String']['output']>;
  networks?: Maybe<InsuranceNetworkCountableConnection>;
  parent?: Maybe<Payer>;
  payerSet: PayerCountableConnection;
  tpo: PayerTpo;
  type: PayerType;
  users: UserCountableConnection;
};


export type PayerFacilitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PayerNetworksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PayerPayerSetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type PayerUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PayerAddUpdateMemberBankDetailsRequestInput = {
  accountNo: Scalars['String']['input'];
  bankId: Scalars['Int']['input'];
  bankName: Scalars['String']['input'];
  branchName: Scalars['String']['input'];
  healthProgramMemberId: Scalars['ID']['input'];
  ibanNumber: Scalars['String']['input'];
  memberBankBeneficiaryName: Scalars['String']['input'];
  memberBankDetailId?: InputMaybe<Scalars['String']['input']>;
  swiftCode: Scalars['String']['input'];
};

export type PayerBankListRequestInput = {
  healthProgramMemberId: Scalars['ID']['input'];
};

export type PayerBankListResponse = {
  __typename?: 'PayerBankListResponse';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type PayerBenefitListRequestInput = {
  healthProgramMemberId: Scalars['ID']['input'];
};

export type PayerBenefitListResponse = {
  __typename?: 'PayerBenefitListResponse';
  benefitType?: Maybe<Scalars['String']['output']>;
  coInValue?: Maybe<Scalars['Double']['output']>;
  covered?: Maybe<Scalars['Boolean']['output']>;
  deductibleValue?: Maybe<Scalars['Double']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  service?: Maybe<Scalars['String']['output']>;
};

export type PayerCityListRequestInput = {
  countryId?: InputMaybe<Scalars['Int']['input']>;
  healthProgramMemberId: Scalars['ID']['input'];
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type PayerCityListResponse = {
  __typename?: 'PayerCityListResponse';
  cityId?: Maybe<Scalars['Int']['output']>;
  cityName?: Maybe<Scalars['String']['output']>;
  countryId?: Maybe<Scalars['Int']['output']>;
  countryName?: Maybe<Scalars['String']['output']>;
};

export type PayerClaimDetailsRequestInput = {
  claimId: Scalars['String']['input'];
  healthProgramMemberId: Scalars['ID']['input'];
};

export type PayerClaimDetailsResponse = {
  __typename?: 'PayerClaimDetailsResponse';
  approvedClaimAmount?: Maybe<Scalars['Int']['output']>;
  benefitType?: Maybe<Scalars['String']['output']>;
  claimId?: Maybe<Scalars['String']['output']>;
  claimNo?: Maybe<Scalars['String']['output']>;
  claimType?: Maybe<Scalars['String']['output']>;
  dateOfDischargeDate?: Maybe<Scalars['String']['output']>;
  dateOfServiceDate?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  memberCardId?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  submissionDate?: Maybe<Scalars['String']['output']>;
  submissionMode?: Maybe<Scalars['String']['output']>;
  treatingDoctorName?: Maybe<Scalars['String']['output']>;
};

export type PayerClaimListRequestInput = {
  healthProgramMemberId: Scalars['ID']['input'];
  pageNo: Scalars['Int']['input'];
};

export type PayerClaimListResponse = {
  __typename?: 'PayerClaimListResponse';
  claimAmount?: Maybe<Scalars['Int']['output']>;
  claimId?: Maybe<Scalars['String']['output']>;
  claimNo?: Maybe<Scalars['String']['output']>;
  providerName?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  submittedDate?: Maybe<Scalars['String']['output']>;
};

export type PayerClaimSubmissionRequestInput = {
  claimFormDocument: Scalars['String']['input'];
  claimedAmount: Scalars['Double']['input'];
  countryId: Scalars['Int']['input'];
  currencyId: Scalars['Int']['input'];
  diagnosis: Scalars['String']['input'];
  healthProgramMemberId: Scalars['ID']['input'];
  investigationResultDocument: Scalars['String']['input'];
  invoiceDocument: Scalars['String']['input'];
  memberBankDetailId: Scalars['String']['input'];
  physicianAddress?: InputMaybe<Scalars['String']['input']>;
  physicianContact?: InputMaybe<Scalars['String']['input']>;
  physicianName?: InputMaybe<Scalars['String']['input']>;
  providerName: Scalars['String']['input'];
  treatmentDate: Scalars['String']['input'];
};

export type PayerClinicianDetails = {
  __typename?: 'PayerClinicianDetails';
  clinicianId?: Maybe<Scalars['Int']['output']>;
  clinicianPhoto?: Maybe<Scalars['String']['output']>;
  clinicianPhotoThumb?: Maybe<Scalars['String']['output']>;
  consultationFee?: Maybe<Scalars['Double']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  generalContact?: Maybe<PayerGeneralContact>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isCovered?: Maybe<Scalars['Boolean']['output']>;
  specialty?: Maybe<Array<Maybe<PayerClinicianSpecialty>>>;
};

export type PayerClinicianDetailsRequestInput = {
  doctorId: Scalars['Int']['input'];
  healthProgramMemberId: Scalars['ID']['input'];
};

export type PayerClinicianDetailsResponse = {
  __typename?: 'PayerClinicianDetailsResponse';
  clinicianDetails?: Maybe<PayerClinicianDetails>;
  languages?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  majorSpecialities?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  nationalities?: Maybe<Array<Maybe<PayerNationality>>>;
  providers?: Maybe<Array<Maybe<PayerProvider>>>;
};

export type PayerClinicianListRequestInput = {
  cityIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  countryIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  doctorIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  healthProgramMemberId: Scalars['ID']['input'];
  languageIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pageNo: Scalars['Int']['input'];
  specialityIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type PayerClinicianListResponse = {
  __typename?: 'PayerClinicianListResponse';
  doctorId?: Maybe<Scalars['Int']['output']>;
  doctorName?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  specialty?: Maybe<Array<Maybe<PayerClinicianSpecialty>>>;
};

export type PayerClinicianSearchRequestInput = {
  healthProgramMemberId: Scalars['ID']['input'];
  isCovered?: InputMaybe<Scalars['Boolean']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type PayerClinicianSearchResponse = {
  __typename?: 'PayerClinicianSearchResponse';
  clinicianId?: Maybe<Scalars['Int']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
};

export type PayerClinicianSpecialty = {
  __typename?: 'PayerClinicianSpecialty';
  specialtyId?: Maybe<Scalars['Int']['output']>;
  specialtyName?: Maybe<Scalars['String']['output']>;
};

export type PayerCountableConnection = {
  __typename?: 'PayerCountableConnection';
  edges: Array<PayerCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type PayerCountableEdge = {
  __typename?: 'PayerCountableEdge';
  cursor: Scalars['String']['output'];
  node: Payer;
};

export type PayerCountryRequestInput = {
  healthProgramMemberId: Scalars['ID']['input'];
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type PayerCountryResponse = {
  __typename?: 'PayerCountryResponse';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type PayerCreate = {
  __typename?: 'PayerCreate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  payer?: Maybe<Payer>;
  payerErrors: Array<PayerError>;
};

export type PayerCurrencyRequestInput = {
  healthProgramMemberId: Scalars['ID']['input'];
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type PayerCurrencyResponse = {
  __typename?: 'PayerCurrencyResponse';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type PayerEligibilityRequestHistory = {
  __typename?: 'PayerEligibilityRequestHistory';
  createdDate?: Maybe<Scalars['String']['output']>;
  exceptionMessage?: Maybe<Scalars['String']['output']>;
  exceptionScreenshotBase64File?: Maybe<Scalars['String']['output']>;
  exceptionScreenshotFileName?: Maybe<Scalars['String']['output']>;
  failed?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  insuranceName?: Maybe<Scalars['String']['output']>;
  isFounded?: Maybe<Scalars['Boolean']['output']>;
  updateDate?: Maybe<Scalars['String']['output']>;
};

export type PayerError = {
  __typename?: 'PayerError';
  code: PayerErrorCode;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum PayerErrorCode {
  AlreadyExists = 'ALREADY_EXISTS',
  DuplicatedInputItem = 'DUPLICATED_INPUT_ITEM',
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  NotPayersImage = 'NOT_PAYERS_IMAGE',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type PayerFilterInput = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  licenseNumber?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
};

export type PayerGeneralContact = {
  __typename?: 'PayerGeneralContact';
  addressLine?: Maybe<Scalars['String']['output']>;
  alternativeName?: Maybe<Scalars['String']['output']>;
  cityName?: Maybe<Scalars['String']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  countryName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type PayerGeoLocation = {
  __typename?: 'PayerGeoLocation';
  area?: Maybe<Scalars['String']['output']>;
  geoLocationId?: Maybe<Scalars['Int']['output']>;
  latitude?: Maybe<Scalars['Int']['output']>;
  link?: Maybe<Scalars['String']['output']>;
  longitude?: Maybe<Scalars['Int']['output']>;
};

export type PayerInput = {
  backgroundImage?: InputMaybe<Scalars['String']['input']>;
  contactEmail?: InputMaybe<Scalars['String']['input']>;
  contactMobileNumber?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  contactPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  insuranceCardLogo?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  licenseNumber?: InputMaybe<Scalars['String']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameAr?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['ID']['input']>;
  tpo?: InputMaybe<Tpo>;
  type?: InputMaybe<PayerTypeEnum>;
};

export type PayerLanguageRequestInput = {
  healthProgramMemberId: Scalars['ID']['input'];
};

export type PayerLanguageResponse = {
  __typename?: 'PayerLanguageResponse';
  appLanguageId?: Maybe<Scalars['String']['output']>;
  languageName?: Maybe<Scalars['String']['output']>;
};

export type PayerMemberBankDetailsRequestInput = {
  healthProgramMemberId: Scalars['ID']['input'];
  pageNo: Scalars['Int']['input'];
};

export type PayerMemberBankDetailsResponse = {
  __typename?: 'PayerMemberBankDetailsResponse';
  accountNo?: Maybe<Scalars['String']['output']>;
  bankAccountHolderName?: Maybe<Scalars['String']['output']>;
  bankId?: Maybe<Scalars['Int']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  branchName?: Maybe<Scalars['String']['output']>;
  ibanNumber?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  memberBankId?: Maybe<Scalars['String']['output']>;
  swiftCode?: Maybe<Scalars['String']['output']>;
};

export type PayerNationality = {
  __typename?: 'PayerNationality';
  nationalityId?: Maybe<Scalars['Int']['output']>;
  nationalityName?: Maybe<Scalars['String']['output']>;
};

export type PayerOrder = {
  direction: OrderDirection;
  field?: InputMaybe<PayerOrderField>;
};

export enum PayerOrderField {
  Created = 'CREATED',
  Name = 'NAME',
  NameAr = 'NAME_AR',
  Tpo = 'TPO'
}

export type PayerPreApprovalListRequestInput = {
  healthProgramMemberId: Scalars['ID']['input'];
  pageNo: Scalars['Int']['input'];
};

export type PayerPreApprovalListResponse = {
  __typename?: 'PayerPreApprovalListResponse';
  IPOP?: Maybe<Scalars['String']['output']>;
  approvedAmount?: Maybe<Scalars['Double']['output']>;
  claimType?: Maybe<Scalars['String']['output']>;
  dateOfDischarge?: Maybe<Scalars['String']['output']>;
  dateOfService?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  preApprovalReference?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  submissionDate?: Maybe<Scalars['String']['output']>;
  submissionMode?: Maybe<Scalars['String']['output']>;
  treatingDoctorName?: Maybe<Scalars['String']['output']>;
};

export type PayerProvider = {
  __typename?: 'PayerProvider';
  providerId?: Maybe<Scalars['Int']['output']>;
  providerLogo?: Maybe<Scalars['String']['output']>;
  providerName?: Maybe<Scalars['String']['output']>;
};

export type PayerProviderClinician = {
  __typename?: 'PayerProviderClinician';
  about?: Maybe<Scalars['String']['output']>;
  clinicianId?: Maybe<Scalars['Int']['output']>;
  clinicianPhoto?: Maybe<Scalars['String']['output']>;
  clinicianPhotoThumb?: Maybe<Scalars['String']['output']>;
  consultationFee?: Maybe<Scalars['Double']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  specialties?: Maybe<Array<Maybe<PayerSpecialtyListResponse>>>;
};

export type PayerProviderDetailsRequestInput = {
  healthProgramMemberId: Scalars['ID']['input'];
  providerId: Scalars['Int']['input'];
};

export type PayerProviderDetailsResponse = {
  __typename?: 'PayerProviderDetailsResponse';
  aboutProvider?: Maybe<Scalars['String']['output']>;
  generalContact?: Maybe<PayerGeneralContact>;
  geoLocation?: Maybe<PayerGeoLocation>;
  officeHours?: Maybe<Scalars['String']['output']>;
  providerBranches?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  providerClinicians?: Maybe<Array<Maybe<PayerProviderClinician>>>;
  providerId?: Maybe<Scalars['Int']['output']>;
  providerLicenseSource?: Maybe<Scalars['String']['output']>;
  providerLogoUrl?: Maybe<Scalars['String']['output']>;
  providerName?: Maybe<Scalars['String']['output']>;
  providerType?: Maybe<Scalars['String']['output']>;
};

export type PayerProviderFilterListRequestInput = {
  cityIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  countryIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  healthProgramMemberId: Scalars['ID']['input'];
  pageNo: Scalars['Int']['input'];
  providerIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  providerTypeIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  specialityIds?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type PayerProviderListRequestInput = {
  healthProgramMemberId: Scalars['ID']['input'];
  pageNo: Scalars['Int']['input'];
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type PayerProviderListResponse = {
  __typename?: 'PayerProviderListResponse';
  isFavorite?: Maybe<Scalars['Boolean']['output']>;
  providerId?: Maybe<Scalars['Int']['output']>;
  providerLogo?: Maybe<Scalars['String']['output']>;
  providerName?: Maybe<Scalars['String']['output']>;
  providerType?: Maybe<Scalars['String']['output']>;
};

export type PayerProviderNearByListRequestInput = {
  healthProgramMemberId: Scalars['ID']['input'];
  latitude: Scalars['Double']['input'];
  longitude: Scalars['Double']['input'];
  pageNo: Scalars['Int']['input'];
};

export type PayerProviderNearByListResponse = {
  __typename?: 'PayerProviderNearByListResponse';
  addressLine?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<Scalars['Double']['output']>;
  isCovered?: Maybe<Scalars['Boolean']['output']>;
  isFavourite?: Maybe<Scalars['Boolean']['output']>;
  latitude?: Maybe<Scalars['Double']['output']>;
  longitude?: Maybe<Scalars['Double']['output']>;
  phoneNo?: Maybe<Scalars['String']['output']>;
  providerId?: Maybe<Scalars['Int']['output']>;
  providerLogo?: Maybe<Scalars['String']['output']>;
  providerName?: Maybe<Scalars['String']['output']>;
  providerType?: Maybe<Scalars['String']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};

export type PayerProviderTypeListRequestInput = {
  healthProgramMemberId: Scalars['ID']['input'];
};

export type PayerProviderTypeListResponse = {
  __typename?: 'PayerProviderTypeListResponse';
  providerType?: Maybe<Scalars['String']['output']>;
  providerTypeId?: Maybe<Scalars['Int']['output']>;
};

export type PayerSpecialtyListRequestInput = {
  healthProgramMemberId: Scalars['ID']['input'];
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type PayerSpecialtyListResponse = {
  __typename?: 'PayerSpecialtyListResponse';
  specialtyId?: Maybe<Scalars['Int']['output']>;
  specialtyName?: Maybe<Scalars['String']['output']>;
};

export enum PayerTpo {
  Eclaim = 'ECLAIM',
  Io = 'IO'
}

export enum PayerType {
  Insurance = 'INSURANCE',
  Tpa = 'TPA'
}

export enum PayerTypeEnum {
  Insurance = 'INSURANCE',
  Tpa = 'TPA'
}

export type PayerUpdate = {
  __typename?: 'PayerUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  payer?: Maybe<Payer>;
  payerErrors: Array<PayerError>;
};

export enum PaymentBrand {
  Mada = 'MADA',
  Master = 'MASTER',
  Visa = 'VISA'
}

export type PaymentCheckoutInput = {
  addressId?: InputMaybe<Scalars['String']['input']>;
  amountSourceId: Scalars['String']['input'];
  amountSourceType: AmountSourceType;
};

export type PaymentCheckoutResponse = {
  __typename?: 'PaymentCheckoutResponse';
  appointmentId?: Maybe<Scalars['H_Long']['output']>;
  buildNumber?: Maybe<Scalars['String']['output']>;
  checkoutPaymentFormUrl?: Maybe<Scalars['String']['output']>;
  healthProgramId?: Maybe<Scalars['H_Long']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  marketplaceOrderId?: Maybe<Scalars['H_Long']['output']>;
  merchantTransactionId?: Maybe<Scalars['String']['output']>;
  ndc?: Maybe<Scalars['String']['output']>;
  orderCheckoutToken?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['H_Long']['output']>;
  outPatientJourneyId?: Maybe<Scalars['H_Long']['output']>;
  patientId?: Maybe<Scalars['H_Long']['output']>;
  paymentStatus?: Maybe<OperationStatus>;
  paymentType?: Maybe<Scalars['String']['output']>;
  prescriptionOrderId?: Maybe<Scalars['H_Long']['output']>;
  result?: Maybe<PaymentResponseResult>;
  shopperResultUrl?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['H_Long']['output']>;
  vendorId?: Maybe<Scalars['H_Long']['output']>;
  visitId?: Maybe<Scalars['H_Long']['output']>;
};

export type PaymentClearanceFromMultipleSourcesInput = {
  addressId?: InputMaybe<Scalars['String']['input']>;
  amountSourceId: Scalars['String']['input'];
  amountSourceType: AmountSourceType;
};

export type PaymentClearanceInput = {
  amountSentToWallet?: InputMaybe<Scalars['String']['input']>;
  amountSourceId: Scalars['String']['input'];
  amountSourceType: AmountSourceType;
  deductedPaymentReceiptIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  patientId?: InputMaybe<Scalars['ID']['input']>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export enum PaymentErrorCodes {
  InvalidOrExpiredToken = 'INVALID_OR_EXPIRED_TOKEN',
  PaymentAddCardError = 'PAYMENT_ADD_CARD_ERROR',
  PaymentCardNotFound = 'PAYMENT_CARD_NOT_FOUND',
  PaymentDeleteCardError = 'PAYMENT_DELETE_CARD_ERROR',
  PaymentInvalidSecurityCodeError = 'PAYMENT_INVALID_SECURITY_CODE_ERROR',
  PaymentOwnershipError = 'PAYMENT_OWNERSHIP_ERROR',
  PaymentPermissionDenied = 'PAYMENT_PERMISSION_DENIED',
  PaymentProcessError = 'PAYMENT_PROCESS_ERROR',
  PaymentUnknown = 'PAYMENT_UNKNOWN',
  PaymentUnknownType = 'PAYMENT_UNKNOWN_TYPE'
}

export type PaymentGraphqlError = {
  __typename?: 'PaymentGraphqlError';
  code?: Maybe<PaymentErrorCodes>;
  errorType?: Maybe<ErrorType>;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type PaymentInvoice = {
  __typename?: 'PaymentInvoice';
  appointmentId?: Maybe<Scalars['ID']['output']>;
  checkoutId?: Maybe<Scalars['String']['output']>;
  consumerId?: Maybe<Scalars['ID']['output']>;
  consumerUserId?: Maybe<Scalars['ID']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  healthProgramId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  marketplaceOrderId?: Maybe<Scalars['ID']['output']>;
  merchantTransactionId?: Maybe<Scalars['String']['output']>;
  orderCheckoutToken?: Maybe<Scalars['String']['output']>;
  outPatientJourneyId?: Maybe<Scalars['ID']['output']>;
  paymentSource?: Maybe<PaymentSource>;
  paymentType?: Maybe<Scalars['String']['output']>;
  prescriptionOrderId?: Maybe<Scalars['ID']['output']>;
  priceAfterVat?: Maybe<Scalars['Float']['output']>;
  priceBeforeVat?: Maybe<Scalars['Float']['output']>;
  vat?: Maybe<Scalars['Float']['output']>;
  vendorId?: Maybe<Scalars['ID']['output']>;
  visitId?: Maybe<Scalars['ID']['output']>;
};

export type PaymentInvoiceCountableConnection = {
  __typename?: 'PaymentInvoiceCountableConnection';
  edges: Array<PaymentInvoiceCountableEdge>;
  pageInfo: H_PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type PaymentInvoiceCountableEdge = {
  __typename?: 'PaymentInvoiceCountableEdge';
  cursor: Scalars['String']['output'];
  node: PaymentInvoice;
};

export type PaymentInvoiceFilterInput = {
  appointmentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  checkoutIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  consumerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  consumerUserIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  createdDate?: InputMaybe<H_DateTimeRangeInput>;
  healthProgramIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  marketplaceOrderIdNotNull?: InputMaybe<Scalars['Boolean']['input']>;
  marketplaceOrderIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  orderCheckoutTokens?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  outPatientJourneyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  paymentSources?: InputMaybe<Array<InputMaybe<PaymentSource>>>;
  paymentTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  prescriptionOrderIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  vendorIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  viewed?: InputMaybe<Scalars['Boolean']['input']>;
  visitIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export enum PaymentMethod {
  Cash = 'CASH',
  Cc = 'CC',
  PaymentCard = 'PAYMENT_CARD'
}

export enum PaymentOperation {
  Confirm = 'CONFIRM',
  Refund = 'REFUND',
  Reverse = 'REVERSE'
}

export type PaymentOperationLog = {
  __typename?: 'PaymentOperationLog';
  appointment?: Maybe<Appointment>;
  appointmentId?: Maybe<Scalars['ID']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  details?: Maybe<Scalars['String']['output']>;
  healthProgramId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  marketplaceOrder?: Maybe<MarketplaceOrder>;
  marketplaceOrderId?: Maybe<Scalars['H_Long']['output']>;
  operationStatus?: Maybe<OperationStatus>;
  operationType?: Maybe<OperationType>;
  order?: Maybe<Order>;
  orderCheckoutToken?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['ID']['output']>;
  outPatientJourney?: Maybe<OutPatientJourney>;
  outPatientJourneyId?: Maybe<Scalars['ID']['output']>;
  patientId?: Maybe<Scalars['ID']['output']>;
  paymentAmount?: Maybe<Scalars['Float']['output']>;
  paymentAmountCanBeRefundedFromWallet?: Maybe<Scalars['Float']['output']>;
  paymentAmountRefundedFromWallet?: Maybe<Scalars['Float']['output']>;
  paymentId?: Maybe<Scalars['String']['output']>;
  prescriptionOrderId?: Maybe<Scalars['H_Long']['output']>;
  refundId?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['ID']['output']>;
  visit?: Maybe<Visit>;
  visitId?: Maybe<Scalars['ID']['output']>;
  walletId?: Maybe<Scalars['ID']['output']>;
};

export type PaymentOperationLogCountableConnection = {
  __typename?: 'PaymentOperationLogCountableConnection';
  edges: Array<PaymentOperationLogCountableEdge>;
  pageInfo: H_PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type PaymentOperationLogCountableEdge = {
  __typename?: 'PaymentOperationLogCountableEdge';
  cursor: Scalars['String']['output'];
  node: PaymentOperationLog;
};

export type PaymentOperationLogFilterInput = {
  appointmentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  healthProgramIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  marketplaceOrderIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  operationStatuses?: InputMaybe<Array<InputMaybe<OperationStatus>>>;
  operationTypes?: InputMaybe<Array<InputMaybe<OperationType>>>;
  orderCheckoutTokens?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  orderIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  outPatientJourneyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  patientIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  paymentIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  prescriptionOrderIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  refundIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  visitIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  walletIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export enum PaymentOperationLogSortField {
  /** Sort visit by created. */
  Created = 'CREATED'
}

export type PaymentOperationLogSortingInput = {
  /** Specifies the direction in which to sort products. */
  direction: H_OrderDirection;
  /** Sort visits by the selected field. */
  field: PaymentOperationLogSortField;
};

export type PaymentParameter = {
  __typename?: 'PaymentParameter';
  name?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type PaymentReceipt = {
  __typename?: 'PaymentReceipt';
  amountRefundedByHyperpay?: Maybe<Scalars['Float']['output']>;
  amountRefundedByHyperpayFromWallet?: Maybe<Scalars['Float']['output']>;
  amountSentToWallet?: Maybe<Scalars['Float']['output']>;
  appointment?: Maybe<Appointment>;
  balanceInWallet?: Maybe<Scalars['Float']['output']>;
  benefitedFrom?: Maybe<Scalars['Boolean']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  healthProgram?: Maybe<HealthProgram>;
  id: Scalars['ID']['output'];
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['Instant']['output']>;
  lastPaymentAmount?: Maybe<Scalars['Float']['output']>;
  lastPaymentId?: Maybe<Scalars['String']['output']>;
  lastPaymentType?: Maybe<Scalars['String']['output']>;
  marketplaceOrder?: Maybe<MarketplaceOrder>;
  marketplaceOrderId?: Maybe<Scalars['H_Long']['output']>;
  order?: Maybe<Order>;
  orderCheckoutToken?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['H_Long']['output']>;
  outPatientJourney?: Maybe<OutPatientJourney>;
  patient?: Maybe<Patient>;
  paymentBrand?: Maybe<PaymentBrand>;
  paymentId?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentType?: Maybe<Scalars['String']['output']>;
  prescriptionOrderId?: Maybe<Scalars['H_Long']['output']>;
  totalAmount?: Maybe<Scalars['Float']['output']>;
  user?: Maybe<User>;
  vendor?: Maybe<Vendor>;
  visit?: Maybe<Visit>;
};

export type PaymentReceiptCountableConnection = {
  __typename?: 'PaymentReceiptCountableConnection';
  edges: Array<PaymentReceiptCountableEdge>;
  pageInfo: H_PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type PaymentReceiptCountableEdge = {
  __typename?: 'PaymentReceiptCountableEdge';
  cursor: Scalars['String']['output'];
  node: PaymentReceipt;
};

export type PaymentReceiptFilterInput = {
  appointmentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  benefitedFrom?: InputMaybe<Scalars['Boolean']['input']>;
  healthProgramIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  marketplaceOrderIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  orderCheckoutTokens?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  orderIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  outPatientJourneyIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  patientIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  paymentBrands?: InputMaybe<Array<InputMaybe<PaymentBrand>>>;
  paymentIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  paymentMethods?: InputMaybe<Array<InputMaybe<PaymentMethod>>>;
  prescriptionOrderIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  userIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  vendorIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  visitIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type PaymentReceiptInput = {
  healthProgramId: Scalars['ID']['input'];
  paymentMethod: PaymentMethod;
};

export type PaymentResponse = {
  __typename?: 'PaymentResponse';
  amount?: Maybe<Scalars['Float']['output']>;
  appointmentId?: Maybe<Scalars['H_Long']['output']>;
  buildNumber?: Maybe<Scalars['String']['output']>;
  card?: Maybe<PaymentResponseCard>;
  currency?: Maybe<Scalars['String']['output']>;
  customParameters?: Maybe<Scalars['DictType']['output']>;
  descriptor?: Maybe<Scalars['String']['output']>;
  healthProgramId?: Maybe<Scalars['H_Long']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  marketplaceOrderId?: Maybe<Scalars['H_Long']['output']>;
  merchantTransactionId?: Maybe<Scalars['String']['output']>;
  ndc?: Maybe<Scalars['String']['output']>;
  orderCheckoutToken?: Maybe<Scalars['String']['output']>;
  orderId?: Maybe<Scalars['H_Long']['output']>;
  outPatientJourneyId?: Maybe<Scalars['H_Long']['output']>;
  patientId?: Maybe<Scalars['H_Long']['output']>;
  paymentBrand?: Maybe<Scalars['String']['output']>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentReceiptId?: Maybe<Scalars['H_Long']['output']>;
  paymentType?: Maybe<Scalars['String']['output']>;
  prescriptionOrderId?: Maybe<Scalars['H_Long']['output']>;
  recurringType?: Maybe<Scalars['String']['output']>;
  redirect?: Maybe<PaymentResponseRedirect>;
  referencedId?: Maybe<Scalars['String']['output']>;
  refundReason?: Maybe<Scalars['String']['output']>;
  refundedAfterPayment?: Maybe<Scalars['Boolean']['output']>;
  registrationId?: Maybe<Scalars['String']['output']>;
  result?: Maybe<PaymentResponseResult>;
  resultDetails?: Maybe<PaymentResponseResultDetails>;
  risk?: Maybe<PaymentResponseRisk>;
  shopperResultUrl?: Maybe<Scalars['String']['output']>;
  standingInstruction?: Maybe<Scalars['DictType']['output']>;
  timestamp?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['H_Long']['output']>;
  vendorId?: Maybe<Scalars['H_Long']['output']>;
  visitId?: Maybe<Scalars['H_Long']['output']>;
};

export type PaymentResponseCard = {
  __typename?: 'PaymentResponseCard';
  bin?: Maybe<Scalars['String']['output']>;
  binCountry?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  expiryMonth?: Maybe<Scalars['String']['output']>;
  expiryYear?: Maybe<Scalars['String']['output']>;
  holder?: Maybe<Scalars['String']['output']>;
  issuer?: Maybe<CardIssuer>;
  last4Digits?: Maybe<Scalars['String']['output']>;
  maxPanLength?: Maybe<Scalars['String']['output']>;
  regulatedFlag?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type PaymentResponseRedirect = {
  __typename?: 'PaymentResponseRedirect';
  parameters?: Maybe<Array<Maybe<PaymentParameter>>>;
  url?: Maybe<Scalars['String']['output']>;
};

export type PaymentResponseResult = {
  __typename?: 'PaymentResponseResult';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
};

export type PaymentResponseResultDetails = {
  __typename?: 'PaymentResponseResultDetails';
  clearingInstituteName?: Maybe<Scalars['String']['output']>;
};

export type PaymentResponseRisk = {
  __typename?: 'PaymentResponseRisk';
  score?: Maybe<Scalars['String']['output']>;
};

export enum PaymentSource {
  Checkout = 'CHECKOUT',
  Wallet = 'WALLET'
}

export enum PaymentStatus {
  FullyCovered = 'FULLY_COVERED',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentSucceeded = 'PAYMENT_SUCCEEDED',
  PaymentUnavailable = 'PAYMENT_UNAVAILABLE',
  PaymentUnderProcessing = 'PAYMENT_UNDER_PROCESSING',
  Refunded = 'REFUNDED',
  RefundedFailed = 'REFUNDED_FAILED',
  RefundedToWallet = 'REFUNDED_TO_WALLET',
  RefundedUnderProcessing = 'REFUNDED_UNDER_PROCESSING',
  UnPaid = 'UN_PAID'
}

export enum PaymentType {
  Cd = 'CD',
  Cp = 'CP',
  Db = 'DB',
  Pa = 'PA',
  Rf = 'RF',
  Rv = 'RV'
}

export type PaymentUpdateInput = {
  /**
   * the amount value is only effective for the case of partial (not full) refund,
   * and the amount value should be within the available value for refund
   */
  amount?: InputMaybe<Scalars['Float']['input']>;
  amountSourceId: Scalars['String']['input'];
  amountSourceType: AmountSourceType;
  paymentOperation: PaymentOperation;
};

export type Permission = Node & {
  __typename?: 'Permission';
  code?: Maybe<PermissionEnum>;
  id: Scalars['ID']['output'];
  keyCloakPermission?: Maybe<KeyCloakPermission>;
  name: Scalars['String']['output'];
};

export enum PermissionEnum {
  ApproveHealthPackages = 'APPROVE_HEALTH_PACKAGES',
  ApproveProducts = 'APPROVE_PRODUCTS',
  AssignPrescriptionOrderToVendors = 'ASSIGN_PRESCRIPTION_ORDER_TO_VENDORS',
  ConvertVirtualOrderToRealOne = 'CONVERT_VIRTUAL_ORDER_TO_REAL_ONE',
  CustomerSupportManageOrders = 'CUSTOMER_SUPPORT_MANAGE_ORDERS',
  ManageAdminPromotions = 'MANAGE_ADMIN_PROMOTIONS',
  ManageAppointments = 'MANAGE_APPOINTMENTS',
  ManageArticles = 'MANAGE_ARTICLES',
  ManageBlocks = 'MANAGE_BLOCKS',
  ManageBranches = 'MANAGE_BRANCHES',
  ManageBrands = 'MANAGE_BRANDS',
  ManageCallbacksRequests = 'MANAGE_CALLBACKS_REQUESTS',
  ManageChat = 'MANAGE_CHAT',
  ManageChatFlow = 'MANAGE_CHAT_FLOW',
  ManageChatFlowContributor = 'MANAGE_CHAT_FLOW_CONTRIBUTOR',
  ManageCheckouts = 'MANAGE_CHECKOUTS',
  ManageCities = 'MANAGE_CITIES',
  ManageCodeSystemEditorReviewers = 'MANAGE_CODE_SYSTEM_EDITOR_REVIEWERS',
  ManageCodeSystemLists = 'MANAGE_CODE_SYSTEM_LISTS',
  ManageCodeSystemReviewers = 'MANAGE_CODE_SYSTEM_REVIEWERS',
  ManageDeliveryTimeSlots = 'MANAGE_DELIVERY_TIME_SLOTS',
  ManageDepartments = 'MANAGE_DEPARTMENTS',
  ManageDiagnosis = 'MANAGE_DIAGNOSIS',
  ManageDiscounts = 'MANAGE_DISCOUNTS',
  ManageDoctors = 'MANAGE_DOCTORS',
  ManageEarlyRefillReasons = 'MANAGE_EARLY_REFILL_REASONS',
  ManageHealthChannels = 'MANAGE_HEALTH_CHANNELS',
  ManageHealthChannelsCategories = 'MANAGE_HEALTH_CHANNELS_CATEGORIES',
  ManageHealthConditions = 'MANAGE_HEALTH_CONDITIONS',
  ManageHealthMessages = 'MANAGE_HEALTH_MESSAGES',
  ManageHealthPackages = 'MANAGE_HEALTH_PACKAGES',
  ManageHealthPackageCategories = 'MANAGE_HEALTH_PACKAGE_CATEGORIES',
  ManageHealthPackageOrders = 'MANAGE_HEALTH_PACKAGE_ORDERS',
  ManageHealthPrograms = 'MANAGE_HEALTH_PROGRAMS',
  ManageHealthProgramsCareForFields = 'MANAGE_HEALTH_PROGRAMS_CARE_FOR_FIELDS',
  ManageHealthSymptoms = 'MANAGE_HEALTH_SYMPTOMS',
  ManageInsurance = 'MANAGE_INSURANCE',
  ManageInsuranceNetworks = 'MANAGE_INSURANCE_NETWORKS',
  ManageInvoices = 'MANAGE_INVOICES',
  ManageLabs = 'MANAGE_LABS',
  ManageLanguages = 'MANAGE_LANGUAGES',
  ManageMarketplaceOrders = 'MANAGE_MARKETPLACE_ORDERS',
  ManageMedicalDeliveryRequests = 'MANAGE_MEDICAL_DELIVERY_REQUESTS',
  ManageMedicalEdits = 'MANAGE_MEDICAL_EDITS',
  ManageMedicalForms = 'MANAGE_MEDICAL_FORMS',
  ManageMedications = 'MANAGE_MEDICATIONS',
  ManageMedicationScientificDetails = 'MANAGE_MEDICATION_SCIENTIFIC_DETAILS',
  ManageOrders = 'MANAGE_ORDERS',
  ManageParameters = 'MANAGE_PARAMETERS',
  ManagePatients = 'MANAGE_PATIENTS',
  ManagePatientMedicalHistory = 'MANAGE_PATIENT_MEDICAL_HISTORY',
  ManagePayments = 'MANAGE_PAYMENTS',
  ManagePermissionGroup = 'MANAGE_PERMISSION_GROUP',
  ManagePharmacyCredentials = 'MANAGE_PHARMACY_CREDENTIALS',
  ManagePlugins = 'MANAGE_PLUGINS',
  ManagePrescriptions = 'MANAGE_PRESCRIPTIONS',
  ManagePricingRules = 'MANAGE_PRICING_RULES',
  ManageProducts = 'MANAGE_PRODUCTS',
  ManageProductCategories = 'MANAGE_PRODUCT_CATEGORIES',
  ManagePrograms = 'MANAGE_PROGRAMS',
  ManageProgramTeams = 'MANAGE_PROGRAM_TEAMS',
  ManageProgramTemplates = 'MANAGE_PROGRAM_TEMPLATES',
  ManagePromotions = 'MANAGE_PROMOTIONS',
  ManageQualifications = 'MANAGE_QUALIFICATIONS',
  ManageRejectionReasons = 'MANAGE_REJECTION_REASONS',
  ManageRiskStratification = 'MANAGE_RISK_STRATIFICATION',
  ManageRuleEngineRules = 'MANAGE_RULE_ENGINE_RULES',
  ManageSettings = 'MANAGE_SETTINGS',
  ManageShipping = 'MANAGE_SHIPPING',
  ManageSocialAndStreaming = 'MANAGE_SOCIAL_AND_STREAMING',
  ManageSpecializations = 'MANAGE_SPECIALIZATIONS',
  ManageStaff = 'MANAGE_STAFF',
  ManageSubscriptions = 'MANAGE_SUBSCRIPTIONS',
  ManageSurveys = 'MANAGE_SURVEYS',
  ManageTranslations = 'MANAGE_TRANSLATIONS',
  ManageUsers = 'MANAGE_USERS',
  ManageVendors = 'MANAGE_VENDORS',
  ManageVisits = 'MANAGE_VISITS',
  ManageVisitCancelReasons = 'MANAGE_VISIT_CANCEL_REASONS',
  ManageVisitRejectionReasons = 'MANAGE_VISIT_REJECTION_REASONS',
  ManageVisitSummary = 'MANAGE_VISIT_SUMMARY',
  ManageWallets = 'MANAGE_WALLETS',
  MangeActivityTracker = 'MANGE_ACTIVITY_TRACKER',
  MangeEditsAndActions = 'MANGE_EDITS_AND_ACTIONS',
  MedicationScientificDetails = 'MEDICATION_SCIENTIFIC_DETAILS',
  PublishCodeSystem = 'PUBLISH_CODE_SYSTEM',
  UploadCodeSystemFile = 'UPLOAD_CODE_SYSTEM_FILE',
  ValidateProcedures = 'VALIDATE_PROCEDURES',
  VerifyMemberIds = 'VERIFY_MEMBER_IDS',
  VerifyNationalIds = 'VERIFY_NATIONAL_IDS',
  ViewAppointments = 'VIEW_APPOINTMENTS',
  ViewCodeSystemLists = 'VIEW_CODE_SYSTEM_LISTS',
  ViewCustomers = 'VIEW_CUSTOMERS',
  ViewHealthPrograms = 'VIEW_HEALTH_PROGRAMS',
  ViewOrders = 'VIEW_ORDERS',
  ViewPatients = 'VIEW_PATIENTS',
  ViewPrograms = 'VIEW_PROGRAMS',
  ViewProgramTeams = 'VIEW_PROGRAM_TEAMS',
  ViewProgramTemplates = 'VIEW_PROGRAM_TEMPLATES',
  ViewStaff = 'VIEW_STAFF',
  ViewSubscriptions = 'VIEW_SUBSCRIPTIONS',
  ViewSurveys = 'VIEW_SURVEYS',
  ViewTransaction = 'VIEW_TRANSACTION',
  ViewUsers = 'VIEW_USERS',
  ViewVisits = 'VIEW_VISITS',
  ViewWallets = 'VIEW_WALLETS',
  WorkflowManageOrders = 'WORKFLOW_MANAGE_ORDERS',
  ManageOutPatientJourneys = 'manage_out_patient_journeys'
}

export type PermissionGroupCreate = {
  __typename?: 'PermissionGroupCreate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  group?: Maybe<Group>;
  permissionGroupErrors: Array<PermissionGroupError>;
};

export type PermissionGroupCreateInput = {
  addPermissions?: InputMaybe<Array<PermissionEnum>>;
  addUsers?: InputMaybe<Array<Scalars['ID']['input']>>;
  groupConfiguration: GroupConfigurationInput;
  name: Scalars['String']['input'];
};

export type PermissionGroupDelete = {
  __typename?: 'PermissionGroupDelete';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  group?: Maybe<Group>;
  permissionGroupErrors: Array<PermissionGroupError>;
};

export type PermissionGroupError = {
  __typename?: 'PermissionGroupError';
  code: PermissionGroupErrorCode;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  permissions?: Maybe<Array<PermissionEnum>>;
  users?: Maybe<Array<Scalars['ID']['output']>>;
};

export enum PermissionGroupErrorCode {
  AssignNonStaffMember = 'ASSIGN_NON_STAFF_MEMBER',
  CannotRemoveFromLastGroup = 'CANNOT_REMOVE_FROM_LAST_GROUP',
  DuplicatedInputItem = 'DUPLICATED_INPUT_ITEM',
  Invalid = 'INVALID',
  LeftNotManageablePermission = 'LEFT_NOT_MANAGEABLE_PERMISSION',
  NotEditable = 'NOT_EDITABLE',
  NotFound = 'NOT_FOUND',
  OutOfScopePermission = 'OUT_OF_SCOPE_PERMISSION',
  OutOfScopeUser = 'OUT_OF_SCOPE_USER',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type PermissionGroupFilterInput = {
  isEditable?: InputMaybe<Scalars['Boolean']['input']>;
  isGlobal?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export enum PermissionGroupSortField {
  CreatedDate = 'CREATED_DATE',
  Name = 'NAME'
}

export type PermissionGroupSortingInput = {
  direction: OrderDirection;
  field: PermissionGroupSortField;
};

export type PermissionGroupUpdate = {
  __typename?: 'PermissionGroupUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  group?: Maybe<Group>;
  permissionGroupErrors: Array<PermissionGroupError>;
};

export type PermissionGroupUpdateInput = {
  addPermissions?: InputMaybe<Array<PermissionEnum>>;
  addUsers?: InputMaybe<Array<Scalars['ID']['input']>>;
  groupConfiguration: GroupConfigurationUpdateInput;
  removePermissions?: InputMaybe<Array<PermissionEnum>>;
  removeUsers?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export enum PersonGenderEnum {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type Pharmacist = Node & {
  __typename?: 'Pharmacist';
  created: Scalars['DateTime']['output'];
  healthLicenseEndDate?: Maybe<Scalars['Date']['output']>;
  healthLicenseNumber?: Maybe<Scalars['String']['output']>;
  healthLicenseStartDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type PharmacistInput = {
  healthLicenseEndDate?: InputMaybe<Scalars['Date']['input']>;
  healthLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  healthLicenseStartDate?: InputMaybe<Scalars['Date']['input']>;
};

export type PharmacyCredentials = {
  __typename?: 'PharmacyCredentials';
  branch?: Maybe<Branch>;
  errors?: Maybe<Array<Maybe<IntegrationGraphqlError>>>;
  license?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
};

export type PharmacyCredentialsInput = {
  branch: Scalars['ID']['input'];
  license: Scalars['String']['input'];
  password: Scalars['String']['input'];
  token?: InputMaybe<Scalars['String']['input']>;
  userName: Scalars['String']['input'];
};

export type Plan = Node & {
  __typename?: 'Plan';
  created: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  fixedCost?: Maybe<Money>;
  fixedCostAmount: Scalars['Float']['output'];
  fixedOrderCost?: Maybe<Money>;
  fixedOrderCostAmount: Scalars['Float']['output'];
  fixedOrderPercentage: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isEditable?: Maybe<Scalars['Boolean']['output']>;
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  period: PlanPeriod;
  subscriptions: VendorSubscriptionCountableConnection;
  validFrom: Scalars['DateTime']['output'];
  validTill?: Maybe<Scalars['DateTime']['output']>;
};


export type PlanSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PlanCountableConnection = {
  __typename?: 'PlanCountableConnection';
  edges: Array<PlanCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type PlanCountableEdge = {
  __typename?: 'PlanCountableEdge';
  cursor: Scalars['String']['output'];
  node: Plan;
};

export type PlanCreate = {
  __typename?: 'PlanCreate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  plan?: Maybe<Plan>;
  subscriptionErrors: Array<SubscriptionError>;
};

export type PlanFilterInput = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
};

export type PlanInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  fixedCostAmount?: InputMaybe<Scalars['Decimal']['input']>;
  fixedOrderCostAmount?: InputMaybe<Scalars['Decimal']['input']>;
  fixedOrderPercentage?: InputMaybe<Scalars['Decimal']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  period?: InputMaybe<Period>;
  validFrom?: InputMaybe<Scalars['DateTime']['input']>;
  validTill?: InputMaybe<Scalars['DateTime']['input']>;
};

export type PlanOrder = {
  direction: OrderDirection;
  field?: InputMaybe<PlanOrderField>;
};

export enum PlanOrderField {
  Name = 'NAME'
}

export enum PlanPeriod {
  HalfYear = 'HALF_YEAR',
  Monthly = 'MONTHLY',
  ThreeMonths = 'THREE_MONTHS',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export type PlanUpdate = {
  __typename?: 'PlanUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  plan?: Maybe<Plan>;
  subscriptionErrors: Array<SubscriptionError>;
};

export type PodiatricMedicalAssistant = Node & {
  __typename?: 'PodiatricMedicalAssistant';
  created: Scalars['DateTime']['output'];
  healthLicenseEndDate?: Maybe<Scalars['Date']['output']>;
  healthLicenseNumber?: Maybe<Scalars['String']['output']>;
  healthLicenseStartDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type PodiatricMedicalAssistantInput = {
  healthLicenseEndDate?: InputMaybe<Scalars['Date']['input']>;
  healthLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  healthLicenseStartDate?: InputMaybe<Scalars['Date']['input']>;
};

export enum PreferredCommunicationMethodsEnum {
  Email = 'EMAIL',
  Phone = 'PHONE',
  Sms = 'SMS'
}

export type Prescription = {
  __typename?: 'Prescription';
  activeOrders?: Maybe<Array<Maybe<Order>>>;
  clinician: Scalars['String']['output'];
  created: Scalars['Instant']['output'];
  diagnosis: Array<Diagnose>;
  dispenseStatus: PrescriptionDispenseStatus;
  id: Scalars['ID']['output'];
  medications: Array<Medication>;
  modified?: Maybe<Scalars['Instant']['output']>;
  orders: OrderCountableConnection;
  patient: Patient;
  patientIdentifier: Scalars['String']['output'];
  patientIdentifierType: Scalars['String']['output'];
  prescriptionId: Scalars['String']['output'];
  providerLicense: Scalars['String']['output'];
  referenceNumber: Scalars['String']['output'];
  user: User;
  userId: Scalars['Int']['output'];
  visit?: Maybe<Visit>;
};


export type PrescriptionOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OrderFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<OrderSortingInput>;
};

export type PrescriptionAddress = {
  __typename?: 'PrescriptionAddress';
  area?: Maybe<Scalars['String']['output']>;
  areaAr?: Maybe<Scalars['String']['output']>;
  buildingName?: Maybe<Scalars['String']['output']>;
  buildingNameAr?: Maybe<Scalars['String']['output']>;
  buildingNumber?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  cityAr?: Maybe<Scalars['String']['output']>;
  coordinates?: Maybe<Scalars['String']['output']>;
  district?: Maybe<Scalars['String']['output']>;
  districtAr?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  nameAr?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
  regionAr?: Maybe<Scalars['String']['output']>;
  streetAddress1?: Maybe<Scalars['String']['output']>;
  streetAddress1Ar?: Maybe<Scalars['String']['output']>;
  streetAddress2?: Maybe<Scalars['String']['output']>;
  streetAddress2Ar?: Maybe<Scalars['String']['output']>;
  unitNumber?: Maybe<Scalars['String']['output']>;
};

export type PrescriptionCountableConnection = {
  __typename?: 'PrescriptionCountableConnection';
  edges: Array<PrescriptionCountableEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type PrescriptionCountableEdge = {
  __typename?: 'PrescriptionCountableEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: Prescription;
};

export enum PrescriptionDispenseStatus {
  Delivered = 'DELIVERED',
  Dispensed = 'DISPENSED',
  ErxHubAuthRequestFailed = 'ERX_HUB_AUTH_REQUEST_FAILED',
  ErxHubClaimApproved = 'ERX_HUB_CLAIM_APPROVED',
  ErxHubClaimFailed = 'ERX_HUB_CLAIM_FAILED',
  ErxHubClaimRejected = 'ERX_HUB_CLAIM_REJECTED',
  ErxHubFullyApproved = 'ERX_HUB_FULLY_APPROVED',
  ErxHubPartiallyApproved = 'ERX_HUB_PARTIALLY_APPROVED',
  ErxHubRejected = 'ERX_HUB_REJECTED',
  ErxHubTimedOut = 'ERX_HUB_TIMED_OUT',
  InProgress = 'IN_PROGRESS',
  OutForDelivery = 'OUT_FOR_DELIVERY',
  Pending = 'PENDING',
  ReadyForCustomerPickup = 'READY_FOR_CUSTOMER_PICKUP',
  WaitingErxHubApproval = 'WAITING_ERX_HUB_APPROVAL',
  WaitingErxHubClaimApproval = 'WAITING_ERX_HUB_CLAIM_APPROVAL',
  WaitingProviderSelection = 'WAITING_PROVIDER_SELECTION'
}

export enum PrescriptionDispenseStatusEnum {
  Delivered = 'DELIVERED',
  Dispensed = 'DISPENSED',
  ErxHubAuthRequestFailed = 'ERX_HUB_AUTH_REQUEST_FAILED',
  ErxHubClaimApproved = 'ERX_HUB_CLAIM_APPROVED',
  ErxHubClaimFailed = 'ERX_HUB_CLAIM_FAILED',
  ErxHubClaimRejected = 'ERX_HUB_CLAIM_REJECTED',
  ErxHubFullyApproved = 'ERX_HUB_FULLY_APPROVED',
  ErxHubPartiallyApproved = 'ERX_HUB_PARTIALLY_APPROVED',
  ErxHubRejected = 'ERX_HUB_REJECTED',
  ErxHubTimedOut = 'ERX_HUB_TIMED_OUT',
  InProgress = 'IN_PROGRESS',
  OutForDelivery = 'OUT_FOR_DELIVERY',
  Pending = 'PENDING',
  ReadyForCustomerPickup = 'READY_FOR_CUSTOMER_PICKUP',
  WaitingErxHubApproval = 'WAITING_ERX_HUB_APPROVAL',
  WaitingErxHubClaimApproval = 'WAITING_ERX_HUB_CLAIM_APPROVAL',
  WaitingProviderSelection = 'WAITING_PROVIDER_SELECTION'
}

export type PrescriptionFilterInput = {
  created?: InputMaybe<DateRangeInput>;
  medication?: InputMaybe<Scalars['String']['input']>;
  orderType?: InputMaybe<OrderTypesEnum>;
  orders?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  referenceNumber_Icontains?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<PrescriptionDispenseStatusEnum>>>;
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type PrescriptionOrderAssignVendorsInput = {
  branches: Array<Scalars['ID']['input']>;
  order: Scalars['ID']['input'];
};

export type PrescriptionOrderAssignVendorsResult = {
  __typename?: 'PrescriptionOrderAssignVendorsResult';
  orderErrors?: Maybe<Array<H_EntityError>>;
  orders?: Maybe<Array<Maybe<Order>>>;
  success: Scalars['Boolean']['output'];
};

export enum PrescriptionSortField {
  Clinician = 'CLINICIAN',
  Created = 'CREATED',
  PatientIdentifier = 'PATIENT_IDENTIFIER',
  ProviderLicense = 'PROVIDER_LICENSE',
  ReferenceNumber = 'REFERENCE_NUMBER'
}

export type PrescriptionSortingInput = {
  direction: OrderDirection;
  field: PrescriptionSortField;
};

export enum PriceRangeEnum {
  Average = 'AVERAGE',
  Cheap = 'CHEAP',
  Expensive = 'EXPENSIVE',
  VeryCheap = 'VERY_CHEAP',
  VeryExpensive = 'VERY_EXPENSIVE'
}

export type PriorAuthorizationActivityInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  denialCode?: InputMaybe<Scalars['String']['input']>;
  denialDescription?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['Int']['input']>;
};

export type PriorAuthorizationInput = {
  activities?: InputMaybe<Array<InputMaybe<PriorAuthorizationActivityInput>>>;
  comments?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};

export enum Priority {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export type ProcedureAgeValidationInput = {
  dateOfBirth: Scalars['String']['input'];
  itemCode: Scalars['String']['input'];
  sequence: Scalars['String']['input'];
};

export enum ProcessingStatus {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  Published = 'PUBLISHED',
  Rejected = 'REJECTED',
  UnderReview = 'UNDER_REVIEW'
}

export type ProductOrServiceInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export enum ProfileType {
  Doctor = 'DOCTOR',
  Patient = 'PATIENT',
  Vendor = 'VENDOR'
}

export type ProviderGuidedCareHealthProgramTeam = H_Node & {
  __typename?: 'ProviderGuidedCareHealthProgramTeam';
  appointments?: Maybe<Array<Maybe<Appointment>>>;
  branch: Branch;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  guidedCareHealthProgram: GuidedCareHealthProgram;
  id: Scalars['ID']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isPatientAlreadyEnrolled?: Maybe<Scalars['Boolean']['output']>;
  lastModifiedDate?: Maybe<Scalars['Instant']['output']>;
  maxNumberOfPatients?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  numberOfEnrolledPatients?: Maybe<Scalars['Int']['output']>;
  provider: Vendor;
  teamEnrolledPatients?: Maybe<Array<Maybe<HealthProgramMember>>>;
  teamMembers?: Maybe<Array<Maybe<ProviderGuidedCareProgramTeamMember>>>;
  teamStatus?: Maybe<ProviderGuidedCareTeamStatus>;
  visits?: Maybe<Array<Maybe<Visit>>>;
};

export type ProviderGuidedCareHealthProgramTeamCrud = {
  __typename?: 'ProviderGuidedCareHealthProgramTeamCRUD';
  providerGuidedCareHealthProgramTeam?: Maybe<ProviderGuidedCareHealthProgramTeam>;
  providerGuidedCareHealthProgramTeamErrors?: Maybe<Array<H_EntityError>>;
};

export type ProviderGuidedCareHealthProgramTeamCountableConnection = {
  __typename?: 'ProviderGuidedCareHealthProgramTeamCountableConnection';
  edges: Array<ProviderGuidedCareHealthProgramTeamCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type ProviderGuidedCareHealthProgramTeamCountableEdge = {
  __typename?: 'ProviderGuidedCareHealthProgramTeamCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ProviderGuidedCareHealthProgramTeam;
};

export type ProviderGuidedCareHealthProgramTeamFilterInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  doctorIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  guidedCareHealthProgramId?: InputMaybe<Scalars['ID']['input']>;
  guidedCareType?: InputMaybe<GuidedCareType>;
  healthProgramMembers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isProgramLatestVersion?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  patientUsers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  patients?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  patientsNotIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  payerId?: InputMaybe<Scalars['ID']['input']>;
  providerId?: InputMaybe<Scalars['ID']['input']>;
  teamLeaderDoctorUserId?: InputMaybe<Scalars['ID']['input']>;
};

export type ProviderGuidedCareHealthProgramTeamInput = {
  branchId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
  guidedCareHealthProgram: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  isActive: Scalars['Boolean']['input'];
  maxNumberOfPatients: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  providerId: Scalars['ID']['input'];
  teamMembers?: InputMaybe<Array<InputMaybe<ProviderGuidedCareProgramTeamMemberInput>>>;
};

export type ProviderGuidedCareHealthProgramTeamPayerResponseInput = {
  isResponseApprove?: InputMaybe<Scalars['Boolean']['input']>;
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export enum ProviderGuidedCareHealthProgramTeamSortingField {
  Created = 'CREATED',
  Modified = 'MODIFIED',
  Name = 'NAME',
  Status = 'STATUS'
}

export type ProviderGuidedCareHealthProgramTeamSortingInput = {
  /** Specifies the direction in which to sort Guided Care Journeys. */
  direction: H_OrderDirection;
  /** Sort journeys by the selected field. */
  field: ProviderGuidedCareHealthProgramTeamSortingField;
};

export type ProviderGuidedCareProgramTeamMember = H_Node & {
  __typename?: 'ProviderGuidedCareProgramTeamMember';
  doctor?: Maybe<Doctor>;
  guidedCareProgramTeamMember?: Maybe<GuidedCareProgramTeamMember>;
  id: Scalars['ID']['output'];
  isBackup?: Maybe<Scalars['Boolean']['output']>;
  providerGuidedCareHealthProgramTeam?: Maybe<ProviderGuidedCareHealthProgramTeam>;
  user?: Maybe<User>;
};

export type ProviderGuidedCareProgramTeamMemberInput = {
  guidedCareProgramTeamMember: GuidedCareProgramTeamMemberIdInput;
  id?: InputMaybe<Scalars['ID']['input']>;
  isBackup?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['ID']['input'];
};

export enum ProviderGuidedCareTeamStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type ProviderSettings = H_Node & {
  __typename?: 'ProviderSettings';
  appointmentConfirmationTimeoutSeconds?: Maybe<Scalars['Int']['output']>;
  appointmentReminderTimeoutSeconds?: Maybe<Scalars['Int']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  id: Scalars['ID']['output'];
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['Instant']['output']>;
  outPatientJourneySupported?: Maybe<Scalars['Boolean']['output']>;
  paymentAfterConsultation?: Maybe<Scalars['Boolean']['output']>;
  vendor?: Maybe<Vendor>;
};

export type ProviderSettingsCountableConnection = {
  __typename?: 'ProviderSettingsCountableConnection';
  edges: Array<ProviderSettingsCountableEdge>;
  pageInfo: H_PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type ProviderSettingsCountableEdge = {
  __typename?: 'ProviderSettingsCountableEdge';
  cursor: Scalars['String']['output'];
  node: ProviderSettings;
};

export type ProviderSettingsFilterInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  vendors?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type ProviderSettingsInput = {
  appointmentConfirmationTimeoutSeconds?: InputMaybe<Scalars['Int']['input']>;
  appointmentReminderTimeoutSeconds?: InputMaybe<Scalars['Int']['input']>;
  outPatientJourneySupported?: InputMaybe<Scalars['Boolean']['input']>;
  paymentAfterConsultation?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Psychologist = Node & {
  __typename?: 'Psychologist';
  created: Scalars['DateTime']['output'];
  healthLicenseEndDate?: Maybe<Scalars['Date']['output']>;
  healthLicenseNumber?: Maybe<Scalars['String']['output']>;
  healthLicenseStartDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type PsychologistInput = {
  healthLicenseEndDate?: InputMaybe<Scalars['Date']['input']>;
  healthLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  healthLicenseStartDate?: InputMaybe<Scalars['Date']['input']>;
};

export type PublishCriteria = {
  __typename?: 'PublishCriteria';
  answeredParticipantsCount?: Maybe<Scalars['Int']['output']>;
  chronicDiseaseCode?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']['output']>;
  errors?: Maybe<Array<Maybe<SurveyGraphqlError>>>;
  expirationDate?: Maybe<Scalars['Date']['output']>;
  fromAge?: Maybe<Scalars['Int']['output']>;
  gender?: Maybe<SurveyGender>;
  id?: Maybe<Scalars['ID']['output']>;
  maxParticipants?: Maybe<Scalars['Int']['output']>;
  participantsCount?: Maybe<Scalars['Int']['output']>;
  toAge?: Maybe<Scalars['Int']['output']>;
};

export type PublishCriteriaInput = {
  chronicDiseaseCode?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  expirationDate: Scalars['Date']['input'];
  fromAge?: InputMaybe<Scalars['Int']['input']>;
  gender?: InputMaybe<SurveyGender>;
  maxParticipants: Scalars['Int']['input'];
  toAge?: InputMaybe<Scalars['Int']['input']>;
};

export type Qualification = Node & {
  __typename?: 'Qualification';
  code: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  doctor?: Maybe<Doctor>;
  fromDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  issuer: Scalars['String']['output'];
  modified: Scalars['DateTime']['output'];
  toDate?: Maybe<Scalars['Date']['output']>;
};

export type QualificationInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  fromDate?: InputMaybe<Scalars['Date']['input']>;
  issuer?: InputMaybe<Scalars['String']['input']>;
  toDate?: InputMaybe<Scalars['Date']['input']>;
};

export type Query = {
  __typename?: 'Query';
  PatientHealthDocument?: Maybe<PatientHealthDocument>;
  PatientHealthDocuments?: Maybe<PatientHealthDocumentCountableConnection>;
  TemplateActivityEventItems?: Maybe<TemplateActivityEventItemCountableConnection>;
  activeAdminPromotions?: Maybe<AdminPromotionConnection>;
  address?: Maybe<Address>;
  adminPromotion?: Maybe<AdminPromotion>;
  adminPromotions?: Maybe<AdminPromotionConnection>;
  anonymousChatGroups?: Maybe<AnonymousChatGroupCountableConnection>;
  anxietyScreening?: Maybe<AnxietyScreeningResponse>;
  /** Look up a Appointment by ID. */
  appointment?: Maybe<Appointment>;
  appointmentNotificationSettings?: Maybe<AppointmentNotificationSettingCountableConnection>;
  appointmentStatusHistories?: Maybe<AppointmentStatusHistoryCountableConnection>;
  /** List of appointments */
  appointments?: Maybe<AppointmentCountableConnection>;
  article?: Maybe<Article>;
  articleCategories?: Maybe<ArticleCategoryCountableConnection>;
  articleCategory?: Maybe<ArticleCategory>;
  articles?: Maybe<ArticleCountableConnection>;
  auditLogsForHealthProgramModule?: Maybe<AuditLogCountableConnection>;
  auditLogsForPaymentModule?: Maybe<AuditLogCountableConnection>;
  /** needed to send the values of (doctorId, start, end) or the value of appointmentId */
  availableTimeSlots?: Maybe<Array<Maybe<TimeSlot>>>;
  beeAndtdee?: Maybe<BeeAndTdeeResponse>;
  block?: Maybe<Block>;
  blocks?: Maybe<BlockCountableConnection>;
  bodyFatPercentage?: Maybe<BodyFatPercentageResponse>;
  branch?: Maybe<Branch>;
  branches?: Maybe<BranchCountableConnection>;
  bulkFiles?: Maybe<BulkFileConnection>;
  bulkTemplates?: Maybe<Array<Maybe<BulkTemplate>>>;
  callbackRequest?: Maybe<CallbackRequest>;
  callbackRequestUpdateHistory: CallbackRequestUpdateHistoryCountableConnection;
  callbackRequests: CallbackRequestCountableConnection;
  cancellationReasons?: Maybe<Array<Maybe<H_CancelReason>>>;
  careJourneyStatistics?: Maybe<CareJourneyStatistics>;
  chat?: Maybe<Chat>;
  chatFlow?: Maybe<ChatFlow>;
  chatFlows?: Maybe<ChatFlowConnection>;
  chatGroup?: Maybe<ChatGroup>;
  chatGroups?: Maybe<ChatGroupConnection>;
  chats?: Maybe<ChatConnection>;
  cities?: Maybe<CityCountableConnection>;
  city?: Maybe<City>;
  codeSystemConceptAutoCompleteSearch?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  codeSystemConceptSearch?: Maybe<CodeSystemConceptConnection>;
  consumerViewPreference?: Maybe<ConsumerViewPreference>;
  containsControlledDrugs?: Maybe<Scalars['Boolean']['output']>;
  countries?: Maybe<Array<Maybe<CountryDisplay>>>;
  customer?: Maybe<User>;
  customers?: Maybe<UserCountableConnection>;
  customersDashboard?: Maybe<Array<Maybe<Aggregation>>>;
  depressionScreening?: Maybe<DepressionScreeningResponse>;
  diabetesRisk?: Maybe<DiabetesRiskResponse>;
  division?: Maybe<Division>;
  divisions?: Maybe<DivisionConnection>;
  doctor?: Maybe<Doctor>;
  doctorCallsRatings: DoctorCallsRatingsConnection;
  /** must be called by vendor nurse user or vendor doctor */
  doctorNurseAssignments?: Maybe<DoctorNurseAssignmentCountableConnection>;
  doctorTeamEnrolledMembers?: Maybe<HealthProgramMemberCountableConnection>;
  doctorVisitHealthProgramTracking: DoctorHealthProgramTotalPointsTrackingConnection;
  doctorVisitRejectionAudits: DoctorVisitRejectionAuditCountableConnection;
  doctors?: Maybe<DoctorCountableConnection>;
  doctorsAvailabilitiesSum?: Maybe<Array<Maybe<DoctorAvailabilitiesSum>>>;
  documentStore?: Maybe<DocumentStore>;
  documentStores?: Maybe<DocumentStoreConnection>;
  downloadBulkFile?: Maybe<Scalars['String']['output']>;
  downloadCodeSystemFile?: Maybe<Scalars['String']['output']>;
  downloadSubList?: Maybe<Scalars['String']['output']>;
  featureFlag?: Maybe<FeatureFlag>;
  framinghamRisk?: Maybe<FraminghamRiskResponse>;
  generateAppointmentChatLinks?: Maybe<AppointmentChatLinkInfo>;
  getAllListFields?: Maybe<Array<Maybe<Field>>>;
  getAllowedDeliveryTypes?: Maybe<Array<Maybe<GenericDrugDeliveryEligibility>>>;
  getClinician?: Maybe<Clinician>;
  getClinicianCredentials?: Maybe<ClinicianCredentials>;
  getCodeSystem?: Maybe<CodeSystem>;
  getCodeSystemConcept?: Maybe<CodeSystemConcept>;
  getCodeSystemConcepts?: Maybe<CodeSystemConceptConnection>;
  getCodeSystemConceptsByCode?: Maybe<CodeSystemConceptConnection>;
  getCodeSystemDefinition?: Maybe<CodeSystemDefinition>;
  getCodeSystemDefinitionById?: Maybe<CodeSystemDefinition>;
  getCodeSystemDefinitionsSummery?: Maybe<Array<Maybe<CodeSystemSummery>>>;
  getCodeSystems?: Maybe<CodeSystemConnection>;
  getDecision?: Maybe<Decision>;
  getDecisions?: Maybe<DecisionConnection>;
  getGenericDrugs?: Maybe<Array<Maybe<GenericDrug>>>;
  getHISDoctorAppointments?: Maybe<Array<Maybe<DoctorAppointment>>>;
  getHISDoctorAvailableTimeSlots?: Maybe<Array<Maybe<TimeSlot>>>;
  getHealthCondition?: Maybe<HealthCondition>;
  getHealthConditionDiagnoses?: Maybe<HealthConditionDiagnosisConnection>;
  getHealthConditions?: Maybe<HealthConditioneConnection>;
  getHealthParameter?: Maybe<HealthParameter>;
  getHealthParameterCategories?: Maybe<FieldCategoryConnection>;
  getHealthParameterUnits?: Maybe<Array<Maybe<FieldUnit>>>;
  getHealthParameters?: Maybe<HealthParameterConnection>;
  getLabTemplate?: Maybe<LabTemplate>;
  getLabTemplates?: Maybe<LabTemplateConnection>;
  getLastMeetingRunEvent?: Maybe<MeetingEvent>;
  getLatestBodyComposition: BodyComposition;
  getMeetingZoomSessionDetails?: Maybe<Scalars['String']['output']>;
  getMeetingZoomSessionUsers?: Maybe<Scalars['String']['output']>;
  getOrCreateChatGroupByParticipants?: Maybe<ChatGroup>;
  getPayment?: Maybe<PaymentResponse>;
  getPaymentCard?: Maybe<UserCard>;
  getPaymentCards?: Maybe<UserCardConnection>;
  getPharmacyCredentials?: Maybe<PharmacyCredentials>;
  getPlan?: Maybe<DecisionPlan>;
  getPlans?: Maybe<DecisionPlanConnection>;
  getReferralById?: Maybe<PatientReferral>;
  getRejectionReasons?: Maybe<Array<Maybe<RejectionReason>>>;
  getRiskFactorTemplate?: Maybe<RiskFactorTemplate>;
  getRiskFactorTemplates?: Maybe<RiskFactorTemplateConnection>;
  getSpecialties?: Maybe<SpecialityConnection>;
  getSubList?: Maybe<SubList>;
  getSubListItems?: Maybe<SubListItemConnection>;
  getSubLists?: Maybe<SubListConnection>;
  getTradeDrugQuantity?: Maybe<Array<Maybe<MedicationTradeDrugs>>>;
  getTradeDrugs?: Maybe<Array<Maybe<TradeDrug>>>;
  getTransaction?: Maybe<TransactionReport>;
  getVisitReferrals?: Maybe<PatientReferralConnection>;
  goalWeight?: Maybe<GoalWeightResponse>;
  groupCandidateUsers?: Maybe<UserCountableConnection>;
  guidedCareHealthProgram?: Maybe<GuidedCareHealthProgram>;
  guidedCareHealthProgramActivity?: Maybe<GuidedCareHealthProgramActivity>;
  guidedCareHealthPrograms?: Maybe<GuidedCareHealthProgramCountableConnection>;
  /** Look up a Guided Care Journey by ID. */
  guidedCareJourney?: Maybe<GuidedCareJourney>;
  guidedCareJourneyItem?: Maybe<GuidedCareJourneyItem>;
  guidedCareJourneyItems?: Maybe<GuidedCareJourneyItemCountableConnection>;
  /** List of Guided Care Journeys */
  guidedCareJourneys?: Maybe<GuidedCareJourneyCountableConnection>;
  /** Look up GuidedCareTask by ID. */
  guidedCareTask?: Maybe<GuidedCareTask>;
  /** List of GuidedCareTasks */
  guidedCareTasks?: Maybe<GuidedCareTaskCountableConnection>;
  guidedCareTeamChatGroups?: Maybe<Array<AnonymousChatGroup>>;
  hasNonFullyCoveredJourney?: Maybe<Scalars['Boolean']['output']>;
  healthChannel?: Maybe<HealthChannel>;
  healthChannelCategories?: Maybe<HealthChannelCategoryCountableConnection>;
  healthChannelCategory?: Maybe<HealthChannelCategory>;
  healthChannelFollowers: HealthChannelFollowerCountableConnection;
  healthChannels?: Maybe<HealthChannelCountableConnection>;
  healthCheckForHealthProgram?: Maybe<Scalars['String']['output']>;
  healthCheckForPayment?: Maybe<Scalars['String']['output']>;
  /** Look up HealthProgram by ID. */
  healthProgram?: Maybe<HealthProgram>;
  /** Look up BenefitOption by ID. */
  healthProgramBenefitGroupOption?: Maybe<BenefitOption>;
  healthProgramBenefitGroupOptions?: Maybe<Array<Maybe<BenefitOption>>>;
  healthProgramBenefitGroups?: Maybe<Array<Maybe<BenefitGroup>>>;
  healthProgramCallsForVendor: Array<Maybe<HealthProgramCallsForVendorResponse>>;
  healthProgramCallsPerVendor: HealthProgramCallsPerVendorResponseConnection;
  healthProgramCareForFields?: Maybe<Array<Maybe<HealthProgramCareForFields>>>;
  /** Look up ExclusionOption by ID. */
  healthProgramExclusionOption?: Maybe<ExclusionOption>;
  healthProgramExclusionOptions?: Maybe<Array<Maybe<ExclusionOption>>>;
  healthProgramExtraBenefits?: Maybe<Array<Maybe<ExtraBenefit>>>;
  healthProgramMember?: Maybe<HealthProgramMember>;
  healthProgramMemberLists?: Maybe<HealthProgramMemberListCountableConnection>;
  healthProgramMembers?: Maybe<HealthProgramMemberCountableConnection>;
  healthProgramMembershipRequest?: Maybe<HealthProgramMembershipRequest>;
  healthProgramMembershipRequests: HealthProgramMembershipRequestConnection;
  healthProgramNetworkProviderProfessionals?: Maybe<HealthProgramNetworkProviderProfessionalCountableConnection>;
  healthProgramNetworkProviders?: Maybe<HealthProgramNetworkProviderCountableConnection>;
  healthProgramNetworks?: Maybe<HealthProgramNetworkCountableConnection>;
  healthProgramTemplate?: Maybe<HealthProgramTemplate>;
  healthProgramTemplates?: Maybe<HealthProgramTemplateCountableConnection>;
  healthProgramTotalPointsReport: HealthProgramTotalPointsTrackingConnection;
  healthProgramUserSubscriptions?: Maybe<HealthProgramUserSubscriptionCountableConnection>;
  healthProgramVendorTotalPointsReport: HealthProgramVendorTotalPointsTrackingConnection;
  healthPrograms?: Maybe<HealthProgramCountableConnection>;
  healthSymptom?: Maybe<HealthSymptom>;
  healthSymptoms?: Maybe<HealthSymptomCountableConnection>;
  idealBodyWeight?: Maybe<IdealBodyWeightResponse>;
  insuranceNetwork?: Maybe<InsuranceNetwork>;
  insuranceNetworkFacilities?: Maybe<InsuranceNetworkFacilityCountableConnection>;
  insuranceNetworks?: Maybe<InsuranceNetworkCountableConnection>;
  keyCloakPermissions?: Maybe<Array<Maybe<Permission>>>;
  lab?: Maybe<Lab>;
  language?: Maybe<Language>;
  languages?: Maybe<LanguageCountableConnection>;
  latestCodeSystemVersion?: Maybe<Scalars['Int']['output']>;
  lifeExpectancy?: Maybe<LifeExpectancyResponse>;
  marketplaceBrand?: Maybe<MarketplaceBrand>;
  marketplaceBrands?: Maybe<MarketplaceBrandConnection>;
  marketplaceCart?: Maybe<MarketplaceCart>;
  marketplaceDiscount?: Maybe<MarketplaceDiscount>;
  marketplaceDiscounts?: Maybe<MarketplaceDiscountConnection>;
  marketplaceHealthPackage?: Maybe<MarketplaceHealthPackage>;
  marketplaceHealthPackageBestSellers?: Maybe<MarketplaceHealthPackageConnection>;
  marketplaceHealthPackageCategories?: Maybe<MarketplaceHealthPackageCategoryConnection>;
  marketplaceHealthPackageCategory?: Maybe<MarketplaceHealthPackageCategory>;
  marketplaceHealthPackages?: Maybe<MarketplaceHealthPackageConnection>;
  marketplaceHealthPackagesForYou?: Maybe<MarketplaceHealthPackageConnection>;
  marketplaceOrder?: Maybe<MarketplaceOrder>;
  marketplaceOrders?: Maybe<MarketplaceOrderConnection>;
  marketplaceProduct?: Maybe<MarketplaceProduct>;
  marketplaceProductBestSellers?: Maybe<MarketplaceProductConnection>;
  marketplaceProductCategories?: Maybe<MarketplaceProductCategoryConnection>;
  marketplaceProductCategory?: Maybe<MarketplaceProductCategory>;
  marketplaceProducts?: Maybe<MarketplaceProductConnection>;
  marketplaceProductsForYou?: Maybe<MarketplaceProductConnection>;
  marketplacePromotion?: Maybe<MarketplacePromotion>;
  marketplacePromotions?: Maybe<MarketplacePromotionConnection>;
  marketplaceReviews?: Maybe<MarketplaceReviewConnection>;
  marketplaceVATPercentage?: Maybe<Scalars['Double']['output']>;
  marketplaceWishlist?: Maybe<MarketplaceWishlist>;
  me?: Maybe<User>;
  medicalForm?: Maybe<MedicalForm>;
  medicalFormAnswers?: Maybe<MedicalFormAnswerConnection>;
  medicalForms?: Maybe<MedicalFormConnection>;
  /** Look up a Medical Message by ID. */
  medicalMessage?: Maybe<MedicalMessage>;
  /** Look up a Medical Message by Unique Message Code. */
  medicalMessageByCode?: Maybe<MedicalMessage>;
  /** Look up a Medical Message Variant by ID. */
  medicalMessageVariant?: Maybe<MedicalMessageVariant>;
  /** List of Medical Message Variants */
  medicalMessageVariants?: Maybe<MedicalMessageVariantCountableConnection>;
  /** List of Medical Messages */
  medicalMessages?: Maybe<MedicalMessageCountableConnection>;
  medlistUsers?: Maybe<UserCountableConnection>;
  meeting?: Maybe<Meeting>;
  optimaAction?: Maybe<OptimaAction>;
  optimaActions?: Maybe<OptimaActionConnection>;
  optimaClaim?: Maybe<TransactionClaim>;
  optimaClaimActivities?: Maybe<TransactionClaimActivityConnection>;
  optimaClaims?: Maybe<TransactionClaimConnection>;
  optimaEdit?: Maybe<OptimaEdit>;
  optimaEdits?: Maybe<OptimaEditConnection>;
  optimaPriorAuthorization?: Maybe<TransactionPriorAuthorization>;
  optimaPriorAuthorizationActivities?: Maybe<TransactionPriorAuthorizationActivityConnection>;
  optimaPriorAuthorizations?: Maybe<TransactionPriorAuthorizationConnection>;
  optimaPriorRequest?: Maybe<TransactionPriorRequest>;
  optimaPriorRequestActivities?: Maybe<TransactionPriorRequestActivityConnection>;
  optimaPriorRequests?: Maybe<TransactionPriorRequestConnection>;
  optimaPriorRequestsRejectedAuth?: Maybe<TransactionPriorRequestConnection>;
  optimaPriorRequestsWithoutClaim?: Maybe<TransactionPriorRequestConnection>;
  optimaRemittance?: Maybe<TransactionRemittance>;
  optimaRemittanceActivities?: Maybe<TransactionRemittanceActivityConnection>;
  optimaRemittances?: Maybe<TransactionRemittanceConnection>;
  optimaValidationRequest?: Maybe<OptimaValidationRequest>;
  optimaValidationRequests?: Maybe<OptimaValidationRequestsConnection>;
  order?: Maybe<Order>;
  orders?: Maybe<OrderCountableConnection>;
  outPatientJourneyStatusHistories?: Maybe<OutPatientJourneyStatusHistoryCountableConnection>;
  outPatientJourneys?: Maybe<OutPatientJourneyCountableConnection>;
  patient?: Maybe<Patient>;
  patientEligibilityRequestHistories?: Maybe<PatientEligibilityRequestHistoryCountableConnection>;
  patientEligibilityRequestHistory?: Maybe<PatientEligibilityRequestHistory>;
  patientEligibleTeams?: Maybe<ProviderGuidedCareHealthProgramTeamCountableConnection>;
  patientEnrollmentRequests?: Maybe<PatientEnrollmentRequestCountableConnection>;
  /** Look up a Patient Health Parameter by ID. */
  patientHealthParameter?: Maybe<PatientHealthParameter>;
  patientHealthParameterVitalSignsChangePercentage?: Maybe<Array<Maybe<PatientHpVitalSignsChangePercentage>>>;
  /** List of Patient Health Parameters */
  patientHealthParameters?: Maybe<PatientHealthParameterCountableConnection>;
  patientIdByConsentCode?: Maybe<Patient>;
  patientMedicalProfile?: Maybe<PatientMedicalProfile>;
  patientProfileActiveConsentForms: PatientProfileConsentFormConnection;
  patientProfileConsentForm?: Maybe<PatientProfileConsentForm>;
  patientProfileConsentFormCheck: Scalars['Boolean']['output'];
  patientProfilePendingConsentForms: PatientProfileConsentFormConnection;
  patientShare?: Maybe<PatientShare>;
  patientShareForAppointment?: Maybe<PatientShare>;
  patients?: Maybe<PatientCountableConnection>;
  payer?: Maybe<Payer>;
  payerBankList?: Maybe<Array<Maybe<PayerBankListResponse>>>;
  payerBenefitList?: Maybe<Array<Maybe<PayerBenefitListResponse>>>;
  payerCityList?: Maybe<Array<Maybe<PayerCityListResponse>>>;
  payerClaim?: Maybe<PayerClaimDetailsResponse>;
  /**  Payer Queries  */
  payerClaims?: Maybe<Array<Maybe<PayerClaimListResponse>>>;
  payerClinicianDetails?: Maybe<PayerClinicianDetailsResponse>;
  payerClinicianList?: Maybe<Array<Maybe<PayerClinicianListResponse>>>;
  payerClinicianSearch?: Maybe<Array<Maybe<PayerClinicianSearchResponse>>>;
  payerCountries?: Maybe<Array<Maybe<PayerCountryResponse>>>;
  payerCurrencies?: Maybe<Array<Maybe<PayerCurrencyResponse>>>;
  payerLanguages?: Maybe<Array<Maybe<PayerLanguageResponse>>>;
  payerMemberBankDetails?: Maybe<Array<Maybe<PayerMemberBankDetailsResponse>>>;
  payerPreApprovals?: Maybe<Array<Maybe<PayerPreApprovalListResponse>>>;
  payerProviderDetails?: Maybe<PayerProviderDetailsResponse>;
  payerProviderFilterList?: Maybe<Array<Maybe<PayerProviderListResponse>>>;
  payerProviderList?: Maybe<Array<Maybe<PayerProviderListResponse>>>;
  payerProviderNearByList?: Maybe<Array<Maybe<PayerProviderNearByListResponse>>>;
  payerProviderTypeList?: Maybe<Array<Maybe<PayerProviderTypeListResponse>>>;
  payerSpecialtyList?: Maybe<Array<Maybe<PayerSpecialtyListResponse>>>;
  payers?: Maybe<PayerCountableConnection>;
  /** can be called by any of (patient, vendor) user only */
  paymentInvoices?: Maybe<PaymentInvoiceCountableConnection>;
  paymentReceipts: PaymentReceiptCountableConnection;
  permissionGroup?: Maybe<Group>;
  permissionGroups?: Maybe<GroupCountableConnection>;
  permissions: Array<Maybe<Permission>>;
  plan?: Maybe<Plan>;
  planPeriods?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  plans?: Maybe<PlanCountableConnection>;
  prescription?: Maybe<Prescription>;
  prescriptions?: Maybe<PrescriptionCountableConnection>;
  providerGuidedCareHealthProgramTeam?: Maybe<ProviderGuidedCareHealthProgramTeam>;
  providerGuidedCareHealthProgramTeams?: Maybe<ProviderGuidedCareHealthProgramTeamCountableConnection>;
  providerSettings?: Maybe<ProviderSettingsCountableConnection>;
  question?: Maybe<Question>;
  questionAnswers?: Maybe<QuestionAnswerConnection>;
  rad?: Maybe<Rad>;
  readArticlesCount?: Maybe<Scalars['Int']['output']>;
  rejectionReasons?: Maybe<Array<Maybe<H_RejectionReason>>>;
  reviewQuestions?: Maybe<Array<Maybe<ReviewQuestion>>>;
  siteSettings: SiteSettings;
  sleep?: Maybe<SleepResponse>;
  survey?: Maybe<Survey>;
  surveyStatistics?: Maybe<SurveyStatistics>;
  surveys?: Maybe<SurveyConnection>;
  /** Look up TaskDefinition by ID. */
  taskDefinition?: Maybe<TaskDefinition>;
  taskDefinitions?: Maybe<TaskDefinitionCountableConnection>;
  teamEnrolledMembers?: Maybe<HealthProgramMemberCountableConnection>;
  teamEnrolledPatients?: Maybe<PatientMinimalCountableConnection>;
  teamLeaderDoctors?: Maybe<TypeProviderGuidedCareProgramTeamMemberCountableConnection>;
  templateActivity?: Maybe<TemplateActivity>;
  templateInterventionActivity?: Maybe<TemplateInterventionActivity>;
  templateInterventions?: Maybe<HealthProgramTemplateInterventionCountableConnection>;
  templateRecommendations?: Maybe<TemplateRecommendationCountableConnection>;
  trackerReminder?: Maybe<TrackerReminder>;
  trackerReminderSetting?: Maybe<TrackerReminderSettings>;
  trackerReminderSettings?: Maybe<TrackerReminderSettingsConnection>;
  trackerReminders?: Maybe<TrackerReminderConnection>;
  trackerSupplementType?: Maybe<TrackerSupplementType>;
  trackerSupplementTypes?: Maybe<TrackerSupplementTypeConnection>;
  trackerTradeDrugType?: Maybe<TrackerTradeDrugType>;
  trackerTradeDrugTypes?: Maybe<TrackerTradeDrugTypeConnection>;
  transcripts?: Maybe<TranscriptConnection>;
  unverifiedNationals?: Maybe<UnverifiedNationalsCountableConnection>;
  user?: Maybe<User>;
  userManagementSiteSettings?: Maybe<UserManagementSiteSettings>;
  users?: Maybe<UserCountableConnection>;
  vendor?: Maybe<Vendor>;
  vendorDepartment?: Maybe<Department>;
  vendorDepartments?: Maybe<DepartmentConnection>;
  vendorSubscription?: Maybe<VendorSubscription>;
  vendorSubscriptions?: Maybe<VendorSubscriptionCountableConnection>;
  vendors?: Maybe<VendorCountableConnection>;
  /** Look up HealthProgram by ID. */
  visit?: Maybe<Visit>;
  visitComplains: VisitComplainCountableConnection;
  visitDiagnosisRiskTemplate?: Maybe<VisitDiagnosisRiskTemplate>;
  visitDiagnosisRiskTemplates?: Maybe<VisitDiagnosisRiskTemplateCountableConnection>;
  visitMessages?: Maybe<VisitMessageCountableConnection>;
  visitPrescriptions?: Maybe<VisitPrescriptionTransactionCountableConnection>;
  visitReviewQuestions?: Maybe<VisitReviewQuestionCountableConnection>;
  visitStatusHistories?: Maybe<VisitStatusHistoryCountableConnection>;
  visitSummaries: VisitSummaryCountableConnection;
  visitSummary?: Maybe<VisitSummary>;
  /** all accessible visits for the requester */
  visits?: Maybe<VisitCountableConnection>;
};


export type QueryPatientHealthDocumentArgs = {
  id: Scalars['ID']['input'];
  patientId: Scalars['ID']['input'];
};


export type QueryPatientHealthDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  patientId: Scalars['ID']['input'];
  type?: InputMaybe<HealthDocumentType>;
};


export type QueryTemplateActivityEventItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter: TemplateActivityEventItemFilterInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<TemplateActivityEventItemSortingInput>;
};


export type QueryActiveAdminPromotionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<AdminPromotionSortingInput>;
};


export type QueryAddressArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAdminPromotionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAdminPromotionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AdminPromotionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<AdminPromotionSortingInput>;
};


export type QueryAnonymousChatGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAnxietyScreeningArgs = {
  input: AnxietyScreeningInput;
  saveData?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryAppointmentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryAppointmentNotificationSettingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AppointmentNotificationSettingFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAppointmentStatusHistoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AppointmentStatusHistoryFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AppointmentFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<AppointmentSortingInput>;
};


export type QueryArticleArgs = {
  id: Scalars['ID']['input'];
};


export type QueryArticleCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ArticleCategoryFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<ArticleCategorySortingInput>;
};


export type QueryArticleCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryArticlesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ArticleFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<ArticleSortingInput>;
};


export type QueryAuditLogsForHealthProgramModuleArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AuditLogFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAuditLogsForPaymentModuleArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AuditLogFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryAvailableTimeSlotsArgs = {
  doctorId?: InputMaybe<Scalars['ID']['input']>;
  end?: InputMaybe<Scalars['Instant']['input']>;
  start?: InputMaybe<Scalars['Instant']['input']>;
};


export type QueryBeeAndtdeeArgs = {
  input: BeeAndTdeeInput;
  saveData?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryBlockArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBlocksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<BlockFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<BlockOrder>;
};


export type QueryBodyFatPercentageArgs = {
  input: BodyFatPercentageInput;
  saveData?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryBranchArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBranchesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<BranchFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<BranchOrder>;
  userLocation?: InputMaybe<LocationInput>;
};


export type QueryBulkFilesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<BulkFilesFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<BulkFilesSortingInput>;
};


export type QueryCallbackRequestArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCallbackRequestUpdateHistoryArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  callbackRequestId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCallbackRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CallbackRequestFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCancellationReasonsArgs = {
  filter?: InputMaybe<ReasonFilterInput>;
};


export type QueryCareJourneyStatisticsArgs = {
  filter?: InputMaybe<CareJourneyStatisticsFilterInput>;
};


export type QueryChatArgs = {
  id: Scalars['ID']['input'];
};


export type QueryChatFlowArgs = {
  id: Scalars['ID']['input'];
};


export type QueryChatFlowsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ChatFlowFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<ChatFlowSortingInput>;
};


export type QueryChatGroupArgs = {
  id: Scalars['ID']['input'];
};


export type QueryChatGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<ChatGroupSortingInput>;
};


export type QueryChatsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter: ChatFilterInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<ChatSortingInput>;
};


export type QueryCitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CityFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<CityOrder>;
};


export type QueryCityArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCodeSystemConceptAutoCompleteSearchArgs = {
  codeSystemCode?: InputMaybe<CodeSystemCode>;
  codeSystemId?: InputMaybe<Scalars['ID']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  propertyCode: Scalars['String']['input'];
};


export type QueryCodeSystemConceptSearchArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  codeSystemCode?: InputMaybe<CodeSystemCode>;
  codeSystemId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  keyword?: InputMaybe<Scalars['String']['input']>;
  keywordSearch?: InputMaybe<KeywordSearch>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchParameters?: InputMaybe<Array<InputMaybe<SearchParameter>>>;
};


export type QueryConsumerViewPreferenceArgs = {
  view: Scalars['String']['input'];
};


export type QueryContainsControlledDrugsArgs = {
  genericDrugs?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryCustomerArgs = {
  nationalId: Scalars['String']['input'];
};


export type QueryCustomersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  branch?: InputMaybe<Scalars['ID']['input']>;
  filter?: InputMaybe<CustomerFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hasChatWith?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<UserSortingInput>;
};


export type QueryCustomersDashboardArgs = {
  filter?: InputMaybe<CustomerDashboardFilterInput>;
};


export type QueryDepressionScreeningArgs = {
  input: DepressionScreeningInput;
  saveData?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryDiabetesRiskArgs = {
  input: DiabetesRiskInput;
  saveData?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryDivisionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDivisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDoctorArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  ssoId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryDoctorCallsRatingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filterInput?: InputMaybe<DoctorCallsRatingsFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDoctorNurseAssignmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<DoctorNurseAssignmentFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDoctorTeamEnrolledMembersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  doctorId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  teamId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryDoctorVisitHealthProgramTrackingArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<DoctorHealthProgramPointsFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDoctorVisitRejectionAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<DoctorVisitRejectionAuditFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDoctorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<DoctorFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<DoctorOrder>;
};


export type QueryDoctorsAvailabilitiesSumArgs = {
  doctors: Array<InputMaybe<Scalars['ID']['input']>>;
  fromDate: Scalars['Date']['input'];
  toDate: Scalars['Date']['input'];
};


export type QueryDocumentStoreArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDocumentStoresArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDownloadBulkFileArgs = {
  fileId: Scalars['ID']['input'];
};


export type QueryDownloadCodeSystemFileArgs = {
  code: CodeSystemCode;
  codeSystemId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryDownloadSubListArgs = {
  subListId: Scalars['ID']['input'];
};


export type QueryFraminghamRiskArgs = {
  input: FraminghamRiskInput;
  saveData?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGenerateAppointmentChatLinksArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetAllowedDeliveryTypesArgs = {
  genericDrugs: Array<InputMaybe<Scalars['String']['input']>>;
};


export type QueryGetClinicianArgs = {
  nationalId: Scalars['String']['input'];
};


export type QueryGetClinicianCredentialsArgs = {
  destinationType: DestinationType;
  user: Scalars['ID']['input'];
};


export type QueryGetCodeSystemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCodeSystemConceptArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCodeSystemConceptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  codeSystemId?: InputMaybe<Scalars['ID']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchParameters?: InputMaybe<Array<InputMaybe<SearchParameter>>>;
  showOnlyVisibleProperties?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGetCodeSystemConceptsByCodeArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  codeSystemCode?: InputMaybe<CodeSystemCode>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchParameters?: InputMaybe<Array<InputMaybe<SearchParameter>>>;
  showOnlyVisibleProperties?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGetCodeSystemDefinitionArgs = {
  code: CodeSystemCode;
};


export type QueryGetCodeSystemDefinitionByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCodeSystemDefinitionsSummeryArgs = {
  name?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetCodeSystemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<CodeSystemCode>;
  first?: InputMaybe<Scalars['Int']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetDecisionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetDecisionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<DecisionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<DecisionSortingInput>;
};


export type QueryGetGenericDrugsArgs = {
  genericCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryGetHisDoctorAppointmentsArgs = {
  filter: HisAppointmentFilterInput;
};


export type QueryGetHisDoctorAvailableTimeSlotsArgs = {
  filter: HisAppointmentFilterInput;
};


export type QueryGetHealthConditionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetHealthConditionDiagnosesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<HealthConditionDiagnosesFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<HealthConditionDiagnosesSortingInput>;
};


export type QueryGetHealthConditionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<HealthConditionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<HealthConditionSortingInput>;
};


export type QueryGetHealthParameterArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetHealthParameterCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<HealthParameterCategoryFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetHealthParametersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<HealthParameterFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<HealthParameterSortingInput>;
};


export type QueryGetLabTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetLabTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<LabTemplateFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<LabTemplateSortingInput>;
};


export type QueryGetLastMeetingRunEventArgs = {
  meetingId: Scalars['ID']['input'];
};


export type QueryGetLatestBodyCompositionArgs = {
  patientId?: InputMaybe<Scalars['H_Long']['input']>;
};


export type QueryGetMeetingZoomSessionDetailsArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetMeetingZoomSessionUsersArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetOrCreateChatGroupByParticipantsArgs = {
  consumerName?: InputMaybe<Scalars['String']['input']>;
  groupName?: InputMaybe<Scalars['String']['input']>;
  participantUserIds: Array<Scalars['String']['input']>;
  providerGuidedCareHealthProgramTeamLeaderName?: InputMaybe<Scalars['String']['input']>;
  providerGuidedCareHealthProgramTeamMemberName?: InputMaybe<Scalars['String']['input']>;
  providerGuidedCareHealthProgramTeamName?: InputMaybe<Scalars['String']['input']>;
  visitId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetPaymentArgs = {
  paymentId: Scalars['String']['input'];
};


export type QueryGetPaymentCardArgs = {
  cardId: Scalars['ID']['input'];
};


export type QueryGetPaymentCardsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetPharmacyCredentialsArgs = {
  branch: Scalars['ID']['input'];
  destinationType: DestinationType;
};


export type QueryGetPlanArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetPlansArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<DecisionPlanFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetReferralByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetRiskFactorTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetRiskFactorTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<RiskFactorTemplateFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<RiskFactorTemplateSortingInput>;
};


export type QueryGetSpecialtiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SpecialityFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetSubListArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetSubListItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  display?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<SubListItemSortingInput>;
  subListId: Scalars['ID']['input'];
};


export type QueryGetSubListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SublistFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<SubListSortingInput>;
};


export type QueryGetTradeDrugQuantityArgs = {
  prescriptionId: Scalars['ID']['input'];
};


export type QueryGetTradeDrugsArgs = {
  tradeCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryGetTransactionArgs = {
  input: PaymentCheckoutInput;
};


export type QueryGetVisitReferralsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<VisitReferralSortingInput>;
  visit: Scalars['ID']['input'];
};


export type QueryGoalWeightArgs = {
  input: GoalWeightInput;
  saveData?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGroupCandidateUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  appType: AppTypes;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<UserSortingInput>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryGuidedCareHealthProgramArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGuidedCareHealthProgramActivityArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGuidedCareHealthProgramsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<GuidedCareHealthProgramFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<GuidedCareHealthProgramSortingInput>;
};


export type QueryGuidedCareJourneyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGuidedCareJourneyItemArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGuidedCareJourneyItemsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<GuidedCareJourneyItemFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGuidedCareJourneysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<GuidedCareJourneyFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<GuidedCareJourneySortingInput>;
};


export type QueryGuidedCareTaskArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGuidedCareTasksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<GuidedCareTaskFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<GuidedCareTaskSortingInput>;
};


export type QueryGuidedCareTeamChatGroupsArgs = {
  filter?: InputMaybe<AnonymousChatGroupFilterInput>;
  teamId: Scalars['ID']['input'];
};


export type QueryHasNonFullyCoveredJourneyArgs = {
  patientId: Scalars['ID']['input'];
};


export type QueryHealthChannelArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryHealthChannelCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryHealthChannelCategoryArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryHealthChannelFollowersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<HealthChannelFollowerFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryHealthChannelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<HealthChannelFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryHealthProgramArgs = {
  id: Scalars['ID']['input'];
};


export type QueryHealthProgramBenefitGroupOptionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryHealthProgramBenefitGroupOptionsArgs = {
  filter?: InputMaybe<BenefitFilterInput>;
};


export type QueryHealthProgramBenefitGroupsArgs = {
  filter?: InputMaybe<BenefitFilterInput>;
};


export type QueryHealthProgramCallsForVendorArgs = {
  filter?: InputMaybe<CallsForVendorFilter>;
};


export type QueryHealthProgramCallsPerVendorArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<CallsPerVendorFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryHealthProgramExclusionOptionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryHealthProgramExclusionOptionsArgs = {
  filter?: InputMaybe<BenefitFilterInput>;
};


export type QueryHealthProgramExtraBenefitsArgs = {
  filter?: InputMaybe<BenefitFilterInput>;
};


export type QueryHealthProgramMemberArgs = {
  id: Scalars['ID']['input'];
};


export type QueryHealthProgramMemberListsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<HealthProgramMemberListFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<HealthProgramMemberListSortingInput>;
};


export type QueryHealthProgramMembersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<HealthProgramMemberFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<HealthProgramMemberSortingInput>;
};


export type QueryHealthProgramMembershipRequestArgs = {
  id: Scalars['ID']['input'];
};


export type QueryHealthProgramMembershipRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<HealthProgramMembershipRequestFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<HealthProgramMembershipRequestSortingInput>;
};


export type QueryHealthProgramNetworkProviderProfessionalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<HealthProgramNetworkProviderProfessionalFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryHealthProgramNetworkProvidersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<HealthProgramNetworkProviderFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryHealthProgramNetworksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<HealthProgramNetworkFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<HealthProgramNetworkSortingInput>;
};


export type QueryHealthProgramTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryHealthProgramTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<HealthProgramTemplateFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<HealthProgramTemplateSortingInput>;
};


export type QueryHealthProgramTotalPointsReportArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter: HealthProgramPointsFilterInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryHealthProgramUserSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<HealthProgramUserSubscriptionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryHealthProgramVendorTotalPointsReportArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<HealthProgramVendorPointsFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryHealthProgramsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<HealthProgramFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<HealthProgramSortingInput>;
};


export type QueryHealthSymptomArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryHealthSymptomsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<HealthSymptomFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<HealthSymptomOrder>;
};


export type QueryIdealBodyWeightArgs = {
  input: IdealBodyWeightInput;
  saveData?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryInsuranceNetworkArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInsuranceNetworkFacilitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<InsuranceNetworkFacilityFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryInsuranceNetworksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<InsuranceNetworkFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<InsuranceSortingInput>;
};


export type QueryKeyCloakPermissionsArgs = {
  appType: AppTypes;
};


export type QueryLabArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLanguageArgs = {
  id: Scalars['ID']['input'];
};


export type QueryLanguagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<LanguageFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLatestCodeSystemVersionArgs = {
  codeSystemCode: CodeSystemCode;
};


export type QueryLifeExpectancyArgs = {
  input: LifeExpectancyInput;
  saveData?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryMarketplaceBrandArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMarketplaceBrandsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MarketplaceBrandFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<MarketplaceDefaultSortingInput>;
};


export type QueryMarketplaceCartArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryMarketplaceDiscountArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMarketplaceDiscountsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MarketplaceDiscountFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<MarketplaceDiscountSortingInput>;
};


export type QueryMarketplaceHealthPackageArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMarketplaceHealthPackageBestSellersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryMarketplaceHealthPackageCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MarketplaceCategoryFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<MarketplaceDefaultSortingInput>;
};


export type QueryMarketplaceHealthPackageCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMarketplaceHealthPackagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MarketplaceHealthPackageFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<MarketplaceHealthPackageSortingInput>;
};


export type QueryMarketplaceHealthPackagesForYouArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryMarketplaceOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMarketplaceOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MarketplaceOrderFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<MarketplaceOrderSortingInput>;
};


export type QueryMarketplaceProductArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMarketplaceProductBestSellersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryMarketplaceProductCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MarketplaceCategoryFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<MarketplaceDefaultSortingInput>;
};


export type QueryMarketplaceProductCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMarketplaceProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MarketplaceProductFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<MarketplaceProductSortingInput>;
};


export type QueryMarketplaceProductsForYouArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryMarketplacePromotionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMarketplacePromotionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MarketplacePromotionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<MarketplacePromotionSortingInput>;
};


export type QueryMarketplaceReviewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MarketplaceReviewFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMedicalFormArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMedicalFormAnswersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MedicalFormAnswerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryMedicalFormsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MedicalFormFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<MedicalFormSortingInput>;
};


export type QueryMedicalMessageArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMedicalMessageByCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryMedicalMessageVariantArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMedicalMessageVariantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MedicalMessageVariantFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<MedicalMessageVariantSortingInput>;
};


export type QueryMedicalMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MedicalMessageFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<MedicalMessageSortingInput>;
};


export type QueryMedlistUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<UserSortingInput>;
};


export type QueryMeetingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOptimaActionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOptimaActionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OptimaActionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOptimaClaimArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOptimaClaimActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TransactionClaimActivityFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOptimaClaimsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TransactionClaimFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<TransactionClaimSortingInput>;
};


export type QueryOptimaEditArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOptimaEditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OptimaEditFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOptimaPriorAuthorizationArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOptimaPriorAuthorizationActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TransactionPriorAuthorizationActivityFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOptimaPriorAuthorizationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TransactionPriorAuthorizationFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<TransactionDefaultSortingInput>;
};


export type QueryOptimaPriorRequestArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOptimaPriorRequestActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TransactionPriorRequestActivityFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOptimaPriorRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TransactionPriorRequestFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<TransactionDefaultSortingInput>;
};


export type QueryOptimaPriorRequestsRejectedAuthArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  transactionDateInput?: InputMaybe<TransactionDateFilterInput>;
};


export type QueryOptimaPriorRequestsWithoutClaimArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  transactionDateInput?: InputMaybe<TransactionDateFilterInput>;
};


export type QueryOptimaRemittanceArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOptimaRemittanceActivitiesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TransactionRemittanceActivityFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOptimaRemittancesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TransactionRemittanceFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<TransactionDefaultSortingInput>;
};


export type QueryOptimaValidationRequestArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryOptimaValidationRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OptimaValidationRequestFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<OptimaValidationRequestsSortingInput>;
};


export type QueryOrderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OrderFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<OrderSortingInput>;
};


export type QueryOutPatientJourneyStatusHistoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OutPatientJourneyStatusHistoryFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryOutPatientJourneysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  visitIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type QueryPatientArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  nationalId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPatientEligibilityRequestHistoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PatientEligibilityRequestHistoryFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PatientEligibilityRequestHistorySortingInput>;
};


export type QueryPatientEligibilityRequestHistoryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPatientEligibleTeamsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PatientEligibleTeamFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPatientEnrollmentRequestsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PatientEnrollmentRequestFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPatientHealthParameterArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPatientHealthParameterVitalSignsChangePercentageArgs = {
  patientId: Scalars['ID']['input'];
};


export type QueryPatientHealthParametersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PatientHealthParameterFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PatientHealthParameterSortingInput>;
};


export type QueryPatientIdByConsentCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryPatientMedicalProfileArgs = {
  patientId: Scalars['ID']['input'];
};


export type QueryPatientProfileActiveConsentFormsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPatientProfileConsentFormArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPatientProfileConsentFormCheckArgs = {
  patientId: Scalars['ID']['input'];
};


export type QueryPatientProfilePendingConsentFormsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPatientShareArgs = {
  branchId?: InputMaybe<Scalars['ID']['input']>;
  doctorId: Scalars['ID']['input'];
  visitCareType: VisitCareType;
};


export type QueryPatientShareForAppointmentArgs = {
  appointmentId: Scalars['ID']['input'];
};


export type QueryPatientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PatientFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPayerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPayerBankListArgs = {
  input?: InputMaybe<PayerBankListRequestInput>;
};


export type QueryPayerBenefitListArgs = {
  input?: InputMaybe<PayerBenefitListRequestInput>;
};


export type QueryPayerCityListArgs = {
  input: PayerCityListRequestInput;
};


export type QueryPayerClaimArgs = {
  input: PayerClaimDetailsRequestInput;
};


export type QueryPayerClaimsArgs = {
  input: PayerClaimListRequestInput;
};


export type QueryPayerClinicianDetailsArgs = {
  input?: InputMaybe<PayerClinicianDetailsRequestInput>;
};


export type QueryPayerClinicianListArgs = {
  input?: InputMaybe<PayerClinicianListRequestInput>;
};


export type QueryPayerClinicianSearchArgs = {
  input: PayerClinicianSearchRequestInput;
};


export type QueryPayerCountriesArgs = {
  input?: InputMaybe<PayerCountryRequestInput>;
};


export type QueryPayerCurrenciesArgs = {
  input?: InputMaybe<PayerCurrencyRequestInput>;
};


export type QueryPayerLanguagesArgs = {
  input: PayerLanguageRequestInput;
};


export type QueryPayerMemberBankDetailsArgs = {
  input?: InputMaybe<PayerMemberBankDetailsRequestInput>;
};


export type QueryPayerPreApprovalsArgs = {
  input: PayerPreApprovalListRequestInput;
};


export type QueryPayerProviderDetailsArgs = {
  input?: InputMaybe<PayerProviderDetailsRequestInput>;
};


export type QueryPayerProviderFilterListArgs = {
  input: PayerProviderFilterListRequestInput;
};


export type QueryPayerProviderListArgs = {
  input?: InputMaybe<PayerProviderListRequestInput>;
};


export type QueryPayerProviderNearByListArgs = {
  input: PayerProviderNearByListRequestInput;
};


export type QueryPayerProviderTypeListArgs = {
  input: PayerProviderTypeListRequestInput;
};


export type QueryPayerSpecialtyListArgs = {
  input: PayerSpecialtyListRequestInput;
};


export type QueryPayersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PayerFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PayerOrder>;
};


export type QueryPaymentInvoicesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PaymentInvoiceFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPaymentReceiptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PaymentReceiptFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPermissionGroupArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPermissionGroupsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  appType: AppTypes;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PermissionGroupFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PermissionGroupSortingInput>;
  vendorId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryPlanArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryPlansArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PlanFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PlanOrder>;
};


export type QueryPrescriptionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  patientIdentifier?: InputMaybe<Scalars['String']['input']>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPrescriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PrescriptionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PrescriptionSortingInput>;
};


export type QueryProviderGuidedCareHealthProgramTeamArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProviderGuidedCareHealthProgramTeamsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProviderGuidedCareHealthProgramTeamFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<ProviderGuidedCareHealthProgramTeamSortingInput>;
};


export type QueryProviderSettingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<ProviderSettingsFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryQuestionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryQuestionAnswersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<QuestionAnswerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRadArgs = {
  id: Scalars['ID']['input'];
};


export type QueryRejectionReasonsArgs = {
  filter?: InputMaybe<ReasonFilterInput>;
};


export type QuerySleepArgs = {
  input: SleepInput;
  saveData?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QuerySurveyArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySurveyStatisticsArgs = {
  id: Scalars['ID']['input'];
  publishCriteriaId?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerySurveysArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SurveyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<SurveySorting>;
};


export type QueryTaskDefinitionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTaskDefinitionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TaskDefinitionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<TaskDefinitionSortingInput>;
};


export type QueryTeamEnrolledMembersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  teamId: Scalars['ID']['input'];
};


export type QueryTeamEnrolledPatientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  teamId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryTeamLeaderDoctorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTemplateActivityArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTemplateInterventionActivityArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTemplateInterventionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<HealthProgramTemplateInterventionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<HealthProgramTemplateSortingInput>;
};


export type QueryTemplateRecommendationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TemplateRecommendationFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTrackerReminderArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTrackerReminderSettingArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTrackerReminderSettingsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TrackerReminderSettingsInputFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<TrackerReminderSettingsSortingInput>;
};


export type QueryTrackerRemindersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TrackerReminderInputFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<TrackerReminderSortingInput>;
};


export type QueryTrackerSupplementTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTrackerSupplementTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TrackerSupplementTypeInputFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTrackerTradeDrugTypeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTrackerTradeDrugTypesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<TrackerTradeDrugTypeInputFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTranscriptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter: TranscriptFilterInput;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<TranscriptionSortingInput>;
};


export type QueryUnverifiedNationalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UnverifiedNationalsFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<UserSortingInput>;
};


export type QueryVendorArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVendorDepartmentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVendorDepartmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<VendorDepartmentFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<VendorDepartmentOrder>;
};


export type QueryVendorSubscriptionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryVendorSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<SubscriptionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryVendorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<VendorFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<VendorOrder>;
  userLocation?: InputMaybe<LocationInput>;
};


export type QueryVisitArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryVisitComplainsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<VisitComplainFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryVisitDiagnosisRiskTemplateArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVisitDiagnosisRiskTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryVisitMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<VisitMessageFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryVisitPrescriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  visitId: Scalars['ID']['input'];
};


export type QueryVisitReviewQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<VisitReviewQuestionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryVisitStatusHistoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<VisitStatusHistoryFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryVisitSummariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<VisitSummaryFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryVisitSummaryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVisitsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<VisitFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<VisitSortingInput>;
};

export type Question = {
  __typename?: 'Question';
  createdDate?: Maybe<Scalars['Date']['output']>;
  department?: Maybe<Department>;
  errors?: Maybe<Array<Maybe<SurveyGraphqlError>>>;
  id?: Maybe<Scalars['ID']['output']>;
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  medicalForm?: Maybe<MedicalForm>;
  options?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  position?: Maybe<Scalars['Int']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  questionOptionScores?: Maybe<Array<Maybe<QuestionOptionScore>>>;
  questionType?: Maybe<QuestionType>;
  required?: Maybe<Scalars['Boolean']['output']>;
  updateDate?: Maybe<Scalars['Date']['output']>;
  vendor?: Maybe<Array<Maybe<Vendor>>>;
};

export type QuestionAnswer = {
  __typename?: 'QuestionAnswer';
  booleanAnswer?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  multipleChoiceAnswer?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  question?: Maybe<Question>;
  singleChoiceAnswer?: Maybe<Scalars['String']['output']>;
  stringAnswer?: Maybe<Scalars['String']['output']>;
};

export type QuestionAnswerCareJourneyExtendedInput = {
  booleanAnswer?: InputMaybe<Scalars['Boolean']['input']>;
  multipleChoiceAnswer?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  questionId: Scalars['ID']['input'];
  singleChoiceAnswer?: InputMaybe<Scalars['String']['input']>;
  stringAnswer?: InputMaybe<Scalars['String']['input']>;
};

export type QuestionAnswerConnection = {
  __typename?: 'QuestionAnswerConnection';
  edges?: Maybe<Array<Maybe<QuestionAnswerEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type QuestionAnswerEdge = {
  __typename?: 'QuestionAnswerEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<QuestionAnswerExtended>;
};

export type QuestionAnswerExtended = {
  __typename?: 'QuestionAnswerExtended';
  booleanAnswer?: Maybe<Scalars['Boolean']['output']>;
  createdBy?: Maybe<User>;
  guidedCareJourneyItem?: Maybe<GuidedCareJourneyItem>;
  id: Scalars['ID']['output'];
  multipleChoiceAnswer?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  outPatientJourney?: Maybe<OutPatientJourney>;
  patient?: Maybe<Patient>;
  question?: Maybe<Question>;
  questionOptionScore?: Maybe<QuestionOptionScore>;
  score?: Maybe<Scalars['Int']['output']>;
  singleChoiceAnswer?: Maybe<Scalars['String']['output']>;
  stringAnswer?: Maybe<Scalars['String']['output']>;
};

export type QuestionAnswerExtendedInput = {
  booleanAnswer?: InputMaybe<Scalars['Boolean']['input']>;
  multipleChoiceAnswer?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  outPatientJourneyId: Scalars['ID']['input'];
  questionId: Scalars['ID']['input'];
  singleChoiceAnswer?: InputMaybe<Scalars['String']['input']>;
  stringAnswer?: InputMaybe<Scalars['String']['input']>;
};

export type QuestionAnswerFilter = {
  guidedCareJourneyItems?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  medicalForms?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  outPatientJourneys?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  patients?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  questions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type QuestionAnswerInput = {
  booleanAnswer?: InputMaybe<Scalars['Boolean']['input']>;
  multipleChoiceAnswer?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  question: Scalars['ID']['input'];
  singleChoiceAnswer?: InputMaybe<Scalars['String']['input']>;
  stringAnswer?: InputMaybe<Scalars['String']['input']>;
};

export type QuestionInput = {
  departmentId?: InputMaybe<Scalars['ID']['input']>;
  images?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  options?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  position?: InputMaybe<Scalars['Int']['input']>;
  question: Scalars['String']['input'];
  questionOptionScores?: InputMaybe<Array<QuestionOptionScoreInput>>;
  questionType: QuestionType;
  required: Scalars['Boolean']['input'];
};

export type QuestionOptionScore = {
  __typename?: 'QuestionOptionScore';
  id?: Maybe<Scalars['ID']['output']>;
  option?: Maybe<Scalars['String']['output']>;
  score?: Maybe<Scalars['Int']['output']>;
};

export type QuestionOptionScoreInput = {
  option: Scalars['String']['input'];
  score: Scalars['Int']['input'];
};

export enum QuestionType {
  Boolean = 'BOOLEAN',
  MultiChoice = 'MULTI_CHOICE',
  SingleChoice = 'SINGLE_CHOICE',
  String = 'STRING'
}

export type Rad = H_Node & {
  __typename?: 'Rad';
  code?: Maybe<Scalars['String']['output']>;
  conceptId?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['Instant']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  modified?: Maybe<Scalars['Instant']['output']>;
  visit?: Maybe<Visit>;
};

export type RadInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  conceptId?: InputMaybe<Scalars['String']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  visitId: Scalars['ID']['input'];
};

export enum RatingActionEnum {
  Create = 'CREATE',
  Update = 'UPDATE'
}

export type RatingAndNumberOfCallsPair = {
  __typename?: 'RatingAndNumberOfCallsPair';
  count?: Maybe<Scalars['Int']['output']>;
  rating?: Maybe<Scalars['Int']['output']>;
};

export type ReasonFilterInput = {
  actor?: InputMaybe<Actor>;
  affectedType?: InputMaybe<AffectedType>;
};

export type Receptionist = Node & {
  __typename?: 'Receptionist';
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type ReferralEdge = {
  __typename?: 'ReferralEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<PatientReferral>;
};

export enum ReferralErrorCodes {
  ReferralDoctorNotFound = 'REFERRAL_DOCTOR_NOT_FOUND',
  ReferralEmptyDoctorLicense = 'REFERRAL_EMPTY_DOCTOR_LICENSE',
  ReferralErrorCallingIntegrator = 'REFERRAL_ERROR_CALLING_INTEGRATOR',
  ReferralInvalidId = 'REFERRAL_INVALID_ID',
  ReferralMakerNoneNegativePageSize = 'REFERRAL_MAKER_NONE_NEGATIVE_PAGE_SIZE',
  ReferralMakerUnknown = 'REFERRAL_MAKER_UNKNOWN',
  ReferralMakerUnknownType = 'REFERRAL_MAKER_UNKNOWN_TYPE',
  ReferralMemberIdIsNull = 'REFERRAL_MEMBER_ID_IS_NULL',
  ReferralMemberNotActive = 'REFERRAL_MEMBER_NOT_ACTIVE',
  ReferralNotFound = 'REFERRAL_NOT_FOUND',
  ReferralNoPrincipalDiagnosis = 'REFERRAL_NO_PRINCIPAL_DIAGNOSIS',
  ReferralOnlyOnePrincipalDiagnosisAllowed = 'REFERRAL_ONLY_ONE_PRINCIPAL_DIAGNOSIS_ALLOWED',
  ReferralOperationNotAllowed = 'REFERRAL_OPERATION_NOT_ALLOWED',
  ReferralPatientNotEligible = 'REFERRAL_PATIENT_NOT_ELIGIBLE',
  ReferralPatientNotFound = 'REFERRAL_PATIENT_NOT_FOUND',
  ReferralPayerLicenseNotDefined = 'REFERRAL_PAYER_LICENSE_NOT_DEFINED',
  ReferralPermissionDenied = 'REFERRAL_PERMISSION_DENIED',
  ReferralUserIdNotDefined = 'REFERRAL_USER_ID_NOT_DEFINED'
}

export type ReferralGraphqlError = {
  __typename?: 'ReferralGraphqlError';
  code?: Maybe<ReferralErrorCodes>;
  errorType?: Maybe<ErrorType>;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum ReferralOrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type ReferralRequestInput = {
  diagnosis: Array<InputMaybe<DiagnosisInput>>;
  observation: Scalars['String']['input'];
  previousReferralReferenceID?: InputMaybe<Scalars['String']['input']>;
  specialty: Scalars['String']['input'];
  visitId: Scalars['ID']['input'];
};

export type RefundPaymentFromWalletInput = {
  /**
   * the amount value is only effective for the case of partial (not full) refund,
   * and the amount value should be within the available value for refund
   */
  amount: Scalars['Float']['input'];
  paymentOperationLogId: Scalars['ID']['input'];
};

export enum RefundStatus {
  Handled = 'HANDLED',
  Requested = 'REQUESTED'
}

export enum RefundTarget {
  PaymentGateway = 'PAYMENT_GATEWAY',
  Wallet = 'WALLET'
}

export type RejectVendor = {
  __typename?: 'RejectVendor';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  rejectErrors: Array<VendorError>;
  vendorRejectionReason?: Maybe<VendorRejectionReason>;
};

export type RejectionReason = {
  __typename?: 'RejectionReason';
  code: Scalars['String']['output'];
  display: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

/** CRUD a new rejection reason. */
export type RejectionReasonCrud = {
  __typename?: 'RejectionReasonCRUD';
  entityErrors?: Maybe<Array<H_EntityError>>;
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  rejectionReason?: Maybe<H_RejectionReason>;
};

export type RejectionReasonInput = {
  actor: Actor;
  affectedType: AffectedType;
  /** Rejection Reason */
  text: Scalars['String']['input'];
  text_ar: Scalars['String']['input'];
};

export enum RelationTypes {
  Brother = 'BROTHER',
  Daughter = 'DAUGHTER',
  Father = 'FATHER',
  Grandfather = 'GRANDFATHER',
  Grandmother = 'GRANDMOTHER',
  Husband = 'HUSBAND',
  Mother = 'MOTHER',
  Other = 'OTHER',
  Sister = 'SISTER',
  Son = 'SON',
  Wife = 'WIFE'
}

export type RemoveVendorFromFavorite = {
  __typename?: 'RemoveVendorFromFavorite';
  customerFavoriteVendor?: Maybe<CustomerFavoriteVendor>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendorErrors: Array<VendorError>;
};

export enum ReportingPeriod {
  Last_7Days = 'LAST_7_DAYS',
  ThisMonth = 'THIS_MONTH',
  Today = 'TODAY'
}

export type RequestOtpForDeleteAccount = {
  __typename?: 'RequestOTPForDeleteAccount';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  sessionToken?: Maybe<Scalars['String']['output']>;
};

export type RequestPasswordReset = {
  __typename?: 'RequestPasswordReset';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  sessionToken?: Maybe<Scalars['String']['output']>;
};

export enum RequestStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export enum RequestedBy {
  Patient = 'PATIENT',
  ProviderGuidedCareHealthProgramTeam = 'PROVIDER_GUIDED_CARE_HEALTH_PROGRAM_TEAM'
}

export type RequestedGenericDrugQuantity = {
  code?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
};

export enum ResidencyTypesEnum {
  Residence = 'RESIDENCE',
  Visitor = 'VISITOR'
}

export type ReviewQuestion = H_Node & {
  __typename?: 'ReviewQuestion';
  created?: Maybe<Scalars['Instant']['output']>;
  id: Scalars['ID']['output'];
  modified?: Maybe<Scalars['Instant']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  valueType?: Maybe<ReviewQuestionValueType>;
  visitsReviewQuestions?: Maybe<VisitReviewQuestionCountableConnection>;
};


export type ReviewQuestionVisitsReviewQuestionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export enum ReviewQuestionValueType {
  Boolean = 'BOOLEAN',
  StarRating = 'STAR_RATING'
}

export type ReviewVendor = {
  __typename?: 'ReviewVendor';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendorErrors: Array<VendorError>;
  vendorReview?: Maybe<VendorReview>;
};

/** Review an Visit */
export type ReviewVisit = {
  __typename?: 'ReviewVisit';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  result?: Maybe<Scalars['String']['output']>;
  visitErrors?: Maybe<Array<H_EntityError>>;
};

export type RiskFactor = {
  __typename?: 'RiskFactor';
  display?: Maybe<Scalars['String']['output']>;
  field?: Maybe<Scalars['String']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
};

export type RiskFactorTemplate = {
  __typename?: 'RiskFactorTemplate';
  arabicDisplay?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['String']['output']>;
  display?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<DecisionMakerGraphqlError>>>;
  fields?: Maybe<Array<Maybe<HealthParameter>>>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  plan?: Maybe<DecisionPlan>;
};

export type RiskFactorTemplateConnection = {
  __typename?: 'RiskFactorTemplateConnection';
  edges?: Maybe<Array<Maybe<RiskFactorTemplateEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type RiskFactorTemplateEdge = {
  __typename?: 'RiskFactorTemplateEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<RiskFactorTemplate>;
};

export type RiskFactorTemplateFilterInput = {
  arabicDisplay?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  display?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
};

export type RiskFactorTemplateInput = {
  arabicDisplay: Scalars['String']['input'];
  code: Scalars['String']['input'];
  display: Scalars['String']['input'];
  isActive: Scalars['Boolean']['input'];
};

export enum RiskFactorTemplateSortingField {
  ArabicDisplay = 'ARABIC_DISPLAY',
  Code = 'CODE',
  CreatedDate = 'CREATED_DATE',
  Display = 'DISPLAY',
  IsActive = 'IS_ACTIVE'
}

export type RiskFactorTemplateSortingInput = {
  direction: DecisionMakerOrderDirection;
  field: RiskFactorTemplateSortingField;
};

export type RiskFactorTemplateUpdateInput = {
  arabicDisplay: Scalars['String']['input'];
  display: Scalars['String']['input'];
  isActive: Scalars['Boolean']['input'];
};

export enum RiskLevel {
  High = 'HIGH',
  Low = 'LOW'
}

export type RiskTemplateResultInput = {
  id: Scalars['ID']['input'];
  result: Scalars['String']['input'];
};

export type RoundInput = {
  maxNumberOfPharmacies: Scalars['Int']['input'];
  pharmaciesTypes: Array<VendorBranchTypeEnum>;
  radius: Scalars['Decimal']['input'];
};

export type S_DateTimeRangeInput = {
  /** Start date. */
  gte?: InputMaybe<Scalars['LocalDateTime']['input']>;
  /** End date. */
  lte?: InputMaybe<Scalars['LocalDateTime']['input']>;
};

export type SearchParameter = {
  code?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Type>;
  valueBoolean?: InputMaybe<Scalars['Boolean']['input']>;
  valueDate?: InputMaybe<Scalars['Date']['input']>;
  valueFloat?: InputMaybe<Scalars['Float']['input']>;
  valueInteger?: InputMaybe<Scalars['Int']['input']>;
  valueString?: InputMaybe<Scalars['String']['input']>;
};

/** Creates a new visit message. */
export type SendVisitMessage = {
  __typename?: 'SendVisitMessage';
  chatErrors?: Maybe<Array<H_EntityError>>;
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  visitMessage?: Maybe<VisitMessage>;
};

export enum ServiceDescription {
  LabTests = 'LAB_TESTS',
  RadTests = 'RAD_TESTS'
}

export type ServiceDetails = {
  __typename?: 'ServiceDetails';
  id?: Maybe<Scalars['ID']['output']>;
  serviceCode?: Maybe<Scalars['String']['output']>;
  serviceName?: Maybe<Scalars['String']['output']>;
  standard?: Maybe<Scalars['String']['output']>;
};

export type ServiceDetailsInput = {
  appointment?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  serviceCode: Scalars['String']['input'];
  serviceName?: InputMaybe<Scalars['String']['input']>;
  standard: Scalars['String']['input'];
};

export type ServiceDetailsInputForAppointment = {
  id?: InputMaybe<Scalars['ID']['input']>;
  serviceCode: Scalars['String']['input'];
  serviceName?: InputMaybe<Scalars['String']['input']>;
  standard: Scalars['String']['input'];
};

export type ServiceOfGuidedCareHealthProgram = H_Node & {
  __typename?: 'ServiceOfGuidedCareHealthProgram';
  frequencyType?: Maybe<AppointmentFrequencyType>;
  id: Scalars['ID']['output'];
  minimumNumberOfCoverages?: Maybe<Scalars['Int']['output']>;
  serviceDescription?: Maybe<ServiceDescription>;
  serviceOfGuidedCareHealthProgramDetails?: Maybe<Array<Maybe<ServiceDetails>>>;
  templateService?: Maybe<ServiceOfHealthProgramTemplate>;
};

export type ServiceOfGuidedCareHealthProgramInput = {
  frequencyType: AppointmentFrequencyType;
  id?: InputMaybe<Scalars['ID']['input']>;
  minimumNumberOfCoverages: Scalars['Int']['input'];
  serviceDescription?: InputMaybe<ServiceDescription>;
  serviceOfGuidedCareHealthProgramDetails: Array<InputMaybe<ServiceDetailsInput>>;
  templateService?: InputMaybe<ServiceOfHealthProgramTemplateInput>;
};

export type ServiceOfHealthProgramTemplate = H_Node & {
  __typename?: 'ServiceOfHealthProgramTemplate';
  frequencyType?: Maybe<AppointmentFrequencyType>;
  id: Scalars['ID']['output'];
  minimumNumberOfCoverages?: Maybe<Scalars['Int']['output']>;
  serviceDescription?: Maybe<ServiceDescription>;
  serviceOfHealthProgramTemplateDetails?: Maybe<Array<Maybe<ServiceDetails>>>;
};

export type ServiceOfHealthProgramTemplateInput = {
  frequencyType: AppointmentFrequencyType;
  id?: InputMaybe<Scalars['ID']['input']>;
  minimumNumberOfCoverages: Scalars['Int']['input'];
  serviceDescription?: InputMaybe<ServiceDescription>;
  serviceOfHealthProgramTemplateDetails: Array<InputMaybe<ServiceDetailsInput>>;
};

export type SetPassword = {
  __typename?: 'SetPassword';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum SiteErrorCode {
  ForbiddenCharacter = 'FORBIDDEN_CHARACTER',
  GraphqlError = 'GRAPHQL_ERROR'
}

export type SiteSettings = H_Node & {
  __typename?: 'SiteSettings';
  /**
   * allowed time in seconds to make a new call after the essential latest call was made
   * , essential call means the first call that was requested by patient and perhaps the call was timedout/rejoined/cancelled
   * and then the final status of it can be
   * (INCOMPLETED, COMPLETED, CANCELED, CANCELED_BY_CONSUMER, ENDED_BY_ONE_OF_THE_PARTICIPANTS)
   */
  allowedTimeInSecondsToMakeNewCall?: Maybe<Scalars['Int']['output']>;
  androidPackageName?: Maybe<Scalars['String']['output']>;
  androidVersion?: Maybe<Scalars['Float']['output']>;
  appointmentReminderSettingsVariablesName?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** time in seconds before the appointment system will send reminder notification */
  appointmentReminderTimeSeconds?: Maybe<Scalars['Int']['output']>;
  callCenterPhoneNumber?: Maybe<Scalars['String']['output']>;
  /** time in seconds for the call join */
  callJoinTimeoutSeconds?: Maybe<Scalars['Int']['output']>;
  /** time in seconds for the call rejoin */
  callRejoinTimeoutSeconds?: Maybe<Scalars['Int']['output']>;
  chargeTaxesOnShipping?: Maybe<Scalars['Boolean']['output']>;
  defaultCurrency: Scalars['String']['output'];
  defaultWeightUnit?: Maybe<WeightUnit>;
  displayGrossPrices?: Maybe<Scalars['Boolean']['output']>;
  /** time in seconds out after which the visit will be automatically rejected */
  doctorVisitAcceptanceTimeoutSeconds?: Maybe<Scalars['Int']['output']>;
  /** time in seconds before the appointment system will send the first reminder notification for patient to schedule guided care appointment */
  guidedCareAppointmentBookingFirstReminderSeconds?: Maybe<Scalars['Int']['output']>;
  /**  guided care appointment reminder care team admin message  */
  guidedCareAppointmentBookingReminderCareTeamAdminMessage?: Maybe<Scalars['String']['output']>;
  /**  guided care appointment reminder care team admin message arabic  */
  guidedCareAppointmentBookingReminderCareTeamAdminMessageAr?: Maybe<Scalars['String']['output']>;
  /**  guided care appointment reminder doctor message  */
  guidedCareAppointmentBookingReminderDoctorMessage?: Maybe<Scalars['String']['output']>;
  /**  guided care appointment reminder doctor message arabic  */
  guidedCareAppointmentBookingReminderDoctorMessageAr?: Maybe<Scalars['String']['output']>;
  /**  guided care appointment reminder patient message  */
  guidedCareAppointmentBookingReminderPatientMessage?: Maybe<Scalars['String']['output']>;
  /**  guided care appointment reminder patient message arabic  */
  guidedCareAppointmentBookingReminderPatientMessageAr?: Maybe<Scalars['String']['output']>;
  /** time in seconds before the appointment system will send the second reminder notification for patient to schedule guided care appointment */
  guidedCareAppointmentBookingSecondReminderSeconds?: Maybe<Scalars['Int']['output']>;
  /** time in seconds before the appointment system will send the third reminder notification for patient to schedule guided care appointment */
  guidedCareAppointmentBookingThirdReminderSeconds?: Maybe<Scalars['Int']['output']>;
  /** health program visit wait time in seconds before call */
  healthProgramVisitWaitTimeBeforeCallSeconds?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  includeTaxesInPrices?: Maybe<Scalars['Boolean']['output']>;
  iosAppId?: Maybe<Scalars['String']['output']>;
  iosVersion?: Maybe<Scalars['Float']['output']>;
  isPaymentModeTest?: Maybe<Scalars['Boolean']['output']>;
  marketPlacePharmacyFirst?: Maybe<Scalars['Boolean']['output']>;
  marketplaceConsumedTimeoutAlert?: Maybe<Scalars['Int']['output']>;
  marketplaceConsumedTimeoutSeconds?: Maybe<Scalars['Int']['output']>;
  marketplaceDeliveredTimeoutAlert?: Maybe<Scalars['Int']['output']>;
  marketplaceDeliveredTimeoutSeconds?: Maybe<Scalars['Int']['output']>;
  marketplaceOrderPaymentTimeoutSeconds?: Maybe<Scalars['Int']['output']>;
  marketplaceOutForDeliveryTimeoutAlert?: Maybe<Scalars['Int']['output']>;
  marketplaceOutForDeliveryTimeoutSeconds?: Maybe<Scalars['Int']['output']>;
  marketplaceReturnedTimeoutAlert?: Maybe<Scalars['Int']['output']>;
  marketplaceReturnedTimeoutSeconds?: Maybe<Scalars['Int']['output']>;
  marketplaceVendorResponseTimeoutAlert?: Maybe<Scalars['Int']['output']>;
  marketplaceVendorResponseTimeoutSeconds?: Maybe<Scalars['Int']['output']>;
  maxFileSizeMB?: Maybe<Scalars['Int']['output']>;
  maxImageSizeMB?: Maybe<Scalars['Int']['output']>;
  /** minimum call duration in seconds to consider call as complete */
  messengerCallMinimumDurationSeconds?: Maybe<Scalars['Int']['output']>;
  microserviceContextPath?: Maybe<Scalars['String']['output']>;
  minDaysToRefill?: Maybe<Scalars['Int']['output']>;
  multiplePharmaciesDelivery?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  orderAcceptanceTimeout?: Maybe<Scalars['Int']['output']>;
  orderBaseDeliveryFee?: Maybe<Scalars['Float']['output']>;
  orderDeliveryTimeout?: Maybe<Scalars['Int']['output']>;
  orderDispensingTimeout?: Maybe<Scalars['Int']['output']>;
  orderDispensingTimeoutAlert?: Maybe<Scalars['Int']['output']>;
  orderErxHubApprovalResubmitTimeout?: Maybe<Scalars['Int']['output']>;
  orderErxHubApprovalTimeout?: Maybe<Scalars['Int']['output']>;
  orderErxHubApprovalTimeoutAlert?: Maybe<Scalars['Int']['output']>;
  paymentDeadlineInSeconds?: Maybe<Scalars['Int']['output']>;
  paymentFirstReminderInSeconds?: Maybe<Scalars['Int']['output']>;
  paymentKafkaTopics?: Maybe<Array<Maybe<P_SubscriptionKafkaTopic>>>;
  paymentSecondReminderInSeconds?: Maybe<Scalars['Int']['output']>;
  prescriptionOrderPaymentTimeoutSeconds?: Maybe<Scalars['Int']['output']>;
  /** time in minutes before the visit considered running late */
  runningLateTimeoutMinutes?: Maybe<Scalars['Int']['output']>;
  subscriptionKafkaTopics?: Maybe<Array<Maybe<SubscriptionKafkaTopic>>>;
  termsAndConditions?: Maybe<Scalars['String']['output']>;
  termsAndConditionsAr?: Maybe<Scalars['String']['output']>;
  timeZone?: Maybe<Scalars['Float']['output']>;
  trackInventoryByDefault?: Maybe<Scalars['Boolean']['output']>;
  vatPercentage?: Maybe<Scalars['Float']['output']>;
  /**
   * VAT Percentage Value For Medical Services Including Only [Appointment, Visit,
   * Out Patient Journey or Patient Check in Journey]
   */
  vatPercentageForMedicalServices?: Maybe<Scalars['Float']['output']>;
};

export type SiteSettingsInput = {
  androidPackageName?: InputMaybe<Scalars['String']['input']>;
  androidVersion?: InputMaybe<Scalars['Float']['input']>;
  callCenterPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  chargeTaxesOnShipping?: InputMaybe<Scalars['Boolean']['input']>;
  defaultCurrency?: InputMaybe<Scalars['String']['input']>;
  defaultWeightUnit?: InputMaybe<WeightUnit>;
  displayGrossPrices?: InputMaybe<Scalars['Boolean']['input']>;
  includeTaxesInPrices?: InputMaybe<Scalars['Boolean']['input']>;
  iosAppId?: InputMaybe<Scalars['String']['input']>;
  iosVersion?: InputMaybe<Scalars['Float']['input']>;
  marketPlacePharmacyFirst?: InputMaybe<Scalars['Boolean']['input']>;
  marketplaceConsumedTimeoutAlert?: InputMaybe<Scalars['Int']['input']>;
  marketplaceConsumedTimeoutSeconds?: InputMaybe<Scalars['Int']['input']>;
  marketplaceDeliveredTimeoutAlert?: InputMaybe<Scalars['Int']['input']>;
  marketplaceDeliveredTimeoutSeconds?: InputMaybe<Scalars['Int']['input']>;
  marketplaceOrderPaymentTimeoutSeconds?: InputMaybe<Scalars['Int']['input']>;
  marketplaceOutForDeliveryTimeoutAlert?: InputMaybe<Scalars['Int']['input']>;
  marketplaceOutForDeliveryTimeoutSeconds?: InputMaybe<Scalars['Int']['input']>;
  marketplaceReturnedTimeoutAlert?: InputMaybe<Scalars['Int']['input']>;
  marketplaceReturnedTimeoutSeconds?: InputMaybe<Scalars['Int']['input']>;
  marketplaceVendorResponseTimeoutAlert?: InputMaybe<Scalars['Int']['input']>;
  marketplaceVendorResponseTimeoutSeconds?: InputMaybe<Scalars['Int']['input']>;
  maxFileSizeMB?: InputMaybe<Scalars['Int']['input']>;
  maxImageSizeMB?: InputMaybe<Scalars['Int']['input']>;
  microserviceContextPath?: InputMaybe<Scalars['String']['input']>;
  minDaysToRefill?: InputMaybe<Scalars['Int']['input']>;
  multiplePharmaciesDelivery?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  orderAcceptanceTimeout?: InputMaybe<Scalars['Int']['input']>;
  orderBaseDeliveryFee?: InputMaybe<Scalars['Float']['input']>;
  orderDeliveryTimeout?: InputMaybe<Scalars['Int']['input']>;
  orderDispensingTimeout?: InputMaybe<Scalars['Int']['input']>;
  orderDispensingTimeoutAlert?: InputMaybe<Scalars['Int']['input']>;
  orderErxHubApprovalResubmitTimeout?: InputMaybe<Scalars['Int']['input']>;
  orderErxHubApprovalTimeout?: InputMaybe<Scalars['Int']['input']>;
  orderErxHubApprovalTimeoutAlert?: InputMaybe<Scalars['Int']['input']>;
  prescriptionOrderPaymentTimeoutSeconds?: InputMaybe<Scalars['Int']['input']>;
  termsAndConditions?: InputMaybe<Scalars['String']['input']>;
  termsAndConditionsAr?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['Float']['input']>;
  trackInventoryByDefault?: InputMaybe<Scalars['Boolean']['input']>;
  vatPercentage?: InputMaybe<Scalars['Float']['input']>;
};

export type SleepInput = {
  age: Scalars['Int']['input'];
  basedOnSleepTime: Scalars['Boolean']['input'];
  time: Scalars['LocalTime']['input'];
};

export type SleepInputLog = {
  __typename?: 'SleepInputLog';
  age: Scalars['Int']['output'];
  basedOnSleepTime: Scalars['Boolean']['output'];
  time: Scalars['LocalTime']['output'];
};

export type SleepResponse = {
  __typename?: 'SleepResponse';
  sleepCycles?: Maybe<Scalars['Int']['output']>;
  time?: Maybe<Scalars['LocalTime']['output']>;
};

export type SleepResult = {
  __typename?: 'SleepResult';
  createdDate?: Maybe<Scalars['Instant']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  queryInput?: Maybe<SleepInputLog>;
  sleepCycles?: Maybe<Scalars['Int']['output']>;
  time?: Maybe<Scalars['LocalTime']['output']>;
};

export type SleepResultConnection = {
  __typename?: 'SleepResultConnection';
  edges: Array<SleepResultEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type SleepResultEdge = {
  __typename?: 'SleepResultEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: SleepResult;
};

export enum SmokingHabit {
  FormerSmoker = 'FORMER_SMOKER',
  HeavySmoker = 'HEAVY_SMOKER',
  LightSmoker = 'LIGHT_SMOKER',
  Never = 'NEVER'
}

export type SocialWorker = Node & {
  __typename?: 'SocialWorker';
  created: Scalars['DateTime']['output'];
  healthLicenseEndDate?: Maybe<Scalars['Date']['output']>;
  healthLicenseNumber?: Maybe<Scalars['String']['output']>;
  healthLicenseStartDate?: Maybe<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  user?: Maybe<User>;
};

export type SocialWorkerInput = {
  healthLicenseEndDate?: InputMaybe<Scalars['Date']['input']>;
  healthLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  healthLicenseStartDate?: InputMaybe<Scalars['Date']['input']>;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum Source {
  Doctor = 'DOCTOR',
  Iot = 'IOT',
  Nurse = 'NURSE',
  Patient = 'PATIENT'
}

export type Speciality = {
  __typename?: 'Speciality';
  arabicDisplay?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  display: Scalars['String']['output'];
};

export type SpecialityConnection = {
  __typename?: 'SpecialityConnection';
  edges?: Maybe<Array<Maybe<SpecialityEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type SpecialityEdge = {
  __typename?: 'SpecialityEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<Speciality>;
};

export type SpecialityFilterInput = {
  codes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  display?: InputMaybe<Scalars['String']['input']>;
};

export type SpecializationError = {
  __typename?: 'SpecializationError';
  code: SpecializationErrorCode;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum SpecializationErrorCode {
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type SubList = {
  __typename?: 'SubList';
  display?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<DecisionMakerGraphqlError>>>;
  field?: Maybe<Field>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SubListConnection = {
  __typename?: 'SubListConnection';
  edges?: Maybe<Array<Maybe<SubListEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type SubListEdge = {
  __typename?: 'SubListEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<SubList>;
};

export type SubListInput = {
  display?: InputMaybe<Scalars['String']['input']>;
  field?: InputMaybe<FieldInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type SubListItem = {
  __typename?: 'SubListItem';
  code: Scalars['String']['output'];
  display: Scalars['String']['output'];
  errors?: Maybe<Array<Maybe<DecisionMakerGraphqlError>>>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type SubListItemConnection = {
  __typename?: 'SubListItemConnection';
  edges?: Maybe<Array<Maybe<SubListItemEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type SubListItemEdge = {
  __typename?: 'SubListItemEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<SubListItem>;
};

export type SubListItemInput = {
  code: Scalars['String']['input'];
  display: Scalars['String']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum SubListItemSortField {
  Code = 'CODE',
  Display = 'DISPLAY'
}

export type SubListItemSortingInput = {
  direction: DecisionMakerOrderDirection;
  field: SubListItemSortField;
};

export enum SubListSortField {
  Display = 'DISPLAY',
  Field = 'FIELD'
}

export type SubListSortingInput = {
  direction: DecisionMakerOrderDirection;
  field: SubListSortField;
};

export type SublistFilterInput = {
  display?: InputMaybe<Scalars['String']['input']>;
  field?: InputMaybe<Scalars['ID']['input']>;
};

export type SubscribedUser = {
  __typename?: 'SubscribedUser';
  errors?: Maybe<Array<Maybe<SubscriptionGraphqlError>>>;
  firebaseToken?: Maybe<Scalars['String']['output']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  doctorAvailability?: Maybe<DoctorAvailabilityStatusChange>;
  messageSent?: Maybe<MessageSent>;
  needActionCodeSystems?: Maybe<NeedActionCodeSystems>;
};


export type SubscriptionNeedActionCodeSystemsArgs = {
  token: Scalars['String']['input'];
};

/**
 * for refund to wallet : changes will be as following, values may differ for (refundAmount, walletBalance)
 *
 * [
 *     {
 *         "field": "refundStatus",
 *         "value": "HANDLED"
 *     }, {
 *         "field": "refundTarget",
 *         "value": "WALLET"
 *     }, {
 *         "field": "refundAmount",
 *         "value": "50.11"
 *     }, {
 *         "field": "walletBalance",
 *         "value": "60.22"
 *     }
 * ]
 */
export type SubscriptionEntityChanged = H_Node & {
  __typename?: 'SubscriptionEntityChanged';
  changes?: Maybe<Array<Maybe<SubscriptionEntityFieldChanges>>>;
  creationDate?: Maybe<Scalars['Instant']['output']>;
  graphqlType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  users?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

export type SubscriptionEntityChangedForAppointmentNotification = H_Node & {
  __typename?: 'SubscriptionEntityChangedForAppointmentNotification';
  arabicText?: Maybe<Scalars['String']['output']>;
  changes?: Maybe<Array<Maybe<SubscriptionEntityFieldChanges>>>;
  creationDate?: Maybe<Scalars['Instant']['output']>;
  englishText?: Maybe<Scalars['String']['output']>;
  graphqlType?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  notificationType?: Maybe<AppointmentNotificationType>;
  users?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

export type SubscriptionEntityFieldChanges = {
  __typename?: 'SubscriptionEntityFieldChanges';
  field?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionError = {
  __typename?: 'SubscriptionError';
  code: SubscriptionErrorCode;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum SubscriptionErrorCode {
  AlreadyExists = 'ALREADY_EXISTS',
  DuplicatedInputItem = 'DUPLICATED_INPUT_ITEM',
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export enum SubscriptionErrorCodes {
  SubscriptionInvalidId = 'SUBSCRIPTION_INVALID_ID',
  SubscriptionMakerUnknownType = 'SUBSCRIPTION_MAKER_UNKNOWN_TYPE',
  SubscriptionNoneNegativePageSize = 'SUBSCRIPTION_NONE_NEGATIVE_PAGE_SIZE',
  SubscriptionNotFound = 'SUBSCRIPTION_NOT_FOUND',
  SubscriptionPermissionDenied = 'SUBSCRIPTION_PERMISSION_DENIED',
  SubscriptionUnknown = 'SUBSCRIPTION_UNKNOWN',
  SubscriptionValidation = 'SUBSCRIPTION_VALIDATION'
}

export type SubscriptionFilterInput = {
  validFrom?: InputMaybe<Scalars['DateTime']['input']>;
  validTill?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SubscriptionGraphqlError = {
  __typename?: 'SubscriptionGraphqlError';
  code?: Maybe<SubscriptionErrorCodes>;
  errorType?: Maybe<ErrorType>;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionInput = {
  fixedCostAmount?: InputMaybe<Scalars['Decimal']['input']>;
  fixedOrderCostAmount?: InputMaybe<Scalars['Decimal']['input']>;
  fixedOrderPercentage?: InputMaybe<Scalars['Decimal']['input']>;
  plan?: InputMaybe<Scalars['ID']['input']>;
  vendor?: InputMaybe<Scalars['ID']['input']>;
};

export type SubscriptionKafkaTopic = {
  __typename?: 'SubscriptionKafkaTopic';
  graphqlType?: Maybe<Scalars['String']['output']>;
  topic?: Maybe<Scalars['String']['output']>;
};

export type Survey = {
  __typename?: 'Survey';
  answeredParticipantsCount?: Maybe<Scalars['Int']['output']>;
  createdBy?: Maybe<User>;
  createdDate?: Maybe<Scalars['Date']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<SurveyGraphqlError>>>;
  id?: Maybe<Scalars['ID']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  notification?: Maybe<Scalars['String']['output']>;
  publishCriteria?: Maybe<Array<Maybe<PublishCriteria>>>;
  questions?: Maybe<Array<Question>>;
  status?: Maybe<SurveyStatus>;
  updateDate?: Maybe<Scalars['Date']['output']>;
};

export type SurveyAnswerInput = {
  answers?: InputMaybe<Array<InputMaybe<QuestionAnswerInput>>>;
  survey: Scalars['ID']['input'];
};

export type SurveyConnection = {
  __typename?: 'SurveyConnection';
  edges?: Maybe<Array<Maybe<SurveyEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type SurveyEdge = {
  __typename?: 'SurveyEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<Survey>;
};

export enum SurveyErrorCodes {
  SurveyDuplicate = 'SURVEY_DUPLICATE',
  SurveyInvalidDateFormat = 'SURVEY_INVALID_DATE_FORMAT',
  SurveyMakerUnknownType = 'SURVEY_MAKER_UNKNOWN_TYPE',
  SurveyNoneNegativePageSize = 'SURVEY_NONE_NEGATIVE_PAGE_SIZE',
  SurveyNotFound = 'SURVEY_NOT_FOUND',
  SurveyPermissionDenied = 'SURVEY_PERMISSION_DENIED',
  SurveyUnknown = 'SURVEY_UNKNOWN',
  SurveyValidation = 'SURVEY_VALIDATION'
}

export type SurveyFilter = {
  label?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<SurveyStatus>;
};

export enum SurveyGender {
  Female = 'Female',
  Male = 'Male'
}

export type SurveyGraphqlError = {
  __typename?: 'SurveyGraphqlError';
  code?: Maybe<SurveyErrorCodes>;
  errorType?: Maybe<ErrorType>;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type SurveyInput = {
  description: Scalars['String']['input'];
  label: Scalars['String']['input'];
  notification: Scalars['String']['input'];
  questions?: InputMaybe<Array<InputMaybe<QuestionInput>>>;
};

export type SurveySorting = {
  direction: SortDirection;
  field: SurveySortingField;
};

export enum SurveySortingField {
  CreationDate = 'CREATION_DATE',
  Status = 'STATUS',
  UpdateDate = 'UPDATE_DATE'
}

export type SurveyStatistics = {
  __typename?: 'SurveyStatistics';
  questionsStatistics?: Maybe<Array<Maybe<QuestionsStatistics>>>;
  survey?: Maybe<Survey>;
};

export enum SurveyStatus {
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  Published = 'PUBLISHED',
  Unpublished = 'UNPUBLISHED'
}

export type SurveyUserAnswer = {
  __typename?: 'SurveyUserAnswer';
  createdDate?: Maybe<Scalars['Date']['output']>;
  errors?: Maybe<Array<Maybe<SurveyGraphqlError>>>;
  id: Scalars['ID']['output'];
  questionAnswers?: Maybe<Array<Maybe<QuestionAnswer>>>;
  user?: Maybe<User>;
};

export enum Tpo {
  Eclaim = 'ECLAIM',
  Io = 'IO'
}

export type T_Specialization = {
  __typename?: 'T_Specialization';
  arabicDisplay?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  display?: Maybe<Scalars['String']['output']>;
};

export type TaskDefinition = H_Node & {
  __typename?: 'TaskDefinition';
  action?: Maybe<TaskDefinitionAction>;
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayAr?: Maybe<Scalars['String']['output']>;
  displayEn?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isFlowEnd?: Maybe<Scalars['Boolean']['output']>;
  medicalMessage?: Maybe<MedicalMessage>;
  notificationMessageAr?: Maybe<Scalars['String']['output']>;
  notificationMessageEn?: Maybe<Scalars['String']['output']>;
  notificationTitleAr?: Maybe<Scalars['String']['output']>;
  notificationTitleEn?: Maybe<Scalars['String']['output']>;
  offsetAfter?: Maybe<Scalars['Int']['output']>;
  offsetBefore?: Maybe<Scalars['Int']['output']>;
  priority?: Maybe<TaskDefinitionPriority>;
  remindingFrequencyInDays?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<TaskDefinitionStatus>;
  taskWorkflowType?: Maybe<TaskWorkflowType>;
};

export enum TaskDefinitionAction {
  SendMedicalMessage = 'SEND_MEDICAL_MESSAGE',
  SendNotification = 'SEND_NOTIFICATION'
}

/** CRUD a new Task Definition */
export type TaskDefinitionCrud = {
  __typename?: 'TaskDefinitionCRUD';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  taskDefinition?: Maybe<TaskDefinition>;
  taskDefinitionErrors?: Maybe<Array<H_EntityError>>;
};

export type TaskDefinitionCountableConnection = {
  __typename?: 'TaskDefinitionCountableConnection';
  edges: Array<TaskDefinitionCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type TaskDefinitionCountableEdge = {
  __typename?: 'TaskDefinitionCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  node: TaskDefinition;
};

export type TaskDefinitionFilterInput = {
  actions?: InputMaybe<Array<InputMaybe<TaskDefinitionAction>>>;
  code?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  priorities?: InputMaybe<Array<InputMaybe<TaskDefinitionPriority>>>;
  statuses?: InputMaybe<Array<InputMaybe<TaskDefinitionStatus>>>;
};

export type TaskDefinitionInnerInput = {
  id: Scalars['ID']['input'];
};

export type TaskDefinitionInput = {
  action: TaskDefinitionAction;
  code: Scalars['String']['input'];
  description: Scalars['String']['input'];
  displayAr: Scalars['String']['input'];
  displayEn: Scalars['String']['input'];
  isFlowEnd: Scalars['Boolean']['input'];
  medicalMessage?: InputMaybe<MedicalMessageInput>;
  notificationMessageAr: Scalars['String']['input'];
  notificationMessageEn: Scalars['String']['input'];
  notificationTitleAr: Scalars['String']['input'];
  notificationTitleEn: Scalars['String']['input'];
  offsetAfter: Scalars['Int']['input'];
  offsetBefore: Scalars['Int']['input'];
  priority: TaskDefinitionPriority;
  remindingFrequencyInDays: Scalars['Int']['input'];
  status: TaskDefinitionStatus;
};

export enum TaskDefinitionPriority {
  Low = 'LOW',
  Moderate = 'MODERATE',
  Urgent = 'URGENT'
}

export enum TaskDefinitionSortingField {
  Created = 'CREATED',
  Priority = 'PRIORITY'
}

export type TaskDefinitionSortingInput = {
  direction: H_OrderDirection;
  field?: InputMaybe<TaskDefinitionSortingField>;
};

export enum TaskDefinitionStatus {
  Cancelled = 'CANCELLED',
  Done = 'DONE',
  Idle = 'IDLE',
  InProgress = 'IN_PROGRESS',
  Missed = 'MISSED',
  Pending = 'PENDING'
}

export type TaskDefinitionUpdateInput = {
  action?: InputMaybe<TaskDefinitionAction>;
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayAr?: InputMaybe<Scalars['String']['input']>;
  displayEn?: InputMaybe<Scalars['String']['input']>;
  medicalMessage?: InputMaybe<MedicalMessageInput>;
  notificationMessageAr?: InputMaybe<Scalars['String']['input']>;
  notificationMessageEn?: InputMaybe<Scalars['String']['input']>;
  notificationTitleAr?: InputMaybe<Scalars['String']['input']>;
  notificationTitleEn?: InputMaybe<Scalars['String']['input']>;
  offsetAfter?: InputMaybe<Scalars['Int']['input']>;
  offsetBefore?: InputMaybe<Scalars['Int']['input']>;
  priority?: InputMaybe<TaskDefinitionPriority>;
  remindingFrequencyInDays?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<TaskDefinitionStatus>;
};

export enum TaskWorkflowType {
  ConfirmAppointmentDate = 'CONFIRM_APPOINTMENT_DATE',
  ReviewMedicalFormAnswer = 'REVIEW_MEDICAL_FORM_ANSWER',
  SubmitMedicalFormAnswers = 'SUBMIT_MEDICAL_FORM_ANSWERS'
}

export enum TeamMemberPosition {
  DentalHygienist = 'DENTAL_HYGIENIST',
  DiabetesEducator = 'DIABETES_EDUCATOR',
  FitnessCoach = 'FITNESS_COACH',
  Nurse = 'NURSE',
  Nutritionist = 'NUTRITIONIST',
  Optometrist = 'OPTOMETRIST',
  Physician = 'PHYSICIAN',
  PodiatricMedicalAssistant = 'PODIATRIC_MEDICAL_ASSISTANT',
  Psychologist = 'PSYCHOLOGIST',
  SocialWorker = 'SOCIAL_WORKER',
  TeamAdmin = 'TEAM_ADMIN',
  TeamLeader = 'TEAM_LEADER'
}

export type TemplateActivity = H_Node & {
  __typename?: 'TemplateActivity';
  afterCallMessage?: Maybe<MedicalMessage>;
  afterCallMessageOffsetDays?: Maybe<Scalars['Int']['output']>;
  beforeCallMessage?: Maybe<MedicalMessage>;
  beforeCallMessageOffsetDays?: Maybe<Scalars['Int']['output']>;
  frequencyType: AppointmentFrequencyType;
  id: Scalars['ID']['output'];
  medicalForm?: Maybe<MedicalForm>;
  medicalFormClassificationToInterventionLinkList?: Maybe<Array<Maybe<MedicalFormClassificationToInterventionLink>>>;
  medicalMessage?: Maybe<MedicalMessage>;
  numberOfOccurrences?: Maybe<Scalars['Int']['output']>;
  serviceOfHealthProgramTemplateDetails?: Maybe<Array<Maybe<ServiceDetails>>>;
  templateActivityEventItems?: Maybe<Array<Maybe<TemplateActivityEventItem>>>;
  templateActivityType?: Maybe<GuidedCareActivityType>;
  templateTeamMember?: Maybe<TemplateTeamMember>;
};

export type TemplateActivityCrud = {
  __typename?: 'TemplateActivityCRUD';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  templateActivity?: Maybe<TemplateActivity>;
  templateActivityErrors?: Maybe<Array<H_EntityError>>;
};

export type TemplateActivityEventItem = H_Node & {
  __typename?: 'TemplateActivityEventItem';
  afterCallMessage?: Maybe<MedicalMessage>;
  afterCallMessageOffsetDays?: Maybe<Scalars['Int']['output']>;
  beforeCallMessage?: Maybe<MedicalMessage>;
  beforeCallMessageOffsetDays?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  offsetInDays?: Maybe<Scalars['Int']['output']>;
  templateActivity?: Maybe<TemplateActivity>;
  timeOfDay?: Maybe<TimeOfDay>;
};

export type TemplateActivityEventItemCountableConnection = {
  __typename?: 'TemplateActivityEventItemCountableConnection';
  edges: Array<TemplateActivityEventItemCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type TemplateActivityEventItemCountableEdge = {
  __typename?: 'TemplateActivityEventItemCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: TemplateActivityEventItem;
};

export type TemplateActivityEventItemFilterInput = {
  activityIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  medicalFromIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  medicalMessageIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  offsetEquals?: InputMaybe<Scalars['Int']['input']>;
  offsetGreaterThanOrEquals?: InputMaybe<Scalars['Int']['input']>;
  offsetLessThanOrEquals?: InputMaybe<Scalars['Int']['input']>;
  position?: InputMaybe<TeamMemberPosition>;
  templateActivityType?: InputMaybe<Array<InputMaybe<GuidedCareActivityType>>>;
  templateId: Scalars['ID']['input'];
};

export type TemplateActivityEventItemInput = {
  afterCallMessageId?: InputMaybe<Scalars['ID']['input']>;
  afterCallMessageOffsetDays?: InputMaybe<Scalars['Int']['input']>;
  beforeCallMessageId?: InputMaybe<Scalars['ID']['input']>;
  beforeCallMessageOffsetDays?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  offsetInDays?: InputMaybe<Scalars['Int']['input']>;
  templateActivity?: InputMaybe<TemplateActivityInputThin>;
  timeOfDay?: InputMaybe<TimeOfDay>;
};

export enum TemplateActivityEventItemSortingField {
  Offset = 'OFFSET'
}

export type TemplateActivityEventItemSortingInput = {
  /** Specifies the direction in which to sort Guided Care Journeys. */
  direction: H_OrderDirection;
  /** Sort journeys by the selected field. */
  field: TemplateActivityEventItemSortingField;
};

export type TemplateActivityInput = {
  afterCallMessageId?: InputMaybe<Scalars['ID']['input']>;
  afterCallMessageOffsetDays?: InputMaybe<Scalars['Int']['input']>;
  beforeCallMessageId?: InputMaybe<Scalars['ID']['input']>;
  beforeCallMessageOffsetDays?: InputMaybe<Scalars['Int']['input']>;
  frequencyType?: InputMaybe<AppointmentFrequencyType>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isModified?: InputMaybe<Scalars['Boolean']['input']>;
  medicalFormClassificationToInterventionLinkList?: InputMaybe<Array<InputMaybe<MedicalFormClassificationToInterventionLinkList>>>;
  medicalFormId?: InputMaybe<Scalars['ID']['input']>;
  medicalMessageId?: InputMaybe<Scalars['ID']['input']>;
  numberOfOccurrences?: InputMaybe<Scalars['Int']['input']>;
  serviceOfHealthProgramTemplateDetails?: InputMaybe<Array<InputMaybe<ServiceDetailsInput>>>;
  templateActivityType?: InputMaybe<GuidedCareActivityType>;
  templateTeamMember?: InputMaybe<ActivityTemplateTeamMemberInput>;
};

export type TemplateActivityInputThin = {
  id: Scalars['ID']['input'];
};

export enum TemplateFieldType {
  AudioFile = 'AUDIO_FILE',
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  List = 'LIST',
  Number = 'NUMBER',
  NumericList = 'NUMERIC_LIST',
  String = 'STRING',
  VideoFile = 'VIDEO_FILE'
}

export type TemplateGoal = H_Node & {
  __typename?: 'TemplateGoal';
  description?: Maybe<Scalars['String']['output']>;
  descriptionAr?: Maybe<Scalars['String']['output']>;
  healthParameter?: Maybe<HealthParameter>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  nameAr?: Maybe<Scalars['String']['output']>;
  target?: Maybe<Scalars['String']['output']>;
  templateRecommendations?: Maybe<Array<Maybe<TemplateRecommendation>>>;
};

export type TemplateGoalInput = {
  description: Scalars['String']['input'];
  descriptionAr?: InputMaybe<Scalars['String']['input']>;
  healthParameterId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  nameAr?: InputMaybe<Scalars['String']['input']>;
  target: Scalars['String']['input'];
  templateRecommendations?: InputMaybe<Array<InputMaybe<TemplateRecommendationInput>>>;
};

export type TemplateIntervention = H_Node & {
  __typename?: 'TemplateIntervention';
  category?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  descriptionAr?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  interventionTemplateActivities?: Maybe<Array<Maybe<TemplateInterventionActivity>>>;
  name?: Maybe<Scalars['String']['output']>;
  nameAr?: Maybe<Scalars['String']['output']>;
};

export type TemplateInterventionActivity = H_Node & {
  __typename?: 'TemplateInterventionActivity';
  afterCallMessage?: Maybe<MedicalMessage>;
  afterCallMessageOffsetDays?: Maybe<Scalars['Int']['output']>;
  beforeCallMessage?: Maybe<MedicalMessage>;
  beforeCallMessageOffsetDays?: Maybe<Scalars['Int']['output']>;
  frequencyType: AppointmentFrequencyType;
  id: Scalars['ID']['output'];
  interventionActivityEventItems?: Maybe<Array<Maybe<TemplateInterventionActivityEventItem>>>;
  medicalForm?: Maybe<MedicalForm>;
  medicalMessage?: Maybe<MedicalMessage>;
  numberOfOccurrences?: Maybe<Scalars['Int']['output']>;
  serviceOfInterventionActivityDetails?: Maybe<Array<Maybe<ServiceDetails>>>;
  templateActivityType?: Maybe<GuidedCareActivityType>;
  templateTeamMember?: Maybe<TemplateTeamMember>;
};

export type TemplateInterventionActivityCrud = {
  __typename?: 'TemplateInterventionActivityCRUD';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  templateActivity?: Maybe<TemplateInterventionActivity>;
  templateActivityErrors?: Maybe<Array<H_EntityError>>;
};

export type TemplateInterventionActivityEventItem = H_Node & {
  __typename?: 'TemplateInterventionActivityEventItem';
  afterCallMessage?: Maybe<MedicalMessage>;
  afterCallMessageOffsetDays?: Maybe<Scalars['Int']['output']>;
  beforeCallMessage?: Maybe<MedicalMessage>;
  beforeCallMessageOffsetDays?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  offsetInDays?: Maybe<Scalars['Int']['output']>;
  templateInterventionActivity?: Maybe<TemplateInterventionActivity>;
  timeOfDay?: Maybe<TimeOfDay>;
};

export type TemplateInterventionActivityEventItemInput = {
  afterCallMessageId?: InputMaybe<Scalars['ID']['input']>;
  afterCallMessageOffsetDays?: InputMaybe<Scalars['Int']['input']>;
  beforeCallMessageId?: InputMaybe<Scalars['ID']['input']>;
  beforeCallMessageOffsetDays?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  offsetInDays?: InputMaybe<Scalars['Int']['input']>;
  timeOfDay?: InputMaybe<TimeOfDay>;
};

export type TemplateInterventionActivityInput = {
  afterCallMessageId?: InputMaybe<Scalars['ID']['input']>;
  afterCallMessageOffsetDays?: InputMaybe<Scalars['Int']['input']>;
  beforeCallMessageId?: InputMaybe<Scalars['ID']['input']>;
  beforeCallMessageOffsetDays?: InputMaybe<Scalars['Int']['input']>;
  frequencyType?: InputMaybe<AppointmentFrequencyType>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isModified?: InputMaybe<Scalars['Boolean']['input']>;
  medicalFormId?: InputMaybe<Scalars['ID']['input']>;
  medicalMessageId?: InputMaybe<Scalars['ID']['input']>;
  numberOfOccurrences?: InputMaybe<Scalars['Int']['input']>;
  serviceOfInterventionActivityDetails?: InputMaybe<Array<InputMaybe<ServiceDetailsInput>>>;
  templateActivityType?: InputMaybe<GuidedCareActivityType>;
  templateTeamMember?: InputMaybe<ActivityTemplateTeamMemberInput>;
};

export type TemplateInterventionInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionAr?: InputMaybe<Scalars['String']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  interventionTemplateActivities?: InputMaybe<Array<InputMaybe<TemplateInterventionActivityInput>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameAr?: InputMaybe<Scalars['String']['input']>;
  uniqueId: Scalars['String']['input'];
};

export type TemplateInterventionInputLight = {
  uniqueId: Scalars['String']['input'];
};

export type TemplateInterventionThinInput = {
  uniqueId: Scalars['String']['input'];
};

export type TemplateRecommendation = H_Node & {
  __typename?: 'TemplateRecommendation';
  code: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  descriptionAr?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  nameAr?: Maybe<Scalars['String']['output']>;
  templateInterventions?: Maybe<Array<Maybe<TemplateIntervention>>>;
};

export type TemplateRecommendationCountableConnection = {
  __typename?: 'TemplateRecommendationCountableConnection';
  edges: Array<TemplateRecommendationCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type TemplateRecommendationCountableEdge = {
  __typename?: 'TemplateRecommendationCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  node: TemplateRecommendation;
};

export type TemplateRecommendationFilterInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type TemplateRecommendationInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  descriptionAr?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameAr?: InputMaybe<Scalars['String']['input']>;
  templateInterventions?: InputMaybe<Array<InputMaybe<TemplateInterventionThinInput>>>;
};

export type TemplateTeamMember = H_Node & {
  __typename?: 'TemplateTeamMember';
  id: Scalars['ID']['output'];
  isBackupPersonMandatory?: Maybe<Scalars['Boolean']['output']>;
  isKeyTeamMember?: Maybe<Scalars['Boolean']['output']>;
  isLicencedHealthProfessional?: Maybe<Scalars['Boolean']['output']>;
  position?: Maybe<TeamMemberPosition>;
  specialization?: Maybe<Scalars['String']['output']>;
  specializationArabicDisplay?: Maybe<Scalars['String']['output']>;
  specializationDisplay?: Maybe<Scalars['String']['output']>;
};

export type TemplateTeamMemberIdInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type TemplateTeamMemberInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  isBackupPersonMandatory: Scalars['Boolean']['input'];
  isKeyTeamMember: Scalars['Boolean']['input'];
  isLicencedHealthProfessional: Scalars['Boolean']['input'];
  position?: InputMaybe<TeamMemberPosition>;
  specialization?: InputMaybe<Scalars['String']['input']>;
  uniqueId: Scalars['String']['input'];
};

export type TerminateVendorSubscription = {
  __typename?: 'TerminateVendorSubscription';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export enum TerminologyErrorCodes {
  InvalidOrExpiredToken = 'INVALID_OR_EXPIRED_TOKEN',
  TerminologyClinicianNotFound = 'TERMINOLOGY_CLINICIAN_NOT_FOUND',
  TerminologyConceptDefinitionListNotFound = 'TERMINOLOGY_CONCEPT_DEFINITION_LIST_NOT_FOUND',
  TerminologyDuplicateColumn = 'TERMINOLOGY_DUPLICATE_COLUMN',
  TerminologyDuplicateProperty = 'TERMINOLOGY_DUPLICATE_PROPERTY',
  TerminologyDuplicateRowData = 'TERMINOLOGY_DUPLICATE_ROW_DATA',
  TerminologyDuplicateVersion = 'TERMINOLOGY_DUPLICATE_VERSION',
  TerminologyEmptyFile = 'TERMINOLOGY_EMPTY_FILE',
  TerminologyFileServiceNotReachable = 'TERMINOLOGY_FILE_SERVICE_NOT_REACHABLE',
  TerminologyInvalidDateFormat = 'TERMINOLOGY_INVALID_DATE_FORMAT',
  TerminologyInvalidFileExtension = 'TERMINOLOGY_INVALID_FILE_EXTENSION',
  TerminologyInvalidProcessingStatus = 'TERMINOLOGY_INVALID_PROCESSING_STATUS',
  TerminologyInvalidToken = 'TERMINOLOGY_INVALID_TOKEN',
  TerminologyListDefinitionNotFound = 'TERMINOLOGY_LIST_Definition_NOT_FOUND',
  TerminologyListItemNotFound = 'TERMINOLOGY_LIST_Item_NOT_FOUND',
  TerminologyListNotFound = 'TERMINOLOGY_LIST_NOT_FOUND',
  TerminologyMandatoryConceptPropertyException = 'TERMINOLOGY_MANDATORY_CONCEPT_PROPERTY_EXCEPTION',
  TerminologyNoneDraftCodeSystem = 'TERMINOLOGY_NONE_DRAFT_CODE_SYSTEM',
  TerminologyNoneEditableConceptDefinition = 'TERMINOLOGY_NONE_EDITABLE_CONCEPT_DEFINITION',
  TerminologyNoneEditableList = 'TERMINOLOGY_NONE_EDITABLE_LIST',
  TerminologyNoneNegativePageSize = 'TERMINOLOGY_NONE_NEGATIVE_PAGE_SIZE',
  TerminologyNoneUnderReviewCodeSystem = 'TERMINOLOGY_NONE_UNDER_REVIEW_CODE_SYSTEM',
  TerminologyNotApprovedCodeSystem = 'TERMINOLOGY_NOT_APPROVED_CODE_SYSTEM',
  TerminologyNotFound = 'TERMINOLOGY_NOT_FOUND',
  TerminologyNoActiveCodeSystem = 'TERMINOLOGY_NO_ACTIVE_CODE_SYSTEM',
  TerminologyOldVersion = 'TERMINOLOGY_OLD_VERSION',
  TerminologyPermissionDenied = 'TERMINOLOGY_PERMISSION_DENIED',
  TerminologyUnknown = 'TERMINOLOGY_UNKNOWN',
  TerminologyUnknownType = 'TERMINOLOGY_UNKNOWN_TYPE',
  TerminologyValidation = 'TERMINOLOGY_VALIDATION'
}

export type TerminologyGraphqlError = {
  __typename?: 'TerminologyGraphqlError';
  code?: Maybe<TerminologyErrorCodes>;
  errorType?: Maybe<ErrorType>;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type TextSummaryError = {
  __typename?: 'TextSummaryError';
  code: TextSummaryErrorCode;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum TextSummaryErrorCode {
  ControlledDrug = 'CONTROLLED_DRUG',
  GraphqlError = 'GRAPHQL_ERROR',
  Ingested = 'INGESTED',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type TextSummaryInput = {
  text: Scalars['String']['input'];
};

export type TextSummaryMutation = {
  __typename?: 'TextSummaryMutation';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  summary?: Maybe<Scalars['String']['output']>;
  textSummaryErrors: Array<TextSummaryError>;
};

export enum TimeOfDay {
  From_0To_2 = 'FROM_0_TO_2',
  From_2To_4 = 'FROM_2_TO_4',
  From_4To_6 = 'FROM_4_TO_6',
  From_6To_8 = 'FROM_6_TO_8',
  From_8To_10 = 'FROM_8_TO_10',
  From_10To_12 = 'FROM_10_TO_12',
  From_12To_14 = 'FROM_12_TO_14',
  From_14To_16 = 'FROM_14_TO_16',
  From_16To_18 = 'FROM_16_TO_18',
  From_18To_20 = 'FROM_18_TO_20',
  From_20To_22 = 'FROM_20_TO_22',
  From_22To_24 = 'FROM_22_TO_24'
}

export type TimeRange = {
  __typename?: 'TimeRange';
  closeTime?: Maybe<Scalars['Time']['output']>;
  openTime?: Maybe<Scalars['Time']['output']>;
};

export type TimeRangeFilterInput = {
  end: Scalars['Time']['input'];
  start: Scalars['Time']['input'];
};

export type TimeRangeInput = {
  closeTime: Scalars['Time']['input'];
  openTime: Scalars['Time']['input'];
};

export type TimeSlot = {
  __typename?: 'TimeSlot';
  endTime?: Maybe<Scalars['Instant']['output']>;
  startTime?: Maybe<Scalars['Instant']['output']>;
};

export type ToGlobalId = {
  __typename?: 'ToGlobalId';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  id?: Maybe<Scalars['ID']['output']>;
};

export enum TrackerActivityType {
  Supplement = 'SUPPLEMENT',
  TradeDrug = 'TRADE_DRUG'
}

export enum TrackerColor {
  Black = 'BLACK',
  Blue = 'BLUE',
  Brown = 'BROWN',
  Green = 'GREEN',
  Orange = 'ORANGE',
  Pink = 'PINK',
  Purple = 'PURPLE',
  Red = 'RED',
  White = 'WHITE',
  Yellow = 'YELLOW'
}

export enum TrackerDayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export enum TrackerDurationType {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export enum TrackerFrequencyType {
  EveryDay = 'EVERY_DAY',
  EveryOtherDay = 'EVERY_OTHER_DAY',
  EveryXDays = 'EVERY_X_DAYS',
  EveryXMonths = 'EVERY_X_MONTHS',
  EveryXWeeks = 'EVERY_X_WEEKS',
  SpecificDaysOfWeek = 'SPECIFIC_DAYS_OF_WEEK'
}

export enum TrackerIntakeInstructions {
  AfterEating = 'AFTER_EATING',
  BeforeEating = 'BEFORE_EATING',
  DoesntMatter = 'DOESNT_MATTER',
  WhileEating = 'WHILE_EATING'
}

export type TrackerReminder = {
  __typename?: 'TrackerReminder';
  id: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
  reminderSettings: TrackerReminderSettings;
  reminderTime: Scalars['Instant']['output'];
  status: TrackerReminderStatus;
  time: TrackerTimeEnum;
  user: User;
};

export type TrackerReminderConnection = {
  __typename?: 'TrackerReminderConnection';
  edges: Array<TrackerReminderEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type TrackerReminderEdge = {
  __typename?: 'TrackerReminderEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: TrackerReminder;
};

export type TrackerReminderInput = {
  activityType: TrackerActivityType;
  reminderSettingsInput: TrackerReminderSettingsInput;
  supplementInput?: InputMaybe<TrackerSupplementInput>;
  tradeDrugInput?: InputMaybe<TrackerTradeDrugInput>;
};

export type TrackerReminderInputFilter = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  reminderTimeGTE?: InputMaybe<Scalars['Instant']['input']>;
  reminderTimeLTE?: InputMaybe<Scalars['Instant']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<TrackerReminderStatus>>>;
};

export type TrackerReminderSettings = {
  __typename?: 'TrackerReminderSettings';
  active: Scalars['Boolean']['output'];
  activityType: TrackerActivityType;
  daysOfWeek?: Maybe<Array<Maybe<TrackerDayOfWeek>>>;
  durationType: TrackerDurationType;
  durationValue: Scalars['Int']['output'];
  endDate: Scalars['Date']['output'];
  frequencyType: TrackerFrequencyType;
  frequencyValue?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  intakeInstructions?: Maybe<TrackerIntakeInstructions>;
  startDate: Scalars['Date']['output'];
  supplement?: Maybe<TrackerSupplement>;
  trackingTimes: Array<TrackerTrackingTime>;
  tradeDrug?: Maybe<TrackerTradeDrug>;
  user: User;
};

export type TrackerReminderSettingsConnection = {
  __typename?: 'TrackerReminderSettingsConnection';
  edges: Array<TrackerReminderSettingsEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type TrackerReminderSettingsEdge = {
  __typename?: 'TrackerReminderSettingsEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: TrackerReminderSettings;
};

export type TrackerReminderSettingsInput = {
  daysOfWeek?: InputMaybe<Array<InputMaybe<TrackerDayOfWeek>>>;
  durationType: TrackerDurationType;
  durationValue: Scalars['Int']['input'];
  frequencyType: TrackerFrequencyType;
  frequencyValue?: InputMaybe<Scalars['Int']['input']>;
  intakeInstructions?: InputMaybe<TrackerIntakeInstructions>;
  startDate: Scalars['Date']['input'];
  trackingTimeInputs: Array<TrackerTrackingTimeInput>;
};

export type TrackerReminderSettingsInputFilter = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  activityType?: InputMaybe<TrackerActivityType>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  startDateGTE?: InputMaybe<Scalars['Instant']['input']>;
  startDateLTE?: InputMaybe<Scalars['Instant']['input']>;
};

export enum TrackerReminderSettingsSortingField {
  StartDate = 'START_DATE'
}

export type TrackerReminderSettingsSortingInput = {
  direction?: InputMaybe<TrackerSortDirection>;
  field?: InputMaybe<TrackerReminderSettingsSortingField>;
};

export enum TrackerReminderSortingField {
  ReminderTime = 'REMINDER_TIME'
}

export type TrackerReminderSortingInput = {
  direction?: InputMaybe<TrackerSortDirection>;
  field?: InputMaybe<TrackerReminderSortingField>;
};

export enum TrackerReminderStatus {
  Cancelled = 'CANCELLED',
  Done = 'DONE',
  Missed = 'MISSED',
  Pending = 'PENDING',
  Skipped = 'SKIPPED',
  TimeComes = 'TIME_COMES'
}

export enum TrackerReminderStatusUpdate {
  Done = 'DONE',
  Skipped = 'SKIPPED'
}

export enum TrackerSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum TrackerStrengthUnit {
  G = 'G',
  Mcg = 'MCG',
  Mg = 'MG',
  Ml = 'ML'
}

export type TrackerSupplement = {
  __typename?: 'TrackerSupplement';
  color?: Maybe<TrackerColor>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  photo?: Maybe<Scalars['String']['output']>;
  strengthUnit?: Maybe<TrackerStrengthUnit>;
  strengthValue?: Maybe<Scalars['Double']['output']>;
  type?: Maybe<TrackerSupplementType>;
};

export type TrackerSupplementConnection = {
  __typename?: 'TrackerSupplementConnection';
  edges: Array<TrackerSupplementEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type TrackerSupplementEdge = {
  __typename?: 'TrackerSupplementEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: TrackerSupplement;
};

export type TrackerSupplementInput = {
  color?: InputMaybe<TrackerColor>;
  name: Scalars['String']['input'];
  photo?: InputMaybe<Scalars['String']['input']>;
  strengthUnit?: InputMaybe<TrackerStrengthUnit>;
  strengthValue?: InputMaybe<Scalars['Double']['input']>;
  typeId?: InputMaybe<Scalars['ID']['input']>;
};

export type TrackerSupplementType = {
  __typename?: 'TrackerSupplementType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  photo?: Maybe<Scalars['String']['output']>;
};

export type TrackerSupplementTypeConnection = {
  __typename?: 'TrackerSupplementTypeConnection';
  edges: Array<TrackerSupplementTypeEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type TrackerSupplementTypeEdge = {
  __typename?: 'TrackerSupplementTypeEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: TrackerSupplementType;
};

export type TrackerSupplementTypeInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  photo?: InputMaybe<Scalars['String']['input']>;
};

export type TrackerSupplementTypeInputFilter = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export enum TrackerTimeEnum {
  ExactTime = 'EXACT_TIME',
  MidDay = 'MID_DAY',
  Morning = 'MORNING',
  Night = 'NIGHT'
}

export type TrackerTrackingTime = {
  __typename?: 'TrackerTrackingTime';
  exactTime?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  quantity: Scalars['Int']['output'];
  time: TrackerTimeEnum;
};

export type TrackerTrackingTimeInput = {
  exactTime?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Int']['input'];
  time: TrackerTimeEnum;
  zoneOffset?: InputMaybe<Scalars['Int']['input']>;
};

export type TrackerTradeDrug = {
  __typename?: 'TrackerTradeDrug';
  color?: Maybe<TrackerColor>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  photo?: Maybe<Scalars['String']['output']>;
  strengthUnit?: Maybe<TrackerStrengthUnit>;
  strengthValue?: Maybe<Scalars['Double']['output']>;
  type?: Maybe<TrackerTradeDrugType>;
};

export type TrackerTradeDrugConnection = {
  __typename?: 'TrackerTradeDrugConnection';
  edges: Array<TrackerTradeDrugEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type TrackerTradeDrugEdge = {
  __typename?: 'TrackerTradeDrugEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: TrackerTradeDrug;
};

export type TrackerTradeDrugInput = {
  color?: InputMaybe<TrackerColor>;
  name: Scalars['String']['input'];
  photo?: InputMaybe<Scalars['String']['input']>;
  strengthUnit?: InputMaybe<TrackerStrengthUnit>;
  strengthValue?: InputMaybe<Scalars['Double']['input']>;
  typeId?: InputMaybe<Scalars['ID']['input']>;
};

export type TrackerTradeDrugType = {
  __typename?: 'TrackerTradeDrugType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  photo?: Maybe<Scalars['String']['output']>;
};

export type TrackerTradeDrugTypeConnection = {
  __typename?: 'TrackerTradeDrugTypeConnection';
  edges: Array<TrackerTradeDrugTypeEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type TrackerTradeDrugTypeEdge = {
  __typename?: 'TrackerTradeDrugTypeEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: TrackerTradeDrugType;
};

export type TrackerTradeDrugTypeInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  photo?: InputMaybe<Scalars['String']['input']>;
};

export type TrackerTradeDrugTypeInputFilter = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type TradeDrug = {
  __typename?: 'TradeDrug';
  code: Scalars['String']['output'];
  dictionaryURL?: Maybe<Scalars['String']['output']>;
  display: Scalars['String']['output'];
  genericCode: Scalars['String']['output'];
  genericDisplay?: Maybe<Scalars['String']['output']>;
  granularUnit?: Maybe<Scalars['Int']['output']>;
  isHazardous?: Maybe<Scalars['Boolean']['output']>;
  isHighAlert?: Maybe<Scalars['Boolean']['output']>;
  isLASA?: Maybe<Scalars['Boolean']['output']>;
  largeIconURL?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Int']['output']>;
  routeOfAdmin?: Maybe<Scalars['String']['output']>;
  smallIconURL?: Maybe<Scalars['String']['output']>;
  unitID?: Maybe<Scalars['Int']['output']>;
};

export type TransactionActivityCodeLookup = {
  __typename?: 'TransactionActivityCodeLookup';
  code?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type TransactionActivityTypeLookup = {
  __typename?: 'TransactionActivityTypeLookup';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
};

export enum TransactionAuthStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export enum TransactionAuthorizationResult {
  No = 'No',
  Pending = 'Pending',
  Yes = 'Yes'
}

export type TransactionClaim = {
  __typename?: 'TransactionClaim';
  Branch?: Maybe<Branch>;
  Vendor?: Maybe<Vendor>;
  activities?: Maybe<Array<Maybe<TransactionClaimActivity>>>;
  allDiagnosis?: Maybe<Scalars['String']['output']>;
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  diagnoses?: Maybe<Array<Maybe<TransactionDiagnosis>>>;
  emiratesIDNumber?: Maybe<Scalars['String']['output']>;
  encounterEnd?: Maybe<Scalars['String']['output']>;
  encounterEndType?: Maybe<Scalars['Int']['output']>;
  encounterEndTypeLookup?: Maybe<TransactionEncounterTypeLookup>;
  encounterStart?: Maybe<Scalars['String']['output']>;
  encounterStartType?: Maybe<Scalars['Int']['output']>;
  encounterStartTypeLookup?: Maybe<TransactionEncounterTypeLookup>;
  encounterType?: Maybe<Scalars['String']['output']>;
  encounterTypeLookup?: Maybe<TransactionEncounterTypeLookup>;
  facility?: Maybe<TransactionFacilitylookup>;
  facilityId?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  gross?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  idPayer?: Maybe<Scalars['String']['output']>;
  memberId?: Maybe<Scalars['String']['output']>;
  nationalIDNumber?: Maybe<Scalars['String']['output']>;
  net?: Maybe<Scalars['Float']['output']>;
  observations?: Maybe<Array<Maybe<TransactionObservation>>>;
  patientId?: Maybe<Scalars['String']['output']>;
  patientShare?: Maybe<Scalars['Float']['output']>;
  payer?: Maybe<TransactionPayerLookup>;
  payerId?: Maybe<Scalars['String']['output']>;
  principalDiagnosis?: Maybe<Scalars['String']['output']>;
  priorAuthorizations?: Maybe<Array<Maybe<TransactionPriorAuthorization>>>;
  provider?: Maybe<TransactionProviderLookup>;
  providerId?: Maybe<Scalars['String']['output']>;
  receiver?: Maybe<TransactionPayerLookup>;
  receiverId?: Maybe<Scalars['String']['output']>;
  remittance?: Maybe<TransactionRemittance>;
  resubmissionAttachment?: Maybe<Scalars['String']['output']>;
  resubmissionComment?: Maybe<Scalars['String']['output']>;
  resubmissionRelatedClaimId?: Maybe<Scalars['String']['output']>;
  resubmissionType?: Maybe<Scalars['String']['output']>;
  sender?: Maybe<TransactionProviderLookup>;
  senderId?: Maybe<Scalars['String']['output']>;
  source: TransactionSource;
  submissionCycle?: Maybe<Scalars['String']['output']>;
  transactionDate: Scalars['String']['output'];
  transactionId: Scalars['String']['output'];
  transactionType?: Maybe<Scalars['String']['output']>;
  weight?: Maybe<Scalars['Float']['output']>;
};

export type TransactionClaimActivity = {
  __typename?: 'TransactionClaimActivity';
  activityId: Scalars['String']['output'];
  claim?: Maybe<TransactionClaim>;
  clinician: Scalars['String']['output'];
  code: Scalars['String']['output'];
  codeLookup?: Maybe<TransactionActivityCodeLookup>;
  duration?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  net: Scalars['Float']['output'];
  observations?: Maybe<Array<Maybe<TransactionObservation>>>;
  patientShare?: Maybe<Scalars['Float']['output']>;
  paymentAmount?: Maybe<Scalars['Float']['output']>;
  priorAuthorizationID?: Maybe<Scalars['String']['output']>;
  priorAuthorizations?: Maybe<Array<Maybe<TransactionPriorAuthorization>>>;
  quantity?: Maybe<Scalars['Float']['output']>;
  start: Scalars['String']['output'];
  type: Scalars['Int']['output'];
  typeLookup?: Maybe<TransactionActivityTypeLookup>;
};

export type TransactionClaimActivityConnection = {
  __typename?: 'TransactionClaimActivityConnection';
  edges?: Maybe<Array<Maybe<TransactionClaimActivityEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type TransactionClaimActivityEdge = {
  __typename?: 'TransactionClaimActivityEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<TransactionClaimActivity>;
};

export type TransactionClaimActivityFilterInput = {
  authorizationId?: InputMaybe<Scalars['ID']['input']>;
  claimId?: InputMaybe<Scalars['ID']['input']>;
};

export type TransactionClaimConnection = {
  __typename?: 'TransactionClaimConnection';
  edges?: Maybe<Array<Maybe<TransactionClaimEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type TransactionClaimEdge = {
  __typename?: 'TransactionClaimEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<TransactionClaim>;
};

export type TransactionClaimFilterInput = {
  emirateIdNumber?: InputMaybe<Scalars['String']['input']>;
  encounterEndDateInput?: InputMaybe<TransactionDateFilterInput>;
  encounterStartDateInput?: InputMaybe<TransactionDateFilterInput>;
  encounterType?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  memberId?: InputMaybe<Scalars['String']['input']>;
  patientId?: InputMaybe<Scalars['String']['input']>;
  payerId?: InputMaybe<Scalars['String']['input']>;
  receiverId?: InputMaybe<Scalars['String']['input']>;
  remittanceId?: InputMaybe<Scalars['ID']['input']>;
  source?: InputMaybe<TransactionSource>;
  submissionCycle?: InputMaybe<Scalars['String']['input']>;
  transactionDateInput?: InputMaybe<TransactionDateFilterInput>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
};

export enum TransactionClaimSortField {
  EncounterEndDate = 'ENCOUNTER_END_DATE',
  EncounterStartDate = 'ENCOUNTER_START_DATE',
  TransactionDate = 'TRANSACTION_DATE'
}

export type TransactionClaimSortingInput = {
  direction?: InputMaybe<TransactionSortDirection>;
  field: TransactionClaimSortField;
};

export type TransactionDateFilterInput = {
  gte?: InputMaybe<Scalars['LocalDateTime']['input']>;
  lte?: InputMaybe<Scalars['LocalDateTime']['input']>;
};

export type TransactionDefaultSortingInput = {
  direction?: InputMaybe<TransactionSortDirection>;
  field: TransactionSortField;
};

export type TransactionDenialLookup = {
  __typename?: 'TransactionDenialLookup';
  code?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type TransactionDiagnosis = {
  __typename?: 'TransactionDiagnosis';
  code?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  type?: Maybe<Scalars['String']['output']>;
};

export type TransactionEncounterTypeLookup = {
  __typename?: 'TransactionEncounterTypeLookup';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
};

export enum TransactionErrorCodes {
  IntegrationActivityCodeNotFound = 'INTEGRATION_ACTIVITY_CODE_NOT_FOUND',
  IntegrationClinicianCredentialsNotFound = 'INTEGRATION_CLINICIAN_CREDENTIALS_NOT_FOUND',
  IntegrationDateOfBirthNotDefined = 'INTEGRATION_DATE_OF_BIRTH_NOT_DEFINED',
  IntegrationDuplicateBranchCredentials = 'INTEGRATION_DUPLICATE_BRANCH_CREDENTIALS',
  IntegrationErxValidation = 'INTEGRATION_ERX_VALIDATION',
  IntegrationInvalidOrExpiredToken = 'INTEGRATION_INVALID_OR_EXPIRED_TOKEN',
  IntegrationMemberNotActive = 'INTEGRATION_MEMBER_NOT_ACTIVE',
  IntegrationNoneNegativePageSize = 'INTEGRATION_NONE_NEGATIVE_PAGE_SIZE',
  IntegrationNullPointer = 'INTEGRATION_NULL_POINTER',
  IntegrationOperationNotAllowed = 'INTEGRATION_OPERATION_NOT_ALLOWED',
  IntegrationPatientNotFound = 'INTEGRATION_PATIENT_NOT_FOUND',
  IntegrationPayerLicenseNotDefined = 'INTEGRATION_PAYER_LICENSE_NOT_DEFINED',
  IntegrationPermissionDenied = 'INTEGRATION_PERMISSION_DENIED',
  IntegrationUnknown = 'INTEGRATION_UNKNOWN',
  IntegrationUnknownType = 'INTEGRATION_UNKNOWN_TYPE',
  InvalidClinicianCredentials = 'INVALID_CLINICIAN_CREDENTIALS'
}

export type TransactionFacilitylookup = {
  __typename?: 'TransactionFacilitylookup';
  eclaimId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export enum TransactionFrequencyType {
  Day = 'Day',
  Hour = 'Hour',
  Month = 'Month',
  Week = 'Week'
}

export type TransactionGraphqlError = {
  __typename?: 'TransactionGraphqlError';
  code?: Maybe<TransactionErrorCodes>;
  errorType?: Maybe<ErrorType>;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type TransactionObservation = {
  __typename?: 'TransactionObservation';
  code?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  valueType?: Maybe<Scalars['String']['output']>;
};

export enum TransactionOrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type TransactionPayerLookup = {
  __typename?: 'TransactionPayerLookup';
  eclaimId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type TransactionPriorAuthorization = {
  __typename?: 'TransactionPriorAuthorization';
  Branch?: Maybe<Branch>;
  Vendor?: Maybe<Vendor>;
  activities?: Maybe<Array<Maybe<TransactionPriorAuthorizationActivity>>>;
  comments?: Maybe<Scalars['String']['output']>;
  denialCode?: Maybe<Scalars['String']['output']>;
  denialLookup?: Maybe<TransactionDenialLookup>;
  end: Scalars['String']['output'];
  hasClaimActivities?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  idPayer?: Maybe<Scalars['String']['output']>;
  limit?: Maybe<Scalars['String']['output']>;
  priorRequest?: Maybe<TransactionPriorRequest>;
  receiver?: Maybe<TransactionProviderLookup>;
  receiverId?: Maybe<Scalars['String']['output']>;
  result?: Maybe<Scalars['String']['output']>;
  sender?: Maybe<TransactionPayerLookup>;
  senderId?: Maybe<Scalars['String']['output']>;
  source: TransactionSource;
  start: Scalars['String']['output'];
  status?: Maybe<TransactionTransactionStatus>;
  transactionDate: Scalars['String']['output'];
  transactionId: Scalars['String']['output'];
  transactionType?: Maybe<Scalars['String']['output']>;
};

export type TransactionPriorAuthorizationActivity = {
  __typename?: 'TransactionPriorAuthorizationActivity';
  activityId?: Maybe<Scalars['String']['output']>;
  claimed?: Maybe<Scalars['Boolean']['output']>;
  clinician?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  codeLookup?: Maybe<TransactionActivityCodeLookup>;
  denialCode?: Maybe<Scalars['String']['output']>;
  denialLookup?: Maybe<TransactionDenialLookup>;
  duration?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  net?: Maybe<Scalars['Float']['output']>;
  observations?: Maybe<Array<Maybe<TransactionObservation>>>;
  patientShare?: Maybe<Scalars['Float']['output']>;
  paymentAmount?: Maybe<Scalars['Float']['output']>;
  priorAuthorizationID?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  start?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
  typeLookup?: Maybe<TransactionActivityTypeLookup>;
};

export type TransactionPriorAuthorizationActivityConnection = {
  __typename?: 'TransactionPriorAuthorizationActivityConnection';
  edges?: Maybe<Array<Maybe<TransactionPriorAuthorizationActivityEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type TransactionPriorAuthorizationActivityEdge = {
  __typename?: 'TransactionPriorAuthorizationActivityEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<TransactionPriorAuthorizationActivity>;
};

export type TransactionPriorAuthorizationActivityFilterInput = {
  priorAuthorizationId?: InputMaybe<Scalars['ID']['input']>;
};

export type TransactionPriorAuthorizationConnection = {
  __typename?: 'TransactionPriorAuthorizationConnection';
  edges?: Maybe<Array<Maybe<TransactionPriorAuthorizationEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type TransactionPriorAuthorizationEdge = {
  __typename?: 'TransactionPriorAuthorizationEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<TransactionPriorAuthorization>;
};

export type TransactionPriorAuthorizationFilterInput = {
  denialCode?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  priorRequestId?: InputMaybe<Scalars['ID']['input']>;
  receiverId?: InputMaybe<Scalars['String']['input']>;
  senderId?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<TransactionSource>;
  status?: InputMaybe<Array<InputMaybe<TransactionTransactionStatus>>>;
  transactionDateInput?: InputMaybe<TransactionDateFilterInput>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
};

export type TransactionPriorRequest = {
  __typename?: 'TransactionPriorRequest';
  Branch?: Maybe<Branch>;
  Vendor?: Maybe<Vendor>;
  activities?: Maybe<Array<Maybe<TransactionPriorRequestActivity>>>;
  comments?: Maybe<Scalars['String']['output']>;
  dateOfBirth?: Maybe<Scalars['String']['output']>;
  dateOrdered?: Maybe<Scalars['String']['output']>;
  denialCode?: Maybe<Scalars['String']['output']>;
  diagnoses?: Maybe<Array<Maybe<TransactionDiagnosis>>>;
  emiratesIDNumber?: Maybe<Scalars['String']['output']>;
  encounterEnd?: Maybe<Scalars['String']['output']>;
  encounterStart?: Maybe<Scalars['String']['output']>;
  encounterStartType?: Maybe<Scalars['Int']['output']>;
  encounterType?: Maybe<Scalars['String']['output']>;
  encounterTypeLookup?: Maybe<TransactionEncounterTypeLookup>;
  facility?: Maybe<TransactionFacilitylookup>;
  facilityId?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  gross?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  limit?: Maybe<Scalars['Float']['output']>;
  memberId?: Maybe<Scalars['String']['output']>;
  net?: Maybe<Scalars['Float']['output']>;
  patientId?: Maybe<Scalars['String']['output']>;
  patientShare?: Maybe<Scalars['Float']['output']>;
  payer?: Maybe<TransactionPayerLookup>;
  payerId?: Maybe<Scalars['String']['output']>;
  principalDiagnosis?: Maybe<Scalars['String']['output']>;
  priorAuthorization?: Maybe<TransactionPriorAuthorization>;
  receiver?: Maybe<TransactionPayerLookup>;
  receiverId?: Maybe<Scalars['String']['output']>;
  requestType?: Maybe<Scalars['String']['output']>;
  result?: Maybe<TransactionAuthorizationResult>;
  sender?: Maybe<TransactionProviderLookup>;
  senderId?: Maybe<Scalars['String']['output']>;
  source: TransactionSource;
  transactionDate: Scalars['String']['output'];
  transactionId: Scalars['String']['output'];
  transactionType?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type TransactionPriorRequestActivity = {
  __typename?: 'TransactionPriorRequestActivity';
  activityId?: Maybe<Scalars['String']['output']>;
  activityReference?: Maybe<Scalars['String']['output']>;
  activityResults?: Maybe<Array<Maybe<TransactionPriorRequestActivityResult>>>;
  alternativeReason?: Maybe<Scalars['String']['output']>;
  arabicInstructions?: Maybe<Scalars['String']['output']>;
  clinician?: Maybe<Scalars['String']['output']>;
  code: Scalars['String']['output'];
  codeLookup?: Maybe<TransactionActivityCodeLookup>;
  denialCode?: Maybe<Scalars['String']['output']>;
  dispenseTrack?: Maybe<Array<Maybe<TransactionPriorRequestActivityDispenseTrack>>>;
  dispensedActivityId?: Maybe<Scalars['String']['output']>;
  dosageAsNeeded?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Float']['output']>;
  frequencyDuration?: Maybe<Scalars['Float']['output']>;
  frequencyQuantity?: Maybe<Scalars['Float']['output']>;
  frequencyType?: Maybe<Scalars['String']['output']>;
  frequencyValue?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  instructionType?: Maybe<Scalars['String']['output']>;
  instructions?: Maybe<Scalars['String']['output']>;
  nHICCode?: Maybe<Scalars['String']['output']>;
  net?: Maybe<Scalars['Float']['output']>;
  observations?: Maybe<Array<Maybe<TransactionObservation>>>;
  patientShare?: Maybe<Scalars['Float']['output']>;
  paymentAmount?: Maybe<Scalars['Float']['output']>;
  pharmacistInstructions?: Maybe<Scalars['String']['output']>;
  priorAuthorizationID?: Maybe<Scalars['String']['output']>;
  prn?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  refills?: Maybe<Scalars['Int']['output']>;
  remainingRefills?: Maybe<Scalars['Float']['output']>;
  repeatPattern?: Maybe<Scalars['Float']['output']>;
  routOfAdmin?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  type: Scalars['Int']['output'];
  typeLookup?: Maybe<TransactionActivityTypeLookup>;
  unitId?: Maybe<Scalars['Int']['output']>;
  unitPerFrequency?: Maybe<Scalars['String']['output']>;
};

export type TransactionPriorRequestActivityConnection = {
  __typename?: 'TransactionPriorRequestActivityConnection';
  edges?: Maybe<Array<Maybe<TransactionPriorRequestActivityEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type TransactionPriorRequestActivityDispenseTrack = {
  __typename?: 'TransactionPriorRequestActivityDispenseTrack';
  activityDescription?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  dispenseDate?: Maybe<Scalars['String']['output']>;
  dispensedQuantity?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  refillNumber?: Maybe<Scalars['Int']['output']>;
  sfdaCode?: Maybe<Scalars['String']['output']>;
};

export type TransactionPriorRequestActivityEdge = {
  __typename?: 'TransactionPriorRequestActivityEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<TransactionPriorRequestActivity>;
};

export type TransactionPriorRequestActivityFilterInput = {
  priorRequestId?: InputMaybe<Scalars['ID']['input']>;
};

export type TransactionPriorRequestActivityResult = {
  __typename?: 'TransactionPriorRequestActivityResult';
  id: Scalars['ID']['output'];
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type TransactionPriorRequestConnection = {
  __typename?: 'TransactionPriorRequestConnection';
  edges?: Maybe<Array<Maybe<TransactionPriorRequestEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type TransactionPriorRequestEdge = {
  __typename?: 'TransactionPriorRequestEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<TransactionPriorRequest>;
};

export type TransactionPriorRequestFilterInput = {
  authorizationStatus?: InputMaybe<Array<InputMaybe<TransactionTransactionStatus>>>;
  emirateIdNumber?: InputMaybe<Scalars['String']['input']>;
  encounterType?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  memberId?: InputMaybe<Scalars['String']['input']>;
  payerId?: InputMaybe<Scalars['String']['input']>;
  priorAuthorizationId?: InputMaybe<Scalars['ID']['input']>;
  receiverId?: InputMaybe<Scalars['String']['input']>;
  requestType?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<TransactionSource>;
  transactionDateInput?: InputMaybe<TransactionDateFilterInput>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type TransactionProviderLookup = {
  __typename?: 'TransactionProviderLookup';
  eclaimId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type TransactionRemittance = {
  __typename?: 'TransactionRemittance';
  Branch?: Maybe<Branch>;
  Vendor?: Maybe<Vendor>;
  activities?: Maybe<Array<Maybe<TransactionRemittanceActivity>>>;
  claim?: Maybe<TransactionClaim>;
  dateSettlement?: Maybe<Scalars['String']['output']>;
  denialCode?: Maybe<Scalars['String']['output']>;
  encounterEnd?: Maybe<Scalars['String']['output']>;
  encounterStart?: Maybe<Scalars['String']['output']>;
  encounterStartType?: Maybe<Scalars['Int']['output']>;
  encounterType?: Maybe<Scalars['String']['output']>;
  encounterTypeLookup?: Maybe<TransactionEncounterTypeLookup>;
  facility?: Maybe<TransactionFacilitylookup>;
  facilityId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  idPayer?: Maybe<Scalars['String']['output']>;
  paymentReference?: Maybe<Scalars['String']['output']>;
  provider?: Maybe<TransactionProviderLookup>;
  providerId?: Maybe<Scalars['String']['output']>;
  receiver?: Maybe<TransactionProviderLookup>;
  receiverId?: Maybe<Scalars['String']['output']>;
  sender?: Maybe<TransactionPayerLookup>;
  senderId?: Maybe<Scalars['String']['output']>;
  source: TransactionSource;
  status?: Maybe<TransactionTransactionStatus>;
  transactionDate: Scalars['String']['output'];
  transactionId: Scalars['String']['output'];
  transactionType?: Maybe<Scalars['String']['output']>;
};

export type TransactionRemittanceActivity = {
  __typename?: 'TransactionRemittanceActivity';
  activityId: Scalars['String']['output'];
  activityPenalty?: Maybe<Scalars['String']['output']>;
  clinician: Scalars['String']['output'];
  code: Scalars['String']['output'];
  codeLookup?: Maybe<TransactionActivityCodeLookup>;
  denialCode?: Maybe<Scalars['String']['output']>;
  denialLookup?: Maybe<TransactionDenialLookup>;
  duration?: Maybe<Scalars['Float']['output']>;
  gross?: Maybe<Scalars['Float']['output']>;
  id: Scalars['ID']['output'];
  list?: Maybe<Scalars['Float']['output']>;
  net: Scalars['Float']['output'];
  patientShare?: Maybe<Scalars['Float']['output']>;
  paymentAmount?: Maybe<Scalars['Float']['output']>;
  priorAuthorizationID?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  start: Scalars['String']['output'];
  type: Scalars['Int']['output'];
  typeLookup?: Maybe<TransactionActivityTypeLookup>;
};

export type TransactionRemittanceActivityConnection = {
  __typename?: 'TransactionRemittanceActivityConnection';
  edges?: Maybe<Array<Maybe<TransactionRemittanceActivityEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type TransactionRemittanceActivityEdge = {
  __typename?: 'TransactionRemittanceActivityEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<TransactionRemittanceActivity>;
};

export type TransactionRemittanceActivityFilterInput = {
  remittanceId?: InputMaybe<Scalars['ID']['input']>;
};

export type TransactionRemittanceConnection = {
  __typename?: 'TransactionRemittanceConnection';
  edges?: Maybe<Array<Maybe<TransactionRemittanceEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type TransactionRemittanceEdge = {
  __typename?: 'TransactionRemittanceEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<TransactionRemittance>;
};

export type TransactionRemittanceFilterInput = {
  claimId?: InputMaybe<Scalars['ID']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  receiverId?: InputMaybe<Scalars['String']['input']>;
  senderId?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<TransactionSource>;
  status?: InputMaybe<Array<InputMaybe<TransactionTransactionStatus>>>;
  transactionDateInput?: InputMaybe<TransactionDateFilterInput>;
  transactionId?: InputMaybe<Scalars['String']['input']>;
};

export type TransactionReport = {
  __typename?: 'TransactionReport';
  buildNumber?: Maybe<Scalars['String']['output']>;
  lastPayment?: Maybe<PaymentResponse>;
  lastPaymentPending?: Maybe<Scalars['Boolean']['output']>;
  lastPaymentSucceeded?: Maybe<Scalars['Boolean']['output']>;
  ndc?: Maybe<Scalars['String']['output']>;
  payments?: Maybe<Array<Maybe<PaymentResponse>>>;
  result?: Maybe<PaymentResponseResult>;
  timestamp?: Maybe<Scalars['String']['output']>;
  timestampInstant?: Maybe<Scalars['Instant']['output']>;
};

export enum TransactionSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum TransactionSortField {
  TransactionDate = 'TRANSACTION_DATE'
}

export enum TransactionSource {
  Eclaim = 'ECLAIM',
  Riayati = 'RIAYATI'
}

export enum TransactionTransactionStatus {
  FullyApproved = 'FULLY_APPROVED',
  PartiallyApproved = 'PARTIALLY_APPROVED',
  Rejected = 'REJECTED'
}

export enum TransactionVisitPrescriptionSortField {
  CreatedDate = 'CREATED_DATE'
}

export type TranscribeError = {
  __typename?: 'TranscribeError';
  code: TranscribeErrorCode;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum TranscribeErrorCode {
  ControlledDrug = 'CONTROLLED_DRUG',
  GraphqlError = 'GRAPHQL_ERROR',
  Ingested = 'INGESTED',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type TranscribeInput = {
  fileUrl: Scalars['String']['input'];
  visitId: Scalars['Int']['input'];
};

export type Transcript = {
  __typename?: 'Transcript';
  content?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['Instant']['output'];
  date?: Maybe<Scalars['Instant']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  done?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  language?: Maybe<Scalars['String']['output']>;
  meeting?: Maybe<Meeting>;
  msgId?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['Instant']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};

export type TranscriptConnection = {
  __typename?: 'TranscriptConnection';
  edges: Array<TranscriptEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type TranscriptEdge = {
  __typename?: 'TranscriptEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: Transcript;
};

export type TranscriptFilterInput = {
  meetingId: Scalars['ID']['input'];
  text?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export enum TranscriptionSortingField {
  Date = 'DATE',
  UserId = 'USER_ID'
}

export type TranscriptionSortingInput = {
  direction?: InputMaybe<CommunicationSortDirection>;
  field?: InputMaybe<TranscriptionSortingField>;
};

export enum Type {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Float = 'FLOAT',
  Integer = 'INTEGER',
  String = 'STRING'
}

export type TypeMutationInsertUnattendedCall = {
  __typename?: 'TypeMutationInsertUnattendedCall';
  result?: Maybe<Scalars['String']['output']>;
  unattendedCallErrors?: Maybe<Array<H_EntityError>>;
};

export type TypePatientEligibilityAuthorizationInfo = {
  __typename?: 'TypePatientEligibilityAuthorizationInfo';
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  summary?: Maybe<Scalars['String']['output']>;
};

export type TypeProviderGuidedCareProgramTeamMemberCountableConnection = {
  __typename?: 'TypeProviderGuidedCareProgramTeamMemberCountableConnection';
  edges: Array<TypeProviderGuidedCareProgramTeamMemberCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type TypeProviderGuidedCareProgramTeamMemberCountableEdge = {
  __typename?: 'TypeProviderGuidedCareProgramTeamMemberCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: ProviderGuidedCareProgramTeamMember;
};

export type UnAssignUserFromBranch = {
  __typename?: 'UnAssignUserFromBranch';
  branchErrors: Array<BranchError>;
  branchUser?: Maybe<BranchUser>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type UnattendedCallInput = {
  doctorId: Scalars['Int']['input'];
  doctorUserId: Scalars['Int']['input'];
  healthProgramId: Scalars['Int']['input'];
  patientId: Scalars['Int']['input'];
  patientUserId: Scalars['Int']['input'];
  vendorId: Scalars['Int']['input'];
  visitId: Scalars['Int']['input'];
};

export type UnbindDependent = {
  __typename?: 'UnbindDependent';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type UnbindDependentInput = {
  dependentUserId: Scalars['ID']['input'];
};

export type UnbindParent = {
  __typename?: 'UnbindParent';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type UnverifiedNationals = Node & {
  __typename?: 'UnverifiedNationals';
  created: Scalars['DateTime']['output'];
  documentExpiryDate: Scalars['Date']['output'];
  frontImageFileName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  idType?: Maybe<UnverifiedNationalsIdType>;
  modified: Scalars['DateTime']['output'];
  nationalId: Scalars['String']['output'];
  nationality?: Maybe<Scalars['String']['output']>;
  rearImageFileName: Scalars['String']['output'];
  rejectionReason?: Maybe<Scalars['String']['output']>;
  status?: Maybe<UnverifiedNationalsStatus>;
  user: User;
};

export type UnverifiedNationalsCountableConnection = {
  __typename?: 'UnverifiedNationalsCountableConnection';
  edges: Array<UnverifiedNationalsCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type UnverifiedNationalsCountableEdge = {
  __typename?: 'UnverifiedNationalsCountableEdge';
  cursor: Scalars['String']['output'];
  node: UnverifiedNationals;
};

export type UnverifiedNationalsFilterInput = {
  status?: InputMaybe<Array<InputMaybe<UnverifiedNationalsStatusesEnum>>>;
};

export enum UnverifiedNationalsIdType {
  NationalCard = 'NATIONAL_CARD',
  Passport = 'PASSPORT',
  ResidentCard = 'RESIDENT_CARD'
}

export enum UnverifiedNationalsStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export enum UnverifiedNationalsStatusesEnum {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type UpdateUserEmailMobile = {
  __typename?: 'UpdateUserEmailMobile';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type UpdateUserEmailMobileInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  emailToken?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  mobileToken?: InputMaybe<Scalars['String']['input']>;
};

export type UploadAttachment = {
  __typename?: 'UploadAttachment';
  attachment?: Maybe<Attachment>;
  chatErrors: Array<ChatError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
};

export type UploadNationalCard = {
  __typename?: 'UploadNationalCard';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  unverifiedNationals?: Maybe<UnverifiedNationals>;
};

export type UploadNationalCardInput = {
  documentExpiryDate: Scalars['Date']['input'];
  frontImageFileName: Scalars['String']['input'];
  idType: PatientIdTypeEnum;
  nationalId: Scalars['String']['input'];
  nationality: Scalars['String']['input'];
  rearImageFileName: Scalars['String']['input'];
};

export type User = Node & {
  __typename?: 'User';
  activeConsentForms?: Maybe<PatientProfileConsentFormConnection>;
  addresses?: Maybe<Array<Maybe<Address>>>;
  appRole?: Maybe<AppRoleTypes>;
  appType?: Maybe<AppTypes>;
  avatar?: Maybe<Scalars['String']['output']>;
  branches?: Maybe<Array<Maybe<Branch>>>;
  consentFormCode?: Maybe<PatientProfileConsentCode>;
  dateJoined: Scalars['DateTime']['output'];
  dateOfBirth?: Maybe<Scalars['Date']['output']>;
  defaultBillingAddress?: Maybe<Address>;
  defaultBranch?: Maybe<Branch>;
  defaultShippingAddress?: Maybe<Address>;
  dentalHygienist?: Maybe<DentalHygienist>;
  departments?: Maybe<Array<Maybe<Department>>>;
  dependents?: Maybe<Array<Maybe<User>>>;
  diabetesEducator?: Maybe<DiabetesEducator>;
  doctor?: Maybe<Doctor>;
  /** must be called by vendor nurse user or vendor doctor */
  doctorNurseAssignments?: Maybe<DoctorNurseAssignmentCountableConnection>;
  editableGroups?: Maybe<Array<Maybe<Group>>>;
  email?: Maybe<Scalars['String']['output']>;
  emailVerified: Scalars['Boolean']['output'];
  events?: Maybe<Array<Maybe<CustomerEvent>>>;
  firstName?: Maybe<Scalars['String']['output']>;
  fitnessCoach?: Maybe<FitnessCoach>;
  followedHealthChannels?: Maybe<HealthChannelCountableConnection>;
  fullName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<UserGender>;
  healthLicenseEndDate?: Maybe<Scalars['Date']['output']>;
  healthLicenseNumber?: Maybe<Scalars['String']['output']>;
  healthLicenseStartDate?: Maybe<Scalars['Date']['output']>;
  healthProgramsSubscriptions?: Maybe<HealthProgramUserSubscriptionCountableConnection>;
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  isStaff?: Maybe<Scalars['Boolean']['output']>;
  isSuperuser?: Maybe<Scalars['Boolean']['output']>;
  isVendorAdmin?: Maybe<Scalars['Boolean']['output']>;
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  lastMessage?: Maybe<Message>;
  lastName?: Maybe<Scalars['String']['output']>;
  manager?: Maybe<Manager>;
  marketplaceCart?: Maybe<MarketplaceCart>;
  marketplaceOrders?: Maybe<MarketplaceOrderConnection>;
  marketplaceWishlist?: Maybe<MarketplaceWishlist>;
  meetingPlatformId?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<MessageCountableConnection>;
  mobile?: Maybe<Scalars['String']['output']>;
  mobileVerified: Scalars['Boolean']['output'];
  nationalId?: Maybe<Scalars['String']['output']>;
  needActionCodeSystems?: Maybe<NeedActionCodeSystems>;
  note?: Maybe<Scalars['String']['output']>;
  notifications?: Maybe<UserNotificationConnection>;
  nurse?: Maybe<Nurse>;
  nutritionist?: Maybe<Nutritionist>;
  optometrist?: Maybe<Optometrist>;
  orders?: Maybe<OrderCountableConnection>;
  parentUser?: Maybe<User>;
  patient?: Maybe<Patient>;
  paymentReceipts: PaymentReceiptCountableConnection;
  pendingConsentForms?: Maybe<PatientProfileConsentFormConnection>;
  permissionGroups?: Maybe<Array<Maybe<Group>>>;
  pharmacist?: Maybe<Pharmacist>;
  photo?: Maybe<Scalars['String']['output']>;
  podiatricMedicalAssistant?: Maybe<PodiatricMedicalAssistant>;
  preferredLanguage?: Maybe<Language>;
  prescriptions?: Maybe<PrescriptionCountableConnection>;
  psychologist?: Maybe<Psychologist>;
  receptionist?: Maybe<Receptionist>;
  relationType?: Maybe<RelationTypes>;
  secondName?: Maybe<Scalars['String']['output']>;
  socialWorker?: Maybe<SocialWorker>;
  termsAndConditionsAcceptedVersion?: Maybe<Scalars['Float']['output']>;
  thirdName?: Maybe<Scalars['String']['output']>;
  unReadMessagesCount?: Maybe<Scalars['Int']['output']>;
  unverifiedNationals?: Maybe<Array<Maybe<UnverifiedNationals>>>;
  userPermissions?: Maybe<Array<Maybe<UserPermission>>>;
  vendor?: Maybe<Vendor>;
  vendorUserType?: Maybe<UserVendorUserType>;
  visits?: Maybe<VisitCountableConnection>;
};


export type UserActiveConsentFormsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserDoctorNurseAssignmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<DoctorNurseAssignmentFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserFollowedHealthChannelsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserHealthProgramsSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<HealthProgramUserSubscriptionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserMarketplaceOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<MarketplaceOrderFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<MarketplaceOrderSortingInput>;
};


export type UserMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  branchId: Scalars['ID']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<UserNotificationFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<UserNotificationSortingInput>;
};


export type UserOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<OrderFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserPaymentReceiptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PaymentReceiptFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserPendingConsentFormsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserPrescriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PrescriptionFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type UserVisitsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<VisitFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type UserAction = {
  __typename?: 'UserAction';
  imageURL?: Maybe<Scalars['String']['output']>;
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  messageAR?: Maybe<Scalars['String']['output']>;
  messageEN?: Maybe<Scalars['String']['output']>;
  requiredAction?: Maybe<Scalars['String']['output']>;
};

export type UserBranchInput = {
  branch?: InputMaybe<Scalars['ID']['input']>;
  user?: InputMaybe<Scalars['ID']['input']>;
};

export type UserCard = {
  __typename?: 'UserCard';
  cardToken?: Maybe<Scalars['String']['output']>;
  expiryMonth?: Maybe<Scalars['Int']['output']>;
  expiryYear?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  lastFourDigits?: Maybe<Scalars['String']['output']>;
  paymentBrand?: Maybe<PaymentBrand>;
  userId?: Maybe<Scalars['ID']['output']>;
};

export type UserCardCrud = {
  __typename?: 'UserCardCRUD';
  entity?: Maybe<UserCard>;
  entityErrors?: Maybe<Array<H_EntityError>>;
};

export type UserCardConnection = {
  __typename?: 'UserCardConnection';
  edges?: Maybe<Array<Maybe<UserCardEdge>>>;
  pageInfo?: Maybe<H_PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type UserCardEdge = {
  __typename?: 'UserCardEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<UserCard>;
};

export type UserCountableConnection = {
  __typename?: 'UserCountableConnection';
  edges: Array<UserCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type UserCountableEdge = {
  __typename?: 'UserCountableEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type UserCreate = {
  __typename?: 'UserCreate';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type UserDelete = {
  __typename?: 'UserDelete';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type UserFilterInput = {
  appRole?: InputMaybe<AppRoleTypes>;
  appType?: InputMaybe<Array<InputMaybe<AppTypes>>>;
  branchId?: InputMaybe<Scalars['String']['input']>;
  dateJoined?: InputMaybe<DateRangeInput>;
  departmentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  groupName?: InputMaybe<Scalars['String']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  joinedPeriod?: InputMaybe<ReportingPeriod>;
  search?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<UserStatus>;
  vendorId?: InputMaybe<Scalars['String']['input']>;
  vendorUserType?: InputMaybe<Array<InputMaybe<VendorUserTypes>>>;
};

export enum UserGender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type UserManagementSiteSettings = Node & {
  __typename?: 'UserManagementSiteSettings';
  deleteAccountEmailMessage?: Maybe<Scalars['String']['output']>;
  deleteAccountEmailMessageAr?: Maybe<Scalars['String']['output']>;
  deleteAccountSmsMessage?: Maybe<Scalars['String']['output']>;
  deleteAccountSmsMessageAr?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  microserviceContextPath?: Maybe<Scalars['String']['output']>;
  passwordResetEmailMessage?: Maybe<Scalars['String']['output']>;
  passwordResetEmailMessageAr?: Maybe<Scalars['String']['output']>;
  passwordResetSmsMessage?: Maybe<Scalars['String']['output']>;
  passwordResetSmsMessageAr?: Maybe<Scalars['String']['output']>;
  patientCreateEmailMessage?: Maybe<Scalars['String']['output']>;
  patientCreateEmailMessageAr?: Maybe<Scalars['String']['output']>;
  patientCreateSmsMessage?: Maybe<Scalars['String']['output']>;
  patientCreateSmsMessageAr?: Maybe<Scalars['String']['output']>;
  patientOrderOtpEmailMessage?: Maybe<Scalars['String']['output']>;
  patientOrderOtpEmailMessageAr?: Maybe<Scalars['String']['output']>;
  patientOrderOtpSmsMessage?: Maybe<Scalars['String']['output']>;
  patientOrderOtpSmsMessageAr?: Maybe<Scalars['String']['output']>;
  rangeExpansionMaxNumberOfRounds?: Maybe<Scalars['Int']['output']>;
  rangeExpansionMaxNumberOfTries?: Maybe<Scalars['Int']['output']>;
  rangeExpansionRoundMaxNumberOfPharmacies?: Maybe<Scalars['Int']['output']>;
  rangeExpansionRoundPharmaciesTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  rangeExpansionRoundRadius?: Maybe<Scalars['Float']['output']>;
  rangeExpansionTimeOutPeriod?: Maybe<Scalars['Int']['output']>;
  registrationEmailMessage?: Maybe<Scalars['String']['output']>;
  registrationEmailMessageAr?: Maybe<Scalars['String']['output']>;
  registrationSmsMessage?: Maybe<Scalars['String']['output']>;
  registrationSmsMessageAr?: Maybe<Scalars['String']['output']>;
  termsAndConditionsAcceptedVersion?: Maybe<Scalars['Float']['output']>;
  termsAndConditionsVersion?: Maybe<Scalars['Float']['output']>;
};

export type UserManagementSiteSettingsError = {
  __typename?: 'UserManagementSiteSettingsError';
  code: SiteErrorCode;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type UserManagementSiteSettingsInput = {
  deleteAccountEmailMessage?: InputMaybe<Scalars['String']['input']>;
  deleteAccountEmailMessageAr?: InputMaybe<Scalars['String']['input']>;
  deleteAccountSmsMessage?: InputMaybe<Scalars['String']['input']>;
  deleteAccountSmsMessageAr?: InputMaybe<Scalars['String']['input']>;
  microserviceContextPath?: InputMaybe<Scalars['String']['input']>;
  passwordResetEmailMessage?: InputMaybe<Scalars['String']['input']>;
  passwordResetEmailMessageAr?: InputMaybe<Scalars['String']['input']>;
  passwordResetSmsMessage?: InputMaybe<Scalars['String']['input']>;
  passwordResetSmsMessageAr?: InputMaybe<Scalars['String']['input']>;
  patientCreateEmailMessage?: InputMaybe<Scalars['String']['input']>;
  patientCreateEmailMessageAr?: InputMaybe<Scalars['String']['input']>;
  patientCreateSmsMessage?: InputMaybe<Scalars['String']['input']>;
  patientCreateSmsMessageAr?: InputMaybe<Scalars['String']['input']>;
  patientOrderOtpEmailMessage?: InputMaybe<Scalars['String']['input']>;
  patientOrderOtpEmailMessageAr?: InputMaybe<Scalars['String']['input']>;
  patientOrderOtpSmsMessage?: InputMaybe<Scalars['String']['input']>;
  patientOrderOtpSmsMessageAr?: InputMaybe<Scalars['String']['input']>;
  rangeExpansionMaxNumberOfRounds?: InputMaybe<Scalars['Int']['input']>;
  rangeExpansionMaxNumberOfTries?: InputMaybe<Scalars['Int']['input']>;
  rangeExpansionRoundMaxNumberOfPharmacies?: InputMaybe<Scalars['Int']['input']>;
  rangeExpansionRoundPharmaciesTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  rangeExpansionRoundRadius?: InputMaybe<Scalars['Float']['input']>;
  rangeExpansionTimeOutPeriod?: InputMaybe<Scalars['Int']['input']>;
  registrationEmailMessage?: InputMaybe<Scalars['String']['input']>;
  registrationEmailMessageAr?: InputMaybe<Scalars['String']['input']>;
  registrationSmsMessage?: InputMaybe<Scalars['String']['input']>;
  registrationSmsMessageAr?: InputMaybe<Scalars['String']['input']>;
  termsAndConditionsAcceptedVersion?: InputMaybe<Scalars['Float']['input']>;
};

export type UserManagementSiteSettingsUpdate = {
  __typename?: 'UserManagementSiteSettingsUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  siteSettingsErrors: Array<UserManagementSiteSettingsError>;
  userManagementSiteSettings?: Maybe<UserManagementSiteSettings>;
};

export type UserNotification = {
  __typename?: 'UserNotification';
  creationDate?: Maybe<Scalars['LocalDateTime']['output']>;
  data?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Maybe<SubscriptionGraphqlError>>>;
  id?: Maybe<Scalars['ID']['output']>;
  medicalMessage?: Maybe<MedicalMessage>;
  seen?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<NotificationType>;
  updateDate?: Maybe<Scalars['LocalDateTime']['output']>;
};

export type UserNotificationConnection = {
  __typename?: 'UserNotificationConnection';
  edges?: Maybe<Array<Maybe<UserNotificationEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type UserNotificationEdge = {
  __typename?: 'UserNotificationEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node?: Maybe<UserNotification>;
};

export enum UserNotificationField {
  CreationDate = 'CREATION_DATE',
  Seen = 'SEEN',
  Type = 'TYPE',
  UpdateDate = 'UPDATE_DATE'
}

export type UserNotificationFilterInput = {
  creationDate?: InputMaybe<S_DateTimeRangeInput>;
  excludeTypes?: InputMaybe<Array<InputMaybe<NotificationType>>>;
  medicalMessageTypes?: InputMaybe<Array<InputMaybe<MedicalMessageType>>>;
  medicalMessages?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  seen?: InputMaybe<Scalars['Boolean']['input']>;
  types?: InputMaybe<Array<InputMaybe<NotificationType>>>;
};

export type UserNotificationSortingInput = {
  direction: SortDirection;
  field: UserNotificationField;
};

export type UserPermission = {
  __typename?: 'UserPermission';
  code?: Maybe<PermissionEnum>;
  keyCloakPermission?: Maybe<KeyCloakPermission>;
  name: Scalars['String']['output'];
  sourcePermissionGroups?: Maybe<Array<Group>>;
};


export type UserPermissionSourcePermissionGroupsArgs = {
  userId: Scalars['ID']['input'];
};

export type UserProfileUpdate = {
  __typename?: 'UserProfileUpdate';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type UserProfileUpdateInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  preferredLanguage?: InputMaybe<Scalars['String']['input']>;
};

export enum UserSortField {
  DateJoined = 'DATE_JOINED',
  Email = 'EMAIL',
  FirstName = 'FIRST_NAME',
  LastMessageSent = 'LAST_MESSAGE_SENT',
  LastName = 'LAST_NAME',
  OrderCount = 'ORDER_COUNT'
}

export type UserSortingInput = {
  direction: OrderDirection;
  field: UserSortField;
};

export enum UserStatus {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED'
}

export type UserUpdate = {
  __typename?: 'UserUpdate';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type UserUpdateInput = {
  addGroups?: InputMaybe<Array<Scalars['ID']['input']>>;
  appRole?: InputMaybe<AppRoleTypes>;
  branches?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  defaultBranch?: InputMaybe<Scalars['ID']['input']>;
  dentalHygienistInfo?: InputMaybe<DentalHygienistInput>;
  departments?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  diabetesEducatorInfo?: InputMaybe<DiabetesEducatorInput>;
  doctorInfo?: InputMaybe<DoctorInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  fitnessCoachInfo?: InputMaybe<FitnessCoachInput>;
  gender?: InputMaybe<PersonGenderEnum>;
  healthLicenseEndDate?: InputMaybe<Scalars['Date']['input']>;
  healthLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  healthLicenseStartDate?: InputMaybe<Scalars['Date']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  nationalId?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  nurseInfo?: InputMaybe<NurseInput>;
  nutritionistInfo?: InputMaybe<NutritionistInput>;
  optometristInfo?: InputMaybe<OptometristInput>;
  password?: InputMaybe<Scalars['String']['input']>;
  pharmacistInfo?: InputMaybe<PharmacistInput>;
  photo?: InputMaybe<Scalars['String']['input']>;
  podiatricMedicalAssistantInfo?: InputMaybe<PodiatricMedicalAssistantInput>;
  preferredLanguage?: InputMaybe<Scalars['String']['input']>;
  psychologistInfo?: InputMaybe<PsychologistInput>;
  relationType?: InputMaybe<RelationTypes>;
  removeGroups?: InputMaybe<Array<Scalars['ID']['input']>>;
  secondName?: InputMaybe<Scalars['String']['input']>;
  socialWorkerInfo?: InputMaybe<SocialWorkerInput>;
  thirdName?: InputMaybe<Scalars['String']['input']>;
};

export enum UserVendorUserType {
  DentalHygienist = 'DENTAL_HYGIENIST',
  DiabetesEducator = 'DIABETES_EDUCATOR',
  Doctor = 'DOCTOR',
  FitnessCoach = 'FITNESS_COACH',
  Manager = 'MANAGER',
  Nurse = 'NURSE',
  Nutritionist = 'NUTRITIONIST',
  Optometrist = 'OPTOMETRIST',
  Pharmacist = 'PHARMACIST',
  PodiatricMedicalAssistant = 'PODIATRIC_MEDICAL_ASSISTANT',
  Psychologist = 'PSYCHOLOGIST',
  Receptionist = 'RECEPTIONIST',
  SocialWorker = 'SOCIAL_WORKER'
}

export type ValidateInsuranceBenefitApprovalLimitInput = {
  amount: Scalars['Float']['input'];
  encounterType: Scalars['String']['input'];
  insuranceLicense?: InputMaybe<Scalars['String']['input']>;
  insurancePolicy?: InputMaybe<Scalars['String']['input']>;
  itemCode: Scalars['String']['input'];
  sequence: Scalars['String']['input'];
  source?: InputMaybe<Scalars['String']['input']>;
};

export type ValidateInsuranceBenefitApprovalLimitResponse = {
  __typename?: 'ValidateInsuranceBenefitApprovalLimitResponse';
  itemCode: Scalars['String']['output'];
  justified: Scalars['Boolean']['output'];
  sequence: Scalars['String']['output'];
};

export type ValidateItemMaximumQuantityAllowedInput = {
  codeSystem: Scalars['String']['input'];
  itemCode: Scalars['String']['input'];
  quantity: Scalars['Float']['input'];
  sequence: Scalars['String']['input'];
};

export type ValidateItemMaximumQuantityAllowedResponse = {
  __typename?: 'ValidateItemMaximumQuantityAllowedResponse';
  itemCode: Scalars['String']['output'];
  justified: Scalars['Boolean']['output'];
  sequence: Scalars['String']['output'];
};

export type ValidatePrescription = {
  __typename?: 'ValidatePrescription';
  allowedDeliveryTypes: Array<DeliveryType>;
  prescription: Prescription;
};

export type ValidatePrescriptionInput = {
  memberID: Scalars['ID']['input'];
  referenceNumber: Scalars['String']['input'];
};

export type ValidateProcedureResponse = {
  __typename?: 'ValidateProcedureResponse';
  itemCode: Scalars['String']['output'];
  justified: Scalars['Boolean']['output'];
  sequence: Scalars['String']['output'];
};

export type ValidateProceduresInput = {
  diagnosisCodes: Array<Scalars['String']['input']>;
  itemCode: Scalars['String']['input'];
  sequence: Scalars['String']['input'];
};

export type Vendor = Node & {
  __typename?: 'Vendor';
  address?: Maybe<Address>;
  appointments?: Maybe<AppointmentCountableConnection>;
  approved: Scalars['Boolean']['output'];
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  approvedBy?: Maybe<User>;
  authorityCode?: Maybe<Scalars['String']['output']>;
  backGroundImage?: Maybe<Scalars['String']['output']>;
  bankInfo?: Maybe<VendorBankInfo>;
  branches?: Maybe<BranchCountableConnection>;
  callDoctorNowPlatformShare?: Maybe<Scalars['Float']['output']>;
  cluster?: Maybe<Scalars['String']['output']>;
  commercialRegistrationNumber: Scalars['String']['output'];
  contactEmail?: Maybe<Scalars['String']['output']>;
  contactMobileNumber?: Maybe<Scalars['String']['output']>;
  contactPhoneNumber?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  customerfavoritevendor: CustomerFavoriteVendorCountableConnection;
  deliveryMinFrom: Scalars['Int']['output'];
  deliveryMinTo: Scalars['Int']['output'];
  deliveryPrice: Scalars['Int']['output'];
  description?: Maybe<Scalars['String']['output']>;
  division?: Maybe<Division>;
  doctoravailabilitySet: DoctorAvailabilityCountableConnection;
  doctors: DoctorCountableConnection;
  editLikeEnabled: Scalars['Boolean']['output'];
  enableVisitDetails: Scalars['Boolean']['output'];
  favoriteCustomers: UserCountableConnection;
  fixedCosts?: Maybe<Scalars['Int']['output']>;
  group?: Maybe<Scalars['String']['output']>;
  hasMultipleBranches: Scalars['Boolean']['output'];
  hasOwnDrivers: Scalars['Boolean']['output'];
  healthProgramNetworkProviderProfessionals?: Maybe<HealthProgramNetworkProviderProfessionalCountableConnection>;
  healthProgramNetworks?: Maybe<HealthProgramNetworkCountableConnection>;
  id: Scalars['ID']['output'];
  images?: Maybe<Array<Maybe<VendorImage>>>;
  isActive: Scalars['Boolean']['output'];
  isFavorite?: Maybe<Scalars['Boolean']['output']>;
  isIntegrated: Scalars['Boolean']['output'];
  isLiveBookingIntegrated: Scalars['Boolean']['output'];
  isShippingFree: Scalars['Boolean']['output'];
  isVip: Scalars['Boolean']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  managersContactInfo?: Maybe<VendorManagersContactInfo>;
  maxNumberOfUsers?: Maybe<Scalars['Int']['output']>;
  modified: Scalars['DateTime']['output'];
  name: Scalars['String']['output'];
  nameAr?: Maybe<Scalars['String']['output']>;
  nationalId: Scalars['String']['output'];
  notifyByEmail: Scalars['Boolean']['output'];
  onboardingStatus?: Maybe<Scalars['String']['output']>;
  operationStatus?: Maybe<Scalars['String']['output']>;
  orderMinimumFreeDelivery: Scalars['Float']['output'];
  ordersCount?: Maybe<Scalars['Int']['output']>;
  orgIdNhic?: Maybe<Scalars['String']['output']>;
  ownerName: Scalars['String']['output'];
  paymentReceipts: PaymentReceiptCountableConnection;
  priceRange?: Maybe<PriceRangeEnum>;
  providerSettings?: Maybe<ProviderSettings>;
  rating?: Maybe<Scalars['Float']['output']>;
  rejections?: Maybe<VendorRejectionReasonCountableConnection>;
  reviews: VendorReviewConnection;
  seoDescription?: Maybe<Scalars['String']['output']>;
  seoTitle?: Maybe<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
  source?: Maybe<Scalars['String']['output']>;
  subscriptions: VendorSubscriptionCountableConnection;
  supportOutpatientJourney: Scalars['Boolean']['output'];
  taxLicenseNumber: Scalars['String']['output'];
  totalOrdersCount: Scalars['Float']['output'];
  tradeName: Scalars['String']['output'];
  type?: Maybe<VendorType>;
  users?: Maybe<UserCountableConnection>;
  virtualGroup?: Maybe<Scalars['String']['output']>;
};


export type VendorAppointmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<AppointmentFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<AppointmentSortingInput>;
};


export type VendorBranchesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<BranchFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<BranchOrder>;
  userLocation?: InputMaybe<LocationInput>;
};


export type VendorCustomerfavoritevendorArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type VendorDoctoravailabilitySetArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type VendorDoctorsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type VendorFavoriteCustomersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type VendorFixedCostsArgs = {
  dateFrom: Scalars['Date']['input'];
  dateTill: Scalars['Date']['input'];
};


export type VendorHealthProgramNetworkProviderProfessionalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<HealthProgramNetworkProviderProfessionalFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type VendorHealthProgramNetworksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<HealthProgramNetworkFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<HealthProgramNetworkSortingInput>;
};


export type VendorPaymentReceiptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PaymentReceiptFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type VendorRejectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type VendorReviewsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type VendorSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type VendorUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<UserSortingInput>;
};

export type VendorAdminInput = {
  acceptsDelivery?: InputMaybe<Scalars['Boolean']['input']>;
  acceptsPickup?: InputMaybe<Scalars['Boolean']['input']>;
  addressInput?: InputMaybe<AddressInput>;
  authorityCode?: InputMaybe<Scalars['String']['input']>;
  backGroundImage?: InputMaybe<Scalars['String']['input']>;
  bankInfo?: InputMaybe<VendorBankInfoInput>;
  branchType?: InputMaybe<VendorBranchTypeEnum>;
  branchWorkingHours?: InputMaybe<Array<InputMaybe<WorkingHourInput>>>;
  branchWorkingHoursOverride?: InputMaybe<Array<InputMaybe<WorkingHourOverrideInput>>>;
  callDoctorNowPlatformShare?: InputMaybe<Scalars['Float']['input']>;
  cluster?: InputMaybe<Scalars['String']['input']>;
  commercialRegistrationNumber?: InputMaybe<Scalars['String']['input']>;
  contactMobileNumber?: InputMaybe<Scalars['String']['input']>;
  contactPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  deliveryMinFrom?: InputMaybe<Scalars['Int']['input']>;
  deliveryMinTo?: InputMaybe<Scalars['Int']['input']>;
  deliveryPrice?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  editLikeEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  enableVisitDetails?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  hasMultipleBranches?: InputMaybe<Scalars['Boolean']['input']>;
  hasOwnDrivers?: InputMaybe<Scalars['Boolean']['input']>;
  healthLicenseEndDate?: InputMaybe<Scalars['Date']['input']>;
  healthLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  healthLicenseStartDate?: InputMaybe<Scalars['Date']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isIntegrated?: InputMaybe<Scalars['Boolean']['input']>;
  isLiveBookingIntegrated?: InputMaybe<Scalars['Boolean']['input']>;
  isVip?: InputMaybe<Scalars['Boolean']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  managersContactInfo?: InputMaybe<VendorManagersContactInfoInput>;
  maxNumberOfUsers?: InputMaybe<Scalars['Int']['input']>;
  mobileNumber: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  nameAr?: InputMaybe<Scalars['String']['input']>;
  nationalId?: InputMaybe<Scalars['String']['input']>;
  notifyByEmail?: InputMaybe<Scalars['Boolean']['input']>;
  operationStatus?: InputMaybe<Scalars['String']['input']>;
  orgIdNhic?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  priceRange?: InputMaybe<PriceRangeEnum>;
  slug?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  subscription?: InputMaybe<SubscriptionInput>;
  supportOutpatientJourney: Scalars['Boolean']['input'];
  taxLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  tradeName?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<VendorTypeEnum>;
  virtualGroup?: InputMaybe<Scalars['String']['input']>;
};

export type VendorBankInfo = Node & {
  __typename?: 'VendorBankInfo';
  accountName?: Maybe<Scalars['String']['output']>;
  accountNumber?: Maybe<Scalars['String']['output']>;
  bankName?: Maybe<Scalars['String']['output']>;
  iban?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  vendor: Vendor;
};

export type VendorBankInfoInput = {
  accountName?: InputMaybe<Scalars['String']['input']>;
  accountNumber?: InputMaybe<Scalars['String']['input']>;
  bankName?: InputMaybe<Scalars['String']['input']>;
  iban?: InputMaybe<Scalars['String']['input']>;
};

export enum VendorBranchTypeEnum {
  Clinic = 'CLINIC',
  DiagnosticCenter = 'DIAGNOSTIC_CENTER',
  Hospital = 'HOSPITAL',
  Pharmacy = 'PHARMACY'
}

export type VendorCountableConnection = {
  __typename?: 'VendorCountableConnection';
  edges: Array<VendorCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type VendorCountableEdge = {
  __typename?: 'VendorCountableEdge';
  cursor: Scalars['String']['output'];
  node: Vendor;
};

export type VendorCreate = {
  __typename?: 'VendorCreate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type VendorDelete = {
  __typename?: 'VendorDelete';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type VendorDepartmentCreate = {
  __typename?: 'VendorDepartmentCreate';
  department?: Maybe<Department>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendorDepartmentErrors: Array<VendorDepartmentError>;
};

export type VendorDepartmentCreateInput = {
  branch: Scalars['ID']['input'];
  code: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  directions?: InputMaybe<Scalars['String']['input']>;
  medicalServices?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name: Scalars['String']['input'];
};

export type VendorDepartmentDelete = {
  __typename?: 'VendorDepartmentDelete';
  department?: Maybe<Department>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendorDepartmentErrors: Array<VendorDepartmentError>;
};

export type VendorDepartmentError = {
  __typename?: 'VendorDepartmentError';
  code: VendorErrorCode;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type VendorDepartmentFilterInput = {
  branch?: InputMaybe<Scalars['ID']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  vendor?: InputMaybe<Scalars['ID']['input']>;
};

export type VendorDepartmentOrder = {
  direction: OrderDirection;
  field?: InputMaybe<VendorDepartmentOrderField>;
};

export enum VendorDepartmentOrderField {
  Branch = 'BRANCH',
  Code = 'CODE',
  Name = 'NAME'
}

export type VendorDepartmentUpdate = {
  __typename?: 'VendorDepartmentUpdate';
  department?: Maybe<Department>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendorDepartmentErrors: Array<VendorDepartmentError>;
};

export type VendorDepartmentUpdateInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  directions?: InputMaybe<Scalars['String']['input']>;
  medicalServices?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type VendorError = {
  __typename?: 'VendorError';
  code: VendorErrorCode;
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export enum VendorErrorCode {
  AlreadyExists = 'ALREADY_EXISTS',
  DuplicatedInputItem = 'DUPLICATED_INPUT_ITEM',
  GraphqlError = 'GRAPHQL_ERROR',
  Invalid = 'INVALID',
  NotFound = 'NOT_FOUND',
  NotVendorsImage = 'NOT_VENDORS_IMAGE',
  Required = 'REQUIRED',
  Unique = 'UNIQUE'
}

export type VendorFilterInput = {
  approved?: InputMaybe<Scalars['Boolean']['input']>;
  commercialRegistrationNumber?: InputMaybe<Scalars['String']['input']>;
  contactMobileNumber?: InputMaybe<Scalars['String']['input']>;
  contactMobileNumber_Icontains?: InputMaybe<Scalars['String']['input']>;
  contactPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  contactPhoneNumber_Icontains?: InputMaybe<Scalars['String']['input']>;
  delivery?: InputMaybe<DecimalRangeInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_Icontains?: InputMaybe<Scalars['String']['input']>;
  hasChatWith?: InputMaybe<Scalars['Boolean']['input']>;
  hasHealthPackages?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isFavorite?: InputMaybe<Scalars['Boolean']['input']>;
  isIntegrated?: InputMaybe<Scalars['Boolean']['input']>;
  isLiveBookingIntegrated?: InputMaybe<Scalars['Boolean']['input']>;
  isVip?: InputMaybe<Scalars['Boolean']['input']>;
  location?: InputMaybe<DistanceFilterInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  nationalId?: InputMaybe<Scalars['String']['input']>;
  nationalId_Icontains?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  ownerName_Icontains?: InputMaybe<Scalars['String']['input']>;
  priceRange?: InputMaybe<Scalars['String']['input']>;
  rating?: InputMaybe<DecimalRangeInput>;
  search?: InputMaybe<Scalars['String']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  slug_Icontains?: InputMaybe<Scalars['String']['input']>;
  subscriptionExpiry?: InputMaybe<DateRangeInput>;
  supportOutpatientJourney?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Array<InputMaybe<VendorTypeEnum>>>;
};

export type VendorImage = Node & {
  __typename?: 'VendorImage';
  alt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  sortOrder?: Maybe<Scalars['Int']['output']>;
  url: Scalars['String']['output'];
};


export type VendorImageUrlArgs = {
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type VendorImageCreate = {
  __typename?: 'VendorImageCreate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  image?: Maybe<VendorImage>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type VendorImageCreateInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
  image: Scalars['String']['input'];
  vendor: Scalars['ID']['input'];
};

export type VendorImageDelete = {
  __typename?: 'VendorImageDelete';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  image?: Maybe<VendorImage>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type VendorImageReorder = {
  __typename?: 'VendorImageReorder';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  images?: Maybe<Array<Maybe<VendorImage>>>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type VendorImageUpdate = {
  __typename?: 'VendorImageUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  image?: Maybe<VendorImage>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type VendorImageUpdateInput = {
  alt?: InputMaybe<Scalars['String']['input']>;
};

export type VendorInput = {
  acceptsDelivery?: InputMaybe<Scalars['Boolean']['input']>;
  acceptsPickup?: InputMaybe<Scalars['Boolean']['input']>;
  addressInput?: InputMaybe<AddressInput>;
  backGroundImage?: InputMaybe<Scalars['String']['input']>;
  bankInfo?: InputMaybe<VendorBankInfoInput>;
  branchType?: InputMaybe<VendorBranchTypeEnum>;
  branchWorkingHours?: InputMaybe<Array<InputMaybe<WorkingHourInput>>>;
  branchWorkingHoursOverride?: InputMaybe<Array<InputMaybe<WorkingHourOverrideInput>>>;
  commercialRegistrationNumber?: InputMaybe<Scalars['String']['input']>;
  contactMobileNumber?: InputMaybe<Scalars['String']['input']>;
  contactPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  deliveryMinFrom?: InputMaybe<Scalars['Int']['input']>;
  deliveryMinTo?: InputMaybe<Scalars['Int']['input']>;
  deliveryPrice?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  hasMultipleBranches?: InputMaybe<Scalars['Boolean']['input']>;
  healthLicenseEndDate?: InputMaybe<Scalars['Date']['input']>;
  healthLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  healthLicenseStartDate?: InputMaybe<Scalars['Date']['input']>;
  isLiveBookingIntegrated?: InputMaybe<Scalars['Boolean']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  managersContactInfo?: InputMaybe<VendorManagersContactInfoInput>;
  mobileNumber: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  nameAr?: InputMaybe<Scalars['String']['input']>;
  nationalId?: InputMaybe<Scalars['String']['input']>;
  notifyByEmail?: InputMaybe<Scalars['Boolean']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  priceRange?: InputMaybe<PriceRangeEnum>;
  supportOutpatientJourney: Scalars['Boolean']['input'];
  taxLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  tradeName?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<VendorTypeEnum>;
};

export type VendorManagersContactInfo = Node & {
  __typename?: 'VendorManagersContactInfo';
  financialManagerEmail?: Maybe<Scalars['String']['output']>;
  financialManagerMobileNumber?: Maybe<Scalars['String']['output']>;
  financialManagerName?: Maybe<Scalars['String']['output']>;
  generalManagerEmail?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  purchasingManagerEmail?: Maybe<Scalars['String']['output']>;
  purchasingManagerMobileNumber?: Maybe<Scalars['String']['output']>;
  purchasingManagerName?: Maybe<Scalars['String']['output']>;
  vendor: Vendor;
};

export type VendorManagersContactInfoInput = {
  financialManagerEmail?: InputMaybe<Scalars['String']['input']>;
  financialManagerMobileNumber?: InputMaybe<Scalars['String']['input']>;
  financialManagerName?: InputMaybe<Scalars['String']['input']>;
  generalManagerEmail?: InputMaybe<Scalars['String']['input']>;
  purchasingManagerEmail?: InputMaybe<Scalars['String']['input']>;
  purchasingManagerMobileNumber?: InputMaybe<Scalars['String']['input']>;
  purchasingManagerName?: InputMaybe<Scalars['String']['input']>;
};

export type VendorMarkMessagesAsSeen = {
  __typename?: 'VendorMarkMessagesAsSeen';
  chatErrors: Array<ChatError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  updatedCount?: Maybe<Scalars['Int']['output']>;
};

export type VendorMessageInput = {
  attachments?: InputMaybe<Array<InputMaybe<MessageAttachmentInput>>>;
  branch?: InputMaybe<Scalars['ID']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  recipient?: InputMaybe<Scalars['ID']['input']>;
};

export type VendorOrder = {
  direction: OrderDirection;
  field?: InputMaybe<VendorOrderField>;
};

export type VendorOrderAcceptInput = {
  acceptedMedications?: InputMaybe<Array<InputMaybe<MedicationOrderLineInput>>>;
};

export enum VendorOrderField {
  Created = 'CREATED',
  Name = 'NAME',
  Nearest = 'NEAREST',
  OrderCount = 'ORDER_COUNT',
  Rating = 'RATING'
}

export type VendorRegister = {
  __typename?: 'VendorRegister';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type VendorRejectionReason = Node & {
  __typename?: 'VendorRejectionReason';
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  reason: Scalars['String']['output'];
  vendor: Vendor;
};

export type VendorRejectionReasonCountableConnection = {
  __typename?: 'VendorRejectionReasonCountableConnection';
  edges: Array<VendorRejectionReasonCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type VendorRejectionReasonCountableEdge = {
  __typename?: 'VendorRejectionReasonCountableEdge';
  cursor: Scalars['String']['output'];
  node: VendorRejectionReason;
};

export type VendorRejectionReasonInput = {
  reason: Scalars['String']['input'];
  vendor: Scalars['ID']['input'];
};

export type VendorReview = Node & {
  __typename?: 'VendorReview';
  content?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID']['output'];
  published: Scalars['Boolean']['output'];
  rating: Scalars['Int']['output'];
  vendor: Vendor;
};

export type VendorReviewConnection = {
  __typename?: 'VendorReviewConnection';
  edges: Array<Maybe<VendorReviewEdge>>;
  pageInfo: PageInfo;
};

export type VendorReviewCreateInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  rating: Scalars['Int']['input'];
  vendor: Scalars['ID']['input'];
};

export type VendorReviewDelete = {
  __typename?: 'VendorReviewDelete';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendorErrors: Array<VendorError>;
  vendorReview?: Maybe<VendorReview>;
};

export type VendorReviewEdge = {
  __typename?: 'VendorReviewEdge';
  cursor: Scalars['String']['output'];
  node?: Maybe<VendorReview>;
};

export type VendorReviewPublish = {
  __typename?: 'VendorReviewPublish';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendorErrors: Array<VendorError>;
  vendorReview?: Maybe<VendorReview>;
};

export type VendorReviewPublishInput = {
  published: Scalars['Boolean']['input'];
};

export type VendorSendMessage = {
  __typename?: 'VendorSendMessage';
  chatErrors: Array<ChatError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  message?: Maybe<Message>;
};

export type VendorSubscription = Node & {
  __typename?: 'VendorSubscription';
  created: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  fixedCost?: Maybe<Money>;
  fixedCostAmount: Scalars['Float']['output'];
  fixedOrderCost?: Maybe<Money>;
  fixedOrderCostAmount: Scalars['Float']['output'];
  fixedOrderPercentage: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  modified: Scalars['DateTime']['output'];
  plan?: Maybe<Plan>;
  validFrom: Scalars['DateTime']['output'];
  validTill?: Maybe<Scalars['DateTime']['output']>;
  vendor?: Maybe<Vendor>;
};

export type VendorSubscriptionCountableConnection = {
  __typename?: 'VendorSubscriptionCountableConnection';
  edges: Array<VendorSubscriptionCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type VendorSubscriptionCountableEdge = {
  __typename?: 'VendorSubscriptionCountableEdge';
  cursor: Scalars['String']['output'];
  node: VendorSubscription;
};

export enum VendorType {
  Clinic = 'CLINIC',
  DiagnosticCenter = 'DIAGNOSTIC_CENTER',
  Hospital = 'HOSPITAL',
  Pharmacy = 'PHARMACY'
}

export enum VendorTypeEnum {
  Clinic = 'CLINIC',
  DiagnosticCenter = 'DIAGNOSTIC_CENTER',
  Hospital = 'HOSPITAL',
  Pharmacy = 'PHARMACY'
}

export type VendorUpdate = {
  __typename?: 'VendorUpdate';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type VendorUpdateAdminInput = {
  acceptsDelivery?: InputMaybe<Scalars['Boolean']['input']>;
  acceptsPickup?: InputMaybe<Scalars['Boolean']['input']>;
  addressInput?: InputMaybe<AddressInput>;
  authorityCode?: InputMaybe<Scalars['String']['input']>;
  backGroundImage?: InputMaybe<Scalars['String']['input']>;
  bankInfo?: InputMaybe<VendorBankInfoInput>;
  branchType?: InputMaybe<VendorBranchTypeEnum>;
  branchWorkingHours?: InputMaybe<Array<InputMaybe<WorkingHourInput>>>;
  branchWorkingHoursOverride?: InputMaybe<Array<InputMaybe<WorkingHourOverrideInput>>>;
  callDoctorNowPlatformShare?: InputMaybe<Scalars['Float']['input']>;
  cluster?: InputMaybe<Scalars['String']['input']>;
  commercialRegistrationNumber?: InputMaybe<Scalars['String']['input']>;
  contactMobileNumber?: InputMaybe<Scalars['String']['input']>;
  contactPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  deliveryMinFrom?: InputMaybe<Scalars['Int']['input']>;
  deliveryMinTo?: InputMaybe<Scalars['Int']['input']>;
  deliveryPrice?: InputMaybe<Scalars['Int']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  editLikeEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  enableVisitDetails?: InputMaybe<Scalars['Boolean']['input']>;
  group?: InputMaybe<Scalars['String']['input']>;
  hasMultipleBranches?: InputMaybe<Scalars['Boolean']['input']>;
  hasOwnDrivers?: InputMaybe<Scalars['Boolean']['input']>;
  healthLicenseEndDate?: InputMaybe<Scalars['Date']['input']>;
  healthLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  healthLicenseStartDate?: InputMaybe<Scalars['Date']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isIntegrated?: InputMaybe<Scalars['Boolean']['input']>;
  isLiveBookingIntegrated?: InputMaybe<Scalars['Boolean']['input']>;
  isVip?: InputMaybe<Scalars['Boolean']['input']>;
  logo?: InputMaybe<Scalars['String']['input']>;
  managersContactInfo?: InputMaybe<VendorManagersContactInfoInput>;
  maxNumberOfUsers?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameAr?: InputMaybe<Scalars['String']['input']>;
  nationalId?: InputMaybe<Scalars['String']['input']>;
  notifyByEmail?: InputMaybe<Scalars['Boolean']['input']>;
  operationStatus?: InputMaybe<Scalars['String']['input']>;
  orgIdNhic?: InputMaybe<Scalars['String']['input']>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  priceRange?: InputMaybe<PriceRangeEnum>;
  slug?: InputMaybe<Scalars['String']['input']>;
  source?: InputMaybe<Scalars['String']['input']>;
  subscription?: InputMaybe<SubscriptionInput>;
  supportOutpatientJourney?: InputMaybe<Scalars['Boolean']['input']>;
  taxLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  tradeName?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<VendorTypeEnum>;
  virtualGroup?: InputMaybe<Scalars['String']['input']>;
};

export type VendorUpdateEnableVisitDetails = {
  __typename?: 'VendorUpdateEnableVisitDetails';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type VendorUpdateEnableVisitDetailsInput = {
  enableVisitDetails: Scalars['Boolean']['input'];
};

export type VendorUpdateIsIntegrated = {
  __typename?: 'VendorUpdateIsIntegrated';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  vendor?: Maybe<Vendor>;
  vendorErrors: Array<VendorError>;
};

export type VendorUpdateIsIntegratedInput = {
  isIntegrated: Scalars['Boolean']['input'];
};

export type VendorUserCreate = {
  __typename?: 'VendorUserCreate';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type VendorUserCreateInput = {
  address?: InputMaybe<AddressInput>;
  appRole: AppRoleTypes;
  branches?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  defaultBranch?: InputMaybe<Scalars['ID']['input']>;
  departments?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<PersonGenderEnum>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  nationalId?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  photo?: InputMaybe<Scalars['String']['input']>;
  preferredLanguage?: InputMaybe<Scalars['String']['input']>;
  relationType?: InputMaybe<RelationTypes>;
  secondName?: InputMaybe<Scalars['String']['input']>;
  thirdName?: InputMaybe<Scalars['String']['input']>;
};

export type VendorUserDelete = {
  __typename?: 'VendorUserDelete';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export enum VendorUserTypes {
  DentalHygienist = 'DENTAL_HYGIENIST',
  DiabetesEducator = 'DIABETES_EDUCATOR',
  Doctor = 'DOCTOR',
  FitnessCoach = 'FITNESS_COACH',
  Manager = 'MANAGER',
  Nurse = 'NURSE',
  Nutritionist = 'NUTRITIONIST',
  Optometrist = 'OPTOMETRIST',
  Pharmacist = 'PHARMACIST',
  PodiatricMedicalAssistant = 'PODIATRIC_MEDICAL_ASSISTANT',
  Psychologist = 'PSYCHOLOGIST',
  Receptionist = 'RECEPTIONIST',
  SocialWorker = 'SOCIAL_WORKER'
}

export type VendorUserUpdate = {
  __typename?: 'VendorUserUpdate';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type VendorUserUpdateInput = {
  appRole?: InputMaybe<AppRoleTypes>;
  branches?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  departments?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<PersonGenderEnum>;
  healthLicenseEndDate?: InputMaybe<Scalars['Date']['input']>;
  healthLicenseNumber?: InputMaybe<Scalars['String']['input']>;
  healthLicenseStartDate?: InputMaybe<Scalars['Date']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobile?: InputMaybe<Scalars['String']['input']>;
  nationalId?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  photo?: InputMaybe<Scalars['String']['input']>;
  preferredLanguage?: InputMaybe<Scalars['String']['input']>;
  relationType?: InputMaybe<RelationTypes>;
  secondName?: InputMaybe<Scalars['String']['input']>;
  thirdName?: InputMaybe<Scalars['String']['input']>;
};

export type VerifyCredentials = {
  __typename?: 'VerifyCredentials';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  sessionToken?: Maybe<Scalars['String']['output']>;
};

export type VerifyNationalId = {
  __typename?: 'VerifyNationalId';
  accountErrors: Array<AccountError>;
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  unverifiedNationals?: Maybe<UnverifiedNationals>;
};

export type VerifyNationalIdInput = {
  rejectionReason?: InputMaybe<Scalars['String']['input']>;
  status: UnverifiedNationalsStatusesEnum;
};

export type Visit = H_Node & {
  __typename?: 'Visit';
  appointment?: Maybe<Appointment>;
  assistantAgentConnected?: Maybe<Scalars['Boolean']['output']>;
  attachments?: Maybe<Array<Maybe<VisitAttachment>>>;
  branch?: Maybe<Branch>;
  callAuditRecords?: Maybe<Array<Maybe<CallAuditRecord>>>;
  callId?: Maybe<Scalars['H_Long']['output']>;
  callRecordStatusInfo?: Maybe<VisitCallRecordStatusInfo>;
  cancellationReason?: Maybe<Scalars['String']['output']>;
  careInitialType?: Maybe<CareType>;
  careType?: Maybe<VisitCareType>;
  chatId?: Maybe<Scalars['H_Long']['output']>;
  complains?: Maybe<VisitComplainCountableConnection>;
  consultationRate?: Maybe<Scalars['Float']['output']>;
  consumerJoined?: Maybe<Scalars['Boolean']['output']>;
  consumerJoinedDate?: Maybe<Scalars['Instant']['output']>;
  consumerLeft?: Maybe<Scalars['Boolean']['output']>;
  consumerLeftDate?: Maybe<Scalars['Instant']['output']>;
  created?: Maybe<Scalars['Instant']['output']>;
  department?: Maybe<Department>;
  doctor?: Maybe<Doctor>;
  doctorAvailabilityStatus?: Maybe<Scalars['String']['output']>;
  doctorId?: Maybe<Scalars['Int']['output']>;
  doctorJoined?: Maybe<Scalars['Boolean']['output']>;
  doctorJoinedDate?: Maybe<Scalars['Instant']['output']>;
  doctorLeft?: Maybe<Scalars['Boolean']['output']>;
  doctorLeftDate?: Maybe<Scalars['Instant']['output']>;
  doctorNote?: Maybe<Scalars['String']['output']>;
  doctorUser?: Maybe<User>;
  doctorUserId?: Maybe<Scalars['Int']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  endTime?: Maybe<Scalars['Instant']['output']>;
  followupWorkflowId?: Maybe<Scalars['String']['output']>;
  followupWorkflowRunId?: Maybe<Scalars['String']['output']>;
  followupWorkflowType?: Maybe<Scalars['String']['output']>;
  hasDoctorNote?: Maybe<Scalars['Boolean']['output']>;
  hasLab?: Maybe<Scalars['Boolean']['output']>;
  hasRad?: Maybe<Scalars['Boolean']['output']>;
  healthProgram?: Maybe<HealthProgram>;
  healthProgramMember?: Maybe<HealthProgramMember>;
  id: Scalars['ID']['output'];
  labs?: Maybe<Array<Maybe<Lab>>>;
  meetingPlatformLink?: Maybe<Scalars['String']['output']>;
  meetingPlatformLinkForChatView?: Maybe<Scalars['String']['output']>;
  meetingPlatformLinkForJoinCall?: Maybe<Scalars['String']['output']>;
  messages?: Maybe<VisitMessageCountableConnection>;
  modified?: Maybe<Scalars['Instant']['output']>;
  noteToPatient?: Maybe<Scalars['String']['output']>;
  /** User-friendly number of a visit */
  number?: Maybe<Scalars['String']['output']>;
  outPatientJourney?: Maybe<OutPatientJourney>;
  patient?: Maybe<Patient>;
  patientId?: Maybe<Scalars['Int']['output']>;
  patientNationalId?: Maybe<Scalars['String']['output']>;
  patientNote?: Maybe<Scalars['String']['output']>;
  patientUser?: Maybe<User>;
  patientUserId?: Maybe<Scalars['Int']['output']>;
  payPerCallOnly?: Maybe<Scalars['Boolean']['output']>;
  paymentOperationLogs: PaymentOperationLogCountableConnection;
  paymentReceipts: PaymentReceiptCountableConnection;
  paymentStatus?: Maybe<PaymentStatus>;
  prescription?: Maybe<Prescription>;
  prescriptionId?: Maybe<Scalars['Int']['output']>;
  priceAmount?: Maybe<Scalars['Float']['output']>;
  priceAmountAfterVat?: Maybe<Scalars['Float']['output']>;
  providerGuidedCareHealthProgramTeam?: Maybe<ProviderGuidedCareHealthProgramTeam>;
  rads?: Maybe<Array<Maybe<Rad>>>;
  rejectionAudits?: Maybe<DoctorVisitRejectionAuditCountableConnection>;
  reviews?: Maybe<Array<Maybe<VisitReviewQuestion>>>;
  startTime?: Maybe<Scalars['Instant']['output']>;
  status?: Maybe<VisitStatus>;
  subscription?: Maybe<HealthProgramUserSubscription>;
  summaries?: Maybe<VisitSummaryCountableConnection>;
  transcription?: Maybe<VisitTranscription>;
  type?: Maybe<VisitType>;
  vatPercentage?: Maybe<Scalars['Float']['output']>;
  vendor?: Maybe<Vendor>;
  vendorActorJoined?: Maybe<Scalars['Boolean']['output']>;
  vendorActorJoinedDate?: Maybe<Scalars['Instant']['output']>;
  vendorActorLeft?: Maybe<Scalars['Boolean']['output']>;
  vendorActorLeftDate?: Maybe<Scalars['Instant']['output']>;
  vendorId?: Maybe<Scalars['Int']['output']>;
  visitDiagnoses?: Maybe<Array<Maybe<VisitDiagnosis>>>;
  visitPrescriptions?: Maybe<Array<Maybe<VisitPrescriptionTransaction>>>;
  vitalSigns?: Maybe<Array<Maybe<PatientHealthParameter>>>;
  workflowId?: Maybe<Scalars['String']['output']>;
};


export type VisitComplainsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type VisitMessagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type VisitPaymentOperationLogsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PaymentOperationLogFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type VisitPaymentReceiptsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PaymentReceiptFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type VisitRejectionAuditsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type VisitSummariesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type VisitAction = {
  __typename?: 'VisitAction';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  visit?: Maybe<Visit>;
  visitErrors?: Maybe<Array<H_EntityError>>;
};

/** Visit Approval Action */
export type VisitApprovalAction = {
  __typename?: 'VisitApprovalAction';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  visit?: Maybe<Visit>;
  visitErrors?: Maybe<Array<H_EntityError>>;
};

/** Update Visit Assistant Agent Status. */
export type VisitAssistantAgentStatusUpdate = {
  __typename?: 'VisitAssistantAgentStatusUpdate';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  visitErrors?: Maybe<Array<H_EntityError>>;
};

export type VisitAttachment = H_Node & {
  __typename?: 'VisitAttachment';
  attachment?: Maybe<Attachment>;
  attachmentId?: Maybe<Scalars['Int']['output']>;
  created?: Maybe<Scalars['Instant']['output']>;
  id: Scalars['ID']['output'];
  modified?: Maybe<Scalars['Instant']['output']>;
  type?: Maybe<VisitAttachmentType>;
  visit?: Maybe<Visit>;
};

export enum VisitAttachmentType {
  File = 'FILE',
  MedicalReport = 'MEDICAL_REPORT',
  TestResult = 'TEST_RESULT'
}

export type VisitAttachmentUploadInput = {
  attachment: Scalars['ID']['input'];
  type: VisitAttachmentType;
  visit: Scalars['ID']['input'];
};

/** end visit call. */
export type VisitCallEnd = {
  __typename?: 'VisitCallEnd';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  success?: Maybe<Scalars['Boolean']['output']>;
  visitErrors?: Maybe<Array<H_EntityError>>;
};

export type VisitCallRecordStatusInfo = {
  __typename?: 'VisitCallRecordStatusInfo';
  callRecordProgress?: Maybe<Scalars['String']['output']>;
  callRecordStatus?: Maybe<CallRecordStatus>;
  callRecordUrl?: Maybe<Scalars['String']['output']>;
};

export enum VisitCareType {
  /** Home Visit */
  HomeVisit = 'HOME_VISIT',
  /** In Person */
  InPerson = 'IN_PERSON',
  /** Virtual */
  Virtual = 'VIRTUAL'
}

export type VisitChronicGuidedCareMemberAddInput = {
  guidedCareProgramId: Scalars['ID']['input'];
  visitDiagnosisRiskTemplate: Scalars['ID']['input'];
};

export type VisitComplain = H_Node & {
  __typename?: 'VisitComplain';
  complain?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['Instant']['output']>;
  customerId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  modified?: Maybe<Scalars['Instant']['output']>;
  visit?: Maybe<Visit>;
};

export type VisitComplainCountableConnection = {
  __typename?: 'VisitComplainCountableConnection';
  edges: Array<VisitComplainCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type VisitComplainCountableEdge = {
  __typename?: 'VisitComplainCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: VisitComplain;
};

/** creates a new Visit Complain */
export type VisitComplainCreate = {
  __typename?: 'VisitComplainCreate';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  visitComplain?: Maybe<VisitComplain>;
  visitErrors?: Maybe<Array<H_EntityError>>;
};

export type VisitComplainCreateInput = {
  /** customer complain */
  complain: Scalars['String']['input'];
  /** ID of Visit to be Complain about */
  visit: Scalars['ID']['input'];
};

export type VisitComplainFilterInput = {
  visits?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type VisitCountableConnection = {
  __typename?: 'VisitCountableConnection';
  edges: Array<VisitCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type VisitCountableEdge = {
  __typename?: 'VisitCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: Visit;
};

export type VisitDiagnosis = H_Node & {
  __typename?: 'VisitDiagnosis';
  code: Scalars['String']['output'];
  display?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  riskTemplate?: Maybe<VisitDiagnosisRiskTemplate>;
  visit?: Maybe<Visit>;
  visitDiagnosisType: VisitDiagnosisType;
};

export type VisitDiagnosisCrud = {
  __typename?: 'VisitDiagnosisCRUD';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  visitDiagnosis?: Maybe<VisitDiagnosis>;
  visitDiagnosisErrors?: Maybe<Array<H_EntityError>>;
};

export type VisitDiagnosisRiskTemplate = H_Node & {
  __typename?: 'VisitDiagnosisRiskTemplate';
  chronicDiseaseCode?: Maybe<Scalars['String']['output']>;
  chronicDiseaseSeverity?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  enrolledGuidedPrograms?: Maybe<Array<Maybe<GuidedCareHealthProgram>>>;
  id: Scalars['ID']['output'];
  isFilled?: Maybe<Scalars['Boolean']['output']>;
  isGuidedCareProgramSelected?: Maybe<Scalars['Boolean']['output']>;
  notification?: Maybe<Scalars['String']['output']>;
  result?: Maybe<Scalars['String']['output']>;
  selectedGuidedCareProgram?: Maybe<GuidedCareHealthProgram>;
  suggestedGuidedPrograms?: Maybe<Array<Maybe<GuidedCareHealthProgram>>>;
  visitDiagnosis?: Maybe<VisitDiagnosis>;
};

export type VisitDiagnosisRiskTemplateCrud = {
  __typename?: 'VisitDiagnosisRiskTemplateCRUD';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  visitDiagnosisRiskTemplate?: Maybe<VisitDiagnosisRiskTemplate>;
  visitDiagnosisRiskTemplateErrors?: Maybe<Array<H_EntityError>>;
};

export type VisitDiagnosisRiskTemplateCountableConnection = {
  __typename?: 'VisitDiagnosisRiskTemplateCountableConnection';
  edges: Array<VisitDiagnosisRiskTemplateCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type VisitDiagnosisRiskTemplateCountableEdge = {
  __typename?: 'VisitDiagnosisRiskTemplateCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  node: VisitDiagnosisRiskTemplate;
};

export enum VisitDiagnosisType {
  Principal = 'PRINCIPAL',
  Secondary = 'SECONDARY'
}

/** assign call request to doctor */
export type VisitDoctorAssign = {
  __typename?: 'VisitDoctorAssign';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  result?: Maybe<Scalars['String']['output']>;
  visitErrors?: Maybe<Array<H_EntityError>>;
};

export type VisitDoctorNoteInput = {
  doctorNote?: InputMaybe<Scalars['String']['input']>;
  visit: Scalars['ID']['input'];
};

export type VisitFilterInput = {
  branches?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  callRecordStatus?: InputMaybe<CallRecordStatus>;
  careInitialType?: InputMaybe<Array<InputMaybe<CareType>>>;
  careType?: InputMaybe<Array<InputMaybe<VisitCareType>>>;
  chatIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  consumerJoined?: InputMaybe<Scalars['Boolean']['input']>;
  consumerLeft?: InputMaybe<Scalars['Boolean']['input']>;
  date?: InputMaybe<H_DateTimeRangeInput>;
  departments?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  doctorIsRunningLate?: InputMaybe<Scalars['Boolean']['input']>;
  doctorJoined?: InputMaybe<Scalars['Boolean']['input']>;
  doctorLeft?: InputMaybe<Scalars['Boolean']['input']>;
  doctors?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  healthProgramMembers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  healthPrograms?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  id?: InputMaybe<Scalars['String']['input']>;
  nationalIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  numbers?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  patientIsRunningLate?: InputMaybe<Scalars['Boolean']['input']>;
  patientName?: InputMaybe<Scalars['String']['input']>;
  patients?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  payPerCallOnly?: InputMaybe<Scalars['Boolean']['input']>;
  payers?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  requestedBy?: InputMaybe<RequestedBy>;
  selfOnly?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<InputMaybe<VisitStatus>>>;
  subscriptions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  types?: InputMaybe<Array<InputMaybe<VisitType>>>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  vendorActorIsRunningLate?: InputMaybe<Scalars['Boolean']['input']>;
  vendorActorJoined?: InputMaybe<Scalars['Boolean']['input']>;
  vendorActorLeft?: InputMaybe<Scalars['Boolean']['input']>;
  vendors?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type VisitMessage = H_Node & {
  __typename?: 'VisitMessage';
  attachments?: Maybe<Array<Maybe<VisitMessageAttachment>>>;
  content?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['Instant']['output']>;
  id: Scalars['ID']['output'];
  isBotMessage?: Maybe<Scalars['Boolean']['output']>;
  jsonPayload?: Maybe<Scalars['String']['output']>;
  recipient?: Maybe<User>;
  recipientId?: Maybe<Scalars['Int']['output']>;
  seenDate?: Maybe<Scalars['Instant']['output']>;
  sender?: Maybe<User>;
  senderId?: Maybe<Scalars['Int']['output']>;
  visit?: Maybe<Visit>;
};

export type VisitMessageAttachment = H_Node & {
  __typename?: 'VisitMessageAttachment';
  attachment?: Maybe<Attachment>;
  attachmentId?: Maybe<Scalars['Int']['output']>;
  created?: Maybe<Scalars['Instant']['output']>;
  id: Scalars['ID']['output'];
  message?: Maybe<VisitMessage>;
  modified?: Maybe<Scalars['Instant']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
};

export type VisitMessageCountableConnection = {
  __typename?: 'VisitMessageCountableConnection';
  edges: Array<VisitMessageCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type VisitMessageCountableEdge = {
  __typename?: 'VisitMessageCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: VisitMessage;
};

export type VisitMessageFilterInput = {
  visits?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type VisitMessageInput = {
  /** message image attachments */
  attachments?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  /** message text content */
  content?: InputMaybe<Scalars['String']['input']>;
  /** Message json payload */
  jsonPayload?: InputMaybe<Scalars['String']['input']>;
  /** visit ID to chat belong to. */
  visit?: InputMaybe<Scalars['ID']['input']>;
};

export type VisitPrescriptionActivity = {
  __typename?: 'VisitPrescriptionActivity';
  code?: Maybe<Scalars['String']['output']>;
  denialCode?: Maybe<Scalars['String']['output']>;
  denialDescription?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['String']['output']>;
  frequencyUnitType?: Maybe<Scalars['String']['output']>;
  frequencyValue?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  observation?: Maybe<Scalars['String']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  refills?: Maybe<Scalars['Int']['output']>;
};

export enum VisitPrescriptionActivityFrequencyType {
  Day = 'Day',
  Hour = 'Hour',
  Month = 'Month',
  Week = 'Week'
}

export enum VisitPrescriptionActivityType {
  GenericDrug = 'GenericDrug',
  TradeDrug = 'TradeDrug'
}

export type VisitPrescriptionTransaction = H_Node & {
  __typename?: 'VisitPrescriptionTransaction';
  activities?: Maybe<Array<Maybe<VisitPrescriptionActivity>>>;
  id: Scalars['ID']['output'];
  referenceId?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type VisitPrescriptionTransactionCountableConnection = {
  __typename?: 'VisitPrescriptionTransactionCountableConnection';
  edges: Array<VisitPrescriptionTransactionCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type VisitPrescriptionTransactionCountableEdge = {
  __typename?: 'VisitPrescriptionTransactionCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: VisitPrescriptionTransaction;
};

export enum VisitReferralSortField {
  CreatedDate = 'CREATED_DATE'
}

export type VisitReferralSortingInput = {
  direction: ReferralOrderDirection;
  field: VisitReferralSortField;
};

export type VisitReviewInput = {
  questionsValues: Array<InputMaybe<VisitReviewQuestionInput>>;
  /** ID of Visit to be reviewed */
  visit: Scalars['ID']['input'];
};

export type VisitReviewQuestion = H_Node & {
  __typename?: 'VisitReviewQuestion';
  created?: Maybe<Scalars['Instant']['output']>;
  customerId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  modified?: Maybe<Scalars['Instant']['output']>;
  question?: Maybe<ReviewQuestion>;
  value?: Maybe<Scalars['Float']['output']>;
  visit?: Maybe<Visit>;
};

export type VisitReviewQuestionCountableConnection = {
  __typename?: 'VisitReviewQuestionCountableConnection';
  edges: Array<VisitReviewQuestionCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type VisitReviewQuestionCountableEdge = {
  __typename?: 'VisitReviewQuestionCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: VisitReviewQuestion;
};

export type VisitReviewQuestionFilterInput = {
  reviewQuestions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type VisitReviewQuestionInput = {
  question: Scalars['ID']['input'];
  /** question value */
  value: Scalars['Float']['input'];
};

export enum VisitSortField {
  /** Sort visit by created. */
  Created = 'CREATED',
  /** Sort visit by date. */
  Date = 'DATE',
  /** Sort visit by number. */
  Number = 'NUMBER'
}

export type VisitSortingInput = {
  /** Specifies the direction in which to sort products. */
  direction: H_OrderDirection;
  /** Sort visits by the selected field. */
  field: VisitSortField;
};

export enum VisitStatus {
  Accepted = 'ACCEPTED',
  Assigned = 'ASSIGNED',
  CallInProgress = 'CALL_IN_PROGRESS',
  CallRejoinable = 'CALL_REJOINABLE',
  CallTimedOut = 'CALL_TIMED_OUT',
  Canceled = 'CANCELED',
  CanceledByConsumer = 'CANCELED_BY_CONSUMER',
  ChatInProgress = 'CHAT_IN_PROGRESS',
  Completed = 'COMPLETED',
  DoctorAnswerTimedOut = 'DOCTOR_ANSWER_TIMED_OUT',
  EndedByOneOfTheParticipants = 'ENDED_BY_ONE_OF_THE_PARTICIPANTS',
  Incompleted = 'INCOMPLETED',
  OutPatientJourneyAssessmentDone = 'OUT_PATIENT_JOURNEY_ASSESSMENT_DONE',
  OutPatientJourneyAssessmentPending = 'OUT_PATIENT_JOURNEY_ASSESSMENT_PENDING',
  OutPatientJourneyCheckedIn = 'OUT_PATIENT_JOURNEY_CHECKED_IN',
  OutPatientJourneyConsultationDone = 'OUT_PATIENT_JOURNEY_CONSULTATION_DONE',
  OutPatientJourneyConsultationPending = 'OUT_PATIENT_JOURNEY_CONSULTATION_PENDING',
  OutPatientJourneyPending = 'OUT_PATIENT_JOURNEY_PENDING',
  OutPatientJourneyTreatmentDone = 'OUT_PATIENT_JOURNEY_TREATMENT_DONE',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentPending = 'PAYMENT_PENDING',
  PaymentSucceeded = 'PAYMENT_SUCCEEDED',
  PaymentTimedOut = 'PAYMENT_TIMED_OUT',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type VisitStatusHistory = H_Node & {
  __typename?: 'VisitStatusHistory';
  callId?: Maybe<Scalars['H_Long']['output']>;
  cancellationReason?: Maybe<Scalars['String']['output']>;
  chatId?: Maybe<Scalars['H_Long']['output']>;
  createdBy?: Maybe<Scalars['String']['output']>;
  createdDate?: Maybe<Scalars['Instant']['output']>;
  doctor?: Maybe<Scalars['H_Long']['output']>;
  doctorId?: Maybe<Scalars['Int']['output']>;
  doctorUserId?: Maybe<Scalars['Int']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  lastModifiedBy?: Maybe<Scalars['String']['output']>;
  lastModifiedDate?: Maybe<Scalars['Instant']['output']>;
  meetingPlatformLink?: Maybe<Scalars['String']['output']>;
  meetingPlatformLinkForChatView?: Maybe<Scalars['String']['output']>;
  meetingPlatformLinkForJoinCall?: Maybe<Scalars['String']['output']>;
  patientId?: Maybe<Scalars['Int']['output']>;
  patientUserId?: Maybe<Scalars['Int']['output']>;
  qualityRating?: Maybe<Scalars['Int']['output']>;
  refId?: Maybe<Scalars['H_Long']['output']>;
  status?: Maybe<VisitStatus>;
  vendorId?: Maybe<Scalars['Int']['output']>;
};

export type VisitStatusHistoryCountableConnection = {
  __typename?: 'VisitStatusHistoryCountableConnection';
  edges: Array<VisitStatusHistoryCountableEdge>;
  pageInfo: H_PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type VisitStatusHistoryCountableEdge = {
  __typename?: 'VisitStatusHistoryCountableEdge';
  cursor: Scalars['String']['output'];
  node: VisitStatusHistory;
};

export type VisitStatusHistoryFilterInput = {
  doctors?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  patients?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  refIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  selfOnly?: InputMaybe<Scalars['Boolean']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<VisitStatus>>>;
  vendors?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

/** Visit status update */
export type VisitStatusUpdate = {
  __typename?: 'VisitStatusUpdate';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  visit?: Maybe<Visit>;
  visitErrors?: Maybe<Array<H_EntityError>>;
};

export type VisitStatusUpdateInput = {
  callId?: InputMaybe<Scalars['H_Long']['input']>;
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  chatId?: InputMaybe<Scalars['H_Long']['input']>;
  consumerJoined?: InputMaybe<Scalars['Boolean']['input']>;
  consumerLeft?: InputMaybe<Scalars['Boolean']['input']>;
  duration?: InputMaybe<Scalars['Int']['input']>;
  followupWorkflowId?: InputMaybe<Scalars['String']['input']>;
  followupWorkflowRunId?: InputMaybe<Scalars['String']['input']>;
  followupWorkflowType?: InputMaybe<Scalars['String']['input']>;
  meetingPlatformLink?: InputMaybe<Scalars['String']['input']>;
  qualityRating?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<VisitStatus>;
  vendorActorJoined?: InputMaybe<Scalars['Boolean']['input']>;
  vendorActorLeft?: InputMaybe<Scalars['Boolean']['input']>;
  workflowId?: InputMaybe<Scalars['String']['input']>;
  workflowRunId?: InputMaybe<Scalars['String']['input']>;
  workflowType?: InputMaybe<Scalars['String']['input']>;
};

export type VisitSummary = H_Node & {
  __typename?: 'VisitSummary';
  created?: Maybe<Scalars['Instant']['output']>;
  doctorNotes?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isProblemSolved?: Maybe<Scalars['Boolean']['output']>;
  modified?: Maybe<Scalars['Instant']['output']>;
  visit?: Maybe<Visit>;
};

/** CRUD a new Visit Summary. */
export type VisitSummaryCrud = {
  __typename?: 'VisitSummaryCRUD';
  /**
   * List of errors that occurred executing the mutation.
   * @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31.
   */
  errors?: Maybe<Array<H_Error>>;
  visitSummary?: Maybe<VisitSummary>;
  visitSummaryErrors?: Maybe<Array<H_EntityError>>;
};

export type VisitSummaryCountableConnection = {
  __typename?: 'VisitSummaryCountableConnection';
  edges: Array<VisitSummaryCountableEdge>;
  /** Pagination data for this connection. */
  pageInfo: H_PageInfo;
  /** A total count of items in the collection. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type VisitSummaryCountableEdge = {
  __typename?: 'VisitSummaryCountableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge. */
  node: VisitSummary;
};

export type VisitSummaryFilterInput = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  visits?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type VisitSummaryInput = {
  doctorNotes?: InputMaybe<Scalars['String']['input']>;
  isProblemSolved?: InputMaybe<Scalars['Boolean']['input']>;
  visitId?: InputMaybe<Scalars['String']['input']>;
};

export type VisitTranscription = Node & {
  __typename?: 'VisitTranscription';
  created: Scalars['DateTime']['output'];
  failureReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  modified: Scalars['DateTime']['output'];
  processingStatus: VisitTranscriptionProcessingStatus;
  segments: VisitTranscriptionSegmentsCountableConnection;
  summarizedText?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  visit?: Maybe<Visit>;
};


export type VisitTranscriptionSegmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type VisitTranscriptionMutation = {
  __typename?: 'VisitTranscriptionMutation';
  /** @deprecated Use typed errors with error codes. This field will be removed after 2020-07-31. */
  errors: Array<Error>;
  transcriptErrors: Array<TranscribeError>;
  visitTranscription?: Maybe<VisitTranscription>;
};

export enum VisitTranscriptionProcessingStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Processed = 'PROCESSED',
  UnderProcess = 'UNDER_PROCESS'
}

export type VisitTranscriptionSegments = Node & {
  __typename?: 'VisitTranscriptionSegments';
  endTime: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  speaker: Scalars['String']['output'];
  startTime: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type VisitTranscriptionSegmentsCountableConnection = {
  __typename?: 'VisitTranscriptionSegmentsCountableConnection';
  edges: Array<VisitTranscriptionSegmentsCountableEdge>;
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']['output']>;
};

export type VisitTranscriptionSegmentsCountableEdge = {
  __typename?: 'VisitTranscriptionSegmentsCountableEdge';
  cursor: Scalars['String']['output'];
  node: VisitTranscriptionSegments;
};

export enum VisitType {
  DirectAppointment = 'DIRECT_APPOINTMENT',
  DirectAtHome = 'DIRECT_AT_HOME',
  DirectCall = 'DIRECT_CALL',
  DirectInPerson = 'DIRECT_IN_PERSON',
  HealthProgram = 'HEALTH_PROGRAM'
}

export type Wallet = {
  __typename?: 'Wallet';
  balance?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  paymentOperationLogs: PaymentOperationLogCountableConnection;
  refundReason?: Maybe<Scalars['String']['output']>;
  refundedAfterPayment?: Maybe<Scalars['Boolean']['output']>;
};


export type WalletPaymentOperationLogsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<PaymentOperationLogFilterInput>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sortBy?: InputMaybe<PaymentOperationLogSortingInput>;
};

export enum WeightUnit {
  Gram = 'GRAM',
  Kilogram = 'KILOGRAM',
  Ounce = 'OUNCE',
  Pound = 'POUND'
}

export type WorkingHour = {
  __typename?: 'WorkingHour';
  day?: Maybe<DayOfWeekEnum>;
  openTimeRanges?: Maybe<Array<Maybe<TimeRange>>>;
};

export type WorkingHourInput = {
  day: DayOfWeekEnum;
  openTimeRanges: Array<InputMaybe<TimeRangeInput>>;
};

export type WorkingHourOverride = {
  __typename?: 'WorkingHourOverride';
  date?: Maybe<Scalars['Date']['output']>;
  openTimeRanges?: Maybe<Array<Maybe<TimeRange>>>;
};

export type WorkingHourOverrideInput = {
  date: Scalars['Date']['input'];
  openTimeRanges: Array<InputMaybe<TimeRangeInput>>;
};

export type OptionsStatistics = {
  __typename?: 'optionsStatistics';
  option?: Maybe<Scalars['String']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
};

export enum Period {
  HalfYear = 'HALF_YEAR',
  Monthly = 'MONTHLY',
  ThreeMonths = 'THREE_MONTHS',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export type QuestionsStatistics = {
  __typename?: 'questionsStatistics';
  answeredParticipantsCount?: Maybe<Scalars['Int']['output']>;
  optionsStatistics?: Maybe<Array<Maybe<OptionsStatistics>>>;
  participantsCount?: Maybe<Scalars['Int']['output']>;
  question?: Maybe<Question>;
};
