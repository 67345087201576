import { VendorBranchTypeEnum } from "@/schema/types";
import { formGirdBreakPoints } from "@toolkit/core";
import { useTranslation } from "@toolkit/i18n";
import { AutocompleteController, Grid } from "@toolkit/ui";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

export const BranchesType: FC<{ defaultValue }> = ({ defaultValue }) => {
  const { t } = useTranslation("admin");
  const {
    control,
    formState: { errors: formErrors },
  } = useFormContext();

  const types = Object.keys(VendorBranchTypeEnum).map(key => ({ value: VendorBranchTypeEnum[key], name: key }));
  const typeValue = defaultValue ? types?.find(item => item.value === defaultValue?.type) : undefined;

  return (
    <Grid item {...formGirdBreakPoints}>
      <AutocompleteController
        ControllerProps={{
          control: control,
          name: "type",
          defaultValue: typeValue,
        }}
        TextFieldProps={{
          placeholder: t("Type"),
          error: Boolean(formErrors?.type?.message),
          helperText: String(formErrors?.type?.message),
        }}
        getOptionLabel={option => option.name}
        options={types || []}
      />
    </Grid>
  );
};
